import React, { Component } from 'react'; 
import { StyleSheet, View, } from 'react-native'; 
import { Typography, Skeleton, Stack  } from '@mui/material'; 

class SkeletonMain extends Component {
    constructor(props){
        super(props)
    }
    
    render(){
        return(
            <View style={{ flex : 1 }}>
                <View style={{flexDirection : "row", margin : 16 }}>
                    <Skeleton 
                        variant="circular"
                        width={ 56 }
                        height={ 56 }
                    />
                    <View style={{flex : 1 }}>
                        <Skeleton 
                            animation="wave"
                            variant="text" sx={{ fontSize :'1.5rem' , marginLeft: 2 }}
                        />
                        <Skeleton 
                            animation="wave"
                            variant="text" sx={{ fontSize :'1.5rem' , marginRight: 8 , marginLeft : 2 }}
                        />
                    </View>
                   
                </View>
                <Skeleton
                    animation="wave"
                    variant="rectangular"
                    height={ 120 }
                    style={{margin : 16, borderRadius : 16  }}
                />
                <View style={{ marginLeft : 16, marginRight : 16, marginBottom : 16,  flexDirection: "row", justifyContent : "center"  }}>
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        style={{borderRadius : 8, flex : 0.3, height : 120, marginRight : 8 }}
                    />
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        style={{borderRadius : 8, flex : 0.3, height : 120 , marginLeft : 8, marginRight : 8 }}
                    />
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        style={{borderRadius : 8, flex : 0.3, height : 120, marginLeft : 8, marginRight : 8  }}
                    />  
                </View> 

                <View style={{ marginLeft : 16, marginRight : 16,marginBottom : 16,  flexDirection: "row", justifyContent : "center"  }}>
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        style={{borderRadius : 8, flex : 1, height : 120 }}
                    />  
                </View> 

                <View style={{ marginLeft : 16, marginRight : 16, flexDirection: "row", justifyContent : "center"  }}>
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        style={{borderRadius : 8, flex : 0.3, height : 120, marginRight : 8 }}
                    />
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        style={{borderRadius : 8, flex : 0.3, height : 120 , marginLeft : 8, marginRight : 8 }}
                    />
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        style={{borderRadius : 8, flex : 0.3, height : 120, marginLeft : 8, marginRight : 8  }}
                    />  
                </View> 

            </View>
        )
    }
}

export default SkeletonMain; 
