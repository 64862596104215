import React, { Component } from 'react';
import { StyleSheet, View, FlatList, Image, Dimensions, Pressable } from 'react-native';
import { code_ok, code_error, code_auth_error, code_empty, homepageBanner } from '../constants/api';
import { Typography, Skeleton } from '@mui/material';
import { executeGet, executePost } from '../utilities/Utilities';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import * as Command from '../constants/Commands';
import { color_text } from '../constants/colors';

var window_width = Dimensions.get("window").width - 32;

class HomepageBanners extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            data: [],
            code: this.props.code
        }
        this.fetchBanners = this.fetchBanners.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.openURL = this.openURL.bind(this);
    }

    componentDidMount = () => {
        this.fetchBanners();
    }

    fetchBanners = async () => {
        let formData = new FormData();
        formData.append("homepage_code", this.state.code);

        const response = await executePost(homepageBanner, formData);
        console.log("HOMEPAGE_BANNER = " + JSON.stringify(response));
        if (response.code == code_ok) {
            this.setState({
                showLoading: false,
                data: response.data
            })
        } else {
            if (response.code != code_auth_error) {
                this.setState({
                    showLoading: false,
                    showAlert: true
                })
                if (global.os == "ANDROID") {
                    JSBridge.launchFunction(Command.SHOW_TOAST, response.msg);
                } else {
                    window.webkit.messageHandlers.JSBridge.postMessage({ id: Command.SHOW_TOAST, params: response.msg })
                }
            } else {
                if (global.os == "ANDROID") {
                    JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                } else {
                    window.webkit.messageHandlers.JSBridge.postMessage({ id: Command.SHOW_TOAST, params: "Autentikasi gagal! Harap login ulang!" })
                }
                window.postMessage({ id: "sign_out" });
            }
        }
    }
    //ANCHOR - Render Item
    renderItem = ({ item, index, separators }) => {
        return (
            <Image
                source={{ uri: item.image }}
                style={{
                    width: window_width, height: 90, borderRadius: 8
                }}
            />
        )
    }
    //ANCHOR - Open URL
    openURL = (item) => {
        //console.log(JSON.stringify(item)); 
        if (item.url.includes("http://") || item.url.includes("https://")) {
            window.open(item.url);
            // if(global.os == "ANDROID"){
            //     JSBridge.launchFunction(Command.OPEN_URL, item.url);
            // } else {
            //     //TODO: IOS Bridge 
            //     let obj = {
            //         id : Command.OPEN_URL, 
            //         params : item.url
            //     }
            //     window.webkit.messageHandlers.JSBridge.postMessage(obj);
            // }
        }

    }
    render() {
        return (
            <View style={{ width: "100%" }}>
                {
                    this.state.showLoading &&
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height={120}
                        style={{ marginLeft: 16, marginRight: 16, borderRadius: 8 }}
                    />
                }
                {
                    !this.state.showLoading &&
                    <View>
                        <Typography
                            fontFamily="Open Sans"
                            fontWeight="600"
                            variant="h3"
                            fontSize={16}
                            style={{ flex: 1, marginLeft: 16, marginTop: 16 }}
                        >
                            {this.props.title}
                        </Typography>
                        <View style={styles.bannerContainer}>
                            <Carousel
                                showThumbs={false}
                                showStatus={false}
                            >
                                {
                                    this.state.data.map((item, index) => (
                                        <Pressable key={"banner_" + index} style={styles.bannerItem}
                                            onPress={() => { this.openURL(item) }}
                                        >
                                            <img src={item.image} />
                                        </Pressable>
                                    ))
                                }
                            </Carousel>
                        </View>
                    </View>

                    

                }
            </View>
        )
    }
}

const styles = StyleSheet.create({
    bannerContainer: {
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 12,
        marginRight: 12
    },
    bannerItem: {
        borderRadius: 12,
        borderWidth: 0,
        marginLeft: 4,
        marginRight: 4,
        overflow: "hidden"
    }
})

export default HomepageBanners;