import * as React from "react"
import Svg, {
  Rect,
  Path,
  G,
  Defs,
  LinearGradient,
  Stop,
  ClipPath,
} from "react-native-svg"

const HomeBg = (props) => (
  <Svg
    {...props}
    viewBox="0 0 375 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Rect y={10} width={375} height={146} fill="white" />
    <Path d="M0 10H375V76.5V156H0V10Z" fill="url(#paint0_linear_20_504)" />
    <G opacity={0.35}>
      <G clipPath="url(#clip0_20_504)">
        <Path
          d="M77.0443 158.88H17.8748C16.8399 158.88 16 158.04 16 157.005V29.3372C16 28.3023 16.8399 27.4624 17.8748 27.4624H77.0443C78.0792 27.4624 78.9191 28.3023 78.9191 29.3372V157.02C78.9191 158.055 78.0792 158.88 77.0443 158.88Z"
          fill="#00AAFF"
        />
        <Path
          d="M75.1545 38.1864H19.7797V67.8386H75.1545V38.1864Z"
          fill="#3B77BE"
        />
        <Path
          d="M75.1545 70.5383H19.7797V100.191H75.1545V70.5383Z"
          fill="#3B77BE"
        />
        <Path
          d="M75.1545 102.89H19.7797V132.543H75.1545V102.89Z"
          fill="#3B77BE"
        />
        <Path
          d="M201.218 158.88H92.5228C91.4879 158.88 90.648 158.04 90.648 157.005V29.3372C90.648 28.3023 91.4879 27.4624 92.5228 27.4624H201.233C202.268 27.4624 203.107 28.3023 203.107 29.3372V157.02C203.092 158.055 202.253 158.88 201.218 158.88Z"
          fill="#00AAFF"
        />
        <Path
          d="M196.373 38.1864H97.3974V67.8386H196.373V38.1864Z"
          fill="#3B77BE"
        />
        <Path
          d="M196.373 70.5383H97.3974V100.191H196.373V70.5383Z"
          fill="#3B77BE"
        />
        <Path
          d="M196.373 102.89H97.3974V132.543H196.373V102.89Z"
          fill="#3B77BE"
        />
        <Path
          d="M142.134 129.512V132.557H158.767V129.512H142.134Z"
          fill="#62BDF3"
        />
        <Path
          d="M144.424 126.794V129.509H159.302V126.794H144.424Z"
          fill="#D1EAF2"
        />
        <Path
          d="M142.14 124.085V126.799H157.018V124.085H142.14Z"
          fill="#3797DB"
        />
        <Path
          d="M173.38 113.974H169.991V132.543H173.38V113.974Z"
          fill="#62BDF3"
        />
        <Path
          d="M176.845 117.124H174.175V132.558H176.845V117.124Z"
          fill="#3797DB"
        />
        <Path
          d="M165.566 113.974H162.176V132.543H165.566V113.974Z"
          fill="#62BDF3"
        />
        <Path
          d="M169.031 117.124H166.361V132.558H169.031V117.124Z"
          fill="#3797DB"
        />
        <Path
          d="M112.771 117.724H106.861V132.558H112.771V117.724Z"
          fill="#62BDF3"
        />
        <Path
          d="M105.857 119.179H104.192V132.558H105.857V119.179Z"
          fill="#D1EAF2"
        />
        <Path
          d="M103.652 117.034H101.012V132.558H103.652V117.034Z"
          fill="#3797DB"
        />
        <Path
          d="M139.258 117.034H136.619V132.558H139.258V117.034Z"
          fill="#3797DB"
        />
        <Path
          d="M122.955 119.179H117.585V132.558H122.955V119.179Z"
          fill="#D1EAF2"
        />
        <Path
          d="M126.915 113.974H123.525V132.543H126.915V113.974Z"
          fill="#62BDF3"
        />
        <Path
          d="M130.379 117.124H127.71V132.558H130.379V117.124Z"
          fill="#3797DB"
        />
        <Path
          d="M116.581 116.029H113.461V132.558H116.581V116.029Z"
          fill="#3797DB"
        />
        <Path
          d="M135.824 118.639H130.919V132.558H135.824V118.639Z"
          fill="#D1EAF2"
        />
        <Path
          d="M27.6239 119.164H25.959V132.543H27.6239V119.164Z"
          fill="white"
        />
        <Path
          d="M25.4191 117.019H22.7794V132.543H25.4191V117.019Z"
          fill="#A2CFF6"
        />
        <Path
          d="M73.4296 117.709H67.5201V132.543H73.4296V117.709Z"
          fill="#62BDF3"
        />
        <Path
          d="M66.5152 119.164H64.8504V132.543H66.5152V119.164Z"
          fill="#D1EAF2"
        />
        <Path
          d="M64.3105 117.019H61.6707V132.543H64.3105V117.019Z"
          fill="#3797DB"
        />
        <Path
          d="M51.4117 113.974H48.022V132.543H51.4117V113.974Z"
          fill="#62BDF3"
        />
        <Path
          d="M28.5264 129.498V132.543H45.1598V129.498H28.5264Z"
          fill="#62BDF3"
        />
        <Path
          d="M30.8312 126.78V129.495H45.7098V126.78H30.8312Z"
          fill="#D1EAF2"
        />
        <Path
          d="M28.5327 124.07V126.785H43.4113V124.07H28.5327Z"
          fill="#62BDF3"
        />
        <Path
          d="M54.8764 117.109H52.2066V132.543H54.8764V117.109Z"
          fill="#3797DB"
        />
        <Path
          d="M60.8758 118.624H55.9713V132.543H60.8758V118.624Z"
          fill="#D1EAF2"
        />
        <Path
          d="M103.907 54.4598H102.242V67.8386H103.907V54.4598Z"
          fill="#D1EAF2"
        />
        <Path
          d="M101.702 52.3151H99.0622V67.8386H101.702V52.3151Z"
          fill="#3797DB"
        />
        <Path
          d="M149.712 53.005H143.803V67.8386H149.712V53.005Z"
          fill="#62BDF3"
        />
        <Path
          d="M142.798 54.4598H141.133V67.8386H142.798V54.4598Z"
          fill="#D1EAF2"
        />
        <Path
          d="M140.593 52.3151H137.954V67.8386H140.593V52.3151Z"
          fill="#3797DB"
        />
        <Path
          d="M127.695 49.2704H124.305V67.8386H127.695V49.2704Z"
          fill="#62BDF3"
        />
        <Path
          d="M104.811 64.7962V67.8409H121.444V64.7962H104.811Z"
          fill="#62BDF3"
        />
        <Path
          d="M107.116 62.078V64.7927H121.994V62.078H107.116Z"
          fill="#D1EAF2"
        />
        <Path
          d="M104.817 59.3685V62.0833H119.696V59.3685H104.817Z"
          fill="#3797DB"
        />
        <Path
          d="M131.159 52.405H128.489V67.8386H131.159V52.405Z"
          fill="#3797DB"
        />
        <Path
          d="M137.159 53.9199H132.254V67.8386H137.159V53.9199Z"
          fill="#D1EAF2"
        />
        <Path
          d="M184.944 49.2704H181.555V67.8386H184.944V49.2704Z"
          fill="#62BDF3"
        />
        <Path
          d="M188.409 52.405H185.739V67.8386H188.409V52.405Z"
          fill="#3797DB"
        />
        <Path
          d="M194.423 53.9199H189.519V67.8386H194.423V53.9199Z"
          fill="#D1EAF2"
        />
        <Path
          d="M34.5383 53.005H28.6288V67.8386H34.5383V53.005Z"
          fill="#62BDF3"
        />
        <Path
          d="M27.6239 54.4598H25.959V67.8386H27.6239V54.4598Z"
          fill="#D1EAF2"
        />
        <Path
          d="M25.4191 52.3151H22.7794V67.8386H25.4191V52.3151Z"
          fill="#3797DB"
        />
        <Path
          d="M70.6999 53.005H64.7904V67.8386H70.6999V53.005Z"
          fill="#62BDF3"
        />
        <Path
          d="M63.7855 54.4598H62.1207V67.8386H63.7855V54.4598Z"
          fill="#D1EAF2"
        />
        <Path
          d="M61.5807 52.3151H58.941V67.8386H61.5807V52.3151Z"
          fill="#3797DB"
        />
        <Path
          d="M44.7223 54.4598H39.3528V67.8386H44.7223V54.4598Z"
          fill="#D1EAF2"
        />
        <Path
          d="M48.6819 49.2704H45.2922V67.8386H48.6819V49.2704Z"
          fill="#62BDF3"
        />
        <Path
          d="M52.1466 52.405H49.4769V67.8386H52.1466V52.405Z"
          fill="#3797DB"
        />
        <Path
          d="M38.3479 51.3102H35.2282V67.8386H38.3479V51.3102Z"
          fill="#3797DB"
        />
        <Path
          d="M162.986 53.005H157.077V67.8386H162.986V53.005Z"
          fill="#62BDF3"
        />
        <Path
          d="M156.072 54.4598H154.407V67.8386H156.072V54.4598Z"
          fill="#D1EAF2"
        />
        <Path
          d="M153.867 52.3151H151.227V67.8386H153.867V52.3151Z"
          fill="#3797DB"
        />
        <Path
          d="M173.155 54.4598H167.786V67.8386H173.155V54.4598Z"
          fill="#D1EAF2"
        />
        <Path
          d="M177.115 49.2704H173.725V67.8386H177.115V49.2704Z"
          fill="#62BDF3"
        />
        <Path d="M180.58 52.405H177.91V67.8386H180.58V52.405Z" fill="#3797DB" />
        <Path
          d="M166.781 51.3102H163.661V67.8386H166.781V51.3102Z"
          fill="#3797DB"
        />
        <Path
          d="M174.655 85.3569H168.746V100.191H174.655V85.3569Z"
          fill="#62BDF3"
        />
        <Path
          d="M167.726 86.8119H166.061V100.191H167.726V86.8119Z"
          fill="#D1EAF2"
        />
        <Path
          d="M165.536 84.682H162.896V100.206H165.536V84.682Z"
          fill="#3797DB"
        />
        <Path
          d="M187.914 85.3569H182.004V100.191H187.914V85.3569Z"
          fill="#62BDF3"
        />
        <Path d="M181 86.8119H179.335V100.191H181V86.8119Z" fill="#D1EAF2" />
        <Path
          d="M178.795 84.682H176.155V100.206H178.795V84.682Z"
          fill="#3797DB"
        />
        <Path
          d="M191.709 83.6771H188.589V100.206H191.709V83.6771Z"
          fill="#3797DB"
        />
        <Path
          d="M58.1461 53.9199H53.2415V67.8386H58.1461V53.9199Z"
          fill="#D1EAF2"
        />
        <Path
          d="M112.771 85.3569H106.861V100.191H112.771V85.3569Z"
          fill="#62BDF3"
        />
        <Path
          d="M105.857 86.8119H104.192V100.191H105.857V86.8119Z"
          fill="#D1EAF2"
        />
        <Path
          d="M103.652 84.682H101.012V100.206H103.652V84.682Z"
          fill="#3797DB"
        />
        <Path
          d="M148.948 85.3569H143.038V100.191H148.948V85.3569Z"
          fill="#62BDF3"
        />
        <Path
          d="M142.018 86.8119H140.353V100.191H142.018V86.8119Z"
          fill="#D1EAF2"
        />
        <Path
          d="M139.828 84.682H137.189V100.206H139.828V84.682Z"
          fill="#3797DB"
        />
        <Path
          d="M122.955 86.8119H117.585V100.191H122.955V86.8119Z"
          fill="#D1EAF2"
        />
        <Path
          d="M126.915 81.6223H123.525V100.191H126.915V81.6223Z"
          fill="#62BDF3"
        />
        <Path
          d="M130.379 84.7721H127.71V100.206H130.379V84.7721Z"
          fill="#3797DB"
        />
        <Path
          d="M116.581 83.6771H113.461V100.206H116.581V83.6771Z"
          fill="#3797DB"
        />
        <Path
          d="M136.379 86.2869H131.474V100.206H136.379V86.2869Z"
          fill="#D1EAF2"
        />
        <Path
          d="M34.5383 84.0071H28.6288V100.206H34.5383V84.0071Z"
          fill="#D1EAF2"
        />
        <Path
          d="M27.6239 86.8119H25.959V100.191H27.6239V86.8119Z"
          fill="#3797DB"
        />
        <Path
          d="M25.4191 84.682H22.7794V100.206H25.4191V84.682Z"
          fill="#62BDF3"
        />
        <Path
          d="M63.7855 86.8119H62.1207V100.191H63.7855V86.8119Z"
          fill="#3797DB"
        />
        <Path
          d="M61.5807 83.0022H58.941V100.191H61.5807V83.0022Z"
          fill="#D1EAF2"
        />
        <Path
          d="M44.7223 83.0022H39.3528V100.191H44.7223V83.0022Z"
          fill="#3797DB"
        />
        <Path
          d="M48.6819 85.3569H45.2922V100.191H48.6819V85.3569Z"
          fill="#D1EAF2"
        />
        <Path
          d="M52.1466 80.6625H49.4769V100.191H52.1466V80.6625Z"
          fill="#62BDF3"
        />
        <Path
          d="M38.3479 85.762H35.2282V100.206H38.3479V85.762Z"
          fill="#62BDF3"
        />
        <Path
          d="M58.1461 83.6771H53.2415V100.206H58.1461V83.6771Z"
          fill="#3797DB"
        />
        <Path
          d="M67.6552 83.0297L63.0361 84.6781L68.5913 100.245L73.2104 98.5962L67.6552 83.0297Z"
          fill="#D1EAF2"
        />
        <Path
          d="M153.326 83.0321L148.707 84.6805L154.262 100.247L158.881 98.5986L153.326 83.0321Z"
          fill="#D1EAF2"
        />
        <Path
          d="M156.19 83.8034L153.887 84.6251L159.443 100.192L161.745 99.3699L156.19 83.8034Z"
          fill="#3797DB"
        />
        <Path
          d="M78.0492 37.5864H76.5493V159.915H78.0492V37.5864Z"
          fill="#3B77BE"
        />
        <Path
          d="M94.0977 37.5864H92.5978V159.915H94.0977V37.5864Z"
          fill="#3B77BE"
        />
        <Path
          d="M93.3478 39.4463H77.2993V40.7062H93.3478V39.4463Z"
          fill="#3B77BE"
        />
        <Path
          d="M93.3478 47.6505H77.2993V48.9104H93.3478V47.6505Z"
          fill="#3B77BE"
        />
        <Path
          d="M93.3478 55.8698H77.2993V57.1296H93.3478V55.8698Z"
          fill="#3B77BE"
        />
        <Path
          d="M93.3478 64.074H77.2993V65.3339H93.3478V64.074Z"
          fill="#3B77BE"
        />
        <Path
          d="M93.3478 72.2782H77.2993V73.5381H93.3478V72.2782Z"
          fill="#3B77BE"
        />
        <Path
          d="M93.3478 80.4974H77.2993V81.7573H93.3478V80.4974Z"
          fill="#3B77BE"
        />
        <Path
          d="M93.3478 88.7017H77.2993V89.9615H93.3478V88.7017Z"
          fill="#3B77BE"
        />
        <Path
          d="M93.3478 96.9209H77.2993V98.1808H93.3478V96.9209Z"
          fill="#3B77BE"
        />
        <Path
          d="M93.3478 105.125H77.2993V106.385H93.3478V105.125Z"
          fill="#3B77BE"
        />
        <Path
          d="M93.3478 113.329H77.2993V114.589H93.3478V113.329Z"
          fill="#3B77BE"
        />
        <Path
          d="M93.3478 121.549H77.2993V122.808H93.3478V121.549Z"
          fill="#3B77BE"
        />
        <Path
          d="M93.3478 129.753H77.2993V131.013H93.3478V129.753Z"
          fill="#3B77BE"
        />
        <Path
          d="M93.3478 137.957H77.2993V139.217H93.3478V137.957Z"
          fill="#3B77BE"
        />
        <Path
          d="M223.836 70.5533H222.171V83.9321H223.836V70.5533Z"
          fill="#D1EAF2"
        />
        <Path
          d="M221.631 68.4086H218.991V83.9321H221.631V68.4086Z"
          fill="#3797DB"
        />
        <Path
          d="M260.537 68.4086H257.897V83.9321H260.537V68.4086Z"
          fill="#3797DB"
        />
        <Path
          d="M247.623 65.3639H244.234V83.9322H247.623V65.3639Z"
          fill="#62BDF3"
        />
        <Path
          d="M224.745 80.8938V83.9385H241.378V80.8938H224.745Z"
          fill="#62BDF3"
        />
        <Path
          d="M227.05 78.1756V80.8904H241.928V78.1756H227.05Z"
          fill="#D1EAF2"
        />
        <Path
          d="M224.751 75.466V78.1808H239.63V75.466H224.751Z"
          fill="#62BDF3"
        />
        <Path
          d="M251.088 68.4985H248.418V83.9321H251.088V68.4985Z"
          fill="#3797DB"
        />
        <Path
          d="M257.102 70.0134H252.198V83.9321H257.102V70.0134Z"
          fill="#D1EAF2"
        />
        <Path
          d="M233.075 38.7864H229.685V57.3546H233.075V38.7864Z"
          fill="#62BDF3"
        />
        <Path
          d="M236.539 41.936H233.87V57.3696H236.539V41.936Z"
          fill="#3797DB"
        />
        <Path
          d="M242.554 43.4359H237.649V57.3546H242.554V43.4359Z"
          fill="#D1EAF2"
        />
        <Path
          d="M221.301 43.9758H215.931V57.3546H221.301V43.9758Z"
          fill="#D1EAF2"
        />
        <Path
          d="M225.26 38.7864H221.871V57.3546H225.26V38.7864Z"
          fill="#62BDF3"
        />
        <Path
          d="M228.725 41.936H226.055V57.3696H228.725V41.936Z"
          fill="#3797DB"
        />
        <Path
          d="M214.926 40.8262H211.807V57.3546H214.926V40.8262Z"
          fill="#3797DB"
        />
        <Path
          d="M244.229 40.9073L241.926 41.729L247.482 57.2955L249.784 56.4738L244.229 40.9073Z"
          fill="#3797DB"
        />
        <Path
          d="M262.007 57.3546H209.167V60.3843H262.007V57.3546Z"
          fill="#3B77BE"
        />
        <Path
          d="M269.776 83.1072H216.936V86.1369H269.776V83.1072Z"
          fill="#3B77BE"
        />
      </G>
    </G>
    <Path
      d="M328.281 41.7966C328.759 42.5582 329.221 43.3324 329.695 44.0971C330.563 45.5098 331.467 46.9059 332.178 48.4128C332.639 49.3942 332.998 50.4395 333.121 51.5273C333.213 52.2639 333.184 53.0114 333.079 53.745L333.147 53.7152C332.964 53.8496 332.856 54.0581 332.723 54.239C331.346 56.172 329.976 58.1111 328.596 60.0419C328.495 59.4989 328.346 58.9633 328.109 58.4654C327.714 57.6079 327.15 56.8485 326.577 56.1115C325.914 55.2694 325.231 54.4452 324.586 53.5878C325.817 49.6569 327.048 45.7266 328.281 41.7966Z"
      fill="#169B48"
    />
    <Path
      d="M333.147 53.7152C334.307 53.1993 335.581 52.9738 336.84 53.0049C338.41 53.0264 339.95 53.3863 341.478 53.7283C342.853 54.0603 344.24 54.3314 345.626 54.6112C342.317 57.0864 338.999 59.5497 335.689 62.0248C334.744 61.6863 333.822 61.2843 332.888 60.9134C331.52 60.3686 330.061 59.8895 328.576 60.0699L328.596 60.0419C329.976 58.1111 331.346 56.172 332.723 54.239C332.856 54.0581 332.964 53.8497 333.147 53.7152Z"
      fill="#11743B"
    />
    <Path
      d="M310.923 54.2582C313.887 54.2568 316.851 54.2682 319.815 54.2678C321.051 54.2647 322.288 54.2836 323.525 54.2709C323.978 54.852 324.426 55.4375 324.892 56.009C325.431 56.6834 325.981 57.3512 326.457 58.076C326.882 58.7294 327.241 59.4502 327.36 60.235C327.436 60.6873 327.413 61.1485 327.441 61.6052C327.446 61.7038 327.381 61.7804 327.329 61.8553C325.475 64.443 323.645 67.0482 321.805 69.6455C320.494 71.4927 319.188 73.3434 317.88 75.1923C317.823 75.2786 317.768 75.3696 317.686 75.4353C317.705 75.279 317.768 75.1345 317.813 74.9851C319.146 70.7841 320.48 66.584 321.812 62.3825C318.978 60.27 316.144 58.157 313.31 56.0436C312.515 55.4471 311.713 54.8612 310.923 54.2582Z"
      fill="#215FAC"
    />
    <Path
      d="M327.44 61.6052C327.964 61.4751 328.489 61.3175 329.032 61.314C329.982 61.2649 330.915 61.5299 331.805 61.8482C333.044 62.3033 334.247 62.8572 335.493 63.293C336.785 67.3841 338.089 71.4717 339.383 75.5623C338.702 75.0771 338.043 74.5577 337.37 74.0607C334.417 71.8364 331.456 69.6219 328.506 67.3937C324.882 70.1189 321.257 72.8432 317.632 75.5671C317.643 75.5133 317.655 75.4594 317.665 75.4064L317.686 75.4353C317.767 75.3696 317.823 75.2785 317.88 75.1923C319.188 73.3433 320.494 71.4927 321.805 69.6455C323.645 67.0482 325.475 64.443 327.329 61.8553C327.381 61.7804 327.446 61.7037 327.44 61.6052Z"
      fill="#184999"
    />
    <Path
      d="M304.276 77.572C304.769 77.4196 305.335 77.3127 305.812 77.5724C306.291 77.8268 306.349 78.6024 305.939 78.9501C305.634 79.2286 305.201 79.2392 304.816 79.2159C304.817 79.515 304.815 79.8146 304.817 80.1146C304.637 80.1154 304.457 80.1154 304.276 80.1146C304.277 79.2672 304.277 78.4194 304.276 77.572ZM304.818 77.9245C304.812 78.2065 304.818 78.489 304.817 78.771C305.043 78.7789 305.299 78.817 305.493 78.6677C305.743 78.4907 305.715 78.0208 305.42 77.9105C305.227 77.8553 305.004 77.861 304.818 77.9245Z"
      fill="#58585B"
    />
    <Path
      d="M311.191 77.5755C311.686 77.417 312.257 77.3141 312.737 77.5729C313.223 77.8357 313.271 78.6322 312.838 78.9699C312.536 79.2287 312.119 79.2396 311.746 79.2142C311.734 79.5138 311.743 79.8142 311.741 80.1146C311.558 80.1155 311.376 80.1155 311.193 80.1142C311.19 79.2681 311.194 78.4216 311.191 77.5755ZM311.737 77.914C311.744 78.1996 311.738 78.4851 311.741 78.7706C311.958 78.7789 312.199 78.8149 312.391 78.6844C312.648 78.528 312.651 78.0774 312.385 77.9298C312.18 77.8374 311.949 77.8768 311.737 77.914Z"
      fill="#58585B"
    />
    <Path
      d="M316.291 77.5833C316.677 77.3385 317.173 77.3876 317.573 77.5675C317.571 77.7243 317.572 77.8811 317.575 78.0379C317.277 77.9223 316.942 77.8032 316.628 77.9249C316.463 77.9862 316.395 78.2547 316.547 78.3654C316.878 78.5704 317.338 78.5327 317.596 78.8647C317.854 79.2588 317.66 79.8723 317.232 80.0431C316.798 80.2201 316.285 80.1693 315.891 79.9131C315.893 79.748 315.891 79.5829 315.892 79.4182C316.206 79.5973 316.57 79.7694 316.936 79.6709C317.135 79.6354 317.259 79.3495 317.112 79.1896C316.8 78.9623 316.356 79.0105 316.079 78.7193C315.787 78.3834 315.922 77.7992 316.291 77.5833Z"
      fill="#58585B"
    />
    <Path
      d="M339.771 77.5786C340.154 77.3391 340.637 77.3929 341.036 77.5598C341.041 77.7174 341.043 77.8755 341.043 78.0336C340.763 77.9298 340.459 77.8195 340.161 77.9044C340.017 77.932 339.905 78.0892 339.935 78.239C339.965 78.3642 340.093 78.4098 340.196 78.4518C340.487 78.5631 340.836 78.5885 341.052 78.8451C341.334 79.2305 341.143 79.8628 340.713 80.0393C340.279 80.2219 339.767 80.168 339.367 79.9202C339.366 79.7525 339.366 79.5852 339.367 79.4183C339.68 79.6005 340.045 79.7682 340.411 79.671C340.614 79.6316 340.744 79.3224 340.566 79.1757C340.248 78.9616 339.803 79.0054 339.536 78.7001C339.262 78.3581 339.404 77.7875 339.771 77.5786Z"
      fill="#58585B"
    />
    <Path
      d="M306.53 77.466C307.104 77.4664 307.678 77.4651 308.253 77.4664C308.252 77.614 308.252 77.7625 308.252 77.9105C307.859 77.9118 307.466 77.9083 307.072 77.9122C307.074 78.1189 307.068 78.3256 307.077 78.5323C307.378 78.5323 307.679 78.5323 307.981 78.5323C307.985 78.6803 307.99 78.8284 307.984 78.9768C307.68 78.9703 307.376 78.9742 307.072 78.9746C307.072 79.2072 307.075 79.4402 307.072 79.6731C307.488 79.6788 307.903 79.6736 308.319 79.6762C308.32 79.822 308.32 79.9687 308.32 80.115C307.723 80.1146 307.126 80.1159 306.529 80.1146C306.531 79.2317 306.53 78.3488 306.53 77.466Z"
      fill="#58585B"
    />
    <Path
      d="M308.713 77.5768C309.193 77.4301 309.733 77.3272 310.207 77.5496C310.594 77.7187 310.778 78.2547 310.568 78.6331C310.472 78.8236 310.279 78.9247 310.099 79.0123C310.297 79.29 310.47 79.6653 310.842 79.7125C310.842 79.8549 310.842 79.9972 310.842 80.1404C310.646 80.136 310.431 80.1544 310.26 80.0362C309.958 79.8466 309.806 79.5045 309.607 79.219C309.536 79.0859 309.372 79.1297 309.251 79.1205C309.253 79.452 309.252 79.7835 309.252 80.115C309.072 80.115 308.892 80.115 308.713 80.115C308.713 79.2689 308.712 78.4229 308.713 77.5768ZM309.253 77.91C309.251 78.1724 309.254 78.4347 309.251 78.6974C309.472 78.704 309.715 78.7474 309.916 78.6252C310.135 78.4986 310.175 78.1303 309.965 77.9757C309.757 77.822 309.488 77.8912 309.253 77.91Z"
      fill="#58585B"
    />
    <Path
      d="M313.383 77.4677C313.567 77.4647 313.751 77.4651 313.935 77.4669C313.933 77.9731 313.933 78.4798 313.935 78.986C313.936 79.1901 313.961 79.4183 314.11 79.5685C314.296 79.734 314.594 79.7327 314.782 79.5702C314.934 79.4205 314.955 79.1888 314.957 78.9843C314.961 78.4789 314.961 77.974 314.957 77.4686C315.141 77.4634 315.326 77.4656 315.51 77.4673C315.492 78.0292 315.522 78.5915 315.499 79.1538C315.477 79.4993 315.308 79.8553 314.997 80.0138C314.54 80.2551 313.888 80.1781 313.578 79.7266C313.443 79.5216 313.388 79.2707 313.387 79.0254C313.381 78.5061 313.389 77.9867 313.383 77.4677Z"
      fill="#58585B"
    />
    <Path
      d="M317.865 77.4634C318.57 77.4691 319.276 77.4643 319.982 77.466C319.981 77.6272 319.982 77.7888 319.979 77.9504C319.717 77.9447 319.456 77.953 319.196 77.9447C319.189 78.6677 319.187 79.3916 319.197 80.1155C319.014 80.115 318.83 80.115 318.647 80.1155C318.658 79.3929 318.65 78.6703 318.653 77.9482C318.388 77.9477 318.123 77.9495 317.858 77.9473C317.858 77.7857 317.852 77.6241 317.865 77.4634Z"
      fill="#58585B"
    />
    <Path
      d="M322.664 77.4651C322.844 77.466 323.024 77.466 323.205 77.466C323.205 77.8408 323.203 78.2161 323.205 78.591C323.537 78.2266 323.85 77.8448 324.167 77.4668C324.372 77.4655 324.577 77.4642 324.782 77.4682C324.457 77.8776 324.11 78.2674 323.776 78.6685C324.006 78.9431 324.189 79.2558 324.435 79.5155C324.531 79.6232 324.674 79.6573 324.806 79.6889C324.812 79.8395 324.811 79.9906 324.811 80.1417C324.568 80.1399 324.304 80.1097 324.116 79.9354C323.82 79.6705 323.624 79.3123 323.38 79.0005C323.321 79.0513 323.262 79.1025 323.204 79.1546C323.204 79.4743 323.203 79.7944 323.205 80.1145C323.023 80.1154 322.842 80.1154 322.66 80.1141C322.672 79.2312 322.661 78.3484 322.664 77.4651Z"
      fill="#58585B"
    />
    <Path
      d="M325.975 77.4862C326.173 77.4586 326.375 77.4564 326.573 77.4875C327.105 78.2473 327.461 79.1792 327.389 80.1282C327.206 80.1308 327.023 80.1194 326.84 80.1098C326.842 79.9022 326.843 79.6942 326.837 79.4871C326.46 79.4779 326.082 79.4752 325.705 79.4884C325.709 79.6964 325.699 79.9044 325.702 80.1129C325.521 80.1194 325.34 80.1313 325.159 80.1282C325.084 79.1788 325.443 78.2451 325.975 77.4862ZM325.786 79.0465C326.111 79.0522 326.435 79.0496 326.76 79.0483C326.646 78.6506 326.494 78.2604 326.27 77.914C326.058 78.2657 325.899 78.6493 325.786 79.0465Z"
      fill="#58585B"
    />
    <Path
      d="M328.495 77.5571C328.57 77.4279 328.739 77.4796 328.86 77.4647C328.969 77.4779 329.121 77.4358 329.187 77.5554C329.694 78.2985 330.027 79.2059 329.957 80.1251C329.773 80.1343 329.59 80.1172 329.407 80.1115C329.408 79.9026 329.408 79.6938 329.405 79.4853C329.029 79.4779 328.653 79.4779 328.277 79.4853C328.277 79.6946 328.272 79.9044 328.272 80.1142C328.091 80.1164 327.91 80.1317 327.728 80.1286C327.655 79.2085 327.989 78.3012 328.495 77.5571ZM328.356 79.0487C328.68 79.0496 329.003 79.05 329.327 79.0487C329.221 78.6506 329.055 78.2727 328.851 77.9184C328.615 78.2547 328.471 78.6532 328.356 79.0487Z"
      fill="#58585B"
    />
    <Path
      d="M330.242 77.4686C330.4 77.4638 330.558 77.4655 330.716 77.4673C331.132 77.9709 331.543 78.4793 331.957 78.9851C331.932 78.4811 331.939 77.9766 331.937 77.4721C332.106 77.4651 332.276 77.4616 332.445 77.4708C332.436 78.3567 332.445 79.243 332.44 80.1294C332.312 80.1206 332.183 80.1141 332.054 80.1097C331.912 79.6599 331.57 79.3319 331.289 78.9746C331.109 78.753 330.929 78.5314 330.739 78.319C330.757 78.9168 330.751 79.515 330.754 80.1132C330.585 80.1158 330.416 80.1167 330.247 80.1123C330.249 79.2312 330.243 78.3501 330.242 77.4686Z"
      fill="#58585B"
    />
    <Path
      d="M334.251 77.4642C334.411 77.4664 334.571 77.4629 334.731 77.4717C335.136 77.9823 335.553 78.4824 335.964 78.9882C335.931 78.4833 335.943 77.9775 335.94 77.4721C336.108 77.4642 336.277 77.4647 336.446 77.4664C336.445 78.3515 336.443 79.2365 336.446 80.1216C336.316 80.1238 336.186 80.1154 336.056 80.1089C335.915 79.6547 335.567 79.3276 335.285 78.9659C335.105 78.7469 334.93 78.5231 334.736 78.3182C334.767 78.9164 334.75 79.5159 334.755 80.1146C334.587 80.1154 334.42 80.1159 334.253 80.1137C334.242 79.2308 334.245 78.3475 334.251 77.4642Z"
      fill="#58585B"
    />
    <Path
      d="M337.569 77.487C337.75 77.4616 337.935 77.4541 338.117 77.48C338.153 77.4835 338.181 77.5023 338.2 77.5364C338.717 78.284 339.052 79.1992 338.985 80.1272C338.802 80.132 338.62 80.1171 338.438 80.1123C338.442 79.9034 338.434 79.695 338.434 79.4865C338.057 79.4782 337.68 79.4751 337.302 79.4878C337.3 79.6959 337.297 79.9043 337.3 80.1128C337.117 80.118 336.933 80.1325 336.75 80.1268C336.684 79.1778 337.037 78.2468 337.569 77.487ZM337.38 79.0429C337.705 79.0565 338.03 79.0469 338.355 79.0482C338.246 78.6602 338.093 78.2831 337.886 77.9393L337.86 77.9306C337.643 78.2704 337.498 78.6553 337.38 79.0429Z"
      fill="#58585B"
    />
    <Path
      d="M341.548 77.4695C341.73 77.4625 341.913 77.4664 342.096 77.4664C342.094 78.3489 342.094 79.2317 342.095 80.1146C341.913 80.1154 341.732 80.1154 341.55 80.1146C341.548 79.233 341.552 78.351 341.548 77.4695Z"
      fill="#58585B"
    />
    <Path
      d="M343.275 77.4738C343.705 77.3586 344.209 77.4221 344.545 77.7431C345.033 78.2468 345.059 79.1174 344.662 79.6867C344.464 79.9849 344.108 80.1311 343.768 80.153C343.39 80.178 342.977 80.0593 342.728 79.7497C342.323 79.2334 342.295 78.4381 342.67 77.8964C342.813 77.6853 343.036 77.5413 343.275 77.4738ZM343.445 77.9174C343.199 77.995 343.037 78.2411 342.998 78.4946C342.942 78.8218 342.958 79.1957 343.168 79.4651C343.419 79.7756 343.938 79.7699 344.184 79.4559C344.372 79.1971 344.404 78.8511 344.352 78.5406C344.327 78.3146 344.213 78.0922 344.02 77.9735C343.848 77.8715 343.632 77.8482 343.445 77.9174Z"
      fill="#58585B"
    />
    <Path
      d="M345.286 77.4717C345.446 77.4687 345.61 77.4494 345.768 77.4792C346.182 77.9815 346.592 78.4868 347.004 78.9913C346.968 78.4868 346.981 77.9806 346.98 77.4752C347.147 77.4617 347.315 77.4656 347.483 77.4665C347.484 78.3506 347.486 79.2344 347.484 80.1185C347.354 80.1251 347.224 80.115 347.095 80.1093C346.994 79.8037 346.809 79.5383 346.599 79.3027C346.324 78.9729 346.063 78.6313 345.774 78.3147C345.806 78.9138 345.788 79.5142 345.795 80.1142C345.626 80.1159 345.457 80.1159 345.289 80.1137C345.287 79.2331 345.293 78.3524 345.286 77.4717Z"
      fill="#58585B"
    />
    <Path
      d="M348.545 77.6481C348.596 77.5781 348.635 77.469 348.737 77.4747C348.908 77.473 349.106 77.4305 349.262 77.5098C349.796 78.2582 350.136 79.1888 350.07 80.1277C349.886 80.1316 349.704 80.118 349.521 80.1115C349.527 79.903 349.519 79.6946 349.519 79.4865C349.141 79.4787 348.763 79.4734 348.385 79.4892C348.385 79.6967 348.382 79.9048 348.384 80.1128C348.201 80.118 348.018 80.1325 347.834 80.1268C347.773 79.2448 348.074 78.3738 348.545 77.6481ZM348.47 79.0508C348.793 79.0469 349.116 79.0512 349.44 79.0482C349.323 78.6523 349.178 78.2564 348.946 77.9161C348.742 78.2713 348.569 78.6492 348.47 79.0508Z"
      fill="#58585B"
    />
    <Path
      d="M350.566 77.4703C350.751 77.4725 350.936 77.4638 351.122 77.4769C351.088 78.1995 351.107 78.9243 351.118 79.6477C351.481 79.6556 351.845 79.6538 352.209 79.6486C352.209 79.808 352.214 79.9674 352.212 80.1272C351.664 80.1268 351.116 80.1268 350.569 80.1272C350.563 79.2417 350.568 78.3558 350.566 77.4703Z"
      fill="#58585B"
    />
    <Path
      d="M320.841 77.4866C321.035 77.4625 321.236 77.4494 321.429 77.4897C321.98 78.2355 322.321 79.1814 322.256 80.1277C322.073 80.1321 321.89 80.1168 321.707 80.1129C321.709 79.904 321.709 79.6946 321.703 79.4862C321.326 79.4796 320.948 79.4726 320.571 79.4897C320.574 79.6973 320.565 79.9048 320.569 80.1129C320.386 80.119 320.204 80.1321 320.022 80.1269C319.955 79.1779 320.307 78.246 320.841 77.4866ZM321.064 78.0353C320.893 78.3568 320.742 78.6935 320.66 79.0513C320.982 79.0461 321.304 79.0526 321.626 79.0478C321.517 78.6563 321.36 78.2784 321.153 77.9311C321.102 77.9386 321.089 77.9977 321.064 78.0353Z"
      fill="#58585B"
    />
    <Path
      opacity={0.77}
      d="M314.093 82.2765C314.438 82.146 314.844 82.0882 315.182 82.2691C315.465 82.418 315.549 82.8436 315.355 83.1003C315.262 83.2281 315.117 83.2965 314.973 83.3433C315.109 83.5303 315.217 83.7423 315.387 83.9012C315.452 83.9621 315.55 83.9582 315.62 84.0098C315.623 84.0821 315.623 84.1548 315.622 84.2284C315.481 84.2288 315.322 84.2297 315.208 84.1303C314.976 83.9345 314.854 83.6446 314.671 83.4068C314.574 83.3959 314.477 83.3867 314.381 83.3805C314.383 83.6577 314.381 83.935 314.382 84.2126C314.283 84.2161 314.185 84.2161 314.087 84.2126C314.082 83.5675 314.069 82.9216 314.093 82.2765ZM314.381 82.4486C314.383 82.6794 314.381 82.9102 314.382 83.141C314.595 83.1541 314.832 83.1852 315.02 83.0556C315.207 82.9325 315.214 82.6019 315.018 82.4837C314.826 82.3624 314.589 82.4075 314.381 82.4486Z"
      fill="#6C6C6D"
    />
    <Path
      opacity={0.77}
      d="M315.913 82.1829C316.336 82.1816 316.759 82.1856 317.182 82.1825C317.183 82.2661 317.182 82.3507 317.179 82.4352C316.856 82.4264 316.533 82.4255 316.209 82.4365C316.211 82.6414 316.211 82.8468 316.21 83.0522C316.465 83.043 316.719 83.0439 316.974 83.054C316.974 83.1302 316.974 83.2072 316.974 83.2848C316.721 83.297 316.467 83.2909 316.213 83.2896C316.207 83.5142 316.212 83.7393 316.21 83.9644C316.551 83.9631 316.891 83.9675 317.232 83.9613C317.232 84.0454 317.232 84.1295 317.232 84.2145C316.791 84.2153 316.35 84.2193 315.909 84.2123C315.912 83.5357 315.905 82.8591 315.913 82.1829Z"
      fill="#6C6C6D"
    />
    <Path
      opacity={0.77}
      d="M317.579 82.2757C317.897 82.1461 318.263 82.108 318.59 82.2214C318.983 82.3646 319.082 82.9703 318.776 83.2532C318.537 83.4896 318.182 83.5133 317.869 83.4831C317.887 83.7261 317.878 83.9705 317.879 84.2144C317.782 84.2148 317.684 84.2162 317.587 84.2197C317.57 83.572 317.587 82.9234 317.579 82.2757ZM317.878 82.4469C317.881 82.7066 317.88 82.9663 317.878 83.2264C318.094 83.2523 318.343 83.2698 318.523 83.1187C318.706 82.9611 318.711 82.6182 318.506 82.4776C318.317 82.3589 318.082 82.3979 317.878 82.4469Z"
      fill="#6C6C6D"
    />
    <Path
      opacity={0.77}
      d="M319.216 82.1833C319.315 82.1777 319.414 82.1777 319.513 82.182C319.541 82.6348 319.49 83.089 319.527 83.5409C319.545 83.7669 319.714 83.9797 319.944 83.9872C320.207 84.027 320.443 83.8024 320.454 83.5352C320.479 83.0877 320.458 82.6384 320.465 82.1904C320.564 82.1829 320.663 82.1781 320.763 82.1759C320.765 82.616 320.77 83.0561 320.757 83.4958C320.746 83.8032 320.553 84.122 320.253 84.2017C319.984 84.2709 319.663 84.2469 319.455 84.0397C319.291 83.8764 319.226 83.6351 319.221 83.406C319.21 82.9983 319.225 82.5911 319.216 82.1833Z"
      fill="#6C6C6D"
    />
    <Path
      opacity={0.77}
      d="M321.203 82.2661C321.516 82.1592 321.857 82.1159 322.18 82.1978C322.424 82.2626 322.605 82.5407 322.532 82.7977C322.492 82.9598 322.355 83.0662 322.217 83.1384C322.341 83.1993 322.477 83.2606 322.554 83.385C322.65 83.5483 322.619 83.7616 322.523 83.9179C322.425 84.0787 322.248 84.1663 322.074 84.2096C321.783 84.2788 321.481 84.2122 321.202 84.1194C321.203 83.5015 321.2 82.8836 321.203 82.2661ZM321.488 82.4478C321.492 82.6453 321.489 82.8433 321.49 83.0412C321.652 83.0539 321.816 83.0592 321.976 83.0281C322.098 83.0009 322.224 82.919 322.255 82.7864C322.294 82.6532 322.233 82.4964 322.107 82.4417C321.911 82.3546 321.685 82.38 321.488 82.4478ZM321.49 83.2873C321.491 83.5076 321.49 83.7279 321.49 83.9486C321.705 83.9893 321.943 84.0423 322.146 83.928C322.33 83.8308 322.386 83.5335 322.221 83.392C322.006 83.2409 321.733 83.2952 321.49 83.2873Z"
      fill="#6C6C6D"
    />
    <Path
      opacity={0.77}
      d="M322.949 82.1868C323.048 82.1807 323.147 82.1794 323.247 82.1837C323.24 82.7749 323.241 83.3666 323.248 83.9578C323.548 83.9621 323.848 83.9565 324.148 83.9608C324.147 84.0445 324.147 84.1286 324.148 84.2135C323.748 84.2179 323.348 84.2157 322.948 84.2148C322.947 83.5387 322.946 82.8625 322.949 82.1868Z"
      fill="#6C6C6D"
    />
    <Path
      opacity={0.77}
      d="M324.458 82.1904C324.556 82.1812 324.654 82.1777 324.752 82.179C324.744 82.8573 324.747 83.5361 324.747 84.2149C324.649 84.2171 324.552 84.2153 324.455 84.2092C324.462 83.5361 324.457 82.8635 324.458 82.1904Z"
      fill="#6C6C6D"
    />
    <Path
      opacity={0.77}
      d="M325.261 82.1904C325.36 82.1821 325.46 82.1786 325.56 82.179C325.561 82.4886 325.562 82.7982 325.558 83.1083C325.856 82.8135 326.128 82.493 326.414 82.1869C326.528 82.1807 326.643 82.1777 326.759 82.1825C326.488 82.5022 326.195 82.8026 325.924 83.1218C326.112 83.3465 326.278 83.5904 326.472 83.8098C326.554 83.9053 326.673 83.9495 326.79 83.9802C326.792 84.0629 326.794 84.1453 326.795 84.2294C326.601 84.2359 326.404 84.1654 326.272 84.0152C326.071 83.7888 325.897 83.5396 325.711 83.301C325.66 83.3391 325.609 83.3785 325.558 83.4179C325.562 83.6833 325.56 83.9491 325.56 84.2149C325.463 84.2149 325.367 84.2162 325.271 84.2175C325.246 83.5427 325.267 82.8661 325.261 82.1904Z"
      fill="#6C6C6D"
    />
    <Path
      opacity={0.77}
      d="M327.754 82.1886C327.852 82.1802 327.95 82.1785 328.048 82.1824C328.042 82.859 328.045 83.5356 328.046 84.2122C327.948 84.2153 327.851 84.2157 327.753 84.214C327.747 83.5387 327.746 82.8638 327.754 82.1886Z"
      fill="#6C6C6D"
    />
    <Path
      opacity={0.77}
      d="M328.558 82.1815C328.664 82.1885 328.808 82.1333 328.872 82.252C329.214 82.697 329.58 83.1231 329.906 83.5811C329.858 83.116 329.886 82.647 329.881 82.1806C329.971 82.1785 330.061 82.1771 330.152 82.1771C330.159 82.855 330.155 83.5334 330.154 84.2117C330.085 84.2148 330.017 84.2183 329.949 84.2227C329.848 83.8627 329.576 83.6035 329.36 83.3166C329.181 83.0933 329.01 82.8625 328.819 82.6497C328.831 83.1712 328.824 83.6928 328.824 84.2144C328.735 84.2148 328.646 84.2148 328.557 84.2144C328.557 83.5369 328.556 82.859 328.558 82.1815Z"
      fill="#6C6C6D"
    />
    <Path
      opacity={0.77}
      d="M330.63 82.267C330.932 82.1514 331.263 82.1334 331.58 82.1816C331.93 82.246 332.211 82.5613 332.261 82.923C332.341 83.3333 332.246 83.8304 331.886 84.0725C331.508 84.313 331.031 84.2565 330.628 84.1181C330.63 83.5011 330.626 82.884 330.63 82.267ZM330.929 82.4413C330.926 82.9418 330.931 83.4424 330.927 83.9429C331.198 84.0038 331.52 84.0287 331.747 83.833C331.968 83.632 332.006 83.3 331.971 83.0145C331.946 82.7969 331.833 82.5805 331.641 82.4772C331.42 82.3638 331.163 82.3887 330.929 82.4413Z"
      fill="#6C6C6D"
    />
    <Path
      opacity={0.77}
      d="M333.085 82.2074C333.348 82.1334 333.643 82.1255 333.894 82.2486C334.14 82.3734 334.303 82.63 334.358 82.9024C334.43 83.2676 334.372 83.6845 334.117 83.9648C333.747 84.3541 333.05 84.3243 332.712 83.9052C332.492 83.6105 332.455 83.2063 332.536 82.8529C332.599 82.5608 332.809 82.3055 333.085 82.2074ZM333.258 82.4281C333.037 82.4851 332.876 82.6913 332.832 82.9164C332.78 83.1914 332.788 83.5024 332.952 83.7384C333.182 84.0708 333.741 84.0607 333.952 83.7104C334.122 83.4165 334.124 83.0307 333.984 82.7237C333.858 82.4527 333.527 82.3445 333.258 82.4281Z"
      fill="#6C6C6D"
    />
    <Path
      opacity={0.77}
      d="M334.734 82.1856C334.817 82.1803 334.899 82.1768 334.982 82.175C335.212 82.4479 335.428 82.7338 335.651 83.0128C335.794 83.198 335.952 83.3723 336.073 83.5747C336.058 83.1087 336.048 82.6423 336.06 82.1764C336.151 82.1768 336.242 82.1799 336.333 82.1834C336.336 82.8586 336.332 83.5339 336.336 84.2092C336.267 84.2131 336.197 84.2171 336.128 84.2215C336.031 83.8825 335.783 83.6303 335.574 83.3623C335.381 83.1201 335.193 82.8744 334.995 82.6379C335.01 83.1648 335.012 83.6925 334.998 84.2193C334.912 84.2167 334.826 84.2162 334.741 84.2175C334.726 83.5405 334.74 82.863 334.734 82.1856Z"
      fill="#6C6C6D"
    />
    <Path
      opacity={0.77}
      d="M336.809 82.1829C337.23 82.1816 337.653 82.186 338.075 82.1825C338.076 82.2661 338.075 82.3502 338.072 82.4347C337.75 82.4277 337.428 82.4242 337.106 82.4374C337.101 82.6414 337.1 82.8464 337.105 83.0509C337.359 83.0439 337.614 83.0439 337.868 83.054C337.868 83.1302 337.868 83.2064 337.869 83.2834C337.613 83.3001 337.356 83.2865 337.1 83.2931C337.104 83.5169 337.1 83.7406 337.108 83.9644C337.447 83.9627 337.786 83.9684 338.125 83.9609C338.124 84.0446 338.123 84.1286 338.122 84.2136C337.683 84.2158 337.243 84.2197 336.804 84.2114C336.809 83.5352 336.802 82.8591 336.809 82.1829Z"
      fill="#6C6C6D"
    />
    <Path
      opacity={0.77}
      d="M338.675 82.2604C338.966 82.0979 339.323 82.1356 339.618 82.2691C339.621 82.3602 339.625 82.4513 339.627 82.5433C339.388 82.429 339.101 82.3357 338.849 82.4627C338.689 82.5271 338.615 82.7929 338.774 82.9006C339.044 83.0741 339.415 83.0324 339.641 83.2869C339.858 83.5663 339.696 84.0173 339.391 84.1492C339.059 84.3029 338.668 84.2424 338.361 84.0554C338.357 83.9573 338.355 83.8597 338.357 83.7625C338.623 83.9267 338.959 84.0686 339.262 83.9232C339.435 83.8496 339.528 83.5601 339.355 83.438C339.096 83.272 338.745 83.3114 338.518 83.0832C338.285 82.8468 338.404 82.4102 338.675 82.2604Z"
      fill="#6C6C6D"
    />
    <Path
      opacity={0.77}
      d="M340.066 82.1881C340.163 82.1806 340.261 82.1785 340.36 82.1815C340.366 82.8577 340.357 83.5338 340.364 84.21C340.264 84.2152 340.165 84.217 340.066 84.2148C340.065 83.5391 340.066 82.8634 340.066 82.1881Z"
      fill="#6C6C6D"
    />
    <Path
      opacity={0.77}
      d="M340.907 83.2195C341.028 82.8459 341.226 82.5061 341.432 82.1768C341.543 82.1803 341.655 82.1838 341.767 82.1855C342.007 82.5648 342.231 82.9655 342.339 83.4086C342.403 83.671 342.414 83.9438 342.408 84.2135C342.308 84.2149 342.209 84.2149 342.111 84.214C342.108 84.0331 342.108 83.8523 342.101 83.6714C341.766 83.6683 341.431 83.6701 341.096 83.6705C341.087 83.8509 341.077 84.0318 341.084 84.2131C340.984 84.2157 340.884 84.2153 340.785 84.2131C340.777 83.8781 340.798 83.5378 340.907 83.2195ZM341.143 83.4275C341.445 83.4196 341.748 83.4209 342.05 83.427C341.949 83.0732 341.802 82.7325 341.595 82.4312C341.393 82.7351 341.245 83.0754 341.143 83.4275Z"
      fill="#6C6C6D"
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_20_504"
        x1={275.5}
        y1={10}
        x2={368.5}
        y2={90.5}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#184999" />
        <Stop offset={1} stopColor="#184999" stopOpacity={0} />
      </LinearGradient>
      <ClipPath id="clip0_20_504">
        <Rect
          width={263.27}
          height={159.9}
          fill="white"
          transform="translate(16)"
        />
      </ClipPath>
    </Defs>
  </Svg>
)

export default HomeBg
