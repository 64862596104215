import React, { Component } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native'; 
import { Typography , List, ListItemButton, ListItemIcon, ListItemText, Modal } from '@mui/material'; 
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess'; 
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

class EducationDialog extends Component { 
    constructor(props){
        super(props)
    }

    render(){
        return(
            <Modal
                open={ this.props.showing }
                onClose={ this.props.onClose }
            >
            <View style={{ justifyContent : "center", alignItems : "center", height : "100%", width : "100%", }}>
                <View style={ styles.container}>
                    <Typography
                        variant="h3" 
                        fontFamily="Open Sans" 
                        fontSize={ 18 }
                        fontWeight={"600"}
                        style={{ margin : 16, paddingLeft : 16, paddingRight : 16 }}
                    >
                        {this.props.title}
                    </Typography>
                    <ScrollView>
                        <List>
                            {
                                this.props.data.map((item, index)=>(
                                    <ListItemButton key={ index } onClick={()=>{ this.props.onItemClick(item)}}>
                                        <ListItemIcon>
                                            <ChevronRightIcon />
                                        </ListItemIcon>
                                        {
                                            item.name &&
                                            <ListItemText primary={ item.name }/>
                                        }

                                        {
                                            item.nama_lembaga &&
                                            <ListItemText primary={ item.nama_lembaga }/>
                                        }
                                        
                                    </ListItemButton>
                                ))
                            }
                        </List>
                    </ScrollView>
                </View>
            </View>

            </Modal>
        )
    }
}


const styles = StyleSheet.create({
    container : {
        backgroundColor : "white",
        borderRadius : 20 , 
        margin : 16 ,
        
        flexWrap : "wrap"
    }
})

export default EducationDialog; 