import React, { Component } from 'react';
import { View, StyleSheet, FlatList, Image, Pressable, Dimensions, ScrollView } from 'react-native';
import {
    Box, Paper, Alert, AlertTitle, Button, Card, CardActionArea, CardContent, Modal,
    Typography, Skeleton, Avatar, Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, IconButton, TextField, List, ListItemButton,
    ListItemText, ListItemIcon, Collapse
} from '@mui/material';
import {
    code_ok, code_empty, code_error, code_auth_error, profile, borrowingList,
    readingHistory, updatePassword, updateAvatar, userSignOut, updateProfile, appConfig,
    saveLembaga, regencies, getLembaga
} from '../constants/api';
import { primary, color_text, sub_text } from '../constants/colors';
import * as Identifier from '../constants/PageIdentifiers';
import * as Command from '../constants/Commands';
import { executeGet, executePost, truncateText, navigateContentDetail, executeMultipartPost, dataURIToBlob } from '../utilities/Utilities';
// import { SettingsIcon , PasswordIcon, AboutIcon , ShareIcon, GraphIcon } from '../assets/Icons';
import {
    IconProfilRakSaya, IconProfilArrow, IconProfilBukuTerakhir, IconProfilLogout,
    SettingsIcon, PasswordIcon, AboutIcon, ShareIcon, GraphIcon, IconRakDigital
} from '../assets/Icons';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PasswordBox from '../components/PasswordBox';
import LoadingDialog from '../components/LoadingDialog';
import WarningDialog from '../components/WarningDialog';
import SuccessDialog from '../components/SuccessDialog';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LogoutIcon from '@mui/icons-material/Logout';
import * as DocumentPicker from 'expo-document-picker';
import ComboBox from '../components/ComboBox';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import moment from 'moment';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import OptionBox from '../components/OptionBox';
import RadioBox from '../components/RadioBox';
import TextBox from '../components/TextBox';
import EducationDialog from '../components/EducationDialog';
import OccupationDialog from '../components/OccupationDialog';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LoadingPage from '../components/LoadingPage';

var _is_mounted = false;
class DesktopProfile extends Component {
    //ANCHOR - Constructor
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            showLoadingPopup: false,
            showAlert: false,
            alertMessage: "",
            showLoadingDialog: false,
            loadingDialogMessage: "",
            showSuccess: false,
            successMessage: "",
            data: this.props.data,
            containerWidth: 0,
            bookWidth: 0,
            bookHeight: 0,
            dummy_book_skeleton: [
                { i: 0 }, { i: 1 }, { i: 2 }, { i: 3 },
                { i: 4 }, { i: 5 }, { i: 6 }, { i: 7 }
            ],
            reading_history_data: [],
            borrowed_data: [],
            showEditPasswordDialog: false,
            showEditProfileDialog: true,
            errorCurrentPass: false,
            errorCurrentPassMessage: "",
            errorNewPass: false,
            errorNewPassMessage: "",
            errorRetypePass: false,
            errorRetypePassMessage: "",
            old_pass: "",
            new_pass: "",
            retype_pass: "",
            uploadingAvatar: false,
            showConfirmLogout: false,

            fullname: '',
            birthdate: new Date().toISOString().split('T')[0],
            city: "",
            cityId: "",
            formatted_birthdate: "",
            sql_date: '',
            gender: 'L',
            occupation: '',
            education: '',
            educations: [],
            occupations: [],
            identities: [],
            errorName: false,
            errorNameMessage: "Nama lengkap harap diisi",
            errorDate: false,
            errorDateMessage: "Tgl lahir harap diisi",
            errorEducation: false,
            errorEducationMessage: "Jenjang edukasi harap dipilih",
            errorOccupation: false,
            errorOccupationMessage: "Jenis pekerjaan harap dipilih",
            showEducations: false,
            selectedEducationCode: '',
            showOccupations: false,
            selectedOccupationCode: '',
            showDatePicker: false,
            regencies: [],
            showRegencies: false,
            flagLembaga: false,
            showLembaga: false,
            lembagas: [],
            lembaga: "",
            selectedLembagaCode: "",
            errorLembaga: false,
            errorLembagaMessage: "",
            selectedProvinceId: "-"
        }

        this.fetchConfig = this.fetchConfig.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
        this.getProfile = this.getProfile.bind(this);
        this.getReadingHistory = this.getReadingHistory.bind(this);
        this.getBorrowingList = this.getBorrowingList.bind(this);
        this.renderHistoryItem = this.renderHistoryItem.bind(this);
        this.handleEditPasswordClick = this.handleEditPasswordClick.bind(this);
        this.handleEditProfile = this.handleEditProfile.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
        this.handleRetypePasswordChange = this.handleRetypePasswordChange.bind(this);
        this.handleUpdateClick = this.handleUpdateClick.bind(this);
        this.contentDetail = this.contentDetail.bind(this);
        this.handleAvatarClick = this.handleAvatarClick.bind(this);
        this.executeUploadAvatar = this.executeUploadAvatar.bind(this);

        this.confirmLogout = this.confirmLogout.bind(this);
        this.handleConfirmClose = this.handleConfirmClose.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.executeLogout = this.executeLogout.bind(this);

        this.handleUpdateDataClick = this.handleUpdateDataClick.bind(this);
        this.handleCalendarClick = this.handleCalendarClick.bind(this);
        this.handleSelectedEducation = this.handleSelectedEducation.bind(this);
        this.handleSelectedOccupation = this.handleSelectedOccupation.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.handleOccupationClick = this.handleOccupationClick.bind(this);
        this.handleEducationClick = this.handleEducationClick.bind(this);
        this.handleSelectedBirthdate = this.handleSelectedBirthdate.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleCityClick = this.handleCityClick.bind(this);

        this.fetchRegencies = this.fetchRegencies.bind(this);
        this.fetchLembaga = this.fetchLembaga.bind(this);
        this.handleProvinceClick = this.handleProvinceClick.bind(this);
        this.setRegency = this.setRegency.bind(this);
    }

    componentDidMount = () => {
        _is_mounted = true;
        this.getProfile();
    }

    fetchRegencies = async () => {
        const response = await executeGet(regencies);
        console.log('fetchRegencies = ' + JSON.stringify(response))
        if (response.code == code_ok) {
            if (_is_mounted) {
                this.setState({
                    regencies: response.data
                });
            }
        } else {
            //TODO: handle errors 
        }
    }

    handleSelectedBirthdate = (value) => {
        //console.log(moment(value).format("DD/MMM/YYYY"));
        this.setState({
            formatted_birthdate: moment(value).format("DD/MMM/YYYY"),
            birthdate: moment(value).format("YYYY-MM-DD"),
            errorDate: false,
            showDatePicker: false
        })
        // this.setState({
        //     showDatePicker : false
        // })

    }

    handleSelectedEducation = (item) => {
        this.setState({
            showEducations: false,
            education: item.name,
            selectedEducationCode: item.code,
            errorEducation: false
        }, () => {
            // only now the state was updated
            console.log("Data is here", this.state.selectedEducationCode);
            console.log("Data2 is here", this.state.education);
            this.fetchLembaga();
        });

    }

    handleSelectedOccupation = (item) => {
        this.setState({
            showOccupations: false,
            occupation: item.name,
            selectedOccupationCode: item.code,
            errorOccupation: false
        })
    }
    handleRadioChange = (event) => {
        if (_is_mounted) {
            this.setState({
                gender: event.target.value
            })
        }
    }
    handleOccupationClick = () => {
        if (_is_mounted) {
            this.setState({
                showOccupations: true
            })
        }
    }
    handleEducationClick = () => {
        if (_is_mounted) {
            this.setState({
                showEducations: true
            })
        }
    }

    handleCalendarClick = () => {
        if (_is_mounted) {
            if (!this.state.showDatePicker) {
                this.setState({
                    showDatePicker: true
                })
            } else {
                this.setState({
                    showDatePicker: false
                })
            }
        }
    }

    // //ANCHOR Fetch Profile 
    // fetchProfile = async()=>{
    //     const response = await executeGet(profile); 
    //     console.log("profile = " + JSON.stringify(response))
    //     if(response.code == code_ok){
    //         this.setState({
    //             showLoading : false, 
    //             data : response.data 
    //         })
    //     } else {
    //         if(response.code == code_auth_error){
    //             // if(global.os == "ANDROID") {
    //             //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
    //             // } else {
    //             //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
    //             // }
    //             window.postMessage({ id: "sign_out"});
    //         } else {
    //             this.setState({
    //                 showLoading : false, 
    //                 showAlert : true, 
    //                 alertMessage : response.msg
    //             })
    //         }
    //     }
    // }

    handleCityClick = () => {
        console.log('handleCityClick')
        if (_is_mounted) {
            if (this.state.regencies.length > 0) {
                this.setState({
                    showRegencies: true
                })
            }
        }
    }

    componentWillUnmount = () => {
        _is_mounted = false;
    }

    //ANCHOR - Handle Password Change 
    handlePasswordChange = (text) => {
        if (_is_mounted) {
            this.setState({
                old_pass: text
            })
        }
    }

    //ANCHOR - Handle New Password Change 
    handleNewPasswordChange = (text) => {
        if (_is_mounted) {
            this.setState({
                new_pass: text
            })
        }
    }

    //ANCHOR - Handle Retyped Password Change 
    handleRetypePasswordChange = (text) => {
        if (_is_mounted) {
            this.setState({
                retype_pass: text
            })
        }
    }

    handleProvinceClick = (item) => {
        if (this.state.selectedProvinceId == item.id) {
            this.setState({
                selectedProvinceId: "-"
            })
        } else {
            this.setState({
                selectedProvinceId: item.id
            })
        }
    }

    //ANCHOR - Get Profile
    getProfile = async () => {
        const response = await executeGet(profile);
        console.log("profile = " + JSON.stringify(response))

        // formData.append("pendidikan", this.state.selectedEducationCode); 
        // formData.append("pekerjaan", this.state.selectedOccupationCode); 
        // formData.append("lembaga_id", this.state.selectedLembagaCode);

        if (response.code == code_ok) {
            this.setState({
                data: response.data,
                photo: response.data.photo,
                fullname: response.data.user.fullname,
                education: response.data.education,
                occupation: response.data.occupation,
                formatted_birthdate: response.data.user.birthdate,
                city: response.data.kota,
                cityId: response.data.kota_id,
                selectedProvinceId: response.data.provinsi_id,
                lembaga: response.data.lembaga,
                selectedEducationCode: response.data.education,
                selectedOccupationCode: response.data.occupation_code,
                selectedLembagaCode: response.data.perpus_id
                // formatted_birthdate: moment(value).format("DD/MMM/YYYY"),
            }, () => {
                // this.getReadingHistory();
                this.fetchConfig();
                this.fetchRegencies();
                this.fetchLembaga();
                // if(response.data.provinsi_id) {
                //     formData.append("provinsi_id", this.state.selectedProvinceId ); 
                //     formData.append("kota_id", this.state.cityId ); 
                //     formData.append("jenjang", this.state.selectedEducationCode ); 
                // }
            })
        } else {
            if (response.code == code_auth_error) {
                window.postMessage({ id: "sign_out" })
            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }
        }
    }

    fetchConfig = async () => {
        this.setState({
            showLoading: true,
            loadingMessage: "Memuat data"
        })
        const response = await executeGet(appConfig);
        if (response.code == code_ok) {
            let data = response.data;
            this.setState({
                showLoading: false,
                educations: data.jenjang,
                occupations: data.occupations,
                identities: data.identities
            })

        } else {
            this.setState({
                showLoading: false,
                alertShowing: true,
                alertTitle: "Error",
                alertMessage: response.msg
            });
        }
        // const response = await executeGet(appConfig);
        // if (response.code == code_ok) {

        //     this.setState({
        //         showLoading: false,
        //         educations: response.data.educations,
        //         occupations: response.data.occupations
        //     })

        // } else {
        //     if (response.code == code_auth_error) {
        //         if (global.os == "ANDROID") {
        //             JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
        //         } else {
        //             window.webkit.messageHandlers.JSBridge.postMessage({ id: Command.SHOW_TOAST, params: "Autentikasi gagal! Harap login ulang!" })
        //         }
        //         window.postMessage({ id: "sign_out" });
        //     } else {
        //         this.setState({
        //             showLoading: false,
        //             showAlert: true,
        //             alertMessage: response.msg
        //         })
        //     }
        // }
    }

    fetchLembaga = async () => {
        console.log("Fetch Lembaga = " + this.state.selectedEducationCode);
        let formData = new FormData();
        formData.append("provinsi_id", this.state.selectedProvinceId);
        formData.append("kota_id", this.state.cityId);
        formData.append("jenjang", this.state.selectedEducationCode);

        const url = getLembaga + "?provinsi_id=" + this.state.selectedProvinceId + "&kota_id=" + this.state.cityId + "&jenjang=" + this.state.selectedEducationCode;
        // const response = await executePost(getLembaga, formData); 
        console.log('fetchLembaga = ' + url)
        const response = await executeGet(url);
        console.log("fetchLembaga = " + JSON.stringify(response));
        if (response.code == code_ok) {
            if (_is_mounted) {
                this.setState({
                    flagLembaga: true,
                    lembagas: response.data
                });
            }


        } else {
            //TODO: handle errors 
        }
    }

    //ANCHOR - Get Reading History
    getReadingHistory = async () => {
        //console.log("GET READING HISTORY");
        let formData = new FormData();
        formData.append("page", 1);
        formData.append("limit", 48);
        const response = await executePost(readingHistory, formData);
        if (response.code == code_ok) {
            let show = parseInt(response.show);
            if (show > 0) {
                let data = [...response.data];
                let mod = show % 8;
                if (mod > 0) {
                    let add_factor = 8 - mod;
                    for (let i = 0; i < add_factor; i++) {
                        data.push({ dummy: i });
                    }
                }
                this.setState({
                    reading_history_data: data
                })
            }

            this.getBorrowingList();

        } else {
            if (response.code == code_auth_error) {
                window.postMessage({ id: "sign_out" })

            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }

        }
    }

    //ANCHOR - Get Borrowing List
    getBorrowingList = async () => {
        //console.log("GET BORROWING LIST");
        const response = await executeGet(borrowingList);
        if (response.code == code_ok) {

            this.setState({
                borrowed_data: response.data,
                showLoading: false
            })
        } else {
            if (response.code == code_auth_error) {
                window.postMessage({ id: "sign_out" })

            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }
        }
    }

    //ANCHOR - Render Skeleton 
    renderSkeleton = () => {
        return (
            <View style={{ flex: 1 }}>
                <View style={{
                    flexDirection: "row",
                    backgroundColor: "rgb(249, 250, 252)",
                    margin: 16
                }}>
                    {
                        //ANCHOR Skeleton Avatar container 
                    }
                    <Paper
                        style={{ borderRadius: 8 }}
                    >
                        <View style={{ flexDirection: "row", padding: 16 }}>
                            <Skeleton
                                variant="circular"
                                animation="wave"
                                width={128}
                                height={128}
                            />
                            <View style={{ marginLeft: 16, }}>
                                <Skeleton
                                    variant="rectangular"
                                    width={220}
                                    height={32}
                                    style={{ borderRadius: 8 }}
                                />
                                <Skeleton
                                    variant="rectangular"
                                    width={150}
                                    height={32}
                                    style={{ marginTop: 8, borderRadius: 8 }}
                                />
                            </View>
                        </View>
                    </Paper>
                    <Paper
                        style={{ borderRadius: 8, marginLeft: 16, flex: 1, padding: 16 }}
                    >
                        <Skeleton
                            variant="rectangular"
                            width={220}
                            height={32}
                            style={{ borderRadius: 8 }}
                        />
                    </Paper>
                </View>

                <Paper style={{ borderRadius: 8, marginTop: 8, marginLeft: 16, marginRight: 16 }}
                >
                    <View style={{ margin: 16 }}
                        onLayout={this.onLayoutChange}
                    >
                        <Skeleton
                            variant="rectangular"
                            animation="wave"
                            style={{ borderRadius: 8, marginBottom: 16 }}
                            width={220}
                            height={32}
                        />
                        <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                            {
                                this.state.dummy_book_skeleton.map((i, x) => (
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        width={this.state.bookWidth}
                                        height={this.state.bookHeight}
                                        style={{ borderRadius: 8 }}
                                    />
                                ))
                            }
                        </View>

                    </View>
                </Paper>
            </View>
        )
    }

    onLayoutChange = (e) => {
        if (_is_mounted) {
            let containerWidth = e.nativeEvent.layout.width;
            let bookWidth = (containerWidth / 8) - 16;
            let bookHeight = bookWidth + 60;
            this.setState({
                bookWidth: bookWidth,
                bookHeight: bookHeight,
                containerWidth: containerWidth
            })
        }
    }

    //ANCHOR - Render history Item
    renderHistoryItem = ({ item, index, separators }) => {
        if (!item.hasOwnProperty("dummy")) {
            return (
                <View
                    style={{
                        marginRight: 16,
                        width: this.state.bookWidth,
                        height: (this.state.bookHeight + 100),
                        borderRadius: 8
                    }}>
                    <CardActionArea
                        style={{
                            borderRadius: 8,
                            width: this.state.bookWidth,
                            height: (this.state.bookHeight + 100),
                        }}
                        onClick={() => { this.contentDetail(item, index) }}
                    >
                        <Image
                            style={{
                                width: this.state.bookWidth,
                                height: this.state.bookHeight,
                                borderRadius: 8,
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0
                            }}
                            resizeMode={"cover"}
                            source={{ uri: item.cover }}
                        />
                        <View style={{
                            position: 'absolute',
                            top: this.state.bookHeight + 8,
                            left: 8,
                            right: 8
                        }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={14}
                                fontWeight="600"
                                color={color_text}
                            >
                                {truncateText(item.title, 20)}
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={12}
                                fontWeight="400"
                                color={sub_text}
                            >
                                {truncateText(item.author, 20)}
                            </Typography>
                        </View>
                    </CardActionArea>
                </View>
            )
        } else {
            return (
                <View
                    key={"_read_" + index}
                    style={{
                        marginRight: 16,
                        width: this.state.bookWidth,
                        height: this.state.bookHeight
                    }}
                />
            )
        }
    }

    handleEditProfile = () => {
        this.setState({
            showEditProfileDialog: true
        })
    }

    //ANCHOR - Handle Edit Password Click 
    handleEditPasswordClick = () => {
        this.setState({
            showEditPasswordDialog: true
        })
    }
    //ANCHOR - Handle Update Click 
    handleUpdateClick = () => {
        if (_is_mounted) {
            if (this.state.old_pass.length == 0) {
                this.setState({
                    errorCurrentPass: true,
                    errorCurrentPassMessage: "Password lama harap diisi"
                })
                return;
            }

            let regPass = /^(?=.*\d).{8,}$/;
            if (!regPass.test(this.state.new_pass)) {
                this.setState({
                    errorNewPass: true,
                    errorNewPassMessage: 'Kata sandi minimal 8-karakter kombinasi huruf angka'
                })
                return;
            }
            if (this.state.retype_pass != this.state.new_pass) {
                this.setState({
                    errorRetypePass: true,
                    errorRetypePassMessage: 'Ketik ulang sandi baru anda'
                })
                return;
            }
            this.setState({
                showEditPasswordDialog: false
            }, () => {
                this.executeUpdate();
            })

        }

    }
    //ANCHOR - Execute update 
    executeUpdate = async () => {
        let formData = new FormData();
        formData.append("old_pass", this.state.old_pass);
        formData.append("new_pass", this.state.new_pass);
        this.setState({
            showLoadingDialog: true,
            loadingDialogMessage: "Mengganti Password"
        })

        const response = await executePost(updatePassword, formData);
        if (response.code == code_ok) {
            this.setState({
                showLoadingDialog: false,
                showSuccess: true,
                successMessage: "Password anda berhasil diganti"
            })
        } else {
            if (response.code != code_auth_error) {
                this.setState({
                    showLoadingDialog: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            } else {
                window.postMessage({ id: "sign_out" })

            }
        }

    }

    handleUpdateDataClick = () => {
        if (this.state.fullname.length == 0) {
            this.setState({
                errorName: true,
                errorNameMessage: "Nama lengkap harap diisi"
            }, () => {
                this.nameRef.current.focus();
            });
            return;
        }

        console.log('nama = ' + this.state.fullname)
        console.log('tglLahir = ' + this.state.birthdate)
        console.log('provinsi = ' + this.state.selectedProvinceId)
        console.log('kota = ' + this.state.cityId)
        console.log('pendidikan = ' + this.state.selectedEducationCode)
        console.log('pekerjaan = ' + this.state.selectedOccupationCode)
        console.log('lembaga = ' + this.state.selectedLembagaCode)

        this.executeUpdateData();
    }
    executeUpdateData = async () => {

        // console.log('nama = ' + this.state.fullname)
        // console.log('tglLahir = ' + this.state.birthdate)
        // console.log('provinsi = ' + this.state.selectedProvinceId)
        // console.log('kota = ' + this.state.cityId)
        // console.log('pendidikan = ' + this.state.selectedEducationCode)
        // console.log('pekerjaan = ' + this.state.selectedOccupationCode)
        // console.log('lembaga = ' + this.state.selectedLembagaCode)

        let formData = new FormData();
        formData.append("fullname", this.state.fullname);
        formData.append("provinsi_id", this.state.selectedProvinceId);
        formData.append("kota_id", this.state.cityId);
        formData.append("tanggal_lahir", this.state.birthdate);
        formData.append("pendidikan", this.state.selectedEducationCode);
        formData.append("pekerjaan", this.state.selectedOccupationCode);
        formData.append("lembaga_id", this.state.selectedLembagaCode);

        this.setState({
            showLoading: true,
            loadingMessage: "Memperbarui data anda"
        })

        const response = await executePost(saveLembaga, formData);
        console.log('updateProfile = ' + JSON.stringify(response))
        if (response.code == code_ok) {
            this.setState({
                showLoading: false,
                showSuccess: true,
                successMessage: "Data berhasil diupdate"
            }, () => {
                let obj = {
                    id: "update_fullname",
                    fullname: this.state.fullname
                }
                window.postMessage(obj);
                this.getProfile();
                // this.goBack(); 
            })
        } else {

            if (response.code == code_auth_error) {
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }

        }

    }

    //ANCHOR - Content Detail 
    contentDetail = (item, index) => {

        let params = {
            content_code: item.content_code
        }

        navigateContentDetail(this.props.navigation, params);
    }

    //ANCHOR - Avatar Click
    handleAvatarClick = async () => {
        let result = await DocumentPicker.getDocumentAsync({
            type: "image/*",
            copyToCacheDirectory: true
        });
        if (result.type == "success") {
            this.setState({
                uploadingAvatar: true
            })

            let nameParts = result.name.split('.');
            let fileType = nameParts[nameParts.length - 1];

            let formData = new FormData();
            formData.append("filename", result.name);
            formData.append("file", {
                uri: result.uri,
                name: result.name,
                type: "multipart/form-data",
                size: result.size
            });
            this.executeUploadAvatar(formData)
        }
    }

    executeUploadAvatar = async (formData) => {
        let contentType = { "Content-Type": "multipart/form-data" }
        let headers = { ...global.defaultHeaders, ...contentType }
        const response = await executeMultipartPost(updateAvatar, formData, headers);
        console.log(response);
        if (response.code == code_ok) {
            this.setState({
                uploadingAvatar: false,
                photo: response.url
            })
        } else {
            if (response.code != code_auth_error) {
                this.setState({
                    uploadingAvatar: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            } else {
                window.postMessage({ id: "sign_out" })
            }
        }
    }

    confirmLogout = () => {
        if (_is_mounted) {
            this.setState({
                showConfirmLogout: true
            })
        }
    }
    handleConfirmClose = () => {
        if (_is_mounted) {
            this.setState({
                showConfirmLogout: false
            })
        }
    }
    handleLogout = () => {
        if (_is_mounted) {
            this.setState({
                showConfirmLogout: false,
                showLoadingPopup: true
            }, () => {
                this.executeLogout()
            })
        }
    }
    executeLogout = async () => {
        const response = await executeGet(userSignOut);
        if (response.code == code_ok) {
            this.setState({
                showLoadingPopup: false
            }, () => {
                window.postMessage({ id: "sign_out" });
            })

        } else {
            this.setState({
                showLoadingPopup: false
            }, () => {
                window.postMessage({ id: "sign_out" });
            })
        }
    }

    handleNameChange = (text) => {
        if (_is_mounted) {
            this.setState({
                fullname: text,
                errorName: false
            })
        }
    }

    handleLembagaClick = () => {
        if (_is_mounted) {
            this.setState({
                showLembaga: true
            })
        }
    }

    handleSelectedLembaga = (item) => {
        console.log("Lembaga yang dipilih: ");
        console.log(item);
        this.setState({
            showLembaga: false,
            lembaga: item.nama_lembaga,
            selectedLembagaCode: item.id,
            errorLembaga: false
        })
    }

    setRegency = (item) => {
        // this.setState({
        //     city: item.name,
        //     cityId: item.id,
        //     showRegencies: false,
        //     errorCity: false
        // })
        // this.fetchLembaga();

        this.setState({
            city: item.name,
            cityId: item.id,
            showRegencies: false,
            errorCity: false
        }, () => {
            // only now the state was updated
            this.fetchLembaga();
        });
    }

    //ANCHOR - Render
    render() {
        return (
            <View style={styles.container}>
                <LoadingDialog
                    open={this.state.showLoadingPopup}
                    message={"Loading"}
                />
                {
                    this.state.showLoading &&
                    (this.renderSkeleton())
                }
                {
                    !this.state.showLoading &&
                    <View style={{ flex: 1, paddingLeft: 147, paddingRight: 147 }}>
                        <View style={{
                            flexDirection: "row",
                            backgroundColor: "rgb(249, 250, 252)",
                            margin: 16

                        }}>
                            {
                                //ANCHOR Avatar container 
                            }
                            <Paper
                                style={{ borderRadius: 8, flex: 0.3 }}
                            >
                                <>
                                    <View style={{ flexDirection: "row", padding: 16, alignItems: "center", marginBottom: 20 }}>
                                        {
                                            !this.state.uploadingAvatar &&
                                            <Avatar
                                                style={{ width: 48, height: 48 }}
                                                src={this.state.data.photo}
                                                onClick={this.handleAvatarClick}
                                                sx={{ cursor: "pointer" }}
                                            />
                                        }
                                        {
                                            this.state.uploadingAvatar &&
                                            <Skeleton
                                                variant="circular"
                                                animation="wave"
                                                width={48}
                                                height={48}
                                            />
                                        }
                                        <View style={{ marginLeft: 16 }}>
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={18}
                                                fontWeight="600"
                                                sx={{ color: color_text }}
                                            >
                                                {this.state.data.user.name}
                                            </Typography>
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={12}
                                                fontWeight="400"
                                                sx={{ color: sub_text }}
                                            >
                                                {this.state.data.user.email}
                                            </Typography>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: "row", alignItems: "center", padding: 16 }}
                                        onClick={this.handleEditProfile}>
                                        <IconProfilBukuTerakhir
                                            width={30}
                                            height={30}
                                            sx={{ cursor: "pointer" }} />
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={14}
                                            fontWeight={"600"}
                                            style={{ marginLeft: 16, flex: 1 }}
                                            sx={{ cursor: "pointer" }}
                                        >
                                            Edit Data
                                        </Typography>
                                    </View>
                                    <View style={{ flexDirection: "row", alignItems: "center", padding: 16 }}
                                        onClick={this.handleEditPasswordClick}>
                                        <PasswordIcon
                                            sx={{ cursor: "pointer" }} />
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={14}
                                            fontWeight={"600"}
                                            style={{ marginLeft: 16, flex: 1 }}
                                            sx={{ cursor: "pointer" }}
                                        >
                                            Ganti Password
                                        </Typography>
                                    </View>
                                    <View style={{ flexDirection: "row", alignItems: "center", padding: 16 }}
                                        onClick={()=>{ this.props.navigation.navigate("DesktopBookRack", { identifier : Identifier.MAIN })}}>
                                        <IconProfilBukuTerakhir
                                            width={30}
                                            height={30}
                                            sx={{ cursor: "pointer" }} />
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={14}
                                            fontWeight={"600"}
                                            style={{ marginLeft: 16, flex: 1 }}
                                            sx={{ cursor: "pointer" }}
                                        >
                                            Rak Buku
                                        </Typography>
                                    </View>
                                    <View style={{ flexDirection: "row", alignItems: "center", padding: 16 }}
                                        onClick={this.confirmLogout}>
                                        <IconProfilLogout
                                            width={30}
                                            height={30}
                                            sx={{ cursor: "pointer" }} />
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={14}
                                            fontWeight={"600"}
                                            style={{ marginLeft: 16, flex: 1 }}
                                            sx={{ cursor: "pointer" }}
                                        >
                                            Keluar

                                        </Typography>
                                    </View>
                                </>
                            </Paper>
                            <Paper
                                style={{ borderRadius: 8, marginLeft: 16, flex: 0.7, padding: 16 }}
                            >

                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    fontWeight="700"
                                    color={primary}
                                >
                                    Edit Data
                                </Typography>
                                <View
                                    style={{ width: "100%", backgroundColor: "#999999", height: 0.5, marginTop: 8, marginBottom: 12 }}
                                />
                                {
                                    //ANCHOR Rekam Jejak Aktivitas
                                }
                                {
                                    !this.state.showRegencies &&
                                    <View style={{ alignItems: "top" }}>
                                        <TextBox
                                            reference={this.nameRef}
                                            caption={"Nama Lengkap"}
                                            placeholder={"Masukkan nama lengkap"}
                                            containerStyle={{ marginTop: 16 }}
                                            error={this.state.errorName}
                                            value={this.state.fullname}
                                            errorMessage={this.state.errorNameMessage}
                                            onChangeText={this.handleNameChange}
                                        />
                                        {/* <RadioBox
                                        caption={"Jenis Kelamin"}
                                        containerStyle={{ marginTop: 16 }}
                                        captionA={"Laki-Laki"}
                                        captionB={"Perempuan"}
                                        valueA={"L"}
                                        valueB={"P"}
                                        value={this.state.gender}
                                        onChange={this.handleRadioChange}
                                    /> */}

                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <OptionBox
                                                reference={this.birthRef}
                                                editable={false}
                                                caption={"Tanggal Lahir"}
                                                usingIcon={true}
                                                placeholder={"Pilih tanggal lahir"}
                                                containerStyle={{ marginTop: 16 }}
                                                icon={<CalendarTodayIcon fontSize="small" />}
                                                onIconClick={this.handleCalendarClick}
                                                error={this.state.errorDate}
                                                errorMessage={this.state.errorDateMessage}
                                                value={this.state.formatted_birthdate}
                                            />
                                            {
                                                this.state.showDatePicker &&
                                                <StaticDatePicker
                                                    displayStaticWrapperAs="desktop"
                                                    inputFormat="DD/MMM/YYYY"
                                                    value={this.state.formatted_birthdate}
                                                    onChange={this.handleSelectedBirthdate}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />

                                            }
                                        </LocalizationProvider>
                                        <OptionBox
                                            reference={this.cityRef}
                                            caption={"Kota"}
                                            usingIcon={true}
                                            editable={false}
                                            placeholder={"Pilih kota"}
                                            containerStyle={{ marginTop: 16 }}
                                            value={this.state.city}
                                            icon={<ExpandMoreIcon fontSize="small" />}
                                            error={this.state.errorCity}
                                            errorMessage={this.state.errorCityMessage}
                                            onIconClick={this.handleCityClick}
                                        />
                                        <ComboBox
                                            reference={this.educationRef}
                                            caption={"Pendidikan"}
                                            usingIcon={true}
                                            editable={false}
                                            data={this.state.educations}
                                            placeholder={"Pilih pendidikan terakhir anda"}
                                            containerStyle={{ marginTop: 16 }}
                                            value={this.state.education}
                                            icon={<ExpandMoreIcon fontSize="small" />}
                                            error={this.state.errorEducation}
                                            errorMessage={this.state.errorEducationMessage}
                                            onIconClick={this.handleEducationClick}
                                            onChange={this.handleSelectedEducation}
                                        />
                                        {/* {
                                        this.state.flagLembaga &&
                                        <ComboBox
                                            reference={this.lembagaRef}
                                            caption={"Lembaga"}
                                            usingIcon={true}
                                            editable={false}
                                            data={this.state.lembagas}
                                            placeholder={"Pilih Lembaga"}
                                            containerStyle={{ marginTop: 16 }}
                                            value={this.state.lembaga}
                                            icon={<ExpandMoreIcon fontSize="small" />}
                                            error={this.state.errorLembaga}
                                            errorMessage={this.state.errorLembagaMessage}
                                            onIconClick={this.handleLembagaClick}
                                            onChange={this.handleSelectedLembaga}
                                        />
                                    } */}
                                        <ComboBox
                                            reference={this.lembagaRef}
                                            caption={"Lembaga"}
                                            usingIcon={true}
                                            editable={false}
                                            data={this.state.lembagas}
                                            placeholder={"Pilih Lembaga"}
                                            containerStyle={{ marginTop: 16 }}
                                            value={this.state.lembaga}
                                            icon={<ExpandMoreIcon fontSize="small" />}
                                            error={this.state.errorLembaga}
                                            errorMessage={this.state.errorLembagaMessage}
                                            onIconClick={this.handleLembagaClick}
                                            onChange={this.handleSelectedLembaga}
                                        />
                                        <ComboBox
                                            reference={this.occupationRef}
                                            caption={"Pekerjaan"}
                                            usingIcon={true}
                                            editable={false}
                                            placeholder={"Pilih pekerjaan anda"}
                                            containerStyle={{ marginTop: 16 }}
                                            value={this.state.occupation}
                                            icon={<ExpandMoreIcon fontSize="small" />}
                                            error={this.state.errorOccupation}
                                            data={this.state.occupations}
                                            errorMessage={this.state.errorOccupationMessage}
                                            onIconClick={this.handleOccupationClick}
                                            onChange={this.handleSelectedOccupation}

                                        />
                                        {/* <OptionBox
                                        reference={this.educationRef}
                                        caption={"Pendidikan"}
                                        usingIcon={true}
                                        editable={false}
                                        placeholder={"Pilih pendidikan terakhir anda"}
                                        containerStyle={{ marginTop: 16 }}
                                        value={this.state.education}
                                        icon={<ExpandMoreIcon fontSize="small" />}
                                        error={this.state.errorEducation}
                                        errorMessage={this.state.errorEducationMessage}
                                        onIconClick={this.handleEducationClick}
                                    />
                                    <OptionBox
                                        reference={this.occupationRef}
                                        caption={"Pekerjaan"}
                                        usingIcon={true}
                                        editable={false}
                                        placeholder={"Pilih pekerjaan anda"}
                                        containerStyle={{ marginTop: 16 }}
                                        value={this.state.occupation}
                                        icon={<ExpandMoreIcon fontSize="small" />}
                                        error={this.state.errorOccupation}
                                        errorMessage={this.state.errorOccupationMessage}
                                        onIconClick={this.handleOccupationClick}
                                    /> */}
                                        <Button
                                            variant="contained"
                                            style={{ marginTop: 24, marginBottom: 24, textTransform: "none", borderRadius: 8, padding: 10 }}
                                            onClick={this.handleUpdateDataClick}
                                        >
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={16}
                                                fontWeight="500"
                                            >
                                                Update Data
                                            </Typography>
                                        </Button>
                                        {
                                            //ANCHOR - Tanggal Terakhir membaca 
                                        }
                                        {/* <View style={{flex : 0.2, borderRightWidth : 0.2, borderColor : "#999999"}}>
                                    <Typography 
                                        fontFamily="Open Sans"
                                        fontSize={12}
                                        fontWeight="700"
                                        color={ primary }
                                    >
                                        Terakhir Membaca
                                    </Typography>
                                    <Typography 
                                        fontFamily="Open Sans"
                                        fontSize={14}
                                        fontWeight="600"
                                        sx={{ color : color_text,marginTop : 1 }}
                                    >
                                        { this.state.data.tanggal_terakhir_baca }
                                    </Typography>

                                </View>
                                {
                                    //ANCHOR - Buku Terakhir dibaca
                                }
                                <View style={{flex : 0.2, borderRightWidth : 0.2, borderColor : "#999999", paddingLeft : 16 }}>
                                    <Typography 
                                        fontFamily="Open Sans"
                                        fontSize={12}
                                        fontWeight="700"
                                        color={ primary }
                                    >
                                        Buku Terakhir
                                    </Typography>
                                    <Typography 
                                        fontFamily="Open Sans"
                                        fontSize={14}
                                        fontWeight="600"
                                        sx={{ color : color_text,marginTop : 1 }}
                                    >
                                        { this.state.data.buku_terakhir_dibaca }
                                    </Typography>
                                </View>
                                {
                                    //ANCHOR - Jumlah Buku Terbaca
                                }
                                <View style={{flex : 0.2, borderRightWidth : 0.2, borderColor : "#999999", paddingLeft : 16 }}>
                                    <Typography 
                                        fontFamily="Open Sans"
                                        fontSize={12}
                                        fontWeight="700"
                                        color={ primary }
                                    >
                                        Total Baca
                                    </Typography>
                                    <Typography 
                                        fontFamily="Open Sans"
                                        fontSize={14}
                                        fontWeight="600"
                                        sx={{ color : color_text,marginTop : 1 }}
                                    >
                                        { this.state.data.total_buku_dibaca }
                                    </Typography>
                                </View>
                                {
                                    //ANCHOR - Jumlah Buku Dipinjam
                                }
                                <View style={{flex : 0.2, borderRightWidth : 0.2, borderColor : "#999999", paddingLeft : 16 }}>
                                    <Typography 
                                        fontFamily="Open Sans"
                                        fontSize={12}
                                        fontWeight="700"
                                        color={ primary }
                                    >
                                        Buku Dipinjam
                                    </Typography>
                                    <Typography 
                                        fontFamily="Open Sans"
                                        fontSize={14}
                                        fontWeight="600"
                                        sx={{ color : color_text,marginTop : 1 }}
                                    >
                                        { this.state.data.total_buku_dipinjam }
                                    </Typography>
                                </View>
                                {
                                    //ANCHOR - Toolbox
                                }
                                <View style={{flex : 0.2, paddingLeft : 16 }}>
                                    <Button
                                        variant="outlined"
                                        style={{ textTransform : "none", borderRadius : 8, padding : 8 , marginBottom : 8 }}
                                        onClick={ this.handleEditPasswordClick }
                                    >
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={ 12 }
                                            fontWeight="700"
                                        >
                                        Edit Password
                                        </Typography>
                                    </Button>
                                    <Button
                                        variant="contained"
                                        style={{ textTransform : "none", borderRadius : 8, padding : 8 , marginBottom : 0 }}
                                        onClick={ this.confirmLogout }
                                    >
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={ 12 }
                                            fontWeight="700"
                                        >
                                        Logout
                                        </Typography>
                                    </Button>
                                </View> */}
                                    </View>
                                }
                                {
                                    this.state.showRegencies &&
                                    <View style={{ flex: 1, marginTop: 86, marginLeft: 16, marginRight: 16, marginBottom: 16 }}>
                                        <ScrollView>
                                            <List>
                                                {
                                                    this.state.regencies.map((item, index) => (
                                                        <>
                                                            <ListItemButton component="a" onClick={() => { this.handleProvinceClick(item) }}>
                                                                <ListItemIcon key={index}>
                                                                    {
                                                                        (this.state.selectedProvinceId == item.id) &&
                                                                        <ExpandMoreIcon />
                                                                    }
                                                                    {
                                                                        (this.state.selectedProvinceId != item.id) &&
                                                                        <ChevronRightIcon />
                                                                    }

                                                                </ListItemIcon>
                                                                <ListItemText primary={item.name} />

                                                            </ListItemButton>
                                                            <Collapse in={this.state.selectedProvinceId == item.id}>
                                                                <List component="div" disablePadding>
                                                                    {
                                                                        item.regencies.map((i, d) => (
                                                                            <ListItemButton key={"child_" + d} style={{ marginLeft: 8 }} onClick={() => { this.setRegency(i) }}>
                                                                                <ListItemIcon key={index}>
                                                                                    <ChevronRightIcon />
                                                                                </ListItemIcon>
                                                                                <ListItemText primary={i.name} />
                                                                            </ListItemButton>
                                                                        ))
                                                                    }
                                                                </List>
                                                            </Collapse>
                                                        </>
                                                    ))
                                                }
                                            </List>
                                        </ScrollView>
                                    </View>
                                }
                                <EducationDialog
                                    showing={this.state.showEducations}
                                    data={this.state.educations}
                                    onItemClick={this.handleSelectedEducation}
                                />
                                <OccupationDialog
                                    showing={this.state.showOccupations}
                                    data={this.state.occupations}
                                    onItemClick={this.handleSelectedOccupation}
                                />
                            </Paper>

                        </View>

                        {/* <Paper style={{borderRadius : 8, marginTop : 8, marginLeft : 16, marginRight : 16 }}
                    >
                        <View style={{margin : 16 }}
                            onLayout={this.onLayoutChange}
                        >
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={16}
                                fontWeight="700"
                                style={{marginBottom : 16 }}
                            >
                                Terakhir Dibaca
                            </Typography>
                            {
                                this.state.reading_history_data.length > 0 && 
                                <FlatList 
                                    horizontal={ true }
                                    keyExtractor={(i, x)=>"_read_idx_"+x} 
                                    data={ this.state.reading_history_data}
                                    renderItem={ this.renderHistoryItem }
                                    showsHorizontalScrollIndicator={ false }
                                />
                            }

                        </View>
                    </Paper> */}

                    </View>
                }
                {
                    //ANCHOR - Edit Password Dialog 
                }
                <Dialog
                    open={this.state.showEditPasswordDialog}
                    onClose={() => {
                        this.setState({
                            showEditPasswordDialog: false
                        })
                    }}
                    PaperProps={{
                        style: {
                            minWidth: 420
                        }
                    }}
                >
                    <DialogTitle>
                        Ganti Password Anda
                    </DialogTitle>
                    <PasswordBox
                        caption={"Password Lama"}
                        containerStyle={{ marginTop: 16, marginLeft: 16, marginRight: 16 }}
                        placeholder={"Masukkan password lama"}
                        error={this.state.errorCurrentPass}
                        errorMessage={this.state.errorCurrentPassMessage}
                        onChangeText={this.handlePasswordChange}
                    />
                    <PasswordBox
                        caption={"Password Baru"}
                        containerStyle={{ marginTop: 16, marginLeft: 16, marginRight: 16 }}
                        placeholder={"Masukkan password baru"}
                        error={this.state.errorNewPass}
                        errorMessage={this.state.errorNewPassMessage}
                        onChangeText={this.handleNewPasswordChange}
                    />
                    <PasswordBox
                        caption={"Ketik ulang password baru"}
                        containerStyle={{ marginTop: 16, marginBottom: 32, marginLeft: 16, marginRight: 16 }}
                        placeholder={"Ketik ulang password baru"}
                        error={this.state.errorRetypePass}
                        errorMessage={this.state.errorRetypePassMessage}
                        onChangeText={this.handleRetypePasswordChange}
                    />

                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({
                                    showEditPasswordDialog: false
                                })
                            }}
                        >Batal</Button>
                        <Button onClick={this.handleUpdateClick}>Ganti</Button>
                    </DialogActions>
                </Dialog>
                <LoadingDialog
                    open={this.state.showLoadingDialog}
                    message={this.state.loadingDialogMessage}
                />
                <WarningDialog
                    open={this.state.showAlert}
                    message={this.state.alertMessage}
                    caption={"OK"}
                    onClick={() => {
                        this.setState({
                            showAlert: false
                        })
                    }}
                />
                <SuccessDialog
                    open={this.state.showSuccess}
                    caption={"OK"}
                    message={this.state.successMessage}
                    onClick={() => {
                        this.setState({
                            showSuccess: false
                        })
                    }}
                />
                <Dialog
                    open={this.state.showConfirmLogout}
                    onClose={this.handleConfirmClose}
                >
                    <DialogTitle>
                        Konfirmasi
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Anda diharuskan untuk login kembali ketika menjalankan aplikasi ini.{"\r\n"}Lanjutkan?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleConfirmClose}>Batal</Button>
                        <Button onClick={this.handleLogout}>Lanjutkan</Button>
                    </DialogActions>

                </Dialog>


            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "rgb(249, 250, 252)"
    }
})


export default DesktopProfile;