import React, { Component } from 'react'; 
import { StyleSheet, View } from 'react-native'; 
import { List, ListItem, ListItemText, ListItemIcon, ListItemButton , Dialog, DialogActions, 
    DialogContent, DialogContentText, DialogTitle } from '@mui/material'; 
import TitleIcon from '@mui/icons-material/Title';


class SortDialog extends Component {
    constructor(props){
        super(props)
    }

    render(){
        return(
            <Dialog
                open={ this.props.showing } 
                onClose={ this.props.onClose }  
                style={{ width : "100%", borderRadius : 8 }}  
            >
                <DialogTitle>
                    Urut Berdasarkan    
                </DialogTitle>
                <DialogContent
                    sx={{ width : 350, padding : 0 }}
                >
                    <List>
                        <ListItem
                            sx={{ padding : 0 }}
                        >
                            <ListItemButton>
                                <ListItemIcon>
                                    <TitleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Judul A-Z" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem
                            sx={{ padding : 0 }}
                        >
                            <ListItemButton>
                                <ListItemIcon>
                                    <TitleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Judul A-Z" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </DialogContent>
            </Dialog>
        )
    }
}

export default SortDialog;