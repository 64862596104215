import React, { Component } from 'react';
import { View, StyleSheet, Image, FlatList, Dimensions, ScrollView, TextInput } from 'react-native';
import { Alert, AlertTitle, Typography, Skeleton, Card, CardActionArea, CircularProgress, IconButton, Chip } from '@mui/material';
import LoadingPage from '../components/LoadingPage';
import AlertDialog from '../components/AlertDialog';
import * as Identifier from '../constants/PageIdentifiers';
import * as Command from '../constants/Commands';
import { code_ok, code_empty, code_error, code_auth_error, sectionContent, sectionContentRecommended, eduOffline } from '../constants/api';
import { executeGet, executePost, isEven, truncateText, navigateContentDetail } from '../utilities/Utilities';
import EmptySearchPage from '../components/EmptySearchPage';
import { color_text, sub_text, primary } from '../constants/colors';
import SortIcon from '@mui/icons-material/Sort';
import SortDialog from '../components/SortDialog';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { NavSearchIcon } from '../assets/NavIcons';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


var _is_mounted = false;
var window_width;
var book_width;
var book_height;


class SectionDetailOffline extends Component {
    //ANCHOR - Constructor
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            moreLoading: false,
            loadingMessage: "",
            showAlert: false,
            alertTitle: "Perhatian",
            alertMessage: "",
            code: this.props.route.params.code,
            title: this.props.route.params.title,
            data: [],
            dummy_data: [
                { i: 0 }, { i: 1 }, { i: 2 }, { i: 3 }, { i: 0 }, { i: 1 }, { i: 2 }, { i: 3 }
            ],
            page: 1,
            total: 0,
            show: 0,
            showSortDialog: true,
            sort_type: -1,
            sort_menu: [
                { id: "001", type: 1, title: "Judul A-Z" }, { id: "002", type: 2, title: "Judul Z-A" },
                { id: "003", type: 3, title: "Penulis A-Z" }, { id: "004", type: 4, title: "Penulis Z-A" }
            ],
            search_types: [{ id: 1, alias: "umum", title: "Umum" }, { id: 2, alias: "kalimat", title: "Kalimat" }],
            searchType: "umum",
            data_sub_kategori: [],
            selected_sub_kategori: "",
            selected_kode_sub_kategori: "",
            keyword: "",
            searchMode: false,
            searchFocus: false
        }
        this.goBack = this.goBack.bind(this);
        this.renderSkeleton = this.renderSkeleton.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.fetchContent = this.fetchContent.bind(this);
        this.onImageError = this.onImageError.bind(this);
        this.fetchMoreData = this.fetchMoreData.bind(this);
        this.handleSortClick = this.handleSortClick.bind(this);
        this.handleSortMenuClick = this.handleSortMenuClick.bind(this);
        this.renderSortMenu = this.renderSortMenu.bind(this);
        this.contentDetail = this.contentDetail.bind(this);
        this.renderSubKategori = this.renderSubKategori.bind(this);
        this.handleSubKategoriClick = this.handleSubKategoriClick.bind(this);
        this.handleSearchIconClick = this.handleSearchIconClick.bind(this);
        this.onSearchInputFocus = this.onSearchInputFocus.bind(this);
        this.onSearchInputBlur = this.onSearchInputBlur.bind(this);
        this.handleSearchPress = this.handleSearchPress.bind(this);
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
        this.renderSearchTypes = this.renderSearchTypes.bind(this);
        this.handleSearchTypeClick = this.handleSearchTypeClick.bind(this);

    }

    componentDidMount = () => {

        //console.log("WIDTH : " +book_width); 
        //console.log("HEIGHT : " + book_height);
        _is_mounted = true;
        if (global.os == "ANDROID") {
            JSBridge.launchFunction(Command.SET_PAGE, Identifier.SECTION_DETAIL);    
        }
        window.addEventListener("message", (event)=>{
            //alert(JSON.stringify(event.data));
            if(event.data.id == "go_back"){
                //alert(global.currentRouteName);
                if(global.currentRouteName == Identifier.SECTION_DETAIL){
                    this.goBack(); 
                }
            }
        }); 
        this.fetchContent();
    }



    componentWillUnmount = () => {
        _is_mounted = false;
        window.removeEventListener("message", null);
    }

    goBack = () => {
        if (!this.state.searchMode) {
            this.props.navigation.navigate(Identifier.MAIN);
        } else {
            this.setState({
                searchMode: false
            })
        }
    }

    //ANCHOR - Fetch Content
    fetchContent = async () => {
        if (_is_mounted) {
            if (this.state.page == 1) {
                this.setState({
                    showLoading: true
                })
            } else {
                this.setState({
                    moreLoading: true
                })
            }

            let formData = new FormData();
            formData.append("page", this.state.page);
            formData.append("limit", 50);
            formData.append("code", this.state.code);
            if (this.state.sort_type != -1) {
                formData.append("sort_by", this.state.sort_type);
            }

            let url = eduOffline;

            const response = await executePost(url, formData);
            if (response.code == code_ok) {
                console.log('eduOffline = ' + JSON.stringify(response));
                if (parseInt(response.show) > 0) {
                    let show = parseInt(response.show) + parseInt(this.state.show);
                    let total = parseInt(response.total);
                    let data = [...this.state.data, ...response.data];

                    let data_sub_kategori;
                    if (response.hasOwnProperty("sub_category")) {
                        data_sub_kategori = [...response.sub_category];
                    } else {
                        data_sub_kategori = [...this.state.data_sub_kategori];
                    }

                    this.setState({
                        total: total,
                        show: show,
                        data: data,
                        moreLoading: false,
                        showLoading: false,
                        data_sub_kategori: data_sub_kategori
                    })
                }

                if (parseInt(response.total) == 0) {
                    this.setState({
                        showLoading: false,
                        moreLoading: false,
                        alertTitle: "Tidak ditemukan",
                        alertMessage: "Mohon maaf, data dihalaman ini tidak ditemukan"
                    })
                }
            } else {
                if (response.code == code_auth_error) {
                    window.postMessage({ id: "sign_out" });
                } else {
                    this.setState({
                        showLoading: false,
                        showAlert: true,
                        alertMessage: response.msg
                    })
                }
            }

        }
    }

    renderSkeleton = ({ item, index, separators }) => {

        return (
            <View
                style={{
                    width: '95%',
                    borderRadius: 8,
                    margin: '10px'
                }}
            >   
                <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}>
                <View
                    style={{
                        marginLeft: 16,
                        marginRight: 16
                    }}
                >
                    <Skeleton
                        style={{
                            borderRadius: 8
                        }}
                        width={book_width}
                        height={book_height}
                        variant="rectangular"
                        animation="wave"
                    />
                </View>
                <View style={{ flex: 1 }}>
                    <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "1.5rem" }}
                        style={{
                            marginLeft: 12, marginTop: 8, marginRight: 12
                        }}
                    />
                    <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{ fontSize: "1.5rem" }}
                        style={{
                            marginLeft: 12,
                            marginBottom: 12,
                            marginRight: 12
                        }}
                    />
                </View>
                </View>
            </View>
        )
    }
    renderItem = ({ item, index, separators }) => {
        let book_width_new = (window_width / 3) - 20
        let book_height_new = book_width_new + 50
        return (
            <Card
                style={{
                    width: '95%',
                    borderRadius: 8,
                    margin: '10px'
                }}
            >
                <CardActionArea
                    sx={{ height: "100%" }}
                >
                    <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}>
                        <View
                            style={{
                                borderRadius: 8,
                                marginLeft: 16,
                                marginRight: 16
                            }}
                        >
                            {/* <IconBpEdukasi /> */}
                            <Image
                                style={{
                                    width: book_width_new,
                                    height: book_height_new,
                                }}
                                resizeMode={"cover"}
                                source={{ uri: item.cover }}
                                onError={(error) => { this.onImageError(item, index) }}
                            />
                        </View>
                        <View style={{ flex: 1 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={14}
                                color={color_text}
                                fontWeight={"600"}
                            >
                                {truncateText(item.title, 32)}
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={12}
                                color={sub_text}
                                style={{ marginTop: '4px', marginRight: 30 }}
                            >
                                {truncateText(item.category, 20)}
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={12}
                                color={sub_text}
                                style={{ marginTop: '4px', marginRight: 30 }}
                            >
                                {truncateText(item.publisher, 20)}
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={12}
                                color={sub_text}
                                style={{ marginTop: '4px', marginRight: 30 }}
                            >
                                {truncateText(item.author, 20)}
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={12}
                                color={sub_text}
                                style={{ marginTop: '4px', marginRight: 30 }}
                            >
                                {truncateText(item.year, 20)}
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={12}
                                color={sub_text}
                                style={{ marginTop: '4px', marginRight: 30 }}
                            >
                                {truncateText(item.isbn, 20)}
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={12}
                                color={sub_text}
                                style={{ marginTop: '4px', marginRight: 30 }}
                            >
                                {truncateText(item.eisbn, 20)}
                            </Typography>
                            {/* <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px' }} /> */}


                        </View>
                    </View>

                    {/* <Image
                        style={{
                            width: book_width,
                            height: book_height,
                        }}
                        resizeMode={"cover"}
                        source={{ uri: item.cover }}
                        onError={(error) => { this.onImageError(item, index) }}
                    />


                    <Typography
                        fontFamily="Open Sans"
                        fontSize={14}
                        color={color_text}
                        fontWeight={"600"}
                        style={{
                            marginLeft: 12, marginTop: 8, marginBottom: 8, marginRight: 12
                        }}
                    >
                        {truncateText(item.title, 32)}
                    </Typography>
                    <Typography
                        fontFamily="Open Sans"
                        color={sub_text}
                        fontSize={12}
                        style={{
                            marginLeft: 12,
                            marginBottom: 12,
                            marginRight: 12
                        }}
                    >
                        {truncateText(item.author, 20)}
                    </Typography> */}
                </CardActionArea>
            </Card>
        )
    }
    renderSearchTypes = ({ item, index, separators }) => {
        return (
            <Chip
                style={{
                    marginRight: (index == 2 ? 16 : 8),
                    marginLeft: (index == 0 ? 16 : 0)
                }}
                variant={this.state.searchType == item.alias ? "contained" : "outlined"}
                label={item.title}
                onClick={() => { this.handleSearchTypeClick(item) }}
            />
        )
    }
    contentDetail = (item, index) => {
        let params = {
            previous: Identifier.SECTION_DETAIL,
            parameters: this.props.route.params,
            content_code: item.code
        }
        console.log("contentDetail 1 = " + JSON.stringify(this.props.route.params));
        console.log("contentDetail 2 = " + JSON.stringify(this.props.navigation));
        console.log("contentDetail 3 = " + JSON.stringify(params));
        navigateContentDetail(this.props.navigation, params);
    }
    onImageError = (item, index) => {
        if (_is_mounted) {
            let data = [...this.state.data];
            let obj = { ...data[index] };
            obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
            data[index] = obj;
            this.setState({
                data: data
            })
        }
    }

    fetchMoreData = () => {
        if (_is_mounted && !this.state.showLoading && !this.state.moreLoading) {
            if (this.state.show < this.state.total) {
                let page = parseInt(this.state.page) + 1;
                this.setState({
                    page: page
                }, () => {
                    this.fetchContent();
                })

            }
        }
    }

    handleSearchTypeClick = (item) => {
        if (_is_mounted) {
            this.setState({
                searchType: item.alias
            })
        }
    }
    handleSortMenuClick = () => {
        if (_is_mounted) {
            if (this.state.showSortDialog) {
                this.setState({
                    showSortDialog: false
                })
            } else {
                this.setState({
                    showSortDialog: true
                })
            }
        }

    }
    handleSortClick = (variant) => {
        if (_is_mounted) {
            this.setState({
                sort_type: variant,
                page: 1,
                total: 0,
                show: 0,
                data: [],
            }, () => {
                this.fetchContent();
            });

        }
    }

    //ANCHOR - Render Sort Menu
    renderSortMenu = ({ item, index, separators }) => {
        return (
            <Chip
                style={{
                    marginRight: (index == 3 ? 16 : 8),
                    marginLeft: (index == 0 ? 16 : 0)
                }}
                variant={this.state.sort_type == item.type ? "contained" : "outlined"}
                label={item.title}
                onClick={() => { this.handleSortClick(item.type) }}
            />
        )
    }
    //ANCHOR - Render Sub Kategori
    renderSubKategori = ({ item, index, separators }) => {
        return (
            <Chip
                style={{
                    marginRight: (index == (this.state.data_sub_kategori.length - 1) ? 16 : 8),
                    marginLeft: (index == 0 ? 16 : 0)
                }}
                variant={this.state.selected_kode_sub_kategori == item.code ? "contained" : "outlined"}
                label={item.title}
                onClick={() => { this.handleSubKategoriClick(item) }}
            />
        )
    }

    //ANCHOR - Handle Sub Kategori Click
    handleSubKategoriClick = (item) => {
        if (_is_mounted) {
            var kode_sub_kategori = "";
            var sub_kategori = "";

            if (item.code != this.state.selected_kode_sub_kategori) {
                kode_sub_kategori = item.code;
                sub_kategori = item.title;
            }

            this.setState({
                selected_kode_sub_kategori: kode_sub_kategori,
                selected_sub_kategori: sub_kategori,
                page: 1,
                total: 0,
                show: 0,
                data: []
            }, () => {
                this.fetchContent()
            })
        }
    }

    //ANCHOR - Handle Search Icon Click 
    handleSearchIconClick = () => {

        if (_is_mounted) {
            if (this.state.searchMode) {
                this.setState({
                    searchMode: false
                })
            } else {
                this.setState({
                    searchMode: true
                })
            }
        }
    }
    onSearchInputFocus = (e) => {
        this.setState({
            searchFocus: true
        })
    }
    onSearchInputBlur = () => {
        this.setState({
            searchFocus: false
        })
    }
    //ANCHOR - Handle Search Text Changed
    handleSearchTextChange = (text) => {
        if (_is_mounted) {
            this.setState({
                keyword: text
            })
        }
    }
    //ANCHOR - Handle Search
    handleSearchPress = (e) => {
        let key = e.nativeEvent.key;
        if (key == "Enter") {
            if (this.state.searchType == "umum") {
                this.setState({
                    page: 1,
                    data: [],
                    show: 0,
                    total: 0,
                }, () => {
                    this.fetchContent();
                });
            } else {
                if (this.state.keyword.length > 5) {
                    let params = {
                        code: this.props.route.params.code,
                        title: this.props.route.params.title,
                        selected_kode_sub_kategori: this.state.selected_kode_sub_kategori,
                        keyword: this.state.keyword,
                        origins: "CONTENT_SECTION"
                    }
                    this.props.navigation.navigate("SearchKalimat", params);
                } else {
                    this.setState({
                        showWarning: true,
                        warningMessage: "Kalimat yang dicari minimal lebih dari 5 huruf"
                    })
                }

            }
        }
    }

    render() {
        window_width = Dimensions.get("window").width;
        book_width = (window_width / 2) - 16;
        book_height = book_width + 40;

        return (
            <View style={{ flex: 1 }}>
                <View style={{
                    marginLeft: 0,
                    //marginTop : (global.os == "ANDROID" ? 16 : 48 ) ,  
                    // marginTop: (global.os == "IOS" ? 48 : 0),
                    //flex : 0.07,
                    height: 64,
                    backgroundColor: "white",
                    flexDirection: "row",
                    alignItems: "center"

                }}>

                    <IconButton
                        style={{ marginRight: 16, marginLeft: 8 }}
                        onClick={() => { this.goBack() }}
                    >
                        <ChevronLeftIcon
                            sx={{ color: "black" }}
                        />
                    </IconButton>
                    {
                        !this.state.searchMode &&
                        <>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={18}
                                fontWeight="600"
                                style={{ flex: 1 }}
                                color={"black"}
                                noWrap={true}

                            >
                                {this.props.route.params.title}
                            </Typography>

                            <IconButton
                                style={{
                                    marginRight: 8
                                }}
                                onClick={this.handleSearchIconClick}
                            >
                                <NavSearchIcon sx={{ color: "black" }} fillLogo={'black'} />
                            </IconButton>
                        </>
                    }
                    {
                        this.state.searchMode &&
                        <View style={[styles.searchBar, {
                            borderColor: (this.state.searchFocus ? primary : "#F9FAFC")
                        }]}>
                            <View style={{ flex: 0.05 }}>
                                <NavSearchIcon
                                    width={18}
                                    height={18}
                                    fillLogo={(this.state.searchFocus ? primary : "#999999")}
                                />
                            </View>
                            <TextInput
                                style={styles.searchInput}
                                onFocus={this.onSearchInputFocus}
                                onBlur={this.onSearchInputBlur}
                                value={this.state.keyword}
                                placeholder={"Pencarian"}
                                onKeyPress={this.handleSearchPress}
                                onChangeText={this.handleSearchTextChange}
                            />
                        </View>

                    }

                    <IconButton
                        style={{
                            marginRight: 16,
                        }}
                        onClick={this.handleSortMenuClick}
                    >
                        {
                            this.state.showSortDialog &&
                            <ExpandLessIcon sx={{ color: "black" }} />
                        }
                        {
                            !this.state.showSortDialog &&
                            <ExpandMoreIcon sx={{ color: "black" }} />
                        }
                    </IconButton>
                </View>
                <View style={{ flex: 1 }}>
                    {
                        (!this.state.showLoading && this.state.showSortDialog) &&
                        <View style={{ paddingBottom: 16, marginBottom: 16, backgroundColor: 'white', boxShadow: "0 4px 6px -1px rgba(0,0,0, 0.07)" }}>
                            {
                                this.state.searchMode &&
                                <FlatList
                                    horizontal={true}
                                    keyExtractor={(m, n) => "search_type_" + m.alias}
                                    data={this.state.search_types}
                                    renderItem={this.renderSearchTypes}
                                    showsHorizontalScrollIndicator={false}
                                    style={{ marginBottom: 16 }}
                                />
                            }
                            <FlatList
                                horizontal={true}
                                keyExtractor={(k, l) => "index_" + k.id}
                                data={this.state.sort_menu}
                                renderItem={this.renderSortMenu}
                                showsHorizontalScrollIndicator={false}
                                style={{ marginBottom: 16 }}
                            />
                            {
                                this.state.data_sub_kategori.length > 0 &&
                                <FlatList
                                    horizontal={true}
                                    keyExtractor={(j, k) => "sub_kat_" + j.code}
                                    data={this.state.data_sub_kategori}
                                    renderItem={this.renderSubKategori}
                                    showsHorizontalScrollIndicator={false}
                                />
                            }
                        </View>
                    }
                    {
                        (this.state.data.length == 0 && !this.state.showLoading) &&
                        <EmptySearchPage
                            title={this.state.alertTitle}
                            message={this.state.alertMessage}
                        />

                    }
                    {
                        this.state.showLoading &&
                        <FlatList
                            keyExtractor={(i, x) => "index_" + x}
                            data={this.state.dummy_data}
                            renderItem={this.renderSkeleton}
                            numColumns={2}
                            showsVerticalScrollIndicator={false}
                        />
                    }
                    {
                        (!this.state.showLoading) &&
                        <FlatList
                            keyExtractor={(item, index) => "item_index_" + index}
                            data={this.state.data}
                            renderItem={this.renderItem}
                            numColumns={1}
                            showsVerticalScrollIndicator={false}
                            onEndReachedThreshold={0.5}
                            onEndReached={this.fetchMoreData}
                            ListFooterComponent={
                                this.state.moreLoading &&
                                <View style={{ alignItems: "center", padding: 8 }}>
                                    <CircularProgress
                                        size={32}
                                    />
                                </View>
                            }
                        />
                    }
                </View>
                {
                    this.state.showAlert &&
                    <Alert
                        onClose={() => { this.goBack() }}
                        severity="error"
                    >
                        <AlertTitle>Error</AlertTitle>
                        {this.state.alertMessage}
                    </Alert>
                }


            </View>

        )
    }
}
//ANCHOR -   Styles
const styles = StyleSheet.create({
    searchBar: {
        backgroundColor: "#F9FAFC",
        borderRadius: 8,
        borderWidth: 0.2,
        padding: 8,
        flexDirection: "row",
        flex: 1
    },
    searchInput: {
        fontFamily: "Open Sans",
        fontWeight: "400",
        color: color_text,
        fontSize: 14,
        flex: 0.95,
        backgroundColor: "#F9FAFC",
        outlineColor: "#F9FAFC",
        marginLeft: 18
    },
})
export default SectionDetailOffline;