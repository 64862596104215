import React, { Component } from 'react';
import { StyleSheet, View, Image, FlatList, Dimensions } from 'react-native';
import {
    Alert, AlertTitle, Typography, Card, CardActionArea, CardContent,
    CardMedia, CircularProgress, IconButton, Skeleton, Chip
} from '@mui/material';
import { primary, color_text, sub_text } from '../constants/colors';
import { code_ok, code_empty, code_error, code_auth_error, searching } from '../constants/api';
import AlertDialog from '../components/AlertDialog';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { executeGet, executePost, isEven, truncateText, navigateContentDetail } from '../utilities/Utilities';
import * as Identifier from '../constants/PageIdentifiers';
import * as Command from '../constants/Commands';
import OptionBox from '../components/OptionBox';
import SearchIcon from '@mui/icons-material/Search';
import LoadingPage from '../components/LoadingPage';
var _is_mounted = false;

var window_width;
var book_width;
var book_height;

class DesktopSearchPage extends Component {
    //ANCHOR - Constructor
    constructor(props) {
        super(props)
        this.state = {
            showLoading: false,
            moreLoading: false,
            showAlert: false,
            alertMessage: "",
            moreLoading: false,
            errorSearch: false,
            errorSearchMessage: "",
            loadingMessage: "melakukan pencarian",
            keyword: localStorage.getItem("keywordSearchDesktop"),
            page: 1,
            total: 0,
            show: 0,
            dummy_data: [
                { i: 0 }, { i: 1 }, { i: 2 }, { i: 3 },
                { i: 0 }, { i: 1 }, { i: 2 }, { i: 3 },
                { i: 0 }, { i: 1 }, { i: 2 }, { i: 3 },
                { i: 0 }, { i: 1 }, { i: 2 }, { i: 3 },
                { i: 0 }, { i: 1 }, { i: 2 }, { i: 3 },
                { i: 0 }, { i: 1 }, { i: 2 }, { i: 3 }
            ],
            data: [],
            dataSearchAll: [],
            sort_type: "",
            sort_menu: [
                { id: "", title: "All" }, { id: "HMP-SD", title: "SD" },
                { id: "HMP-SMP", title: "SMP" }, { id: "HMP-SMA", title: "SMA" }, { id: "HMP-PERTI", title: "Perguruan Tinggi" },
                { id: "HMP-Audiobook", title: "Audiobook" }, { id: "HMP-Videobook", title: "Videobook" },
                { id: "HMP-Lokal", title: "Lokal" }, { id: "HMP-JURNAL", title: "Jurnal" },
                { id: "HMP-PRA", title: "Pra Nikah" }, { id: "HMP-NIKAH", title: "Nikah" },
                { id: "HMP-BSE", title: "BSE" }, { id: "HMP-DEPAG", title: "Departemen Agama" },
                { id: "HMP-GOLD", title: "GOLD" }
            ]
        }
        this.startSearching = this.startSearching.bind(this);
        this.executeSearching = this.executeSearching.bind(this);
        this.renderSkeleton = this.renderSkeleton.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.contentDetail = this.contentDetail.bind(this);
        this.fetchMoreData = this.fetchMoreData.bind(this);
        this.renderSortMenu = this.renderSortMenu.bind(this);
    }
    componentDidMount = () => {
        console.log("Search Page = " + this.state.keyword)
        _is_mounted = true;
        this.startSearching();
    }
    componentWillUnmount = () => {
        _is_mounted = false;
    }

    renderSortMenu = ({ item, index, separators }) => {
        return (
            <Chip
                style={{
                    marginRight: (index == 3 ? 16 : 8),
                    marginLeft: (index == 0 ? 16 : 0)
                }}
                variant={this.state.sort_type == item.id ? "contained" : "outlined"}
                label={item.title}
                onClick={() => { this.handleSortClick(item.id) }}
            />
        )
    }

    handleSortClick = (variant) => {
        if (_is_mounted) {
            this.setState({
                sort_type: variant,
                data: variant == "" ? this.state.dataSearchAll : this.state.dataSearchAll.filter(x =>
                    x.homepage_code == variant
                )
            }, () => {
                // this.fetchContent();
                // const filteredHomes = this.state.data.filter( x => 
                //     x.homepage_code == variant
                // );

            });

        }
    }

    //ANCHOR - Render Skeleton
    renderSkeleton = ({ item, index, separators }) => {
        return (
            <View
                style={{
                    width: book_width,
                    borderRadius: 8,
                    marginBottom: 16
                }}
            >
                <Skeleton
                    style={{
                        borderRadius: 8
                    }}
                    width={book_width}
                    height={book_height}
                    variant="rectangular"
                    animation="wave"
                />

                <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "1.5rem" }}
                    style={{
                        marginLeft: 12, marginTop: 8, marginRight: 12
                    }}
                />
                <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "1.5rem" }}
                    style={{
                        marginLeft: 12,
                        marginBottom: 12,
                        marginRight: 12
                    }}
                />
            </View>
        )
    }

    //ANCHOR - Render Item 
    renderItem = ({ item, index, separators }) => {
        if (!item.hasOwnProperty("dummy")) {
            return (
                <View
                    style={{
                        width: book_width,
                        height: book_height + 100,
                        borderRadius: 8,
                        marginBottom: 8,
                    }}
                >
                    <CardActionArea
                        style={{ height: (book_height + 100), borderRadius: 8 }}
                        onClick={() => { this.contentDetail(item, index) }}
                    >
                        <Image
                            style={{
                                borderRadius: 8,
                                width: book_width,
                                height: book_height,
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0
                            }}
                            resizeMode={"cover"}
                            source={{ uri: item.cover }}
                            onError={(error) => { this.onImageError(item, index) }}
                        />
                        <View style={{
                            position: 'absolute',
                            top: (book_height + 16),
                            left: 0,
                            right: 0
                        }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={14}
                                color={color_text}
                                fontWeight={"600"}
                                style={{
                                    marginLeft: 12, marginTop: 8, marginBottom: 8, marginRight: 12
                                }}
                            >
                                {truncateText(item.title, 32)}
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                color={sub_text}
                                fontSize={14}
                                style={{
                                    marginLeft: 12,
                                    marginBottom: 12,
                                    marginRight: 12
                                }}
                            >
                                {truncateText(item.author, 20)}
                            </Typography>
                        </View>
                    </CardActionArea>
                </View>

            )

        } else {
            return (
                <View
                    style={{
                        width: book_width,
                        borderRadius: 8,
                        marginBottom: 16
                    }}
                >
                </View>

            )
        }
    }

    //ANCHOR - Content Detail 
    contentDetail = (item, index) => {
        let params = {
            content_code: item.code
        }
        navigateContentDetail(this.props.navigation, params);
    }
    //ANCHOR - Start Searching
    startSearching = () => {
        this.setState({
            page: 1,
            total: 0,
            show: 0,
            showLoading: true,
            data: []
        }, () => {
            this.executeSearching();
        })
    }
    //ANCHOR - Execute Searching
    executeSearching = async () => {

        let formData = new FormData();
        formData.append("keyword", this.state.keyword);
        formData.append("page", this.state.page);

        const response = await executePost(searching, formData);
        if (response.code == code_ok) {

            if (response.show > 0) {
                let mod = parseInt(response.show) % 6;
                let data = [...this.state.data, ...response.data];
                if (mod > 0) {
                    let add_factor = 6 - mod;
                    for (let i = 0; i < add_factor; i++) {
                        data.push({ dummy: i });
                    }
                }
                let show = this.state.show + response.show;

                this.setState({
                    showLoading: false,
                    moreLoading: false,
                    total: response.total,
                    show: show,
                    data: data,
                    dataSearchAll: data
                })
            }
        } else {
            if (response.code == code_auth_error) {
                window.postMessage({ id: "sign_out" })

            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }
        }

    }

    //ANCHOR - On Image Error
    onImageError = (item, index) => {
        if (_is_mounted) {
            let data = [...this.state.data];
            let obj = { ...data[index] };
            obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
            data[index] = obj;
            this.setState({
                data: data
            })
        }
    }
    //ANCHOR - Fetch More data 
    fetchMoreData = () => {
        if (_is_mounted && !this.state.showLoading && !this.state.moreLoading) {
            if (this.state.show < this.state.total) {
                let page = parseInt(this.state.page) + 1;
                this.setState({
                    page: page
                }, () => {
                    this.executeSearching();
                })

            }
        }
    }
    //ANCHOR - Render Method
    render() {

        window_width = Dimensions.get("window").width;
        book_width = (window_width / 6) - 32;
        book_height = book_width + 60;

        return (
            <View style={{ flex: 1 }}>
                <Typography
                    fontFamily="Open Sans"
                    fontSize={16}
                    fontWeight="600"
                    style={{ marginLeft: 16, marginTop: 16, marginBottom: 16 }}
                >
                    Hasil Pencarian
                </Typography>
                <View style={{ paddingBottom: 16, marginBottom: 16, backgroundColor: 'white', boxShadow: "0 4px 6px -1px rgba(0,0,0, 0.07)" }}>
                    <FlatList
                        horizontal={true}
                        keyExtractor={(k, l) => "index_" + k.id}
                        data={this.state.sort_menu}
                        renderItem={this.renderSortMenu}
                        showsHorizontalScrollIndicator={false}
                        style={{ marginBottom: 16 }}
                    />
                </View>
                {
                    //ANCHOR - FlatList Skeleton 
                    (this.state.showLoading) &&
                    <FlatList
                        keyExtractor={(i, x) => "search_idx_" + x}
                        data={this.state.dummy_data}
                        renderItem={this.renderSkeleton}
                        numColumns={6}
                        showsVerticalScrollIndicator={false}
                        style={{ marginLeft: 16, marginRight: 16 }}
                        columnWrapperStyle={{ justifyContent: "space-between" }}

                    />
                }
                {
                    //ANCHOR - FlatList Content 
                    (!this.state.showLoading && this.state.data.length > 0) &&

                    <FlatList
                        keyExtractor={(item, index) => "content_index_" + index}
                        data={this.state.data}
                        renderItem={this.renderItem}
                        style={{ marginLeft: 16, marginRight: 16 }}
                        numColumns={6}
                        columnWrapperStyle={{ justifyContent: 'space-between' }}
                        onEndReachedThreshold={0.5}
                        onEndReached={this.fetchMoreData}
                        ListFooterComponent={
                            this.state.moreLoading &&
                            <View style={{ alignItems: "center", padding: 8 }}>
                                <CircularProgress
                                    size={32}
                                />
                            </View>
                        }
                    />
                }


            </View>
        )
    }
}


export default DesktopSearchPage; 