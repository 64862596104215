import React, { Component } from 'react';
import { View, StyleSheet, TextInput } from 'react-native';
import { primary, color_text, textbox, danger } from '../constants/colors';
import IconButton from '@mui/material/IconButton';
import { Typography, AutoComplete } from '@mui/material';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/700.css';
import "./comboBox.css";

class ComboBox extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            itemSelected: this.props.value
        }

        this.onChange = this.onChange.bind(this);
        console.log('value_combobox = ' + this.props.value)
    }

    onChange = (e) => {
        let index = e.nativeEvent.target.value;
        let data = [...this.props.data];
        let item = data[index];
        this.setState({
            itemSelected: item.code
        },()=>{
            console.log('item combobox 1= ' + index)
            console.log('item combobox 2= ' + JSON.stringify(item))
            this.props.onChange(item);
        })
    }

    render() {
        return (
            <View style={this.props.containerStyle}>
                <Typography
                    variant="h3"
                    fontWeight="400"
                    fontSize={14}
                    fontFamily="Open Sans"
                    marginLeft={0.5}
                >
                    {this.props.caption}
                </Typography>
                <View style={styles.input_container}>

                    <select
                        ref={this.props.reference}
                        style={{
                            borderWidth: 0,
                            padding: 12,
                            borderRadius: 8,
                            width: "100%",
                            fontFamily: "Open Sans"
                        }}
                        // defaultValue={this.props.value}
                        // value='SD'
                        onChange={this.onChange}
                    >
                        <optgroup>
                            <option
                                className="item"
                                key={0}
                                value={0}
                                style={{ fontFamily: "Open Sans", fontSize: 16 }}
                            >
                                {"Pilih"}
                            </option>
                            {

                                this.props.data.length > 0 &&
                                this.props.data.map((item, index) => (
                                    <>
                                        {
                                            this.props.value !== null ?
                                                <>
                                                {/* this.state.itemSelected === item.name && */}
                                                    {
                                                        item.name && 
                                                        <option
                                                            className="item"
                                                            key={index}
                                                            value={index}
                                                            style={{ fontFamily: "Open Sans", fontSize: 16 }}
                                                            selected={ this.state.itemSelected === item.name ? true : false}
                                                        >
                                                            {item.name}
                                                        </option>
                                                    }

                                                    {
                                                        item.nama_lembaga &&
                                                        <option
                                                            className="item"
                                                            key={index}
                                                            value={index}
                                                            style={{ fontFamily: "Open Sans", fontSize: 16 }}
                                                            selected={ this.state.itemSelected === item.nama_lembaga ? true : false}
                                                        >
                                                            {item.nama_lembaga}
                                                        </option>
                                                    }
                                                </>
                                                : <>
                                                    {
                                                        item.name &&
                                                        <option
                                                            className="item"
                                                            key={index}
                                                            value={index}
                                                            style={{ fontFamily: "Open Sans", fontSize: 16 }}
                                                        >
                                                            {item.name}
                                                        </option>
                                                    }

                                                    {
                                                        item.nama_lembaga &&
                                                        <option
                                                            className="item"
                                                            key={index}
                                                            value={index}
                                                            style={{ fontFamily: "Open Sans", fontSize: 16 }}
                                                        >
                                                            {item.nama_lembaga}
                                                        </option>
                                                    }
                                                </>
                                        }

                                    </>

                                ))
                            }
                        </optgroup>
                    </select>

                </View>
                {
                    this.props.error &&
                    <View style={{ flexDirection: "row-reverse" }}>
                        <Typography
                            variant="body1"
                            fontFamily="Open Sans"
                            fontSize={14}
                            color={danger}
                        >
                            {this.props.errorMessage}
                        </Typography>
                    </View>

                }
            </View>

        )
    }
}

const styles = StyleSheet.create({
    input_container: {
        backgroundColor: "white",
        borderRadius: 8,
        width: "100%",
        justifyContent: "center",
        marginTop: 8,
        paddingRight: 16
    },
    input: {
        fontFamily: "Open Sans",
        fontSize: 14,
        padding: 12,
        flex: 1,
        borderRadius: 8,
        outlineColor: primary
    }
})

export default ComboBox;