import React, { Component } from 'react'; 
import { View, StyleSheet , Image, FlatList, Dimensions, ScrollView,TextInput  } from 'react-native'; 
import { Alert, AlertTitle, Typography, Skeleton, Card, CardActionArea , Button,
    CircularProgress, IconButton, Chip, Radio, RadioGroup, FormControlLabel } from '@mui/material'; 
import LoadingPage from '../components/LoadingPage'; 
import AlertDialog from '../components/AlertDialog'; 
import * as Identifier from '../constants/PageIdentifiers';
import * as Command from '../constants/Commands'; 
import { code_ok, code_empty, code_error, code_auth_error, sectionContent, 
    sectionContentRecommended, readContent } from '../constants/api';
import { executeGet, executePost , isEven, truncateText, navigateContentDetail } from '../utilities/Utilities'; 
import  EmptySearchPage  from '../components/EmptySearchPage';
import { color_text, sub_text, primary } from '../constants/colors';
import SortIcon from '@mui/icons-material/Sort';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SortDialog from '../components/SortDialog';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SearchIcon from '../assets/images/SearchIcon';
import { NavSearchIcon } from '../assets/NavIcons';
import LoadingDialog from '../components/LoadingDialog';

var _is_mounted = false; 
var window_width;
var book_width;
var book_height;

//KHUSUS UNTUK SEARCHING KALIMAT 
class DesktopSearchKalimat extends Component {
    constructor(props){
        super(props)
        this.state = {
            showLoading : true, 
            moreLoading : false, 
            loadingMessage : "", 
            showAlert : false,
            alertTitle : "Perhatian", 
            alertMessage : "",
            showWarning : false, 
            warningMessage : "",  
            code : this.props.route.params.code,
            title : this.props.route.params.title,
            data : [], 
            dummy_data : [
                {i : 0}, { i : 1 }, {i : 2 }, { i : 3 }, 
                {i : 0}, { i : 1 }
            ],
            page : 1, 
            total : 0, 
            show : 0, 
            showSortDialog : true,
            sort_type : -1,
            sort_menu : [
                {id : "001", type: 1, title : "Judul A-Z"},{id : "002", type : 2, title : "Judul Z-A"}
            ],
            data_sub_kategori :[] ,
            selected_sub_kategori : "",  
            selected_kode_sub_kategori : this.props.route.params.selected_kode_sub_kategori,
            keyword : this.props.route.params.keyword,
            searchFocus : false,
            showLoadingDialog : false, 
            loadingDialogMessage : ""
        }
        this.goBack = this.goBack.bind(this); 
        this.onSearchInputFocus = this.onSearchInputFocus.bind(this);
        this.onSearchInputBlur = this.onSearchInputBlur.bind(this);
        this.handleSearchPress = this.handleSearchPress.bind(this); 
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this); 

        this.renderSkeleton = this.renderSkeleton.bind(this);
        this.renderItem = this.renderItem.bind(this); 
        this.fetchContent = this.fetchContent.bind(this); 
        this.onImageError = this.onImageError.bind(this); 
        this.fetchMoreData = this.fetchMoreData.bind(this); 
        this.handleSortClick = this.handleSortClick.bind(this); 
        this.handleSortMenuClick = this.handleSortMenuClick.bind(this); 
        this.renderSortMenu = this.renderSortMenu.bind(this);
        this.directRead = this.directRead.bind(this); 
        
        

    }
    componentDidMount = ()=>{
        _is_mounted = true;
        this.setState({
            code : this.props.route.params.code,
            title : this.props.route.params.title,
            selected_kode_sub_kategori : this.props.route.params.selected_kode_sub_kategori,
            keyword : this.props.route.params.keyword
        },()=>{
            this.fetchContent();
        })

    }

    componentWillUnmount = ()=>{
        _is_mounted = false
    }

    handleSearchTextChange = (text)=>{
        if(_is_mounted){
            this.setState({
                keyword : text
            })
        }
    }
    handleSearchPress = (e)=>{
        let key = e.nativeEvent.key; 
        if(key == "Enter"){
            this.setState({
                page : 1 , 
                data : [], 
                show : 0, 
                total : 0,
            }, ()=>{
                this.fetchContent();
            });
        }
    }
    onSearchInputFocus = (e)=>{
        this.setState({
             searchFocus : true
        })
    }
    onSearchInputBlur = ()=>{
        this.setState({
            searchFocus : false
        })
    }
    goBack = ()=>{
        this.props.navigation.goBack(); 
    }
    //ANCHOR - Render Skeleton
    renderSkeleton = ({item, index, separators})=>{
        
        return(
            <View
                style={{ 
                    flexDirection : "row", 
                    alignItems : "center", 
                    borderRadius : 8, 
                    marginBottom : 16  ,
                    flex : 1                      
                }}
            >
                <Skeleton 
                    style={{
                        borderRadius : 8 
                    }}
                    width={ book_width }
                    height={ book_height }
                    variant="rectangular"
                    animation="wave"
                />
                <View style={{flex : 1, height : "100%" }} >
                    <Skeleton 
                        variant="text"
                        animation="wave"
                        sx={{ fontSize : "2rem"}}
                        style={{ 
                            marginLeft : 12, marginTop : 8, marginRight : 12 
                        }}
                    />
                    <Skeleton 
                        variant="text"
                        animation="wave"
                        sx={{ fontSize : "2rem"}}
                        style={{
                            marginLeft : 12 ,
                            marginBottom : 12, 
                            marginRight : 12
                        }}
                    />
                    <Skeleton 
                        variant="text"
                        animation="wave"
                        sx={{ fontSize : "2rem"}}
                        style={{
                            marginLeft : 12 ,
                            marginBottom : 12, 
                            marginRight : 12
                        }}
                    />
                </View>
            </View>
        )
    }

    //ANCHOR - Render Item
    renderItem = ({item, index, separators})=>{
        console.log(JSON.stringify(item));
        if(!item.hasOwnProperty("dummy")) {
            return(
                <View
                    style={{ 
                        flex : 1,   
                        borderRadius : 8, 
                        marginBottom : 8,
                    }}
                >
                    <CardActionArea
                        style={{ borderRadius : 8, flexDirection : "row" }}
                        
                    >
                        <View style={{ flexDirection : "row", padding : 8 }} >
                            <Image
                                style={{
                                    borderRadius : 8,
                                    width : book_width,
                                    height : book_height,
                                }}
                                resizeMode={"cover"}
                                source={{ uri : item.cover }}
                                onError={(error)=>{ this.onImageError(item, index)}}
                            />
                            <View style={{
                                flex : 1,
                                
                            }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 14 }
                                    color={ color_text } 
                                    fontWeight={"600"}
                                    style={{ 
                                        marginLeft : 12, marginTop : 8, marginBottom : 8, marginRight : 12 
                                    }}
                                >
                                    { truncateText(item.judul, 32)  }
                                </Typography>
                                <Typography
                                    fontFamily="Open Sans"
                                    color={ primary }
                                    fontSize={ 14 }
                                    style={{
                                        marginLeft : 12 ,
                                        marginBottom : 12, 
                                        marginRight : 12
                                    }}
                                >
                                    Halaman : { item.hal_idx } 
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color={ color_text }
                                    fontSize={ 12 }
                                    style={{ marginLeft : 12 }}
                                >
                                    { item.hal_txt }
                                </Typography>
                                <View style={{flex : 1, flexDirection : "column-reverse"}}>
                                    <View style={{ flexDirection : "row-reverse", alignItems : "center"}}>
                                        <Button
                                            variant="contained"
                                            style={{ textTransform : "none", borderRadius : 25, marginRight : 0, 
                                            padding : 8, minWidth: 120  }}
                                            onClick={()=>{ this.directRead(item)}}
                                        >
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={ 14 }
                                                fontWeight="500"
                                            >
                                            Baca
                                            </Typography>
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            style={{ textTransform : "none", borderRadius : 25, marginRight : 16, 
                                            padding : 8, minWidth: 120  }}
                                            onClick={()=>{ this.contentDetail(item, index)}}
                                        >
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={ 14 }
                                                fontWeight="500"
                                            >
                                            Detail
                                            </Typography>
                                        </Button>
                                    </View>
                                </View>

                            </View>
                        </View>
                    </CardActionArea>
                </View>
            )
        } else {
            return(
                <View
                    style={{ 
                        width : book_width, 
                        height : book_height + 100,
                        borderRadius : 8, 
                        marginBottom : 8,
                    }}
                >
                </View>
            )
        }
    }
    //ANCHOR - Content Detail
    contentDetail = (item,index)=>{
        //console.log(JSON.stringify(item));
        let params = {
            content_code : item.kode_konten
        }
        navigateContentDetail(this.props.navigation, params); 
    }
    //ANCHOR - On Image Error
    onImageError = (item, index)=>{
        if(_is_mounted){
            let data = [...this.state.data]; 
            let obj = { ...data[index]}; 
            obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
            data[index] = obj; 
            this.setState({
                data : data
            })
        }
    }
    //ANCHOR - Fetch Content
    fetchContent = async()=>{
        if(_is_mounted){
            if(this.state.page == 1) {
                this.setState({
                    showLoading : true 
                })
            } else {
                this.setState({
                    moreLoading : true 
                })
            }

            let formData = new FormData(); 
            formData.append("page", this.state.page);
            formData.append("limit", 48);
            formData.append("code", this.state.code);
            if(this.state.sort_type != -1) {
                formData.append("sort_by", this.state.sort_type); 
            }   
            let url;
            if(this.props.route.params.origins == "CONTENT_SECTION") {
                url = sectionContent; 
                if(this.state.selected_kode_sub_kategori != "") {
                    formData.append("code_sub_category", this.state.selected_kode_sub_kategori); 
                }
                formData.append("keyword", this.state.keyword);    
            } else {
                url = sectionContentRecommended; 
            }
            const response = await executePost(url, formData); 
            
            if(response.data_searchingKalimat.code == code_ok){
                //console.log(JSON.stringify(response));
                if(parseInt(response.data_searchingKalimat.total_show) > 0 ) {
                    let show = parseInt(response.data_searchingKalimat.total_show ) + parseInt(this.state.show); 
                    let total = parseInt(response.data_searchingKalimat.total_rows); 
                    let mod = response.data_searchingKalimat.total_show % 2; 
                    let data = [...this.state.data, ...response.data_searchingKalimat.data]; 
                    if(mod > 0 ) {
                        let add_factor = (2 - mod); 
                        for(let i = 0;i < add_factor;i++){
                            //added_data.push({ dummy : i }); 
                            data.push({ dummy : i })
                        }
                    }
                    //let data = [...this.state.data, ...response.data]; 
                    // let data_sub_kategori;
                    // if(response.hasOwnProperty("sub_category")) {
                    //     data_sub_kategori = [...response.sub_category]; 
                    // } else {
                    //     data_sub_kategori = [...this.state.data_sub_kategori];
                    // }

                    this.setState({
                        total : total, 
                        show : show , 
                        data : data ,
                        moreLoading : false, 
                        showLoading : false,
                        //data_sub_kategori : data_sub_kategori

                    })
                } 
                
                if(parseInt(response.data_searchingKalimat.total_rows) == 0) {
                    this.setState({
                        showLoading : false, 
                        moreLoading : false, 
                        alertTitle : "Tidak ditemukan", 
                        alertMessage : "Mohon maaf, data dihalaman ini tidak ditemukan"
                    })
                }
            } else {
                if(response.data_searchingKalimat.code == code_auth_error){
                    // if(global.os == "ANDROID"){
                    //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                    // } else {
                    //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                    // }
                    window.postMessage({ id: "sign_out"});
                } else {
                    this.setState({
                        showLoading : false, 
                        showAlert : true, 
                        alertMessage : response.data_searchingKalimat.msg
                    })
                }
            }

        }
    }
    //ANCHOR - Fetch More Data 
    fetchMoreData = ()=>{
        if(_is_mounted && !this.state.showLoading && !this.state.moreLoading){
            if(this.state.show < this.state.total ) {
                let page = parseInt(this.state.page) + 1; 
                this.setState({
                    page : page
                }, ()=>{
                    this.fetchContent(); 
                })

            }
        }
    }
    handleSortMenuClick = ()=>{
        if(_is_mounted){
            if(this.state.showSortDialog){
                this.setState({
                    showSortDialog : false
                })
            } else {
                this.setState({
                    showSortDialog : true
                })
            }
        }
        
    }

    handleSortClick = (variant)=>{
        if(_is_mounted){
            this.setState({
                sort_type : variant,
                page : 1, 
                total : 0, 
                show : 0,
                data : [],
            },()=>{
                this.fetchContent();
            }); 
        }
    }
    
    renderSortMenu = ({ item, index, separators})=>{
        return(
            <Chip 
                style={{ 
                    marginRight : (index == 3 ? 16 : 8) ,
                    marginLeft : (index == 0 ? 16 : 0)
                }}
                variant={ this.state.sort_type == item.type ? "contained" : "outlined"} 
                label={ item.title } 
                onClick={()=>{ this.handleSortClick(item.type )}}
            />
        )
    }

    directRead = async(item)=>{
        let code = item.kode_konten; 
        let formData = new FormData(); 
        formData.append("code", code); 
        this.setState({
            showLoadingDialog : true, 
            loadingDialogMessage : "Harap Tunggu"
        })


        let response = await executePost(readContent, formData);
        if(response.code == code_ok){
            this.setState({
                showLoadingDialog : false, 
            },()=>{
                let data = response.data; 
                data.mode = 'read';
                data.keyword = this.state.keyword;
                data.directIndex = item.hal_idx;
                this.props.navigation.navigate("ReaderDesktop", data); 
            })
        } else {
            if(response.code != code_auth_error){
                this.setState({
                    showLoadingDialog : false,
                    showAlert : true, 
                    alertMessage : response.msg
                })
            } else {
                window.postMessage({ id : "sign_out"})
            }
        }


    }
    render(){
        window_width = Dimensions.get("window").width; 
        book_width = (window_width / 8) - 16 ; 
        book_height = book_width + (book_width * 0.3);
        
        return(
            <View style={{ flex : 1 }}>
                
                <View style={{
                    marginLeft : 16 , 
                    marginTop : 16 ,  
                    marginBottom : 16 ,
                    flex : 0.05,
                    backgroundColor : "#F2F2F2",
                    flexDirection : "row", 
                    alignItems : "center"
                }}>
                    <IconButton
                        style={{ marginRight : 16 }}
                        onClick={()=>{ this.goBack() }}
                    >
                        <ChevronLeftIcon 
                            sx={{color : color_text }}
                        />
                    </IconButton>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 18 }
                        fontWeight="600"
                        style={{ flex : 0.7}}
                    >
                        Hasil Pencarian Kalimat
                    </Typography>
                    {
                        //ANCHOR - Search bar
                    }
                    <View style={ [styles.searchBar,{
                        borderColor : (this.state.searchFocus ? primary : "#F9FAFC"),
                        marginRight : 16
                    }]}>
                        <View style={{ flex : 0.05}}>
                            <NavSearchIcon
                                width={ 18 }
                                height={ 18 } 
                                fillLogo={ (this.state.searchFocus ? primary : "#999999") }
                            />
                        </View>
                        <TextInput 
                            style={styles.searchInput }
                            onFocus={this.onSearchInputFocus }
                            onBlur={ this.onSearchInputBlur }
                            placeholder={"Pencarian"}
                            defaultValue={ this.state.keyword }
                            onKeyPress={ this.handleSearchPress }
                            onChangeText={ this.handleSearchTextChange }
                        />
                    </View>
                </View>
                <View style={{ flex : 0.95}}>
                    {
                        (!this.state.showLoading && this.state.showSortDialog) && 
                        <View style={{marginBottom : 16 }}>
                            <FlatList 
                                horizontal={ true }
                                keyExtractor={(k, l)=>"index_"+k.id}
                                data={ this.state.sort_menu }
                                renderItem={ this.renderSortMenu }
                                showsHorizontalScrollIndicator={ false }
                                style={{ marginBottom : 16 }}
                            />
                        </View>
                    }
                    {
                        (this.state.data.length == 0 && !this.state.showLoading ) && 
                        <EmptySearchPage 
                            title={ this.state.alertTitle }
                            message={ this.state.alertMessage }
                        />
                        
                    }
                    {
                        this.state.showLoading && 
                        <FlatList 
                            keyExtractor={(i, x)=>"index_"+x}
                            data={ this.state.dummy_data } 
                            style={{marginLeft : 16 , marginRight : 16 }}
                            renderItem={ this.renderSkeleton }
                            numColumns={ 2 }
                            columnWrapperStyle={{justifyContent: 'space-between'}} 
                        />
                        
                    }
                    {
                        (!this.state.showLoading) && 
                        <FlatList 
                            keyExtractor={(item , index)=>"item_index_"+index}
                            data={ this.state.data }
                            renderItem={ this.renderItem }
                            style={{marginLeft : 16 , marginRight : 16 }}
                            numColumns={ 2 }
                            columnWrapperStyle={{justifyContent: 'space-between'}} 
                            onEndReachedThreshold={0.5}
                            onEndReached={this.fetchMoreData }
                            ListFooterComponent={
                                this.state.moreLoading && 
                                <View style={{alignItems : "center", padding : 8 }}>
                                    <CircularProgress 
                                        size={ 32 }
                                    />
                                </View>
                            }
                        />
                    }
                </View>
                {
                    this.state.showAlert && 
                    <Alert 
                        onClose={()=>{ this.goBack() }}
                        severity="error"
                    > 
                        <AlertTitle>Error</AlertTitle>
                        { this.state.alertMessage }
                    </Alert>
                }
                <LoadingDialog
                    open={ this.state.showLoadingDialog }
                    message={ this.state.loadingDialogMessage}
                />
            </View>
        )
    }

}

const styles = StyleSheet.create({
    searchBar : {
        backgroundColor : "#F9FAFC", 
        borderRadius : 8, 
        borderWidth : 0.2,
        padding : 8, 
        flexDirection : "row",
        flex : 0.3
    },
    searchInput : {
        fontFamily : "Open Sans", 
        fontWeight : "400",
        color : color_text,
        fontSize : 14,
        flex : 0.95, 
        backgroundColor : "#F9FAFC",
        outlineColor : "#F9FAFC",
        marginLeft : 18
    },
})

export default DesktopSearchKalimat; 