import React, { Component } from 'react';
import { StyleSheet, View, FlatList, Image, Dimensions } from 'react-native'; 
import { code_ok, code_error, code_auth_error, code_empty , homepageCategories } from '../constants/api';
import { Skeleton, Typography, Chip, Button } from '@mui/material'; 
import { executePost , truncateText, navigateContentDetail } from '../utilities/Utilities';
import AlertDialog from '../components/AlertDialog';
import { color_text, sub_text, primary } from '../constants/colors';
import * as Identifier from '../constants/PageIdentifiers';
import WarningDialog from '../components/WarningDialog';
import * as Command from '../constants/Commands';

class DesktopHomepageContentCategories extends Component {
    constructor(props){
        super(props)
        this.state = {
            showLoading : true, 
            showAlert : false, 
            alertMessage : "",  
            code : this.props.code, 
            title : this.props.title, 
            dummy_data : [
                {i : 0} , { i : 1 }, { i : 2 } , 
                { i : 3 } , {i : 0} , { i : 1 },
                { i : 2 } , { i : 3 } , {i : 0} , { i : 1 }, { i : 2 } ,  
                { i : 3 } 
            ],
            data : []
        }

        this.renderSkeleton = this.renderSkeleton.bind(this);
        this.renderItem = this.renderItem.bind(this); 
        this.fetchHomepageCategories = this.fetchHomepageCategories.bind(this); 
        this.handleAlertClose = this.handleAlertClose.bind(this); 
        this.handleClick = this.handleClick.bind(this); 
        this.handleMoreClick = this.handleMoreClick.bind(this); 
    }
    componentDidMount = ()=>{
        this.fetchHomepageCategories();
    }
    renderSkeleton = ({item ,index, separators})=>{
        return(
            <Skeleton 
                variant="rectangular"
                sx={{width : 120, height : 40 , borderRadius : 2 }}
                animation="wave"
                style={{
                    marginLeft : (index == 0 ? 16 : 8 ),
                    marginRight : (index == 3 ? 16 : 8 )
                }}
            />
        )
    }

    //ANCHOR - Render Item
    renderItem = ({item, index, separators})=>{
        return(
            <View style={{
                marginLeft : (index == 0 ? 16 : 0 ), 
                marginRight : (index == (this.state.data.length - 1 ) ? 16 : 8 )
            }}>
                <Button
                    variant="contained"
                    style={{
                        textTransform : "none", 
                        borderRadius : 15, 
                        padding : 10,
                        marginBottom : 8 
                    }}
                    sx={{ minWidth : 140 }}
                    onClick={()=>{this.handleClick(item.title, item.code )}}
                >
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 14 }
                        fontWeight="500"
                    >
                        { item.title}
                    </Typography>
                </Button>
            </View>
        )
    }
    fetchHomepageCategories = async()=>{
        let formData = new FormData(); 
        formData.append("homepage_code", this.props.code ); 
        formData.append("page", 1); 
        formData.append("limit", 12); 

        const response = await executePost(homepageCategories, formData); 
        if(response.code == code_ok){
            
            this.setState({
                showLoading : false, 
                data : response.data
            })
        } else {
            if(response.code != code_auth_error){
                this.setState({
                    showLoading : false, 
                    showAlert : true, 
                    alertMessage : response.msg 
                })
            } else {
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out"});
            }
        }
    }
    handleAlertClose = ()=>{

    }
    handleClick = (title, code)=>{
        this.props.navigation.navigate("DesktopContentCategoryPage",{ code : code, title : title })
    }
    handleMoreClick = (code)=>{
        this.props.navigation.navigate("DesktopCategoryPage", { identifier : Identifier.MAIN_DESKTOP})
        //this.props.navigation.navigate("CategoryPage", { identifier : Identifier.MAIN})
    }
    render(){
        return(
            <View style={{width : "100%", marginBottom : 16 }}>
            {
                this.state.showLoading && 
                <View>
                    <Skeleton 
                        variant="text"
                        sx={{fontSize : '2rem', maxWidth : 180 }}
                        style={{marginLeft : 16 , marginBottom : 8 , marginRight : 16,  marginTop : 16 }} 
                    />
                    <FlatList
                        showsHorizontalScrollIndicator={ false }
                        keyExtractor={(i, x)=>"skeleton_categories_"+ x }
                        data={ this.state.dummy_data}
                        renderItem={ this.renderSkeleton }
                        horizontal={ true }
                    />
                </View>
            }
            {
                !this.state.showLoading && 
                <View style={{ marginTop : 16 }}>
                    <View style={{flexDirection : "row", alignItems : "center", marginBottom : 8 }}>
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={ 16 }
                            variant="h3"
                            style={{flex : 1, marginLeft : 16 }}
                            fontWeight={"700"}
                            color={ color_text }
                        >
                            { this.props.title  }
                        </Typography>    
                        <Button
                            style={{textTransform : "none", marginRight : 16  }}
                            onClick={()=>{ this.handleMoreClick(this.state.code )}}
                        >
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 14 }
                                fontWeight="500"
                                color={ primary }
                            >
                            selengkapnya
                            </Typography>
                        </Button>
                    </View>
                    <FlatList 
                        showsHorizontalScrollIndicator={false}
                        horizontal={ true }
                        keyExtractor={(item, index)=>"category_code_"+item.code } 
                        data={ this.state.data }
                        renderItem={ this.renderItem }
                    />
                </View>
            }
            </View>
        )
    }
}

export default DesktopHomepageContentCategories; 