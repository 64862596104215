import React, { Component } from 'react'; 
import { StyleSheet, View, Image, FlatList, Dimensions , ScrollView } from 'react-native'; 
import { Typography, Button , IconButton} from '@mui/material'; 
import * as Identifier from '../constants/PageIdentifiers'; 
import * as Command from '../constants/Commands';
import { primary , color_text, sub_text } from '../constants/colors';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { CalendarIcon, TimeIcon } from '../assets/Icons';
import { executeGet, executePost, getQueryStrings } from '../utilities/Utilities';
import Plyr from "plyr-react";
import "plyr-react/plyr.css"
import { code_ok, code_error, code_auth_error, code_empty , widgetTutorialEdukasi } from '../constants/api';

var _is_mounted = false;
class DetailEdukasi extends Component {
    constructor(props){
        super(props)
        this.state = {
            showLoading : false, 
            showAlert : false,
            alertMessage : "",  
            title : "", 
            code : "", 
            description : "", 
            image : "", 
            date : "", 
            time : ""
        }

        this.goBack = this.goBack.bind(this); 
    }

    
    componentDidMount = ()=>{
        if (global.os == "ANDROID") {
            JSBridge.launchFunction(Command.SET_PAGE, Identifier.DETAIL_EDUKASI);    
        }
        _is_mounted = true;
        window.addEventListener("message", (event)=>{
            
            if(event.data.id == "go_back"){
                if(global.currentRouteName == Identifier.DETAIL_EDUKASI){
                    this.goBack(); 
                }
            }
        });
        console.log("DETAIL_EDUKASI = " + this.props.route.params);
        this.fetchContents(); 
    }

    fetchContents = async()=>{
        
        const response = await executePost(widgetTutorialEdukasi + '/detail/' + this.props.route.params.id); 
        if(response.code == code_ok){
            _is_mounted = true;
            console.log("EDUKASI ==========");
            console.log(JSON.stringify(response));
            this.setState({
                showLoading : false, 
                data_utama: response.data,
                data : response.data,
                videoSrc : {
                    type : "video", 
                    sources : [
                        {
                            src : response.data[0].full_url
                        }
                    ]
                }
            })
        } else {
            _is_mounted = true;
            if(response.code != code_auth_error){
                this.setState({
                    showLoading : false, 
                    alertMessage : response.msg, 
                    showAlert : true
                })
            } else {
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out"});
            }
        }   
    }

    componentWillUnmount = ()=>{
        _is_mounted = false;
        window.removeEventListener("message", null); 
    }

    goBack = ()=>{
        /*
        if(this.props.route.params.identifier == Identifier.MAIN) {
            this.props.navigation.navigate(Identifier.MAIN); 
        } else {
            this.props.navigation.navigate(Identifier.NEWS, { identifier : Identifier.MAIN });
        }*/
        // this.props.navigation.goBack(); 
        let paramLocal = localStorage.getItem('param1');
        this.props.navigation.navigate("SectionEdukasi", paramLocal); 
    }

    render(){
        const image_width = Dimensions.get("window").width ; 
        const image_height = image_width - (image_width * 0.50) ;  
        
        return(
            <>
            { _is_mounted && 
            <View style={{flex : 1, backgroundColor : primary }}>
                <View style={{
                    height : 56 , 
                    flexDirection : "row", 
                    alignItems : "center", 
                    backgroundColor : "white" ,
                    padding : 12,
                    // marginTop: (global.os == "IOS" ? 48 : 0),
                    boxShadow: "0 4px 6px -1px rgba(0,0,0, 0.07)"
                }}>
                    <IconButton
                        onClick={()=>{ this.goBack() }}
                    >
                        <ChevronLeftIcon 
                            sx={{color : "black"}}
                        />
                    </IconButton>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 18 }
                        color="black"
                        fontWeight={"500"}
                        style={{ marginLeft : 15 }}
                    >
                        Detail Tutorial Edukasi
                    </Typography>
                </View>
                <View style={{
                    flex : 1,
                    backgroundColor : "white", 
                    borderTopLeftRadius : 0, 
                    borderTopRightRadius : 0
                }}>
                    <ScrollView>
                        
                        {/* <View style={{flexDirection : "row", alignItems : "center", marginLeft : 16, marginRight : 16 , marginBottom : 16 }}>
                            <CalendarIcon />
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 14 }
                                fontWeight={"400"}
                                color={ sub_text }
                                style={{ 
                                    marginLeft : 4, 
                                    marginRight : 24, 
                                }}
                            >
                                { this.state.date }
                            </Typography>

                            <TimeIcon />
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 14 }
                                fontWeight={"400"}
                                color={ sub_text }
                                style={{ 
                                    marginLeft : 4, 
                                }}
                            >
                                { this.state.time }
                            </Typography>
                        </View> */}

                        <Plyr 
                            source={this.state.videoSrc} 
                        />
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={ 16 }
                            fontWeight={"600"}
                            color={ color_text }
                            style={{ 
                                marginTop : 24, 
                                marginLeft : 16, 
                                marginRight : 16, 
                                marginBottom : 8
                            }}
                        >
                            { this.state.data[0].title }
                        </Typography>
                        <View style={{marginLeft : 16, marginRight : 16 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={14}
                            >
                            <div 
                                dangerouslySetInnerHTML={{ __html: this.state.data[0].full_name }} 
                            />
                            </Typography>
                        </View>
                        
                    </ScrollView>
                </View>
            </View>
            }
            </>
        )
    }
}

export default DetailEdukasi; 