import React, { Component } from 'react'; 
import { StyleSheet , View  } from 'react-native'; 
import { Typography, Button , ListSubheader, List, ListItemButton, ListItemIcon, ListItemText, 
    Collapse , Modal } from '@mui/material'; 
import { ExpandLessIcon , ExpandMoreIcon, ChevronRightIcon } from '@mui/icons-material';


class RegenciesDialog extends Component { 
    constructor(props){
        super(props)
    }

    
    render(){
        return(
            <Modal
                open={ this.props.showing }
                onClose={ this.props.onClose }
            >
                <View style={ styles.container }>
                    <Typography
                        variant="h3"
                        fontFamily="Open Sans"
                        fontSize={ 16 }
                        fontWeight={"600"}
                        style={{ marginLeft : 16, marginTop : 16, marginBottom : 16  }}
                        >
                        Pilih Kota Kelahiran
                    </Typography>
                    <List
                        sx={{ width: '100%', maxWidth: 360}}
                    >
                        <ListItemButton component="a" href="#simple-list">
                            
                            <ListItemText primary="Spam" />
                        </ListItemButton>
                      

                    </List>
                </View>

            </Modal>
            
        )
    }
}
const styles = StyleSheet.create({
    container : {
        backgroundColor : "white",
        margin : 16 ,
        borderRadius : 8 ,
        flex : 1
    }
})
export default RegenciesDialog; 