import React, { Component } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { Typography, Stack, Card, CardActionArea, CardContent } from '@mui/material';
import {
    IconKoleksiSD, IconProgramApresiasi, IconFileManajemen, IconStatistikBp,
    NotificationIcon, NewsIcon, BookIcon, ProfileIcon, NavNewsIcon, NavProfileIcon, NavRackIcon, IconKoleksiSMP, IconKoleksiPerguruanTinggi, IconKoleksiSMA, IconP3smpt, IconProgramPembinaan, IconStatistikPembinaan, IconTutorial, IconFaq, IconFolderAdd,
    IconDashboardTitikbaca
} from '../../assets/Icons';

import { primary, color_text, sub_text } from '../../constants/colors';
import { executeGet, truncateText, updateAuthHeader } from '../../utilities/Utilities';
import { code_ok, code_empty, code_error, code_auth_error, notificationCheck, refreshToken } from '../../constants/api';
import * as Identifier from '../../constants/PageIdentifiers';
import LoadingDialog from '../LoadingDialog';


var _is_mounted = false;
var subdomain = "dev";
const email = localStorage.getItem('email');
console.log('level di global_mobile = ' + global.level)

class MainMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            total_unread: 0,
            total_read: 0,
            showLoadingDialog: false,
            loadingMessage: ''
        }

        this.checkNotification = this.checkNotification.bind(this);
        this.actionP3SMPT = this.actionP3SMPT.bind(this);
    }

    componentDidMount = () => {
        _is_mounted = true;
        const domainName = window.location.hostname;
        let adom = domainName.split(".");
        subdomain = adom[0];
        // console.log('ini token bukan? = ' + global.token)
        this.checkNotification();
        window.addEventListener("message", (event) => {
            if (event.data.id == "check_notifications") {
                this.checkNotification();
            }
        });
    }

    componentWillUnmount = () => {
        _is_mounted = false;
        window.removeEventListener("message", null);
    }

    checkNotification = async () => {
        if (_is_mounted) {
            const response = await executeGet(notificationCheck);
            if (response.code == code_ok) {
                this.setState({
                    total_unread: parseInt(response.data.unread),
                    total_read: parseInt(response.data.read)
                })
            }
        }
    }

    actionWebview = async (mainUrl) => {
        // refreshToken
        let response = await executeGet(refreshToken);
        console.log('refreshToken = ' + JSON.stringify(response))
        if (response.code == code_ok) {
            updateAuthHeader(response)
            console.log("refreshToken = " + response.client_token)
            this.props.navigation.navigate("WebViewerP3smpt", mainUrl + "?os=" + global.os + "&platform=" + global.platform + "&token=" + response.client_token);
            
            // let uri = mainUrl + "?platform=" + global.platform + "&token=" + response.client_token + "&dev=" + subdomain
            // this.props.navigation.navigate("WebViewerP3smpt", uri);
        } else {
            if (response.code == code_auth_error) {
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }
        }
    }

    actionP3SMPT = async (mainUrl) => {
        this.setState({
            showLoadingDialog : true,
            loadingMessage : "Mohon Menunggu"
        });

        // refreshToken
        let response = await executeGet(refreshToken);
        console.log('refreshToken = ' + JSON.stringify(response))
        if (response.code == code_ok) {
            updateAuthHeader(response)
            console.log("refreshToken = " + response.client_token)
            // this.checkBeforeEdukasi();
            this.setState({
                showLoadingDialog : false
            }, () => {
                window.location.href = mainUrl + "?os=" + global.os + "&platform=" + global.platform + "&token=" + response.client_token + "&dev=" + subdomain;
            });
            
            // let uri = mainUrl + "?platform=" + global.platform + "&token=" + response.client_token + "&dev=" + subdomain
            // this.props.navigation.navigate("WebViewerP3smpt", uri);
        } else {
            if (response.code == code_auth_error) {
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }
        }
    }

    render() {
        const level = localStorage.getItem('level');
        console.log('level di local_mobile = ' + level)

        return (
            <View>
                <LoadingDialog
                    open={ this.state.showLoadingDialog }
                    message={ this.state.loadingMessage }
                />
                {
                    level == "Admin Pusat" &&
                    <>

                        <Typography
                            fontFamily="Open Sans"
                            fontSize={16}
                            variant="h3"
                            color={color_text}
                            fontWeight={"600"}
                            style={{ flex: 1, marginLeft: 16, marginTop: 16 }}
                        >
                            {truncateText(this.props.title, 70)}
                        </Typography>

                        <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}
                            onClick={() => this.actionP3SMPT('https://bintangpusnas.perpusnas.go.id/web/office/p3smpt-menu')}>
                            <View
                                style={{
                                    borderRadius: 8,
                                    marginLeft: 16,
                                    marginRight: 16
                                }}
                            >
                                <IconP3smpt />
                            </View>
                            <View style={{ flex: 1 }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    color={color_text}
                                    fontWeight={"600"}
                                >
                                    P3SMPT
                                </Typography>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={12}
                                    color={sub_text}
                                    style={{ marginTop: '4px' }}
                                >
                                    Pusat Pengembangan Perpustakaan Sekolah/Madrasah dan Perguruan Tinggi
                                </Typography>
                                <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px' }} />


                            </View>
                        </View>

                        <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}
                            // onClick={() => { window.location.href = "https://bintangpusnas.perpusnas.go.id/web/office/apresiasi?platform=" + global.platform + "&token=" + global.token + "&dev=" + subdomain }}
                            onClick={() => this.actionP3SMPT('https://bintangpusnas.perpusnas.go.id/web/office/apresiasi-menu')}>
                            <View
                                style={{
                                    borderRadius: 8,
                                    marginLeft: 16,
                                    marginRight: 16
                                }}
                            >
                                <IconProgramApresiasi />
                            </View>
                            <View style={{ flex: 1 }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    color={color_text}
                                    fontWeight={"600"}
                                >
                                    Program Apresiasi
                                </Typography>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={12}
                                    color={sub_text}
                                    style={{ marginTop: '4px' }}
                                >
                                    Program untuk memberikan apresiasi kepada lembaga perpustakaan, pustakawan, dan pemustaka
                                </Typography>
                                <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px' }} />


                            </View>
                        </View>

                        <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}
                            // onClick={() => { window.location.href = "https://bintangpusnas.perpusnas.go.id/web/office/kegiatan-pembinaan?fr=app&platform=" + global.platform + "&token=" + global.token + "&dev=" + subdomain }}
                            onClick={() => this.actionP3SMPT('https://bintangpusnas.perpusnas.go.id/web/office/kegiatan-pembinaan')}>
                            <View
                                style={{
                                    borderRadius: 8,
                                    marginLeft: 16,
                                    marginRight: 16
                                }}
                            >
                                <IconProgramPembinaan />
                            </View>
                            <View style={{ flex: 1 }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    color={color_text}
                                    fontWeight={"600"}
                                >
                                    Program Pembinaan
                                </Typography>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={12}
                                    color={sub_text}
                                    style={{ marginTop: '4px' }}
                                >
                                    Program untuk melakukan pembinaan perpustakaan sehingga memenuhi Standar Nasional Perpustakaan
                                </Typography>
                                <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px' }} />


                            </View>
                        </View>

                        <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}
                            // onClick={() => { window.location.href = "https://bintangpusnas.perpusnas.go.id/web/office/statistik-pembinaan-menu?platform=" + global.platform + "&token=" + global.token  + "&dev=" + subdomain}}
                            onClick={() => this.actionP3SMPT('https://bintangpusnas.perpusnas.go.id/web/office/statistik-pembinaan-menu')}>
                            <View
                                style={{
                                    borderRadius: 8,
                                    marginLeft: 16,
                                    marginRight: 16
                                }}
                            >
                                <IconStatistikPembinaan />
                            </View>
                            <View style={{ flex: 1 }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    color={color_text}
                                    fontWeight={"600"}
                                >
                                    Statistik Pembinaan
                                </Typography>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={12}
                                    color={sub_text}
                                    style={{ marginTop: '4px' }}
                                >
                                    Capaian pembinaan, dan analisa pembinaan digunakan untuk menentukan program pembinaan
                                </Typography>
                                <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px' }} />


                            </View>
                        </View>

                        <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}
                            // onClick={() => { window.location.href = "https://bintangpusnas.perpusnas.go.id/web/office/bp-statistik-koleksi-menu?platform=" + global.platform + "&token=" + global.token  + "&dev=" + subdomain}}
                            onClick={() => this.actionP3SMPT('https://bintangpusnas.perpusnas.go.id/web/office/bp-statistik-koleksi-menu')}>
                            <View
                                style={{
                                    borderRadius: 8,
                                    marginLeft: 16,
                                    marginRight: 16
                                }}
                            >
                                <IconStatistikBp />
                            </View>
                            <View style={{ flex: 1 }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    color={color_text}
                                    fontWeight={"600"}
                                >
                                    Statistik BintangPusnas Edu
                                </Typography>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={12}
                                    color={sub_text}
                                    style={{ marginTop: '4px' }}
                                >
                                    Semua data koleksi dan pemanfaatannya, karakter pemustaka dan tingkat antusias pemustaka
                                </Typography>
                                <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px' }} />


                            </View>
                        </View>

                        <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}
                            // onClick={() => { window.location.href = "https://bintangpusnas.perpusnas.go.id/web/office/administrasi?platform=" + global.platform + "&token=" + global.token  + "&dev=" + subdomain}}
                            onClick={() => this.actionP3SMPT('https://bintangpusnas.perpusnas.go.id/web/office/administrasi')}>
                            <View
                                style={{
                                    borderRadius: 8,
                                    marginLeft: 16,
                                    marginRight: 16
                                }}
                            >
                                <IconFileManajemen />
                            </View>
                            <View style={{ flex: 1 }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    color={color_text}
                                    fontWeight={"600"}
                                >
                                    Regulasi Perpustakaan
                                </Typography>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={12}
                                    color={sub_text}
                                    style={{ marginTop: '4px' }}
                                >
                                    Berisi regulasi dan kebijakan publik tentang Perpustakaan Nasional Indonesia
                                </Typography>
                                <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px' }} />


                            </View>
                        </View>
                    </>
                }

                {
                    level == "Admin Pusat" &&
                    <>
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={16}
                            variant="h3"
                            color={color_text}
                            fontWeight={"600"}
                            style={{ flex: 1, marginLeft: 16, marginTop: 16 }}
                        >
                            {truncateText('Titikbaca Perpusnas RI', 70)}
                        </Typography>
                        <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}
                            onClick={() => { 
                                this.actionWebview('https://kubuku.id/titikbacaPnriDashboard/public/') 
                                // this.actionP3SMPT('https://kubuku.id/titikbacaPnriDashboard/public/')
                            }}>
                            <View
                                style={{
                                    borderRadius: 8,
                                    marginLeft: 16,
                                    marginRight: 16
                                }}
                            >
                                <IconDashboardTitikbaca />
                            </View>
                            <View style={{ flex: 1 }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    color={color_text}
                                    fontWeight={"600"}
                                    style={{ marginTop: '15px' }}
                                >
                                    Dashboard Titikbaca Perpusnas RI
                                </Typography>
                                {/* <Typography
                                        fontFamily="Open Sans"
                                        fontSize={12}
                                        color={sub_text}
                                        style={{ marginTop: '4px' }}
                                    >
                                        Pusat Pengembangan Perpustakaan Sekolah/Madrasah dan Perguruan Tinggi
                                    </Typography> */}
                                <View style={{ border: '0.5px solid #E8E8F2', marginTop: '20px' }} />


                            </View>
                        </View>
                        <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}
                            onClick={() => { this.actionP3SMPT('https://bintangpusnas.perpusnas.go.id/web/office/literasi') }}>
                            <View
                                style={{
                                    borderRadius: 8,
                                    marginLeft: 16,
                                    marginRight: 16
                                }}
                            >
                                <IconP3smpt />
                            </View>
                            <View style={{ flex: 1 }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    color={color_text}
                                    fontWeight={"600"}
                                    style={{ marginTop: '15px' }}
                                >
                                    Statistik Titikbaca Perpusnas RI
                                </Typography>
                                {/* <Typography
                                        fontFamily="Open Sans"
                                        fontSize={12}
                                        color={sub_text}
                                        style={{ marginTop: '4px' }}
                                    >
                                        Pusat Pengembangan Perpustakaan Sekolah/Madrasah dan Perguruan Tinggi
                                    </Typography> */}
                                <View style={{ border: '0.5px solid #E8E8F2', marginTop: '20px' }} />


                            </View>
                        </View>
                    </>
                }

                {
                    level == "Admin PUPK" &&
                    <>
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={16}
                            variant="h3"
                            color={color_text}
                            fontWeight={"600"}
                            style={{ flex: 1, marginLeft: 16, marginTop: 16 }}
                        >
                            {truncateText('Titikbaca Perpusnas RI', 70)}
                        </Typography>
                        <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}
                            onClick={() => { 
                                this.actionWebview('https://kubuku.id/titikbacaPnriDashboard/public/') 
                                // this.actionP3SMPT('https://kubuku.id/titikbacaPnriDashboard/public/')
                            }}>
                            <View
                                style={{
                                    borderRadius: 8,
                                    marginLeft: 16,
                                    marginRight: 16
                                }}
                            >
                                <IconDashboardTitikbaca />
                            </View>
                            <View style={{ flex: 1 }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    color={color_text}
                                    fontWeight={"600"}
                                    style={{ marginTop: '15px' }}
                                >
                                    Dashboard Titikbaca Perpusnas RI
                                </Typography>
                                {/* <Typography
                                        fontFamily="Open Sans"
                                        fontSize={12}
                                        color={sub_text}
                                        style={{ marginTop: '4px' }}
                                    >
                                        Pusat Pengembangan Perpustakaan Sekolah/Madrasah dan Perguruan Tinggi
                                    </Typography> */}
                                <View style={{ border: '0.5px solid #E8E8F2', marginTop: '20px' }} />


                            </View>
                        </View>
                        <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}
                            onClick={() => this.actionP3SMPT('https://bintangpusnas.perpusnas.go.id/web/office/literasi')}>
                            <View
                                style={{
                                    borderRadius: 8,
                                    marginLeft: 16,
                                    marginRight: 16
                                }}
                            >
                                <IconP3smpt />
                            </View>
                            <View style={{ flex: 1 }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    color={color_text}
                                    fontWeight={"600"}
                                    style={{ marginTop: '15px' }}
                                >
                                    Statistik Titikbaca Perpusnas RI
                                </Typography>
                                {/* <Typography
                                        fontFamily="Open Sans"
                                        fontSize={12}
                                        color={sub_text}
                                        style={{ marginTop: '4px' }}
                                    >
                                        Pusat Pengembangan Perpustakaan Sekolah/Madrasah dan Perguruan Tinggi
                                    </Typography> */}
                                <View style={{ border: '0.5px solid #E8E8F2', marginTop: '20px' }} />


                            </View>
                        </View>

                    </>

                }

            </View>

        )
    }
}

export default MainMenu;