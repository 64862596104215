import React, { Component } from 'react'; 
import { StyleSheet, View } from 'react-native'; 
import { Typography, Modal, Box , CircularProgress, Button} from '@mui/material'; 
import WarningLogo from '../assets/WarningLogo';

class WarningDialog extends Component {
    constructor(props){
        super(props)
    }

    render(){
        return(
            <Modal
                open={ this.props.open }
            >
                <View style={ styles.container }>
                    <View style={{marginTop : 64 }}>
                        <WarningLogo
                            width={ 208 }
                            height={ 120 }
                        />
                    </View>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 14 }
                        fontWeight="400"
                        align="center"
                        style={{marginLeft : 16, marginRight : 16, marginTop : 24 }}
                    >
                        { this.props.message }
                    </Typography>
                    <Button
                        variant="contained"
                        style={{ width : "50%", textTransform : "none", marginTop : 16, borderRadius : 8, padding : 10 , marginBottom : 0 }}
                        onClick={ this.props.onClick }
                    >
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={ 16 }
                            fontWeight="500"
                        >
                        { this.props.caption }
                        </Typography>
                    </Button>
                </View>
            </Modal>
        )
    }
}

const styles = StyleSheet.create({
    container : {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 350,
        height : 350, 
        backgroundColor: 'white',
        boxShadow: 24,
        borderRadius : 15, 
        alignItems : "center", 
    
    }
})

export default WarningDialog; 