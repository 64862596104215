import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import * as React from 'react';
import { Button, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { NavigationContainer, getFocusedRouteNameFromRoute, useNavigationContainerRef } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { primary, color_text, textbox } from './constants/colors';
import * as Command from './constants/Commands';
import { NoInternetConnection } from './components/NoInternet'

const Stack = createNativeStackNavigator();

const theme = createTheme({
    palette: {
        primary: {
            main: primary
        }
    }
});

//== Mobile Section ==// 
import Splash from './mobile/Splash';
import Login from './mobile/Login';
import UpdatePage from './mobile/UpdatePage';
import SignUp from './mobile/SignUp';
import ForgotPassword from './mobile/ForgotPassword';
import Main from './mobile/Main';
import SearchPage from './mobile/SearchPage';
import Notification from './mobile/Notification';
import News from './mobile/News';
import NewsDetail from './mobile/NewsDetail';
import MyBookRack from './mobile/MyBookRack';
import Profile from './mobile/Profile';
import InputKTP from './mobile/InputKTP';
import UpdatePassword from './mobile/UpdatePassword';
import EditData from './mobile/EditData';
import ReadingHistory from './mobile/ReadingHistory';
import ContentTypePage from './mobile/ContentTypePage';
import ContentCategoryPage from './mobile/ContentCategoryPage';
import SectionDetail from './mobile/SectionDetail';
import SectionDetailOffline from './mobile/SectionDetailOffline';
import SectionDetailAudiobook from './mobile/SectionDetailAudiobook';
import CategoryPage from './mobile/CategoryPage';
import ContentDetail from './mobile/ContentDetail';
import CommentPage from './mobile/CommentPage';
import ReaderMobile from './mobile/ReaderMobile';
import SearchKalimat from './mobile/SearchKalimat';
import PlayerMobile from './mobile/PlayerMobile';

//== Desktop Section ==// 
import SplashDesktop from './desktop/SplashDesktop';
import LoginDesktop from './desktop/LoginDesktop';
import SignUpDesktop from './desktop/SignUpDesktop';
import VerificationUserDesktop from './desktop/VerificationUserDesktop';
import ForgotPasswordDesktop from './desktop/ForgotPasswordDesktop';
// import MainDesktop from './desktop/MainDesktop';
import MainDesktop from './desktop/Main';
import DesktopContentTypePage from './desktop/DesktopContentTypePage';
import DesktopContentCategoryPage from './desktop/DesktopContentCategoryPage';
import DesktopSectionDetail from './desktop/DesktopSectionDetail';
import DesktopSectionDetailOffline from './desktop/DesktopSectionDetailOffline';
import DesktopSectionKementrian from './desktop/DesktopSectionKementrian';
import DesktopSectionMusik from './desktop/DesktopSectionMusik';
import DesktopCategoryPage from './desktop/DesktopCategoryPage';
import DesktopNewsDetail from './desktop/DesktopNewsDetail';
import DesktopContentDetail from './desktop/DesktopContentDetail';
import ReaderDesktop from './desktop/ReaderDesktop';
import ReaderPlayerDesktop from './desktop/ReaderPlayerDesktop';
import DesktopPlayer from './desktop/DesktopPlayer';
import MainHome from './mobile/MainHome';
import DesktopBookRack from './desktop/DesktopBookRack';
import NewsDesktop from './desktop/NewsDesktop';
import InputKTPDesktop from './desktop/InputKTPDesktop';
import DesktopSearchKalimat from './desktop/DesktopSearchKalimat';
import VerificationUser from './mobile/VerificationUser';
import ReaderPlayerMobile from './mobile/ReaderPlayerMobile';
import CommentPageDesktop from './desktop/CommentPageDesktop';
import Intro from './components/Intro';
import DetailEdukasi from './mobile/DetailEdukasi';
import DesktopDetailEdukasi from './desktop/DesktopDetailEdukasi';
import SectionEdukasi from './mobile/SectionEdukasi';
import SectionMusik from './mobile/SectionMusik';
import WebViewer from './mobile/WebViewer';
import WebViewerP3smpt from './mobile/WebViewerP3smpt';
import SectionKementrian from './mobile/SectionKementrian';

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});

const OS = params.os;
console.log('os = ' + OS)
if (OS == 'IOS' || OS == 'ios') {
    window.location = "https://bintangpusnas.perpusnas.go.id/superapp/";
}

const platform = params.platform;
global.platform = platform;
global.token = params.token;
console.log('appToken = ' + global.token)
if (global.token == 'null' || global.token == null || global.token == 'undefined' || global.token == '') {
    global.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJrdWJ1a3UuY28uaWQiLCJpYXQiOjE2NzQ2MTQ2MTAsImV4cCI6MjQ1MjIxNDYxMCwiYXVkIjoia3VidWt1LmNvLmlkIiwiQVBQSUQiOjEsIkFDQ0VTU0lEIjozLCJDTElFTlRJRCI6NDgzOCwiT1MiOiJkYXJ3aW4iLCJVVUlEIjoiZDhmOGI3YmMtNmEzZi00NTQ1LWIzMDgtOTJmOTg5M2RiZTg4In0.aNM1U9tw3wxHC2d8JuPuF1E_rVd5ALXxHP7NNjVy1-0';
}

global.dummy = params.dummy;
global.reader = params.reader;

console.log('reader = ' + global.reader)


export default function App() {
    window.addEventListener("message", (event) => {
        console.log("event listener App = " + JSON.stringify(event))
        if (event.data.hasOwnProperty("command")) {
            console.log("event listener App 1 = " + event.data.command)
        }
    })

    const navigationRef = useNavigationContainerRef();
    React.useEffect(() => {
        //console.log("APP OS: " + global.os);
    })
    if (platform == "mobile") {
        //== Mobile ==//
        return (

            <ThemeProvider theme={theme}>
                <NavigationContainer
                    ref={navigationRef}
                    onReady={() => {

                    }}
                    onStateChange={() => {
                        const currentRouteName = navigationRef.current.getCurrentRoute().name;
                        global.currentRouteName = currentRouteName;
                        //console.log("Current Route Name : " + currentRouteName);
                        // if(global.os == "ANDROID") {
                        //     JSBridge.launchFunction(Command.SET_PAGE, currentRouteName);
                        // } else if(global.os == "IOS") {
                        //     let obj = {
                        //         id : Command.SET_PAGE, 
                        //         params : currentRouteName
                        //     }
                        //     window.webkit.messageHandlers.JSBridge.postMessage(obj);
                        // }
                    }}
                >
                    <Stack.Navigator>
                        <Stack.Screen
                            name="Splash"
                            component={Splash}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="Intro"
                            component={Intro}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="Login"
                            component={Login}
                            options={{
                                title: "Harap Login",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="UpdatePage"
                            component={UpdatePage}
                            options={{
                                title: "Harap Update Aplikasi",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="SignUp"
                            component={SignUp}
                            options={{
                                title: "Daftar Akun",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="VerificationUser"
                            component={VerificationUser}
                            options={{
                                title: "Kelengkapan Akun",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="ForgotPassword"
                            component={ForgotPassword}
                            options={{
                                title: "Lupa Password",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="Main"
                            component={MainHome}
                            options={{
                                headerShown: false,
                                title: "Bintang Pusnas"
                            }}
                        />
                        <Stack.Screen
                            name="SearchPage"
                            component={SearchPage}
                            options={{
                                headerShown: false,
                                title: "Bintang Pusnas",
                            }}
                        />
                        <Stack.Screen
                            name="Notification"
                            component={Notification}
                            options={{
                                headerShown: false,
                                title: "Bintang Pusnas"
                            }}
                        />
                        <Stack.Screen
                            name="News"
                            component={News}
                            options={{
                                headerShown: false,
                                title: "Bintang Pusnas"
                            }}
                        />
                        <Stack.Screen
                            name="NewsDetail"
                            component={NewsDetail}
                            options={{
                                headerShown: false,
                                title: "Bintang Pusnas"
                            }}
                        />
                        <Stack.Screen
                            name="DetailEdukasi"
                            component={DetailEdukasi}
                            options={{
                                headerShown: false,
                                title: "Bintang Pusnas"
                            }}
                        />
                        <Stack.Screen
                            name="MyBookRack"
                            component={MyBookRack}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="Profile"
                            component={Profile}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="InputKTP"
                            component={InputKTP}
                            options={{
                                title: "Input KTP",
                                headerShown: false
                            }}
                        />

                        <Stack.Screen
                            name="UpdatePassword"
                            component={UpdatePassword}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="EditData"
                            component={EditData}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="ReadingHistory"
                            component={ReadingHistory}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="ContentTypePage"
                            component={ContentTypePage}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="ContentCategoryPage"
                            component={ContentCategoryPage}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="SectionDetail"
                            component={SectionDetail}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="SectionEdukasi"
                            component={SectionEdukasi}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="SectionMusik"
                            component={SectionMusik}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="SectionKementrian"
                            component={SectionKementrian}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="SectionDetailOffline"
                            component={SectionDetailOffline}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="PlayerMobile"
                            component={PlayerMobile}
                            options={{
                                title: "Media Player",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="SectionDetailAudiobook"
                            component={SectionDetailAudiobook}
                            options={{
                                title: "Koleksi Audiobook",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="CategoryPage"
                            component={CategoryPage}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="ContentDetail"
                            component={ContentDetail}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="CommentPage"
                            component={CommentPage}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="ReaderMobile"
                            component={ReaderMobile}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="WebViewer"
                            component={WebViewer}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="WebViewerP3smpt"
                            component={WebViewerP3smpt}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="ReaderPlayerMobile"
                            component={ReaderPlayerMobile}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="SearchKalimat"
                            component={SearchKalimat}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />




                    </Stack.Navigator>
                    <StatusBar style="auto" />
                </NavigationContainer>
            </ThemeProvider>

        );
    } else if (platform == "desktop") {
        //== Desktop ==// 
        return (
            <ThemeProvider theme={theme}>
                <NavigationContainer
                    ref={navigationRef}
                    onReady={() => {

                    }}
                    onStateChange={() => {
                        const currentRouteName = navigationRef.current.getCurrentRoute().name;

                        global.currentPage = currentRouteName;

                    }}
                >
                    <Stack.Navigator>
                        <Stack.Screen
                            name="SplashDesktop"
                            component={SplashDesktop}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="Intro"
                            component={Intro}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="LoginDesktop"
                            component={LoginDesktop}
                            options={{
                                title: "Harap Login",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="SignUpDesktop"
                            component={SignUpDesktop}
                            options={{
                                title: "Daftar Akun Baru",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="VerificationUserDesktop"
                            component={VerificationUserDesktop}
                            options={{
                                title: "Kelengkapan Akun",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="ForgotPasswordDesktop"
                            component={ForgotPasswordDesktop}
                            options={{
                                title: "Lupa Password",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="MainDesktop"
                            component={MainDesktop}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="DesktopContentTypePage"
                            component={DesktopContentTypePage}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="InputKTPDesktop"
                            component={InputKTPDesktop}
                            options={{
                                title: "Input KTP",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="DesktopContentCategoryPage"
                            component={DesktopContentCategoryPage}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="DesktopSectionDetail"
                            component={DesktopSectionDetail}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="DesktopSectionKementrian"
                            component={DesktopSectionKementrian}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="DesktopSectionMusik"
                            component={DesktopSectionMusik}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="DesktopSectionDetailOffline"
                            component={DesktopSectionDetailOffline}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="NewsDesktop"
                            component={NewsDesktop}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="DesktopCategoryPage"
                            component={DesktopCategoryPage}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="DesktopNewsDetail"
                            component={DesktopNewsDetail}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="DesktopDetailEdukasi"
                            component={DesktopDetailEdukasi}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="DesktopContentDetail"
                            component={DesktopContentDetail}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="ReaderPlayerDesktop"
                            component={ReaderPlayerDesktop}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="ReaderDesktop"
                            component={ReaderDesktop}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="DesktopSearchKalimat"
                            component={DesktopSearchKalimat}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="DesktopPlayer"
                            component={DesktopPlayer}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="DesktopBookRack"
                            component={DesktopBookRack}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="CommentPageDesktop"
                            component={CommentPageDesktop}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="WebViewer"
                            component={WebViewer}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />
                        <Stack.Screen
                            name="WebViewerP3smpt"
                            component={WebViewerP3smpt}
                            options={{
                                title: "Bintang Pusnas",
                                headerShown: false
                            }}
                        />

                    </Stack.Navigator>
                </NavigationContainer>
            </ThemeProvider>

        );
    } else {
        return (
            <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                <Typography
                    fontFamily="Open Sans"
                    fontWeight="700"
                    color={"#e74c3c"}
                    fontSize={24}
                >
                    Access Denied!
                </Typography>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
