import React, { Component } from 'react'; 
import { StyleSheet, View, ScrollView, Image } from 'react-native'; 
import { primary , color_text, textbox, danger, sub_text } from '../constants/colors';
import { Typography, Button } from '@mui/material'; 
import * as Identifier from "../constants/PageIdentifiers";
import * as Command from "../constants/Commands";
import TextBox from '../components/TextBox';
import SignUpBackground from '../assets/images/SignUpBackground';
import LogoPusnas from '../assets/images/LogoPusnas';
import HeaderLogoWhite from '../assets/HeaderLogoWhite';
import { code_ok, code_empty, code_error, code_auth_error, forgotPasswordRequest } from '../constants/api'; 
import { executePost } from '../utilities/Utilities';
import AlertDialog from '../components/AlertDialog';
import LoadingPage from '../components/LoadingPage';

var _is_mounted = false; 
class ForgotPassword extends Component {
    constructor(props){
        super(props)
        this.state = {
            email : "", 
            errorEmail : false, 
            errorEmailMessage : "" ,
            showAlert : false, 
            alertMessage : "", 
            showSuccess : false, 
            successMessage : "",
            isSubmitting : false
        }
        this.handleEmailChange = this.handleEmailChange.bind(this); 
        this.handleSubmitClick = this.handleSubmitClick.bind(this); 

        this.handleAlertClick = this.handleAlertClick.bind(this); 
        this.handleSuccessClick = this.handleSuccessClick.bind(this); 

        this.backToLogin = this.backToLogin.bind(this);
        this.submitRequest = this.submitRequest.bind(this);  
    }



    componentDidMount = ()=>{
        _is_mounted = true;
        if(global.os == "ANDROID") {
            JSBridge.launchFunction(Command.SET_PAGE, Identifier.FORGOT_PASSWORD);
        }
        window.addEventListener("message", (event)=>{
            if(event.data.id == "go_back"){
                this.backToLogin();
            }
        });
    }

    componentWillUnmount = ()=>{
        _is_mounted = false; 
        window.removeEventListener("message", null); 

    }

    handleEmailChange = (text)=>{
        if(_is_mounted){
            this.setState({
                email : text, 
                errorEmail : false
            });
        }
    }   

    handleSubmitClick = ()=>{

        if(_is_mounted){
            if(this.state.email.length == 0){
                this.setState({
                    errorEmail : true, 
                    errorEmailMessage : "Harap masukkan e-mail anda yang terdaftar di aplikasi ini"
                });
                return; 
            } else {
                let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
                if(! regEmail.test(this.state.email)){
                    this.setState({
                        errorEmail : true, 
                        errorEmailMessage : "E-mail wajib diisi dengan format yang benar"
                    }); 
                    return;
                } else {
                    //== Submit ==// 
                    this.submitRequest();
                }
            }
        }
    }

    backToLogin = ()=>{
        //JSBridge.launchFunction(Command.SET_PAGE, Identifier.LOGIN);
        this.props.navigation.goBack();
        //this.props.navigation.navigate(Identifier.LOGIN);
    }

    handleAlertClick = ()=>{
        this.setState({
            showAlert : false
        })
    }
    handleSuccessClick = ()=>{
        this.backToLogin(); 
    }
    
    submitRequest = async()=>{
        let formData = new FormData(); 
        formData.append("email", this.state.email);
        
        this.setState({
            isSubmitting : true
        })

        const response = await executePost(forgotPasswordRequest , formData); 
        if(response.code == code_ok){
            this.setState({
                isSubmitting : false, 
                showSuccess : true, 
                successMessage : response.msg
            })
        } else {
            this.setState({
                isSubmitting : false, 
                showAlert : true, 
                alertMessage : response.msg 
            })
        }
    }

    render(){
        return(
            <View style={{flex : 1}}>
                {
                    !this.state.isSubmitting && 
                    <View style={ styles.container }>
                        <View 
                            style={{
                                height : 250, 
                                backgroundColor : primary 
                            }}
                        />
                        <View style={{width : "100%", position : "absolute", alignItems : "center", top : 16 }}>
                            <SignUpBackground />
                        </View>
                        <View style={{width : "auto", position : "absolute" , left : 8, top : 8  }}>
                            <HeaderLogoWhite 
                                width={ "auto" }
                                height={ 22 }
                            />
                        </View>
                        <ScrollView 
                            style={ styles.scrollview }
                            showsVerticalScrollIndicator={ false }
                        >
                            <View 
                                style={{ width : "100%", height : 190 }}
                            />
                            <View
                                style={ styles.form_container }
                            >
                                <Typography
                                    variant="h3"
                                    fontSize={ 20 }
                                    color={ primary }
                                    fontFamily="Open Sans"
                                    fontWeight="600"
                                    style={{marginTop : 16 , marginLeft : 16 }}
                                >
                                    Lupa Password
                                </Typography>   
                                <Typography
                                    variant="body1"
                                    fontSize={14}
                                    fontFamily="Open Sans"
                                    fontWeight="500"
                                    color={ sub_text }
                                    style={{marginTop : 2 , marginLeft : 16 }}
                                >
                                    Masukkan e-mail anda dibawah ini
                                </Typography> 
                                <TextBox
                                    caption={"Email"}
                                    placeholder={"Masukkan email anda"} 
                                    containerStyle={{marginTop : 16, marginLeft : 16 , marginRight : 16, marginBottom : 16 }}
                                    error={ this.state.errorEmail }
                                    errorMessage={ this.state.errorEmailMessage }
                                    onChangeText={ this.handleEmailChange }
                                />

                                <Button
                                    variant="contained"
                                    style={{marginLeft : 16, marginRight : 16, textTransform : "none", borderRadius : 8, padding : 10  }}
                                    onClick={ this.handleSubmitClick }
                                >
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={ 16 }
                                        fontWeight="500"
                                    >
                                    Kirim
                                    </Typography>
                                </Button>
                                <View style={{ flexDirection : "row", marginTop : 24, alignItems : "center", justifyContent : "center"}}>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={ 14 }
                                    >
                                        Kembali ke halaman login
                                    </Typography>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={ 14 }
                                        fontWeight="600"
                                        style={{ marginLeft : 8 }}
                                        color={ primary }
                                        onClick={()=>{ this.backToLogin() }}
                                    >
                                        Klik disini
                                    </Typography>
                                </View>
                                
                            </View>
                        </ScrollView>
                        <AlertDialog 
                            showing={ this.state.showAlert } 
                            title={ "Perhatian!" }
                            message={ this.state.alertMessage }
                            onClose={ this.handleAlertClick }
                            onClick={ this.handleAlertClick }
                            buttonCaption={"OK"}
                        />
                        <AlertDialog 
                            showing={ this.state.showSuccess } 
                            title={ "Permintaan Terkirim!" }
                            message={ this.state.successMessage }
                            onClose={ this.handleSuccessClick }
                            onClick={ this.handleSuccessClick }
                            buttonCaption={"OK"}
                        />
                    </View>
                }
                {
                    this.state.isSubmitting && 
                    <LoadingPage 
                        message={"Mengirim Permintaan"}
                    />
                }
                
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container : {
        flex : 1 ,
        backgroundColor : "white"
    },
    scrollview : {
        position : "absolute", 
        top : 0,
        bottom : 0, 
        left : 0, 
        right : 0,  
        flex : 1, 
    },
    form_container : {
        backgroundColor : "white", 
        borderTopLeftRadius : 20, 
        borderTopRightRadius : 20
    }
})
export default ForgotPassword;