import React, { Component } from 'react';
import { StyleSheet, View, FlatList, Image, Dimensions } from 'react-native';
import { code_ok, code_error, code_auth_error, code_empty, homepageNews, widgetTutorialEdukasi } from '../../constants/api';
import { Skeleton, Typography, Card, CardActionArea, CardMedia, CardContent, Button } from '@mui/material';
import { executeGet, executePost, truncateText, getQueryStrings, isEven, navigateContentDetail } from '../../utilities/Utilities';
import AlertDialog from '../../components/AlertDialog';
import { color_text, sub_text, primary } from '../../constants/colors';
import * as Identifier from '../../constants/PageIdentifiers';

class MenuIntegrasi extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: false,
            showAlert: false,
            alertMessage: "",
            loadingMessage: "",
            code: this.props.code,
            data: [
                {
                    "nama": "Math Games",
                    "color": "#000000",
                    "url_icon": "https://kubuku.id/images/math_genius.jpg",
                    "android_uri": "https://kubuku.id/api/wl/transitUrl/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTcwOTA2NjgsImV4cCI6MTcxNzE3NzA2OCwiaWRfdXNlciI6IjIxMjM2IiwiaWRfbWl0cmEiOiI1MzciLCJkZXN0X3VybCI6Imh0dHBzOlwvXC9rdWJ1a3UuaWRcL2dhbWVzXC9tYXRoZ2VuaXVzXC8ifQ.qqmqN8qQtH5-phE78YBwMZcdVkJ66eu4v9VOVf695Ag"
                },
                {
                    "nama": "Get The Word",
                    "color": "#0000",
                    "url_icon": "https://kubuku.id/images/word.png",
                    "android_uri": "https://kubuku.id/api/wl/transitUrl/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTcwOTA2NjgsImV4cCI6MTcxNzE3NzA2OCwiaWRfdXNlciI6IjIxMjM2IiwiaWRfbWl0cmEiOiI1MzciLCJkZXN0X3VybCI6Imh0dHBzOlwvXC9rdWJ1a3UuaWRcL2dhbWVzXC9nZXRfdGhlX3dvcmQifQ.m0vpmrfoyXOUsGqvpVHv66o9F334x2fBRKsh4Vmjrqk"
                },
                {
                    "nama": "Fruit Games",
                    "color": "#000000",
                    "url_icon": "https://kubuku.id/images/fruit_games.png",
                    "android_uri": "https://kubuku.id/api/wl/transitUrl/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTcwOTA2NjgsImV4cCI6MTcxNzE3NzA2OCwiaWRfdXNlciI6IjIxMjM2IiwiaWRfbWl0cmEiOiI1MzciLCJkZXN0X3VybCI6Imh0dHBzOlwvXC9rdWJ1a3UuaWRcL2dhbWVzXC9mcnVpdCJ9.5nZy-k85eKwJ9rpmuYwnIHQ9WKHZiatEPf_Yy3wIMfo"
                },
                {
                    "nama": "Add Number",
                    "color": "#0000",
                    "url_icon": "https://kubuku.id/images/addition.png",
                    "android_uri": "https://kubuku.id/api/wl/transitUrl/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTcwOTA2NjgsImV4cCI6MTcxNzE3NzA2OCwiaWRfdXNlciI6IjIxMjM2IiwiaWRfbWl0cmEiOiI1MzciLCJkZXN0X3VybCI6Imh0dHBzOlwvXC9rdWJ1a3UuaWRcL2dhbWVzXC9hZGRfbnVtYmVyIn0.QE17rHgf31Hyxa2UC6xB_Iq3IbuEM7OBXL48UHl1LSE"
                },
                {
                    "nama": "Games",
                    "color": "#000000",
                    "url_icon": "https://kubuku.id/images/math.png",
                    "android_uri": "https://kubuku.id/api/wl/transitUrl/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MTcwOTA2NjgsImV4cCI6MTcxNzE3NzA2OCwiaWRfdXNlciI6IjIxMjM2IiwiaWRfbWl0cmEiOiI1MzciLCJkZXN0X3VybCI6Imh0dHBzOlwvXC9wcmV2aWV3LmNvZGVjYW55b24ubmV0XC9pdGVtXC9xdWl6LWJveGluZy1odG1sNS1nYW1lLWNvbnN0cnVjdC0zXC9mdWxsX3NjcmVlbl9wcmV2aWV3XC80MDAxODEyMj9fZ2E9Mi4yNTg5NDkwLjM4MDg0MTI4Mi4xNjY0ODYyNDA5LTExNDgzMjY0MTQuMTY2MjczNjk2NyJ9.-qx5epjIHAMz0MQ5RLWHgTx7sAdVix-FXA8kbvvmW3U"
                }
            ],
            dummy_data: [
                { i: 0 }, { i: 1 }, { i: 2 }
            ]
        }

        this.renderSkeleton = this.renderSkeleton.bind(this);
        this.renderItem = this.renderItem.bind(this);
        // this.fetchNews = this.fetchNews.bind(this);
        // this.handleNewsClick = this.handleNewsClick.bind(this);
        // this.handleMoreClick = this.handleMoreClick.bind(this); 
    }

    componentDidMount = () => {
        // this.fetchNews();
    }

    // handleMoreClick = (code, title)=>{
    //     console.log("CODE ");
    //     console.log(code);
    //     this.props.navigation.navigate("News", { identifier : Identifier.NEWS, code : code, title : title, origins : "NEWS"});
    // }

    contentDetail = (item,index)=>{
        console.log("edukasi 1 = " + JSON.stringify(this.props.navigation));
        console.log("edukasi 2 = " + JSON.stringify(item));
        // navigateContentDetail(this.props.navigation, item); 
        // this.props.navigation.navigate("DetailEdukasi", item); 
        // window.location.href = item.android_uri
        this.props.navigation.navigate("WebViewer", item.android_uri);
    }

    renderSkeleton = ({ item, index, separators }) => {
        return (
            <View style={{
                width: 220,
                marginLeft: (index == 0 ? 16 : 0),
                marginRight: (index == (this.state.dummy_data.length - 1) ? 16 : 8)
            }}>
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    style={{ borderRadius: 8, width: 220, height: 110, marginBottom: 8 }}
                />
                <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "1.5rem", width: 180 }}
                    style={{ marginBottom: 4 }}
                />
                <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "1.2rem", width: 120 }}
                    style={{ marginBottom: 4 }}
                />
            </View>
        )
    }

    renderItem = ({ item, index, separators }) => {
        let window_width = Dimensions.get("window").width; 
        // let book_width = (window_width / 2.2) - 16 ; 
        // let book_height = book_width + 10;
        let book_width = 161;
        let book_height = 167;
        return (
            
            <Card
                style={{ 
                    borderRadius: 8,
                    marginLeft: (index == 0 ? 16 : 0),
                    marginRight: (index == (this.state.data.length - 1) ? 16 : 10),
                    marginBottom: 8,
                    marginTop: 4,
                    boxShadow: "0px 33px 74px rgba(0, 0, 0, 0.0196802), 0px 13.7866px 30.9154px rgba(0, 0, 0, 0.0282725), 0px 7.37098px 16.5289px rgba(0, 0, 0, 0.035), 0px 4.13211px 9.26595px rgba(0, 0, 0, 0.0417275), 0px 2.19453px 4.92108px rgba(0, 0, 0, 0.0503198), 0px 0.913195px 2.04777px rgba(0, 0, 0, 0.07)"
                }}
            >
                <CardActionArea
                    sx={{ height : "100%"}}
                    onClick={()=>{ this.contentDetail(item, index)}}
                >
                    <Image
                        style={{
                            width : book_width,
                            height : book_height
                        }}
                        resizeMode={"cover"}
                        source={{ uri : item.url_icon }}
                        onError={(error)=>{ this.onImageError(item, index)}}
                    />


                    {/* <Typography
                        fontFamily="Open Sans"
                        fontSize={ 14 }
                        color={ color_text } 
                        fontWeight={"600"}
                        style={{ 
                            marginLeft : 12, marginTop : 8, marginBottom : 8, marginRight : 12 
                        }}
                    >
                        { truncateText(item.title, 24)  }
                    </Typography>
                    <Typography
                        fontFamily="Open Sans"
                        color={ sub_text }
                        fontSize={ 12 }
                        style={{
                            marginLeft : 12 ,
                            marginBottom : 12, 
                            marginRight : 12
                        }}
                    >
                        { truncateText(item.full_name, 20 )}
                    </Typography> */}
                </CardActionArea>
            </Card>
        )
    }
    fetchNews = async () => {
        // let formData = new FormData();
        // formData.append("homepage_code", this.props.code);
        // formData.append("page", 1);
        // formData.append("limit", 6);

        const response = await executePost(widgetTutorialEdukasi);
        console.log("widgetTutorialEdukasi news = " + JSON.stringify(response))
        if (response.code == code_ok) {
            this.setState({
                showLoading: false,
                data: response.data
            })

        } else {
            if (response.code != code_auth_error) {
                this.setState({
                    showLoading: false,
                })
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, response.msg);
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : response.msg })
                // }
            } else {
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            }
        }

    }


    render() {
        return (
            <View style={{ width: "100%" }}>
                {
                    this.state.showLoading &&
                    <View>
                        <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: "2rem", width: 150 }}
                            style={{ marginBottom: 8, marginLeft: 16 }}
                        />
                        <FlatList
                            keyExtractor={(i, x) => "news_skeleton_" + x}
                            data={this.state.dummy_data}
                            renderItem={this.renderSkeleton}
                            horizontal={true}
                        />
                    </View>
                }
                {
                    !this.state.showLoading &&
                    <View style={{ marginBottom: 16 }}>
                        <View style={{flexDirection : "row", alignItems : "center"}}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={16}
                                variant="h3"
                                color={color_text}
                                fontWeight={"600"}
                                style={{ flex: 1, marginLeft: 16, marginTop: 16, marginBottom: 16 }}
                            >
                                {truncateText('Gamifikasi/Permainan Edukasi', 30)}
                            </Typography>
                            {/* <Button
                                style={{textTransform : "none", marginRight : 16  }}
                                onClick={()=>{
                                    let params = { code : "HMP-Edukasi", title : "Koleksi Tutorial Edukasi", origins : "CONTENT_SECTION"}; 
                                    this.props.navigation.navigate("SectionEdukasi", params);
                                    // window.location.href = "http://depok.kubuku.id/tutorial-edu/";
                                }}
                            >
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 12 }
                                    fontWeight="500"
                                    color={ primary }
                                >
                                Lihat Semua
                                </Typography>
                            </Button> */}
                        </View>
                        {/* <HomeYoutube /> */}
                        <FlatList
                            keyExtractor={(index) => index}
                            data={this.state.data}
                            horizontal={true}
                            renderItem={this.renderItem}
                        />
                    </View>
                }

            </View>
        )
    }
}

export default MenuIntegrasi;