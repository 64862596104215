import React, { Component } from "react"
import Svg, { Path, Defs, LinearGradient, Stop } from "react-native-svg"


class SignUpBackground extends Component {
  constructor(props){
    super(props)
  }

  render(){
    return(
      <Svg
        width={300}
        height={252}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...this.props}
      >
        <Path
          opacity={0.5}
          d="M162.552 35.444c1.003-3.793 4.882-6.627 9.513-6.627.855 0 1.681.089 2.463.28 2.286-4.28 7.257-7.245 13.024-7.245 1.489 0 2.92.191 4.277.56 3.097-4.486 8.746-7.497 15.177-7.497 7.67 0 14.189 4.25 16.667 10.198a13.45 13.45 0 0 1 5.914-1.343c6.711 0 12.168 4.767 12.168 10.626 0 .354-.015.693-.059 1.033h-79.144v.014Z"
          fill="#EDFDFC"
        />
        <Path
          opacity={0.5}
          d="M122.522 42.055c-.28-1.048-1.342-1.83-2.625-1.83-.236 0-.457.03-.679.074-.634-1.18-2.006-1.992-3.599-1.992-.413 0-.811.059-1.179.162-.856-1.24-2.405-2.066-4.189-2.066-2.109 0-3.909 1.166-4.602 2.819a3.708 3.708 0 0 0-1.637-.37c-1.859 0-3.348 1.314-3.348 2.937 0 .104 0 .192.014.28h21.844v-.014Z"
          fill="#fff"
        />
        <Path
          opacity={0.5}
          d="M295.962 55.706c-4.039-5.21-18.13-5.21-21.449-5.21-4.838 0-9.085 2.258-11.416 5.638a12.074 12.074 0 0 0-3.215-.428c-4.336 0-8.068 2.228-9.794 5.446a8.434 8.434 0 0 0-1.858-.207c-3.496 0-6.416 2.125-7.153 4.973h42.36c.014-.073 1.548.074 1.563 0 17.111.222 14.423-7.305 10.962-10.212ZM37.183 63.218h47.035c-.737-2.804-3.613-4.9-7.05-4.9-.634 0-1.239.074-1.829.207-1.696-3.173-5.383-5.372-9.646-5.372-1.106 0-2.168.148-3.17.413-1.122-1.638-2.715-2.996-4.588-3.97-11.926-2.722-20.032 2.153-20.752 13.622Z"
          fill="#EDFDFC"
        />
        <Path
          d="M44.174 71.851c4.675-1.033 3.805 6.567 3.496 8.604a30.447 30.447 0 0 1 4.07 2.686c-.56-2.435-2.463-3.837-2.36-5.475.104-1.845 1.372-3.675 3.422-1.078 1.623 2.067.089 5.55-.604 6.922a27.371 27.371 0 0 1 4.41 4.56c-.251-2.907-2.272-4.796-1.977-6.641.34-2.022 1.932-3.881 3.864-.694 1.608 2.642-.722 6.508-1.578 7.763.856 1.195 1.269 2.007 1.313 2.096a53.658 53.658 0 0 1 2.168 4.87c-.162-2.568-1.578-4.546-1.091-6.184.575-1.977 2.374-3.571 3.879-.074 1.194 2.76-1.283 6.08-2.375 7.35a52.797 52.797 0 0 1 2.463 9.651c.62-2.331 0-6.065 1.24-8.013.81-1.27 1.784-1.314 2.462-1.107.576.177 1.033.635 1.254 1.254 1.254 3.528-3.407 7.35-4.867 8.442a52.99 52.99 0 0 1 .472 9.932c.324-1.21.826-3.025 1.032-3.985.34-1.505 1.505-3.379 2.758-3.866.06-.015.104-.045.148-.059.973-.295 1.932.295 2.286 1.313 1.416 3.97-4.926 7.04-6.298 7.645v.059c-.502 7.098-2.242 11.614-2.316 11.806l-.604-.266c.014-.044 1.681-4.338 2.212-10.979-5.575-2.775-5.93-7.173-4.675-8.309 1.297-1.181 3.731-.93 3.864 2.199.074 1.815.53 3.675.9 4.914a51.294 51.294 0 0 0-.428-10.124c-8.245-.634-7.11-6.375-5.148-7.128 1.991-.752 2.994.487 3.54 3.011a10.82 10.82 0 0 0 1.534 3.616 51.453 51.453 0 0 0-2.375-9.475c-1.312.87-4.793 2.848-6.843.812-2.567-2.553-.767-4.147 1.047-4.28 1.667-.133 3.083 2.39 5.62 2.996a53.148 53.148 0 0 0-2.434-5.593c-.015-.03-.84-1.668-2.7-3.793-1.224 1.048-4.468 3.468-6.74 1.756-2.861-2.14-1.224-3.97.56-4.368 1.608-.355 3.246 1.785 5.738 2.125-1.622-1.771-3.938-3.793-7.065-5.534-1.283 1.077-4.44 3.364-6.666 1.697-2.862-2.14-1.224-3.97.56-4.369 1.623-.354 3.29 1.845 5.84 2.14l.03-.073c-.693-.694-2.153-2.17-2.89-2.82-.989-.885-1.83-2.685-1.534-4.072.295-1.447 1.386-1.86 1.386-1.86Z"
          fill="url(#a)"
        />
        <Path
          d="m36.71 108.51-9.306-3.572a215.332 215.332 0 0 1-1.652-13.075c11.475 1.402 20.044 5.637 26.446 11.201l.486 11.452 3.658-7.364c14.853 16.661 14.808 40.51 14.808 40.51s-31.342-15.304-37.08-21.03c-2.212-2.213-3.967-7.895-5.265-14.049 3.348-1.476 7.906-4.073 7.906-4.073Z"
          fill="#B7D972"
        />
        <Path
          d="M25.752 91.863s35.472 32.806 45.398 55.799c0 0-31.342-15.304-37.094-21.015-2.212-2.214-3.967-7.895-5.265-14.05 3.362-1.475 7.92-4.073 7.92-4.073l-9.307-3.571a216.565 216.565 0 0 1-1.652-13.09Z"
          fill="url(#b)"
        />
        <Path
          d="M19.543 102.046c2.861-2.317 4.867 3.217 5.354 4.737a23.05 23.05 0 0 1 3.731.457c-1.224-1.49-3.009-1.8-3.51-2.966-.56-1.299-.31-3.011 1.991-1.918 1.829.87 1.962 3.792 1.947 4.973a20.038 20.038 0 0 1 4.602 1.638c-1.18-1.918-3.216-2.524-3.629-3.911-.471-1.505 0-3.335 2.434-1.8 2.006 1.269 1.726 4.737 1.563 5.888 1.003.531 1.564.959 1.623 1.004a40.535 40.535 0 0 1 3.156 2.612c-1.003-1.712-2.64-2.583-2.876-3.881-.28-1.565.413-3.277 2.655-1.388 1.77 1.506 1.195 4.634.87 5.889a39.201 39.201 0 0 1 5 5.814c-.368-1.815-2.08-4.191-1.902-5.947.118-1.151.781-1.52 1.312-1.609.458-.088.93.074 1.298.428 2.065 2.007.162 6.243-.457 7.497a39.917 39.917 0 0 1 3.732 6.685c-.192-.944-.472-2.361-.65-3.099-.28-1.151-.117-2.848.576-3.615l.088-.089c.575-.531 1.431-.457 2.02.118 2.331 2.243-.987 6.538-1.725 7.438 0 .015.015.03.015.044 2.094 5.077 2.434 8.781 2.448 8.944l-.472.014c0-.029-.324-3.571-2.227-8.338-4.793 0-6.548-2.907-6.076-4.132.486-1.269 2.256-1.918 3.407.192.678 1.225 1.622 2.346 2.3 3.084a39.63 39.63 0 0 0-3.76-6.833c-5.9 2.391-7.095-1.962-5.989-3.143 1.121-1.195 2.227-.694 3.481.856a8.312 8.312 0 0 0 2.286 1.963 39.282 39.282 0 0 0-4.896-5.726c-.605 1.047-2.33 3.615-4.44 2.907-2.64-.885-1.947-2.597-.752-3.321 1.106-.664 2.95.591 4.911.133a39.313 39.313 0 0 0-3.599-3.025c-.014-.015-1.15-.856-3.17-1.697-.487 1.151-1.889 3.925-4.042 3.527-2.7-.502-2.212-2.317-1.106-3.203.988-.796 2.846.119 4.675-.501a21.412 21.412 0 0 0-6.77-1.388c-.516 1.181-1.902 3.837-4.011 3.454-2.7-.502-2.213-2.317-1.107-3.203 1.003-.811 2.906.148 4.764-.531v-.059c-.707-.236-2.227-.753-2.964-.959-.974-.266-2.183-1.225-2.449-2.288-.28-1.062.34-1.726.34-1.726Z"
          fill="url(#c)"
        />
        <Path
          d="M16.888 120.508c6.873-1.388 13.23-1.166 18.997.103.885 2.597 2.006 5.667 2.006 5.667l1.917-4.634c21.46 6.7 33.835 27.199 33.835 27.199-15.015 3.276-26.224 1.623-34.513-2.17.088-1.83.265-4.545.265-4.545l-2.876 3.232a38.96 38.96 0 0 1-9.572-7.409c1.504-1.667 3.156-3.6 3.156-3.6l-5.487.929c-5.944-7.423-7.728-14.772-7.728-14.772Z"
          fill="#B7D972"
        />
        <Path
          d="M39.115 146.673c.088-1.83.265-4.545.265-4.545l-2.876 3.232a38.961 38.961 0 0 1-9.572-7.409c1.505-1.667 3.157-3.6 3.157-3.6l-5.487.929c-5.93-7.423-7.714-14.772-7.714-14.772 25.634 6.08 56.755 28.335 56.755 28.335-15.03 3.276-26.224 1.623-34.528-2.17Z"
          fill="url(#d)"
        />
        <Path
          d="m263.879 109.321 6.136-3.187c.206-4.959.103-9.135.103-9.135-7.788 1.859-11.327 3.438-15.295 7.777l-.693 6.611-2.64-3.822c-8.909 12.633-8.23 31.42-8.23 31.42s20.383-13.002 23.893-17.385c1.357-1.697 2.11-5.756 2.523-10.095-2.449-.737-5.797-2.184-5.797-2.184Z"
          fill="#B7D972"
        />
        <Path
          d="M270.118 96.999s-21.844 25.383-26.873 41.986c0 0 20.383-13.002 23.894-17.385 1.357-1.697 2.109-5.756 2.522-10.095-2.434-.752-5.767-2.184-5.767-2.184l6.135-3.187c.192-4.959.089-9.135.089-9.135Z"
          fill="url(#e)"
        />
        <Path
          d="M278.481 116.036c-4.853-.413-9.204.236-13.068 1.564-.413 1.86-.944 4.059-.944 4.059l-1.681-3.04c-14.248 6.301-21.151 21.384-21.151 21.384 10.605 1.077 18.186-.945 23.599-4.206-.207-1.255-.531-3.114-.531-3.114l2.242 1.992a27.061 27.061 0 0 0 6.003-5.859c-1.165-1.033-2.463-2.228-2.463-2.228l3.849.207c3.481-5.564 4.145-10.759 4.145-10.759Z"
          fill="#B7D972"
        />
        <Path
          d="M265.221 135.797c-.206-1.255-.531-3.114-.531-3.114l2.242 1.992a27.061 27.061 0 0 0 6.003-5.859c-1.165-1.033-2.463-2.228-2.463-2.228l3.85.207c3.51-5.579 4.159-10.774 4.159-10.774-17.168 6.199-36.844 23.967-36.844 23.967 10.59 1.092 18.171-.93 23.584-4.191Z"
          fill="url(#f)"
        />
        <Path
          d="m248.348 67.512-90.531-20.07v141.97l90.531 19.126V67.512Z"
          fill="url(#g)"
        />
        <Path
          d="m250.723 208.509-2.375.029V66.553l2.375-.03V208.51Z"
          fill="url(#h)"
        />
        <Path
          d="m157.817 47.442 1.033-.782 91.873 19.864-2.375.988-90.531-20.07Z"
          fill="#B7D972"
        />
        <Path
          d="m67.271 67.512 90.546-20.07v141.97l-90.546 19.126V67.512Z"
          fill="url(#i)"
        />
        <Path
          d="m71.077 68.855 80.472-19.997s4.233 1.771 6.268 1.535l-38.245 31.98s-26.504 3.439-27.168 2.288c-.678-1.151-21.327-15.806-21.327-15.806Z"
          fill="#EDFDFC"
        />
        <Path
          d="m71.077 207.874 10.25 4.59V73.444l-10.25-4.589v139.019Z"
          fill="#EDFDFC"
        />
        <Path
          d="m157.817 189.412-76.49 23.052V73.43l76.49-23.052v139.034Z"
          fill="#E6EDCE"
        />
        <Path
          opacity={0.5}
          d="M150.147 73.106 88.982 91.538v-10.02l61.165-18.433v10.02ZM150.147 77.474l-38.466 11.585v-1.8l38.466-11.586v1.8ZM150.147 81.96l-38.466 11.6v-1.8l38.466-11.6v1.8ZM150.147 86.461l-38.466 11.585v-1.8l38.466-11.585v1.8ZM150.147 90.948l-38.466 11.599v-1.8l38.466-11.6v1.8ZM150.147 95.449l-38.466 11.585v-1.801l38.466-11.585v1.8ZM150.147 99.935l-38.466 11.585v-1.786l38.466-11.6v1.801ZM150.147 104.422l-38.466 11.599v-1.8l38.466-11.585v1.786ZM150.147 108.923l-38.466 11.585v-1.786l38.466-11.6v1.801ZM150.147 113.409l-38.466 11.6v-1.801l38.466-11.585v1.786ZM150.147 117.91l-38.466 11.585v-1.8l38.466-11.585v1.8ZM150.147 122.397l-61.165 18.432v-1.8l61.165-18.418v1.786ZM119.572 136.107l-30.59 9.209v-1.786l30.59-9.224v1.801ZM119.572 140.608l-30.59 9.209v-1.801l30.59-9.209v1.801ZM119.572 145.094l-30.59 9.209v-1.786l30.59-9.223v1.8ZM119.572 149.581l-30.59 9.223v-1.8l30.59-9.209v1.786ZM119.572 154.082l-30.59 9.209v-1.801l30.59-9.209v1.801ZM119.572 158.568l-30.59 9.224v-1.801l30.59-9.209v1.786ZM119.572 163.069l-30.59 9.209v-1.8l30.59-9.209v1.8ZM119.572 167.556l-30.59 9.223v-1.8l30.59-9.224v1.801ZM119.572 172.057l-30.59 9.209v-1.801l30.59-9.209v1.801ZM150.147 167.334l-61.165 18.418v-1.786l61.165-18.432v1.8ZM150.147 171.821l-61.165 18.432v-1.8l61.165-18.418v1.786ZM150.147 176.322 88.982 194.74v-1.786l61.165-18.433v1.801ZM88.982 136.328l20.162-6.065V88.026l-20.162 6.08v42.222ZM121.726 171.407l28.421-8.574v-37.736l-28.421 8.56v37.75Z"
          fill="#DBE5B1"
        />
        <Path
          d="m244.557 68.855-80.471-19.997s-4.234 1.771-6.269 1.535l38.245 31.98s26.504 3.439 27.168 2.288c.679-1.151 21.327-15.806 21.327-15.806Z"
          fill="#F3FCFF"
        />
        <Path
          d="m244.557 207.874-10.265 4.59V73.444l10.265-4.589v139.019Z"
          fill="#EAE7D0"
        />
        <Path
          d="m157.817 189.412 76.475 23.052V73.43l-76.475-23.052v139.034Z"
          fill="#DAEEF7"
        />
        <Path
          d="m157.861 189.412 76.475 23.052V73.43l-76.475-23.052v139.034Z"
          fill="#F6F9EA"
        />
        <Path
          opacity={0.5}
          d="m165.516 73.106 61.165 18.432v-10.02l-61.165-18.433v10.02ZM165.516 77.474l38.481 11.585v-1.8l-38.481-11.586v1.8ZM165.516 81.96l38.481 11.6v-1.8l-38.481-11.6v1.8ZM165.516 86.461l38.481 11.585v-1.8L165.516 84.66v1.8ZM165.516 90.948l38.481 11.599v-1.8l-38.481-11.6v1.8ZM165.516 95.449l38.481 11.585v-1.801l-38.481-11.585v1.8ZM165.516 99.935l38.481 11.585v-1.786l-38.481-11.6v1.801ZM165.516 104.422l38.481 11.599v-1.8l-38.481-11.585v1.786ZM165.516 108.923l38.481 11.585v-1.786l-38.481-11.6v1.801ZM165.516 113.409l38.481 11.6v-1.801l-38.481-11.585v1.786ZM165.516 117.91l38.481 11.585v-1.8l-38.481-11.585v1.8ZM165.516 122.397l61.165 18.432v-1.8l-61.165-18.418v1.786ZM196.106 136.107l30.575 9.209v-1.786l-30.575-9.224v1.801ZM196.106 140.608l30.575 9.209v-1.801l-30.575-9.209v1.801ZM196.106 145.094l30.575 9.209v-1.786l-30.575-9.223v1.8ZM196.106 149.581l30.575 9.223v-1.8l-30.575-9.209v1.786ZM196.106 154.082l30.575 9.209v-1.801l-30.575-9.209v1.801ZM196.106 158.568l30.575 9.224v-1.801l-30.575-9.209v1.786ZM196.106 163.069l30.575 9.209v-1.8l-30.575-9.209v1.8ZM196.106 167.556l30.575 9.223v-1.8l-30.575-9.224v1.801ZM196.106 172.057l30.575 9.209v-1.801l-30.575-9.209v1.801ZM165.516 167.334l61.165 18.418v-1.786l-61.165-18.432v1.8ZM165.516 171.821l61.165 18.432v-1.8l-61.165-18.418v1.786ZM165.516 176.322l61.165 18.418v-1.786l-61.165-18.433v1.801ZM226.681 136.328l-20.162-6.065V88.026l20.162 6.08v42.222ZM193.938 171.407l-28.422-8.574v-37.736l28.422 8.56v37.75Z"
          fill="#DBE5B1"
        />
        <Path
          d="m64.897 208.509 2.374.029V66.553l-2.374-.03V208.51Z"
          fill="url(#j)"
        />
        <Path
          d="m157.817 47.442-1.047-.782-91.873 19.864 2.374.988 90.546-20.07Z"
          fill="url(#k)"
        />
        <Path
          d="M165.236 48.047c-2.906-1.033-5.207-1.358-6.711-1.432-.914-.088-3.717-.162-8.053 1.387 0 0 .265.28.796.635.767.517 2.08 1.21 3.983 1.505 5.811 1.21 9.985-2.095 9.985-2.095Z"
          fill="url(#l)"
        />
        <Path
          d="m162.301 49.523 78.51 21 1.18 138.487-2.566 2.037V71.143l-77.124-21.62Z"
          fill="#D6D2B4"
        />
        <Path
          d="m219.602 232.018-61.003-21.399 48.318-26.667 62.405 22.328-46.918 25.502a3.443 3.443 0 0 1-2.802.236Z"
          fill="url(#m)"
        />
        <Path
          d="M163.348 187.434s9.174 10.626-1.431 22.241l57.478 21.62a3.927 3.927 0 0 0 4.115-.856l2.08-2.022s7.242-19.864 7.242-20.366c0-.502-14.484-12.249-14.484-12.249s-23.849-2.878-24.469-2.878c-.634.015-30.531-5.49-30.531-5.49Z"
          fill="#EDFDFC"
        />
        <Path
          d="M225.826 224.787c-.369 0-42.699-13.991-42.699-13.991s42.197 15.348 42.33 14.979c.133-.369.369-.988.369-.988Z"
          fill="#D6D2B4"
        />
        <Path
          d="M160.089 186.874v1.313l60.678 20.794s3.746 4.678 3.378 11.054c-.369 6.375-1.756 11.407-3.747 11.481 0 0 1.269.103 1.903-.295.634-.398 2.124-1.727 2.124-1.727s2.507-11.334 2.64-11.614c.118-.281-1.313-9.519-2.271-10.213-.974-.693-.163-1.785-4.75-2.626-4.587-.842-40.737-17.09-41.607-16.869-.871.222-8.363-.118-8.776 0-.413.133-9.572-1.298-9.572-1.298Z"
          fill="url(#n)"
        />
        <Path
          d="M221.711 208.42s7.728 13.12 0 23.642l51.445-27.892s6.062-12.426-1.755-20.233l-49.69 24.483Z"
          fill="url(#o)"
        />
        <Path
          d="m221.711 208.42-61.622-21.546 55.634-18.92 55.678 15.983-49.69 24.483Z"
          fill="url(#p)"
        />
        <Path
          d="m211.844 212.67-44.838-15.613c.015.324.015.664.015 1.003l44.823 14.61ZM166.504 201.72c-.118.458-.28.93-.442 1.387l32.906 10.744-32.464-12.131Z"
          fill="#D6D2B4"
        />
        <Path
          d="m211.903 201.617 62.241-18.492-46.784-23.052-63.688 19.289 45.443 22.048c.826.428 1.844.487 2.788.207Z"
          fill="url(#q)"
        />
        <Path
          d="M270.723 163.099s-9.794 9.179.162 19.214l-58.717 18.669c-1.445.458-3.038.177-4.071-.738l-1.961-1.741s-6.107-17.164-6.077-17.591c.029-.428 15.192-10.582 15.192-10.582s24.011-2.479 24.631-2.479c.619 0 30.841-4.752 30.841-4.752Z"
          fill="#EDFDFC"
        />
        <Path
          d="M206.077 195.389c.368 0 43.51-12.087 43.51-12.087s-43.083 13.268-43.186 12.943c-.103-.34-.324-.856-.324-.856Z"
          fill="#D6D2B4"
        />
        <Path
          d="m273.997 162.627-.074 1.136-61.873 17.975s-4.012 4.044-4.012 9.548c.015 5.505 1.092 9.858 3.098 9.918 0 0-1.269.088-1.888-.251-.62-.34-2.021-1.491-2.021-1.491s-1.858-9.799-1.976-10.035c-.104-.236 1.858-8.235 2.861-8.825 1.003-.591.266-1.535 4.897-2.273 4.631-.723 41.711-14.758 42.581-14.581.855.192 8.378-.103 8.776 0 .413.118 9.631-1.121 9.631-1.121Z"
          fill="url(#r)"
        />
        <Path
          d="M211.15 181.236s-8.48 11.334-1.357 20.425l-49.837-24.1s-5.34-10.743 2.905-17.488l48.289 21.163Z"
          fill="url(#s)"
        />
        <Path
          d="m211.15 181.236 62.847-18.609-54.528-16.367-56.608 13.828 48.289 21.148Z"
          fill="#847D3D"
        />
        <Path
          d="m220.767 184.911 45.737-13.489c-.029.281-.059.576-.073.871l-45.664 12.618ZM266.74 175.451c.104.399.222.797.369 1.21l-33.525 9.283 33.156-10.493Z"
          fill="#D6D2B4"
        />
        <Path
          opacity={0.3}
          d="M171.239 163.748s-8.392 8.752-1.829 18.403l3.717 1.786s-4.779-11.541 2.315-18.344l-4.203-1.845Z"
          fill="#06BFAD"
        />
        <Path
          d="m226.224 179.037-51.844-17.606 37.744-21.841 53.068 18.358-36.682 20.897c-.663.384-1.504.458-2.286.192Z"
          fill="url(#t)"
        />
        <Path
          d="M176.534 142.423s8.363 8.722.501 18.241l48.953 17.768c1.21.443 2.508.163 3.319-.693l1.563-1.653s4.454-16.293 4.41-16.706c-.044-.413-12.846-10.05-12.846-10.05s-19.853-2.376-20.369-2.376c-.502 0-25.531-4.531-25.531-4.531Z"
          fill="#EDFDFC"
        />
        <Path
          d="M230.811 173.119c-.31 0-36.209-11.511-36.209-11.511s35.899 12.633 35.973 12.323c.074-.31.236-.812.236-.812Z"
          fill="#D6D2B4"
        />
        <Path
          d="m173.82 141.966.103 1.077 51.534 17.104s3.437 3.852 3.614 9.076c.177 5.225-.575 9.357-2.213 9.416 0 0 1.048.088 1.534-.236.487-.325 1.608-1.417 1.608-1.417s1.209-9.298 1.283-9.534c.089-.236-1.799-7.821-2.655-8.382-.855-.561-.265-1.461-4.1-2.155-3.835-.693-34.823-14.049-35.531-13.872-.708.177-6.888-.103-7.227 0-.325.103-7.95-1.077-7.95-1.077Z"
          fill="url(#u)"
        />
        <Path
          d="M226.165 159.69s7.36 10.773 1.8 19.392l40.221-22.846s4.041-10.197-2.98-16.602l-39.041 20.056Z"
          fill="url(#v)"
        />
        <Path
          d="m226.165 159.69-52.345-17.724 44.336-15.481 47.05 13.149-39.041 20.056Z"
          fill="#B7D972"
        />
        <Path
          d="m218.378 163.173-38.098-12.84c.044.266.074.546.089.827l38.009 12.013ZM180.206 154.141c-.073.369-.147.752-.265 1.136l27.906 8.84-27.641-9.976Z"
          fill="#D6D2B4"
        />
        <Path
          d="m221.681 154.406-30.309-10.625 5.988-2.612 32.109 9.518-7.788 3.719Z"
          fill="url(#w)"
        />
        <Path
          d="M186.062 156.251s-3.127 3.173-1.696 7.453c0 0 .944-2.214 1.902-2.848 0 0-.162 2.7.944 3.807 0 0 .974-5.962 3.968-6.552l-5.118-1.86Z"
          fill="url(#x)"
        />
        <Path
          d="M221.711 191.08s1.917 2.258 1.77 5.106c0 0 1.062-1.461 1.312-2.553 0 0 1.062.487 1.697 1.756 0 0 1.18-3.025-.045-5.623l-4.734 1.314Z"
          fill="url(#y)"
        />
        <Path
          d="M192.035 205.778s-3.244 4.354-2.138 11.334c0 0 1.106-2.848 3.643-4.442 0 0 1.106 4.59 1.74 6.184 0 0 2.537-9.239 5.546-10.006l-8.791-3.07Z"
          fill="url(#z)"
        />
        <Path
          d="M113.319 31.09c3.997 1.136 18.038-2.096 22.64-3.217l.162-.074 1.622-1.136c1.343-.886 2.729-1.919 2.862-1.86.191.089.206.266-.885 1.815-.074.104 0 .236.118.251.958.03 3.953.163 3.997.413.059.296-2.626.236-2.729.502-.103.251 2.522.236 2.537.561.015.325-2.375.25-2.419.398-.044.133 2.242.443 2.36.635.118.192-2.36.148-2.463.31-.103.148 1.608.738 1.578.9-.029.148.398.104-3.805-.118 0 0-1.047-.088-1.46-.147l-1.048.486c-8.48 4.93-24.528 5.372-24.528 5.372l1.461-5.091Z"
          fill="#FFC0BB"
        />
        <Path
          d="M103.112 28.035s-1.947 1.845-3.171.56c0 0-3.51.768-3.215-2.33 0 0-2.39-1.447-1.505-4.236 0 0-2.153-1.963.207-4.221 0 0-1.549-2.642 1.416-3.527 0 0 .383-4.516 4.601-3.306 0 0 3.186-3.439 5.93-1.27 0 0 2.861-.944 4.056 2.347 0 0 3.038 1.019 1.932 4.752 0 0 2.95 3.262 1.003 6.553 0 0 1.032 4.412-3.924 3.852-4.985-.576-7.33.826-7.33.826Z"
          fill="#002A24"
        />
        <Path
          d="M117.021 37.126s-5.192 1.83-9.543.65c-.811-.222-1.387-.945-1.372-1.786.015-.886-1.578-4.531.221-5.033 3.879-1.062 9.602-.797 9.602-.797s1.844 2.671 1.092 6.966Z"
          fill="#B7D972"
        />
        <Path
          d="M102.891 29.82s-4.322.768-5.826 2.51c-1.505 1.741-5.369 20.956-4.44 24.394l18.26-2.612s1.962-15.363 1.578-19.79c-.398-4.427-5.236-4.25-5.236-4.25l-4.336-.251Z"
          fill="#B7D972"
        />
        <Path
          d="M112.434 38.085c.073-2.332 0-3.925 0-3.925.25 1.593 0 3.925 0 3.925ZM96.49 44.195s.767.369 1.725.369c.812-1.284 1.947-4.56 1.859-7.38-1.74 2.879-3.584 7.01-3.584 7.01Z"
          fill="#847D3D"
        />
        <Path
          d="m123.761 72.338-.767 2.671a.423.423 0 0 0 .28.517l6.254 1.785c.457.133.796-.413.487-.767l-3.142-3.586c-.015 0-1.401.96-3.112-.62Z"
          fill="url(#A)"
        />
        <Path
          d="M112.36 54.54s12.537-3.527 13.997-3.365c1.622.178 6.121 2.937 1.032 22.55 0 0-2.433.782-3.923-.915l.103-11.171s-9.07 4.973-11.873 3.763c-2.802-1.225.664-10.862.664-10.862Z"
          fill="#473B1E"
        />
        <Path
          d="m103.614 72.28-1.682 2.213a.417.417 0 0 0 .074.575l5.192 3.911c.368.28.885-.103.722-.546l-1.637-4.486c.015.014-1.622.413-2.669-1.668Z"
          fill="url(#B)"
        />
        <Path
          d="M93.068 56.665s-.25 12.854 12.773 7.999l-2.596 8.087s1.858 1.904 3.156 1.683c1.195-.207 13.57-15.29 6.372-20.617-1.033-.767-2.301-1.151-3.584-1.18-3.245-.06-11.696.191-16.121 4.028Z"
          fill="#847D3D"
        />
        <Path
          d="M110.929 22c-.133.722.221 1.386.797 1.49.575.103 1.15-.399 1.283-1.107.133-.723-.221-1.387-.797-1.49-.575-.119-1.15.383-1.283 1.106Z"
          fill="#F99893"
        />
        <Path
          d="M111.534 25.895a5.64 5.64 0 0 0 .221-.989c.148-.915.118-1.844.03-2.656-.015-.177-.03-.34-.03-.517a5.49 5.49 0 0 0-.487-2.11c-1.002-2.258-3.421-3.616-5.87-3.29-3.304.442-5.472 3.57-4.808 6.729-.383-.118-.782-.089-1.091.147-.59.428-.65 1.373-.104 2.125.531.738 1.446.99 2.05.561.089-.059.148-.133.222-.206.84 2.641 3.481 4.265 6.135 3.66a5.06 5.06 0 0 0 3.068-2.096c.044-.06.103-.133.148-.207.221-.369.383-.752.516-1.15ZM97.994 40.89c-2.507 3.305-4.631 17.605-5.265 22.313l.014.177.458 1.933c.339 1.58.796 3.247.693 3.35-.148.148-.325.089-1.357-1.49-.074-.103-.221-.089-.266.03-.383.87-1.593 3.63-1.843 3.585-.295-.058.737-2.538.53-2.73-.206-.177-1.15 2.273-1.445 2.155-.295-.103.634-2.317.516-2.406-.117-.088-1.224 1.934-1.445 1.978-.221.044.723-2.258.605-2.406-.103-.147-1.269 1.225-1.401 1.137-.133-.089-.251.34 1.504-3.513 0 0 .457-.959.664-1.313l-.06-1.151c-1.474-9.71 3.954-24.867 3.954-24.867l4.144 3.217Z"
          fill="#FFC0BB"
        />
        <Path
          d="M91.386 42.217s1.357-7.467 5.576-10.153c1.784-1.136 2.802-1.564 3.613-1.092.841.487-.988 2.302-.62 4.353.723 4.059-1.887 9.15-1.887 9.15s-3.555-.398-6.682-2.258Z"
          fill="#B7D972"
        />
        <Path
          d="m100.885 16.376-.059 3.646 8.481-2.288s.958.812 1.091.782c.148-.014 1.21-.516 1.165-.664-.044-.147-3.112-4-3.112-4s-2.817.724-3.068.798c-.236.073-4.498 1.726-4.498 1.726Z"
          fill="#002A24"
        />
        <Path
          d="M100.472 21.704s4.321-3.748 10.929-1.8c0 0-.044-.768-.324-1.727 0 0-4.484-2.907-10.797 1.697 0 .015-.339.487.192 1.83Z"
          fill="url(#C)"
        />
        <Path
          d="M79.808 171.295v2.878a.428.428 0 0 1-.428.428h-6.755c-.486 0-.678-.635-.265-.9l3.702-2.421c-.015.015 1.563 1.093 3.746.015ZM94.705 171.295v2.878c0 .236.192.428.428.428h6.755c.487 0 .678-.635.265-.9l-3.702-2.421c0 .015-1.563 1.093-3.746.015Z"
          fill="#002A24"
        />
        <Path
          d="M92.965 101.919s3.26.797 5.958 2.833c2.699 2.037 8.157 5.962 7.891 9.962-.265 3.999-4.572 10.242-4.572 10.242l-1.446-2.996s1.313-7.04 1.018-7.291l-5.885-5.312-2.965-7.438Z"
          fill="url(#D)"
        />
        <Path
          d="M100.501 129.471c-.014-.531 1.357 1.019 1.328.606-.531-8.722-17.596-1.402-17.596-1.402l-4.16 3.054-5.442 40.407s2.744.974 5.192.31l10.192-34.799 1.283-.487c0-.014 5.693-3.984 9.203-7.689Z"
          fill="#473B1E"
        />
        <Path
          d="M101.829 128.099c-3.51 3.704-10.546 9.047-10.546 9.047l3.422 35.049s3.363.502 4.97-.383c.015 0 2.39-33.323 2.154-43.713Z"
          fill="#473B1E"
        />
        <Path
          d="m80.074 131.715-.679 5.77c1.77.694 6.106 1.417 9.366-5.711 1.032-2.244 1.298-3.867 1.106-5.033-3.127.841-5.634 1.904-5.634 1.904l-4.16 3.07Z"
          fill="#847D3D"
        />
        <Path
          d="M95.723 108.161c-.148 0-1.755-3.556-2.611-5.475-.265-.605-.973-.9-1.578-.649-2.33.915-4.912-.015-4.912-.015s-7.743 27.051-4.542 29.176c8.377 5.564 17.994-.856 17.994-.856s1.239-5.062 1.239-5.254c-.015-.191-5.384-16.927-5.59-16.927Z"
          fill="#B7D972"
        />
        <Path
          d="M86.622 102.022s-1.475 2.184-1.622 2.155c-1.003-.178-3.776-2.642-4.248 5.504.266 2.494-2.109 22.654-2.109 22.654 6.43 3.01 8.245 1.254 8.245 1.254 1.327-6.095-.266-31.567-.266-31.567Z"
          fill="url(#E)"
        />
        <Path
          d="M86.622 102.022s-4.159.531-7.212 1.977c-3.053 1.447-8.466 4.339-8.245 8.383.221 4.044 7.994 12.588 7.994 12.588l1.18-1.18 1.047-1.594-5.354-9.43 6.859-2.863 3.731-7.881Z"
          fill="url(#F)"
        />
        <Path
          d="M93.82 102.465c1.962 2.14 5.87 8.81 8.982 29.102 0 0-3.451 2.096-7.404 2.317 0 0-1.017-26.844-3.923-31.833a1.956 1.956 0 0 1 2.345.414Z"
          fill="url(#G)"
        />
        <Path
          d="M100.251 121.104c1.032.693 1.622 1.948 1.947 3.025.014.059.029.103.044.162.206.871-.236 1.048-.472 1.078-.074.015-.133-.03-.177-.089a6.388 6.388 0 0 0-.266-.472c-.722-1.181-1.578.753-1.828.28-.325-.605 1.076-1.121.899-1.416-.354-.576-1.15.738-1.43.383-.296-.383 1.297-.767.9-1.431-.207-.354-1.27.767-1.402.295-.133-.502 1.21-.708.974-1.107-.34-.605-1.092.738-1.387.325-.162-.266 1.46-1.535 2.198-1.033Z"
          fill="#FFC0BB"
        />
        <Path
          d="m93.422 88.828.28 3.247-.516.31-.944-3.94 1.18.383Z"
          fill="#473B1E"
        />
        <Path
          d="M92.729 91.736c-.148.738.22 1.416.81 1.534.59.119 1.18-.383 1.314-1.121.147-.738-.222-1.417-.812-1.535-.575-.118-1.165.384-1.312 1.122Z"
          fill="#F99893"
        />
        <Path
          d="M93.333 95.735c.103-.325.192-.664.236-1.018.148-.93.133-1.89.044-2.716-.014-.177-.029-.354-.029-.53a5.504 5.504 0 0 0-.487-2.17c-1.003-2.317-3.48-3.734-5.988-3.424a5.768 5.768 0 0 0-4.97 6.862c-.399-.133-.797-.088-1.121.133-.62.428-.664 1.402-.118 2.17.545.767 1.475 1.032 2.094.605a.992.992 0 0 0 .221-.207c.841 2.715 3.54 4.398 6.254 3.807a5.195 5.195 0 0 0 3.156-2.125c.045-.059.104-.132.163-.221.236-.354.413-.753.545-1.166Z"
          fill="#FFC0BB"
        />
        <Path
          d="M92.817 85.375s1.696-.325-.398-1.358c-2.095-1.033-7.227-1.623-9.926.68 0 0-.9-1.152-1.711-.65-.811.502.84 1.077.84 1.077s-1.135.222-1.357.841c-.162.473.531.325.9.222-.62.25-2.434 1.963-.074 6.64.413-.191 3.113-4.028 3.113-4.028s5.413 1.77 4.424.31c0 0 6.313 1.579 4.779-1.27.03-.014 2.36-.368-.59-2.464Z"
          fill="#473B1E"
        />
        <Path
          d="M84.218 88.799c.089.31-1.401 3.69-2.08 3.955-.545 0-1.032.074-1.032.074-.162-.96 1.46-3.645 1.46-3.645l1.652-.384Z"
          fill="#473B1E"
        />
        <Path
          d="M77.935 116.057a326.89 326.89 0 0 0-1.785-3.041c-.044-.088-.103-.162-.147-.25.162-.059.31-.118.472-.177.62-.222 1.224-.473 1.829-.709.605-.251 1.21-.502 1.814-.767a39.643 39.643 0 0 0 1.785-.827c-.635.207-1.254.428-1.859.65-.62.221-1.224.472-1.843.708-.605.251-1.21.502-1.815.767-.604.266-1.194.532-1.784.827.413-.133.811-.266 1.224-.413.044.088.103.191.147.28.576 1.033 1.15 2.051 1.74 3.055.59 1.018 1.18 2.022 1.786 3.04.604 1.004 1.209 2.007 1.843 2.996a159.815 159.815 0 0 0-1.681-3.099 121.084 121.084 0 0 0-1.726-3.04Z"
          fill="#07085B"
        />
        <Path
          d="M82.227 123.391c-.059-.059-.03-.177.06-.192 1.341-.28 1.43-.383 1.356-.516-.044-.074-1.239-.295-2.3-.472l.014.014-.693 1.092s-1.077 1.211-1.106 1.24c.058.089.103.148.103.148 2.124 2.184 2.168 2.125 2.271 2.051.089-.059-.516-1.21-.398-1.24.133-.029 1.43 1.196 1.46 1.033.03-.162-1.032-1.431-.944-1.49.089-.044 1.328 1.151 1.475.974.147-.177-1.239-1.446-1.062-1.535.177-.088 1.578 1.255 1.696 1.063.089-.148-1.445-1.683-1.932-2.17Z"
          fill="#FFC0BB"
        />
        <Path
          d="m79.144 124.97.59-.664c.192-.221.384-.442.576-.679.191-.221.368-.457.545-.693l.266-.354c.088-.118.162-.251.25-.369-.117.28-.265.531-.427.782-.162.251-.354.487-.531.708-.192.222-.384.443-.605.664a24.4 24.4 0 0 1-.664.605Z"
          fill="#07085B"
        />
        <Path
          d="M187.478 37.17s-3.923-.368-6.534 1.506c-2.596 1.874-5.634 5.047-12.242 3.084 0 0 3.127 3.749 7.271 4.162 4.145.413 3.024-2.937 12.464-.974l-.458-4-.501-3.777Z"
          fill="url(#H)"
        />
        <Path
          d="m207.493 99.891.59 38.606.988 2.775 2.95-2.775 2.831-39.595-7.359.989ZM225.147 101.204h-7.389s3.466 37.057 3.437 37.293c-.03.237 1.253 1.889 1.253 1.889l2.699-1.889v-37.293Z"
          fill="#FFC0BB"
        />
        <Path
          d="m207.493 99.891.59 38.606.988 2.775 2.95-2.775 2.831-39.595-7.359.989ZM225.147 101.204h-7.389s3.466 37.057 3.437 37.293c-.03.237 1.253 1.889 1.253 1.889l2.699-1.889v-37.293Z"
          fill="#FFC0BB"
        />
        <Path
          d="m207.935 128.211.162 10.272.988 2.789 2.965-2.789.738-10.375c-2.287.885-3.968.487-4.853.103ZM220.265 128.211c.531 5.785.93 10.198.93 10.286-.03.237 1.253 1.889 1.253 1.889l2.699-1.889v-10.241c-2.109.634-3.879.295-4.882-.045Z"
          fill="#EDFDFC"
        />
        <Path
          d="M206.386 124c2.095.841 4.515.767 8.114 0l1.37-26.736c.384.325.605.487.605.487L219 124s2.118.9 7 0c.015-2.125-.13-24.92-.145-26.411-.088-10.847-3.436-12.426-3.436-12.426l-5.708-1.447-1.254.222c-1.991-.65-5.605-1.402-7.079 1.077-2.11 3.527-1.992 14.404-1.992 14.404s-.014 23.09 0 24.581Z"
          fill="#473B1E"
        />
        <Path
          d="m210.236 74.138-5.339-2.479.486 5.667s-10.118-4.604-10.634-5.062c-.177-.147-.369-.56-.546-1.033a11.463 11.463 0 0 1-.442-1.535c-.177-.782-.855-1.416-1.077-1.623-.103-.088-.383-.62-.457-.87-.044-.163-.295-.148-.324.014l-.059.354c-.059.355.073.709.147.9l-.147-.059-.236 2.376.28 2.081 1.608 1.21s14.1 10.169 15.398 8.84c1.371-1.416 1.342-8.78 1.342-8.78Z"
          fill="#FFC0BB"
        />
        <Path
          d="M212.021 138.497v2.937a.458.458 0 0 1-.458.443h-7.094c-.516 0-.708-.649-.28-.915l3.894-2.465s1.651 1.107 3.938 0Z"
          fill="url(#I)"
        />
        <Path
          d="M225.133 138.497v2.937a.457.457 0 0 1-.457.443h-7.095c-.516 0-.708-.649-.28-.915l3.894-2.465s1.652 1.107 3.938 0Z"
          fill="url(#J)"
        />
        <Path
          d="M209.026 62.155s-1.091.266-2.684 2.229c-1.593 1.962-2.994 7.393-2.994 7.393l5.059 2.775 3.289-6.553-2.67-5.844Z"
          fill="url(#K)"
        />
        <Path
          d="M223.333 93.442c-.531.192-1.062.413-1.578.634-.516.222-1.032.473-1.549.709-.516.25-1.017.502-1.519.767-.501.266-1.003.531-1.489.797l-.045-.088c.369-.074.753-.133 1.121-.177a9.77 9.77 0 0 1 1.136-.06c.383 0 .752.015 1.136.06l.28.029.28.044.133.03.133.03.28.073-.28-.059-.133-.03-.133-.014-.28-.044-.28-.03c-.089-.015-.192-.015-.281-.015-.088 0-.191-.014-.28-.014h-.56c-.369 0-.752.029-1.121.073s-.752.104-1.106.177l-.34.074.295-.162a36.5 36.5 0 0 1 1.505-.812 29.415 29.415 0 0 1 1.534-.752 49.513 49.513 0 0 1 1.563-.68l.797-.31c.236-.073.516-.162.781-.25ZM208.363 93.22c.265.089.546.177.811.28.265.09.531.207.796.31a36.628 36.628 0 0 1 3.098 1.432c.501.251 1.003.532 1.489.812l.295.162-.339-.073a14.285 14.285 0 0 0-1.106-.178 9.525 9.525 0 0 0-1.121-.073h-.56c-.089 0-.192.014-.281.014-.088 0-.191.015-.28.015l-.28.03-.28.044-.133.015-.133.03-.28.058.28-.074.133-.03.133-.029.28-.044.28-.03a9.589 9.589 0 0 1 1.136-.058c.383 0 .752.014 1.136.059.383.044.752.088 1.12.177l-.044.088c-.486-.28-.988-.546-1.489-.811a48.177 48.177 0 0 0-1.52-.768 29.141 29.141 0 0 0-1.548-.708 24.108 24.108 0 0 0-1.593-.65Z"
          fill="#B7D972"
        />
        <Path
          opacity={0.5}
          d="M209.498 86.963c.458.089.93.148 1.402.207.472.059.929.118 1.401.162a44.4 44.4 0 0 0 2.817.177c.472.015.944.015 1.416 0s.944-.044 1.401-.074c.472-.044.929-.088 1.401-.162a10.84 10.84 0 0 0 1.372-.295 11.47 11.47 0 0 1-1.372.31 22.794 22.794 0 0 1-4.233.266 35.613 35.613 0 0 1-2.817-.192 22.718 22.718 0 0 1-1.401-.177 24.57 24.57 0 0 1-1.387-.222Z"
          fill="#DAEEF7"
        />
        <Path
          d="M221.785 52.135s1.003-5.166-1.918-6.996c-2.905-1.815-5.914-1.505-7.92-.28-2.006 1.225-3.791 5.106-1.357 8.84l6.003-3.719 5.192 2.155Z"
          fill="url(#L)"
        />
        <Path
          d="M220.088 88.72c.015.058.045.103.074.162l.089.147.162.31.28.517.782 1.357c.103.207 1.136 1.993 1.372 2.391-.295-.354-.664-.723-.915-1.121a21.91 21.91 0 0 1-.354-.606c-.118-.206-.221-.413-.339-.62a20.053 20.053 0 0 1-.62-1.254c-.103-.206-.191-.428-.28-.634-.044-.104-.088-.222-.133-.325a3.026 3.026 0 0 1-.118-.325Z"
          fill="#EA9734"
        />
        <Path
          d="M230.059 68.472c-2.301-2.775-4.749-5.343-6.947-6.73-1.844-1.151-4.897-1.476-5.634-1.461-1.8-.221-3.097-.325-4.056.177-2.876.398-5.885 2.007-5.929 3.025-.192 5.033-.251 8.9-.236 11.866 0 .014-.089 10.743-.015 13.03 0 0 11.652 2.524 17.625 1.02 0 0 .988-14.036-.413-17.828l.605.767c-.015-.015 2.389-.56 5-3.866Z"
          fill="url(#M)"
        />
        <Path
          d="M229.705 68.914c-1.578 1.919-3.333 2.952-4.218 3.262l5.634 6.759-10.325 11.127 1.815 3.158c1.43-.693 1.755-3.335 1.755-3.335s12.773-8.53 12.773-10.581c.014-2.73-7.434-10.39-7.434-10.39Z"
          fill="#FFC0BB"
        />
        <Path
          d="M229.793 68.781c-.088.104-.162.207-.265.296l-.28.295-.133.147-.147.133-.281.28c-.103.09-.191.178-.295.266-.191.177-.413.34-.619.502-.428.31-.87.605-1.328.856l-.177.088-.177.089c-.118.059-.236.118-.354.162-.059.03-.118.045-.177.074-.059.03-.118.044-.177.074-.088.03-.177.059-.25.088l-.62-.737-.708-.827-1.416-1.638c-.472-.546-.958-1.077-1.445-1.623-.487-.532-.974-1.063-1.475-1.594.428.575.87 1.15 1.313 1.712l1.342 1.697c.457.56.914 1.122 1.372 1.668l.693.826.708.826.073.089.104-.03c.147-.044.28-.088.413-.132a3.04 3.04 0 0 0 .383-.163 8.72 8.72 0 0 0 .369-.177l.177-.088.177-.104c.472-.265.914-.59 1.342-.93l.31-.265c.103-.089.192-.177.295-.28.088-.104.192-.192.28-.28l.266-.296.132-.148.118-.162.251-.31c.162-.221.295-.443.457-.664l-.251.28Z"
          fill="#002A24"
        />
        <Path
          d="M210.929 55.603a4.337 4.337 0 0 1-.324-.871c-.236-.812-.325-1.653-.34-2.39 0-.163-.014-.31-.029-.473-.059-.635 0-1.299.206-1.963.664-2.154 2.759-3.645 5.074-3.645 3.127.015 5.487 2.583 5.251 5.52.339-.148.723-.163 1.032 0 .605.324.753 1.165.34 1.903-.413.723-1.239 1.063-1.829.738a.854.854 0 0 1-.222-.162c-.472 2.48-2.743 4.25-5.265 4.014a4.836 4.836 0 0 1-3.097-1.535c-.059-.044-.104-.103-.163-.177a3.504 3.504 0 0 1-.634-.96Z"
          fill="#FFC0BB"
        />
        <Path
          d="M220.619 51.972c.295-.28.546-.265.546-.265l-.206-1.653-.561-.974s-2.124-2.612-2.197-2.671c-.074-.06-1.652-.871-1.741-.915-.103-.045-2.242.368-2.242.368s-3.82-.634-3.112 1.329c.723 1.962 3.525 2.892 3.658 2.804-.472-.428-.561-1.683-.561-1.683 2.065 2.022 5.826 3.439 6.416 3.66Z"
          fill="url(#N)"
        />
        <Path
          d="m187.463 37.155 12.316 103.822.604-.059-12.315-103.807-.605.044Z"
          fill="#473B1E"
        />
        <Path
          d="M190.442 70.611c-.014-.118.03-.472.045-.56a.589.589 0 0 1 .427-.458l.251-.074.015.561c-.015.428-.03.399.56.65.841.368.487.811.487.811s-.236.31-1.254-.206c0 0-.486-.251-.531-.724Z"
          fill="#FFC0BB"
        />
        <Path
          d="M190.369 71.084c0-.104.029-.458.044-.532a.578.578 0 0 1 .413-.428l.236-.073.015.53c-.015.4-.03.37.531.62.796.355.457.768.457.768s-.221.295-1.195-.192c-.015 0-.472-.25-.501-.693Z"
          fill="#FFC0BB"
        />
        <Path
          d="M190.487 71.733c0-.089.014-.384.029-.443a.48.48 0 0 1 .339-.369l.207-.059.015.443c-.015.34-.03.325.457.531.678.296.398.65.398.65s-.192.25-1.018-.163c0-.014-.398-.221-.427-.59ZM190.634 69.933c-.015-.119.03-.473.044-.561a.591.591 0 0 1 .428-.458l.251-.073.015.56c-.015.428-.03.399.56.65.841.369.487.811.487.811s-.236.31-1.254-.206c-.015 0-.501-.266-.531-.723Z"
          fill="#FFC0BB"
        />
        <Path
          d="M191.372 69.401c0 .089 0 .192.014.28.015.09.104.148.177.178.177.088.354.147.531.236.045.03.089.044.133.074.044.03.089.059.118.088a.636.636 0 0 1 .177.251c.03.103.03.207-.015.31-.014.03-.029.044-.044.074a.246.246 0 0 1-.074.059.283.283 0 0 1-.147.044.735.735 0 0 1-.31-.015 1.552 1.552 0 0 1-.28-.073c-.192-.06-.354-.148-.531-.236a1.182 1.182 0 0 1-.398-.414.88.88 0 0 1-.089-.28c-.015-.044 0-.089 0-.148l.015-.147v.147c0 .045 0 .089.015.133a.528.528 0 0 0 .103.251c.103.162.236.28.398.384.162.074.339.162.516.221.177.06.369.103.546.074.044-.015.089-.015.118-.044.015-.015.03-.03.044-.03.015-.015.015-.03.03-.059a.369.369 0 0 0 .015-.25.65.65 0 0 0-.148-.222c-.029-.03-.074-.06-.103-.089-.044-.03-.074-.044-.118-.074-.162-.088-.339-.162-.516-.25-.045-.03-.089-.045-.118-.089a.217.217 0 0 1-.059-.133c-.03-.074-.015-.162 0-.25Z"
          fill="#F99893"
        />
        <Path
          d="M192.109 70.95c.118.09.221.207.221.37a.41.41 0 0 1-.059.221c-.014.015-.029.044-.044.044-.015.015-.029.03-.059.03-.029.015-.074.03-.118.044a.95.95 0 0 1-.457-.03 2.084 2.084 0 0 1-.428-.147c-.074-.03-.133-.059-.206-.088a.831.831 0 0 1-.192-.119 1.292 1.292 0 0 1-.295-.339c-.03-.074-.059-.133-.074-.207 0-.044-.015-.073-.015-.118v-.103c.015-.147.03-.28.059-.428-.014.148-.014.28-.014.428v.103c0 .03.014.074.014.104.015.073.045.133.074.192.074.118.162.22.28.31.059.044.118.088.177.118s.133.059.192.088c.133.06.266.103.413.148.133.044.28.059.428.044.059-.015.133-.03.162-.089a.298.298 0 0 0 .059-.191.415.415 0 0 0-.044-.192c.015-.089-.03-.148-.074-.192Z"
          fill="#F99893"
        />
        <Path
          d="M192.109 71.63a.38.38 0 0 1 0 .31c-.015.03-.029.044-.044.073-.03.015-.044.03-.074.045-.059.03-.103.03-.162.044a1.516 1.516 0 0 1-.634-.118c-.104-.044-.192-.089-.295-.133a.872.872 0 0 1-.266-.192 1.044 1.044 0 0 1-.192-.25.797.797 0 0 1-.073-.31c.029.206.162.383.309.516.074.059.163.133.251.162.089.045.192.089.28.118.104.03.192.074.295.089.104.03.207.044.31.044.044 0 .103 0 .148-.03.029-.014.044-.014.059-.029.014-.015.029-.03.044-.059a.315.315 0 0 0 .044-.28Z"
          fill="#F99893"
        />
        <Path
          d="M191.888 72.058a.315.315 0 0 1 .118.25.518.518 0 0 1-.03.148c0 .015-.014.03-.014.03-.015.014-.015.03-.03.03-.029.014-.044.029-.074.043a.73.73 0 0 1-.309.015c-.104-.014-.192-.044-.281-.074a2.09 2.09 0 0 1-.265-.118c-.044-.03-.089-.044-.133-.073-.044-.03-.074-.06-.118-.089-.147-.133-.251-.31-.251-.502.03.192.148.34.295.458.03.03.074.059.118.073.045.03.089.045.118.06.089.044.177.073.266.103.088.03.177.059.265.074a.808.808 0 0 0 .266 0 .112.112 0 0 0 .059-.03c.015 0 .015-.015.015-.015s.014-.014.014-.03a.166.166 0 0 0 .045-.117c0-.044 0-.089-.015-.133-.015-.03-.03-.074-.059-.103Z"
          fill="#F99893"
        />
        <Defs>
          <LinearGradient
            id="a"
            x1={39.221}
            y1={100.692}
            x2={70.27}
            y2={100.692}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00503E" />
            <Stop offset={1} stopColor="#023222" />
          </LinearGradient>
          <LinearGradient
            id="b"
            x1={25.755}
            y1={119.757}
            x2={71.157}
            y2={119.757}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#C9CD55" />
            <Stop offset={0.506} stopColor="#659C52" />
            <Stop offset={1} stopColor="#287F50" />
          </LinearGradient>
          <LinearGradient
            id="c"
            x1={18.973}
            y1={118.741}
            x2={51.321}
            y2={118.741}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00503E" />
            <Stop offset={1} stopColor="#023222" />
          </LinearGradient>
          <LinearGradient
            id="d"
            x1={16.887}
            y1={135.556}
            x2={73.64}
            y2={135.556}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#C9CD55" />
            <Stop offset={0.506} stopColor="#659C52" />
            <Stop offset={1} stopColor="#287F50" />
          </LinearGradient>
          <LinearGradient
            id="e"
            x1={243.244}
            y1={117.996}
            x2={270.147}
            y2={117.996}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#C9CD55" />
            <Stop offset={0.506} stopColor="#659C52" />
            <Stop offset={1} stopColor="#287F50" />
          </LinearGradient>
          <LinearGradient
            id="f"
            x1={241.627}
            y1={128.164}
            x2={278.478}
            y2={128.164}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#C9CD55" />
            <Stop offset={0.506} stopColor="#659C52" />
            <Stop offset={1} stopColor="#287F50" />
          </LinearGradient>
          <LinearGradient
            id="g"
            x1={255.419}
            y1={39.33}
            x2={206.473}
            y2={122.145}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#C9CD55" />
            <Stop offset={0.506} stopColor="#659C52" />
            <Stop offset={1} stopColor="#287F50" />
          </LinearGradient>
          <LinearGradient
            id="h"
            x1={248.349}
            y1={137.528}
            x2={250.718}
            y2={137.528}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00503E" />
            <Stop offset={1} stopColor="#023222" />
          </LinearGradient>
          <LinearGradient
            id="i"
            x1={180.185}
            y1={46.443}
            x2={79.196}
            y2={168.749}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#C9CD55" />
            <Stop offset={0.506} stopColor="#659C52" />
            <Stop offset={1} stopColor="#287F50" />
          </LinearGradient>
          <LinearGradient
            id="j"
            x1={64.903}
            y1={137.528}
            x2={67.272}
            y2={137.528}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00503E" />
            <Stop offset={1} stopColor="#023222" />
          </LinearGradient>
          <LinearGradient
            id="k"
            x1={64.903}
            y1={57.081}
            x2={157.81}
            y2={57.081}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#C7A44F" />
            <Stop offset={1} stopColor="#609A3F" />
          </LinearGradient>
          <LinearGradient
            id="l"
            x1={150.472}
            y1={48.494}
            x2={165.24}
            y2={48.494}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00503E" />
            <Stop offset={1} stopColor="#023222" />
          </LinearGradient>
          <LinearGradient
            id="m"
            x1={158.597}
            y1={208.074}
            x2={269.321}
            y2={208.074}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#53CD9F" />
            <Stop offset={1} stopColor="#0D7A5C" />
          </LinearGradient>
          <LinearGradient
            id="n"
            x1={160.095}
            y1={209.204}
            x2={227.058}
            y2={209.204}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#53CD9F" />
            <Stop offset={1} stopColor="#0D7A5C" />
          </LinearGradient>
          <LinearGradient
            id="o"
            x1={221.713}
            y1={208.006}
            x2={275.395}
            y2={208.006}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#53CD9F" />
            <Stop offset={1} stopColor="#0D7A5C" />
          </LinearGradient>
          <LinearGradient
            id="p"
            x1={160.095}
            y1={188.187}
            x2={271.407}
            y2={188.187}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#53CD9F" />
            <Stop offset={1} stopColor="#0D7A5C" />
          </LinearGradient>
          <LinearGradient
            id="q"
            x1={163.661}
            y1={180.938}
            x2={274.135}
            y2={180.938}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00503E" />
            <Stop offset={1} stopColor="#023222" />
          </LinearGradient>
          <LinearGradient
            id="r"
            x1={205.259}
            y1={181.914}
            x2={274.002}
            y2={181.914}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00503E" />
            <Stop offset={1} stopColor="#023222" />
          </LinearGradient>
          <LinearGradient
            id="s"
            x1={158.247}
            y1={180.879}
            x2={211.145}
            y2={180.879}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00503E" />
            <Stop offset={1} stopColor="#023222" />
          </LinearGradient>
          <LinearGradient
            id="t"
            x1={174.384}
            y1={159.393}
            x2={265.191}
            y2={159.393}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#C9CD55" />
            <Stop offset={0.506} stopColor="#659C52" />
            <Stop offset={1} stopColor="#287F50" />
          </LinearGradient>
          <LinearGradient
            id="u"
            x1={173.814}
            y1={160.302}
            x2={231.284}
            y2={160.302}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#C9CD55" />
            <Stop offset={0.506} stopColor="#659C52" />
            <Stop offset={1} stopColor="#287F50" />
          </LinearGradient>
          <LinearGradient
            id="v"
            x1={226.169}
            y1={159.363}
            x2={269.346}
            y2={159.363}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#C9CD55" />
            <Stop offset={0.506} stopColor="#659C52" />
            <Stop offset={1} stopColor="#287F50" />
          </LinearGradient>
          <LinearGradient
            id="w"
            x1={191.376}
            y1={147.791}
            x2={229.471}
            y2={147.791}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#C9CD55" />
            <Stop offset={0.506} stopColor="#659C52" />
            <Stop offset={1} stopColor="#287F50" />
          </LinearGradient>
          <LinearGradient
            id="x"
            x1={183.989}
            y1={160.456}
            x2={191.185}
            y2={160.456}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#FB9A2B" />
            <Stop offset={1} stopColor="#E65925" />
          </LinearGradient>
          <LinearGradient
            id="y"
            x1={221.713}
            y1={192.978}
            x2={226.999}
            y2={192.978}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#FB9A2B" />
            <Stop offset={1} stopColor="#E65925" />
          </LinearGradient>
          <LinearGradient
            id="z"
            x1={189.673}
            y1={212.313}
            x2={200.829}
            y2={212.313}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#FB9A2B" />
            <Stop offset={1} stopColor="#E65925" />
          </LinearGradient>
          <LinearGradient
            id="A"
            x1={122.971}
            y1={74.835}
            x2={130.126}
            y2={74.835}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#FB9A2B" />
            <Stop offset={1} stopColor="#E65925" />
          </LinearGradient>
          <LinearGradient
            id="B"
            x1={101.861}
            y1={75.677}
            x2={107.967}
            y2={75.677}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#FB9A2B" />
            <Stop offset={1} stopColor="#E65925" />
          </LinearGradient>
          <LinearGradient
            id="C"
            x1={100.173}
            y1={19.494}
            x2={111.409}
            y2={19.494}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#FB9A2B" />
            <Stop offset={1} stopColor="#E65925" />
          </LinearGradient>
          <LinearGradient
            id="D"
            x1={109.965}
            y1={99.041}
            x2={81.74}
            y2={131.917}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00503E" />
            <Stop offset={1} stopColor="#023222" />
          </LinearGradient>
          <LinearGradient
            id="E"
            x1={105.11}
            y1={94.873}
            x2={76.885}
            y2={127.749}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00503E" />
            <Stop offset={1} stopColor="#023222" />
          </LinearGradient>
          <LinearGradient
            id="F"
            x1={99.146}
            y1={89.753}
            x2={70.921}
            y2={122.629}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00503E" />
            <Stop offset={1} stopColor="#023222" />
          </LinearGradient>
          <LinearGradient
            id="G"
            x1={111.352}
            y1={100.232}
            x2={83.127}
            y2={133.108}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#00503E" />
            <Stop offset={1} stopColor="#023222" />
          </LinearGradient>
          <LinearGradient
            id="H"
            x1={168.713}
            y1={41.549}
            x2={188.445}
            y2={41.549}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#FB9A2B" />
            <Stop offset={1} stopColor="#E65925" />
          </LinearGradient>
          <LinearGradient
            id="I"
            x1={203.955}
            y1={140.181}
            x2={212.026}
            y2={140.181}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#FB9A2B" />
            <Stop offset={1} stopColor="#E65925" />
          </LinearGradient>
          <LinearGradient
            id="J"
            x1={217.059}
            y1={140.188}
            x2={225.13}
            y2={140.188}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#FB9A2B" />
            <Stop offset={1} stopColor="#E65925" />
          </LinearGradient>
          <LinearGradient
            id="K"
            x1={190.795}
            y1={79.195}
            x2={205.815}
            y2={70.066}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#53CD9F" />
            <Stop offset={1} stopColor="#0D7A5C" />
          </LinearGradient>
          <LinearGradient
            id="L"
            x1={209.641}
            y1={44.489}
            x2={219.386}
            y2={53.011}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#FB9A2B" />
            <Stop offset={1} stopColor="#E65925" />
          </LinearGradient>
          <LinearGradient
            id="M"
            x1={207.212}
            y1={74.98}
            x2={230.058}
            y2={74.98}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#53CD9F" />
            <Stop offset={1} stopColor="#0D7A5C" />
          </LinearGradient>
          <LinearGradient
            id="N"
            x1={210.549}
            y1={43.45}
            x2={220.294}
            y2={51.972}
            gradientUnits="userSpaceOnUse"
          >
            <Stop stopColor="#FB9A2B" />
            <Stop offset={1} stopColor="#E65925" />
          </LinearGradient>
        </Defs>
      </Svg>
    )
  }
}
// const SignUpBackground = (props) => (
  
// )

export default SignUpBackground;
