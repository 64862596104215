import React, { Component } from 'react';
import { StyleSheet, View, FlatList, Image, Dimensions } from 'react-native';
import { code_ok, code_error, code_auth_error, code_empty, statistikKoleksi } from '../../constants/api';
import { Skeleton, Typography, Card, CardActionArea, CardMedia, CardContent, Button } from '@mui/material';
import { executeGet, executePost, truncateText, navigateContentDetail } from '../../utilities/Utilities';
import AlertDialog from '../../components/AlertDialog';
import { color_text, sub_text, primary } from '../../constants/colors';
import * as Identifier from '../../constants/PageIdentifiers';
import { IconAudioBookLight, IconMeet, IconMessenger } from '../../assets/Icons';

class MenuStatistikDesktop extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            showAlert: false,
            alertMessage: "",
            loadingMessage: "",
            code: this.props.code,
            data: [],
            dataCard: [
                {
                    "code": "HMP-SD",
                    "title": "Koleksi SD/MI",
                    "image": "https://bintangpusnas.perpusnas.go.id/images/icon/image_edukasi.png",
                    "description": "Berisi koleksi-koleksi buku penunjang dan bahan bacaan untuk anak sekolah SD/MI. Sehingga kebutuhan literasi mereka terpenuhi"
                },
                {
                    "code": "HMP-SMP",
                    "title": "Koleksi SMP/MTs",
                    "image": "https://bintangpusnas.perpusnas.go.id/images/icon/image_smp.png",
                    "description": "Berisi koleksi-koleksi buku penunjang dan bahan bacaan untuk anak sekolah SMP/MTs. Sehingga kebutuhan literasi mereka terpenuhi"
                },
                {
                    "code": "HMP-SMA",
                    "title": "Koleksi SMA/SMK/MA",
                    "image": "https://bintangpusnas.perpusnas.go.id/images/icon/image_sma.png",
                    "description": "Berisi koleksi-koleksi buku penunjang dan bahan bacaan untuk anak sekolah SLTA. Sehingga kebutuhan literasi mereka terpenuhi"
                },
                {
                    "code": "HMP-PERTI",
                    "title": "Koleksi Perguruan Tinggi",
                    "image": "https://bintangpusnas.perpusnas.go.id/images/icon/image_perti.png",
                    "description": "Berisi koleksi-koleksi buku penunjang dan bahan bacaan untuk mahasiswa. Sehingga kebutuhan literasi mereka terpenuhi"
                }
            ],
            dummy_data: [
                { i: 0 }, { i: 1 }, { i: 2 }
            ]

        }

        this.handleAlertClose = this.handleAlertClose.bind(this);
        this.handleMoreClick = this.handleMoreClick.bind(this);
        this.fetchContents = this.fetchContents.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.handleImageError = this.handleImageError.bind(this);
        this.contentDetail = this.contentDetail.bind(this);
    }

    componentDidMount = () => {
        this.fetchContents();
    }

    handleAlertClose = () => {

    }
    handleMoreClick = (code, title) => {
        this.props.navigation.navigate("SectionDetail", { identifier: Identifier.SECTION_DETAIL, code: code, title: title, origins: "CONTENT_RECOMMENDED" });
    }

    fetchContents = async () => {

        const response = await executeGet(statistikKoleksi);
        if (response.code == code_ok) {
            console.log(JSON.stringify(response));
            console.log(JSON.stringify(this.state.data));
            this.setState({
                showLoading: false,
                data : response.data
            })
        } else {
            if (response.code != code_auth_error) {
                this.setState({
                    showLoading: false,
                    alertMessage: response.msg,
                    showAlert: true
                })
            } else {
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            }
        }
    }

    renderItem = ({ item, index, separators }) => {
        return (
            <Card
                style={{
                    borderRadius: 8,
                    padding: 21,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: (index == 0 ? 0 : 21),
                    marginBottom: (index == (this.state.data.length - 1) ? 21 : 0),
                    boxShadow: "0px 33px 74px rgba(0, 0, 0, 0.0196802), 0px 13.7866px 30.9154px rgba(0, 0, 0, 0.0282725), 0px 7.37098px 16.5289px rgba(0, 0, 0, 0.035), 0px 4.13211px 9.26595px rgba(0, 0, 0, 0.0417275), 0px 2.19453px 4.92108px rgba(0, 0, 0, 0.0503198), 0px 0.913195px 2.04777px rgba(0, 0, 0, 0.07)"
                }}
            >
                <CardActionArea
                    // onClick={() => { this.contentDetail(item, index) }}
                >
                    <View style={{ flexDirection: "row", padding: 8 }}>
                        <Image
                            style={{
                                width: 90,
                                height: 90,
                                borderRadius: '50%'
                            }}
                            source={{ uri: item.cover }}
                            onError={(error) => { this.handleImageError(item, index) }}
                        />
                        {/* <IconKoleksiBukuBSEDepag /> */}
                        <View style={{ flex: 1, flexDirection: 'row', alignSelf: 'center' }}>
                            <View style={{ flex: 0.7, marginLeft: 30 }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={22}
                                    color={color_text}
                                    fontWeight={"600"}
                                >
                                    {truncateText(item.title, 30)}
                                    {/* { item.title } */}
                                </Typography>
                                

                            </View>
                            <View style={{ flex: 0.3, marginLeft: 10 }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={22}
                                    color={color_text}
                                    fontWeight={"600"}
                                    style={{ textAlign: 'right' }}
                                >
                                    {truncateText(item.total1, 50)}
                                    {/* { item.title } */}
                                </Typography>
                                

                            </View>
                        </View>

                    </View>
                </CardActionArea>
            </Card>
        )
    }

    contentDetail = (item, index) => {
        let params = {
            previous: Identifier.MAIN,
            parameters: {},
            content_code: item.content_code
        }
        navigateContentDetail(this.props.navigation, params);
    }
    handleImageError = (item, index) => {
        let data = [...this.state.data];
        let obj = { ...data[index] };
        obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
        data[index] = obj;
        this.setState({
            data: data
        })
    }

    renderItemCard = ({ item, index, separators }) => {
        //== ATTENTION FOR YOUTUBE TYPE ==//

        return (
            <Card
                sx={{ maxWidth: 255 }}
                style={{
                    borderRadius: 8,
                    marginLeft: (index == 0 ? 16 : 4),
                    marginRight: (index == (this.state.data.length - 1) ? 26 : 26),
                    marginBottom: 8,
                    marginTop: 4,
                    boxShadow: "0px 33px 74px rgba(0, 0, 0, 0.0196802), 0px 13.7866px 30.9154px rgba(0, 0, 0, 0.0282725), 0px 7.37098px 16.5289px rgba(0, 0, 0, 0.035), 0px 4.13211px 9.26595px rgba(0, 0, 0, 0.0417275), 0px 2.19453px 4.92108px rgba(0, 0, 0, 0.0503198), 0px 0.913195px 2.04777px rgba(0, 0, 0, 0.07)"
                }}>
                <CardActionArea
                    sx={{ height: "100%" }}
                    onClick={() => { 
                        let params = { code: item.code, title: item.title, origins: "CONTENT_SECTION" };
                        this.props.navigation.navigate("DesktopSectionDetail", params);
                    }}
                >
                    <CardMedia
                        image={item.image}
                        height={110}
                        component="img"
                    />
                    <CardContent
                        style={{ flex: 1 }}
                    >
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={14}
                            fontWeight={"600"}
                            color={color_text}
                            style={{ marginBottom: 8, flex: 1 }}
                        >
                            {item.title}
                        </Typography>

                        <Typography
                            fontFamily="Open Sans"
                            fontSize={12}
                            fontWeight={"400"}
                            color={sub_text}
                            style={{ marginBottom: 8 }}
                        >
                            {item.description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    }

    render() {
        return (
            <View style={{ width: "100%", paddingLeft: 147, paddingRight: 147 }}>
                {
                    this.state.showLoading &&
                    <View>
                        <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: '2rem', maxWidth: 120 }}
                            style={{ marginLeft: 16, marginBottom: 8, marginRight: 16, marginTop: 16 }}
                        />
                        <Skeleton
                            variant="rectangular"
                            style={{ height: 90, marginTop: 8, borderRadius: 8, marginLeft: 16, marginRight: 16 }}
                            animation="wave"
                        />
                        <Skeleton
                            variant="rectangular"
                            style={{ height: 90, marginTop: 8, borderRadius: 8, marginLeft: 16, marginRight: 16 }}
                            animation="wave"
                        />
                        <Skeleton
                            variant="rectangular"
                            style={{ height: 90, marginTop: 8, borderRadius: 8, marginLeft: 16, marginRight: 16 }}
                            animation="wave"
                        />
                    </View>
                }
                {
                    !this.state.showLoading &&
                    <View style={{ marginTop: 30 }}>
                        <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 20 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={28}
                                variant="h3"
                                style={{ flex: 1, marginLeft: 10 }}
                                color={color_text}
                                fontWeight={"600"}
                            >
                                {truncateText(this.props.title, 30)}
                            </Typography>
                        </View>

                        <View style={{
                            flex: 1,
                            flexDirection: "row"
                        }}>
                            <View style={{ flex: 1 }} >
                                <FlatList
                                    keyExtractor={(item, index) => "rec_" + item.content_code}
                                    data={this.state.data.slice(0, 3)}
                                    renderItem={this.renderItem}
                                    showsVerticalScrollIndicator={false}
                                />
                            </View>

                            <View style={{ flex: 1 }} >
                                <FlatList
                                    keyExtractor={(item, index) => "rec_" + item.content_code}
                                    data={this.state.data.slice(3, 6)}
                                    renderItem={this.renderItem}
                                    showsVerticalScrollIndicator={false}
                                />
                            </View>
                        </View>

                        <View style={{ marginTop: '30px' }}>
                            <FlatList
                                keyExtractor={(item, index) => item.homepage_code + "_" + item.news_code}
                                data={this.state.dataCard}
                                horizontal={true}
                                renderItem={this.renderItemCard}
                            />
                        </View>

                    </View>

                }


            </View>
        )
    }

}

const styles = {
    media: {
        height: 0,
        paddingTop: '56.25%' // 16:9
    },
    card: {
        position: 'relative',
        margin: '16px',
        borderRadius: '15px'
    },
    overlay: {
        position: 'absolute',
        bottom: '39px',
        left: '20px',
        color: 'white',
        fontSize: '18px'
    },
    overlaySub: {
        position: 'absolute',
        bottom: '12px',
        left: '20px',
        color: 'white',
        fontSize: '12px'
    }
}

export default MenuStatistikDesktop;