import React, { Component } from 'react'; 
import { View, StyleSheet , Image, FlatList, Dimensions, ScrollView  } from 'react-native'; 
import { Alert, AlertTitle, Typography, Skeleton, Card, CardActionArea , CircularProgress, 
    IconButton, Chip, List , ListItemButton, ListItemIcon, 
    ListItemText, Collapse } from '@mui/material'; 
import LoadingPage from '../components/LoadingPage'; 
import AlertDialog from '../components/AlertDialog'; 
import * as Identifier from '../constants/PageIdentifiers';
import * as Command from '../constants/Commands'; 
import { code_ok, code_empty, code_error, code_auth_error, categories, categoryContent } from '../constants/api';
import { executeGet, executePost , isEven, truncateText } from '../utilities/Utilities'; 
import  EmptySearchPage  from '../components/EmptySearchPage';
import { color_text, sub_text, primary } from '../constants/colors';
import SortIcon from '@mui/icons-material/Sort';
import SortDialog from '../components/SortDialog';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

var _is_mounted = false; 
var window_width;
var book_width;
var book_height;

class CategoryPage extends Component {
    constructor(props){
        super(props)
        this.state = {
            showLoading : true, 
            moreLoading : false, 
            loadingMessage : "", 
            showAlert : false,
            alertMessage : "",
            selectedTypeCode : "-", 
            selectedSubTypeCode : "-", 
            data : [], 
            dummy_data : [
                {i : 0}, { i : 1 }, {i : 2 }, { i : 3 }, {i : 0}, { i : 1 }, {i : 2 }, { i : 3 }
            ],
            page : 1, 
            total : 0, 
            show : 0, 
            showSortDialog : false,
            sort_type : -1,
            sort_menu : [
                {id : "001", type: 1, title : "Judul A-Z"},{id : "002", type : 2, title : "Judul Z-A"}, 
                {id:"003", type : 3, title : "Penulis A-Z"}, { id : "004", type : 4 , title : "Penulis Z-A"}
            ]
        }
        this.goBack = this.goBack.bind(this); 
        //this.renderSkeleton = this.renderSkeleton.bind(this);
        //this.renderItem = this.renderItem.bind(this); 
        this.fetchContent = this.fetchContent.bind(this); 
        //this.onImageError = this.onImageError.bind(this); 
        //this.fetchMoreData = this.fetchMoreData.bind(this); 
        this.handleTypeClick = this.handleTypeClick.bind(this); 
        this.handleSubTypeClick = this.handleSubTypeClick.bind(this); 
        this.handleCategoryClick = this.handleCategoryClick.bind(this);

    }

    componentDidMount = ()=>{
        _is_mounted = true;
        if (global.os == "ANDROID") {
            JSBridge.launchFunction(Command.SET_PAGE, Identifier.CATEGORY_PAGE);    
        }
        window.addEventListener("message", (event)=>{
            if(event.data.id == "go_back"){
                if(event.data.page == Identifier.CATEGORY_PAGE){
                    this.goBack(); 
                }
            }
        }); 
        this.fetchContent(); 
    }

    componentWillUnmount = ()=>{
        _is_mounted = false;
        window.removeEventListener("message", null); 
    }

    goBack = ()=>{
        this.props.navigation.navigate(Identifier.MAIN);
    }

    fetchContent = async()=>{
        const response = await executeGet(categories); 
        if(response.code == code_ok){
            this.setState({
                showLoading : false,
                data : response.data
            }); 
            //console.log(JSON.stringify(response.data)); 

        } else {
            if(response.code == code_auth_error){
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out"});
            } else {
                this.setState({
                    showLoading : false, 
                    showAlert : true, 
                    alertMessage : response.msg
                })
            }
        }
    }

    handleTypeClick = (item, index)=>{
        if(_is_mounted){
            if(this.state.selectedTypeCode != item.type_code){
                this.setState({
                    selectedTypeCode : item.type_code
                })
            } else {
                this.setState({
                    selectedTypeCode : "-"
                })
            }
        }
    }

    handleSubTypeClick = (item, index)=>{
        if(_is_mounted){
            if(this.state.selectedSubTypeCode != item.sub_type_code){
                this.setState({
                    selectedSubTypeCode : item.sub_type_code
                }) 
            } else {
                this.setState({
                    selectedSubTypeCode : "-"
                })
            }
        }
    }
    handleCategoryClick = (item, index)=>{
        if(_is_mounted){
            let code = item.category_code;
            let title = item.category;
            this.props.navigation.navigate("ContentCategoryPage",{ code : code, title : title, identifier : Identifier.CATEGORY_PAGE })

        }
    }

    render(){
        window_width = Dimensions.get("window").width; 
        book_width = (window_width / 2) - 16 ; 
        book_height = book_width + 40;
        
        return(
            <View style={{flex : 1}}>
                <View style={{
                    marginLeft : 16 , 
                    //marginTop : (global.os == "ANDROID" ? 16 : 48 ) , 
                    marginTop : 16, 
                    marginBottom : 16 ,
                    flex : 0.05,
                    backgroundColor : "#F2F2F2",
                    flexDirection : "row", 
                    alignItems : "center"
            
                }}>
                    
                    <IconButton
                        style={{ marginRight : 16 }}
                        onClick={()=>{ this.goBack() }}
                    >
                        <ChevronLeftIcon 
                            sx={{color : color_text }}
                        />
                    </IconButton>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 18 }
                        fontWeight="600"
                        style={{ flex : 1}}
                    >
                       Kategori
                    </Typography>
                    {/* <IconButton
                        style={{
                            marginRight : 16 
                        }}
                        onClick={ this.handleSortMenuClick }
                    >
                        <SortIcon />
                    </IconButton> */}
                </View>
                
                {
                    this.state.showLoading &&
                    <View style={{flex : 0.95, alignItems : 'center', backgroundColor : "white"}}>
                        <LoadingPage 
                            message={"Memuat data"}
                        />
                    </View>
                }
                {
                    (!this.state.showLoading && this.state.data.length > 0) && 
                    <ScrollView style={{ flex : 0.95, marginTop : 8 }}>
                        <List>
                            {
                                this.state.data.map((item, index)=>(
                                    <>
                                    <ListItemButton component="a" onClick={()=>{ this.handleTypeClick(item, index)}}>
                                        <ListItemIcon key={ index }>
                                            {
                                                (this.state.selectedTypeCode == item.type_code) && 
                                                <ExpandMoreIcon />
                                            }
                                            {
                                                (this.state.selectedTypeCode != item.type_code) && 
                                                <ChevronRightIcon />
                                            }
                                            
                                        </ListItemIcon>
                                        <ListItemText primary={ item.type } />

                                    </ListItemButton>
                                    <Collapse in={ this.state.selectedTypeCode == item.type_code }>
                                        <List component="div" disablePadding>
                                        {
                                            item.sub_types.map((i, d)=>(
                                            <>
                                                <ListItemButton key={ "child_"+d} style={{marginLeft : 8 }} onClick={()=>{ this.handleSubTypeClick(i, d)}}>
                                                    <ListItemIcon key={ index }>
                                                    {
                                                        (this.state.selectedSubTypeCode == i.sub_type_code) && 
                                                        <ExpandMoreIcon />
                                                    }
                                                    {
                                                        (this.state.selectedSubTypeCode != i.sub_type_code) && 
                                                        <ChevronRightIcon />
                                                    }
                                                    </ListItemIcon>
                                                    <ListItemText primary={ i.sub_type } />
                                                </ListItemButton>
                                                <Collapse in={ this.state.selectedSubTypeCode == i.sub_type_code }>
                                                    <List component="div" disablePadding>
                                                    {
                                                        i.categories.map((x,y)=>(
                                                            <ListItemButton disablePadding key={ "sub_child_"+y}  onClick={()=>{ this.handleCategoryClick(x, y)}}>
                                                                <ListItemText 
                                                                    style={{marginLeft : 16, marginRight : 16 }}
                                                                    primary={ x.category } />
                                                                {/* <ListItemIcon key={ y }>
                                                                    <ChevronRightIcon />
                                                                </ListItemIcon> */}
                                                            </ListItemButton>
                                                        ))
                                                    }
                                                    </List>
                                                </Collapse>
                                            </>
                                            ))
                                        }
                                        </List>
                                    </Collapse>
                                    
                                    </>

                                ))
                            }

                        </List>
                    </ScrollView>
                }
                {
                    (!this.state.showLoading && this.state.data.length == 0) && 
                    <View style={{ flex : 0.95}}>
                        <EmptySearchPage 
                            title={"Data tidak tersedia"}
                            message={"Data dihalaman ini belum tersedia"}
                        />
                    </View>
                }
                {
                    this.state.showAlert && 
                    <Alert 
                        onClose={()=>{ this.goBack() }}
                        severity="error"
                    > 
                        <AlertTitle>Error</AlertTitle>
                        { this.state.alertMessage }
                    </Alert>

                }
                

            </View>
        )
    }
}

export default CategoryPage;