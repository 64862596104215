import React, { Component } from 'react';
import { StyleSheet, View, } from 'react-native'; 
import { primary , color_text , } from '../constants/colors'; 
import { Typography, Radio, RadioGroup } from '@mui/material';

class RadioBox extends Component {
    constructor(props){
        super(props)
    }

    render(){
        return(
            <View style={ this.props.containerStyle }> 
                <Typography
                    variant="h3"
                    fontWeight="400"
                    fontSize={ 14 } 
                    fontFamily="Open Sans"
                    marginLeft={0.5}
                >
                    { this.props.caption }
                </Typography>
                <View style={{flexDirection : "row", alignItems : "center"}}>
                    <Radio 
                        value="L"
                        name="radio-l"
                        checked={ this.props.value == this.props.valueA }
                        onChange={ this.props.onChange }
                    />
                    <Typography
                        variant="h3"
                        fontWeight="400"
                        fontSize={ 14 } 
                        fontFamily="Open Sans"
                        color={ this.props.value == this.props.valueA ? primary : color_text }
                    >
                        { this.props.captionA }
                    </Typography>

                    <Radio
                        value="P"
                        name="radio-p"
                        checked={ this.props.value == this.props.valueB }
                        onChange={ this.props.onChange }
                    />
                    <Typography
                        variant="h3"
                        fontWeight="400"
                        fontSize={ 14 }  
                        fontFamily="Open Sans"
                        color={ this.props.value == this.props.valueB ? primary : color_text }
                    >
                        { this.props.captionB }
                    </Typography>
                    
                </View>


            </View>
        )
    }
}

export default RadioBox;