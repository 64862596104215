import React, { Component } from 'react';
import { StyleSheet, View, Image, FlatList, Pressable, Dimensions } from 'react-native';
import { Alert, AlertTitle, IconButton, Typography, Card, CardActionArea, CardMedia, CardContent, Skeleton } from '@mui/material';
import { code_ok, code_error, code_empty, code_auth_error, newsList } from '../constants/api';
import { primary, color_text, sub_text } from '../constants/colors';
import { executeGet, executePost, truncateText } from '../utilities/Utilities';
import * as Identifier from '../constants/PageIdentifiers';
import * as Command from '../constants/Commands';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

var _is_mounted = false;
var window_width;
var book_width;
var book_height;

class NewsDesktop extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            showAlert: false,
            alertMessage: "",
            dummy_data: [
                { i: 0 }, { i: 1 }, { i: 2 }, { i: 3 }, { i: 4 }
            ],
            data: [],
            page: 1,
            show: 0,
            total: 0
        }
        this.goBack = this.goBack.bind(this);
        this.renderSkeleton = this.renderSkeleton.bind(this);
        this.fetchNews = this.fetchNews.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.newsDetail = this.newsDetail.bind(this);
    }

    componentDidMount = () => {
        _is_mounted = true;
        console.log("[PAGE] NEWS");

        window.addEventListener("message", (event) => {

            if (event.data.id == "go_back") {
                if (global.currentRouteName == Identifier.NEWS) {
                    this.goBack();
                }
            }
        });
        this.fetchNews();
    }
    componentWillUnmount = () => {
        _is_mounted = false;
        window.removeEventListener("message", null);
    }

    goBack = () => {
        //JSBridge.launchFunction(Command.SET_PAGE, this.props.route.params.identifier );
        //Bugs On react-navigation/native , https://github.com/react-navigation/react-navigation/issues/8771

        //this.props.navigation.navigate(this.props.route.params.identifier);
        this.props.navigation.goBack();
    }

    fetchNews = async () => {
        let code = this.props.route.params.code;
        console.log(this.props.route.params.tipe)
        if (this.props.route.params.tipe!="youtubeUtama") {
            let formData = new FormData();
            formData.append("page", this.state.page);
            formData.append("code", code);
            this.setState({
                showLoading: true
            })
            const response = await executePost(newsList, formData);
            if (response.code == code_ok) {
                if (_is_mounted) {
                    if (parseInt(response.show) > 0) {
                        let show = parseInt(this.state.show) + parseInt(response.show);
                        let total = parseInt(response.total);
                        let data = [...this.state.data, ...response.data];
                        console.log("BERITA");
                        console.log(response);
                        this.setState({
                            showLoading: false,
                            show: show,
                            total: total,
                            data: data
                        })
                    } else {
                        this.setState({
                            showLoading: false
                        })
                    }
                }

            } else {
                if (response.code == code_auth_error) {
                    // if(global.os == "ANDROID") {
                    //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                    // } else {
                    //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                    // }
                    window.postMessage({ id: "sign_out" });
                } else {
                    this.setState({
                        showLoading: false,
                        showAlert: true,
                        alertMessage: response.msg
                    })
                }
            }
        } else {
            let response = await executeGet("https://bintangpusnas.perpusnas.go.id/api/youtubeUtama")
            console.log("youtube news = " + JSON.stringify(response))
            if (response.code == code_ok) {
                let total = parseInt(response.total);
                let data = [...this.state.data, ...response.data];
                console.log("BERITA");
                console.log(response);
                this.setState({
                    showLoading: false,
                    show: 1,
                    total: total,
                    data: data
                })

            } else {
                if (response.code != code_auth_error) {
                    this.setState({
                        showLoading: false,
                    })
                } else {
                    window.postMessage({ id: "sign_out" });
                }
            }
        }


    }

    renderSkeleton = ({ item, index, separators }) => {
        return (
            <View
                style={{
                    marginLeft: 16,
                    marginRight: 16,
                    marginBottom: 12,
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        alignItems: "center"
                    }}
                >

                    <View style={{ flex: 1 }} >
                        <Skeleton
                            animation="wave"
                            variant="text"
                            sx={{ fontSize: "2rem" }}
                            style={{ width: 180, marginBottom: 8 }}
                        />
                        <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: "1.5rem" }}
                            style={{ marginBottom: 4, marginRight: 12 }}
                        />
                        <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: "1.5rem" }}
                            style={{ marginBottom: 4, width: 90 }}
                        />
                    </View>
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        style={{ width: 90, height: 90, borderRadius: 8 }}
                    />
                </View>
                <View
                    style={{
                        height: 0.5,
                        marginTop: 8,
                        marginBottom: 8,
                        backgroundColor: "silver"
                    }}
                />

            </View>
        )
    }

    renderItem = ({ item, index, separators }) => {
        return (
            
            <View
                style={{
                    width: book_width,
                    height: book_height,
                    borderRadius: 8
                }}
            >
                
                {/* {item.title} */}
                <Card
                    sx={{ maxWidth: 300 }}
                    style={{
                        borderRadius: 8,
                        marginLeft: 8,
                        marginRight: 8,
                        marginTop: 16,
                        boxShadow: "0px 33px 74px rgba(0, 0, 0, 0.0196802), 0px 13.7866px 30.9154px rgba(0, 0, 0, 0.0282725), 0px 7.37098px 16.5289px rgba(0, 0, 0, 0.035), 0px 4.13211px 9.26595px rgba(0, 0, 0, 0.0417275), 0px 2.19453px 4.92108px rgba(0, 0, 0, 0.0503198), 0px 0.913195px 2.04777px rgba(0, 0, 0, 0.07)"
                    }}>
                    <CardActionArea
                        sx={{ height: "100%" }}
                        onClick={() => { this.newsDetail(item, index) }}
                    >
                        <CardMedia
                            image={item.content_image}
                            height={145}
                            component="img"
                        />
                        <CardContent
                            style={{ flex: 1 }}
                        >
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={14}
                                fontWeight={"600"}
                                color={color_text}
                                style={{ marginBottom: 8, flex: 1 }}
                            >
                                {truncateText(item.title, 32)}
                            </Typography>

                            <Typography
                                fontFamily="Open Sans"
                                fontSize={12}
                                fontWeight={"400"}
                                color={sub_text}
                                style={{ marginBottom: 8 }}
                            >
                                {item.input_time}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </View>
        )
    }

    newsDetail = (item, index) => {
        item.identifier = Identifier.NEWS;
        this.props.navigation.navigate("DesktopNewsDetail", item);
    }

    render() {
        window_width = Dimensions.get("window").width;
        book_width = (window_width / 6) - 16;
        book_height = book_width + 40;

        return (
            <View style={{ flex: 1 }}>
                <View style={{
                    // marginLeft: 16,
                    //marginTop : (global.os == "ANDROID" ? 16 : 48 ) , 
                    paddingTop: 16,
                    paddingBottom: 16,
                    marginBottom: 16,
                    flex: 0.05,
                    backgroundColor: "white",
                    flexDirection: "row",
                    alignItems: "center",
                    boxShadow: "0 4px 6px -1px rgba(0,0,0, 0.07)"
                }}>

                    <IconButton
                        style={{ marginRight: 16, marginLeft: 16 }}
                        onClick={() => { this.goBack() }}
                    >
                        <ChevronLeftIcon
                            sx={{ color: color_text }}
                        />
                    </IconButton>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={18}
                        fontWeight={"600"}
                        style={{ flex: 1 }}
                    >
                        Berita
                    </Typography>
                </View>
                <View style={{ flex: 0.95 }}>
                    {
                        this.state.showLoading &&
                        <FlatList
                            keyExtractor={(i, x) => "idx_" + x}
                            renderItem={this.renderSkeleton}
                            data={this.state.dummy_data}

                        />
                    }
                    {
                        !this.state.showLoading &&
                        // <FlatList 
                        //     keyExtractor={(i, x)=>"news_code_"+i.code+"_"+x} 
                        //     renderItem={ this.renderItem }
                        //     data={ this.state.data }
                        // />
                        <View
                            style={{
                                marginTop: 14,
                                paddingLeft: 147, 
                                paddingRight: 147
                            }}
                        >
                            <FlatList
                                keyExtractor={(item, index) => "item_index_" + index}
                                data={this.state.data}
                                renderItem={this.renderItem}
                                style={{ marginLeft: 16, marginRight: 16 }}
                                numColumns={5}
                                
                                onEndReachedThreshold={0.5}
                                onEndReached={this.fetchMoreData}
                                ListFooterComponent={
                                    this.state.moreLoading &&
                                    <View style={{ alignItems: "center", padding: 8 }}>
                                        <CircularProgress
                                            size={32}
                                        />
                                    </View>
                                }
                            />
                        </View>
                        
                    }
                </View>
                {
                    this.state.showAlert &&
                    <Alert
                        onClose={() => { this.goBack() }}
                        severity="error"
                    >
                        <AlertTitle>Error</AlertTitle>
                        {this.state.alertMessage}
                    </Alert>

                }
            </View>
        )
    }
}



export default NewsDesktop;