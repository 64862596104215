import * as React from "react"
import Svg, { Circle, Path } from "react-native-svg"


class NotificationIcon extends React.Component {
    constructor(props){
        super(props)
    }
    render(){
        return(
            <Svg
                width={34}
                height={34}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...this.props}
            >
                <Circle cx={17} cy={17} r={17} fill="#fff" fillOpacity={0.35} />
                <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.325 8.938a4.234 4.234 0 0 0-4.201 4.758l.06.477a2.176 2.176 0 0 1-.684 1.868 3.3 3.3 0 0 0-.731 3.867l.077.16a3.54 3.54 0 0 0 2.965 1.988l.14.242c.76 1.302 1.944 2.015 3.187 2.015 1.242 0 2.426-.713 3.185-2.015l.144-.246a3.421 3.421 0 0 0 2.058-5.918l-.033-.03a2.35 2.35 0 0 1-.733-2.015l.049-.387a4.238 4.238 0 0 0-4.206-4.764h-1.277Zm-2.198 12H20.197a2.296 2.296 0 0 0 1.563-3.98l-.033-.03a3.476 3.476 0 0 1-1.084-2.978l.049-.388a3.113 3.113 0 0 0-3.09-3.5h-1.277a3.109 3.109 0 0 0-3.085 3.495l.06.476a3.3 3.3 0 0 1-1.037 2.835c-.705.65-.9 1.686-.482 2.549l.077.16a2.415 2.415 0 0 0 2.173 1.36h.096Zm3.01 2.25c-.69 0-1.427-.347-1.997-1.125h3.995c-.57.778-1.307 1.125-1.997 1.125Z"
                fill="#fff"
                />
            </Svg>
        )
    }
}

export default NotificationIcon;