import React, { Component } from 'react'; 
import { StyleSheet, View, Image , FlatList, Pressable } from 'react-native'; 
import { Alert, AlertTitle, IconButton, Typography, Card, CardActionArea, CardMedia, CardContent, Skeleton } from '@mui/material'; 
import { code_ok, code_error, code_empty, code_auth_error, newsList   } from '../constants/api'; 
import { primary, color_text, sub_text } from '../constants/colors';
import { executeGet, executePost } from '../utilities/Utilities';
import * as Identifier from '../constants/PageIdentifiers'; 
import * as Command from '../constants/Commands';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

var _is_mounted = false;
class News extends Component { 
    constructor(props){
        super(props)
        this.state = {
            showLoading : true, 
            showAlert : false, 
            alertMessage : "", 
            dummy_data : [
                {i:0},{i:1},{i:2},{i:3},{i:4} 
            ],
            data : [],
            page : 1, 
            show : 0 , 
            total : 0
        }
        this.goBack = this.goBack.bind(this); 
        this.renderSkeleton = this.renderSkeleton.bind(this); 
        this.fetchNews = this.fetchNews.bind(this); 
        this.renderItem = this.renderItem.bind(this); 
        this.newsDetail = this.newsDetail.bind(this); 
    }

    componentDidMount = ()=>{
        _is_mounted = true; 
        console.log("[PAGE] NEWS");
        if (global.os == "ANDROID") {
            JSBridge.launchFunction(Command.SET_PAGE, Identifier.NEWS);    
        }
        window.addEventListener("message", (event)=>{
        
            if(event.data.id == "go_back"){
                if(global.currentRouteName == Identifier.NEWS) {
                    this.goBack(); 
                }
            }
        });
        this.fetchNews();
    }
    componentWillUnmount = ()=>{
        _is_mounted = false; 
        window.removeEventListener("message", null);
    }

    goBack = ()=>{
        //JSBridge.launchFunction(Command.SET_PAGE, this.props.route.params.identifier );
        //Bugs On react-navigation/native , https://github.com/react-navigation/react-navigation/issues/8771
        
        //this.props.navigation.navigate(this.props.route.params.identifier);
        this.props.navigation.goBack();
    }

    fetchNews = async()=>{
        let code = this.props.route.params.code;
        console.log("CODE: " + code);
        if (code != "HMP-0000") {
            let formData = new FormData(); 
            formData.append("page", this.state.page ) ;
            formData.append("code", code ) ; 
            this.setState({
                showLoading : true
            })
            const response = await executePost(newsList, formData); 
            if(response.code == code_ok){
                if(_is_mounted){
                    if(parseInt(response.show) > 0 ) {
                        let show = parseInt(this.state.show) + parseInt(response.show); 
                        let total = parseInt(response.total); 
                        let data = [...this.state.data, ...response.data ]; 
                        console.log("BERITA");
                        console.log(response);
                        this.setState({
                            showLoading : false, 
                            show : show, 
                            total : total, 
                            data : data 
                        })
                    } else {
                        this.setState({
                            showLoading : false
                        })
                    }
                }

            } else {
                if(response.code == code_auth_error){
                    // if(global.os == "ANDROID") {
                    //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                    // } else {
                    //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                    // }
                    window.postMessage({ id: "sign_out"});
                } else {
                    this.setState({
                        showLoading : false, 
                        showAlert : true, 
                        alertMessage : response.msg
                    })
                }
            }
        } else {
            console.log("YT UTAMA");
            this.setState({
                showLoading : true
            })
            const response = await executeGet("https://bintangpusnas.perpusnas.go.id/api/youtubeUtama") 
            console.log(response);
            if(response.code == code_ok){
                let total = parseInt(response.total); 
                let data = [...this.state.data, ...response.data ]; 
                console.log("YOUTUBE");
                console.log(response);
                this.setState({
                    showLoading : false, 
                    show : 1, 
                    total : total, 
                    data : data 
                })
            }
        }



    }

    renderSkeleton = ({item, index, separators})=>{
        return(
            <View
                style={{
                    marginLeft : 16,
                    marginRight : 16,
                    marginBottom : 12, 
                }}
            >
                <View
                    style={{
                        flexDirection : "row", 
                        alignItems : "center"
                    }}
                >

                    <View style={{ flex : 1 }} >
                        <Skeleton 
                            animation="wave"
                            variant="text"
                            sx={{ fontSize : "2rem"}} 
                            style={{ width : 180, marginBottom : 8 }}
                        />
                        <Skeleton 
                            variant="text"
                            animation="wave" 
                            sx={{ fontSize : "1.5rem"}} 
                            style={{ marginBottom : 4, marginRight : 12 }}
                        />
                        <Skeleton 
                            variant="text"
                            animation="wave" 
                            sx={{ fontSize : "1.5rem"}} 
                            style={{ marginBottom : 4, width : 90  }}
                        />
                    </View>
                    <Skeleton 
                        variant="rectangular"
                        animation="wave"
                        style={{ width : 90 , height : 90 , borderRadius : 8 }}
                    />
                </View>
                <View 
                    style={{ 
                        height : 0.5, 
                        marginTop : 8 , 
                        marginBottom : 8 , 
                        backgroundColor : "silver"
                    }}            
                />

            </View>
        )
    }

    renderItem = ({item ,index, separators})=>{
        console.log("REDER ITEM");
        console.log(item);
        return(
            <Pressable
                style={{
                    marginLeft : 16,
                    marginRight : 16,
                    marginBottom : 12,    
                }}
                onPress={()=>{ this.newsDetail(item, index)}}
            >
                <View
                    style={{
                        flexDirection : "row", 
                        alignItems : "center"
                    }}
                >

                    <View style={{ flex : 1 }} >
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={ 14 }
                            fontWeight={"600"}
                            style={{width : 180, marginBottom : 8 }}
                            color={ color_text }
                        >
                            { item.title }
                        </Typography>

                        <Typography
                            fontFamily="Open Sans"
                            fontSize={ 12 }
                            fontWeight={"500"}
                            style={{ marginRight : 12 }}
                            color={ sub_text }
                        >
                            {
                                item.input_time
                            }   
                        </Typography>
                        
                    </View>
                    <Image 
                        style={{
                            width : 90, 
                            height : 90, 
                            borderRadius : 8
                        }}
                        source={{uri : item.content_image }}
                    />
                </View>
                <View 
                    style={{ 
                        height : 0.5, 
                        marginTop : 8 , 
                        marginBottom : 8 , 
                        backgroundColor : "silver"
                    }}            
                />
            </Pressable>
        )
    }

    newsDetail = (item, index)=>{
        item.identifier = Identifier.NEWS;
        this.props.navigation.navigate("NewsDetail", item); 
    }

    render(){
        return(
            <View style={{flex : 1}}>
                <View style={{
                    height : 56 , 
                    flexDirection : "row", 
                    alignItems : "center", 
                    backgroundColor : "white" ,
                    padding : 12,
                    // marginTop: (global.os == "IOS" ? 48 : 0),
                    boxShadow: "0 4px 6px -1px rgba(0,0,0, 0.07)"
                }}>
                    
                    <IconButton
                        style={{ marginRight : 16 }}
                        onClick={()=>{ this.goBack() }}
                    >
                        <ChevronLeftIcon 
                            sx={{color : color_text }}
                        />
                    </IconButton>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 18 }
                        fontWeight={"600"}
                        style={{ flex : 1}}
                    >
                        Berita
                    </Typography>
                </View>
                <View style={{flex : 0.95, paddingTop: 30 }}>
                {
                    this.state.showLoading && 
                    <FlatList 
                        keyExtractor={(i, x)=>"idx_"+x} 
                        renderItem={ this.renderSkeleton }
                        data={ this.state.dummy_data }

                    />
                }
                {
                    !this.state.showLoading && 
                    <FlatList 
                        keyExtractor={(i, x)=>"news_code_"+i.code+"_"+x} 
                        renderItem={ this.renderItem }
                        data={ this.state.data }
                    />
                }
                </View>
                {
                    this.state.showAlert && 
                    <Alert 
                        onClose={()=>{ this.goBack() }}
                        severity="error"
                    > 
                        <AlertTitle>Error</AlertTitle>
                        { this.state.alertMessage }
                    </Alert>

                }
            </View>
        )
    }
}



export default News; 