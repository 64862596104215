import React, { Component } from 'react'; 
import { StyleSheet, View, ScrollView, Image } from 'react-native'; 
import { primary , color_text, textbox, danger, sub_text } from '../constants/colors';
import { Typography, Button , List , ListItemButton, ListItemIcon, 
    ListItemText, Collapse, CircularProgress } from '@mui/material'; 
import TextBox from '../components/TextBox';
import RadioBox from '../components/RadioBox';
import OptionBox from '../components/OptionBox';
import PasswordBox from '../components/PasswordBox';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Identifier from "../constants/PageIdentifiers";
import * as Command from "../constants/Commands";
import SignUpBackground from '../assets/images/SignUpBackground';
import LogoPusnas from '../assets/images/LogoPusnas';
import { code_ok, code_empty, code_error, code_auth_error, regencies , userRegistration } from '../constants/api';
import { executeGet, executePost } from '../utilities/Utilities';
import RegenciesDialog from '../components/RegenciesDialog';
import EducationDialog from '../components/EducationDialog';
import OccupationDialog from '../components/OccupationDialog';
import IdentityDialog from '../components/IdentityDialog';
import LoadingButton from '@mui/lab/LoadingButton';
import LoadingPage from '../components/LoadingPage';
import AlertDialog from '../components/AlertDialog';
import AlertPage from '../components/AlertPage';
import HeaderLogo from '../assets/HeaderLogo';
import HeaderLogoWhite from '../assets/HeaderLogoWhite';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import moment from 'moment';



var _is_mounted = false; 
class SignUp extends Component { 
    constructor(props){
        super(props)
        this.state = {
            name : "", 
            email : "", 
            city : "", 
            birthdate : "",
            formatted_birthdate : "", 
            password : "", 
            retype : "",  
            gender : "L", 
            occupation : "", 
            education : "", 
            educations : [], 
            occupations : [],
            identities : [],
            identityNumber : "", 
            identityType : "", 
            errorIdentityType : false, 
            errorIdentityTypeMessage : "", 
            selectedIdentityCode : "",
            showIdentity : false, 
            errorName : false, 
            errorNameMessage : "Masukkan nama lengkap anda", 
            errorDate : false, 
            errorDateMessage : "Pilihlah tanggal lahir anda",
            errorEmail : false, 
            errorEmailMessage : "Masukkan e-mail anda dengan format yang benar", 
            errorCity : false, 
            errorCityMessage : "Masukkan kota lahir anda",
            errorEducation : false, 
            errorEducationMessage : "", 
            errorOccupation : false, 
            errorOccupationMessage : "", 
            errorPassword : false, 
            errorPasswordMessage : "Minimal 8-karakter alfanumerik",
            errorRetype : false, 
            errorRetypeMessage : "Ketik ulang password anda",
            errorIdentity : false, 
            errorIdentityMessage : "", 
            regencies : [],
            showRegencies : false,
            selectedProvinceId : "-",
            showEducations : false, 
            selectedEducationCode : "", 
            showOccupations : false, 
            selectedOccupationCode : "",
            showIdentityDialog : false, 
            isSubmitting : false,
            showAlert : false, 
            alertMessage : "",
            showSuccess : false,
            successMessage : ""

        }

        this.handleNameChange = this.handleNameChange.bind(this); 
        this.handleEmailChange = this.handleEmailChange.bind(this); 
        this.handleRadioChange = this.handleRadioChange.bind(this); 
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleRetypeChange = this.handleRetypeChange.bind(this); 
        this.handleCalendarClick = this.handleCalendarClick.bind(this); 
        this.handleBirthdateChange = this.handleBirthdateChange.bind(this); 
        this.handleCityClick = this.handleCityClick.bind(this); 
        this.handleProvinceClick = this.handleProvinceClick.bind(this)
        this.handleOccupationClick = this.handleOccupationClick.bind(this);
        this.handleEducationClick = this.handleEducationClick.bind(this); 
        this.handleSelectedEducation = this.handleSelectedEducation.bind(this); 
        this.handleSelectedOccupation = this.handleSelectedOccupation.bind(this); 
        this.handleIdentityChange = this.handleIdentityChange.bind(this); 
        this.handleSelectedIdentity = this.handleSelectedIdentity.bind(this); 
        this.handleIdentityTypeClick = this.handleIdentityTypeClick.bind(this); 
        this.fetchRegencies = this.fetchRegencies.bind(this); 
        this.setRegency = this.setRegency.bind(this); 
        this.handleSubmitClick = this.handleSubmitClick.bind(this); 


        this.executeSignUp = this.executeSignUp.bind(this); 
        this.handleAlertClose = this.handleAlertClose.bind(this); 
        
        //== Refs ==//
        this.nameRef = React.createRef(); 
        this.birthRef = React.createRef(); 
        this.cityRef = React.createRef(); 
        this.identityRef = React.createRef(); 
        this.identTypeRef = React.createRef(); 
        this.educationRef = React.createRef();
        this.occupationRef = React.createRef(); 
        this.emailRef = React.createRef();
        this.passwordRef = React.createRef(); 
        this.retypeRef = React.createRef(); 

        this.backToLogin = this.backToLogin.bind(this); 
        this.handleSuccessClick = this.handleSuccessClick.bind(this); 

    }

    componentDidMount = ()=>{
        _is_mounted = true; 
        this.fetchRegencies();
        if(global.os == "ANDROID") {
            JSBridge.launchFunction(Command.SET_PAGE, Identifier.SIGNUP);
        }
        // this.setState({
        //     educations : this.props.route.params.educations, 
        //     occupations : this.props.route.params.occupations,
        //     identities : this.props.route.params.identities
        // })

        window.addEventListener("message", (event)=>{
            if(event.data.id == "go_back"){
                this.backToLogin();
            } else if(event.data.id == "set_date"){
                let date_object = event.data; 
                let formatted_birthdate = event.data.birthdate; 
                let _birthdate = event.data.mysql_date; 
                if(_is_mounted){
                    this.setState({
                        birthdate : _birthdate, 
                        formatted_birthdate : formatted_birthdate,
                        errorDate : false
                    })
                }
            }
        })
    }

    componentWillUnmount = ()=>{
        //console.log("SIGNUP PAGE UNMOUNTED");
        window.removeEventListener("message", null);
        _is_mounted = false;
    }

    handleNameChange = (text)=>{
        if(_is_mounted){
            this.setState({
                name : text, 
                errorName : false
            })
        }
    }

    handleEmailChange = (text)=>{
        if(_is_mounted){
            this.setState({
                email : text, 
                errorEmail : false
            })
        }
    }

    handlePasswordChange = (text)=>{
        if(_is_mounted){
            this.setState({
                password : text, 
                errorPassword : false 
            })
        }
    }

    handleRetypeChange = (text)=>{
        if(_is_mounted){
            this.setState({
                retype : text, 
                errorRetype : false 
            })
        }
    }

    handleRadioChange = (event)=>{
        if(_is_mounted ){
            this.setState({
                gender : event.target.value 
            })
        }
    }

    handleCalendarClick = ()=>{
        if(global.os == "ANDROID") {
            JSBridge.launchFunction(Command.SET_DATE, "-"); 
        } else {
            let obj = { id : Command.SET_DATE, params : "-"}
            window.webkit.messageHandlers.JSBridge.postMessage(obj);
        }
    }
    
    handleBirthdateChange = (text)=>{
        
    }

    handleCityClick = ()=>{
        if(_is_mounted){
            if(this.state.regencies.length > 0 ) {
                this.setState({
                    showRegencies : true
                })
            }
        }
    }

    handleProvinceClick = (item)=>{
        if(this.state.selectedProvinceId == item.id) {
            this.setState({
                selectedProvinceId : "-"
            })
        } else {
            this.setState({
                selectedProvinceId : item.id
            })
        }
    }

    fetchRegencies = async()=>{
        console.log("Fetch Regencies");
        const response = await executeGet(regencies); 
        if(response.code == code_ok){
            console.log("got response");
            if(_is_mounted){
                this.setState({
                    regencies : response.data
                });
            }


        } else {
            //TODO: handle errors 
        }
    }

    setRegency = (item)=>{
        this.setState({
            city : item.name ,
            showRegencies : false,
            errorCity : false
        })
    }

    handleOccupationClick = ()=>{
        if(_is_mounted){
            this.setState({
                showOccupations : true
            })
        }
    }

    handleEducationClick = ()=>{
        if(_is_mounted){
            this.setState({
                showEducations : true
            })
        }
    }

    handleSelectedEducation = (item)=>{
        this.setState({
            showEducations : false,
            education : item.name, 
            selectedEducationCode : item.code,
            errorEducation : false
        })
    }

    handleSelectedOccupation = (item)=>{
        this.setState({
            showOccupations : false, 
            occupation : item.name, 
            selectedOccupationCode : item.code,
            errorOccupation : false
        })
    }
    handleIdentityChange = (text)=>{
        if(_is_mounted){
            this.setState({
                identityNumber : text,
                errorIdentity : false
            })
        }
    }

    handleSelectedIdentity = (item)=>{
        this.setState({
            errorIdentityType : false, 
            selectedIdentityCode : item.short_name,
            identityType : item.name ,
            showIdentityDialog : false
        })
    }
    handleIdentityTypeClick = ()=>{
        this.setState({
            showIdentityDialog : true
        })
    }
    handleSubmitClick = ()=>{

        //== Verify ==//
        if(this.state.name.length < 3){
            this.setState({
                errorName : true, 
                errorNameMessage : "Harap masukkan nama lengkap anda"
            },()=>{
                this.nameRef.current.focus();
            })
            return; 
        }

        // if(this.state.birthdate.length == 0) {
        //     this.setState({
        //         errorDate : true ,
        //         errorDateMessage : "Harap pilih tanggal lahir anda"
        //     },()=>{
        //          this.birthRef.current.focus();
        //     })
        //     return; 
        // }
        // if(this.state.city.length == 0){
        //     this.setState({
        //         errorCity : true, 
        //         errorCityMessage : "Harap pilih kota lahir anda"
        //     },()=>{
        //         this.cityRef.current.focus();
        //     })
        //     return; 
        // }
        // if(this.state.identityNumber.length == 0){
        //     this.setState({
        //         errorIdentity : true, 
        //         errorIdentityMessage : "Harap masukkan nomor identitas anda"
        //     },()=>{
        //         this.identityRef.current.focus();
        //     })
        //     return; 
        // }
        // if(this.state.identityType.length == 0){
        //     this.setState({
        //         errorIdentityType : true, 
        //         errorIdentityTypeMessage : "Harap pilih jenis identitas anda"
        //     },()=>{
        //         this.identTypeRef.current.focus(); 
        //     })
        //     return; 
        // }
        // if(this.state.selectedEducationCode.length == 0){
        //     this.setState({
        //         errorEducation : true, 
        //         errorEducationMessage : "Harap pilih pendidikan terakhir anda"
        //     },()=>{
        //         this.educationRef.current.focus(); 
        //     })
        //     return; 
        // }
        // if(this.state.selectedOccupationCode.length == 0){
        //     this.setState({
        //         errorOccupation : true, 
        //         errorOccupationMessage : "Harap pilih pekerjaan anda"
        //     },()=>{
        //         this.occupationRef.current.focus(); 
        //     })
        //     return;
        // }
        if(this.state.email.length == 0){
            this.setState({
                errorEmail : true, 
                errorEmailMessage : "Harap masukkan email anda"
            },()=>{
                this.emailRef.current.focus(); 
            })
            return;
        }
        let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if(! regEmail.test(this.state.email)){
            this.setState({
                errorEmail : true, 
                errorEmailMessage : "E-mail wajib diisi dengan format yang benar"
            }, ()=>{
                this.emailRef.current.focus(); 
            }); 
            return;
        }
        let regPass = /^(?=.*\d).{8,}$/; 
        if(! regPass.test(this.state.password)) {
            this.setState({
                errorPassword : true, 
                errorPasswordMessage : 'Kata sandi minimal 8-karakter kombinasi huruf angka'
            },()=>{
                this.passwordRef.current.focus();
            });
            return; 
        }
        if(this.state.retype != this.state.password){
            this.setState({
                errorRetype : true, 
                errorRetypeMessage : "Harap ketik ulang password anda"
            },()=>{
                this.retypeRef.current.focus();
            })
            return;
        }

        //== Build Parameters ==// 
        let formData = new FormData();
        // formData.append("id_number", this.state.identityNumber ); 
        // formData.append("id_type_code", this.state.selectedIdentityCode ); 
        // formData.append("city_of_birth", this.state.city);
        // formData.append("occupation_code", this.state.selectedOccupationCode); 
        // formData.append("education_code", this.state.selectedEducationCode); 
        formData.append("fullname", this.state.name); 
        // formData.append("gender", this.state.gender);
        // formData.append("birthdate", this.state.birthdate);
        formData.append("email", this.state.email);
        formData.append("password", this.state.password); 
        this.executeSignUp(formData); 

    }


    executeSignUp = async(formData)=>{
        this.setState({
            isSubmitting : true, 
        })

        const response = await executePost(userRegistration, formData); 
        //console.log(JSON.stringify(response));
        if(response.code == code_ok) {
            this.setState({
                isSubmitting : false, 
                showSuccess : true, 
                successMessage : response.msg
            })
        } else {
            this.setState({
                isSubmitting : false, 
                alertMessage : response.msg, 
                showAlert : true, 
            })
        }

    }

    handleAlertClose = ()=>{
        this.setState({
            showAlert : false,
            isSubmitting : false
        })
    }
    backToLogin = ()=>{
        //JSBridge.launchFunction(Command.SET_PAGE, Identifier.LOGIN);
        this.props.navigation.goBack();
        //this.props.navigation.navigate(Identifier.LOGIN);
    }
    handleSuccessClick = ()=>{
        this.backToLogin();
    }
    render(){
        return(
            <View style={{flex : 1}}>
                {
                    !this.state.isSubmitting && 
                    <View style={ styles.container }> 
                        <View   
                            style={{
                                height : 250, 
                                backgroundColor : primary 
                            }}
                        />
                        <View style={{width : "100%", position : "absolute", alignItems : "center", top : 16 }}>
                            <SignUpBackground />
                        </View>
                        <View style={{width : "auto", position : "absolute" , left : 8, top : 8  }}>
                            {/* <LogoPusnas 
                                width={ "auto" }
                                height={ 46 }
                            /> */}
                            <HeaderLogoWhite
                                width={"auto"}
                                height={ 22 }
                            />
                        </View>
                        
                        <ScrollView 
                            style={ styles.scrollview }
                            showsVerticalScrollIndicator={ false }
                        >
                            <View 
                                style={{ width : "100%", height : 190 }}
                            />
                            <View
                                style={ styles.form_container }
                            >
                                <Typography
                                    variant="h3"
                                    fontSize={ 20 }
                                    color={ primary }
                                    fontFamily="Open Sans"
                                    fontWeight="600"
                                    style={{marginTop : 16 , marginLeft : 16 }}
                                >
                                    {
                                        !this.state.showRegencies && 
                                        "Daftar"
                                    }
                                    {
                                        this.state.showRegencies && 
                                        "Pilih Kota Kelahiran"
                                    }
                                </Typography>   
                                <Typography
                                    variant="body1"
                                    fontSize={14}
                                    fontFamily="Open Sans"
                                    fontWeight="500"
                                    color={ sub_text }
                                    style={{marginTop : 2 , marginLeft : 16 }}
                                >
                                    {
                                        !this.state.showRegencies && 
                                        "Isi datamu di formulir ini"
                                    }
                                    {
                                        this.state.showRegencies && 
                                        "Pilih provinsi kemudian pilih kota kelahiranmu"
                                    }

                                </Typography> 
                                {
                                    !this.state.showRegencies && 
                                    <View style={{ flex : 1, marginTop : 16, marginLeft : 16, marginRight : 16, marginBottom : 16   }}>
                                    <TextBox
                                        reference={ this.nameRef }
                                        caption={"Nama Lengkap"}
                                        placeholder={"Masukkan nama lengkap"} 
                                        containerStyle={{marginTop : 8 }}
                                        error={ this.state.errorName }
                                        errorMessage={ this.state.errorNameMessage }
                                        onChangeText={ this.handleNameChange }
                                    />
                                    
                                    {/* <RadioBox 
                                        caption={"Jenis Kelamin"}
                                        containerStyle={{marginTop : 16 }}
                                        captionA={"Laki-Laki"}
                                        captionB={"Perempuan"}
                                        valueA={"L"}
                                        valueB={"P"}
                                        value={ this.state.gender }
                                        onChange={ this.handleRadioChange }
                                    />

                                    <OptionBox 
                                        reference={ this.birthRef }
                                        editable={ false }
                                        caption={"Tanggal Lahir"} 
                                        usingIcon={ true }
                                        placeholder={"Pilih tanggal lahir"} 
                                        containerStyle={{marginTop : 16 }}
                                        icon={ <CalendarTodayIcon fontSize="small" /> }
                                        onIconClick={ this.handleCalendarClick }
                                        error={ this.state.errorDate } 
                                        errorMessage={ this.state.errorDateMessage }
                                        value={ this.state.formatted_birthdate }
                                    />

                                    
                                    <OptionBox 
                                        reference={ this.cityRef }
                                        caption={"Kota Lahir"} 
                                        usingIcon={ true }
                                        editable={ false }
                                        placeholder={"Pilih kota lahir"} 
                                        containerStyle={{marginTop : 16 }}
                                        value={ this.state.city }
                                        icon={ <ExpandMoreIcon fontSize="small"/> }
                                        error={ this.state.errorCity }
                                        errorMessage={ this.state.errorCityMessage }
                                        onIconClick={ this.handleCityClick }
                                    />
                                    <TextBox
                                        reference={ this.identityRef }
                                        caption={"No. Identitas"}
                                        placeholder={"Masukkan nomor identitas anda"} 
                                        containerStyle={{marginTop : 16 }}
                                        error={ this.state.errorIdentity }
                                        errorMessage={ this.state.errorIdentityMessage }
                                        onChangeText={ this.handleIdentityChange }
                                    />
                                    <OptionBox
                                        reference={ this.identTypeRef}
                                        caption={"Jenis Identitas"} 
                                        usingIcon={ true }
                                        placeholder={"Pilih jenis identitas anda"} 
                                        containerStyle={{marginTop : 16 }} 
                                        editable={ false }
                                        value={ this.state.identityType } 
                                        icon={<ExpandMoreIcon fontSize="small" />} 
                                        error={ this.state.errorIdentityType } 
                                        errorMessage={ this.state.errorIdentityTypeMessage } 
                                        onIconClick={ this.handleIdentityTypeClick }
                                    />
                                    <OptionBox
                                        reference={ this.educationRef }
                                        caption={"Pendidikan"} 
                                        usingIcon={ true }
                                        editable={ false }
                                        placeholder={"Pilih pendidikan terakhir anda"} 
                                        containerStyle={{marginTop : 16 }} 
                                        value={ this.state.education } 
                                        icon={<ExpandMoreIcon fontSize="small" />} 
                                        error={ this.state.errorEducation } 
                                        errorMessage={ this.state.errorEducationMessage } 
                                        onIconClick={ this.handleEducationClick }
                                    />
                                    <OptionBox
                                        reference={ this.occupationRef }
                                        caption={"Pekerjaan"} 
                                        usingIcon={ true }
                                        editable={ false }
                                        placeholder={"Pilih pekerjaan anda"} 
                                        containerStyle={{marginTop : 16 }} 
                                        value={ this.state.occupation } 
                                        icon={<ExpandMoreIcon fontSize="small" />} 
                                        error={ this.state.errorOccupation } 
                                        errorMessage={ this.state.errorOccupationMessage } 
                                        onIconClick={ this.handleOccupationClick }
                                    /> */}

                                    <TextBox
                                        reference={ this.emailRef }
                                        caption={"Email"}
                                        placeholder={"Masukkan email anda"} 
                                        containerStyle={{marginTop : 16 }}
                                        error={ this.state.errorEmail }
                                        errorMessage={ this.state.errorEmailMessage }
                                        onChangeText={ this.handleEmailChange }
                                    />
                                    <PasswordBox
                                        reference={ this.passwordRef }
                                        caption={"Buat Password"}
                                        containerStyle={{ marginTop : 16 }}
                                        placeholder={"Min. 8-karakter alfanumerik"}
                                        error={ this.state.errorPassword }
                                        errorMessage={ this.state.errorPasswordMessage }
                                        onChangeText={ this.handlePasswordChange }
                                    />
                                    <PasswordBox
                                        reference={ this.retypeRef }
                                        caption={"Ketik ulang Password"}
                                        containerStyle={{ marginTop : 16, marginBottom : 16  }}
                                        placeholder={"Ketik ulang password diatas"}
                                        error={ this.state.errorRetype }
                                        errorMessage={ this.state.errorRetypeMessage }
                                        onChangeText={ this.handleRetypeChange }
                                        
                                    />
                                    
                                    <Button
                                        variant="contained"
                                        style={{ textTransform : "none", borderRadius : 8, padding : 10  }}
                                        onClick={ this.handleSubmitClick }
                                    >
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={ 16 }
                                            fontWeight="500"
                                        >
                                        Daftar
                                        </Typography>
                                    </Button>
                                    <View style={{ flexDirection : "row", marginTop : 24, alignItems : "center", justifyContent : "center"}}>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={ 14 }
                                        >
                                            Sudah memiliki akun?
                                        </Typography>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={ 14 }
                                            fontWeight="600"
                                            style={{ marginLeft : 8 }}
                                            color={ primary }
                                            onClick={()=>{ this.backToLogin() }}
                                        >
                                            Klik disini
                                        </Typography>
                                    </View>
                                    
                                </View>   
                                }
                                {
                                    this.state.showRegencies && 
                                    <View style={{ flex : 1, marginTop : 16, marginLeft : 16, marginRight : 16, marginBottom : 16   }}>
                                        <List>
                                            {
                                                this.state.regencies.map((item, index)=>(
                                                    <>
                                                    <ListItemButton component="a" onClick={()=>{ this.handleProvinceClick(item)}}>
                                                        <ListItemIcon key={ index }>
                                                            {
                                                                (this.state.selectedProvinceId == item.id) && 
                                                                <ExpandMoreIcon />
                                                            }
                                                            {
                                                                (this.state.selectedProvinceId != item.id) && 
                                                                <ChevronRightIcon />
                                                            }
                                                            
                                                        </ListItemIcon>
                                                        <ListItemText primary={ item.name } />

                                                    </ListItemButton>
                                                    <Collapse in={ this.state.selectedProvinceId == item.id }>
                                                        <List component="div" disablePadding>
                                                        {
                                                            item.regencies.map((i, d)=>(
                                                                <ListItemButton key={ "child_"+d} style={{marginLeft : 8 }} onClick={()=>{ this.setRegency(i)}}>
                                                                    <ListItemIcon key={ index }>
                                                                        <ChevronRightIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={ i.name } />
                                                                </ListItemButton>
                                                            ))
                                                        }
                                                        </List>
                                                    </Collapse>
                                                    </>

                                                ))
                                            }
                                        </List>

                                    </View>
                                }
                            </View>             
                            <EducationDialog 
                                showing={ this.state.showEducations} 
                                data={ this.state.educations }
                                onItemClick={ this.handleSelectedEducation }
                            />
                            <OccupationDialog 
                                showing={ this.state.showOccupations }
                                data={ this.state.occupations } 
                                onItemClick={ this.handleSelectedOccupation }
                            />
                            <IdentityDialog 
                                showing={ this.state.showIdentityDialog }
                                data={ this.state.identities } 
                                onItemClick={ this.handleSelectedIdentity }
                            />
                        </ScrollView>
                    </View>

                }
                {
                    this.state.isSubmitting && 
                    <LoadingPage 
                        message={"Harap Tunggu"}
                    />
                }
                <AlertDialog
                    showing={ this.state.showAlert } 
                    title={ "Perhatian!" }
                    message={ this.state.alertMessage }
                    onClose={ this.handleAlertClose }
                    onClick={ this.handleAlertClose }
                    buttonCaption={"OK"}
                />
                <AlertDialog 
                    showing={ this.state.showSuccess }
                    title={"Pendaftaran Berhasil!"} 
                    message={ this.state.successMessage } 
                    onClose={ this.handleSuccessClick }
                    onClick={ this.handleSuccessClick }
                    buttonCaption={"OK"}
                />
               
                
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container : {
        flex : 1,
        backgroundColor : "white"
    },
    scrollview : {
        position : "absolute", 
        top : 0,
        bottom : 0, 
        left : 0, 
        right : 0,  
        flex : 1, 
        
    },
    form_container : {
        backgroundColor : "white", 
        borderTopLeftRadius : 20, 
        borderTopRightRadius : 20
    }
});

export default SignUp;