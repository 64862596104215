import React, { Component } from 'react'; 
import { StyleSheet, View, Image, FlatList, Dimensions , ScrollView } from 'react-native'; 
import { Alert, AlertTitle, Typography, Button , IconButton, Skeleton , CardActionArea } from '@mui/material'; 
import * as Identifier from '../constants/PageIdentifiers'; 
import { code_ok, code_empty, code_error, code_auth_error, readingHistory } from '../constants/api';
import * as Command from '../constants/Commands';
import { primary , color_text, sub_text } from '../constants/colors';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { CalendarIcon, TimeIcon } from '../assets/Icons';
import PasswordBox from '../components/PasswordBox';
import LoadingPage from '../components/LoadingPage';
import AlertDialog from '../components/AlertDialog';
import { executePost, executeGet, truncateText, navigateContentDetail } from '../utilities/Utilities';
import TodayIcon from '@mui/icons-material/Today';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import  EmptySearchPage  from '../components/EmptySearchPage';

var _is_mounted = false;
class ReadingHistory extends Component {
    constructor(props){
        super(props)
        this.state = {
            showLoading : true, 
            showAlert : false,
            alertTitle : "", 
            alertMessage : "",  
            total : 0, 
            page : 1, 
            show : 0 , 
            dummy_data : [
                {i :0 }, {i :1 }, {i :2 }, {i :3 }, { i : 4 }
            ],
            data : []
        }

        this.renderSkeleton = this.renderSkeleton.bind(this); 
        this.renderItem = this.renderItem.bind(this);
        this.goBack = this.goBack.bind(this); 
        this.fetchContents = this.fetchContents.bind(this); 
        this.contentDetail = this.contentDetail.bind(this); 

    }

    componentDidMount = ()=>{
        _is_mounted = true;
        if (global.os == "ANDROID") {
            JSBridge.launchFunction(Command.SET_PAGE, Identifier.READING_HISTORY);    
        }
        window.addEventListener("message", (event)=>{
            if(event.data.id == "go_back"){
                if(event.data.page == Identifier.READING_HISTORY){
                    this.goBack(); 
                }
            }
        });
        this.fetchContents();
    }

    componentWillUnmount = ()=>{
        _is_mounted = false;
        window.removeEventListener("message", null); 
    }

    renderSkeleton = ({item, index, separators})=>{
        return(
            <View style={{marginBottom : 8 }}>
                <View style={{ flexDirection : "row" }}>
                    <Skeleton 
                        animation="wave"
                        variant="rectangular"
                        style={{ 
                            borderRadius : 8 , 
                            margin : 16, 
                            width : 80, 
                            height : 120
                        }}
                    />
                    <View style={{
                        marginRight : 16 ,
                        marginTop : 16,
                        flex : 1
                    }}>
                        <Skeleton 
                            animation="wave"
                            variant="text"
                            sx={{
                                fontSize : "2rem"
                            }}
                        />
                        <Skeleton 
                            animation="wave"
                            variant="text"
                            sx={{
                                fontSize : "1.5rem",
                            }}
                            style={{
                                marginTop : 8, 
                                width : 140
                            }}
                        />
                        <Skeleton 
                            animation="wave"
                            variant="text"
                            sx={{
                                fontSize : "1.5rem",
                            }}
                            style={{
                                marginTop : 4, 
                                width : 140
                            }}
                        />
                    </View>
                </View>


                <View 
                    style={{
                        backgroundColor : "silver", 
                        height : 0.5 , 
                        marginLeft : 16, 
                        marginRight : 16, 
                        marginTop : 12
                    }}
                />
            </View>
        )
    }

    renderItem = ({item, index, separators})=>{
        return(
            <View>
                <CardActionArea
                    onClick={()=>{ this.contentDetail(item, index)}}
                >
                    <View style={{marginBottom : 8 }}>
                        <View style={{ flexDirection : "row" }}>
                            <Image 
                                source={{ uri : item.cover }}
                                style={{ 
                                    borderRadius : 8 , 
                                    margin : 16, 
                                    width : 80, 
                                    height : 120
                                }}
                            />
                            <View style={{
                                marginRight : 16 ,
                                marginTop : 16,
                                flex : 1
                            }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 14 }
                                    fontWeight="600"
                                    color={ color_text }
                                >
                                    { truncateText(item.title, 64) }
                                </Typography>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 12 }
                                    fontWeight="600"
                                    color={ sub_text }
                                >
                                    {item.author }
                                </Typography>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 12 }
                                    fontWeight="600"
                                    color={ primary }
                                    style={{marginTop : 4 }}
                                >
                                    {item.publisher }
                                </Typography>
                                <View style={{flexDirection : "row", alignItems : "center", marginTop : 12}}>
                                    <TodayIcon  sx={{ color : sub_text , fontSize : 18 }}/>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={ 12 }
                                        fontWeight="500"
                                        color={ color_text }
                                        style={{marginLeft : 4  }}
                                    >
                                        { item.start_date }
                                    </Typography>
                                </View>
                                <View style={{flexDirection : "row", alignItems : "center", marginTop : 4}}>
                                    <AccessTimeIcon sx={{ color : sub_text, fontSize : 18 }}/>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={ 12 }
                                        fontWeight="500"
                                        color={ color_text }
                                        style={{ marginLeft : 4  }}
                                    >
                                        { item.start_time } ~ { item.end_time } ({ item.duration} menit)
                                    </Typography>
                                </View>
                                <View style={{flexDirection : "row", alignItems : "center", marginTop : 4}}>
                                    <DescriptionOutlinedIcon sx={{ color : sub_text, fontSize : 18 }}/>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={ 12 }
                                        fontWeight="500"
                                        color={ color_text }
                                        style={{ marginLeft : 4  }}
                                    >
                                        Hal. { item.last_page }
                                    </Typography>
                                </View>


                                
                            </View>
                        </View>
                    </View>
                </CardActionArea>
                <View 
                    style={{ backgroundColor : "silver", height : 0.5, marginLeft : 16, marginRight : 16 }}
                />
            </View>
        )
        
    }
    goBack = ()=>{
        //JSBridge.launchFunction(Command.SET_PAGE, this.props.route.params.identifier );
        //this.props.navigation.goBack(); 
        this.props.navigation.navigate(this.props.route.params.identifier, { identifier : Identifier.MAIN });
    }

    fetchContents = async()=>{
        let formData = new FormData(); 
        formData.append("page", this.state.page); 
        formData.append("limit", 20); 
        if(this.state.page == 1 ) {
            this.setState({
                showLoading : true 
            })
        } else {
            this.setState({
                moreLoading : true 
            })
        }

        const response = await executePost(readingHistory, formData); 
        if(response.code == code_ok){
            let total = parseInt(response.total);
            let show = parseInt(response.show) + parseInt(this.state.show ); 
            if( parseInt(response.show) > 0 ) {
                let data = [...this.state.data, ...response.data]; 
                this.setState({
                    showLoading : false, 
                    moreLoading : false,    
                    data : data
                })
            }   

            if(total == 0){
                this.setState({
                    showLoading : false, 
                    moreLoading : false, 
                    data : [], 
                    alertTitle : "Tidak Ditemukan", 
                    alertMessage : "Belum ada histori baca anda di halaman ini"
                })
            }
        } else {
            if(response.code == code_auth_error){
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out"});
            } else {
                this.setState({
                    showLoading : false, 
                    showAlert : true, 
                    alertMessage : response.msg
                })
            }

        }
    }
    contentDetail = (item, index)=>{
        let params = {
            previous : Identifier.READING_HISTORY,  
            parameters : this.props.route.params, 
            content_code : item.content_code 
        }

        navigateContentDetail(this.props.navigation, params); 
    }

    render(){
        return(
            <View style={{ flex : 1, backgroundColor : primary }}>
                <View style={{
                    height : 56 , 
                    flexDirection : "row", 
                    alignItems : "center", 
                    backgroundColor : primary ,
                    padding : 12, 
                    //marginTop : (global.os == "ANDROID" ? 0 : 32 ) , 
                    marginTop : 0 , 
                }}>
                    <IconButton
                        onClick={()=>{ this.goBack() }}
                    >
                        <ChevronLeftIcon 
                            sx={{color : "white"}}
                        />
                    </IconButton>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 16 }
                        color="white"
                        fontWeight={"500"}
                        style={{ marginLeft : 15 }}
                    >
                        Aktivitas Baca
                    </Typography>
                </View>
                <View style={{flex : 1, backgroundColor : "white" }}>
                {
                    this.state.showLoading && 
                    <FlatList 
                        keyExtractor={(i, x)=>"index_"+x}
                        data={ this.state.dummy_data }
                        renderItem={ this.renderSkeleton }
                        showsVerticalScrollIndicator={false}
                    />
                }
                {
                    (!this.state.showLoading && this.state.data.length > 0) && 
                    <FlatList 
                        keyExtractor={(item, index)=>"code_"+item.content_code+"_"+index} 
                        data={ this.state.data }
                        renderItem={ this.renderItem }
                        showsVerticalScrollIndicator={false}
                    />
                }
                {
                    (!this.state.showLoading && this.state.data.length == 0) && 
                    <EmptySearchPage 
                        title={ this.state.alertTitle }
                        message={ this.state.alertMessage }
                    />
                }
                </View>
                {
                    this.state.showAlert && 
                    <Alert 
                        onClose={()=>{ this.goBack() }}
                        severity="error"
                    > 
                        <AlertTitle>Error</AlertTitle>
                        { this.state.alertMessage }
                    </Alert>
                }
            </View>
        )
    }
}

export default ReadingHistory; 