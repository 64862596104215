import React, { Component } from 'react'; 
import { StyleSheet, View, Image, FlatList, Pressable } from 'react-native'; 
import { Typography, Skeleton, Button, CardActionArea } from '@mui/material'; 
import { code_ok, code_error, code_empty, code_auth_error, homepageNews, homepageRecommended } from '../constants/api'; 
import { executeGet, executePost, truncateText, navigateContentDetail } from '../utilities/Utilities';
import * as Command from '../constants/Commands';
import * as Identifier from '../constants/PageIdentifiers';
import { color_text, primary, sub_text } from '../constants/colors';

var _is_mounted = false; 
class DesktopSplitRecomNews extends Component {
    //ANCHOR - Constructor
    constructor(props){
        super(props)
        this.state = {
            homepage_news_code : "-",  
            homepage_recommended_code : "-",
            title_recom : "-", 
            title_news : "-", 
            showLoading : false, 
            showAlert : false, 
            alertMessage : "" ,
            homepage_data : [],
            recom_data : [], 
            news_data : [], 
            fetching_news : false, 
            fetching_recommended : false,
            dummy_recom : [
                {i:0},{i:1},{i:2},{i:3}
            ],
            dummy_news : [
                {i:0},{i:1},{i:2},{i:3}
            ]
            
        }

        this.fetchRecommended = this.fetchRecommended.bind(this); 
        this.fetchNews = this.fetchNews.bind(this); 

        this.renderRecomSkeleton = this.renderRecomSkeleton.bind(this); 
        this.renderNewsSkeleton = this.renderNewsSkeleton.bind(this); 
        this.renderRecomItem = this.renderRecomItem.bind(this); 
        this.renderNewsItem = this.renderNewsItem.bind(this); 

        this.handleRecomImageError = this.handleRecomImageError.bind(this); 
        this.handleMoreClick = this.handleMoreClick.bind(this); 

        this.navigateNewsSection = this.navigateNewsSection.bind(this); 
        this.navigateNewsDetail = this.navigateNewsDetail.bind(this); 

        this.contentDetail = this.contentDetail.bind(this);

    }
    //ANCHOR - Component Did Mount
    componentDidMount = ()=>{
        _is_mounted = true;
        let homepage_data = this.props.data ; 
        let h_recom_code = "-";
        let h_news_code = "-";
        let title_recom = "-";
        let title_news = "-"; 
        //== check for the recommended data  ==// 
        for(let i = 0 ; i < homepage_data.length;i++){
            let item = homepage_data[i];
            if(item.type == "recommended") {
               h_recom_code = item.code;
               title_recom = item.title; 
            } else if(item.type == "news") {
                h_news_code = item.code;
                title_news = item.title;
            }
        }
        if(this.props.isMounted){
            this.setState({
                homepage_recommended_code : h_recom_code,
                homepage_news_code : h_news_code,
                title_news : title_news, 
                title_recom : title_recom
            },()=>{
                if(h_recom_code != "-") {
                    this.fetchRecommended(); 
                }
                if(h_news_code != "-") {
                    this.fetchNews(); 
                }
            })
        }
    }

    componentWillUnmount = ()=>{
        _is_mounted = false;
    }

    //ANCHOR - Fetch Recommended
    fetchRecommended = async()=>{
        if(this.props.isMounted){
            this.setState({
                fetching_recommended : true 
            })
            let formData = new FormData();
            formData.append("page", 1);
            formData.append("homepage_code", this.state.homepage_recommended_code) 
            formData.append("limit", 4); 
            
            const response = await executePost(homepageRecommended, formData); 
            if(response.code == code_ok){
                
                this.setState({
                    fetching_recommended : false, 
                    recom_data : response.data
                })
            } else {
                if(response.code != code_auth_error){
                    this.setState({
                        fetching_recommended : false, 
                        alertMessage : response.msg, 
                        showAlert : true
                    })
                } else {
                    window.postMessage({ id : "sign_out"});

                }
            }   
        }
    }

    //ANCHOR - Fetch News
    fetchNews = async()=>{
        if(this.props.isMounted){
            this.setState({
                fetching_news : true 
            })

            let formData = new FormData(); 
            formData.append("homepage_code", this.state.homepage_news_code ); 
            formData.append("page", 1); 
            formData.append("limit", 3 ); 

            const response = await executePost(homepageNews, formData); 
            if(response.code == code_ok){
                
                this.setState({
                    fetching_news : false, 
                    news_data : response.data
                })

            } else {
                if(response.code == code_auth_error){
                    window.postMessage({ id : "sign_out"})
                }
            }
        }
    }
    //ANCHOR - Render Recommended Skeleton
    renderRecomSkeleton = ({item,index, separators})=>{
        return(
            <View style={ styles.recomSkeletonContainer }>
                <Skeleton 
                    variant="rectangle"
                    style={{ width : 120, height : 170, borderRadius : 8 }}
                    animation="wave"
                />
                <View style={{ flex : 1, marginLeft : 16 , marginRight : 16  }}>
                    <Skeleton 
                        variant="rectangle"
                        animation="wave" 
                        style={{ width : 220, height : 24 , borderRadius : 8, marginBottom : 8 }}
                    />
                    <Skeleton 
                        variant="rectangle"
                        animation="wave" 
                        style={{ width : 180, height : 24 , borderRadius : 8, marginBottom : 12 }}
                    />
                    <Skeleton 
                        variant="rectangle"
                        animation="wave" 
                        style={{ width : "100%", height : 90 , borderRadius : 8}}
                    />
                </View>
            </View>
        )

    }
    //ANCHOR - Render News Skeleton 
    renderNewsSkeleton = ({item,index, separators})=>{
        return(
            <View style={ [styles.recomSkeletonContainer,{ marginLeft : 16 }] }>
                <Skeleton 
                    variant="rectangle"
                    animation="wave"
                    style={{ borderRadius : 8, width : 72, height : 72 }}
                />
                <View style={{ flex : 1, marginLeft : 16 , marginRight : 16  }}>
                    <Skeleton 
                        variant="rectangle"
                        animation="wave" 
                        style={{ width : 160, height : 24 , borderRadius : 8, marginBottom : 8 }}
                    />
                    <Skeleton 
                        variant="rectangle"
                        animation="wave" 
                        style={{ width : 180, height : 24 , borderRadius : 8, marginBottom : 12 }}
                    />
                </View>
            </View>
        )
    }
    //ANCHOR - Render Recommended Item 
    renderRecomItem = ({item , index, separators})=>{
        return(
            <View style={ styles.recomSkeletonContainer }>
            <CardActionArea
                style={{borderRadius : 8 }}
                onClick={()=>{ this.contentDetail(item, index)}}
            >
                <View style={ [styles.recomSkeletonContainer,{ }] }>
                    <Image 
                        style={{ width : 120, height : 170, borderRadius : 8 }}
                        source={{ uri : item.cover }}
                        onError={(error)=>{ this.handleRecomImageError(item, index)}}
                    />  
                    <View style={{ flex : 1, marginLeft : 16 , marginRight : 16 }}>
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={ 16 }
                            fontWeight="700"
                            sx={{ color : color_text }}
                        >
                            { item.title }
                        </Typography>
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={ 14 }
                            fontWeight="500"
                            sx={{ color : sub_text }}
                        >
                            { item.author }
                        </Typography>
                        <Typography 
                            fontFamily="Open Sans"
                            fontSize={12}
                            fontWeight="500"
                            style={{ flex : 1 , marginTop : 24 , overflow : "scroll" , maxHeight : 80}}
                        >
                            <div 
                                dangerouslySetInnerHTML={{ __html: item.synopsis }} 
                            />
                        </Typography>
                    </View>
                </View>
            </CardActionArea>
            </View>
        )
    }
    //ANCHOR - Render News Item
    renderNewsItem = ({item, index, separators})=>{
        return(
            <Pressable style={ [styles.recomSkeletonContainer,{ marginLeft : 16 }] }
                onPress={()=>{ this.navigateNewsDetail(item, index)}}
            >
                <Image 
                    style={{ width : 72, height : 72, borderRadius : 8 }}
                    source={{ uri : item.header_image }}
                />  
                <View style={{ flex : 1, marginLeft : 16 , marginRight : 16 ,height : "100%" }}>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 16 }
                        fontWeight="600"
                        sx={{ color : color_text }}
                    >
                        { item.title }
                    </Typography>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 14 }
                        fontWeight="500"
                        sx={{ color : sub_text }}
                    >
                        { item.input_time }
                    </Typography>
                </View>
            </Pressable>
        )
    }
    //ANCHOR - Handle Recommended Image Error
    handleRecomImageError = (item, index)=>{
        if(_is_mounted){
            let data = [...this.state.recom_data]; 
            let obj = { ...data[index]}; 
            obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
            data[index] = obj; 
            this.setState({
                recom_data : data
            })
        }
    }

    handleMoreClick = (code, title, id)=>{
        if(id == "section_detail") {
            this.props.navigation.navigate("DesktopSectionDetail", { identifier : Identifier.SECTION_DETAIL_DESKTOP, code : code, title : title, origins : "CONTENT_RECOMMENDED"}); 
        }
    }

    navigateNewsSection = ()=>{
        window.postMessage({command : Command.DISPATCH_MENU, menu : "news"})
        //window.electron.sendMessage(Command.DISPATCH_MENU,{ menu : "news"} )
    }

    navigateNewsDetail = (item ,index)=>{
        this.props.navigation.navigate("DesktopNewsDetail", item); 
    }

    contentDetail = (item ,index)=>{
        
        let params = {
            content_code : item.content_code
        }
        navigateContentDetail(this.props.navigation, params); 
    }
    render(){
        return(
            <View style={ styles.mainContainer }>
                <View style={ styles.recomContainer}>
                {
                    this.state.fetching_recommended && 
                    <View>
                        <Skeleton
                            variant="rectangle"
                            style={{ width : 220, borderRadius : 8 , height : 32, marginBottom : 8 }} 
                            animation="wave"
                        />
                        <FlatList
                            listKey={(i, x)=>"content_skeleton_index_"+x }
                            numColumns={ 2 }
                            keyExtractor={(i,x)=>"recom_index_"+x}
                            data={ this.state.dummy_recom }
                            renderItem={ this.renderRecomSkeleton }
                        />
                    </View>
                }
                {
                    !this.state.fetching_recommended && 
                    <View>
                        <View style={ styles.moreContainer }>
                            <Typography
                                fontFamily="Open Sans" 
                                fontSize={16}
                                fontWeight="700"
                                style={{ flex : 1 }}
                            >
                                { this.state.title_recom }
                            </Typography>
                            <Button
                                style={{textTransform : "none", marginRight : 16  }}
                                onClick={()=>{ this.handleMoreClick(this.state.homepage_recommended_code, this.state.title_recom, "section_detail" )}}
                            >
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 14 }
                                    fontWeight="500"
                                    color={ primary }
                                >
                                Selengkapnya
                                </Typography>
                            </Button>
                        </View>
                        <FlatList
                            numColumns={ 2 }
                            listKey={(i,x)=>"list_recom_key_"+x}
                            keyExtractor={(i,x)=>"recom_item_"+x}
                            data={ this.state.recom_data }
                            renderItem={ this.renderRecomItem }
                            columnWrapperStyle={{justifyContent:"space-between"}}
                        />
                    </View>
                }
                </View>
                <View style={ styles.newsContainer }>
                {
                    this.state.fetching_news && 
                    <View>
                        <Skeleton 
                            variant="rectangle"
                            style={{ width : 180, borderRadius : 8 , height : 32, marginBottom : 8 }} 
                            animation="wave"
                        />
                        <FlatList
                            listKey={(i, x)=>"news_skeleton_index_"+x}
                            keyExtractor={(i,x)=>"news_index_"+x}
                            data={ this.state.dummy_news }
                            renderItem={ this.renderNewsSkeleton }
                        />
                    </View>   
                }
                {
                    !this.state.fetching_news && 
                    <View>
                        <View style={ styles.moreContainer }>
                            <Typography
                                fontFamily="Open Sans" 
                                fontSize={16}
                                fontWeight="700"
                                style={{ marginLeft : 16, flex : 1  }}
                            >
                                { this.state.title_news }
                            </Typography>
                            <Button
                                style={{textTransform : "none", marginRight : 16  }}
                                onClick={()=>{ this.navigateNewsSection() }}
                                //onClick={()=>{ this.handleMoreClick(this.state.code, this.props.title )}}
                            >
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 14 }
                                    fontWeight="500"
                                    color={ primary }
                                >
                                Selengkapnya
                                </Typography>
                            </Button>
                        </View>
                        <FlatList
                            listKey={(i,x)=>"list_news_key_"+x}
                            keyExtractor={(i,x)=>"news_item_"+x}
                            data={ this.state.news_data }
                            renderItem={ this.renderNewsItem }
                        />
                    </View>
                }
                </View>
            </View>
        )
    }
}
//ANCHOR - Styles
const styles = StyleSheet.create({
    mainContainer : {
        marginLeft : 16, 
        marginRight : 16, 
        flexDirection : "row"
    },
    recomContainer : {
        flex : 0.7, 
        borderRightWidth : 0.2,
        borderColor : "#999999"
    },
    newsContainer : {
        flex : 0.3 ,
        
    },
    recomSkeletonContainer :{ 
        flex : 1, 
        flexDirection : "row",
        alignItems : "center",
        marginBottom : 8 ,
    },
    moreContainer : {
        flexDirection : "row",
        alignItems : "center",
        marginBottom : 16 
    }
})

export default DesktopSplitRecomNews; 