import React, { Component } from 'react';
import { View, StyleSheet, Dimensions, TextInput } from 'react-native';
import LoadingDialog from '../components/LoadingDialog';
import { executeGet, executePost } from '../utilities/Utilities';
import { code_ok, code_error, code_empty, code_auth_error, getContent, closeBook } from '../constants/api';
import { Button, Dialog, Typography, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import TextBox from '../components/TextBox';
import { color_text, primary, textbox } from '../constants/colors';
import * as Command from '../constants/Commands';

var _is_mounted = false;
class WebViewerP3smpt extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoadingDialog: true,
            showCloseDialog: false,
            loadingDialogMessage: "Harap Tunggu",
            id_baca: "",
            id_baca_mitra: "",
            token_baca: "",
            blobUrl: "",
            id_konten: "",
            kode_konten: "",
            lastPage: "1",
            mode: "-",
            showHighlightDialog: false,
            highlightComment: "",
            showCitationDialog: false,
            citationData: {},
            //Voices Modules 
            voices: [],
            currentVoice: {},
            last_page_index: 0
        }

        //this.fetchContent = this.fetchContent.bind(this); 
        this.onLoadIframe = this.onLoadIframe.bind(this);
        this.iframeRef = React.createRef();
        this.executeCloseBook = this.executeCloseBook.bind(this);
        this.handleCloseHighlightDialog = this.handleCloseHighlightDialog.bind(this);
        this.handleConfirmAddHighlight = this.handleConfirmAddHighlight.bind(this);
        this.updateVoices = this.updateVoices.bind(this);
        this.speakUp = this.speakUp.bind(this);

        this.handleBookCloseCancel = this.handleBookCloseCancel.bind(this);
        this.handleBookCloseAccept = this.handleBookCloseAccept.bind(this);

        this.synth = React.createRef();

    }

    handleBookCloseCancel = () => {
        if (_is_mounted) {
            this.setState({
                showCloseDialog: false
            })
        }
    }
    handleBookCloseAccept = () => {
        if (_is_mounted) {
            this.setState({
                showCloseDialog: false,
            }, () => {
                this.executeCloseBook();
                //this.props.navigation.goBack();

            })

        }
    }

    updateVoices = () => {
        this.setState({
            voices: this.synth.current.getVoices()
        }, () => {

        });
    }

    componentDidMount = () => {
        _is_mounted = true;
        console.log('OS = ' + global.os);
        console.log('param = ' + this.props.route.params)
        console.log('param = ' + JSON.stringify(this.props.route.params))
        // try {
        //     if(global.appAccess == undefined) {
        //         this.synth.current = window.speechSynthesis;
        //         this.synth.current.onvoiceschanged = this.updateVoices;
        //         //this.updateVoices();
        //         this.setState({
        //             id_baca : this.props.route.params.id_baca, 
        //             id_baca_mitra : this.props.route.params.id_baca_mitra,
        //             token_baca : this.props.route.params.token ,
        //             id_konten : this.props.route.params.id_konten,
        //             kode_konten : this.props.route.params.kode_konten,
        //             mode : this.props.route.params.mode,
        //             voices : this.synth.current.getVoices(),
        //             currentVoice : this.synth.current.getVoices()[6]
        //         }, ()=>{
        //             //this.fetchContent();
        //             //console.log(window.speechSynthesis);
        //         })
        //     } else {
        //         this.setState({
        //             id_baca : this.props.route.params.id_baca, 
        //             id_baca_mitra : this.props.route.params.id_baca_mitra,
        //             token_baca : this.props.route.params.token ,
        //             id_konten : this.props.route.params.id_konten,
        //             kode_konten : this.props.route.params.kode_konten,
        //             mode : this.props.route.params.mode,
        //         }, ()=>{
        //             //this.fetchContent();
        //             //console.log(window.speechSynthesis);
        //         })
        //     }

        //     window.addEventListener("message",(event)=>{
        //         console.log("event listener = " + JSON.stringify(event))
        //         if(event.data.hasOwnProperty("command")){
        //             console.log("event listener 1 = " + event.data.command)
        //             if(event.data.command == "close") {
        //                 console.log("COMMAND CLOSE"); 
        //                 let page = event.data.page; 
        //                 this.setState({
        //                     lastPage : page
        //                 }, ()=>{
        //                     this.executeCloseBook();
        //                 })
        //             } else if(event.data.command == "log") {
        //                 console.log(event.data.message); 

        //             }  else if(event.data.command == "cite") {
        //                 let data = event.data.data;
        //                 this.setState({
        //                     citationData : data,
        //                     showCitationDialog : true
        //                 })
        //             } else if(event.data.command == "add_highlight"){
        //                 this.setState({
        //                     showHighlightDialog : true
        //                 })
        //             } else if(event.data.command == "speak"){
        //                 // if(global.appAccess == undefined){
        //                 //     this.speakUp(event.data.message);
        //                 // } else {
        //                 //     JSBridge.launchFunction(Command.SPEAK_UP, event.data.message);
        //                 // }
        //                 JSBridge.launchFunction(Command.SPEAK_UP, event.data.message);
        //             } else if(event.data.command == "close_dialog") {
        //                 //alert("RECEIVE CLOSE DIALOG");
        //                 this.setState({
        //                     showLoadingDialog : false
        //                 })
        //             } else if(event.data.command == "hide_keyboard"){
        //                 JSBridge.launchFunction(Command.HIDE_KEYBOARD, "-");
        //             }
        //         } else if(event.data.hasOwnProperty("id")){
        //             if(event.data.id == "go_back"){
        //                 try {
        //                     // setTimeout(()=>{
        //                     //     document.getElementById('readerFrame').contentWindow.postMessage({ id : "go_back"},"*");
        //                     // }, 500);

        //                     // let page = event.data.page; 
        //                     // this.setState({
        //                     //     lastPage : page
        //                     // }, ()=>{
        //                     //     this.executeCloseBook();
        //                     // })

        //                     this.executeCloseBook();
        //                     // this.setState({
        //                     //     showCloseDialog: true
        //                     // })
        //                 } catch(error){

        //                 }

        //             }
        //         }
        //     })
        // } catch(error){

        // }  
    }

    componentWillUnmount = () => {
        _is_mounted = false;
        window.removeEventListener("message", null);
        console.log('appAccess = ' + global.appAccess)
        // if(global.appAccess == null){
        //     this.synth.current.onvoiceschanged = null; 
        // }
    }

    tutupBuku = () => {
        console.log("TUTUP BUKU");
        this.props.navigation.goBack();
        //this.executeCloseBook();
        // this.setState({
        //     showCloseDialog: true
        // });
    }

    speakUp = (text) => {
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.voice = this.state.voices[6];
        utterance.pitch = 1;
        utterance.rate = 1;
        this.synth.current.speak(utterance);
    }
    onLoadIframe = () => {
        this.setState({
            showLoadingDialog: false,
        }, () => {
            setTimeout(() => {
                let tesIsi = {
                    headers: global.defaultHeaders,
                    token: this.props.route.params.token,
                    id_baca: this.props.route.params.id_baca,
                    id_baca_mitra: this.props.route.params.id_baca_mitra,
                    id_konten: this.props.route.params.id_konten,
                    kode_konten: this.props.route.params.kode_konten,
                    mode: this.props.route.params.mode,
                    last_page_index: 0,
                    local_content: this.props.route.params.local,
                    keyword: (this.props.route.params.hasOwnProperty("keyword") ? this.props.route.params.keyword : ""),
                    directIndex: (this.props.route.params.hasOwnProperty("directIndex") ? 0 : 0)
                }
                // this.props.route.params.directIndex
                console.log("HEADER POST MESSAGE = " + JSON.stringify(global.defaultHeaders));
                console.log("POST MESSAGE = " + JSON.stringify(tesIsi));
                document.getElementById('readerFrame').contentWindow.postMessage(tesIsi, "*");
            }, 2000);
        })
    }

    executeCloseBook = async () => {
        console.log("ID BACA: " + this.state.id_baca);
        //console.log("ID BACA MITRA: " + this.state.id_baca_mitra); 
        //console.log("LAST PAGE: " + this.state.lastPage); 

        let formData = new FormData();
        formData.append("id_baca", this.state.id_baca);
        formData.append("id_baca_mitra", this.state.id_baca_mitra);
        formData.append("last_page", this.state.lastPage);
        this.setState({
            showLoadingDialog: true,
            loadingDialogMessage: "Mengembalikan Buku"
        })
        const response = await executePost(closeBook, formData);
        console.log("KEMBLIKAN = " + JSON.stringify(response));
        //console.log(response);
        if (response.code == code_ok) {
            this.setState({
                showLoadingDialog: false
            }, () => {
                if (global.appAccess != undefined) {
                    try {
                        //JSBridge.launchFunction(Command.STOP_SPEAK, "-"); 
                    } catch (error) {

                    }
                }
                if (global.contentId != null) {
                    if (global.os == "ANDROID") {
                        JSBridge.launchFunction(Command.CLOSE_BOOK, "-");
                    } else {
                        this.props.navigation.goBack();
                    }

                } else {
                    if (global.os == "ANDROID") {
                        // JSBridge.launchFunction(Command.CLOSE_BOOK, "-");
                        this.props.navigation.goBack();
                    } else {
                        this.props.navigation.goBack();
                    }

                    //this.props.navigate(-1);
                    //this.props.navigate('/Home');
                    //this.props.navigation.navigate(-1)
                    // history.back();
                    /*
                    this.props.navigation.reset({
                        index: 0,
                        routes: [{ pageTo: 'Main' }],
                    }); */
                }
                // let verifikasiFlag = localStorage.getItem('verifikasi');
                // if(verifikasiFlag == '1') {
                //     localStorage.setItem('verifikasi', 0);
                //     this.props.navigation.pop(2);
                // } else {
                //     this.props.navigation.goBack();
                // }
            })
        } else {
            this.setState({
                showLoadingDialog: false
            }, () => {
                console.log('global = ' + global.os + " -- " + global.contentId)
                if (global.contentId != null) {
                    if (global.os == "ANDROID" || global.os == "android") {
                        JSBridge.launchFunction(Command.CLOSE_BOOK, "-");
                    } else {
                        history.back();
                    }
                } else {
                    if (global.os == "ANDROID" || global.os == "android") {
                        JSBridge.launchFunction(Command.CLOSE_BOOK, "-");
                    } else {
                        this.props.navigation.goBack();
                    }
                    // this.props.navigation.goBack();
                    //this.props.navigate(-1)
                    // history.back();
                }
                // let verifikasiFlag = localStorage.getItem('verifikasi');
                // if(verifikasiFlag == '1') {
                //     localStorage.setItem('verifikasi', 0);
                //     this.props.navigation.pop(2);
                // } else {
                //     this.props.navigation.goBack();
                // }
            })
        }
    }

    handleCloseHighlightDialog = () => {
        this.setState({
            showHighlightDialog: false
        }, () => {
            document.getElementById('readerFrame').contentWindow.postMessage({
                id: "cancel_highlight"
            }, "*");
        })
    }

    handleConfirmAddHighlight = () => {
        this.setState({
            showHighlightDialog: false
        }, () => {
            //console.log(this.state.highlightComment);
            let comment = this.state.highlightComment;
            document.getElementById("readerFrame").contentWindow.postMessage({
                id: "confirm_higlight",
                message: comment
            }, "*");
        })
    }
    render() {
        let viewWidth = Dimensions.get("window").width;
        let viewHeight = Dimensions.get("window").height;
        return (
            <View style={styles.container}>
                <LoadingDialog
                    open={this.state.showLoadingDialog}
                    message={this.state.loadingDialogMessage}
                />
                <View onClick={() => this.tutupBuku()} style={{ borderRadius: "10px", position: "absolute", top: "38px", right: "25px", height: "42px", width: "50px", color: "white", backgroundColor: "rgb(24, 73, 153)", zIndex: 9999 }}>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={24}
                        fontWeight="600"
                        style={{ position: "absolute", top: "2px", left: "16px" }}
                    >
                        X
                    </Typography>
                </View>
                <iframe
                    id="readerFrame"
                    src={this.props.route.params}
                    //src={ "https://reader.bintangpusnas.com:3000/?platform=mobile" } 
                    //src={"http://localhost:3000/?platform=mobile"}
                    width={viewWidth}
                    style={{ height: "calc(100% - 0px)" }}
                    frameBorder={0}
                    onLoad={() => { this.setState({ showLoadingDialog: false }) }}></iframe>

            </View>
        )
    }
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginTop: (global.os === "IOS" ? 48 : 0),
    }
})

export default WebViewerP3smpt; 