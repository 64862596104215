import React, { Component } from 'react';
import { StyleSheet, View, FlatList, Image, Dimensions } from 'react-native'; 
import { code_ok, code_error, code_auth_error, code_empty , homepageContent } from '../constants/api';
import { Skeleton, Typography, Card, CardActionArea, CardMedia, CardContent, Button } from '@mui/material'; 
import { executeGet, executePost , truncateText, navigateContentDetail } from '../utilities/Utilities';
import AlertDialog from '../components/AlertDialog';
import { color_text, sub_text, primary } from '../constants/colors';
import * as Identifier from '../constants/PageIdentifiers';

class HomepageContent extends Component { 
    constructor(props){
        super(props)
        this.state = {
            showLoading : true, 
            showAlert : false, 
            alertMessage : "", 
            loadingMessage : "",
            code : this.props.code ,
            data : [], 
            dummy_data : [
                { i : 0 }, { i : 1}, {i : 2 }, {i : 3}, { i : 4 } , { i : 5 }
            ]
        }

        this.fetchContents = this.fetchContents.bind(this); 
        this.handleAlertClose = this.handleAlertClose.bind(this); 
        this.handleMoreClick = this.handleMoreClick.bind(this); 
        this.renderSkeleton = this.renderSkeleton.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.onImageError = this.onImageError.bind(this); 
        this.contentDetail = this.contentDetail.bind(this);
    }

    componentDidMount = ()=>{

        this.fetchContents();
    }
    fetchContents = async()=>{

        let formData = new FormData();
        formData.append("page", 1);
        formData.append("homepage_code", this.state.code) 
        formData.append("limit", 12); 
        
        const response = await executePost(homepageContent, formData); 
        if(response.code == code_ok){
            this.setState({
                showLoading : false, 
                data : response.data
            })
        } else {
            if(response.code != code_auth_error ) {
                this.setState({
                    showLoading : false, 
                    alertMessage : response.msg, 
                    showAlert : true
                })
            } else {
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out"});
            }
        }   
    }
    handleAlertClose = ()=>{

    }

    renderSkeleton = ({item, index, separators})=>{
        return(
            <Skeleton 
                variant="rectangular"
                sx={{width : 120, height : 160 , borderRadius : 2 }}
                animation="wave"
                style={{
                    marginLeft : (index == 0 ? 16 : 8 ),
                    marginRight : (index == 5 ? 16 : 8 )
                }}
            />
        )
    }
    renderItem = ({item, index, separators })=>{
        return(
            <Card
                raised={ false }
                sx={{maxWidth : 120 , maxHeight : 280 }}
                style={{borderRadius : 8 , 
                    marginLeft : (index == 0 ? 16 : 0), 
                    marginRight : (index == (this.state.data.length - 1) ? 16 : 8 ) ,
                    marginTop : 4, 
                    marginBottom : 4,  alignItems : "center"}}
            >
                <CardActionArea
                    onClick={()=>{ this.contentDetail(item, index)}}
                >
                    <Image 
                        source={{uri : item.cover }}
                        style={{ width : 120 , height : 160 , borderRadius : 8 }}
                        onError={(error)=>{ this.onImageError(item, index)}}
                    />
                
                    <View style={{padding : 8 }}>
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={ 12 }
                            fontWeight={"600"}
                            style={{flex : 1 }}
                        >
                            { truncateText(item.title, 12 )  }
                        </Typography>
                        <Typography 
                            fontFamily="Open Sans"
                            fontSize={ 12 } 
                            fontWeight={ "500" }
                            color={ sub_text }
                            style={{marginTop : 16 }}
                        >
                            { truncateText(item.author, 12 )}
                        </Typography>
                    </View>
                </CardActionArea>
            </Card>
        )
    }
    contentDetail = (item, index)=>{
        let params = {
            previous : Identifier.MAIN , 
            parameters : {}, 
            content_code : item.content_code 
        }

        navigateContentDetail(this.props.navigation, params)
    }
    handleMoreClick = (code, title)=>{
        this.props.navigation.navigate("SectionDetail", { identifier : Identifier.SECTION_DETAIL, code : code, title : title, origins : "CONTENT_SECTION"}); 

    }
    onImageError = (item, index)=>{
        let data = [...this.state.data]; 
        let obj = { ...data[index]}; 
        obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
        data[index] = obj; 
        this.setState({
            data : data
        })
    }
    render(){
        return(
            <View style={{width : "100%"}}> 
            {
                this.state.showLoading && 
                <View>
                    <Skeleton 
                        variant="text"
                        sx={{fontSize : '2rem' }}
                        style={{marginLeft : 16 , marginBottom : 8 , marginRight : 16,  marginTop : 16 }} 
                    />
                    <FlatList
                        keyExtractor={(i, x)=>"skeleton_"+ x }
                        data={ this.state.dummy_data}
                        renderItem={ this.renderSkeleton }
                        horizontal={ true }
                        showsHorizontalScrollIndicator={false}
                    />
                </View>
            }
            {
                !this.state.showLoading && 
                <View style={{ marginTop : 16 }}>
                    <View style={{flexDirection : "row", alignItems : "center", marginBottom : 8 }}>
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={ 16 }
                            variant="h3"
                            style={{flex : 1, marginLeft : 16 }}
                            color={ color_text }
                            fontWeight={"600"}
                        >
                            { truncateText(this.props.title, 20) }
                        </Typography>    
                        <Button
                            style={{textTransform : "none", marginRight : 16  }}
                            onClick={()=>{ this.handleMoreClick(this.props.code, this.props.title )}}
                        >
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 12 }
                                fontWeight="500"
                                color={ primary }
                            >
                            selebihnya
                            </Typography>
                        </Button>
                    </View>
                    <FlatList
                        keyExtractor={(item ,index)=>item.homepage_code + "_"+item.content_code }
                        data={ this.state.data }
                        horizontal={ true }
                        renderItem={ this.renderItem }
                        showsHorizontalScrollIndicator={false}
                    />
                </View>
            }
            <AlertDialog
                showing={ this.state.showAlert } 
                title={ "Perhatian!" }
                message={ this.state.alertMessage }
                onClose={ this.handleAlertClose }
                onClick={ this.handleAlertClose }
                buttonCaption={"OK"}
            />

            </View>
        )
    }
}

export default HomepageContent;