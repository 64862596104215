import React, { Component } from 'react'; 
import { StyleSheet, View } from 'react-native'; 
import { Button , Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'; 

class AlertDialog extends Component { 
    constructor(props){
        super(props);

    }

    render(){
        return(
            <Dialog
                open={ this.props.showing } 
                onClose={ this.props.onClose }  
                style={{ width : "100%"}}  
            >
                <DialogTitle>
                    { this.props.title }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { this.props.message }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={ this.props.onClick}>{ this.props.buttonCaption }</Button>
                </DialogActions>

            </Dialog>
        )
    }
}


export default AlertDialog;