import React, { Component } from 'react';
import { StyleSheet, View, FlatList, Image, Dimensions } from 'react-native'; 
import { code_ok, code_error, code_auth_error, code_empty , homepageNews,newsList } from '../../constants/api';
import { Skeleton, Typography, Card, CardActionArea, CardMedia, CardContent, Button } from '@mui/material'; 
import { executeGet, executePost , truncateText, navigateContentDetail } from '../../utilities/Utilities';
import AlertDialog from '../../components/AlertDialog';
import { color_text, sub_text, primary } from '../../constants/colors';
import * as Identifier from '../../constants/PageIdentifiers';


class MenuNewsPilihan extends Component {
    constructor(props){
        super(props)
        this.state = {
            showLoading : true, 
            showAlert : false, 
            alertMessage : "", 
            loadingMessage : "",
            code : this.props.code ,
            data_utama:[],
            data : [
                {"homepage_code":"HMP-0007","content_code":"BK30118","cover":"https://bintangpusnas.perpusnas.go.id/api/konten_pnri/cover/978-602-478-207-8.jpg","title":"Peluncuran Buku Perdebatan Pasal 33 Dalam Amandemen UUD 1945","synopsis":null,"year":"2018","isbn":"978-602-478-207-8","eisbn":null,"type_code":"TYP-0001","sub_type_code":"STYP-0001","category_code":"14","sub_category_code":"91","author_code":"22304","publisher_code":"414","author":"Kamis, 18/8/2022 21:34 WIB","publisher":"CV. Cipta Media Edukasi","type":"Books","sub_type":"Buku","category":"Pendidikan","sub_category":"Pendidikan"},
                {"homepage_code":"HMP-0007","content_code":"BK30104","cover":"https://bintangpusnas.perpusnas.go.id/api/konten_pnri/cover/978-602-478-183-5.jpg","title":"Humas Pemerintah Perlu Memahami Perannya","synopsis":null,"year":"2018","isbn":"978-602-478-183-5","eisbn":"-","type_code":"TYP-0001","sub_type_code":"STYP-0001","category_code":"14","sub_category_code":"91","author_code":"22292","publisher_code":"414","author":"Selasa, 18/8/2022 21:34 WIB","publisher":"CV. Cipta Media Edukasi","type":"Books","sub_type":"Buku","category":"Pendidikan","sub_category":"Pendidikan"},
                {"homepage_code":"HMP-0007","content_code":"BK30268","cover":"https://bintangpusnas.perpusnas.go.id/api/konten_pnri/cover/978-602-5838-65-1.jpg","title":"Membangun Kualitas SDM Melalui Perpustakaan","synopsis":null,"year":"2018","isbn":"978-602-5838-65-1","eisbn":null,"type_code":"TYP-0001","sub_type_code":"STYP-0001","category_code":"14","sub_category_code":"91","author_code":"22448","publisher_code":"414","author":"Jumat, 18/8/2022 21:34 WIB","publisher":"CV. Cipta Media Edukasi","type":"Books","sub_type":"Buku","category":"Pendidikan","sub_category":"Pendidikan"}], 
            dummy_data : [
                { i : 0 }, { i : 1}, {i : 2 } 
            ]

        }

        this.handleAlertClose = this.handleAlertClose.bind(this); 
        this.handleMoreClick = this.handleMoreClick.bind(this); 
        this.fetchContents = this.fetchContents.bind(this); 
        this.renderItem = this.renderItem.bind(this); 
        this.handleImageError = this.handleImageError.bind(this); 
        this.contentDetail = this.contentDetail.bind(this);
    }

    componentDidMount = ()=>{
        this.fetchContents(); 
    }

    handleAlertClose = ()=>{

    }
    handleMoreClick = (code, title)=>{
        console.log("CODE ");
        console.log(code);
        this.props.navigation.navigate("News", { identifier : Identifier.NEWS, code : code, title : title, origins : "NEWS"}); 
    }
    
    navigateNewsDetail = (item ,index)=>{
        this.props.navigation.navigate("NewsDetail", item); 
    }

    fetchContents = async()=>{

        let formData = new FormData();
        
        formData.append("page", 1);
        formData.append("homepage_code", 'HMP-0008' ) //this.props.code) 
        formData.append("limit", 4); 
        
        const response = await executePost(homepageNews, formData); 
        if(response.code == code_ok){
            console.log("BERITA PILIHAN ==========");
            //console.log(JSON.stringify(response));
            console.log(JSON.stringify(this.state.data.slice(0,1)));
            this.setState({
                showLoading : false, 
                data_utama: response.data.slice(0,1),
                data : response.data.slice(1)
            })
        } else {
            if(response.code != code_auth_error){
                this.setState({
                    showLoading : false, 
                    alertMessage : response.msg, 
                    showAlert : true
                })
            } else {
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out"});
            }
        }   
    }

    renderItem = ({item, index, separators})=>{
        return(
            <Card
                style={{
                    borderRadius : 8, 
                    marginLeft : 16, 
                    marginRight : 16,
                    marginTop : (index == 0 ? 0 : 8 ), 
                    marginBottom : (index == (this.state.data.length - 1) ?  8 : 0 ),
                    boxShadow: "0px 33px 74px rgba(0, 0, 0, 0.0196802), 0px 13.7866px 30.9154px rgba(0, 0, 0, 0.0282725), 0px 7.37098px 16.5289px rgba(0, 0, 0, 0.035), 0px 4.13211px 9.26595px rgba(0, 0, 0, 0.0417275), 0px 2.19453px 4.92108px rgba(0, 0, 0, 0.0503198), 0px 0.913195px 2.04777px rgba(0, 0, 0, 0.07)"
                }}
            >
                <CardActionArea
                    onClick={()=>{ this.contentDetail(item, index)}}
                >
                    <View style={{flexDirection : "row", padding : 12 }}>
                        <Image 
                            style={{
                                width : 90, 
                                height : 90 , 
                                borderRadius : 8 
                            }}
                            source={{uri : item.header_image }} 
                            //onError={(error)=>{ this.handleImageError(item, index)}}
                        />
                        <View style={{flex : 1, marginLeft : 10}}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 14 } 
                                color={ color_text }
                                fontWeight={"600"}
                            >
                                { item.title }
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 12 } 
                                color={ sub_text }
                                style={{marginTop : 8 }}
                            >
                                { item.input_time }
                            </Typography>

                        </View>
                    </View>
                </CardActionArea>
            </Card>
        )
    }

    contentDetail = (item, index)=>{
        let params = {
            previous : Identifier.MAIN, 
            parameters : {}, 
            content_code : item.content_code 
        }
        this.navigateNewsDetail(item, params); 
    }
    handleImageError = (item, index)=>{
        let data = [...this.state.data]; 
        let obj = { ...data[index]}; 
        obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
        data[index] = obj; 
        this.setState({
            data : data
        })
    }

    render(){
        return(
            <View style={{width : "100%"}}>
                {
                    this.state.showLoading && 
                    <View>
                        <Skeleton 
                            variant="text"
                            animation="wave"
                            sx={{fontSize : '2rem' , maxWidth : 120 }}
                            style={{marginLeft : 16 , marginBottom : 8 , marginRight : 16,  marginTop : 16 }} 
                        />
                        <Skeleton 
                            variant="rectangular"
                            style={{height : 90, marginTop : 8, borderRadius : 8 , marginLeft : 16, marginRight : 16 }}
                            animation="wave"
                        />
                        <Skeleton 
                            variant="rectangular"
                            style={{height : 90, marginTop : 8, borderRadius : 8 , marginLeft : 16, marginRight : 16 }}
                            animation="wave"
                        />
                        <Skeleton 
                            variant="rectangular"
                            style={{height : 90, marginTop : 8, borderRadius : 8 , marginLeft : 16, marginRight : 16 }}
                            animation="wave"
                        />
                    </View>
                }
                {
                    !this.state.showLoading && 
                    <View style={{ marginTop : 16 }}>
                        <View style={{flexDirection : "row", alignItems : "center"}}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 16 }
                                variant="h3"
                                style={{flex : 1, marginLeft : 16 }}
                                color={ color_text }
                                fontWeight={"600"}
                            >
                                { truncateText(this.props.title, 20) }
                            </Typography>    
                            <Button
                                style={{textTransform : "none", marginRight : 16  }}
                                onClick={()=>{ this.handleMoreClick(this.state.code, this.props.title )}}
                            >
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 12 }
                                    fontWeight="500"
                                    color={ primary }
                                >
                                Lihat Semua
                                </Typography>
                            </Button>
                        </View>
                        <CardActionArea
                            style={{marginTop: 10,  marginBottom:10}}
                            onClick={()=>{ this.contentDetail(this.state.data_utama[0], 1)}}
                        >
                            <CardMedia image={this.state.data_utama[0].header_image} style={styles.media}/>
                            
                            <div
                                style={{width: '100%', maxHeight: '64px', 
                                background: 'linear-gradient(to bottom, rgba(255,0,0,0), rgba(24, 73, 153, 1))', 
                                position: 'absolute', bottom: '0px', color: 'white', paddingLeft: '16px', paddingBottom: '10px'}}>
                                <Typography
                                        fontFamily="Open Sans"
                                        fontSize={ 14 } 
                                        fontWeight={"600"}
                                        style={{width: '90%'}}
                                    >
                                    {this.state.data_utama[0].title}
                                    </Typography>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={ 12 } 
                                        style={{marginTop : 4 }}
                                    >
                                    {this.state.data_utama[0].input_time}
                                    </Typography>
                            </div>
                            
                        </CardActionArea>
                        <FlatList
                            keyExtractor={(item ,index)=>"rec_"+item.content_code }
                            data={ this.state.data }
                            listKey={"news_list"}
                            //keyExtractor={(i,x)=>"news_index_" + x}
                            renderItem={ this.renderItem }
                            showsVerticalScrollIndicator={false}
                        />

                    </View>
                    
                }

                
            </View>
        )
    }

}

const styles = {
    media: {
       height: 0,
       paddingTop: '56.25%' // 16:9
    },
    card: {
       position: 'relative',
       margin: '16px',
       borderRadius: '15px'
    },
    overlay: {
       position: 'absolute',
       bottom: '39px',
       left: '20px',
       color: 'white',
        fontSize: '18px'
    },
    overlaySub: {
        position: 'absolute',
        bottom: '12px',
        left: '20px',
        color: 'white',
         fontSize: '12px'
     }
 }

export default MenuNewsPilihan; 