import React, { Component } from 'react'; 
import { View, StyleSheet , Image, FlatList, Dimensions, ScrollView  } from 'react-native'; 
import { Alert, AlertTitle, Typography, Skeleton, Card, CardActionArea , CircularProgress, 
    IconButton, Chip, List , ListItemButton, ListItemIcon, 
    ListItemText, Collapse } from '@mui/material'; 
import LoadingPage from '../components/LoadingPage'; 
import AlertDialog from '../components/AlertDialog'; 
import * as Identifier from '../constants/PageIdentifiers';
import * as Command from '../constants/Commands'; 
import { code_ok, code_empty, code_error, code_auth_error, categories, categoryContent } from '../constants/api';
import { executeGet, executePost , isEven, truncateText, navigateContentDetail } from '../utilities/Utilities'; 
import  EmptySearchPage  from '../components/EmptySearchPage';
import { color_text, sub_text, primary } from '../constants/colors';
import SortIcon from '@mui/icons-material/Sort';
import SortDialog from '../components/SortDialog';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

var _is_mounted = false; 
var window_width;
var book_width;
var book_height;

var _is_mounted = false;
var _first_run = true; 
class DesktopCategoryPage extends Component {
    //ANCHOR - Constructor
    constructor(props){
        super(props)
        this.state = {
            showLoading : false, 
            showAlert : false, 
            alertMessage : "",  
            load_category : true, 
            load_content : true,
            more_loading : false, 
            data : [],
            dummy_category : [
                {i : 0},{i : 1},{i : 2},
                {i : 0},{i : 1},{i : 2},
                {i : 0},{i : 1},{i : 2}
            ],
            dummy_content : [
                {i : 0},{i : 1},{i : 2},
                {i : 0},{i : 1},{i : 2},
                {i : 0},{i : 1},{i : 2},
                {i : 0},{i : 1},{i : 2}
            ],
            book_width : 0, 
            book_height : 0,
            content_data : [],  
            selected_type : "",  
            selected_type_code : "",  
            selected_sub_type : "", 
            selected_sub_type_code : "", 
            selected_category : "",  
            selected_category_code : "",  
            selected_sub_category_code : "",
            selected_sub_category : "",
            total_content : 0, 
            show_content : 0, 
            page : 1 
        }
        this.fetchCategories = this.fetchCategories.bind(this); 
        this.goBack = this.goBack.bind(this);
        this.renderCategorySkeleton = this.renderCategorySkeleton.bind(this);
        this.renderContentSkeleton = this.renderContentSkeleton.bind(this);

        this.containerRef = React.createRef(); 
        this.onContentLayout = this.onContentLayout.bind(this);
        this.handleTypeClick = this.handleTypeClick.bind(this); 
        this.handleSubTypeClick = this.handleSubTypeClick.bind(this); 
        this.handleCategoryClick = this.handleCategoryClick.bind(this);
        this.fetchContent = this.fetchContent.bind(this); 
        this.renderContent = this.renderContent.bind(this);

        this.onImageError = this.onImageError.bind(this); 
        this.fetchMoreContent = this.fetchMoreContent.bind(this);
        this.contentDetail = this.contentDetail.bind(this);



    }

    componentDidMount = ()=>{
        _is_mounted = true; 
        this.fetchCategories();
    }

    componentWillUnmount = ()=>{
        _is_mounted = false; 
    }
    //ANCHOR - Fetch categories
    fetchCategories = async()=>{
        const response = await executeGet(categories); 
        if(response.code == code_ok){

            if(response.data.length > 0 ) {
                let data = response.data; 
                let type_object = data[0]; 
                let selected_type = type_object.type; 
                let selected_type_code = type_object.type_code;
                let sub_type_data = type_object.sub_types; 
                let sub_type_object = sub_type_data[0]; 
                let selected_sub_type = sub_type_object.sub_type; 
                let selected_sub_type_code = sub_type_object.sub_type_code; 
                let categories = sub_type_object.categories; 
                let category_object = categories[Math.floor(Math.random() * categories.length)]; 
                let selected_category = category_object.category; 
                let selected_category_code = category_object.category_code;    


                this.setState({
                    showLoading : false,
                    data : response.data,
                    load_category : false, 
                    selected_type : selected_type, 
                    selected_type_code : selected_type_code, 
                    selected_sub_type : selected_sub_type, 
                    selected_sub_type_code : selected_sub_type_code,  
                    selected_category : selected_category, 
                    selected_category_code : selected_category_code ,
                },()=>{
                    this.fetchContent();
                }); 
            }
        } else {
            if(response.code == code_auth_error){
                window.postMessage({id : "sign_out"});

            } else {
                this.setState({
                    showLoading : false, 
                    showAlert : true, 
                    alertMessage : response.msg
                })
            }
        }
    }
    //ANCHOR - Go back
    goBack = ()=>{
        this.props.navigation.goBack(); 
    }
    //ANCHOR - Render Category Skeleton
    renderCategorySkeleton = ({ item, index, separators})=>{
        return(
            <View style={{ 
                marginTop : (index == 0 ? 24 : 8 ),
                marginLeft : 16 ,
                marginRight : 16 }}>
                <Skeleton 
                    variant="rounded"
                    sx={{ height : 48 }}
                    animation="wave"
                />
            </View>
        )
    }

    //ANCHOR - Render Content Skeleton 
    renderContentSkeleton = ({item, index, separators})=>{
        return(
            <View style={{ marginBottom : 16 }}>
                <Skeleton 
                    variant="rectangular"
                    animation="wave"
                    style={{ 
                        width : this.state.book_width,
                        height : this.state.book_height,
                        borderRadius : 8 
                    }}
                />
                <Skeleton 
                    variant="rectangular"
                    animation="wave"
                    style={{
                        borderRadius : 8 , 
                        marginTop : 16, 
                        height : 32 
                    }}
                />
                <Skeleton 
                    variant="rectangular"
                    animation="wave"
                    style={{
                        borderRadius : 8 , 
                        marginTop : 8, 
                        height : 24 
                    }}
                />
            </View>
        )        
    }
    //ANCHOR - On Content Layout
    onContentLayout = (e)=>{
        if(_first_run){
            let width = e.nativeEvent.layout.width; 
            let book_width = (width / 6) - 16 ; 
            let book_height = book_width + 50; 
            this.setState({
                book_width : book_width, 
                book_height : book_height 
            })
            _first_run = false; 
        }
    }
    //ANCHOR - Handle Type Click
    handleTypeClick = (item, index)=>{
        
        if(_is_mounted){
            if(this.state.selected_type_code != item.type_code){
                this.setState({
                    selected_type_code : item.type_code
                })
            } else {
                this.setState({
                    selected_type_code : "-"
                })
            }
        }
    }

    //ANCHOR - Handle Sub Type Click
    handleSubTypeClick = (item, index)=>{
        
        if(_is_mounted){
            if(this.state.selected_sub_type_code != item.sub_type_code){
                this.setState({
                    selected_sub_type_code : item.sub_type_code
                }) 
            } else {
                this.setState({
                    selected_sub_type_code : "-"
                })
            }
        }
    }
    //ANCHOR - Handle Category Click
    handleCategoryClick = (item, index)=>{
        
        if(_is_mounted){
            let code = item.category_code;
            let title = item.category;
            this.setState({
                selected_category : title, 
                selected_category_code : code , 
                page : 1, 
                total_content : 0, 
                show_content : 0 ,
                content_data : [],  
                load_content : true
            },()=>{
                this.fetchContent(); 
            })
        }
    }
    //ANCHOR - Content Detail
    contentDetail = (item, index)=>{
        let params = {
            //previous : Identifier.CONTENT_CATEGORY_PAGE, 
            //parameters : this.props.route.params,
            content_code : item.code
        }
        navigateContentDetail(this.props.navigation, params); 
    }
    //ANCHOR - Fetch Content
    fetchContent = async()=>{
        
        let formData = new FormData(); 
        formData.append("page", this.state.page);
        formData.append("limit", 54); // 54 % 6 = 0 
        formData.append("code", this.state.selected_category_code);

        const response = await executePost(categoryContent, formData); 
        if(response.code == code_ok){
            

            let show = response.show; 
            let total = response.total;
            
            if(show > 0 ) {
                let total_show = show + this.state.show_content; 
                let data = [...response.data]; 
                let mod = (show % 6);
                
                if(mod > 0 ) {
                    let add_factor = (6 - mod); 
                    
                    let added_data = []; 
                    for(let i = 0;i < add_factor;i++){
                        added_data.push({ dummy : i }); 
                    }
                    
                    data = [...response.data,...added_data ]; 
                    
                }
                let saved_data = [...this.state.content_data,...data]; 
                this.setState({
                    total_content : total,
                    show_content : total_show,
                    content_data : saved_data, 
                    load_content : false ,
                    more_loading : false
                })
            } else {
                this.setState({
                    load_content : false ,
                    more_loading : false
                })
            }
        } else {
            if(response.code == code_auth_error){
                window.postMessage({id : "sign_out"}); 
            } else {
                this.setState({
                    load_content : false, 
                    more_loading : false,
                    showAlert : true, 
                    alertMessage : response.msg
                })
            }
        }

    }
    //ANCHOR - Render Content
    renderContent = ({item, index, separators})=>{
        if(!item.hasOwnProperty("dummy")) {
            
            return(
                <View
                    key={ "content_"+item.code }
                    style={{ 
                        width : this.state.book_width, 
                        height : this.state.book_height + 100,
                        borderRadius : 8, 
                        marginBottom : 8,
                        
                    }}
                >
                    <CardActionArea
                        style={{ height : (this.state.book_height + 100) , borderRadius : 8 }}
                        onClick={()=>{ this.contentDetail(item, index)}}
                    >
                        <Image
                            style={{
                                borderRadius : 8,
                                width : this.state.book_width,
                                height : this.state.book_height,
                                position : "absolute", 
                                top : 0,
                                left : 0, 
                                right : 0 
                            }}
                            resizeMode={"cover"}
                            source={{ uri : item.cover }}
                            onError={(error)=>{ this.onImageError(item, index)}}
                        />
                        <View style={{
                            position :  'absolute', 
                            top :  (this.state.book_height + 16 ),
                            left : 0, 
                            right : 0
                        }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 14 }
                                color={ color_text } 
                                fontWeight={"600"}
                                style={{ 
                                    marginLeft : 12, marginTop : 8, marginBottom : 8, marginRight : 12 
                                }}
                            >
                                {  truncateText(item.title, 20) }
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                color={ sub_text }
                                fontSize={ 14 }
                                style={{
                                    marginLeft : 12 ,
                                    marginBottom : 12, 
                                    marginRight : 12
                                }}
                            >
                                { truncateText(item.author, 15) }
                            </Typography>
                        </View>
                    </CardActionArea>
                </View>
            )
        } else {
            return(
                <View
                    key={"dummyz_"+index}
                    style={{ 
                        width : this.state.book_width, 
                        height : this.state.book_height + 100,
                        borderRadius : 8, 
                        marginBottom : 8,
                    }}>
                </View>
            )
        }
    }
    //ANCHOR - On Image Error
    onImageError = (item, index)=>{
        if(_is_mounted){
            let data = [...this.state.content_data]; 
            let obj = { ...data[index]}; 
            obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
            data[index] = obj; 
            this.setState({
                content_data : data
            })
        }
    }
    fetchMoreContent = ()=>{
        if(_is_mounted && !this.state.showLoading && !this.state.more_loading){
            if(this.state.show_content < this.state.total_content ) {
        
                let page = parseInt(this.state.page) + 1; 
                this.setState({
                    more_loading : true, 
                    page : page
                }, ()=>{
                    this.fetchContent(); 
                })

            }
        }
    }
    //ANCHOR - Render Method
    render(){
        return(
            <View style={ styles.container }>
                <View style={{
                    marginLeft : 16 , 
                    marginTop : 16 ,  
                    marginBottom : 16 ,
                    flex : 0.05,
                    backgroundColor : "#F2F2F2",
                    flexDirection : "row", 
                    alignItems : "center"
                }}>
                    <IconButton
                        style={{ marginRight : 16 }}
                        onClick={()=>{ this.goBack() }}
                    >
                        <ChevronLeftIcon 
                            sx={{color : color_text }}
                        />
                    </IconButton>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 18 }
                        fontWeight="600"
                        style={{ flex : 1}}
                    >
                        Daftar Kategori
                    </Typography>
                </View>
                <View style={ styles.mainContainer }>
                    <View style={ styles.sideContainer }>
                    {
                        this.state.load_category && 
                        <FlatList 
                            keyExtractor={(i,x)=>"skeleton_"+x}
                            data={ this.state.dummy_category} 
                            renderItem={ this.renderCategorySkeleton }
                        />
                    }
                    {
                        !this.state.load_category && 
                        <ScrollView
                            style={{ flex : 1 }}
                        >
                        <List>
                            {
                                this.state.data.map((item, index)=>(
                                    <>
                                    <ListItemButton component="a" onClick={()=>{ this.handleTypeClick(item, index)}}>
                                        <ListItemIcon key={ "parent_"+index }>
                                            {
                                                (this.state.selected_type_code == item.type_code) && 
                                                <ExpandMoreIcon />
                                            }
                                            {
                                                (this.state.selected_type_code != item.type_code) && 
                                                <ChevronRightIcon />
                                            }
                                            
                                        </ListItemIcon>
                                        <ListItemText primary={ item.type } />

                                    </ListItemButton>
                                    <Collapse in={ this.state.selected_type_code == item.type_code }>
                                        <List component="div" disablePadding>
                                        {
                                            item.sub_types.map((i, d)=>(
                                            <>
                                                <ListItemButton key={ "child_"+d} style={{marginLeft : 8 }} onClick={()=>{ this.handleSubTypeClick(i, d)}}>
                                                    <ListItemIcon key={ "sub_child_"+index }>
                                                    {
                                                        (this.state.selected_sub_type_code == i.sub_type_code) && 
                                                        <ExpandMoreIcon />
                                                    }
                                                    {
                                                        (this.state.selected_sub_type_code != i.sub_type_code) && 
                                                        <ChevronRightIcon />
                                                    }
                                                    </ListItemIcon>
                                                    <ListItemText primary={ i.sub_type } />
                                                </ListItemButton>
                                                <Collapse in={ this.state.selected_sub_type_code == i.sub_type_code }>
                                                    <List component="div" disablePadding>
                                                    {
                                                        i.categories.map((x,y)=>(
                                                            <ListItemButton disablePadding key={ "_sub_child_"+y}  onClick={()=>{ this.handleCategoryClick(x, y)}}>
                                                                <ListItemText 
                                                                    style={{marginLeft : 16, marginRight : 16 }}
                                                                    primary={ x.category } />
                                                                {/* <ListItemIcon key={ y }>
                                                                    <ChevronRightIcon />
                                                                </ListItemIcon> */}
                                                            </ListItemButton>
                                                        ))
                                                    }
                                                    </List>
                                                </Collapse>
                                            </>
                                            ))
                                        }
                                        </List>
                                    </Collapse>
                                    </>
                                ))
                            }
                        </List>
                        </ScrollView>

                    }
                    </View>
                    <View 
                        style={ styles.contentContainer }
                        onLayout={ this.onContentLayout }
                        >
                        {
                            this.state.load_content && 
                            <FlatList 
                                style={{marginTop : 24 , marginLeft : 16 , marginRight : 16 }}
                                numColumns={ 6 }
                                keyExtractor={(i,x)=>"content_skeleton_"+x} 
                                data={ this.state.dummy_content } 
                                renderItem={ this.renderContentSkeleton }
                                columnWrapperStyle={{justifyContent: 'space-between'}} 
                            />
                        }
                        {
                            !this.state.load_content && 
                            <FlatList 
                                style={{marginTop : 24 , marginLeft : 16 , marginRight : 16 }}
                                numColumns={ 6 }
                                keyExtractor={(i,x)=>"content_data_"+x} 
                                data={ this.state.content_data } 
                                renderItem={ this.renderContent }
                                columnWrapperStyle={{justifyContent: 'space-between'}} 
                                showsVerticalScrollIndicator={ false }
                                onEndReachedThreshold={0.5}
                                onEndReached={this.fetchMoreContent }
                                ListFooterComponent={
                                    this.state.more_loading && 
                                    <View style={{alignItems : "center", padding : 8 }}>
                                        <CircularProgress 
                                            size={ 32 }
                                        />
                                    </View>
                                }
                            />
                        }

                    </View>

                </View>
                <AlertDialog 
                    showing={ this.state.showAlert }
                    title={"Perhatian!"}
                    message={this.state.alertMessage }
                    buttonCaption={"OK"}
                    onClick={()=>{ this.setState({ showAlert : false })}}
                />
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container : {
        flex : 1
    },
    mainContainer : {
        flexDirection : "row",
        flex : 1
    },
    sideContainer : {
        flex : 0.2, 
        backgroundColor : "white",
        borderRightWidth : 0.2, 
        borderColor : "#999999"
    },
    contentContainer : {
        flex : 0.8,
        backgroundColor : "white"

    }

})

export default DesktopCategoryPage; 