import React, { Component } from 'react';
import { StyleSheet, View, FlatList, Image, Dimensions } from 'react-native';
import { code_ok, code_error, code_auth_error, code_empty, homepageNews, widgetaudio, sectionContent } from '../../constants/api';
import { Skeleton, Typography, Card, CardActionArea, CardMedia, CardContent, Button } from '@mui/material';
import { executeGet, executePost, truncateText, getQueryStrings, isEven, navigateContentDetail } from '../../utilities/Utilities';
import AlertDialog from '../../components/AlertDialog';
import { color_text, sub_text, primary } from '../../constants/colors';
import * as Identifier from '../../constants/PageIdentifiers';

class MenuAudiobook extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            showAlert: false,
            alertMessage: "",
            loadingMessage: "",
            code: this.props.code,
            data: [],
            dummy_data: [
                { i: 0 }, { i: 1 }, { i: 2 }
            ]
        }

        this.renderSkeleton = this.renderSkeleton.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.fetchNews = this.fetchNews.bind(this);
        this.handleNewsClick = this.handleNewsClick.bind(this);
        this.handleMoreClick = this.handleMoreClick.bind(this); 
    }

    componentDidMount = () => {
        this.fetchNews();
    }

    handleMoreClick = (code, title)=>{
        console.log("CODE ");
        console.log(code);
        this.props.navigation.navigate("News", { identifier : Identifier.NEWS, code : code, title : title, origins : "NEWS"});
    }

    contentDetail = (item,index)=>{
        let params = {
            previous : Identifier.MAIN, 
            parameters : { code: "HMP-Audiobook", title: "Koleksi Audio Book", origins: "CONTENT_SECTION" }, 
            content_code : item.content_code
        }
        console.log("contentDetail 2 = " + JSON.stringify(this.props.navigation));
        console.log("contentDetail 3 = " + JSON.stringify(params));
        navigateContentDetail(this.props.navigation, params); 
    }

    renderSkeleton = ({ item, index, separators }) => {
        return (
            <View style={{
                width: 220,
                marginLeft: (index == 0 ? 16 : 0),
                marginRight: (index == (this.state.dummy_data.length - 1) ? 16 : 8)
            }}>
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    style={{ borderRadius: 8, width: 220, height: 110, marginBottom: 8 }}
                />
                <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "1.5rem", width: 180 }}
                    style={{ marginBottom: 4 }}
                />
                <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "1.2rem", width: 120 }}
                    style={{ marginBottom: 4 }}
                />
            </View>
        )
    }

    renderItem = ({ item, index, separators }) => {
        let window_width = Dimensions.get("window").width; 
        // let book_width = (window_width / 2.2) - 16 ; 
        // let book_height = book_width + 10;
        let book_width = 161;
        let book_height = 167;
        return (
            
            <Card
                style={{ 
                    width : book_width, 
                    borderRadius : 8, 
                    marginLeft : 4, 
                    marginRight : 4,
                    marginTop : 8,
                    marginBottom: 8
                }}
            >
                <CardActionArea
                    sx={{ height : "100%"}}
                    onClick={()=>{ this.contentDetail(item, index)}}
                >
                    <Image
                        style={{
                            width : book_width,
                            height : book_height
                        }}
                        resizeMode={"cover"}
                        source={{ uri : item.cover }}
                        onError={(error)=>{ this.onImageError(item, index)}}
                    />


                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 14 }
                        color={ color_text } 
                        fontWeight={"600"}
                        style={{ 
                            marginLeft : 12, marginTop : 8, marginBottom : 8, marginRight : 12, height: '15%' 
                        }}
                    >
                        { truncateText(item.title, 24)  }
                    </Typography>
                    <Typography
                        fontFamily="Open Sans"
                        color={ sub_text }
                        fontSize={ 12 }
                        style={{
                            marginLeft : 12 ,
                            marginBottom : 12, 
                            marginRight : 12,
                            height: '15%'
                        }}
                    >
                        { truncateText(item.author, 20 )}
                    </Typography>
                </CardActionArea>
            </Card>
        )
    }
    fetchNews = async () => {
        // let formData = new FormData();
        // formData.append("homepage_code", this.props.code);
        // formData.append("page", 1);
        // formData.append("limit", 6);

        const response = await executeGet(widgetaudio);
        console.log("widgetaudio news = " + JSON.stringify(response))
        if (response.code == code_ok) {
            this.setState({
                showLoading: false,
                data: response.data
            })

        } else {
            if (response.code != code_auth_error) {
                this.setState({
                    showLoading: false,
                })
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, response.msg);
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : response.msg })
                // }
            } else {
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            }
        }

    }

    handleNewsClick = (item, index) => {
        item.identifier = Identifier.MAIN;
        this.props.navigation.navigate("NewsDetail", item);
    }

    render() {
        return (
            <View style={{ width: "100%" }}>
                {
                    this.state.showLoading &&
                    <View>
                        <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: "2rem", width: 150 }}
                            style={{ marginBottom: 8, marginLeft: 16 }}
                        />
                        <FlatList
                            keyExtractor={(i, x) => "news_skeleton_" + x}
                            data={this.state.dummy_data}
                            renderItem={this.renderSkeleton}
                            horizontal={true}
                        />
                    </View>
                }
                {
                    !this.state.showLoading &&
                    <View style={{ marginBottom: 16 }}>
                        <View style={{flexDirection : "row", alignItems : "center"}}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={16}
                                variant="h3"
                                color={color_text}
                                fontWeight={"600"}
                                style={{ flex: 1, marginLeft: 16, marginTop: 16, marginBottom: 16 }}
                            >
                                {truncateText('Audiobook', 20)}
                            </Typography>
                            <Button
                                style={{textTransform : "none", marginRight : 16  }}
                                onClick={()=>{
                                    let params = { code : "HMP-Audiobook", title : "Koleksi Audiobook", origins : "CONTENT_SECTION"}; 
                                    this.props.navigation.navigate("SectionDetailAudiobook", params);
                                }}
                            >
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 12 }
                                    fontWeight="500"
                                    color={ primary }
                                >
                                Lihat Semua
                                </Typography>
                            </Button>
                        </View>
                        {/* <HomeYoutube /> */}
                        <FlatList
                            keyExtractor={(index) => index}
                            data={this.state.data}
                            horizontal={true}
                            renderItem={this.renderItem}
                        />
                    </View>
                }

            </View>
        )
    }
}

export default MenuAudiobook;