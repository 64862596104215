import * as React from "react"
import Svg, { G, Path } from "react-native-svg"



class MainLogo extends React.Component {
    constructor(props){
        super(props) 
    }

    render(){
        return(
        <Svg
            id="Layer_1"
            x="0px"
            y="0px"
            width={ this.props.width }
            height={ this.props.height }
            viewBox="0 0 2000 526"
            style={{
                enableBackground: "new 0 0 2000 526",
            }}
            xmlSpace="preserve"
            
        >
            <G>
                <G>
                    <Path
                    fill="#43556B"
                    d="M46.5,369.5c3.7,3.2,5.6,7.8,5.6,13.6c0,6.1-1.9,10.8-5.6,14.2c-3.8,3.4-9,5.1-15.8,5.1H17v17.1H9.5v-54.8 h21.1C37.5,364.7,42.8,366.3,46.5,369.5z M41.1,392.5c2.5-2.1,3.8-5.1,3.8-9.1c0-3.9-1.3-6.9-3.8-8.9c-2.5-2-6.1-3-10.8-3 L17,371.6v24h13.3C35,395.6,38.6,394.6,41.1,392.5z"
                    />
                    <Path
                    fill="#43556B"
                    d="M92.5,383.5c3.3,3.8,4.9,9,4.9,15.8c0,1,0,1.8-0.1,2.3H64.6c0.6,3.7,2.1,6.7,4.7,8.8c2.5,2.2,5.7,3.3,9.4,3.3 c2.5,0,4.7-0.4,6.9-1.3c2.1-0.9,4-2.1,5.5-3.8l4,4.2c-2,2.2-4.5,3.9-7.4,5.1c-2.9,1.2-6.1,1.8-9.6,1.8c-4.1,0-7.7-0.9-10.8-2.6 c-3.2-1.7-5.6-4.2-7.4-7.4c-1.7-3.2-2.6-6.8-2.6-10.9c0-4.1,0.9-7.7,2.6-10.8c1.7-3.2,4.2-5.6,7.3-7.4c3.1-1.8,6.7-2.7,10.7-2.7 C84.4,377.8,89.2,379.7,92.5,383.5z M90.9,396c-0.2-3.8-1.5-6.7-3.8-8.9c-2.3-2.2-5.3-3.3-9.1-3.3c-3.7,0-6.7,1.1-9.1,3.3 c-2.4,2.2-3.9,5.1-4.3,8.8H90.9z"
                    />
                    <Path
                    fill="#43556B"
                    d="M120.5,380.2c2.5-1.5,5.5-2.3,8.9-2.4v7c-4.2-0.2-7.6,0.9-10.3,3.3c-2.6,2.4-4.1,5.5-4.5,9.5v21.9h-7.3v-41.4 h7.3v8.9C116,384,118,381.8,120.5,380.2z"
                    />
                    <Path
                    fill="#43556B"
                    d="M170.7,380.5c3,1.8,5.4,4.3,7,7.4c1.7,3.2,2.5,6.8,2.5,11c0,4.1-0.8,7.7-2.5,10.8c-1.6,3.2-4,5.6-7,7.4 c-3,1.7-6.4,2.6-10.3,2.6c-3.5,0-6.6-0.7-9.2-2.2c-2.7-1.5-4.8-3.6-6.3-6.3v23.4h-7.3v-56.6h7.3v8.2c1.5-2.7,3.6-4.8,6.2-6.3 c2.6-1.5,5.7-2.2,9.1-2.2C164.2,377.8,167.7,378.7,170.7,380.5z M166.1,411.5c2.1-1.3,3.8-3,4.9-5.2c1.2-2.2,1.8-4.8,1.8-7.6 c0-2.8-0.6-5.3-1.8-7.5c-1.2-2.2-2.8-4-4.9-5.2c-2.1-1.3-4.5-1.9-7.1-1.9c-2.7,0-5.1,0.6-7.2,1.9c-2.1,1.3-3.8,3-5,5.2 c-1.2,2.2-1.8,4.8-1.8,7.5c0,2.9,0.6,5.4,1.8,7.6c1.2,2.2,2.9,4,5,5.2c2.1,1.3,4.5,1.9,7.2,1.9C161.6,413.4,164,412.8,166.1,411.5 z"
                    />
                    <Path
                    fill="#43556B"
                    d="M227.1,378.1v41.4h-7.4v-8.8c-2.7,5.9-7.8,8.9-15.3,9c-4.8,0-8.6-1.4-11.3-4.3c-2.7-2.8-4.1-6.8-4.1-11.8 v-25.6h7.4v23.7c0,3.5,1,6.2,2.9,8.2c1.9,2,4.5,2.9,7.9,2.9c3.9-0.1,6.9-1.4,9.2-4.1c2.3-2.7,3.4-6.1,3.4-10.3v-20.4H227.1z"
                    />
                    <Path
                    fill="#43556B"
                    d="M258.2,384.7c-2.1-0.6-4-0.9-5.9-0.9c-2.2,0-4,0.4-5.3,1.2c-1.3,0.8-2,2-2,3.7s0.7,2.9,2.2,3.8 c1.5,0.9,3.7,1.7,6.8,2.6c2.9,0.8,5.2,1.7,7,2.5c1.8,0.8,3.4,2.1,4.7,3.7c1.3,1.6,1.9,3.8,1.9,6.4c0,3.9-1.5,6.9-4.4,8.9 c-2.9,2-6.6,3.1-11,3.1c-3.1,0-6.1-0.5-9-1.5c-2.9-1-5.3-2.4-7.3-4.2l2.7-5.3c1.7,1.6,3.9,2.8,6.4,3.8c2.5,0.9,5,1.4,7.4,1.4 c2.4,0,4.3-0.4,5.8-1.3c1.5-0.9,2.2-2.2,2.2-4.1c0-1.9-0.8-3.3-2.3-4.2c-1.5-0.9-3.9-1.9-7-2.8c-2.8-0.8-5-1.6-6.8-2.4 c-1.7-0.8-3.2-2-4.5-3.6c-1.2-1.6-1.8-3.6-1.8-6.1c0-3.9,1.4-6.8,4.2-8.7c2.8-1.9,6.3-2.9,10.6-2.9c2.5,0,5,0.4,7.4,1.1 c2.4,0.7,4.5,1.7,6.3,2.9l-2.7,5.6C262.2,386.2,260.3,385.3,258.2,384.7z"
                    />
                    <Path
                    fill="#43556B"
                    d="M299.5,416.8c-3.2,2-6.4,3.1-9.7,3.1c-3.3,0-6-1-8-2.9c-2-2-3.1-4.9-3.1-8.9V385h-5.9v-5.6h5.9v-11.1h7.4 v11.1h12.7v5.6h-12.7v21.6c0,2.3,0.4,3.9,1.3,4.9c0.8,1,2.1,1.5,3.8,1.5c2,0,4.1-0.7,6.4-2L299.5,416.8z"
                    />
                    <Path
                    fill="#43556B"
                    d="M333.7,419.5v-5.8c-3.1,4.1-7.9,6.1-14.5,6.1c-2.9,0-5.4-0.5-7.6-1.6c-2.2-1.1-3.8-2.6-5-4.5 c-1.2-1.9-1.8-4.1-1.8-6.5c0-3.7,1.4-6.6,4.1-8.8c2.7-2.2,6.6-3.3,11.5-3.3h13.2v-2c0-2.9-0.9-5.2-2.7-6.8 c-1.8-1.6-4.4-2.4-7.9-2.4c-4.2,0-8.4,1.5-12.8,4.5l-3.1-5c3-1.9,5.7-3.3,8.3-4.2c2.5-0.9,5.5-1.3,9-1.3c5.2,0,9.2,1.3,12,3.8 c2.8,2.5,4.3,6.1,4.3,10.6l0.1,27.3H333.7z M329.3,411.3c2.4-1.6,3.8-3.8,4.3-6.5v-4.2h-12.1c-3.3,0-5.7,0.5-7.3,1.5 c-1.6,1-2.4,2.6-2.4,4.7s0.8,3.8,2.4,5.1c1.6,1.3,3.8,1.9,6.5,1.9C324.1,413.8,326.9,413,329.3,411.3z"
                    />
                    <Path
                    fill="#43556B"
                    d="M382.8,419.5l-13.2-20.1l-8.1,8.4v11.7h-7.3v-58.1h7.3v37l20.1-20.4h8.5l-15.4,16.1l16.9,25.4H382.8z"
                    />
                    <Path
                    fill="#43556B"
                    d="M422.6,419.5v-5.8c-3.1,4.1-7.9,6.1-14.5,6.1c-2.9,0-5.4-0.5-7.6-1.6c-2.2-1.1-3.8-2.6-5-4.5 s-1.8-4.1-1.8-6.5c0-3.7,1.4-6.6,4.1-8.8c2.7-2.2,6.6-3.3,11.5-3.3h13.2v-2c0-2.9-0.9-5.2-2.7-6.8c-1.8-1.6-4.4-2.4-7.9-2.4 c-4.2,0-8.4,1.5-12.8,4.5l-3.1-5c3-1.9,5.7-3.3,8.3-4.2c2.5-0.9,5.5-1.3,9-1.3c5.2,0,9.2,1.3,12,3.8c2.8,2.5,4.3,6.1,4.3,10.6 l0.1,27.3H422.6z M418.3,411.3c2.4-1.6,3.8-3.8,4.3-6.5v-4.2h-12.1c-3.3,0-5.7,0.5-7.3,1.5c-1.6,1-2.4,2.6-2.4,4.7 s0.8,3.8,2.4,5.1c1.6,1.3,3.8,1.9,6.5,1.9C413,413.8,415.9,413,418.3,411.3z"
                    />
                    <Path
                    fill="#43556B"
                    d="M468.1,419.5v-5.8c-3.1,4.1-7.9,6.1-14.5,6.1c-2.9,0-5.4-0.5-7.6-1.6c-2.2-1.1-3.8-2.6-5-4.5 c-1.2-1.9-1.8-4.1-1.8-6.5c0-3.7,1.4-6.6,4.1-8.8c2.7-2.2,6.6-3.3,11.5-3.3h13.2v-2c0-2.9-0.9-5.2-2.7-6.8 c-1.8-1.6-4.4-2.4-7.9-2.4c-4.2,0-8.4,1.5-12.8,4.5l-3.1-5c3-1.9,5.7-3.3,8.3-4.2c2.5-0.9,5.5-1.3,9-1.3c5.2,0,9.2,1.3,12,3.8 c2.8,2.5,4.3,6.1,4.3,10.6l0.1,27.3H468.1z M463.8,411.3c2.4-1.6,3.8-3.8,4.3-6.5v-4.2h-12.1c-3.3,0-5.7,0.5-7.3,1.5 c-1.6,1-2.4,2.6-2.4,4.7s0.8,3.8,2.4,5.1c1.6,1.3,3.8,1.9,6.5,1.9C458.5,413.8,461.4,413,463.8,411.3z"
                    />
                    <Path
                    fill="#43556B"
                    d="M522.8,382.1c2.8,2.8,4.2,6.8,4.2,11.8v25.6h-7.3v-23.7c0-3.5-1-6.2-2.9-8.2c-2-2-4.7-2.9-8.1-2.9 c-3.7,0.1-6.7,1.2-9,3.6c-2.3,2.3-3.6,5.3-4,9v22.2h-7.3v-41.4h7.3v8.8c2.8-5.9,8-8.9,15.7-9C516.2,377.8,520,379.3,522.8,382.1z"
                    />
                    <Path
                    fill="#43556B"
                    d="M561.2,364.7h8.9l20,38.5l19.9-38.5h9v54.8h-7l-0.1-43.8l-19.5,38h-4.6l-19.7-38v43.8h-7V364.7z"
                    />
                    <Path
                    fill="#43556B"
                    d="M665.2,383.5c3.3,3.8,4.9,9,4.9,15.8c0,1,0,1.8-0.1,2.3h-32.7c0.6,3.7,2.1,6.7,4.7,8.8 c2.5,2.2,5.7,3.3,9.4,3.3c2.5,0,4.7-0.4,6.9-1.3c2.1-0.9,4-2.1,5.5-3.8l4,4.2c-2,2.2-4.5,3.9-7.4,5.1c-2.9,1.2-6.1,1.8-9.6,1.8 c-4.1,0-7.7-0.9-10.8-2.6c-3.2-1.7-5.6-4.2-7.4-7.4c-1.7-3.2-2.6-6.8-2.6-10.9c0-4.1,0.9-7.7,2.6-10.8c1.7-3.2,4.2-5.6,7.3-7.4 c3.1-1.8,6.7-2.7,10.7-2.7C657,377.8,661.9,379.7,665.2,383.5z M663.5,396c-0.2-3.8-1.5-6.7-3.8-8.9c-2.3-2.2-5.3-3.3-9.1-3.3 c-3.7,0-6.7,1.1-9.1,3.3c-2.4,2.2-3.9,5.1-4.3,8.8H663.5z"
                    />
                    <Path
                    fill="#43556B"
                    d="M714.5,382.1c2.8,2.8,4.2,6.8,4.2,11.8v25.6h-7.3v-23.7c0-3.5-1-6.2-2.9-8.2c-2-2-4.7-2.9-8.1-2.9 c-3.7,0.1-6.7,1.2-9,3.6c-2.3,2.3-3.6,5.3-4,9v22.2h-7.3v-41.4h7.3v8.8c2.8-5.9,8-8.9,15.7-9C707.9,377.8,711.8,379.3,714.5,382.1 z"
                    />
                    <Path
                    fill="#43556B"
                    d="M725.4,428.7c2,0.1,3.5-0.6,4.5-2c1-1.4,1.6-3.5,1.6-6.2v-42.4h7.4v43.5c0,4.3-1,7.6-3.1,9.9 c-2.1,2.3-5,3.5-8.7,3.5c-2.3,0-4.7-0.5-7.1-1.4l1.7-5.6C722.8,428.4,724.1,428.6,725.4,428.7z M738.4,362.9 c0.9,0.9,1.3,2,1.3,3.4c0,1.4-0.4,2.5-1.3,3.4c-0.9,0.9-1.9,1.3-3.3,1.3c-1.3,0-2.4-0.4-3.3-1.3c-0.9-0.9-1.3-2-1.3-3.4 c0-1.4,0.4-2.5,1.3-3.4c0.9-0.9,1.9-1.3,3.3-1.3C736.4,361.5,737.5,362,738.4,362.9z"
                    />
                    <Path
                    fill="#43556B"
                    d="M777.6,419.5v-5.8c-3.1,4.1-7.9,6.1-14.5,6.1c-2.9,0-5.4-0.5-7.6-1.6c-2.2-1.1-3.8-2.6-5-4.5 s-1.8-4.1-1.8-6.5c0-3.7,1.4-6.6,4.1-8.8c2.7-2.2,6.6-3.3,11.5-3.3h13.2v-2c0-2.9-0.9-5.2-2.7-6.8c-1.8-1.6-4.4-2.4-7.9-2.4 c-4.2,0-8.4,1.5-12.8,4.5l-3.1-5c3-1.9,5.7-3.3,8.3-4.2c2.5-0.9,5.5-1.3,9-1.3c5.2,0,9.2,1.3,12,3.8c2.8,2.5,4.3,6.1,4.3,10.6 l0.1,27.3H777.6z M773.2,411.3c2.4-1.6,3.8-3.8,4.3-6.5v-4.2h-12.1c-3.3,0-5.7,0.5-7.3,1.5c-1.6,1-2.4,2.6-2.4,4.7 s0.8,3.8,2.4,5.1c1.6,1.3,3.8,1.9,6.5,1.9C768,413.8,770.8,413,773.2,411.3z"
                    />
                    <Path
                    fill="#43556B"
                    d="M832.2,382.1c2.8,2.8,4.2,6.8,4.2,11.8v25.6h-7.3v-23.7c0-3.5-1-6.2-2.9-8.2c-2-2-4.7-2.9-8.1-2.9 c-3.7,0.1-6.7,1.2-9,3.6c-2.3,2.3-3.6,5.3-4,9v22.2h-7.3v-41.4h7.3v8.8c2.8-5.9,8-8.9,15.7-9C825.7,377.8,829.5,379.3,832.2,382.1 z"
                    />
                    <Path
                    fill="#43556B"
                    d="M886,378.1v37.2c0,4-0.9,7.4-2.6,10.4c-1.7,3-4.2,5.3-7.4,6.9c-3.2,1.6-6.9,2.4-11.1,2.4 c-6.1,0-11.8-1.9-17.1-5.8l3.2-5.3c2,1.7,4.2,2.9,6.3,3.7c2.2,0.8,4.6,1.2,7.2,1.2c4.3,0,7.7-1.2,10.3-3.6 c2.6-2.4,3.8-5.6,3.8-9.6v-5.8c-1.5,2.5-3.4,4.5-6,5.8c-2.5,1.4-5.4,2.1-8.6,2.1c-3.7,0-7-0.8-9.8-2.5c-2.8-1.7-5.1-4.1-6.6-7.1 c-1.6-3-2.4-6.5-2.4-10.4c0-3.9,0.8-7.3,2.4-10.3c1.6-3,3.8-5.3,6.6-7c2.8-1.7,6.1-2.5,9.8-2.5c3.3,0,6.2,0.7,8.7,2 c2.5,1.4,4.5,3.3,5.9,5.8v-7.6H886z M872.3,409.9c2-1.2,3.5-2.9,4.7-5c1.1-2.1,1.7-4.5,1.7-7.2c0-2.7-0.6-5.1-1.7-7.2 c-1.1-2.1-2.7-3.8-4.7-5c-2-1.2-4.3-1.8-6.8-1.8c-2.6,0-4.8,0.6-6.9,1.8c-2,1.2-3.6,2.9-4.7,5c-1.1,2.1-1.7,4.5-1.7,7.2 c0.1,4.1,1.3,7.4,3.8,10c2.5,2.6,5.6,3.9,9.6,3.9C868.1,411.7,870.3,411.1,872.3,409.9z"
                    />
                    <Path
                    fill="#43556B"
                    d="M928.3,419.5l-13.2-20.1l-8.1,8.4v11.7h-7.3v-58.1h7.3v37l20.1-20.4h8.5l-15.4,16.1l16.9,25.4H928.3z"
                    />
                    <Path
                    fill="#43556B"
                    d="M968.1,419.5v-5.8c-3.1,4.1-7.9,6.1-14.5,6.1c-2.9,0-5.4-0.5-7.6-1.6c-2.2-1.1-3.8-2.6-5-4.5 c-1.2-1.9-1.8-4.1-1.8-6.5c0-3.7,1.4-6.6,4.1-8.8c2.7-2.2,6.6-3.3,11.5-3.3H968v-2c0-2.9-0.9-5.2-2.7-6.8 c-1.8-1.6-4.4-2.4-7.9-2.4c-4.2,0-8.4,1.5-12.8,4.5l-3.1-5c3-1.9,5.7-3.3,8.3-4.2c2.5-0.9,5.5-1.3,9-1.3c5.2,0,9.2,1.3,12,3.8 c2.8,2.5,4.3,6.1,4.3,10.6l0.1,27.3H968.1z M963.8,411.3c2.4-1.6,3.8-3.8,4.3-6.5v-4.2h-12.1c-3.3,0-5.7,0.5-7.3,1.5 c-1.6,1-2.4,2.6-2.4,4.7s0.8,3.8,2.4,5.1c1.6,1.3,3.8,1.9,6.5,1.9C958.5,413.8,961.4,413,963.8,411.3z"
                    />
                    <Path
                    fill="#43556B"
                    d="M1025,378.1v41.4h-7.4v-8.8c-2.7,5.9-7.8,8.9-15.3,9c-4.8,0-8.6-1.4-11.3-4.3c-2.7-2.8-4.1-6.8-4.1-11.8 v-25.6h7.4v23.7c0,3.5,1,6.2,2.9,8.2c1.9,2,4.5,2.9,7.9,2.9c3.9-0.1,6.9-1.4,9.2-4.1c2.3-2.7,3.4-6.1,3.4-10.3v-20.4H1025z"
                    />
                    <Path
                    fill="#43556B"
                    d="M1060.4,364.7h8.9l20,38.5l19.9-38.5h9v54.8h-7l-0.1-43.8l-19.5,38h-4.6l-19.7-38v43.8h-7V364.7z"
                    />
                    <Path
                    fill="#43556B"
                    d="M1158.5,419.5v-5.8c-3.1,4.1-7.9,6.1-14.5,6.1c-2.9,0-5.4-0.5-7.6-1.6c-2.2-1.1-3.8-2.6-5-4.5 c-1.2-1.9-1.8-4.1-1.8-6.5c0-3.7,1.4-6.6,4.1-8.8c2.7-2.2,6.6-3.3,11.5-3.3h13.2v-2c0-2.9-0.9-5.2-2.7-6.8 c-1.8-1.6-4.4-2.4-7.9-2.4c-4.2,0-8.4,1.5-12.8,4.5l-3.1-5c3-1.9,5.7-3.3,8.3-4.2c2.5-0.9,5.5-1.3,9-1.3c5.2,0,9.2,1.3,12,3.8 c2.8,2.5,4.3,6.1,4.3,10.6l0.1,27.3H1158.5z M1154.2,411.3c2.4-1.6,3.8-3.8,4.3-6.5v-4.2h-12.1c-3.3,0-5.7,0.5-7.3,1.5 c-1.6,1-2.4,2.6-2.4,4.7s0.8,3.8,2.4,5.1c1.6,1.3,3.8,1.9,6.5,1.9C1148.9,413.8,1151.8,413,1154.2,411.3z"
                    />
                    <Path
                    fill="#43556B"
                    d="M1195.7,384.7c-2.1-0.6-4-0.9-5.9-0.9c-2.2,0-4,0.4-5.3,1.2c-1.3,0.8-2,2-2,3.7s0.7,2.9,2.2,3.8 c1.5,0.9,3.7,1.7,6.8,2.6c2.9,0.8,5.2,1.7,7,2.5c1.8,0.8,3.4,2.1,4.7,3.7c1.3,1.6,1.9,3.8,1.9,6.4c0,3.9-1.5,6.9-4.4,8.9 c-2.9,2-6.6,3.1-11,3.1c-3.1,0-6.1-0.5-9-1.5c-2.9-1-5.3-2.4-7.3-4.2l2.7-5.3c1.7,1.6,3.8,2.8,6.4,3.8c2.5,0.9,5,1.4,7.4,1.4 c2.4,0,4.3-0.4,5.8-1.3c1.5-0.9,2.2-2.2,2.2-4.1c0-1.9-0.8-3.3-2.3-4.2c-1.5-0.9-3.9-1.9-7-2.8c-2.8-0.8-5-1.6-6.8-2.4 c-1.7-0.8-3.2-2-4.5-3.6c-1.2-1.6-1.8-3.6-1.8-6.1c0-3.9,1.4-6.8,4.2-8.7c2.8-1.9,6.3-2.9,10.6-2.9c2.5,0,5,0.4,7.4,1.1 c2.4,0.7,4.5,1.7,6.3,2.9l-2.7,5.6C1199.6,386.2,1197.7,385.3,1195.7,384.7z"
                    />
                    <Path
                    fill="#43556B"
                    d="M1223.7,432.6c-2.2,1.6-4.7,2.4-7.6,2.4c-1.8,0-3.4-0.3-4.9-0.8c-1.5-0.5-2.9-1.3-4.2-2.4l3.1-5.7 c1.8,1.3,3.7,2,5.6,2c3.1,0,5.5-1.8,7-5.3l1.6-3.7l-17.2-41h7.7l13.2,33.2l12.5-33.2h7.4l-19,47.3 C1227.6,428.6,1225.9,431,1223.7,432.6z"
                    />
                    <Path
                    fill="#43556B"
                    d="M1278.9,419.5v-5.8c-3.1,4.1-7.9,6.1-14.5,6.1c-2.9,0-5.4-0.5-7.6-1.6c-2.2-1.1-3.8-2.6-5-4.5 c-1.2-1.9-1.8-4.1-1.8-6.5c0-3.7,1.4-6.6,4.1-8.8c2.7-2.2,6.6-3.3,11.5-3.3h13.2v-2c0-2.9-0.9-5.2-2.7-6.8 c-1.8-1.6-4.4-2.4-7.9-2.4c-4.2,0-8.4,1.5-12.8,4.5l-3.1-5c3-1.9,5.7-3.3,8.3-4.2c2.5-0.9,5.5-1.3,9-1.3c5.2,0,9.2,1.3,12,3.8 c2.8,2.5,4.3,6.1,4.3,10.6l0.1,27.3H1278.9z M1274.5,411.3c2.4-1.6,3.8-3.8,4.3-6.5v-4.2h-12.1c-3.3,0-5.7,0.5-7.3,1.5 c-1.6,1-2.4,2.6-2.4,4.7s0.8,3.8,2.4,5.1c1.6,1.3,3.8,1.9,6.5,1.9C1269.3,413.8,1272.2,413,1274.5,411.3z"
                    />
                    <Path
                    fill="#43556B"
                    d="M1312.2,380.2c2.5-1.5,5.5-2.3,8.9-2.4v7c-4.2-0.2-7.6,0.9-10.3,3.3c-2.6,2.4-4.1,5.5-4.5,9.5v21.9h-7.3 v-41.4h7.3v8.9C1307.7,384,1309.7,381.8,1312.2,380.2z"
                    />
                    <Path
                    fill="#43556B"
                    d="M1352.5,419.5v-5.8c-3.1,4.1-7.9,6.1-14.5,6.1c-2.9,0-5.4-0.5-7.6-1.6c-2.2-1.1-3.8-2.6-5-4.5 s-1.8-4.1-1.8-6.5c0-3.7,1.4-6.6,4.1-8.8c2.7-2.2,6.6-3.3,11.5-3.3h13.2v-2c0-2.9-0.9-5.2-2.7-6.8c-1.8-1.6-4.4-2.4-7.9-2.4 c-4.2,0-8.4,1.5-12.8,4.5l-3.1-5c3-1.9,5.7-3.3,8.3-4.2c2.5-0.9,5.5-1.3,9-1.3c5.2,0,9.2,1.3,12,3.8c2.8,2.5,4.3,6.1,4.3,10.6 l0.1,27.3H1352.5z M1348.2,411.3c2.4-1.6,3.8-3.8,4.3-6.5v-4.2h-12.1c-3.3,0-5.7,0.5-7.3,1.5c-1.6,1-2.4,2.6-2.4,4.7 s0.8,3.8,2.4,5.1c1.6,1.3,3.8,1.9,6.5,1.9C1342.9,413.8,1345.8,413,1348.2,411.3z"
                    />
                    <Path
                    fill="#43556B"
                    d="M1401.7,419.5l-13.2-20.1l-8.1,8.4v11.7h-7.3v-58.1h7.3v37l20.1-20.4h8.5l-15.4,16.1l16.9,25.4H1401.7z"
                    />
                    <Path
                    fill="#43556B"
                    d="M1441.5,419.5v-5.8c-3.1,4.1-7.9,6.1-14.5,6.1c-2.9,0-5.4-0.5-7.6-1.6c-2.2-1.1-3.8-2.6-5-4.5 c-1.2-1.9-1.8-4.1-1.8-6.5c0-3.7,1.4-6.6,4.1-8.8c2.7-2.2,6.6-3.3,11.5-3.3h13.2v-2c0-2.9-0.9-5.2-2.7-6.8 c-1.8-1.6-4.4-2.4-7.9-2.4c-4.2,0-8.4,1.5-12.8,4.5l-3.1-5c3-1.9,5.7-3.3,8.3-4.2c2.5-0.9,5.5-1.3,9-1.3c5.2,0,9.2,1.3,12,3.8 c2.8,2.5,4.3,6.1,4.3,10.6l0.1,27.3H1441.5z M1437.1,411.3c2.4-1.6,3.8-3.8,4.3-6.5v-4.2h-12.1c-3.3,0-5.7,0.5-7.3,1.5 c-1.6,1-2.4,2.6-2.4,4.7s0.8,3.8,2.4,5.1c1.6,1.3,3.8,1.9,6.5,1.9C1431.9,413.8,1434.7,413,1437.1,411.3z"
                    />
                    <Path
                    fill="#43556B"
                    d="M1483.9,416.8c-3.2,2-6.4,3.1-9.7,3.1c-3.3,0-6-1-8-2.9c-2-2-3.1-4.9-3.1-8.9V385h-5.9v-5.6h5.9v-11.1h7.4 v11.1h12.7v5.6h-12.7v21.6c0,2.3,0.4,3.9,1.3,4.9c0.8,1,2.1,1.5,3.8,1.5c2,0,4.1-0.7,6.4-2L1483.9,416.8z"
                    />
                </G>
                <G>
                    <Path
                    fill="#184999"
                    d="M142.5,124.3c11.8,8.3,17.6,19.5,17.6,33.7c0,9.8-2.9,18.4-8.6,25.7c-5.7,7.3-13.5,12.2-23.3,14.9 c11.9,2.3,21.3,7.6,28.2,15.9c6.8,8.3,10.3,18.6,10.3,30.7c0,15.8-6.2,28.3-18.7,37.4c-12.5,9.1-29.4,13.7-50.8,13.7H9.5V111.9 h84.8C114.7,111.9,130.8,116,142.5,124.3z M110.8,180.8c4.5-3.6,6.7-8.6,6.7-15.1c0-6.3-2.2-11.2-6.7-14.8 c-4.5-3.5-10.7-5.2-18.6-5H51.1v40.3h41.1C100.1,186.2,106.3,184.4,110.8,180.8z M115.4,256.4c5.6-4,8.4-9.4,8.4-16.5 c0-6.7-2.8-11.9-8.4-15.8c-5.6-3.9-13.3-5.7-23.2-5.5H51.1v43.7h41.1C102,262.3,109.8,260.3,115.4,256.4z"
                    />
                    <Path
                    fill="#184999"
                    d="M232.2,97.9c4.1,4.2,6.2,9.7,6.2,16.3c0,6.7-2.1,12.1-6.2,16.3c-4.1,4.2-9.4,6.3-15.9,6.3 c-6.3,0-11.5-2.1-15.7-6.5c-4.1-4.3-6.2-9.7-6.2-16.2c0-6.7,2.1-12.1,6.2-16.3c4.1-4.2,9.4-6.3,15.7-6.3 C222.8,91.6,228.1,93.7,232.2,97.9z M196.3,154.6h10.1c16.8,0,30.4,13.6,30.4,30.4v111.3h-10.1c-16.8,0-30.4-13.6-30.4-30.4V154.6 z"
                    />
                    <Path
                    fill="#184999"
                    d="M403.2,167.7c9.4,9.8,14.1,23,14.1,39.5v89h-40.6v-77.2c0-9-2.5-16-7.5-21.2c-5-5.2-11.8-7.8-20.4-7.8 c-10,0.2-17.9,3.6-23.7,10.4c-5.8,6.8-8.7,15.4-8.7,25.9v69.8h-40.6V154.6h40.6v24.8c9.8-17.2,26.2-26,49-26.3 C381.2,153,393.8,157.9,403.2,167.7z"
                    />
                    <Path
                    fill="#184999"
                    d="M540,288.4c-5.3,3.2-11.1,5.6-17.5,7.2c-6.4,1.7-12.9,2.5-19.4,2.5c-13.2,0-23.9-3.8-32.1-11.3 c-8.3-7.5-12.4-18.3-12.4-32.4v-65.6h-19.8v-27.9h19.8v-39h9.9c16.8,0,30.4,13.6,30.4,30.4v8.6h38.5v28.2h-38.5v58.5 c0,10.9,4.3,16.3,12.9,16.3c6,0,12.6-1.8,19.8-5.3L540,288.4z"
                    />
                    <Path
                    fill="#184999"
                    d="M646.7,296.3v-16.6c-4.7,6-10.9,10.5-18.4,13.7c-7.6,3.2-16.2,4.7-26.1,4.7c-14.9,0-26.8-4.1-35.6-12.2 c-8.8-8.2-13.2-18.8-13.2-32c0-13.3,4.7-23.7,14.1-31.2c9.4-7.5,22.7-11.3,39.9-11.5h39v-2.6c0-7.5-2.5-13.4-7.5-17.5 c-5-4.1-12.3-6.2-22-6.2c-13.2,0-27.2,4.1-42.1,12.4l-12.6-27.9c11.2-5.6,21.8-9.7,31.6-12.4c9.8-2.6,20.6-4,32.4-4 c19,0,33.7,4.5,44.1,13.4c10.4,9,15.8,21.4,15.9,37.4l0.3,92.5H646.7z M635.3,263.1c6.1-4,9.8-9.1,11.1-15.3v-12.6h-31.9 c-7.9,0-13.7,1.3-17.5,3.8c-3.8,2.5-5.7,6.6-5.7,12.2c0,5.4,2,9.8,5.9,13c4,3.3,9.4,4.9,16.2,4.9 C621.9,269.2,629.2,267.1,635.3,263.1z"
                    />
                    <Path
                    fill="#184999"
                    d="M848.8,167.7c9.4,9.8,14.1,23,14.1,39.5v89h-40.6v-77.2c0-9-2.5-16-7.5-21.2c-5-5.2-11.8-7.8-20.4-7.8 c-10,0.2-17.9,3.6-23.7,10.4c-5.8,6.8-8.7,15.4-8.7,25.9v69.8h-40.6V154.6H762v24.8c9.8-17.2,26.2-26,49-26.3 C826.8,153,839.4,157.9,848.8,167.7z"
                    />
                    <Path
                    fill="#184999"
                    d="M1030.7,154.6v128.5c0,13.3-3.2,25-9.5,34.9c-6.3,9.9-15.2,17.6-26.6,22.9c-11.4,5.4-24.6,8-39.5,8 c-11.6,0-22.6-1.7-33.1-5c-10.4-3.3-20-7.9-28.6-13.7l15-28.4c13.3,9.5,28,14.2,44,14.2c11.6,0,20.8-2.9,27.7-8.6 c6.8-5.7,10.3-13.4,10.3-23v-16.1c-4.7,6.7-10.7,11.8-17.9,15.3c-7.2,3.5-15.5,5.3-24.8,5.3c-12.1,0-22.9-2.9-32.3-8.6 c-9.4-5.7-16.7-13.7-21.9-24.1c-5.2-10.4-7.8-22.3-7.8-35.8c0-13.3,2.6-25.1,7.8-35.2c5.2-10.1,12.5-18,21.9-23.6 c9.4-5.6,20.2-8.4,32.3-8.4c9.1,0,17.3,1.8,24.5,5.4c7.2,3.6,13.3,8.7,18.2,15.1v-19.2H1030.7z M981.2,248 c6.1-6.8,9.2-15.6,9.2-26.5c0-10.9-3.1-19.8-9.2-26.6c-6.1-6.8-14.1-10.3-24-10.3c-9.8,0-17.8,3.4-24,10.3 c-6.1,6.8-9.3,15.7-9.5,26.6c0.2,10.9,3.3,19.7,9.4,26.5c6.1,6.8,14.1,10.1,24.1,10.1C967.1,258.1,975,254.7,981.2,248z"
                    />
                    <Path
                    fill="#184999"
                    d="M1381,154.6v141.7h-40.3v-25c-9.7,17.7-25.5,26.6-47.4,26.6c-15.6,0-28.1-4.9-37.3-14.6 c-9.2-9.7-13.8-23-13.8-39.6v-89h40.6V232c0,9,2.4,16,7.1,21.1c4.7,5.1,11.2,7.6,19.5,7.6c9.7-0.2,17.3-3.6,22.9-10.3 c5.6-6.7,8.4-15.3,8.4-25.8v-70.1H1381z"
                    />
                    <Path
                    fill="#184999"
                    d="M1486.7,186.1c-7.6-2.4-14.3-3.6-20.3-3.6c-4.9,0-8.9,0.9-11.9,2.6c-3,1.8-4.5,4.4-4.5,7.9c0,3.9,2,6.8,6.1,9 c4,2.1,10.6,4.5,19.8,7.1c10,3,18.3,5.9,24.8,8.8c6.5,2.9,12.1,7.2,16.9,12.9c4.7,5.7,7.1,13.2,7.1,22.5c0,14.1-5.4,25-16.2,32.8 c-10.8,7.8-24.6,11.7-41.5,11.7c-11.6,0-22.7-1.7-33.5-5.1c-10.7-3.4-20-8.3-27.9-14.6l13.2-27.4c7.2,5.6,15.2,10,24.1,13 c8.9,3.1,17.3,4.6,25.2,4.6c5.4,0,9.8-0.9,13-2.8c3.2-1.8,4.9-4.6,4.9-8.3c0-4-2.1-7.2-6.2-9.5c-4.1-2.3-10.8-4.7-19.9-7.4 c-9.7-2.8-17.7-5.6-24.1-8.4c-6.4-2.8-11.9-7.1-16.5-12.8c-4.6-5.7-6.8-13.1-6.8-22.3c0-14,5.3-24.9,15.8-32.7 c10.5-7.7,23.9-11.6,40-11.6c9.7,0,19.3,1.4,29,4.1c9.7,2.7,18.4,6.6,26.3,11.7l-14,27.7C1501.8,191.8,1494.2,188.4,1486.7,186.1z "
                    />
                    <Path
                    fill="#184999"
                    d="M1679.1,167.7c9.4,9.8,14.1,23,14.1,39.5v89h-40.6v-77.2c0-9-2.5-16-7.5-21.2c-5-5.2-11.8-7.8-20.4-7.8 c-10,0.2-17.9,3.6-23.7,10.4c-5.8,6.8-8.7,15.4-8.7,25.9v69.8h-40.6V154.6h40.6v24.8c9.8-17.2,26.2-26,49-26.3 C1657.1,153,1669.7,157.9,1679.1,167.7z"
                    />
                    <Path
                    fill="#184999"
                    d="M1810.4,296.3v-16.6c-4.7,6-10.9,10.5-18.4,13.7c-7.6,3.2-16.2,4.7-26.1,4.7c-14.9,0-26.8-4.1-35.6-12.2 c-8.8-8.2-13.2-18.8-13.2-32c0-13.3,4.7-23.7,14.1-31.2c9.4-7.5,22.7-11.3,39.9-11.5h39v-2.6c0-7.5-2.5-13.4-7.5-17.5 c-5-4.1-12.3-6.2-22-6.2c-13.2,0-27.2,4.1-42.1,12.4l-12.6-27.9c11.2-5.6,21.8-9.7,31.6-12.4c9.8-2.6,20.6-4,32.4-4 c19,0,33.7,4.5,44.1,13.4c10.4,9,15.8,21.4,15.9,37.4l0.3,92.5H1810.4z M1799.1,263.1c6.1-4,9.8-9.1,11.1-15.3v-12.6h-31.9 c-7.9,0-13.7,1.3-17.5,3.8c-3.8,2.5-5.7,6.6-5.7,12.2c0,5.4,2,9.8,5.9,13c4,3.3,9.4,4.9,16.2,4.9 C1785.6,269.2,1792.9,267.1,1799.1,263.1z"
                    />
                    <Path
                    fill="#184999"
                    d="M1951.6,186.1c-7.6-2.4-14.3-3.6-20.3-3.6c-4.9,0-8.9,0.9-11.9,2.6c-3,1.8-4.5,4.4-4.5,7.9c0,3.9,2,6.8,6.1,9 c4,2.1,10.6,4.5,19.8,7.1c10,3,18.3,5.9,24.8,8.8c6.5,2.9,12.1,7.2,16.9,12.9c4.7,5.7,7.1,13.2,7.1,22.5c0,14.1-5.4,25-16.2,32.8 c-10.8,7.8-24.6,11.7-41.5,11.7c-11.6,0-22.7-1.7-33.5-5.1c-10.7-3.4-20-8.3-27.9-14.6l13.2-27.4c7.2,5.6,15.2,10,24.1,13 c8.9,3.1,17.3,4.6,25.2,4.6c5.4,0,9.8-0.9,13-2.8c3.2-1.8,4.9-4.6,4.9-8.3c0-4-2.1-7.2-6.2-9.5c-4.1-2.3-10.8-4.7-19.9-7.4 c-9.7-2.8-17.7-5.6-24.1-8.4c-6.4-2.8-11.9-7.1-16.5-12.8c-4.6-5.7-6.8-13.1-6.8-22.3c0-14,5.3-24.9,15.8-32.7 c10.5-7.7,23.9-11.6,40-11.6c9.7,0,19.3,1.4,29,4.1c9.7,2.7,18.4,6.6,26.3,11.7l-14,27.7C1966.8,191.8,1959.1,188.4,1951.6,186.1z "
                    />
                    <G>
                    <G>
                        <Path
                        fill="#169B48"
                        d="M1154.9,102.3H1072c-2.1,0-3.8,1.7-3.8,3.8v2.2c0,6,1.2,11.9,3.8,17.2c1.5,3.2,3.3,6,5.5,8.1 c12.7,12.7,28.4,14.1,34.2,14.2c0.4,0,0.8,0,1.2,0c0.3,0,0.5,0,0.7,0c5.2,0,10.4,0,15.6,0v0c1,1.3,2.1,2.6,3.2,3.9 c1.2,1.5,2.5,3,3.6,4.6c1,1.5,1.8,3.1,2.1,4.8c0.2,1,0.1,2,0.2,3.1c1.2-0.3,2.4-0.6,3.7-0.7c2.2-0.1,4.3,0.5,6.4,1.2 c2.9,1,5.6,2.3,8.5,3.2c3,9.2,6,18.3,9,27.5c-1.6-1.1-3.1-2.2-4.6-3.4c-6.8-5-13.6-9.9-20.5-14.9c-8.4,6.1-16.7,12.2-25.1,18.3 c0-0.1,0.1-0.2,0.1-0.4l0,0.1c0-0.3,0.2-0.7,0.3-1c3.1-9.3,6.1-18.6,9.2-28c-10.4,0.2-20.6,3.1-29.4,8.6 c-6.5,4.1-12.9,9.3-18.4,16c-0.6,0.7-1.1,1.4-1.7,2.1c-4.8,6.3-6.1,14.1-6.1,22.1v78.3c0,2.1,1.7,3.8,3.8,3.8h44.6v-50.5 c0-17.2,14-31.2,31.2-31.2h6.9c30.9,0,56-25.1,56-56v0C1212.1,127.9,1186.5,102.3,1154.9,102.3z M1157.2,165.2 c-2.2-0.8-4.3-1.7-6.5-2.5c-3.2-1.2-6.5-2.3-10-1.9l0-0.1c-0.2-1.2-0.6-2.4-1.1-3.5c-0.9-1.9-2.2-3.6-3.5-5.3 c-1.5-1.9-3.1-3.7-4.6-5.6c2.8-8.8,5.7-17.6,8.5-26.4c1.1,1.7,2.2,3.4,3.3,5.1c2,3.2,4.1,6.3,5.7,9.7c1.1,2.2,1.9,4.5,2.2,7 c0.2,1.6,0.1,3.3-0.1,5l0.2-0.1c2.7-1.2,5.6-1.7,8.5-1.6c3.6,0,7.2,0.9,10.7,1.6c3.2,0.7,6.4,1.3,9.6,2 C1172.5,154.2,1164.9,159.7,1157.2,165.2z"
                        />
                    </G>
                    </G>
                </G>
            </G>
        </Svg>
        )
    }
}

export default MainLogo
