import React, { Component } from 'react'; 
import { StyleSheet, View, Image, FlatList, Dimensions , ScrollView } from 'react-native'; 
import { Alert, AlertTitle, Typography, Button , IconButton} from '@mui/material'; 
import * as Identifier from '../constants/PageIdentifiers'; 
import { code_ok, code_empty, code_error, code_auth_error, updatePassword } from '../constants/api';
import * as Command from '../constants/Commands';
import { primary , color_text, sub_text } from '../constants/colors';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { CalendarIcon, TimeIcon } from '../assets/Icons';
import PasswordBox from '../components/PasswordBox';
import LoadingPage from '../components/LoadingPage';
import AlertDialog from '../components/AlertDialog';
import { executePost, executeGet } from '../utilities/Utilities';

var _is_mounted = false;
class UpdatePassword extends Component {
    constructor(props){
        super(props)
        this.state = {
            showLoading : false, 
            showAlert : false,
            alertMessage : "",  
            errorCurrentPass : false, 
            errorCurrentPassMessage : "", 
            errorNewPass : false, 
            errorNewPassMessage : "", 
            errorRetypePass : false, 
            errorRetypePassMessage : "",
            old_pass : "", 
            new_pass : "",  
            retype_pass : ""
        }

        this.goBack = this.goBack.bind(this); 
        this.handlePasswordChange = this.handlePasswordChange.bind(this); 
        this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this); 
        this.handleRetypePasswordChange = this.handleRetypePasswordChange.bind(this); 
        this.handleUpdateClick = this.handleUpdateClick.bind(this);
        this.executeUpdate = this.executeUpdate.bind(this); 
        this.handleAlertClose = this.handleAlertClose.bind(this); 
    }

    componentDidMount = ()=>{
        //console.log("Update Password Mount");
        _is_mounted = true;
        if (global.os == "ANDROID") {
            JSBridge.launchFunction(Command.SET_PAGE, Identifier.UPDATE_PASSWORD);    
        }
        window.addEventListener("message", (event)=>{
            if(event.data.id == "go_back"){
                //console.log("PAGE: " + event.data.page);
                if(!this.state.showLoading) {
                    if(event.data.page == Identifier.UPDATE_PASSWORD){
                        this.goBack(); 
                    }
                }
            }
        });
    }
    componentWillUnmount = ()=>{
        
        _is_mounted = false;
        window.removeEventListener("message", null); 
    }   
    goBack = ()=>{
        if(!this.state.showLoading){
            this.props.navigation.navigate(this.props.route.params.identifier, { identifier : Identifier.MAIN });
        }
    }
    handlePasswordChange = (text)=>{
        if(_is_mounted){
            this.setState({
                old_pass : text ,
                errorCurrentPass : false
            })
        }
    }
    handleNewPasswordChange = (text)=>{
        if(_is_mounted){
            this.setState({
                new_pass : text ,
                errorNewPass : false
            })
        }
    }
    handleRetypePasswordChange = (text)=>{
        if(_is_mounted){
            this.setState({
                retype_pass : text ,
                errorRetypePass : false
            })
        }
    }
    handleUpdateClick = ()=>{
        if(this.state.old_pass.length == 0 ) {
            this.setState({
                errorCurrentPass : true, 
                errorCurrentPassMessage : "Password lama harap diisi"
            })
            return; 
        }

        let regPass = /^(?=.*\d).{8,}$/; 
        if(!regPass.test(this.state.new_pass)) {
            this.setState({
                errorNewPass : true, 
                errorNewPassMessage : 'Kata sandi minimal 8-karakter kombinasi huruf angka'
            })
            return;
        }
        if(this.state.retype_pass != this.state.new_pass){
            this.setState({
                errorRetypePass : true, 
                errorRetypePassMessage : 'Ketik ulang sandi baru anda'
            })
            return;
        }

        this.executeUpdate();
        
    }

    executeUpdate = async()=>{
        let formData = new FormData();
        formData.append("old_pass", this.state.old_pass);
        formData.append("new_pass", this.state.new_pass); 
        this.setState({
            showLoading : true
        })

        const response = await executePost(updatePassword, formData);
        if(response.code == code_ok){
            this.setState({ 
                showAlert : true, 
                alertMessage : response.msg
            })
        } else {
            if( response.code != code_auth_error ) {
                this.setState({
                    showAlert : true, 
                    alertMessage : response.msg
                })
            } else {
                JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                window.postMessage({ id: "sign_out"});
            }
        }

    }
    handleAlertClose = ()=>{
        this.setState({
            showAlert : false, 
            showLoading : false 
        })
    }

    render(){
        return(
            <View style={{ flex : 1, backgroundColor : primary}}>
                 <View style={{
                    height : 56 , 
                    flexDirection : "row", 
                    alignItems : "center", 
                    backgroundColor : primary ,
                    padding : 12,
                    // marginTop : (global.os == "ANDROID" ? 0 : 32 ) , 
                }}>
                    <IconButton
                        onClick={()=>{ this.goBack() }}
                    >
                        <ChevronLeftIcon 
                            sx={{color : "white"}}
                        />
                    </IconButton>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 16 }
                        color="white"
                        fontWeight={"500"}
                        style={{ marginLeft : 15 }}
                    >
                        Ganti Password
                    </Typography>
                </View>
                {
                    this.state.showLoading && 
                    <LoadingPage 
                        message={"Mengganti Password Anda"}
                    />
                }
                {
                    !this.state.showLoading && 
                    <View style={{paddingLeft : 16, paddingRight : 16, flex : 1, backgroundColor : "white" }}>
                        <PasswordBox
                            caption={"Password Lama"}
                            containerStyle={{ marginTop : 16 }}
                            placeholder={"Masukkan password lama"}
                            error={ this.state.errorCurrentPass }
                            errorMessage={ this.state.errorCurrentPassMessage }
                            onChangeText={ this.handlePasswordChange }
                        />
                        <PasswordBox
                            caption={"Password Baru"}
                            containerStyle={{ marginTop : 16 }}
                            placeholder={"Masukkan password baru"}
                            error={ this.state.errorNewPass }
                            errorMessage={ this.state.errorNewPassMessage }
                            onChangeText={ this.handleNewPasswordChange }
                        />
                        <PasswordBox
                            caption={"Ketik ulang password paru"}
                            containerStyle={{ marginTop : 16 }}
                            placeholder={"Ketik ulang password baru"}
                            error={ this.state.errorRetypePass }
                            errorMessage={ this.state.errorRetypePassMessage }
                            onChangeText={ this.handleRetypePasswordChange }
                        />
                        <Button
                            variant="contained"
                            style={{ marginTop : 16,  textTransform : "none", borderRadius : 8, padding : 10  }}
                            onClick={ this.handleUpdateClick }
                        >
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 16 }
                                fontWeight="500"
                            >
                            Update
                            </Typography>
                        </Button>
                    </View>
                }
                <AlertDialog
                    showing={ this.state.showAlert } 
                    title={ "Perhatian!" }
                    message={ this.state.alertMessage }
                    onClose={ this.handleAlertClose }
                    onClick={ this.handleAlertClose }
                    buttonCaption={"OK"}
                />

            </View>
        )
    }
}


export default UpdatePassword;