import React, { Component } from "react"
import Svg, { G, Path, Defs, ClipPath, Rect } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

class LogoSplash extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Svg width={228} height={254} viewBox="0 0 228 254" fill="none" xmlns="http://www.w3.org/2000/svg" {...this.props}>
        <Path d="M112.936 10.4684C114.714 13.2981 116.428 16.1749 118.192 19.016C121.417 24.2653 124.776 29.4528 127.416 35.052C129.129 38.6985 130.464 42.5826 130.919 46.6246C131.263 49.3615 131.155 52.1391 130.762 54.8647L131.017 54.754C130.336 55.2536 129.934 56.0281 129.44 56.7001C124.323 63.8826 119.233 71.0878 114.105 78.2621C113.731 76.2444 113.177 74.2543 112.298 72.4042C110.828 69.2181 108.736 66.3966 106.604 63.658C104.143 60.5289 101.602 57.4666 99.2086 54.2805C103.781 39.6748 108.354 25.0708 112.936 10.4684Z" fill="#169B48" />
        <Path d="M131.016 54.754C135.327 52.8372 140.059 51.9992 144.741 52.1147C150.574 52.1945 156.295 53.532 161.971 54.8028C167.08 56.0363 172.236 57.0435 177.383 58.0833C165.088 67.2801 152.759 76.4331 140.463 85.63C136.949 84.3722 133.524 82.8784 130.055 81.5002C124.972 79.4759 119.549 77.6958 114.032 78.3662L114.105 78.2621C119.233 71.0878 124.323 63.8826 129.44 56.7001C129.934 56.0281 130.336 55.2536 131.016 54.754Z" fill="#11743B" />
        <Path d="M48.4379 56.7714C59.4513 56.7665 70.4647 56.8088 81.4781 56.8072C86.0728 56.7958 90.6676 56.8658 95.2638 56.8186C96.9474 58.9779 98.6136 61.1534 100.343 63.2769C102.347 65.7828 104.392 68.2642 106.16 70.9572C107.737 73.385 109.07 76.0634 109.515 78.9793C109.797 80.6602 109.712 82.3736 109.813 84.0708C109.834 84.4369 109.591 84.7217 109.398 84.9999C102.511 94.615 95.7104 104.295 88.872 113.946C84.0027 120.81 79.1508 127.686 74.291 134.556C74.0764 134.877 73.8713 135.215 73.57 135.459C73.6394 134.878 73.8745 134.341 74.0386 133.786C78.993 118.177 83.9506 102.57 88.8988 86.959C78.3682 79.1095 67.8392 71.2583 57.3102 63.4054C54.3564 61.1892 51.3743 59.012 48.4379 56.7714Z" fill="#215FAC" />
        <Path d="M109.813 84.0708C111.757 83.5876 113.71 83.0018 115.725 82.9888C119.258 82.8065 122.723 83.791 126.03 84.9739C130.633 86.6646 135.103 88.723 139.732 90.342C144.534 105.543 149.379 120.731 154.189 135.931C151.658 134.128 149.209 132.198 146.706 130.351C135.734 122.087 124.735 113.858 113.774 105.579C100.307 115.705 86.838 125.828 73.3679 135.949C73.4089 135.749 73.4515 135.549 73.491 135.352L73.5699 135.459C73.8713 135.215 74.0764 134.877 74.291 134.556C79.1507 127.686 84.0026 120.81 88.8719 113.946C95.7103 104.295 102.511 94.615 109.398 85C109.591 84.7217 109.834 84.437 109.813 84.0708Z" fill="#184999" />
        <Path d="M23.7398 143.398C25.5717 142.832 27.6765 142.435 29.4501 143.4C31.2283 144.345 31.4445 147.227 29.9203 148.519C28.7874 149.554 27.1779 149.593 25.7484 149.507C25.75 150.618 25.7453 151.731 25.7516 152.846C25.081 152.849 24.412 152.849 23.7414 152.846C23.7445 149.697 23.7461 146.547 23.7398 143.398ZM25.7563 144.708C25.7326 145.756 25.7563 146.806 25.75 147.853C26.591 147.883 27.5409 148.024 28.2635 147.469C29.1913 146.812 29.0887 145.066 27.9905 144.656C27.2742 144.451 26.4458 144.472 25.7563 144.708Z" fill="#58585B" />
        <Path d="M49.4366 143.411C51.2748 142.822 53.3955 142.44 55.18 143.402C56.9851 144.378 57.1634 147.338 55.5556 148.592C54.4321 149.554 52.8827 149.595 51.4957 149.5C51.4531 150.613 51.4847 151.73 51.4784 152.846C50.7999 152.849 50.1214 152.849 49.443 152.844C49.4319 149.701 49.4445 146.555 49.4366 143.411ZM51.4642 144.669C51.4894 145.73 51.4689 146.791 51.4768 147.852C52.2847 147.883 53.1793 148.016 53.8941 147.532C54.8503 146.951 54.8597 145.276 53.8704 144.728C53.1115 144.385 52.2531 144.531 51.4642 144.669Z" fill="#58585B" />
        <Path d="M68.3852 143.44C69.8195 142.531 71.6624 142.713 73.1503 143.382C73.1424 143.964 73.144 144.547 73.1566 145.129C72.0505 144.7 70.8025 144.257 69.6364 144.71C69.0258 144.937 68.7702 145.935 69.3382 146.347C70.5674 147.108 72.2762 146.968 73.2339 148.202C74.1933 149.666 73.4738 151.946 71.8801 152.58C70.2692 153.238 68.3631 153.049 66.8973 152.097C66.9052 151.484 66.9004 150.87 66.9036 150.258C68.068 150.924 69.4234 151.563 70.7804 151.197C71.5204 151.065 71.9827 150.003 71.4352 149.409C70.2755 148.564 68.6282 148.743 67.5963 147.661C66.5123 146.413 67.014 144.243 68.3852 143.44Z" fill="#58585B" />
        <Path d="M155.631 143.423C157.051 142.533 158.848 142.733 160.33 143.353C160.347 143.939 160.355 144.526 160.355 145.114C159.315 144.728 158.185 144.318 157.078 144.633C156.543 144.736 156.128 145.32 156.24 145.877C156.35 146.342 156.827 146.511 157.21 146.667C158.291 147.081 159.588 147.175 160.39 148.129C161.439 149.561 160.729 151.91 159.129 152.566C157.516 153.245 155.614 153.044 154.129 152.123C154.124 151.5 154.124 150.879 154.127 150.259C155.29 150.936 156.649 151.559 158.009 151.198C158.761 151.051 159.244 149.902 158.585 149.357C157.403 148.562 155.749 148.724 154.755 147.59C153.737 146.319 154.266 144.199 155.631 143.423Z" fill="#58585B" />
        <Path d="M32.1151 143.005C34.2484 143.006 36.3832 143.001 38.518 143.006C38.5133 143.555 38.5133 144.106 38.5164 144.656C37.0554 144.661 35.5927 144.648 34.1316 144.663C34.1379 145.431 34.1158 146.199 34.1474 146.967C35.2677 146.967 36.3863 146.967 37.5066 146.967C37.524 147.517 37.5429 148.067 37.5177 148.618C36.3895 148.594 35.2598 148.609 34.1316 148.61C34.13 149.474 34.1426 150.34 34.1284 151.206C35.6747 151.227 37.2195 151.207 38.7642 151.217C38.7673 151.759 38.7673 152.304 38.7657 152.847C36.5489 152.846 34.3304 152.851 32.1135 152.846C32.1198 149.565 32.1167 146.285 32.1151 143.005Z" fill="#58585B" />
        <Path d="M40.2283 143.416C42.0113 142.871 44.0183 142.489 45.7792 143.315C47.215 143.943 47.8998 145.935 47.1188 147.341C46.7638 148.049 46.0458 148.425 45.3768 148.75C46.1121 149.782 46.7575 151.176 48.1397 151.352C48.1365 151.881 48.1365 152.41 48.1397 152.942C47.4107 152.926 46.6123 152.994 45.9748 152.555C44.8546 151.85 44.2881 150.579 43.5497 149.518C43.2862 149.024 42.6756 149.186 42.2274 149.152C42.2353 150.384 42.2306 151.616 42.2306 152.847C41.5632 152.847 40.8942 152.847 40.2267 152.847C40.2283 149.704 40.2236 146.56 40.2283 143.416ZM42.2338 144.654C42.2259 145.629 42.2385 146.604 42.2259 147.58C43.0495 147.605 43.9505 147.766 44.6984 147.312C45.5125 146.841 45.6608 145.473 44.8782 144.899C44.1051 144.327 43.1079 144.585 42.2338 144.654Z" fill="#58585B" />
        <Path d="M57.5815 143.011C58.2647 143 58.9479 143.001 59.6311 143.008C59.6232 144.889 59.6232 146.771 59.6295 148.652C59.6358 149.411 59.7258 150.258 60.2796 150.817C60.9723 151.432 62.0799 151.427 62.7773 150.823C63.3422 150.267 63.4227 149.406 63.4274 148.646C63.4448 146.768 63.4416 144.892 63.429 143.014C64.1138 142.995 64.7986 143.003 65.4834 143.009C65.4171 145.097 65.5275 147.186 65.4439 149.276C65.3603 150.559 64.7339 151.882 63.5757 152.471C61.878 153.368 59.456 153.082 58.3057 151.404C57.804 150.642 57.5973 149.71 57.5957 148.799C57.5736 146.869 57.6004 144.939 57.5815 143.011Z" fill="#58585B" />
        <Path d="M74.2326 142.995C76.8534 143.016 79.4758 142.998 82.0982 143.005C82.095 143.603 82.0982 144.204 82.0887 144.804C81.1168 144.783 80.1464 144.814 79.1776 144.783C79.1524 147.47 79.146 150.159 79.1839 152.849C78.5023 152.847 77.8206 152.847 77.1406 152.849C77.18 150.164 77.1501 147.479 77.1627 144.796C76.1765 144.794 75.1904 144.801 74.2058 144.793C74.2058 144.192 74.1869 143.592 74.2326 142.995Z" fill="#58585B" />
        <Path d="M92.0657 143.001C92.7347 143.004 93.4037 143.004 94.0743 143.004C94.0743 144.397 94.0679 145.792 94.0758 147.185C95.3081 145.831 96.4726 144.412 97.6512 143.008C98.4118 143.003 99.1739 142.998 99.9344 143.013C98.7289 144.534 97.4398 145.982 96.198 147.473C97.0501 148.493 97.7333 149.655 98.6469 150.62C99.0019 151.02 99.532 151.147 100.024 151.264C100.048 151.824 100.043 152.385 100.042 152.947C99.1407 152.94 98.1593 152.828 97.4619 152.18C96.359 151.196 95.6332 149.865 94.7243 148.706C94.5066 148.895 94.2888 149.085 94.0727 149.279C94.0727 150.467 94.0695 151.656 94.0758 152.846C93.4005 152.849 92.7268 152.849 92.0515 152.844C92.0956 149.564 92.053 146.283 92.0657 143.001Z" fill="#58585B" />
        <Path d="M104.37 143.08C105.105 142.977 105.854 142.969 106.59 143.084C108.567 145.908 109.891 149.37 109.621 152.896C108.941 152.906 108.262 152.864 107.584 152.828C107.588 152.057 107.595 151.284 107.569 150.514C106.168 150.48 104.764 150.47 103.363 150.519C103.379 151.292 103.341 152.065 103.353 152.839C102.68 152.864 102.009 152.908 101.335 152.896C101.056 149.369 102.389 145.9 104.37 143.08ZM103.666 148.877C104.873 148.898 106.078 148.889 107.285 148.884C106.859 147.406 106.295 145.956 105.465 144.669C104.676 145.976 104.084 147.401 103.666 148.877Z" fill="#58585B" />
        <Path d="M113.731 143.343C114.009 142.863 114.637 143.055 115.086 143C115.492 143.049 116.058 142.892 116.301 143.337C118.185 146.098 119.422 149.469 119.162 152.885C118.48 152.919 117.802 152.856 117.122 152.835C117.123 152.058 117.123 151.282 117.114 150.508C115.716 150.48 114.32 150.48 112.923 150.508C112.92 151.285 112.904 152.065 112.903 152.844C112.229 152.852 111.557 152.909 110.883 152.898C110.612 149.479 111.85 146.108 113.731 143.343ZM113.217 148.885C114.419 148.889 115.62 148.89 116.822 148.885C116.431 147.406 115.811 146.002 115.053 144.686C114.176 145.935 113.643 147.416 113.217 148.885Z" fill="#58585B" />
        <Path d="M120.224 143.014C120.809 142.996 121.396 143.003 121.983 143.009C123.531 144.88 125.057 146.77 126.595 148.649C126.504 146.776 126.529 144.902 126.52 143.027C127.147 143.001 127.779 142.988 128.408 143.022C128.377 146.314 128.41 149.607 128.391 152.901C127.913 152.868 127.435 152.844 126.957 152.828C126.426 151.157 125.158 149.938 124.112 148.61C123.443 147.787 122.774 146.963 122.068 146.174C122.136 148.395 122.113 150.618 122.124 152.841C121.496 152.85 120.868 152.854 120.241 152.837C120.248 149.564 120.227 146.29 120.224 143.014Z" fill="#58585B" />
        <Path d="M135.119 142.998C135.712 143.006 136.307 142.993 136.902 143.026C138.405 144.923 139.956 146.781 141.484 148.661C141.362 146.784 141.405 144.905 141.395 143.027C142.02 142.998 142.647 143 143.273 143.006C143.27 146.295 143.265 149.583 143.275 152.872C142.79 152.88 142.307 152.849 141.826 152.825C141.302 151.137 140.007 149.922 138.962 148.578C138.293 147.764 137.642 146.932 136.919 146.171C137.036 148.394 136.971 150.621 136.992 152.846C136.368 152.849 135.747 152.851 135.125 152.842C135.084 149.562 135.097 146.28 135.119 142.998Z" fill="#58585B" />
        <Path d="M147.446 143.082C148.12 142.988 148.807 142.96 149.483 143.056C149.619 143.069 149.722 143.139 149.793 143.266C151.713 146.044 152.956 149.445 152.71 152.893C152.03 152.911 151.353 152.855 150.675 152.837C150.691 152.061 150.662 151.287 150.662 150.512C149.261 150.481 147.858 150.47 146.456 150.517C146.448 151.29 146.438 152.064 146.448 152.839C145.766 152.859 145.084 152.912 144.403 152.891C144.158 149.365 145.469 145.906 147.446 143.082ZM146.746 148.864C147.953 148.914 149.16 148.878 150.369 148.883C149.962 147.442 149.394 146.041 148.625 144.763L148.53 144.731C147.723 145.993 147.183 147.424 146.746 148.864Z" fill="#58585B" />
        <Path d="M162.231 143.017C162.91 142.991 163.588 143.006 164.268 143.006C164.26 146.285 164.262 149.565 164.267 152.846C163.59 152.849 162.914 152.849 162.239 152.846C162.233 149.57 162.248 146.293 162.231 143.017Z" fill="#58585B" />
        <Path d="M168.648 143.034C170.246 142.606 172.121 142.842 173.367 144.034C175.18 145.906 175.277 149.14 173.805 151.256C173.068 152.364 171.744 152.907 170.48 152.989C169.076 153.081 167.54 152.641 166.616 151.49C165.111 149.572 165.008 146.617 166.4 144.604C166.931 143.82 167.76 143.284 168.648 143.034ZM169.281 144.682C168.367 144.97 167.764 145.884 167.619 146.827C167.411 148.042 167.469 149.432 168.252 150.432C169.185 151.586 171.113 151.565 172.026 150.398C172.724 149.437 172.845 148.151 172.653 146.997C172.556 146.158 172.135 145.331 171.419 144.89C170.778 144.511 169.977 144.425 169.281 144.682Z" fill="#58585B" />
        <Path d="M176.121 143.026C176.716 143.014 177.325 142.943 177.913 143.053C179.45 144.92 180.976 146.798 182.503 148.672C182.372 146.798 182.42 144.917 182.415 143.039C183.037 142.988 183.66 143.003 184.285 143.006C184.289 146.292 184.296 149.575 184.289 152.861C183.807 152.885 183.324 152.848 182.844 152.826C182.469 151.691 181.781 150.705 181.001 149.829C179.979 148.604 179.008 147.335 177.934 146.158C178.055 148.384 177.988 150.615 178.011 152.844C177.383 152.851 176.757 152.851 176.132 152.843C176.126 149.57 176.146 146.298 176.121 143.026Z" fill="#58585B" />
        <Path d="M188.232 143.681C188.422 143.421 188.567 143.016 188.946 143.037C189.581 143.03 190.314 142.873 190.896 143.167C192.879 145.948 194.141 149.406 193.897 152.894C193.215 152.909 192.537 152.859 191.858 152.834C191.879 152.06 191.849 151.285 191.849 150.512C190.445 150.483 189.04 150.463 187.638 150.522C187.638 151.293 187.623 152.066 187.634 152.839C186.953 152.859 186.271 152.912 185.588 152.891C185.362 149.614 186.483 146.378 188.232 143.681ZM187.953 148.893C189.154 148.879 190.353 148.895 191.555 148.883C191.12 147.412 190.583 145.941 189.72 144.677C188.963 145.997 188.319 147.401 187.953 148.893Z" fill="#58585B" />
        <Path d="M195.74 143.021C196.428 143.029 197.116 142.996 197.805 143.045C197.679 145.73 197.752 148.423 197.789 151.111C199.14 151.14 200.494 151.134 201.846 151.114C201.846 151.707 201.863 152.299 201.857 152.893C199.822 152.891 197.785 152.891 195.751 152.893C195.73 149.603 195.749 146.311 195.74 143.021Z" fill="#58585B" />
        <Path d="M85.2904 143.081C86.0131 142.992 86.7578 142.943 87.4773 143.093C89.5254 145.864 90.7892 149.378 90.5494 152.895C89.8693 152.911 89.1909 152.854 88.5108 152.839C88.5187 152.063 88.5155 151.285 88.495 150.511C87.0923 150.486 85.6896 150.46 84.2885 150.524C84.2979 151.295 84.2648 152.066 84.2806 152.839C83.6021 152.862 82.9252 152.911 82.2467 152.891C82.0006 149.365 83.3086 145.903 85.2904 143.081ZM86.1204 145.12C85.4861 146.314 84.9228 147.566 84.6182 148.895C85.8143 148.876 87.0118 148.9 88.2094 148.882C87.8039 147.427 87.2217 146.023 86.4517 144.733C86.2608 144.76 86.215 144.98 86.1204 145.12Z" fill="#58585B" />
        <Path opacity={0.77} d="M60.2182 160.879C61.4994 160.394 63.0094 160.179 64.2654 160.851C65.3163 161.405 65.6287 162.986 64.9076 163.94C64.562 164.415 64.0224 164.669 63.4875 164.843C63.9924 165.538 64.3964 166.325 65.0259 166.916C65.2689 167.142 65.6302 167.127 65.8906 167.319C65.9016 167.588 65.9048 167.858 65.9001 168.131C65.3746 168.133 64.7861 168.136 64.3617 167.767C63.5001 167.04 63.0441 165.962 62.3641 165.079C62.0059 165.038 61.6462 165.004 61.288 164.981C61.2943 166.011 61.2896 167.041 61.2912 168.073C60.9251 168.086 60.559 168.086 60.1945 168.073C60.1756 165.676 60.1283 163.276 60.2182 160.879ZM61.288 161.518C61.2943 162.376 61.2896 163.234 61.2912 164.091C62.0832 164.14 62.9637 164.255 63.6611 163.774C64.3569 163.317 64.3837 162.088 63.6548 161.649C62.94 161.198 62.0627 161.366 61.288 161.518Z" fill="#6C6C6D" />
        <Path opacity={0.77} d="M66.9793 160.531C68.5508 160.526 70.1224 160.541 71.6955 160.53C71.6987 160.84 71.6955 161.154 71.6845 161.468C70.4837 161.436 69.283 161.433 68.0822 161.473C68.0885 162.235 68.087 162.998 68.0854 163.761C69.0305 163.727 69.9772 163.73 70.9224 163.768C70.9224 164.051 70.9224 164.337 70.9239 164.625C69.982 164.671 69.0384 164.648 68.0964 164.643C68.0743 165.478 68.0917 166.314 68.0854 167.151C69.3508 167.146 70.6163 167.162 71.8817 167.139C71.8817 167.452 71.8817 167.764 71.8817 168.08C70.2423 168.083 68.6045 168.098 66.9667 168.072C66.9777 165.558 66.9525 163.044 66.9793 160.531Z" fill="#6C6C6D" />
        <Path opacity={0.77} d="M73.1693 160.876C74.3527 160.394 75.7112 160.253 76.9293 160.674C78.3888 161.206 78.7565 163.457 77.6189 164.508C76.7321 165.386 75.4099 165.474 74.2486 165.362C74.3164 166.265 74.2833 167.173 74.2864 168.079C73.9235 168.081 73.5622 168.086 73.2024 168.099C73.1362 165.692 73.2009 163.283 73.1693 160.876ZM74.2817 161.512C74.2927 162.477 74.2912 163.442 74.2833 164.409C75.0864 164.505 76.0094 164.57 76.68 164.008C77.3585 163.422 77.379 162.148 76.6169 161.626C75.9148 161.185 75.0391 161.33 74.2817 161.512Z" fill="#6C6C6D" />
        <Path opacity={0.77} d="M79.2549 160.533C79.6209 160.512 79.9886 160.512 80.3578 160.528C80.4604 162.21 80.271 163.898 80.4099 165.577C80.4761 166.417 81.1025 167.208 81.9593 167.235C82.936 167.383 83.8133 166.549 83.8543 165.556C83.9474 163.893 83.8685 162.223 83.8953 160.559C84.263 160.531 84.6306 160.513 84.9998 160.505C85.0077 162.14 85.0282 163.776 84.9777 165.409C84.9367 166.552 84.2204 167.736 83.1064 168.033C82.1076 168.29 80.9148 168.2 80.1401 167.43C79.531 166.824 79.2896 165.927 79.2707 165.076C79.2312 163.561 79.2864 162.048 79.2549 160.533Z" fill="#6C6C6D" />
        <Path opacity={0.77} d="M86.6377 160.84C87.799 160.443 89.0676 160.282 90.2684 160.586C91.174 160.827 91.8462 161.86 91.5748 162.816C91.4265 163.418 90.9153 163.813 90.4056 164.082C90.8664 164.308 91.3697 164.536 91.6569 164.998C92.015 165.605 91.8999 166.397 91.5417 166.978C91.1772 167.575 90.5192 167.901 89.8739 168.062C88.7899 168.319 87.6681 168.071 86.6314 167.727C86.6377 165.431 86.6267 163.135 86.6377 160.84ZM87.6949 161.516C87.7107 162.249 87.6996 162.985 87.7043 163.72C88.3039 163.768 88.9146 163.787 89.5094 163.672C89.9638 163.571 90.4293 163.266 90.5445 162.773C90.6912 162.279 90.464 161.696 89.9938 161.493C89.2664 161.169 88.4286 161.263 87.6949 161.516ZM87.7012 164.635C87.7059 165.453 87.7012 166.272 87.7043 167.092C88.5012 167.243 89.3879 167.44 90.139 167.015C90.8238 166.654 91.0336 165.549 90.4183 165.024C89.6214 164.462 88.6069 164.664 87.7012 164.635Z" fill="#6C6C6D" />
        <Path opacity={0.77} d="M93.1228 160.546C93.4904 160.523 93.8612 160.518 94.232 160.534C94.2052 162.731 94.2084 164.929 94.2368 167.126C95.3507 167.142 96.4647 167.121 97.5787 167.137C97.5771 167.448 97.5771 167.76 97.5787 168.076C96.0923 168.092 94.606 168.084 93.1197 168.081C93.1181 165.569 93.1133 163.056 93.1228 160.546Z" fill="#6C6C6D" />
        <Path opacity={0.77} d="M98.7321 160.559C99.095 160.525 99.4594 160.512 99.8239 160.516C99.7939 163.037 99.805 165.559 99.8034 168.081C99.4405 168.089 99.0808 168.083 98.721 168.06C98.7478 165.559 98.7257 163.06 98.7321 160.559Z" fill="#6C6C6D" />
        <Path opacity={0.77} d="M101.714 160.559C102.083 160.528 102.452 160.515 102.825 160.516C102.828 161.667 102.833 162.817 102.82 163.969C103.925 162.874 104.934 161.683 105.998 160.546C106.424 160.523 106.852 160.512 107.281 160.53C106.272 161.717 105.187 162.834 104.177 164.02C104.876 164.855 105.493 165.761 106.216 166.576C106.52 166.931 106.962 167.095 107.397 167.209C107.404 167.517 107.41 167.823 107.415 168.135C106.695 168.159 105.96 167.897 105.472 167.339C104.726 166.498 104.078 165.572 103.387 164.685C103.196 164.827 103.008 164.973 102.82 165.12C102.834 166.106 102.826 167.094 102.825 168.081C102.467 168.081 102.11 168.086 101.753 168.091C101.66 165.584 101.736 163.07 101.714 160.559Z" fill="#6C6C6D" />
        <Path opacity={0.77} d="M110.979 160.552C111.341 160.521 111.705 160.515 112.07 160.529C112.048 163.043 112.059 165.557 112.063 168.071C111.7 168.083 111.337 168.084 110.976 168.078C110.951 165.569 110.948 163.061 110.979 160.552Z" fill="#6C6C6D" />
        <Path opacity={0.77} d="M113.964 160.526C114.357 160.552 114.894 160.347 115.13 160.788C116.402 162.441 117.762 164.024 118.974 165.726C118.797 163.998 118.898 162.256 118.881 160.523C119.216 160.514 119.55 160.51 119.888 160.51C119.913 163.028 119.9 165.549 119.894 168.07C119.64 168.081 119.386 168.094 119.135 168.11C118.758 166.773 117.75 165.809 116.947 164.744C116.281 163.914 115.646 163.056 114.935 162.265C114.979 164.203 114.952 166.141 114.954 168.079C114.622 168.081 114.293 168.081 113.963 168.079C113.961 165.562 113.96 163.043 113.964 160.526Z" fill="#6C6C6D" />
        <Path opacity={0.77} d="M121.666 160.844C122.788 160.414 124.015 160.347 125.192 160.526C126.494 160.765 127.539 161.937 127.723 163.281C128.022 164.806 127.668 166.653 126.33 167.552C124.926 168.446 123.155 168.236 121.658 167.722C121.666 165.429 121.65 163.136 121.666 160.844ZM122.777 161.491C122.764 163.351 122.783 165.211 122.767 167.071C123.774 167.297 124.97 167.39 125.814 166.662C126.636 165.915 126.778 164.682 126.646 163.621C126.554 162.812 126.134 162.009 125.42 161.625C124.599 161.203 123.643 161.296 122.777 161.491Z" fill="#6C6C6D" />
        <Path opacity={0.77} d="M130.786 160.622C131.763 160.347 132.859 160.318 133.792 160.775C134.707 161.239 135.311 162.192 135.516 163.205C135.783 164.562 135.567 166.111 134.622 167.152C133.246 168.599 130.658 168.488 129.401 166.931C128.582 165.836 128.446 164.334 128.746 163.021C128.981 161.935 129.76 160.987 130.786 160.622ZM131.428 161.442C130.608 161.654 130.008 162.42 129.846 163.257C129.652 164.279 129.683 165.434 130.291 166.311C131.147 167.546 133.225 167.508 134.01 166.207C134.639 165.115 134.645 163.681 134.128 162.541C133.658 161.533 132.429 161.132 131.428 161.442Z" fill="#6C6C6D" />
        <Path opacity={0.77} d="M136.914 160.541C137.22 160.521 137.527 160.508 137.834 160.502C138.689 161.516 139.491 162.578 140.321 163.615C140.853 164.303 141.438 164.951 141.889 165.702C141.834 163.971 141.795 162.238 141.842 160.507C142.178 160.508 142.517 160.52 142.857 160.533C142.866 163.042 142.85 165.551 142.868 168.06C142.609 168.075 142.35 168.089 142.094 168.106C141.733 166.846 140.81 165.909 140.035 164.913C139.319 164.013 138.62 163.1 137.882 162.222C137.94 164.179 137.948 166.14 137.893 168.098C137.574 168.088 137.255 168.086 136.94 168.091C136.884 165.575 136.935 163.058 136.914 160.541Z" fill="#6C6C6D" />
        <Path opacity={0.77} d="M144.622 160.531C146.189 160.526 147.759 160.543 149.327 160.53C149.332 160.84 149.329 161.153 149.318 161.467C148.122 161.441 146.924 161.428 145.728 161.477C145.709 162.235 145.706 162.996 145.725 163.756C146.669 163.73 147.614 163.73 148.557 163.768C148.557 164.051 148.559 164.334 148.562 164.62C147.611 164.682 146.658 164.632 145.706 164.656C145.719 165.488 145.705 166.319 145.733 167.151C146.994 167.144 148.254 167.165 149.515 167.138C149.509 167.448 149.506 167.761 149.504 168.076C147.871 168.085 146.238 168.099 144.607 168.068C144.625 165.556 144.597 163.044 144.622 160.531Z" fill="#6C6C6D" />
        <Path opacity={0.77} d="M151.557 160.819C152.639 160.215 153.966 160.355 155.06 160.852C155.072 161.19 155.087 161.528 155.095 161.87C154.206 161.445 153.14 161.099 152.204 161.571C151.608 161.81 151.335 162.798 151.926 163.198C152.927 163.842 154.306 163.688 155.145 164.633C155.951 165.671 155.352 167.347 154.217 167.837C152.983 168.408 151.532 168.184 150.391 167.489C150.375 167.124 150.369 166.762 150.375 166.4C151.363 167.01 152.611 167.538 153.738 166.997C154.38 166.724 154.729 165.649 154.083 165.195C153.121 164.578 151.819 164.724 150.975 163.877C150.107 162.998 150.549 161.376 151.557 160.819Z" fill="#6C6C6D" />
        <Path opacity={0.77} d="M156.726 160.55C157.087 160.523 157.452 160.515 157.816 160.526C157.84 163.038 157.807 165.551 157.834 168.063C157.463 168.083 157.094 168.089 156.726 168.081C156.723 165.57 156.724 163.06 156.726 160.55Z" fill="#6C6C6D" />
        <Path opacity={0.77} d="M159.85 164.383C160.3 162.995 161.037 161.732 161.8 160.508C162.214 160.521 162.629 160.534 163.045 160.541C163.937 161.95 164.771 163.439 165.171 165.086C165.409 166.06 165.448 167.074 165.426 168.076C165.057 168.081 164.689 168.081 164.323 168.078C164.312 167.406 164.314 166.734 164.285 166.062C163.042 166.051 161.797 166.057 160.554 166.059C160.521 166.729 160.483 167.401 160.51 168.075C160.137 168.084 159.766 168.083 159.397 168.075C159.369 166.83 159.446 165.566 159.85 164.383ZM160.727 165.156C161.851 165.126 162.974 165.131 164.098 165.154C163.722 163.839 163.175 162.573 162.406 161.454C161.655 162.583 161.108 163.847 160.727 165.156Z" fill="#6C6C6D" />
        <G clipPath="url(#clip0_731_1342)">
          <Path d="M23.2677 245.138C23.7521 245.561 23.9898 246.142 23.9898 246.899C23.9898 247.692 23.7432 248.309 23.2589 248.74C22.7745 249.181 22.0876 249.392 21.2069 249.392H19.4455V251.602H18.468V244.495H21.2069C22.0964 244.513 22.7833 244.724 23.2677 245.138ZM22.5719 248.115C22.8978 247.851 23.0563 247.454 23.0563 246.935C23.0563 246.424 22.8978 246.045 22.5719 245.781C22.2461 245.517 21.7793 245.393 21.1717 245.393L19.4455 245.402V248.52H21.1717C21.7793 248.52 22.2461 248.388 22.5719 248.115Z" fill="#43556B" />
          <Path
            d="M29.2298 246.952C29.6525 247.446 29.8727 248.124 29.8727 248.996C29.8727 249.128 29.8727 249.233 29.8639 249.304H25.619C25.6894 249.788 25.892 250.167 26.2267 250.449C26.5525 250.731 26.9576 250.871 27.442 250.871C27.759 250.871 28.0585 250.81 28.3315 250.695C28.6045 250.581 28.8423 250.422 29.0448 250.211L29.5644 250.748C29.3002 251.03 28.9832 251.25 28.6133 251.409C28.2434 251.567 27.8295 251.647 27.3715 251.647C26.8431 251.647 26.3764 251.532 25.9624 251.303C25.5573 251.074 25.2315 250.757 25.0113 250.343C24.7823 249.929 24.6678 249.462 24.6678 248.934C24.6678 248.406 24.7823 247.939 25.0113 247.525C25.2403 247.12 25.5573 246.794 25.9624 246.565C26.3676 246.327 26.8343 246.213 27.3539 246.213C28.173 246.213 28.8071 246.459 29.2298 246.952ZM29.0184 248.573C28.992 248.088 28.8247 247.701 28.534 247.419C28.2346 247.137 27.8471 246.996 27.3539 246.996C26.8784 246.996 26.4821 247.137 26.1738 247.419C25.8568 247.701 25.6718 248.088 25.6102 248.564H29.0184V248.573Z"
            fill="#43556B"
          />
          <Path d="M32.8495 246.529C33.1754 246.327 33.5629 246.23 34.0032 246.221V247.137C33.4572 247.12 33.008 247.26 32.6734 247.56C32.3387 247.868 32.145 248.273 32.0921 248.784V251.611H31.1498V246.239H32.0921V247.393C32.2771 247.014 32.5236 246.723 32.8495 246.529Z" fill="#43556B" />
          <Path
            d="M39.3577 246.565C39.754 246.794 40.0535 247.119 40.2736 247.525C40.4938 247.938 40.5995 248.414 40.5995 248.943C40.5995 249.471 40.4938 249.938 40.2825 250.352C40.0711 250.766 39.7717 251.083 39.3754 251.303C38.9879 251.532 38.5387 251.646 38.0367 251.646C37.5876 251.646 37.1825 251.549 36.839 251.364C36.4955 251.171 36.2225 250.907 36.0288 250.554V253.593H35.0864V246.256H36.0288V247.322C36.2225 246.97 36.4955 246.697 36.839 246.512C37.1825 246.327 37.5788 246.23 38.0191 246.23C38.5211 246.212 38.9614 246.327 39.3577 246.565ZM38.7677 250.581C39.0407 250.422 39.2521 250.193 39.4106 249.902C39.5603 249.612 39.6396 249.286 39.6396 248.916C39.6396 248.555 39.5603 248.229 39.4018 247.938C39.2432 247.648 39.0319 247.419 38.7589 247.26C38.4859 247.102 38.1776 247.014 37.8342 247.014C37.4907 247.014 37.1825 247.093 36.9094 247.26C36.6364 247.419 36.4163 247.648 36.2665 247.938C36.108 248.229 36.0288 248.555 36.0288 248.916C36.0288 249.286 36.108 249.621 36.2665 249.902C36.4251 250.193 36.6364 250.413 36.9094 250.581C37.1825 250.739 37.4907 250.827 37.8342 250.827C38.1776 250.827 38.4859 250.739 38.7677 250.581Z"
            fill="#43556B"
          />
          <Path d="M46.6673 246.248V251.62H45.7074V250.484C45.3551 251.25 44.6946 251.638 43.717 251.655C43.0917 251.655 42.6074 251.47 42.2551 251.1C41.9028 250.731 41.7267 250.22 41.7267 249.577V246.257H42.6778V249.321C42.6778 249.771 42.8011 250.132 43.0477 250.378C43.2943 250.634 43.6378 250.757 44.0693 250.757C44.5713 250.748 44.9676 250.572 45.2582 250.22C45.5489 249.867 45.6986 249.427 45.6986 248.881V246.239H46.6673V246.248Z" fill="#43556B" />
          <Path
            d="M50.7009 247.111C50.4367 247.032 50.1813 246.988 49.9347 246.988C49.6529 246.988 49.4239 247.04 49.2478 247.146C49.0716 247.252 48.9924 247.41 48.9924 247.63C48.9924 247.851 49.0892 248.009 49.283 248.124C49.4767 248.238 49.7674 248.353 50.1637 248.467C50.5335 248.573 50.8418 248.687 51.0796 248.793C51.3174 248.899 51.5199 249.057 51.6872 249.269C51.8546 249.48 51.9338 249.753 51.9338 250.096C51.9338 250.607 51.7401 250.986 51.3614 251.25C50.9827 251.514 50.5071 251.647 49.9347 251.647C49.5384 251.647 49.1509 251.585 48.7722 251.453C48.3935 251.321 48.0764 251.145 47.821 250.907L48.1733 250.22C48.3935 250.422 48.6753 250.59 49.0012 250.704C49.327 250.827 49.6529 250.889 49.9611 250.889C50.2693 250.889 50.5247 250.827 50.7097 250.713C50.9034 250.598 50.9915 250.422 50.9915 250.185C50.9915 249.938 50.8946 249.762 50.6921 249.639C50.4895 249.515 50.1901 249.392 49.785 249.277C49.4239 249.172 49.1333 249.075 48.9043 248.969C48.6753 248.863 48.4816 248.714 48.323 248.511C48.1645 248.309 48.0852 248.044 48.0852 247.719C48.0852 247.217 48.2702 246.847 48.6313 246.591C48.9924 246.345 49.4503 246.213 50.0051 246.213C50.331 246.213 50.648 246.257 50.9651 246.353C51.2821 246.442 51.5551 246.565 51.7841 246.723L51.4406 247.446C51.2117 247.296 50.9651 247.19 50.7009 247.111Z"
            fill="#43556B"
          />
          <Path d="M56.0554 251.259C55.6415 251.523 55.2276 251.655 54.7961 251.655C54.3733 251.655 54.0211 251.532 53.7568 251.276C53.4926 251.021 53.3605 250.642 53.3605 250.123V247.146H52.6031V246.424H53.3605V244.979H54.3117V246.424H55.9586V247.146H54.3117V249.947C54.3117 250.246 54.3645 250.457 54.4702 250.59C54.5759 250.722 54.7432 250.783 54.9546 250.783C55.21 250.783 55.4918 250.695 55.7824 250.528L56.0554 251.259Z" fill="#43556B" />
          <Path
            d="M60.4765 251.611V250.863C60.0802 251.391 59.4549 251.655 58.6006 251.655C58.2307 251.655 57.9049 251.585 57.6231 251.444C57.3412 251.303 57.1299 251.109 56.9714 250.854C56.8216 250.607 56.7424 250.325 56.7424 250.008C56.7424 249.524 56.9185 249.145 57.2708 248.863C57.6231 248.582 58.125 248.441 58.7591 248.432H60.4677V248.176C60.4677 247.798 60.3532 247.507 60.1154 247.296C59.8776 247.093 59.5429 246.987 59.0938 246.987C58.5566 246.987 58.0017 247.181 57.4381 247.569L57.0418 246.917C57.4293 246.67 57.7816 246.485 58.1162 246.371C58.4421 246.256 58.8384 246.204 59.2875 246.204C59.9569 246.204 60.4765 246.371 60.8375 246.697C61.1986 247.023 61.3924 247.481 61.3924 248.071L61.4012 251.602H60.4765V251.611ZM59.9128 250.554C60.2211 250.343 60.406 250.061 60.4677 249.709V249.172H58.8912C58.4685 249.172 58.1515 249.233 57.9401 249.365C57.7375 249.497 57.6319 249.7 57.6319 249.973C57.6319 250.246 57.7375 250.466 57.9489 250.634C58.1603 250.792 58.4421 250.88 58.7944 250.88C59.2347 250.871 59.6046 250.766 59.9128 250.554Z"
            fill="#43556B"
          />
          <Path d="M66.8527 251.611L65.1354 249.004L64.0874 250.088V251.611H63.145V244.081H64.0874V248.881L66.6854 246.239H67.795L65.7959 248.317L67.9888 251.602H66.8527V251.611Z" fill="#43556B" />
          <Path
            d="M72.0046 251.611V250.863C71.6083 251.391 70.983 251.655 70.1287 251.655C69.7589 251.655 69.433 251.585 69.1512 251.444C68.8694 251.303 68.658 251.109 68.4995 250.854C68.3498 250.607 68.2705 250.325 68.2705 250.008C68.2705 249.524 68.4466 249.145 68.7989 248.863C69.1512 248.582 69.6532 248.441 70.2873 248.432H71.9958V248.176C71.9958 247.798 71.8813 247.507 71.6435 247.296C71.4057 247.093 71.0711 246.987 70.6219 246.987C70.0847 246.987 69.5299 247.181 68.9662 247.569L68.5699 246.917C68.9574 246.67 69.3097 246.485 69.6444 246.371C69.9702 246.256 70.3665 246.204 70.8157 246.204C71.485 246.204 72.0046 246.371 72.3657 246.697C72.7268 247.023 72.9205 247.481 72.9205 248.071L72.9293 251.602H72.0046V251.611ZM71.441 250.554C71.7492 250.343 71.9341 250.061 71.9958 249.709V249.172H70.4194C69.9966 249.172 69.6796 249.233 69.4682 249.365C69.2657 249.497 69.16 249.7 69.16 249.973C69.16 250.246 69.2657 250.466 69.477 250.634C69.6884 250.792 69.9702 250.88 70.3225 250.88C70.7628 250.871 71.1327 250.766 71.441 250.554Z"
            fill="#43556B"
          />
          <Path
            d="M77.8964 251.611V250.863C77.5001 251.391 76.8748 251.655 76.0205 251.655C75.6506 251.655 75.3248 251.585 75.043 251.444C74.7612 251.303 74.5498 251.109 74.3913 250.854C74.2328 250.598 74.1623 250.325 74.1623 250.008C74.1623 249.524 74.3384 249.145 74.6907 248.863C75.043 248.582 75.545 248.441 76.1791 248.432H77.8876V248.176C77.8876 247.798 77.7731 247.507 77.5353 247.296C77.2975 247.093 76.9629 246.987 76.5137 246.987C75.9765 246.987 75.4217 247.181 74.858 247.569L74.4617 246.917C74.8492 246.67 75.2015 246.485 75.5362 246.371C75.862 246.256 76.2583 246.204 76.7075 246.204C77.3768 246.204 77.8964 246.371 78.2575 246.697C78.6185 247.023 78.8123 247.481 78.8123 248.071L78.8211 251.602H77.8964V251.611ZM77.3416 250.554C77.6498 250.343 77.8347 250.061 77.8964 249.709V249.172H76.32C75.8972 249.172 75.5802 249.233 75.3688 249.365C75.1663 249.497 75.0606 249.7 75.0606 249.973C75.0606 250.246 75.1663 250.466 75.3776 250.634C75.589 250.792 75.8708 250.88 76.2231 250.88C76.6546 250.871 77.0333 250.766 77.3416 250.554Z"
            fill="#43556B"
          />
          <Path d="M84.9859 246.767C85.3469 247.137 85.5231 247.648 85.5231 248.291V251.611H84.5807V248.546C84.5807 248.097 84.4574 247.736 84.202 247.489C83.9466 247.234 83.5944 247.111 83.154 247.111C82.6697 247.12 82.2822 247.269 81.9827 247.569C81.6833 247.868 81.5072 248.264 81.4719 248.74V251.611H80.5296V246.239H81.4631V247.375C81.8242 246.609 82.5023 246.221 83.5063 246.204C84.1316 246.212 84.6248 246.397 84.9859 246.767Z" fill="#43556B" />
          <Path d="M89.9529 244.513H91.1066L93.7046 249.506L96.285 244.513H97.4563V251.62H96.5404L96.5316 245.948L94.004 250.871H93.4052L90.86 245.948V251.62H89.9529V244.513Z" fill="#43556B" />
          <Path
            d="M103.436 246.952C103.859 247.446 104.079 248.124 104.079 248.996C104.079 249.128 104.079 249.233 104.07 249.304H99.8254C99.8959 249.788 100.098 250.167 100.433 250.449C100.759 250.731 101.164 250.871 101.648 250.871C101.965 250.871 102.265 250.81 102.538 250.695C102.811 250.581 103.049 250.422 103.251 250.211L103.771 250.748C103.507 251.03 103.19 251.25 102.82 251.409C102.45 251.567 102.036 251.647 101.578 251.647C101.05 251.647 100.583 251.532 100.169 251.303C99.7638 251.074 99.4379 250.757 99.2177 250.343C98.9888 249.929 98.8743 249.462 98.8743 248.934C98.8743 248.406 98.9888 247.939 99.2177 247.525C99.4467 247.12 99.7638 246.794 100.169 246.565C100.574 246.327 101.041 246.213 101.56 246.213C102.379 246.213 103.005 246.459 103.436 246.952ZM103.225 248.573C103.198 248.088 103.031 247.701 102.74 247.419C102.441 247.137 102.054 246.996 101.56 246.996C101.085 246.996 100.688 247.137 100.38 247.419C100.063 247.701 99.8783 248.088 99.8166 248.564H103.225V248.573Z"
            fill="#43556B"
          />
          <Path d="M109.83 246.767C110.191 247.137 110.367 247.648 110.367 248.291V251.611H109.425V248.546C109.425 248.097 109.301 247.736 109.046 247.489C108.791 247.234 108.438 247.111 107.998 247.111C107.514 247.12 107.126 247.269 106.827 247.569C106.527 247.868 106.351 248.264 106.316 248.74V251.611H105.374V246.239H106.316V247.375C106.677 246.609 107.355 246.221 108.359 246.204C108.976 246.212 109.469 246.397 109.83 246.767Z" fill="#43556B" />
          <Path d="M111.239 252.809C111.494 252.818 111.688 252.73 111.829 252.545C111.961 252.36 112.032 252.096 112.032 251.743V246.239H112.983V251.875C112.983 252.43 112.851 252.862 112.578 253.161C112.305 253.461 111.935 253.61 111.45 253.61C111.142 253.61 110.834 253.549 110.526 253.425L110.746 252.703C110.896 252.765 111.063 252.8 111.239 252.809ZM112.921 244.275C113.036 244.389 113.088 244.539 113.088 244.715C113.088 244.891 113.036 245.041 112.921 245.155C112.807 245.27 112.666 245.332 112.498 245.332C112.331 245.332 112.19 245.27 112.076 245.155C111.961 245.041 111.908 244.891 111.908 244.715C111.908 244.539 111.961 244.398 112.076 244.275C112.19 244.16 112.331 244.099 112.498 244.099C112.666 244.107 112.807 244.16 112.921 244.275Z" fill="#43556B" />
          <Path
            d="M117.994 251.611V250.863C117.598 251.391 116.972 251.655 116.118 251.655C115.748 251.655 115.422 251.585 115.141 251.444C114.859 251.303 114.647 251.109 114.489 250.854C114.339 250.607 114.26 250.325 114.26 250.008C114.26 249.524 114.436 249.145 114.788 248.863C115.141 248.582 115.643 248.441 116.277 248.432H117.985V248.176C117.985 247.798 117.871 247.507 117.633 247.296C117.395 247.093 117.06 246.987 116.611 246.987C116.074 246.987 115.519 247.181 114.956 247.569L114.559 246.917C114.947 246.67 115.299 246.485 115.634 246.371C115.96 246.256 116.356 246.204 116.805 246.204C117.474 246.204 117.994 246.371 118.355 246.697C118.716 247.023 118.91 247.481 118.91 248.071L118.919 251.602H117.994V251.611ZM117.43 250.554C117.739 250.343 117.923 250.061 117.985 249.709V249.172H116.409C115.986 249.172 115.669 249.233 115.458 249.365C115.255 249.497 115.149 249.7 115.149 249.973C115.149 250.246 115.255 250.466 115.466 250.634C115.678 250.792 115.96 250.88 116.312 250.88C116.752 250.871 117.122 250.766 117.43 250.554Z"
            fill="#43556B"
          />
          <Path d="M125.083 246.767C125.444 247.137 125.621 247.648 125.621 248.291V251.611H124.678V248.546C124.678 248.097 124.555 247.736 124.3 247.489C124.044 247.234 123.692 247.111 123.252 247.111C122.767 247.12 122.38 247.269 122.08 247.569C121.781 247.868 121.605 248.264 121.569 248.74V251.611H120.627V246.239H121.569V247.375C121.931 246.609 122.609 246.221 123.613 246.204C124.229 246.212 124.722 246.397 125.083 246.767Z" fill="#43556B" />
          <Path
            d="M132.05 246.248V251.065C132.05 251.576 131.935 252.025 131.706 252.413C131.477 252.8 131.16 253.091 130.746 253.302C130.332 253.505 129.857 253.61 129.302 253.61C128.509 253.61 127.769 253.364 127.091 252.862L127.505 252.175C127.769 252.395 128.042 252.554 128.324 252.659C128.606 252.765 128.923 252.818 129.258 252.818C129.813 252.818 130.253 252.659 130.588 252.351C130.922 252.043 131.081 251.62 131.081 251.1V250.352C130.887 250.678 130.632 250.924 130.306 251.109C129.98 251.285 129.61 251.382 129.187 251.382C128.703 251.382 128.28 251.277 127.91 251.056C127.54 250.836 127.259 250.528 127.056 250.14C126.854 249.744 126.748 249.295 126.748 248.793C126.748 248.291 126.854 247.851 127.056 247.463C127.259 247.076 127.54 246.776 127.91 246.556C128.28 246.336 128.703 246.23 129.187 246.23C129.61 246.23 129.989 246.318 130.306 246.494C130.623 246.67 130.878 246.917 131.072 247.243V246.257H132.05V246.248ZM130.279 250.378C130.535 250.22 130.737 250.008 130.887 249.735C131.037 249.462 131.107 249.154 131.107 248.811C131.107 248.458 131.037 248.15 130.887 247.868C130.737 247.595 130.544 247.375 130.279 247.225C130.024 247.067 129.725 246.988 129.399 246.988C129.073 246.988 128.773 247.067 128.509 247.225C128.245 247.384 128.042 247.595 127.893 247.868C127.743 248.141 127.673 248.458 127.673 248.811C127.681 249.339 127.84 249.771 128.157 250.114C128.474 250.449 128.888 250.625 129.399 250.625C129.725 250.607 130.015 250.537 130.279 250.378Z"
            fill="#43556B"
          />
          <Path d="M137.536 251.611L135.819 249.004L134.771 250.088V251.611H133.829V244.081H134.771V248.881L137.369 246.239H138.479L136.479 248.317L138.672 251.602H137.536V251.611Z" fill="#43556B" />
          <Path
            d="M142.688 251.611V250.863C142.292 251.391 141.667 251.655 140.812 251.655C140.442 251.655 140.117 251.585 139.835 251.444C139.553 251.303 139.342 251.109 139.183 250.854C139.033 250.607 138.954 250.325 138.954 250.008C138.954 249.524 139.13 249.145 139.483 248.863C139.835 248.582 140.337 248.441 140.971 248.432H142.679V248.176C142.679 247.798 142.565 247.507 142.327 247.296C142.089 247.093 141.755 246.987 141.306 246.987C140.768 246.987 140.213 247.181 139.65 247.569L139.254 246.917C139.641 246.67 139.993 246.485 140.328 246.371C140.654 246.256 141.05 246.204 141.499 246.204C142.169 246.204 142.688 246.371 143.049 246.697C143.41 247.023 143.604 247.481 143.604 248.071L143.613 251.602H142.688V251.611ZM142.125 250.554C142.433 250.343 142.618 250.061 142.679 249.709V249.172H141.103C140.68 249.172 140.363 249.233 140.152 249.365C139.949 249.497 139.844 249.7 139.844 249.973C139.844 250.246 139.949 250.466 140.161 250.634C140.372 250.792 140.654 250.88 141.006 250.88C141.446 250.871 141.816 250.766 142.125 250.554Z"
            fill="#43556B"
          />
          <Path d="M150.06 246.248V251.62H149.1V250.484C148.747 251.25 148.087 251.638 147.109 251.655C146.484 251.655 146 251.47 145.647 251.1C145.295 250.731 145.119 250.22 145.119 249.577V246.257H146.07V249.321C146.07 249.771 146.193 250.132 146.44 250.378C146.687 250.634 147.03 250.757 147.462 250.757C147.964 250.748 148.36 250.572 148.65 250.22C148.941 249.867 149.091 249.427 149.091 248.881V246.239H150.06V246.248Z" fill="#43556B" />
          <Path d="M154.639 244.513H155.793L158.391 249.506L160.971 244.513H162.143V251.62H161.227L161.218 245.948L158.69 250.871H158.091L155.546 245.948V251.62H154.639V244.513Z" fill="#43556B" />
          <Path
            d="M167.356 251.611V250.863C166.96 251.391 166.335 251.655 165.48 251.655C165.11 251.655 164.785 251.585 164.503 251.444C164.221 251.303 164.01 251.109 163.851 250.854C163.701 250.607 163.622 250.325 163.622 250.008C163.622 249.524 163.798 249.145 164.15 248.863C164.503 248.582 165.005 248.441 165.639 248.432H167.347V248.176C167.347 247.798 167.233 247.507 166.995 247.296C166.757 247.093 166.423 246.987 165.973 246.987C165.436 246.987 164.881 247.181 164.318 247.569L163.922 246.917C164.309 246.67 164.661 246.485 164.996 246.371C165.322 246.256 165.718 246.204 166.167 246.204C166.837 246.204 167.356 246.371 167.717 246.697C168.078 247.023 168.272 247.481 168.272 248.071L168.281 251.602H167.356V251.611ZM166.793 250.554C167.101 250.343 167.286 250.061 167.347 249.709V249.172H165.771C165.348 249.172 165.031 249.233 164.82 249.365C164.617 249.497 164.512 249.7 164.512 249.973C164.512 250.246 164.617 250.466 164.829 250.634C165.04 250.792 165.322 250.88 165.674 250.88C166.114 250.871 166.484 250.766 166.793 250.554Z"
            fill="#43556B"
          />
          <Path
            d="M172.174 247.111C171.909 247.032 171.654 246.988 171.407 246.988C171.126 246.988 170.897 247.04 170.72 247.146C170.544 247.252 170.465 247.41 170.465 247.63C170.465 247.851 170.562 248.009 170.756 248.124C170.949 248.238 171.24 248.353 171.636 248.467C172.006 248.573 172.314 248.687 172.552 248.793C172.79 248.899 172.993 249.057 173.16 249.269C173.327 249.48 173.406 249.753 173.406 250.096C173.406 250.607 173.213 250.986 172.834 251.25C172.455 251.514 171.98 251.647 171.407 251.647C171.011 251.647 170.624 251.585 170.245 251.453C169.866 251.321 169.549 251.145 169.294 250.907L169.646 250.22C169.866 250.422 170.148 250.59 170.474 250.704C170.8 250.827 171.126 250.889 171.434 250.889C171.742 250.889 171.997 250.827 172.182 250.713C172.376 250.598 172.464 250.422 172.464 250.185C172.464 249.938 172.367 249.762 172.165 249.639C171.962 249.515 171.663 249.392 171.258 249.277C170.897 249.172 170.606 249.075 170.377 248.969C170.148 248.863 169.954 248.714 169.796 248.511C169.637 248.309 169.558 248.044 169.558 247.719C169.558 247.217 169.743 246.847 170.104 246.591C170.465 246.345 170.923 246.213 171.478 246.213C171.804 246.213 172.121 246.257 172.438 246.353C172.755 246.45 173.028 246.565 173.257 246.723L172.913 247.446C172.684 247.296 172.438 247.19 172.174 247.111Z"
            fill="#43556B"
          />
          <Path d="M175.811 253.311C175.529 253.522 175.203 253.628 174.824 253.628C174.595 253.628 174.384 253.593 174.199 253.522C174.005 253.452 173.829 253.346 173.653 253.205L174.058 252.466C174.296 252.633 174.534 252.721 174.78 252.721C175.185 252.721 175.494 252.492 175.696 252.034L175.899 251.558L173.679 246.248H174.675L176.383 250.554L177.995 246.248H178.955L176.498 252.377C176.313 252.791 176.084 253.1 175.811 253.311Z" fill="#43556B" />
          <Path
            d="M182.953 251.611V250.863C182.557 251.391 181.932 251.655 181.077 251.655C180.707 251.655 180.381 251.585 180.1 251.444C179.818 251.303 179.606 251.109 179.448 250.854C179.298 250.607 179.219 250.325 179.219 250.008C179.219 249.524 179.395 249.145 179.747 248.863C180.1 248.582 180.602 248.441 181.236 248.432H182.944V248.176C182.944 247.798 182.83 247.507 182.592 247.296C182.354 247.093 182.02 246.987 181.57 246.987C181.033 246.987 180.478 247.181 179.915 247.569L179.518 246.917C179.906 246.67 180.258 246.485 180.593 246.371C180.919 246.256 181.315 246.204 181.764 246.204C182.433 246.204 182.953 246.371 183.314 246.697C183.675 247.023 183.869 247.481 183.869 248.071L183.878 251.602H182.953V251.611ZM182.389 250.554C182.698 250.343 182.883 250.061 182.944 249.709V249.172H181.368C180.945 249.172 180.628 249.233 180.417 249.365C180.214 249.497 180.108 249.7 180.108 249.973C180.108 250.246 180.214 250.466 180.426 250.634C180.637 250.792 180.919 250.88 181.271 250.88C181.711 250.871 182.081 250.766 182.389 250.554Z"
            fill="#43556B"
          />
          <Path d="M187.268 246.529C187.594 246.327 187.982 246.23 188.422 246.221V247.137C187.876 247.12 187.427 247.26 187.092 247.56C186.758 247.868 186.564 248.273 186.511 248.784V251.611H185.569V246.239H186.511V247.393C186.696 247.014 186.943 246.723 187.268 246.529Z" fill="#43556B" />
          <Path
            d="M192.5 251.611V250.863C192.103 251.391 191.478 251.655 190.624 251.655C190.254 251.655 189.928 251.585 189.646 251.444C189.364 251.303 189.153 251.109 188.995 250.854C188.836 250.598 188.766 250.325 188.766 250.008C188.766 249.524 188.942 249.145 189.294 248.863C189.646 248.582 190.148 248.441 190.782 248.432H192.491V248.176C192.491 247.798 192.376 247.507 192.139 247.296C191.901 247.093 191.566 246.987 191.117 246.987C190.58 246.987 190.025 247.181 189.461 247.569L189.065 246.917C189.453 246.67 189.805 246.485 190.139 246.371C190.465 246.256 190.862 246.204 191.311 246.204C191.98 246.204 192.5 246.371 192.861 246.697C193.222 247.023 193.416 247.481 193.416 248.071L193.424 251.602H192.5V251.611ZM191.936 250.554C192.244 250.343 192.429 250.061 192.491 249.709V249.172H190.914C190.492 249.172 190.175 249.233 189.963 249.365C189.761 249.497 189.655 249.7 189.655 249.973C189.655 250.246 189.761 250.466 189.972 250.634C190.183 250.792 190.465 250.88 190.818 250.88C191.249 250.871 191.628 250.766 191.936 250.554Z"
            fill="#43556B"
          />
          <Path d="M198.867 251.611L197.15 249.004L196.102 250.088V251.611H195.159V244.081H196.102V248.881L198.7 246.239H199.809L197.81 248.317L200.003 251.602H198.867V251.611Z" fill="#43556B" />
          <Path
            d="M204.028 251.611V250.863C203.631 251.391 203.006 251.655 202.152 251.655C201.782 251.655 201.456 251.585 201.174 251.444C200.893 251.303 200.681 251.109 200.523 250.854C200.364 250.598 200.294 250.325 200.294 250.008C200.294 249.524 200.47 249.145 200.822 248.863C201.174 248.582 201.676 248.441 202.31 248.432H204.019V248.176C204.019 247.798 203.905 247.507 203.667 247.296C203.429 247.093 203.094 246.987 202.645 246.987C202.108 246.987 201.553 247.181 200.989 247.569L200.593 246.917C200.981 246.67 201.333 246.485 201.668 246.371C201.993 246.256 202.39 246.204 202.839 246.204C203.508 246.204 204.028 246.371 204.389 246.697C204.75 247.023 204.944 247.481 204.944 248.071L204.953 251.602H204.028V251.611ZM203.464 250.554C203.772 250.343 203.957 250.061 204.019 249.709V249.172H202.443C202.02 249.172 201.703 249.233 201.491 249.365C201.289 249.497 201.183 249.7 201.183 249.973C201.183 250.246 201.289 250.466 201.5 250.634C201.712 250.792 201.993 250.88 202.346 250.88C202.777 250.871 203.156 250.766 203.464 250.554Z"
            fill="#43556B"
          />
          <Path d="M209.523 251.259C209.109 251.523 208.695 251.655 208.264 251.655C207.841 251.655 207.489 251.532 207.225 251.276C206.96 251.021 206.828 250.642 206.828 250.123V247.146H206.071V246.424H206.828V244.979H207.78V246.424H209.426V247.146H207.78V249.947C207.78 250.246 207.832 250.457 207.938 250.59C208.044 250.722 208.211 250.783 208.422 250.783C208.678 250.783 208.96 250.695 209.25 250.528L209.523 251.259Z" fill="#43556B" />
          <Path d="M18.4327 215C18.5913 215.247 18.741 215.493 18.8995 215.731C19.1813 216.18 19.4807 216.629 19.7185 217.105C19.8682 217.422 19.9915 217.748 20.0268 218.1C20.0532 218.338 20.0444 218.576 20.0092 218.805L20.0356 218.796C19.9739 218.84 19.9387 218.902 19.8947 218.963C19.4455 219.58 18.9876 220.196 18.5384 220.813C18.5032 220.637 18.4592 220.469 18.3799 220.311C18.2478 220.038 18.0628 219.791 17.8779 219.562C17.6577 219.298 17.4376 219.034 17.2262 218.761C17.6225 217.51 18.0276 216.251 18.4327 215Z" fill="#169B48" />
          <Path d="M20.0356 218.796C20.4143 218.629 20.8371 218.558 21.251 218.567C21.7706 218.576 22.2726 218.69 22.7746 218.796C23.2237 218.902 23.6817 218.99 24.1396 219.078C23.0476 219.87 21.9643 220.654 20.8723 221.438C20.564 221.332 20.2558 221.2 19.9476 221.086C19.4984 220.91 19.014 220.76 18.5297 220.813L18.5385 220.804C18.9964 220.187 19.4456 219.571 19.8947 218.954C19.9388 218.91 19.974 218.84 20.0356 218.796Z" fill="#11743B" />
          <Path d="M12.7259 218.972C13.7034 218.972 14.6722 218.972 15.6498 218.972C16.0549 218.972 16.46 218.981 16.8651 218.972C17.0148 219.157 17.1645 219.342 17.3142 219.527C17.4904 219.738 17.6753 219.958 17.825 220.187C17.966 220.399 18.0804 220.628 18.1245 220.874C18.1509 221.015 18.1421 221.165 18.1509 221.315C18.1509 221.35 18.1333 221.368 18.1157 221.394C17.508 222.222 16.9003 223.05 16.3015 223.878C15.8699 224.468 15.4384 225.058 15.0157 225.648C14.9981 225.674 14.9804 225.701 14.954 225.727C14.9628 225.674 14.9804 225.63 14.9981 225.586C15.4384 224.248 15.8787 222.909 16.3103 221.57C15.3767 220.901 14.4432 220.223 13.5185 219.553C13.2543 219.351 12.9901 219.166 12.7259 218.972Z" fill="#215FAC" />
          <Path d="M18.1598 221.315C18.3359 221.271 18.5032 221.227 18.6794 221.218C18.9876 221.2 19.2958 221.288 19.5953 221.385C20.0004 221.526 20.3967 221.711 20.8106 221.843C21.2333 223.147 21.6649 224.45 22.0876 225.754C21.8674 225.595 21.6473 225.437 21.4271 225.278C20.4583 224.565 19.4808 223.86 18.512 223.156C17.3231 224.027 16.1254 224.891 14.9365 225.762C14.9365 225.745 14.9453 225.727 14.9453 225.71L14.9541 225.718C14.9805 225.701 14.9981 225.666 15.0157 225.639C15.4473 225.049 15.8788 224.459 16.3015 223.869C16.9092 223.041 17.5081 222.213 18.1157 221.385C18.1421 221.368 18.1598 221.35 18.1598 221.315Z" fill="#184999" />
          <Path d="M11.8452 223.094C12.8932 223.834 13.4128 224.829 13.4128 226.097C13.4128 226.969 13.1574 227.735 12.6466 228.387C12.1358 229.039 11.4489 229.479 10.5682 229.717C11.6338 229.919 12.4705 230.395 13.0781 231.135C13.6858 231.875 13.9941 232.791 13.9941 233.865C13.9941 235.274 13.4392 236.384 12.3296 237.194C11.2199 238.004 9.71393 238.418 7.80285 238.418H0V221.993H7.55626C9.37047 221.993 10.7972 222.363 11.8452 223.094ZM9.01819 228.123C9.4145 227.806 9.61706 227.357 9.61706 226.775C9.61706 226.212 9.4145 225.771 9.01819 225.463C8.62189 225.146 8.06706 225.005 7.36251 225.014H3.70768V228.607H7.37132C8.06706 228.607 8.62189 228.449 9.01819 228.123ZM9.43211 234.86C9.9341 234.508 10.1807 234.024 10.1807 233.389C10.1807 232.799 9.9341 232.324 9.43211 231.98C8.93013 231.637 8.24319 231.47 7.37132 231.487H3.70768V235.38H7.37132C8.24319 235.389 8.93013 235.213 9.43211 234.86Z" fill="#184999" />
          <Path d="M16.6361 225.789H20.2469V238.409H16.6361V225.789Z" fill="#184999" />
          <Path d="M35.06 226.969C35.8966 227.841 36.3194 229.021 36.3194 230.492V238.418H32.7086V231.54C32.7086 230.738 32.4884 230.113 32.0392 229.655C31.5901 229.197 30.9912 228.959 30.225 228.959C29.3355 228.977 28.631 229.285 28.1114 229.884C27.5918 230.483 27.3364 231.258 27.3364 232.192V238.409H23.7256V225.789H27.3364V227.991C28.2083 226.458 29.6702 225.674 31.7046 225.648C33.1049 225.648 34.2233 226.088 35.06 226.969Z" fill="#184999" />
          <Path d="M47.2486 237.714C46.7819 237.995 46.2623 238.207 45.6898 238.357C45.1174 238.506 44.5449 238.577 43.9637 238.577C42.7924 238.577 41.8324 238.242 41.1015 237.564C40.3705 236.895 40.0006 235.926 40.0006 234.675V228.836H38.2393V226.352H40.0006V222.882H43.5938V226.352H47.0197V228.862H43.5938V234.068C43.5938 235.036 43.9725 235.521 44.7475 235.521C45.2759 235.521 45.866 235.362 46.5089 235.054L47.2486 237.714Z" fill="#184999" />
          <Path
            d="M56.7512 238.418V236.939C56.3285 237.467 55.7824 237.881 55.1131 238.163C54.4438 238.445 53.6688 238.586 52.7881 238.586C51.4583 238.586 50.4015 238.225 49.6177 237.494C48.8339 236.763 48.4464 235.82 48.4464 234.64C48.4464 233.451 48.8691 232.526 49.7057 231.857C50.5424 231.196 51.7313 230.853 53.2637 230.835H56.7336V230.598C56.7336 229.928 56.5134 229.409 56.0643 229.039C55.6151 228.669 54.9634 228.484 54.1091 228.484C52.9378 228.484 51.6873 228.854 50.3574 229.585L49.2302 227.101C50.2341 226.599 51.1677 226.229 52.0483 226C52.9202 225.762 53.889 225.648 54.937 225.648C56.6279 225.648 57.9401 226.044 58.8648 226.846C59.7984 227.647 60.2651 228.757 60.2827 230.175L60.3092 238.409H56.7512V238.418ZM55.7472 235.459C56.2932 235.098 56.6191 234.649 56.7336 234.094V232.967H53.8978C53.1932 232.967 52.6736 233.081 52.339 233.31C52.0043 233.539 51.837 233.9 51.837 234.402C51.837 234.887 52.0131 235.274 52.3654 235.565C52.7177 235.855 53.202 235.996 53.8097 235.996C54.5495 235.996 55.1924 235.82 55.7472 235.459Z"
            fill="#184999"
          />
          <Path d="M74.7611 226.969C75.5978 227.841 76.0205 229.021 76.0205 230.492V238.418H72.4097V231.54C72.4097 230.738 72.1895 230.113 71.7404 229.655C71.2913 229.197 70.6924 228.959 69.9262 228.959C69.0367 228.977 68.3322 229.285 67.8126 229.884C67.293 230.483 67.0376 231.258 67.0376 232.192V238.409H63.4268V225.789H67.0376V227.991C67.9094 226.458 69.3714 225.674 71.4057 225.648C72.7972 225.648 73.9245 226.088 74.7611 226.969Z" fill="#184999" />
          <Path
            d="M90.9569 225.789V237.238C90.9569 238.427 90.6751 239.466 90.1115 240.347C89.5478 241.228 88.7552 241.915 87.7424 242.39C86.7296 242.866 85.5495 243.104 84.2197 243.104C83.1893 243.104 82.2029 242.954 81.2782 242.655C80.3447 242.355 79.4992 241.95 78.733 241.43L80.0717 238.894C81.2606 239.739 82.564 240.162 83.9907 240.162C85.0211 240.162 85.8402 239.907 86.4566 239.396C87.0643 238.885 87.3725 238.207 87.3725 237.344V235.908C86.9498 236.507 86.4214 236.956 85.7785 237.273C85.1356 237.591 84.4046 237.74 83.5768 237.74C82.4936 237.74 81.5424 237.485 80.7058 236.974C79.8691 236.463 79.2174 235.75 78.7595 234.825C78.3015 233.9 78.0637 232.835 78.0637 231.637C78.0637 230.448 78.2927 229.409 78.7595 228.502C79.2174 227.603 79.8691 226.899 80.7058 226.405C81.5424 225.903 82.5024 225.657 83.5768 225.657C84.387 225.657 85.118 225.815 85.7609 226.141C86.4038 226.458 86.941 226.907 87.3813 227.489V225.78H90.9569V225.789ZM86.5447 234.112C87.0907 233.513 87.3637 232.72 87.3637 231.751C87.3637 230.783 87.0907 229.99 86.5447 229.382C85.9987 228.775 85.2853 228.466 84.4134 228.466C83.5416 228.466 82.8282 228.775 82.2822 229.382C81.7362 229.99 81.4543 230.783 81.4367 231.751C81.4543 232.72 81.7274 233.504 82.2734 234.112C82.8106 234.711 83.5328 235.019 84.4222 235.019C85.2853 235.01 85.9987 234.711 86.5447 234.112Z"
            fill="#184999"
          />
          <Path d="M106.166 223.481C107.311 224.477 107.875 225.877 107.875 227.691C107.875 229.602 107.302 231.082 106.149 232.139C104.995 233.196 103.383 233.724 101.305 233.724H98.0464V238.418H94.3387V221.993H101.305C103.401 221.993 105.021 222.486 106.166 223.481ZM103.524 229.911C104.079 229.435 104.361 228.731 104.361 227.815C104.361 226.907 104.079 226.22 103.524 225.762C102.969 225.305 102.168 225.067 101.12 225.067H98.0464V230.624H101.12C102.168 230.624 102.969 230.386 103.524 229.911Z" fill="#184999" />
          <Path d="M122.159 225.789V238.409H118.566V236.181C117.703 237.758 116.294 238.55 114.339 238.55C112.948 238.55 111.838 238.119 111.019 237.247C110.2 236.375 109.786 235.204 109.786 233.715V225.789H113.397V232.685C113.397 233.486 113.608 234.112 114.031 234.561C114.454 235.019 115.035 235.239 115.766 235.239C116.629 235.221 117.307 234.922 117.809 234.323C118.311 233.733 118.557 232.958 118.557 232.024V225.78H122.159V225.789Z" fill="#184999" />
          <Path
            d="M131.574 228.598C130.905 228.387 130.297 228.281 129.769 228.281C129.328 228.281 128.976 228.361 128.712 228.519C128.448 228.678 128.316 228.907 128.316 229.224C128.316 229.567 128.492 229.831 128.853 230.025C129.214 230.21 129.795 230.422 130.614 230.659C131.504 230.924 132.243 231.188 132.816 231.443C133.397 231.699 133.899 232.086 134.313 232.597C134.736 233.108 134.947 233.777 134.947 234.605C134.947 235.856 134.463 236.833 133.503 237.529C132.543 238.225 131.31 238.577 129.804 238.577C128.773 238.577 127.778 238.427 126.827 238.119C125.876 237.811 125.048 237.379 124.344 236.816L125.515 234.376C126.158 234.878 126.871 235.265 127.664 235.538C128.456 235.811 129.196 235.952 129.901 235.952C130.385 235.952 130.773 235.873 131.063 235.706C131.354 235.538 131.495 235.292 131.495 234.966C131.495 234.605 131.31 234.323 130.94 234.121C130.57 233.918 129.98 233.698 129.17 233.46C128.307 233.213 127.593 232.958 127.021 232.711C126.448 232.465 125.964 232.086 125.559 231.575C125.154 231.064 124.951 230.404 124.951 229.594C124.951 228.343 125.418 227.374 126.36 226.687C127.303 226 128.492 225.657 129.927 225.657C130.79 225.657 131.644 225.78 132.508 226.018C133.371 226.265 134.146 226.608 134.85 227.066L133.608 229.532C132.921 229.109 132.243 228.81 131.574 228.598Z"
            fill="#184999"
          />
          <Path d="M148.712 226.969C149.549 227.841 149.972 229.021 149.972 230.492V238.418H146.361V231.54C146.361 230.738 146.141 230.113 145.691 229.655C145.242 229.197 144.643 228.959 143.877 228.959C142.988 228.977 142.283 229.285 141.764 229.884C141.244 230.483 140.989 231.258 140.989 232.192V238.409H137.378V225.789H140.989V227.991C141.86 226.458 143.322 225.674 145.357 225.648C146.748 225.648 147.875 226.088 148.712 226.969Z" fill="#184999" />
          <Path
            d="M160.408 238.418V236.939C159.985 237.467 159.439 237.881 158.77 238.163C158.1 238.445 157.325 238.586 156.445 238.586C155.115 238.586 154.058 238.225 153.274 237.494C152.49 236.763 152.103 235.82 152.103 234.64C152.103 233.451 152.526 232.526 153.362 231.857C154.199 231.196 155.388 230.853 156.92 230.835H160.39V230.598C160.39 229.928 160.17 229.409 159.721 229.039C159.272 228.669 158.62 228.484 157.766 228.484C156.594 228.484 155.344 228.854 154.014 229.585L152.887 227.101C153.891 226.599 154.824 226.229 155.705 226C156.577 225.762 157.545 225.648 158.593 225.648C160.284 225.648 161.597 226.044 162.521 226.846C163.455 227.647 163.922 228.757 163.939 230.175L163.966 238.409H160.408V238.418ZM159.395 235.459C159.941 235.098 160.267 234.649 160.381 234.094V232.967H157.545C156.841 232.967 156.321 233.081 155.987 233.31C155.652 233.539 155.485 233.9 155.485 234.402C155.485 234.887 155.661 235.274 156.013 235.565C156.365 235.855 156.85 235.996 157.457 235.996C158.197 235.996 158.849 235.82 159.395 235.459Z"
            fill="#184999"
          />
          <Path
            d="M172.984 228.598C172.314 228.387 171.707 228.281 171.178 228.281C170.738 228.281 170.386 228.361 170.122 228.519C169.857 228.678 169.725 228.907 169.725 229.224C169.725 229.567 169.901 229.831 170.262 230.025C170.624 230.21 171.205 230.422 172.024 230.659C172.913 230.924 173.653 231.188 174.226 231.443C174.807 231.699 175.309 232.086 175.723 232.597C176.145 233.108 176.357 233.777 176.357 234.605C176.357 235.856 175.872 236.833 174.912 237.529C173.953 238.225 172.72 238.577 171.214 238.577C170.183 238.577 169.188 238.427 168.237 238.119C167.286 237.811 166.458 237.379 165.753 236.816L166.925 234.376C167.568 234.878 168.281 235.265 169.074 235.538C169.866 235.811 170.606 235.952 171.31 235.952C171.795 235.952 172.182 235.873 172.473 235.706C172.764 235.538 172.905 235.292 172.905 234.966C172.905 234.605 172.72 234.323 172.35 234.121C171.98 233.918 171.39 233.698 170.58 233.46C169.716 233.213 169.003 232.958 168.431 232.711C167.858 232.465 167.374 232.086 166.969 231.575C166.564 231.064 166.361 230.404 166.361 229.594C166.361 228.343 166.828 227.374 167.77 226.687C168.712 226 169.901 225.657 171.337 225.657C172.2 225.657 173.054 225.78 173.917 226.018C174.78 226.265 175.555 226.608 176.26 227.066L175.018 229.532C174.331 229.109 173.653 228.81 172.984 228.598Z"
            fill="#184999"
          />
          <Path d="M184.336 221.993H196.841V225.067H188.043V228.634H195.952V231.707H188.043V235.345H197.097V238.418H184.336V221.993Z" fill="#184999" />
          <Path d="M212.394 221.006V238.418H208.801V236.569C208.343 237.229 207.788 237.722 207.11 238.057C206.441 238.392 205.675 238.559 204.812 238.559C203.623 238.559 202.575 238.286 201.676 237.749C200.778 237.212 200.082 236.445 199.589 235.459C199.096 234.473 198.849 233.336 198.849 232.059C198.849 230.791 199.096 229.673 199.58 228.704C200.065 227.735 200.752 226.987 201.641 226.449C202.531 225.921 203.561 225.648 204.715 225.648C206.494 225.648 207.859 226.317 208.801 227.638V221.006H212.394ZM207.929 234.675C208.519 234.023 208.81 233.187 208.81 232.156C208.81 231.126 208.519 230.28 207.929 229.637C207.339 228.986 206.591 228.66 205.666 228.66C204.724 228.66 203.966 228.986 203.376 229.637C202.786 230.289 202.487 231.126 202.469 232.156C202.487 233.187 202.786 234.032 203.376 234.675C203.966 235.327 204.724 235.653 205.666 235.653C206.582 235.644 207.339 235.318 207.929 234.675Z" fill="#184999" />
          <Path d="M228 225.789V238.409H224.407V236.181C223.544 237.758 222.135 238.55 220.18 238.55C218.788 238.55 217.678 238.119 216.859 237.247C216.04 236.375 215.626 235.204 215.626 233.715V225.789H219.237V232.685C219.237 233.486 219.449 234.112 219.871 234.561C220.294 235.019 220.875 235.239 221.606 235.239C222.469 235.221 223.147 234.922 223.649 234.323C224.151 233.733 224.398 232.958 224.398 232.024V225.78H228V225.789Z" fill="#184999" />
        </G>
        <Defs>
          <ClipPath id="clip0_731_1342">
            <Rect width={228} height={38.6281} fill="white" transform="translate(0 215)" />
          </ClipPath>
        </Defs>
      </Svg>
        )
    }
}


export default LogoSplash;
