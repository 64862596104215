import React, { Component } from 'react';
import { StyleSheet, View, Pressable, TextInput, FlatList, Image } from 'react-native';
import {
    Typography, Avatar, Skeleton, CardActionArea, Button, Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle,
    CircularProgress
} from '@mui/material';
import LoadingPage from '../components/LoadingPage';
import WarningDialog from '../components/WarningDialog';
import LoadingDialog from '../components/LoadingDialog';
import { LogoPusnas, LogoPusnasBlue, LogoBintangPusnasBlue } from "../assets/Icons";
import LogoPusnasNew from '../assets/images/LogoPusnasNew';
import { NavHomeIcon, NavNewsIcon, NavRackIcon, NavProfileIcon, NavSearchIcon } from '../assets/NavIcons';
import { primary, color_text, sub_text } from '../constants/colors';
import SearchIcon from '../assets/images/SearchIcon';
import { executeGet, executePost, removeCredentials, executeMultipartPost, truncateText } from '../utilities/Utilities';
import {
    code_ok, code_empty, code_error, _code_auth_error, homepage, homepage_banner,
    homepageContentType, homepageCategories, notificationCheck, updateFBToken, updateAvatar, newsList,
    borrowingList, bookReturn, readBorrowedContent, userSignOut
} from '../constants/api';
import * as Command from '../constants/Commands';


var _is_mounted = false;

class Header extends Component {
    //ANCHOR - Constructor
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            showLoadingDialog: false,
            loadingDialogMessage: "",
            showAlert: false,
            alertMessage: "",
            selectedMenu: "home",
            searchFocus: false,
            keyword: "",
            photo: "",
            fullname: "-",
            homepage_data: [],
            news_data: [],
            rack_data: [],
            container_width: 0,
            card_width: 0,
            card_height: 0,
            tooltip: false,
            showConfirmLogout: false,
            loadingMessage: "",
            alertMessage: "",
            showLoading: false
        }

        this.confirmLogout = this.confirmLogout.bind(this);
        this.handleConfirmClose = this.handleConfirmClose.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.executeLogout = this.executeLogout.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);

        this.handleSearchPress = this.handleSearchPress.bind(this);
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
        this.onSearchInputFocus = this.onSearchInputFocus.bind(this);
        this.onSearchInputBlur = this.onSearchInputBlur.bind(this);
    }

    componentDidMount = () => {
        _is_mounted = true;
        // let photo ;
        // if(this.props.route.params.gender == "L"){
        //     photo = "https://bintangpusnas.perpusnas.go.id/images/avatar/userL.png"; 
        // } else {
        //     photo = "https://bintangpusnas.perpusnas.go.id/images/avatar/userP.png"; 
        // }
        // this.setState({
        //     fullname : this.props.route.params.fullname, 
        //     photo : this.props.route.params.photo,
        // },()=>{
        //     // this.getHomepage();

        // })

    }

    //ANCHOR - Handle Search Text Changed
    handleSearchTextChange = (text) => {
        if (_is_mounted) {
            this.setState({
                keyword: text
            })
        }
    }
    //ANCHOR - Handle Search
    handleSearchPress = (e) => {
        let key = e.nativeEvent.key;
        if (key == "Enter") {
            console.log("Cari = " + this.state.keyword);
            // let params = { keyword: this.state.keyword };
            // this.props.navigation.navigate("DesktopSectionDetail", params);
            // this.props.navigate("DesktopSectionDetail", params)
            localStorage.setItem('keywordSearchDesktop', this.state.keyword)
            this.props.action("search")
            // if (this.state.searchType == "umum") {
            //     this.setState({
            //         page: 1,
            //         data: [],
            //         show: 0,
            //         total: 0,
            //     }, () => {
            //         this.fetchContent();
            //     });
            // } else {
            //     if (this.state.keyword.length > 5) {
            //         let params = {
            //             code: this.props.route.params.code,
            //             title: this.props.route.params.title,
            //             selected_kode_sub_kategori: this.state.selected_kode_sub_kategori,
            //             keyword: this.state.keyword,
            //             origins: "CONTENT_SECTION"
            //         }
            //         this.props.navigation.navigate("DesktopSearchKalimat", params);
            //     } else {
            //         this.setState({
            //             showWarning: true,
            //             warningMessage: "Kalimat yang dicari minimal lebih dari 5 huruf"
            //         })
            //     }
            // }
        }
    }

    onSearchInputFocus = (e) => {
        this.setState({
            searchFocus: true
        })
    }
    onSearchInputBlur = () => {
        this.setState({
            searchFocus: false
        })
    }

    showTooltip = () => {
        this.setState({
            tooltip: !this.state.tooltip
        })
    }

    confirmLogout = () => {
        console.log('logout ' + _is_mounted)
        if (_is_mounted) {
            this.setState({
                showConfirmLogout: true
            })
        }
    }
    handleConfirmClose = () => {
        if (_is_mounted) {
            this.setState({
                showConfirmLogout: false
            })
        }
    }
    handleLogout = () => {
        if (_is_mounted) {
            this.setState({
                showConfirmLogout: false,
                showLoading: true
            }, () => {
                this.executeLogout()
            })
        }
    }
    executeLogout = async () => {
        // this.setState({ showLoading: true });
        // const response = await executeGet(userSignOut);
        // if (response.code == code_ok) {
        //     this.setState({ showLoading: false });
        //     window.postMessage({ id: "sign_out" });
        // } else {
        //     this.setState({ showLoading: false });
        //     window.postMessage({ id: "sign_out" });
        // }
    }

    handleSearchClick = () => {
        console.log('handleSearchClick');
        this.props.navigation.navigate("DesktopSearchPage");
    }

    render() {
        return (
            <View style={styles.mainToolbar}>
                {
                    this.state.showLoading &&
                    <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                        <CircularProgress
                            size={64}
                        />
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={16}
                            color={color_text}
                        >
                            Loading
                        </Typography>

                    </View>
                }
                <View style={{ flexDirection: 'row', flex: 1 }}>
                    <View style={{ flex: 1 }} onClick={() => this.props.action("home")}>
                        <LogoBintangPusnasBlue
                            sx={{ cursor: "pointer" }}
                            width={287}
                            height={88}
                        />
                    </View>
                    <View style={{ flex: 1, alignItems: 'center', alignSelf: 'center' }} >
                        <LogoPusnasNew
                            width={264}
                            height={55}
                        />
                    </View>
                    <View style={{ flex: 1 }} >
                        <View style={styles.userBar}>
                            {/* <View style={{ marginLeft: 8 }}
                                // onClick={() => this.showTooltip()}
                                onClick={() => this.props.action("profile")}
                            >
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    align="right"
                                    sx={{ cursor: "pointer" }}
                                >
                                    Hello,
                                </Typography>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    align="right"
                                    fontWeight="700"
                                    sx={{ color: "#999999", cursor: "pointer" }}
                                >
                                    {this.props.fullname}
                                </Typography>

                            </View> */}
                            <Avatar
                                src={this.state.photo}
                                style={{ width: 48, height: 48 }}
                                // onClick={() => this.showTooltip()}
                                sx={{ cursor: "pointer" }}
                                onClick={() => this.props.action("profile")}
                            />
                            <View style={[styles.searchBar, {
                                borderColor: (this.state.searchFocus ? primary : "#F2F2F2"),
                                marginRight: 16
                            }]}>
                                <View style={{ flex: 0.05 }}>
                                    <NavSearchIcon
                                        width={18}
                                        height={18}
                                        fillLogo={(this.state.searchFocus ? primary : "#999999")}
                                    />
                                </View>
                                <TextInput
                                    style={styles.searchInput}
                                    onFocus={this.onSearchInputFocus}
                                    onBlur={this.onSearchInputBlur}
                                    placeholder={"Pencarian"}
                                    onKeyPress={this.handleSearchPress}
                                    onChangeText={this.handleSearchTextChange}
                                />
                            </View>
                            {/* <View
                                style={{ marginRight: 10, borderColor: "#999999", borderWidth: 2, borderRadius: '50%', padding: 8}}
                                onClick={() => {
                                    let params = { code: "HMP-SD", title: "SD/MI", origins: "CONTENT_SECTION" };
                                    this.props.navigation.navigate("DesktopSectionDetail", params);
                                    this.props.action("search")
                                }}>
                                <NavSearchIcon
                                    width={28}
                                    height={28}
                                    fillLogo={(this.state.searchFocus ? primary : "#999999")}
                                />
                            </View> */}

                            {/* <SearchIcon
                                width={48}
                                height={48}
                                fillLogo={(this.state.searchFocus ? primary : "#999999")} /> */}

                        </View>
                    </View>

                </View>
                {
                    this.state.tooltip &&
                    <View
                        style={{
                            position: 'static',
                            padding: '10px',
                            right: '145px',
                            top: '50px',
                            backgroundColor: 'white',
                            boxShadow: '0px 33px 74px rgba(0, 0, 0, 0.0196802), 0px 13.7866px 30.9154px rgba(0, 0, 0, 0.0282725), 0px 7.37098px 16.5289px rgba(0, 0, 0, 0.035), 0px 4.13211px 9.26595px rgba(0, 0, 0, 0.0417275), 0px 2.19453px 4.92108px rgba(0, 0, 0, 0.0503198), 0px 0.913195px 2.04777px rgba(0, 0, 0, 0.07)',
                            width: '100px',
                            alignItems: 'center',
                            borderRadius: '10px'
                        }}
                        onClick={this.confirmLogout}
                    >
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={14}
                            align="right"
                            fontWeight="700"
                            sx={{ color: 'black' }}
                        >
                            Logout
                        </Typography>
                    </View>
                }

                <Dialog
                    open={this.state.showConfirmLogout}
                    onClose={this.handleConfirmClose}
                >
                    <DialogTitle>
                        Konfirmasi
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Anda diharuskan untuk login kembali ketika menjalankan aplikasi ini.{"\r\n"}Lanjutkan?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleConfirmClose}>Batal</Button>
                        <Button onClick={this.handleLogout}>Lanjutkan</Button>
                    </DialogActions>

                </Dialog>
                {
                    this.state.showAlert &&
                    <Alert
                        onClose={() => { this.goBack() }}
                        severity="error"
                    >
                        <AlertTitle>Error</AlertTitle>
                        {this.state.alertMessage}
                    </Alert>
                }
                {/* <LogoPusnasBlue
                                        width={ 187 }
                                        height={ 39 }
                                    />
                        <LogoPusnasBlue
                                        width={ 187 }
                                        height={ 39 }
                                    />
                        <View style={ styles.userBar }>
                            <Avatar 
                                src={ this.state.photo }
                                style={{ width : 48, height : 48  }}
                            />
                            <View style={{ marginRight : 8 }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 14 }   
                                    align="right"
                                >
                                    Hello,
                                </Typography>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 14 }   
                                    align="right"
                                    fontWeight="700"
                                    sx={{color : "#999999"}}
                                >
                                    { this.props.fullname }
                                </Typography>

                            </View>
                        </View> */}
            </View>

        )
    }
}

const styles = StyleSheet.create({
    leftMenuContainer: {
        flex: 0.06,
        paddingTop: 16,
        borderColor: "#E8E8F2",
        borderRightWidth: 0.5,
        backgroundColor: "white",
        alignItems: "center"
    },
    mainMenuContainer: {
        flex: 0.94,
        backgroundColor: "white"
    },
    mainToolbar: {
        flex: 0.07,
        borderColor: "#E8E8F2",
        borderBottomWidth: 0.5,
        backgroundColor: "white",
        alignItems: "center",
        flexDirection: "row",
        paddingLeft: 147,
        paddingRight: 147,
        paddingTop: 10,
        paddingBottom: 10,
        boxShadow: '0px 33px 74px rgba(0, 0, 0, 0.0196802), 0px 13.7866px 30.9154px rgba(0, 0, 0, 0.0282725), 0px 7.37098px 16.5289px rgba(0, 0, 0, 0.035), 0px 4.13211px 9.26595px rgba(0, 0, 0, 0.0417275), 0px 2.19453px 4.92108px rgba(0, 0, 0, 0.0503198), 0px 0.913195px 2.04777px rgba(0, 0, 0, 0.07)'
    },
    mainContainer: {
        flex: 0.93
    },
    menuNavigation: {
        marginTop: 24
    },
    searchBar: {
        backgroundColor: "#F9FAFC",
        borderRadius: 8,
        borderWidth: 0.2,
        padding: 8,
        flexDirection: "row",
        flex: 0.8
    },
    searchInput: {
        fontFamily: "Open Sans",
        fontWeight: "400",
        color: color_text,
        fontSize: 14,
        flex: 0.8,
        backgroundColor: "#F9FAFC",
        outlineColor: "#F9FAFC",
        marginLeft: 18
    },
    userBar: {
        flex: 1,
        flexDirection: "row-reverse",
        alignItems: "center"
    }
})


export default Header;