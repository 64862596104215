import React, { Component } from 'react'; 
import { View , StyleSheet, TextInput } from 'react-native'; 
import { primary , color_text, textbox, danger } from '../constants/colors';
import { Typography } from '@mui/material'; 
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/700.css';

class TextBox extends Component { 
    constructor(props){
        super(props) 

    }

    render(){
        return(
            <View style={ this.props.containerStyle }>
                {
                    this.props.labeled && 
                    <Typography
                        variant="h3"
                        fontWeight="400"
                        fontSize={ 14 } 
                        fontFamily="Open Sans"
                        marginLeft={0.5}
                    >
                        { this.props.caption }
                    </Typography>

                }
                
                <TextInput 
                    ref={ this.props.reference }
                    style={[styles.input,{ marginTop : this.props.labeled ? 8 : 0 }] } 
                    value={ this.props.value }
                    editable={ this.props.editable } 
                    keyboardType={ this.props.keyboardType }
                    placeholder={ this.props.placeholder }
                    onChangeText={ this.props.onChangeText }
                />
                {
                    this.props.error && 
                    <View style={{flexDirection : "row-reverse"}}>
                        <Typography
                            variant="body1"
                            fontFamily="Open Sans"
                            fontSize={ 14 }
                            color={ danger }  
                        >
                            { this.props.errorMessage }
                        </Typography>
                    </View>

                }
                
            </View>
        )
    }
}

const styles = StyleSheet.create({
    input : {
        fontFamily : "Open Sans", 
        fontWeight : "400",
        color : color_text,
        fontSize : 14,
        width : "100%",
        padding : 12 ,  
        backgroundColor : textbox,
        borderRadius : 8,
        outlineColor : primary
    }
})

TextBox.defaultProps = {
    labeled : true
}
export default TextBox; 