import React, { Component } from 'react'; 
import { StyleSheet, Image, View, Linking, Dimensions, Pressable } from 'react-native'; 
import { Alert, AlertTitle, CircularProgress, LinearProgress, Typography, Button } from '@mui/material';
import { Layer1Background, Layer2Background, WordLogo, CrowdBackground } from '../assets/Backgrounds';
import { LogoPusnas } from '../assets/Icons';
import TextBox from '../components/TextBox';
import { primary , color_text, textbox, danger, sub_text } from '../constants/colors';
import { code_ok, code_empty, code_error, code_auth_error, forgotPasswordRequest } from '../constants/api'; 
import { executePost } from '../utilities/Utilities';
import * as Identifier from "../constants/PageIdentifiers";
import * as Command from "../constants/Commands";
import LoadingDialog  from '../components/LoadingDialog';
import WarningDialog from '../components/WarningDialog';
import SuccessDialog from '../components/SuccessDialog';
import HeaderLogoWhite from '../assets/HeaderLogoWhite';

var _is_mounted = false;
class ForgotPasswordDesktop extends Component {
    constructor(props){
        super(props)
        this.state = {
            email : "", 
            errorEmail : false, 
            errorEmailMessage : "" ,
            showAlert : false, 
            alertMessage : "", 
            showSuccess : false, 
            successMessage : "",
            isSubmitting : false
        }

        this.handleEmailChange = this.handleEmailChange.bind(this); 
        this.handleSubmitClick = this.handleSubmitClick.bind(this); 

        this.handleAlertClick = this.handleAlertClick.bind(this); 
        this.handleSuccessClick = this.handleSuccessClick.bind(this); 

        this.backToLogin = this.backToLogin.bind(this);
        this.submitRequest = this.submitRequest.bind(this);  
    }

    componentDidMount = ()=>{
        _is_mounted = true;
    }

    componentWillUnmount = ()=>{
        _is_mounted = false;
    }

    handleEmailChange = (text)=>{
        if(_is_mounted){
            this.setState({
                email : text, 
                errorEmail : false
            });
        }
    }   

    handleSubmitClick = ()=>{
        if(_is_mounted){
            if(this.state.email.length == 0){
                this.setState({
                    errorEmail : true, 
                    errorEmailMessage : "Harap masukkan e-mail anda yang terdaftar di aplikasi ini"
                });
                return; 
            } else {
                let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
                if(! regEmail.test(this.state.email)){
                    this.setState({
                        errorEmail : true, 
                        errorEmailMessage : "E-mail wajib diisi dengan format yang benar"
                    }); 
                    return;
                } else {
                    //== Submit ==// 
                    this.submitRequest();
                }
            }
        }
    }

    backToLogin = ()=>{
        //JSBridge.launchFunction(Command.SET_PAGE, Identifier.LOGIN);
        this.props.navigation.goBack();
        //this.props.navigation.navigate(Identifier.LOGIN);
    }

    handleAlertClick = ()=>{
        this.setState({
            showAlert : false
        })
    }
    handleSuccessClick = ()=>{
        this.setState({
            showSuccess : false
        },()=>{
            this.backToLogin(); 
        })
    }
    
    submitRequest = async()=>{
        let formData = new FormData(); 
        formData.append("email", this.state.email);
        
        this.setState({
            isSubmitting : true
        })

        const response = await executePost(forgotPasswordRequest , formData); 
        if(response.code == code_ok){
            this.setState({
                isSubmitting : false, 
                showSuccess : true, 
                successMessage : response.msg
            })
        } else {
            this.setState({
                isSubmitting : false, 
                showAlert : true, 
                alertMessage : response.msg 
            })
        }
    }

    render(){
        const winHeight = Dimensions.get("window").height;
        const backgroundWidth = winHeight - 144; 
        const backgroundWidth2 = winHeight - 112; 
        
        return(
            <View style={{ flex : 1, flexDirection : "row"}}> 
                <View style={{ flex : 0.6, alignItems : "center", justifyContent : "center" }} >
                    <View style={{ position: "absolute", left : -16, top : 0 }}>
                        <Layer1Background 
                            //width={ backgroundWidth2 } 
                            height={ winHeight }
                            //viewBox={"0 0 " + backgroundWidth2 + " " + winHeight }
                        />
                    </View>
                    <View style={{ position: "absolute", left : -16, top : 0 }}>
                        <Layer2Background 
                            //width={ backgroundWidth }
                            height={ winHeight }
                            //viewBox={"0 0 " + backgroundWidth + " " + winHeight }
                        />
                    </View>
                    {/* <View style={{ position : "absolute", left : 16 , top : 16 }}>
                        <LogoPusnas 
                            width={ 55 }
                            height={ 51 }
                        />
                    </View> */}
                    <View style={{ position: "absolute", left : 16, top : 28 }}>
                        <HeaderLogoWhite
                            width={ 300 }
                            height={ 45 }
                        />
                    </View>
                    <View style={{marginRight : 128 }}>
                        <CrowdBackground 
                            width={ 500 }
                            height={ 421 }
                        />
                    </View>
                </View>
                <View style={{flex : 0.4, alignItems : 'center', justifyContent : "center" }}>
                    <View style={{ width : "70%"}}>
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={24}
                            fontWeight="700"
                            sx={{color : primary }}
                        >
                            Lupa Password
                        </Typography>
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={16}
                            fontWeight="600"
                            sx={{ color : sub_text }}
                        >
                            Masukkan e-mail yang terkait dengan akun anda dibawah ini
                        </Typography>
                        <TextBox 
                            caption={"Email"}
                            editable={ true }
                            containerStyle={{ marginTop : 24 }}
                            placeholder={"Masukkan email"}
                            keyboardType={"email-address"}
                            error={ this.state.errorEmail }
                            errorMessage={ this.state.errorEmailMessage }
                            onChangeText={ this.handleEmailChange }
                        />
                        <Button
                            variant="contained"
                            style={{marginLeft : 0, marginRight : 0, textTransform : "none", marginTop : 16, borderRadius : 8, padding : 10  }}
                            onClick={ this.handleSubmitClick }
                        >
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 16 }
                                fontWeight="500"
                            >
                            Kirim
                            </Typography>
                        </Button>
                        <View style={{ flexDirection : "row", marginTop : 24, marginBottom : 16, alignItems : "center", justifyContent : "center"}}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 14 }
                            >
                                Ingat kembali password anda?
                            </Typography>
                            <Pressable
                                onPress={()=>{ this.backToLogin() }}
                            >
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 14 }
                                    fontWeight="600"
                                    style={{ marginLeft : 8 }}
                                    color={ primary }   
                                >
                                    Klik disini
                                </Typography>
                            </Pressable>
                        </View>
                    </View>
                </View>
                <WarningDialog
                    open={this.state.showAlert}
                    message={ this.state.alertMessage }
                    caption={"OK"}
                    onClick={ this.handleAlertClick }
                />
                <LoadingDialog 
                    open={this.state.isSubmitting}
                    message={ "Mengirim data" }
                />
                <SuccessDialog
                    open={ this.state.showSuccess }
                    message={ this.state.successMessage } 
                    caption={"OK"}
                    onClick={ this.handleSuccessClick }
                />
            </View>
        )
    }
}

export default ForgotPasswordDesktop; 