import React, { Component } from 'react'; 
import { View, StyleSheet , Image, FlatList, Dimensions, ScrollView , Pressable } from 'react-native'; 
import { Alert, AlertTitle, Typography, Skeleton , CircularProgress, 
    IconButton, Chip, List , ListItemButton, ListItemIcon, 
    ListItemText, Collapse, Paper , Button} from '@mui/material'; 
import LoadingPage from '../components/LoadingPage'; 
import AlertDialog from '../components/AlertDialog'; 
import * as Identifier from '../constants/PageIdentifiers';
import * as Command from '../constants/Commands'; 
import { code_ok, code_empty, code_error, code_auth_error, commentList, sendComment } from '../constants/api';
import { executeGet, executePost , isEven, truncateText } from '../utilities/Utilities'; 
import  EmptySearchPage  from '../components/EmptySearchPage';
import { color_text, sub_text, primary } from '../constants/colors';
import { IconComment, IconRate, IconShare, IconStar , IconStock, IconStorage, IconBookmark } from '../assets/Icons';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TextBox from '../components/TextBox';
import SendIcon from '@mui/icons-material/Send';

var _is_mounted = false; 
class CommentPageDesktop extends Component {
    constructor(props){
        super(props)
        this.state = {
            showLoading : true, 
            loadingMessage : "", 
            moreLoading : false, 
            showAlert : false, 
            alertMessage : "",
            data : [],
            content_code : this.props.route.params.content_code, 
            dummy_data : [
                {i :0}, { i : 1} , {i :2}, { i : 3}, {i :4}, { i : 5}
            ],
            total : 0,
            show : 0,
            page : 1,
            allow_input : false,
            already_input : 1,
            comment : "",
            errorComment : false, 
            errorCommentMessage : "", 

        }
        this.goBack = this.goBack.bind(this); 
        this.fetchComments = this.fetchComments.bind(this); 
        this.renderSkeleton = this.renderSkeleton.bind(this); 
        this.renderItem = this.renderItem.bind(this); 
        this.expandReview = this.expandReview.bind(this);
        this.handleCommentChange = this.handleCommentChange.bind(this); 
        this.handleSendComment = this.handleSendComment.bind(this); 
        this.postComment = this.postComment.bind(this);

        
    }

    componentDidMount = ()=>{
        _is_mounted = true;
        window.addEventListener("message", (event)=>{
            if(event.data.id == "go_back"){
                if(event.data.page == Identifier.CONTENT_DETAIL){
                    this.goBack(); 
                } 
            }
        });
        this.fetchComments(); 

    }
    componentWillUnmount = ()=>{
        _is_mounted = false;
        window.removeEventListener("message", null); 
    }
    goBack = ()=>{
        // this.props.navigation.navigate("ContentDetail", this.props.route.params); 
        this.props.navigation.goBack();
    }
    fetchComments = async()=>{
        let formData = new FormData();
        formData.append("page", this.state.page )
        formData.append("code", this.props.route.params.content_code)
        if(this.state.page == 1){
            this.setState({
                showLoading : true 
            })
        } else {
            this.setState({
                moreLoading : true 
            })
        }

        const response = await executePost(commentList, formData); 
        if(response.code == code_ok){
            if(response.show > 0 ) {
                let show = this.state.show + response.show;
                let data = [...this.state.data, ...response.data ]; 
                let allow_input; 
                let already_input; 
                if(this.state.page == 1) {
                    allow_input = response.allow;
                    already_input = (response.status == 1); 
                }
                this.setState({
                    total : response.total, 
                    show : show,
                    data : data, 
                    showLoading : false, 
                    moreLoading : false,
                    allow_input : allow_input, 
                    already_input : already_input
                })
            } else {
                let allow_input; 
                let already_input; 

                if(this.state.page == 1) {
                    allow_input = response.allow;
                    already_input = (response.status == 1); 
                }

                this.setState({
                    showLoading : false, 
                    allow_input : allow_input, 
                    already_input : already_input
                })
            }
        } else {
            this.setState({
                showAlert : true, 
                alertMessage : response.msg
            })
        }
    } 
    renderSkeleton = ({item, index, separators})=>{
        return(
            <View style={{
                    marginTop : (index == 0 ? 16 :  0 ), 
                    flexDirection : "row", 
                    paddingLeft : 16 , 
                    paddingRight : 16, 
                    paddingTop : 8, 
                    paddingBottom : 16 
            }}>
                <Skeleton 
                    variant="circular"
                    animation="wave" 
                    width={ 48 }
                    height={ 48 }
                />
                <View style={{flex : 1,  marginLeft : 16 }}>
                    <Skeleton 
                        variant="text"
                        animation="wave"
                        sx={{ fontSize : "2rem", width : "70%"}}
                    />
                    <Skeleton 
                        variant="rounded"
                        animation="wave"
                        height={ 90 } 
                        style={{ borderRadius : 8 }}
                    />
                    <View style={{ height : 0.2 , backgroundColor : "silver", marginTop : 16 }} />

                </View>
            </View>
        )
    }
    renderItem = ({item, index, separators})=>{
        return(
            <View style={{ 
                marginTop : (index == 0 ? 16 :  0 ), 
                flexDirection : "row", 
                paddingLeft : 16 , 
                paddingRight : 16, 
                paddingTop : 8, 
                paddingBottom : 16,
                boxShadow: "0 4px 6px -1px rgba(0,0,0, 0.07)"
            }}>
                <Image 
                    source={{uri : item.photo }}
                    style={{ width : 48, height : 48 }} 
                />
                <View style={{ flex : 1, marginLeft : 16 }}>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 14 }
                        color={ color_text } 
                        fontWeight="700"
                    >
                        { item.fullname }
                    </Typography>
                    {
                        item.reviews.length > 250 && 
                        <View>
                            <Collapse in={ item.collapsed != 1} collapsedSize={90}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 14 }
                                    color={ color_text }
                                    fontWeight="500"
                                >
                                    { item.reviews }
                                </Typography>
                            </Collapse>
                            <Pressable 
                                style={{ padding : 12, alignItems : "center", backgroundColor : "rgba(255, 255, 255,0.8)", marginTop : (item.collapsed == 1 ? -24 : 0 ) }}
                                onPress={()=>{ this.expandReview(item, index)}}
                            >
                                {
                                    item.collapsed == 1 && 
                                    <ExpandMoreIcon />
                                }
                                {
                                    item.collapsed != 1 && 
                                    <ExpandLessIcon />
                                }
                            </Pressable>
                        </View>
                    }
                    {
                        item.reviews.length < 250 && 
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={ 14 }
                            color={ color_text }
                            fontWeight="500"
                        >
                            { item.reviews }
                        </Typography>

                    }
                    <View style={{ backgroundColor : "silver", height : 0.2, marginTop : 16  }} />

                </View>
            </View>
        )
    }

    expandReview = (item, index)=>{
        let data = [...this.state.data];
        
        if(item.collapsed == 1) {
            item.collapsed = 0;
        } else {
            item.collapsed = 1; 
        }
        data[index] = item;
        this.setState({
            data : data
        })
    }
    handleCommentChange = (text)=>{
        if(_is_mounted){
            this.setState({
                comment : text,
                errorComment : false
            })
        }
    }
    handleSendComment = ()=>{
        if(this.state.comment.length == 0 ) {
            this.setState({
                errorComment : true, 
                errorCommentMessage : "Harap diisi"
            })
            return; 
        }
        this.postComment(); 

    }

    postComment = async()=>{
        let formData = new FormData(); 
        formData.append("content_code", this.props.route.params.content_code); 
        formData.append("comment", this.state.comment); 

        this.setState({
            showLoading : true
        })

        const response = await executePost(sendComment, formData); 
        if(response.code == code_ok){
            this.setState({
                data : [], 
                page : 1, 
                total : 0, 
                show : 0
            },()=>{
                this.fetchComments();
            })
        }
    }
    render(){
        return(
            <View style={{flex : 1, backgroundColor : primary }}>
                <View style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    flex: 0.05,
                    backgroundColor: "white",
                    flexDirection: "row",
                    alignItems: "center",
                    boxShadow: "0 4px 6px -1px rgba(0,0,0, 0.07)"
                }}>
                    <IconButton
                        style={{ marginRight: 16, marginLeft: 16 }}
                        onClick={()=>{ this.goBack() }}
                    >
                        <ChevronLeftIcon 
                            sx={{color : "black"}}
                        />
                    </IconButton>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 16 }
                        color="black"
                        fontWeight={"500"}
                        style={{ marginLeft : 15 }}
                    >
                        Komentar
                    </Typography>
                </View>
                <View style={{ flex : 1, backgroundColor : "#F2F2F2"}}>
                {
                    this.state.showLoading && 
                    <FlatList 
                        keyExtractor={(i, x)=>"skeleton_"+x}
                        data={ this.state.dummy_data}
                        renderItem={ this.renderSkeleton }
                        showsVerticalScrollIndicator={false}
                    />

                }
                {
                    (!this.state.showLoading && this.state.data.length > 0) &&
                    <View style={{flex : 1}}>
                        <FlatList 
                            style={{ flex : 0.9}}
                            keyExtractor={(i, x)=>"data_"+x}
                            data={ this.state.data }
                            renderItem={ this.renderItem }
                            showsVerticalScrollIndicator={false}
                        />
                        {
                            (this.state.allow_input && this.state.already_input == 0) && 
                            <View style={{ flex: 0.1, flexDirection : "row", justifyContent : "center",  position : "absolute", bottom : 16, left : 16 , right : 16 }}>
                                <TextBox 
                                    labeled={ false }
                                    editable={ true }
                                    placeholder={"Ketik komentar anda"}
                                    keyboardType={"default"}
                                    containerStyle={{
                                        flex : 1,
                                        marginRight : 8 
                                    }}
                                    error={ this.state.errorComment }
                                    errorMessage={ this.state.errorCommentMessage }
                                    onChangeText={ this.handleCommentChange }
                                />

                                <IconButton
                                    onClick={ this.handleSendComment }
                                >
                                    <SendIcon />
                                </IconButton>
                            </View>
                        }
                    </View>
                }
                {
                    (!this.state.showLoading && this.state.data.length == 0) &&
                    <View style={{flex : 1}}>
                        <EmptySearchPage 
                            title={"Belum ada komentar"}
                            message={"Komentar untuk konten ini belum tersedia"}
                        />
                        {
                            (this.state.allow_input && this.state.already_input == 0) && 
                            <View style={{ flexDirection : "row", justifyContent : "center",  position : "absolute", bottom : 16, left : 16 , right : 16 }}>
                                <TextBox 
                                    labeled={ false }
                                    editable={ true }
                                    placeholder={"Ketik komentar anda"}
                                    keyboardType={"default"}
                                    containerStyle={{
                                        flex : 1,
                                        marginRight : 8 
                                    }}
                                    error={ this.state.errorComment }
                                    errorMessage={ this.state.errorCommentMessage }
                                    onChangeText={ this.handleCommentChange }
                                />

                                <IconButton
                                    onClick={ this.handleSendComment }
                                >
                                    <SendIcon />
                                </IconButton>
                            </View>
                        }
                        
                    </View>
                }
                </View>
            </View>
        )
    }
}

export default CommentPageDesktop; 