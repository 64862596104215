import React, { Component } from 'react'; 
import { View, StyleSheet, FlatList, Image , Pressable } from 'react-native'; 
import { IconButton, Alert, AlertTitle, Card, CardActionArea, CardContent, Typography, Skeleton  } from '@mui/material';
import { code_ok, code_empty, code_error, code_auth_error, notificationList , updateNotificationStatus } from '../constants/api';
import { color_text, sub_text } from '../constants/colors';
import * as Identifier from '../constants/PageIdentifiers';
import * as Command from '../constants/Commands';
import { executeGet, executePost } from '../utilities/Utilities';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

var _is_mounted = false;
class Notification extends Component {
    constructor(props){
        super(props)
        this.state = {
            showLoading : true, 
            showAlert : false, 
            moreLoading :false, 
            loadingMessage : "", 
            alertMessage : "",
            data : [], 
            dummy_data : [
                {i : 0},{i : 1},{i : 2},{i : 3},{i : 4},{i : 5},{i : 6},{i : 7}
            ]
        }

        this.fetchNotifications = this.fetchNotifications.bind(this); 
        this.renderSkeleton = this.renderSkeleton.bind(this); 
        this.renderItem = this.renderItem.bind(this); 
        this.goBack = this.goBack.bind(this); 
        this.onPressItem = this.onPressItem.bind(this); 

    }

    componentDidMount = ()=>{
        _is_mounted = true;
        if (global.os == "ANDROID") {
            JSBridge.launchFunction(Command.SET_PAGE, Identifier.NOTIFICATION);    
        }
        window.addEventListener("message", (event)=>{
            if(event.data.id == "go_back"){
                if(global.currentRouteName == Identifier.NOTIFICATION){
                    this.goBack(); 
                }
            }
        });

        this.fetchNotifications();
    }
    componentWillUnmount = ()=>{
        _is_mounted = false;
        window.removeEventListener("message", null);
    }
    fetchNotifications = async()=>{ 
        let formData = new FormData();
        formData.append("page", 1); 

        const response = await executePost(notificationList, formData); 
        if(response.code == code_ok){
            this.setState({
                data : response.data , 
                showLoading : false
            })
        } else {
            if(response.code == code_auth_error){
                if(global.os == "ANDROID") {
                    JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                } else {
                    window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                }
                window.postMessage({ id: "sign_out"});
            } else {
                this.setState({
                    showLoading : false, 
                    showAlert : true, 
                    alertMessage : response.msg
                })
            }
        }

    }
    renderSkeleton = ({item, index, separators})=>{
        return(
            <View>
                <Skeleton
                    variant="text"
                    sx={{ fontSize : "2rem", width : 180 }} 
                    animation="wave" 
                    style={{marginLeft : 16, marginBottom : 8 }}
                />

                <Skeleton 
                    variant="rectangular"
                    sx={{ height : 90 }}
                    style={{ marginLeft : 16, marginRight : 16, marginBottom : 8, borderRadius : 8 }}
                />
            </View>
        )
    }

    goBack = ()=>{
        //JSBridge.launchFunction(Command.SET_PAGE, this.props.route.params.identifier );
        //this.props.navigation.goBack(); 
        this.props.navigation.navigate(this.props.route.params.identifier);
    }
    renderItem = ({item, index, separators})=>{
        return(
            <Card
                style={{
                    marginLeft : 16, 
                    marginRight : 16, 
                    marginBottom : 8 ,
                    borderRadius : 8
                }}
            >
                <CardActionArea sx={{ height : "100%"}}>
                    <CardContent>
                        <Pressable style={{ flexDirection : "row", alignItems : "center"}}
                            onPress={()=>{ this.onPressItem(item,index )}}
                        >
                            <View style={{flex : 1}}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 14 }
                                    fontWeight={"600"}
                                    color={ color_text }
                                >
                                    { item.title }
                                </Typography>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 12 }
                                    fontWeight={"500"}
                                    color={ sub_text }
                                    style={{marginBottom : 12}}
                                >
                                    { item.date }
                                </Typography>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 12 }
                                    fontWeight={"500"}
                                    color={ color_text }
                                    style={{marginBottom : 8}}
                                >
                                    { item.message }
                                </Typography>
                                
                            </View>
                            <View>
                                {
                                    item.read_status == "0" && 
                                    <MailOutlinedIcon />
                                }
                                {
                                    item.read_status == "1" &&
                                    <DraftsOutlinedIcon />
                                }
                                
                            </View>
                        </Pressable>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    }

    onPressItem = async(item, index)=>{
        //TODO: URI Handling

        if(item.read_status == "0") {

            let formData = new FormData();
            formData.append("code", item.code);
            formData.append("status", "1"); 

            const response = await executePost(updateNotificationStatus, formData); 
            if(response.code == code_ok){

                let data = [...this.state.data]; 
                item.read_status = "1"; 
                data[index] = item; 

                this.setState({
                    data : data 
                }, ()=>{
                    window.postMessage({ id : "check_notifications"}); 
                })
            } else {
                if(response.code == code_auth_error){
                    if(global.os == "ANDROID"){
                        JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                    } else {
                        window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                    }
                    window.postMessage({ id: "sign_out"});
                } else {
                    this.setState({
                        showLoading : false, 
                        showAlert : true, 
                        alertMessage : response.msg
                    })
                }
            }
        }
    }
    render(){
        return(
            <View style={{flex : 1}}>
                <View style={{
                    marginLeft : 16 , 
                    //marginTop : (global.os == "ANDROID" ? 16 : 48 ) , 
                    marginTop : 16 , 
                    marginBottom : 16 ,
                    flex : 0.05,
                    backgroundColor : "#F2F2F2",
                    flexDirection : "row", 
                    alignItems : "center", 
                    
            
                }}>
              
                    <IconButton
                        style={{ marginRight : 16 }}
                        onClick={()=>{ this.goBack() }}
                    >
                        <ChevronLeftIcon 
                            sx={{color : color_text }}
                        />
                    </IconButton>                
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 18 }
                        style={{ flex : 1 }}
                        fontWeight={"600"}
                    >
                        Notifikasi
                    </Typography>
                </View>
                <View style={{flex : 0.95 }}>
                    {
                        this.state.showLoading &&
                        <FlatList 
                            keyExtractor={(i, x)=>"index_"+x}
                            data={ this.state.dummy_data }
                            renderItem={ this.renderSkeleton }
                            showsVerticalScrollIndicator={false}
                        />
                    }
                    {
                        !this.state.showLoading && 
                        <FlatList 
                            keyExtractor={(item, index)=>"code_"+item.code}
                            data={ this.state.data }
                            renderItem={ this.renderItem }
                            showsVerticalScrollIndicator={false}
                        />  
                    }
                </View>
                {
                    this.state.showAlert && 
                    <Alert 
                        onClose={()=>{ this.goBack() }}
                        severity="error"
                    > 
                        <AlertTitle>Error</AlertTitle>
                        { this.state.alertMessage }
                    </Alert>

                }

            </View>
        )
    }
}

export default Notification;