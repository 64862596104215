import React, { Component } from "react"
import Svg, { G, Path, Defs, ClipPath, Rect } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

class LogoSplash extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Svg
                {...this.props}
                width={this.props.width}
                height={this.props.height}
                viewBox="0 0 190 255"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <Path
                    d="M93.9359 10.4684C95.7142 13.2981 97.4277 16.1749 99.1918 19.016C102.417 24.2653 105.776 29.4528 108.416 35.052C110.129 38.6985 111.464 42.5826 111.919 46.6246C112.263 49.3615 112.155 52.1391 111.762 54.8647L112.017 54.754C111.336 55.2536 110.934 56.0281 110.44 56.7002C105.323 63.8826 100.233 71.0878 95.1051 78.2621C94.7312 76.2444 94.1773 74.2543 93.2985 72.4042C91.8279 69.2181 89.7357 66.3966 87.604 63.658C85.1426 60.5289 82.6022 57.4666 80.2086 54.2805C84.7812 39.6748 89.3539 25.0708 93.9359 10.4684Z"
                    fill="#169B48"
                />
                <Path
                    d="M112.016 54.754C116.327 52.8372 121.059 51.9992 125.741 52.1147C131.574 52.1945 137.295 53.532 142.971 54.8028C148.08 56.0363 153.236 57.0435 158.383 58.0833C146.088 67.2801 133.759 76.4331 121.463 85.63C117.949 84.3722 114.524 82.8784 111.055 81.5002C105.972 79.4759 100.549 77.6958 95.0323 78.3662L95.1049 78.2621C100.233 71.0878 105.323 63.8826 110.44 56.7001C110.934 56.0281 111.336 55.2536 112.016 54.754Z"
                    fill="#11743B"
                />
                <Path
                    d="M29.4379 56.7714C40.4513 56.7665 51.4647 56.8088 62.4781 56.8072C67.0729 56.7958 71.6676 56.8658 76.2638 56.8186C77.9474 58.9779 79.6136 61.1534 81.3429 63.2769C83.3468 65.7828 85.3917 68.2642 87.1605 70.9572C88.7368 73.385 90.07 76.0634 90.515 78.9793C90.7974 80.6602 90.7122 82.3736 90.8132 84.0708C90.8337 84.4369 90.5907 84.7217 90.3982 84.9999C83.5109 94.615 76.7104 104.295 69.872 113.946C65.0027 120.81 60.1508 127.686 55.291 134.556C55.0764 134.877 54.8713 135.215 54.57 135.459C54.6394 134.878 54.8745 134.341 55.0386 133.786C59.993 118.177 64.9506 102.57 69.8988 86.959C59.3682 79.1095 48.8392 71.2583 38.3102 63.4054C35.3564 61.1892 32.3743 59.012 29.4379 56.7714Z"
                    fill="#215FAC"
                />
                <Path
                    d="M90.8131 84.0708C92.757 83.5876 94.7104 83.0018 96.7253 82.9888C100.258 82.8065 103.723 83.791 107.03 84.9739C111.633 86.6646 116.103 88.723 120.732 90.342C125.534 105.543 130.379 120.731 135.189 135.931C132.658 134.128 130.209 132.198 127.706 130.351C116.734 122.087 105.735 113.858 94.7735 105.579C81.3066 115.705 67.838 125.828 54.3679 135.949C54.4089 135.749 54.4515 135.549 54.491 135.352L54.5699 135.459C54.8713 135.215 55.0764 134.877 55.291 134.556C60.1507 127.686 65.0026 120.81 69.8719 113.946C76.7103 104.295 83.5108 94.615 90.3982 85C90.5907 84.7217 90.8336 84.437 90.8131 84.0708Z"
                    fill="#184999"
                />
                <Path
                    d="M4.73981 143.398C6.57169 142.832 8.67655 142.435 10.4501 143.4C12.2283 144.345 12.4445 147.227 10.9203 148.519C9.78736 149.554 8.17795 149.593 6.74841 149.507C6.74999 150.618 6.74526 151.731 6.75157 152.846C6.08098 152.849 5.41197 152.849 4.74138 152.846C4.74454 149.697 4.74612 146.547 4.73981 143.398ZM6.7563 144.708C6.73263 145.756 6.7563 146.806 6.74999 147.853C7.59099 147.883 8.54085 148.024 9.26351 147.469C10.1913 146.812 10.0887 145.066 8.99054 144.656C8.2742 144.451 7.44582 144.472 6.7563 144.708Z"
                    fill="#58585B"
                />
                <Path
                    d="M30.4366 143.411C32.2748 142.822 34.3955 142.44 36.18 143.402C37.9851 144.378 38.1634 147.338 36.5556 148.592C35.4321 149.554 33.8827 149.595 32.4957 149.5C32.4531 150.613 32.4847 151.73 32.4784 152.846C31.7999 152.849 31.1214 152.849 30.443 152.844C30.4319 149.701 30.4445 146.555 30.4366 143.411ZM32.4642 144.669C32.4894 145.73 32.4689 146.791 32.4768 147.852C33.2847 147.883 34.1793 148.016 34.8941 147.532C35.8503 146.951 35.8597 145.276 34.8704 144.728C34.1115 144.385 33.2531 144.531 32.4642 144.669Z"
                    fill="#58585B"
                />
                <Path
                    d="M49.3852 143.44C50.8195 142.531 52.6624 142.713 54.1503 143.382C54.1424 143.964 54.144 144.547 54.1566 145.129C53.0505 144.7 51.8025 144.257 50.6364 144.71C50.0258 144.937 49.7702 145.935 50.3382 146.347C51.5674 147.108 53.2762 146.968 54.2339 148.202C55.1933 149.666 54.4738 151.946 52.8801 152.58C51.2692 153.238 49.3631 153.049 47.8973 152.097C47.9052 151.484 47.9004 150.87 47.9036 150.258C49.068 150.924 50.4234 151.563 51.7804 151.197C52.5204 151.065 52.9827 150.003 52.4352 149.409C51.2755 148.564 49.6282 148.743 48.5963 147.661C47.5123 146.413 48.014 144.243 49.3852 143.44Z"
                    fill="#58585B"
                />
                <Path
                    d="M136.631 143.423C138.051 142.533 139.848 142.733 141.33 143.353C141.347 143.939 141.355 144.526 141.355 145.114C140.315 144.728 139.185 144.318 138.078 144.633C137.543 144.736 137.128 145.32 137.24 145.877C137.35 146.342 137.827 146.511 138.21 146.667C139.291 147.081 140.588 147.175 141.39 148.129C142.439 149.561 141.729 151.91 140.129 152.566C138.516 153.245 136.614 153.044 135.129 152.123C135.124 151.5 135.124 150.879 135.127 150.259C136.29 150.936 137.649 151.559 139.009 151.198C139.761 151.051 140.244 149.902 139.585 149.357C138.403 148.562 136.749 148.724 135.755 147.59C134.737 146.319 135.266 144.199 136.631 143.423Z"
                    fill="#58585B"
                />
                <Path
                    d="M13.1151 143.005C15.2484 143.006 17.3832 143.001 19.518 143.006C19.5133 143.555 19.5133 144.106 19.5164 144.656C18.0554 144.661 16.5927 144.648 15.1316 144.663C15.1379 145.431 15.1158 146.199 15.1474 146.967C16.2677 146.967 17.3863 146.967 18.5066 146.967C18.524 147.517 18.5429 148.067 18.5177 148.618C17.3895 148.594 16.2598 148.609 15.1316 148.61C15.13 149.474 15.1426 150.34 15.1284 151.206C16.6747 151.227 18.2195 151.207 19.7642 151.217C19.7673 151.759 19.7673 152.304 19.7657 152.847C17.5489 152.846 15.3304 152.851 13.1135 152.846C13.1198 149.565 13.1167 146.285 13.1151 143.005Z"
                    fill="#58585B"
                />
                <Path
                    d="M21.2283 143.416C23.0113 142.871 25.0183 142.489 26.7792 143.315C28.215 143.943 28.8998 145.935 28.1188 147.341C27.7638 148.049 27.0458 148.425 26.3768 148.75C27.1121 149.782 27.7575 151.176 29.1397 151.352C29.1365 151.881 29.1365 152.41 29.1397 152.942C28.4107 152.926 27.6123 152.994 26.9748 152.555C25.8546 151.85 25.2881 150.579 24.5497 149.518C24.2862 149.024 23.6756 149.186 23.2274 149.152C23.2353 150.384 23.2306 151.616 23.2306 152.847C22.5632 152.847 21.8942 152.847 21.2267 152.847C21.2283 149.704 21.2236 146.56 21.2283 143.416ZM23.2338 144.654C23.2259 145.629 23.2385 146.604 23.2259 147.58C24.0495 147.605 24.9505 147.766 25.6984 147.312C26.5125 146.841 26.6608 145.473 25.8782 144.899C25.1051 144.327 24.1079 144.585 23.2338 144.654Z"
                    fill="#58585B"
                />
                <Path
                    d="M38.5815 143.011C39.2647 143 39.9479 143.001 40.6311 143.008C40.6232 144.889 40.6232 146.771 40.6295 148.652C40.6358 149.411 40.7258 150.258 41.2796 150.817C41.9723 151.432 43.0799 151.427 43.7773 150.823C44.3422 150.267 44.4227 149.406 44.4274 148.646C44.4448 146.768 44.4416 144.892 44.429 143.014C45.1138 142.995 45.7986 143.003 46.4834 143.009C46.4171 145.097 46.5275 147.186 46.4439 149.276C46.3603 150.559 45.7339 151.882 44.5757 152.471C42.878 153.368 40.456 153.082 39.3057 151.404C38.804 150.642 38.5973 149.71 38.5957 148.799C38.5736 146.869 38.6004 144.939 38.5815 143.011Z"
                    fill="#58585B"
                />
                <Path
                    d="M55.2326 142.995C57.8534 143.016 60.4758 142.998 63.0982 143.005C63.095 143.603 63.0982 144.204 63.0887 144.804C62.1168 144.783 61.1464 144.814 60.1776 144.783C60.1524 147.47 60.146 150.159 60.1839 152.849C59.5023 152.847 58.8206 152.847 58.1406 152.849C58.18 150.164 58.1501 147.479 58.1627 144.796C57.1765 144.794 56.1904 144.801 55.2058 144.793C55.2058 144.192 55.1869 143.592 55.2326 142.995Z"
                    fill="#58585B"
                />
                <Path
                    d="M73.0657 143.001C73.7347 143.004 74.4037 143.004 75.0743 143.004C75.0743 144.397 75.0679 145.792 75.0758 147.185C76.3081 145.831 77.4726 144.412 78.6512 143.008C79.4118 143.003 80.1739 142.998 80.9344 143.013C79.7289 144.534 78.4398 145.982 77.198 147.473C78.0501 148.493 78.7333 149.655 79.6469 150.62C80.0019 151.02 80.532 151.147 81.0243 151.264C81.048 151.824 81.0433 152.385 81.0417 152.947C80.1407 152.94 79.1593 152.828 78.4619 152.18C77.359 151.196 76.6332 149.865 75.7243 148.706C75.5066 148.895 75.2888 149.085 75.0727 149.279C75.0727 150.467 75.0695 151.656 75.0758 152.846C74.4005 152.849 73.7268 152.849 73.0515 152.844C73.0956 149.564 73.053 146.283 73.0657 143.001Z"
                    fill="#58585B"
                />
                <Path
                    d="M85.3696 143.08C86.1049 142.977 86.8544 142.969 87.5896 143.084C89.5667 145.908 90.8905 149.37 90.6207 152.896C89.9406 152.906 89.2622 152.864 88.5837 152.828C88.5884 152.057 88.5947 151.284 88.5695 150.514C87.1684 150.48 85.7641 150.47 84.3629 150.519C84.3787 151.292 84.3408 152.065 84.3535 152.839C83.6797 152.864 83.0091 152.908 82.3354 152.896C82.0561 149.369 83.3894 145.9 85.3696 143.08ZM84.6659 148.877C85.8729 148.898 87.0784 148.889 88.2855 148.884C87.8595 147.406 87.2946 145.956 86.4646 144.669C85.6757 145.976 85.084 147.401 84.6659 148.877Z"
                    fill="#58585B"
                />
                <Path
                    d="M94.731 143.343C95.0087 142.863 95.6367 143.055 96.0864 143C96.4919 143.049 97.0584 142.892 97.3013 143.337C99.1853 146.098 100.422 149.469 100.162 152.885C99.4804 152.919 98.8019 152.856 98.1218 152.835C98.1234 152.058 98.1234 151.282 98.1139 150.508C96.716 150.48 95.3196 150.48 93.9232 150.508C93.92 151.285 93.9042 152.065 93.9027 152.844C93.2289 152.852 92.5567 152.909 91.883 152.898C91.6116 149.479 92.8502 146.108 94.731 143.343ZM94.2166 148.885C95.419 148.889 96.6197 148.89 97.822 148.885C97.4307 147.406 96.8106 146.002 96.0533 144.686C95.176 145.935 94.6427 147.416 94.2166 148.885Z"
                    fill="#58585B"
                />
                <Path
                    d="M101.224 143.014C101.809 142.996 102.396 143.003 102.983 143.009C104.531 144.88 106.057 146.77 107.595 148.649C107.504 146.776 107.529 144.902 107.52 143.027C108.147 143.001 108.779 142.988 109.408 143.022C109.377 146.314 109.41 149.607 109.391 152.901C108.913 152.868 108.435 152.844 107.957 152.828C107.426 151.157 106.158 149.938 105.112 148.61C104.443 147.787 103.774 146.963 103.068 146.174C103.136 148.395 103.113 150.618 103.124 152.841C102.496 152.85 101.868 152.854 101.241 152.837C101.248 149.564 101.227 146.29 101.224 143.014Z"
                    fill="#58585B"
                />
                <Path
                    d="M116.119 142.998C116.712 143.006 117.307 142.993 117.902 143.026C119.405 144.923 120.956 146.781 122.484 148.661C122.362 146.784 122.405 144.905 122.395 143.027C123.02 142.998 123.647 143 124.273 143.006C124.27 146.295 124.265 149.583 124.275 152.872C123.79 152.88 123.307 152.849 122.826 152.825C122.302 151.137 121.007 149.922 119.962 148.578C119.293 147.764 118.642 146.932 117.919 146.171C118.036 148.394 117.971 150.621 117.992 152.846C117.368 152.849 116.747 152.851 116.125 152.842C116.084 149.562 116.097 146.28 116.119 142.998Z"
                    fill="#58585B"
                />
                <Path
                    d="M128.446 143.082C129.12 142.988 129.807 142.96 130.483 143.056C130.619 143.069 130.722 143.139 130.793 143.266C132.713 146.044 133.956 149.445 133.71 152.893C133.03 152.911 132.353 152.855 131.675 152.837C131.691 152.061 131.662 151.287 131.662 150.512C130.261 150.481 128.858 150.47 127.456 150.517C127.448 151.29 127.438 152.064 127.448 152.839C126.766 152.859 126.084 152.912 125.403 152.891C125.158 149.365 126.469 145.906 128.446 143.082ZM127.746 148.864C128.953 148.914 130.16 148.878 131.369 148.883C130.962 147.442 130.394 146.041 129.625 144.763L129.53 144.731C128.723 145.993 128.183 147.424 127.746 148.864Z"
                    fill="#58585B"
                />
                <Path
                    d="M143.231 143.017C143.91 142.991 144.588 143.006 145.268 143.006C145.26 146.285 145.262 149.565 145.267 152.846C144.59 152.849 143.914 152.849 143.239 152.846C143.233 149.57 143.248 146.293 143.231 143.017Z"
                    fill="#58585B"
                />
                <Path
                    d="M149.648 143.034C151.246 142.606 153.121 142.842 154.367 144.034C156.18 145.906 156.277 149.14 154.805 151.256C154.068 152.364 152.744 152.907 151.48 152.989C150.076 153.081 148.54 152.641 147.616 151.49C146.111 149.572 146.008 146.617 147.4 144.604C147.931 143.82 148.76 143.284 149.648 143.034ZM150.281 144.682C149.367 144.97 148.764 145.884 148.619 146.827C148.411 148.042 148.469 149.432 149.252 150.432C150.185 151.586 152.113 151.565 153.026 150.398C153.724 149.437 153.845 148.151 153.653 146.997C153.556 146.158 153.135 145.331 152.419 144.89C151.778 144.511 150.977 144.425 150.281 144.682Z"
                    fill="#58585B"
                />
                <Path
                    d="M157.121 143.026C157.716 143.014 158.325 142.943 158.913 143.053C160.45 144.92 161.976 146.798 163.503 148.672C163.372 146.798 163.42 144.917 163.415 143.039C164.037 142.988 164.66 143.003 165.285 143.006C165.289 146.292 165.296 149.575 165.289 152.861C164.807 152.885 164.324 152.848 163.844 152.826C163.469 151.691 162.781 150.705 162.001 149.829C160.979 148.604 160.008 147.335 158.934 146.158C159.055 148.384 158.988 150.615 159.011 152.844C158.383 152.851 157.757 152.851 157.132 152.843C157.126 149.57 157.146 146.298 157.121 143.026Z"
                    fill="#58585B"
                />
                <Path
                    d="M169.232 143.681C169.422 143.421 169.567 143.016 169.946 143.037C170.581 143.03 171.314 142.873 171.896 143.167C173.879 145.948 175.141 149.406 174.897 152.894C174.215 152.909 173.537 152.859 172.858 152.834C172.879 152.06 172.849 151.285 172.849 150.512C171.445 150.483 170.04 150.463 168.638 150.522C168.638 151.293 168.623 152.066 168.634 152.839C167.953 152.859 167.271 152.912 166.588 152.891C166.362 149.614 167.483 146.378 169.232 143.681ZM168.953 148.893C170.154 148.879 171.353 148.895 172.555 148.883C172.12 147.412 171.583 145.941 170.72 144.677C169.963 145.997 169.319 147.401 168.953 148.893Z"
                    fill="#58585B"
                />
                <Path
                    d="M176.74 143.021C177.428 143.029 178.116 142.996 178.805 143.045C178.679 145.73 178.752 148.423 178.789 151.111C180.14 151.14 181.494 151.134 182.846 151.114C182.846 151.707 182.863 152.299 182.857 152.893C180.822 152.891 178.785 152.891 176.751 152.893C176.73 149.603 176.749 146.311 176.74 143.021Z"
                    fill="#58585B"
                />
                <Path
                    d="M66.2904 143.081C67.0131 142.992 67.7578 142.943 68.4773 143.093C70.5254 145.864 71.7892 149.378 71.5494 152.895C70.8693 152.911 70.1909 152.854 69.5108 152.839C69.5187 152.063 69.5155 151.285 69.495 150.511C68.0923 150.486 66.6896 150.46 65.2885 150.524C65.2979 151.295 65.2648 152.066 65.2806 152.839C64.6021 152.862 63.9252 152.911 63.2467 152.891C63.0006 149.365 64.3086 145.903 66.2904 143.081ZM67.1204 145.12C66.4861 146.314 65.9228 147.566 65.6182 148.895C66.8143 148.876 68.0118 148.9 69.2094 148.882C68.8039 147.427 68.2217 146.023 67.4517 144.733C67.2608 144.76 67.215 144.98 67.1204 145.12Z"
                    fill="#58585B"
                />
                <Path
                    opacity={0.77}
                    d="M41.2182 160.879C42.4994 160.394 44.0094 160.179 45.2654 160.851C46.3163 161.405 46.6287 162.986 45.9076 163.94C45.562 164.415 45.0224 164.669 44.4875 164.843C44.9924 165.538 45.3964 166.325 46.0259 166.916C46.2689 167.142 46.6302 167.127 46.8906 167.319C46.9016 167.588 46.9048 167.858 46.9001 168.131C46.3746 168.133 45.7861 168.136 45.3617 167.767C44.5001 167.04 44.0441 165.962 43.3641 165.079C43.0059 165.038 42.6462 165.004 42.288 164.981C42.2943 166.011 42.2896 167.041 42.2912 168.073C41.9251 168.086 41.559 168.086 41.1945 168.073C41.1756 165.676 41.1283 163.276 41.2182 160.879ZM42.288 161.518C42.2943 162.376 42.2896 163.234 42.2912 164.091C43.0832 164.14 43.9637 164.255 44.6611 163.774C45.3569 163.317 45.3837 162.088 44.6548 161.649C43.94 161.198 43.0627 161.366 42.288 161.518Z"
                    fill="#6C6C6D"
                />
                <Path
                    opacity={0.77}
                    d="M47.9793 160.531C49.5508 160.526 51.1224 160.541 52.6955 160.53C52.6987 160.84 52.6955 161.154 52.6845 161.468C51.4837 161.436 50.283 161.433 49.0822 161.473C49.0885 162.235 49.087 162.998 49.0854 163.761C50.0305 163.727 50.9772 163.73 51.9224 163.768C51.9224 164.051 51.9224 164.337 51.9239 164.625C50.982 164.671 50.0384 164.648 49.0964 164.643C49.0743 165.478 49.0917 166.314 49.0854 167.151C50.3508 167.146 51.6162 167.162 52.8817 167.139C52.8817 167.452 52.8817 167.764 52.8817 168.08C51.2423 168.083 49.6045 168.098 47.9667 168.072C47.9777 165.558 47.9525 163.044 47.9793 160.531Z"
                    fill="#6C6C6D"
                />
                <Path
                    opacity={0.77}
                    d="M54.1693 160.876C55.3527 160.394 56.7112 160.253 57.9293 160.674C59.3888 161.206 59.7565 163.457 58.6189 164.508C57.7321 165.386 56.4099 165.474 55.2486 165.362C55.3164 166.265 55.2833 167.173 55.2864 168.079C54.9235 168.081 54.5622 168.086 54.2024 168.099C54.1362 165.692 54.2009 163.283 54.1693 160.876ZM55.2817 161.512C55.2927 162.477 55.2912 163.442 55.2833 164.409C56.0864 164.505 57.0094 164.57 57.68 164.008C58.3585 163.422 58.379 162.148 57.6169 161.626C56.9148 161.185 56.0391 161.33 55.2817 161.512Z"
                    fill="#6C6C6D"
                />
                <Path
                    opacity={0.77}
                    d="M60.2549 160.533C60.6209 160.512 60.9886 160.512 61.3578 160.528C61.4604 162.21 61.271 163.898 61.4099 165.577C61.4761 166.417 62.1025 167.208 62.9593 167.235C63.936 167.383 64.8133 166.549 64.8543 165.556C64.9474 163.893 64.8685 162.223 64.8953 160.559C65.263 160.531 65.6306 160.513 65.9998 160.505C66.0077 162.14 66.0282 163.776 65.9777 165.409C65.9367 166.552 65.2204 167.736 64.1064 168.033C63.1076 168.29 61.9148 168.2 61.1401 167.43C60.531 166.824 60.2896 165.927 60.2707 165.076C60.2312 163.561 60.2864 162.048 60.2549 160.533Z"
                    fill="#6C6C6D"
                />
                <Path
                    opacity={0.77}
                    d="M67.6377 160.84C68.799 160.443 70.0676 160.282 71.2684 160.586C72.174 160.827 72.8462 161.86 72.5748 162.816C72.4265 163.418 71.9153 163.813 71.4056 164.082C71.8664 164.308 72.3697 164.536 72.6569 164.998C73.015 165.605 72.8999 166.397 72.5417 166.978C72.1772 167.575 71.5192 167.901 70.8739 168.062C69.7899 168.319 68.6681 168.071 67.6314 167.727C67.6377 165.431 67.6267 163.135 67.6377 160.84ZM68.6949 161.516C68.7107 162.249 68.6996 162.985 68.7043 163.72C69.3039 163.768 69.9146 163.787 70.5094 163.672C70.9638 163.571 71.4293 163.266 71.5445 162.773C71.6912 162.279 71.464 161.696 70.9938 161.493C70.2664 161.169 69.4286 161.263 68.6949 161.516ZM68.7012 164.635C68.7059 165.453 68.7012 166.272 68.7043 167.092C69.5012 167.243 70.3879 167.44 71.139 167.015C71.8238 166.654 72.0336 165.549 71.4183 165.024C70.6214 164.462 69.6069 164.664 68.7012 164.635Z"
                    fill="#6C6C6D"
                />
                <Path
                    opacity={0.77}
                    d="M74.1228 160.546C74.4904 160.523 74.8612 160.518 75.232 160.534C75.2052 162.731 75.2084 164.929 75.2368 167.126C76.3507 167.142 77.4647 167.121 78.5787 167.137C78.5771 167.448 78.5771 167.76 78.5787 168.076C77.0923 168.092 75.606 168.084 74.1197 168.081C74.1181 165.569 74.1133 163.056 74.1228 160.546Z"
                    fill="#6C6C6D"
                />
                <Path
                    opacity={0.77}
                    d="M79.7321 160.559C80.095 160.525 80.4594 160.512 80.8239 160.516C80.7939 163.037 80.805 165.559 80.8034 168.081C80.4405 168.089 80.0808 168.083 79.721 168.06C79.7478 165.559 79.7257 163.06 79.7321 160.559Z"
                    fill="#6C6C6D"
                />
                <Path
                    opacity={0.77}
                    d="M82.714 160.559C83.0833 160.528 83.4525 160.515 83.8249 160.516C83.828 161.667 83.8327 162.817 83.8201 163.969C84.9246 162.874 85.9344 161.683 86.9979 160.546C87.4239 160.523 87.8515 160.512 88.2807 160.53C87.2725 161.717 86.1869 162.834 85.1771 164.02C85.8761 164.855 86.493 165.761 87.2157 166.576C87.5202 166.931 87.962 167.095 88.3975 167.209C88.4038 167.517 88.4101 167.823 88.4148 168.135C87.6953 168.159 86.96 167.897 86.4725 167.339C85.7262 166.498 85.0777 165.572 84.3866 164.685C84.1957 164.827 84.0079 164.973 83.8201 165.12C83.8343 166.106 83.8264 167.094 83.8249 168.081C83.4667 168.081 83.1101 168.086 82.7535 168.091C82.6604 165.584 82.7361 163.07 82.714 160.559Z"
                    fill="#6C6C6D"
                />
                <Path
                    opacity={0.77}
                    d="M91.9793 160.552C92.3406 160.521 92.7051 160.515 93.0696 160.529C93.0475 163.043 93.0586 165.557 93.0633 168.071C92.7004 168.083 92.3375 168.084 91.9761 168.078C91.9509 165.569 91.9477 163.061 91.9793 160.552Z"
                    fill="#6C6C6D"
                />
                <Path
                    opacity={0.77}
                    d="M94.9645 160.526C95.3573 160.552 95.8938 160.347 96.1305 160.788C97.4022 162.441 98.7623 164.024 99.9741 165.726C99.7974 163.998 99.8984 162.256 99.8811 160.523C100.216 160.514 100.55 160.51 100.888 160.51C100.913 163.028 100.9 165.549 100.894 168.07C100.64 168.081 100.386 168.094 100.135 168.11C99.758 166.773 98.7497 165.809 97.9466 164.744C97.2808 163.914 96.6465 163.056 95.9348 162.265C95.979 164.203 95.9522 166.141 95.9538 168.079C95.6224 168.081 95.2927 168.081 94.9629 168.079C94.9613 165.562 94.9597 163.043 94.9645 160.526Z"
                    fill="#6C6C6D"
                />
                <Path
                    opacity={0.77}
                    d="M102.666 160.844C103.788 160.414 105.015 160.347 106.192 160.526C107.494 160.765 108.539 161.937 108.723 163.281C109.022 164.806 108.668 166.653 107.33 167.552C105.926 168.446 104.155 168.236 102.658 167.722C102.666 165.429 102.65 163.136 102.666 160.844ZM103.777 161.491C103.764 163.351 103.783 165.211 103.767 167.071C104.774 167.297 105.97 167.39 106.814 166.662C107.636 165.915 107.778 164.682 107.646 163.621C107.554 162.812 107.134 162.009 106.42 161.625C105.599 161.203 104.643 161.296 103.777 161.491Z"
                    fill="#6C6C6D"
                />
                <Path
                    opacity={0.77}
                    d="M111.786 160.622C112.763 160.347 113.859 160.318 114.792 160.775C115.707 161.239 116.311 162.192 116.516 163.205C116.783 164.562 116.567 166.111 115.622 167.152C114.246 168.599 111.658 168.488 110.401 166.931C109.582 165.836 109.446 164.334 109.746 163.021C109.981 161.935 110.76 160.987 111.786 160.622ZM112.428 161.442C111.608 161.654 111.008 162.42 110.846 163.257C110.652 164.279 110.683 165.434 111.291 166.311C112.147 167.546 114.225 167.508 115.01 166.207C115.639 165.115 115.645 163.681 115.128 162.541C114.658 161.533 113.429 161.132 112.428 161.442Z"
                    fill="#6C6C6D"
                />
                <Path
                    opacity={0.77}
                    d="M117.914 160.541C118.22 160.521 118.527 160.508 118.834 160.502C119.689 161.516 120.491 162.578 121.321 163.615C121.853 164.303 122.438 164.951 122.889 165.702C122.834 163.971 122.795 162.238 122.842 160.507C123.178 160.508 123.517 160.52 123.857 160.533C123.866 163.042 123.85 165.551 123.868 168.06C123.609 168.075 123.35 168.089 123.094 168.106C122.733 166.846 121.81 165.909 121.035 164.913C120.319 164.013 119.62 163.1 118.882 162.222C118.94 164.179 118.948 166.14 118.893 168.098C118.574 168.088 118.255 168.086 117.94 168.091C117.884 165.575 117.935 163.058 117.914 160.541Z"
                    fill="#6C6C6D"
                />
                <Path
                    opacity={0.77}
                    d="M125.622 160.531C127.189 160.526 128.759 160.543 130.327 160.53C130.332 160.84 130.329 161.153 130.318 161.467C129.122 161.441 127.924 161.428 126.728 161.477C126.709 162.235 126.706 162.996 126.725 163.756C127.669 163.73 128.614 163.73 129.557 163.768C129.557 164.051 129.559 164.334 129.562 164.62C128.611 164.682 127.658 164.632 126.706 164.656C126.719 165.488 126.705 166.319 126.733 167.151C127.994 167.144 129.254 167.165 130.515 167.138C130.509 167.448 130.506 167.761 130.504 168.076C128.871 168.085 127.238 168.099 125.607 168.068C125.625 165.556 125.597 163.044 125.622 160.531Z"
                    fill="#6C6C6D"
                />
                <Path
                    opacity={0.77}
                    d="M132.557 160.819C133.639 160.215 134.966 160.355 136.06 160.852C136.072 161.19 136.087 161.528 136.095 161.87C135.206 161.445 134.14 161.099 133.204 161.571C132.608 161.81 132.335 162.798 132.926 163.198C133.927 163.842 135.306 163.688 136.145 164.633C136.951 165.671 136.352 167.347 135.217 167.837C133.983 168.408 132.532 168.184 131.391 167.489C131.375 167.124 131.369 166.762 131.375 166.4C132.363 167.01 133.611 167.538 134.738 166.997C135.38 166.724 135.729 165.649 135.083 165.195C134.121 164.578 132.819 164.724 131.975 163.877C131.107 162.998 131.549 161.376 132.557 160.819Z"
                    fill="#6C6C6D"
                />
                <Path
                    opacity={0.77}
                    d="M137.726 160.55C138.087 160.523 138.452 160.515 138.816 160.526C138.84 163.038 138.807 165.551 138.834 168.063C138.463 168.083 138.094 168.089 137.726 168.081C137.723 165.57 137.724 163.06 137.726 160.55Z"
                    fill="#6C6C6D"
                />
                <Path
                    opacity={0.77}
                    d="M140.85 164.383C141.3 162.995 142.037 161.732 142.8 160.508C143.214 160.521 143.629 160.534 144.045 160.541C144.937 161.95 145.771 163.439 146.171 165.086C146.409 166.06 146.448 167.074 146.426 168.076C146.057 168.081 145.689 168.081 145.323 168.078C145.312 167.406 145.314 166.734 145.285 166.062C144.042 166.051 142.797 166.057 141.554 166.059C141.521 166.729 141.483 167.401 141.51 168.075C141.137 168.084 140.766 168.083 140.397 168.075C140.369 166.83 140.446 165.566 140.85 164.383ZM141.727 165.156C142.851 165.126 143.974 165.131 145.098 165.154C144.722 163.839 144.175 162.573 143.406 161.454C142.655 162.583 142.108 163.847 141.727 165.156Z"
                    fill="#6C6C6D"
                />
                <G clipPath="url(#clip0_99_1056)">
                    <Path
                        d="M5.69011 248.432C6.06229 248.759 6.24442 249.213 6.24442 249.795C6.24442 250.409 6.05437 250.887 5.68219 251.222C5.31001 251.557 4.77946 251.732 4.10637 251.732H2.75228V253.446H2V247.946H4.10637C4.78738 247.946 5.31793 248.105 5.69011 248.432ZM5.15164 250.736C5.40504 250.529 5.52382 250.226 5.52382 249.819C5.52382 249.429 5.39712 249.126 5.15164 248.926C4.89824 248.727 4.5419 248.624 4.0747 248.624L2.74436 248.632V251.047H4.0747C4.5419 251.047 4.90616 250.943 5.15164 250.736Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M10.275 249.835C10.5997 250.218 10.766 250.744 10.766 251.421C10.766 251.525 10.766 251.605 10.7581 251.66H7.49558C7.55101 252.035 7.70938 252.33 7.96278 252.545C8.21618 252.768 8.52501 252.872 8.89719 252.872C9.14267 252.872 9.37231 252.824 9.57819 252.736C9.792 252.649 9.97413 252.521 10.1246 252.362L10.5205 252.776C10.3146 252.999 10.0692 253.167 9.78408 253.286C9.49901 253.406 9.18226 253.47 8.83384 253.47C8.42998 253.47 8.06572 253.382 7.74898 253.207C7.43223 253.031 7.18675 252.784 7.01254 252.465C6.83833 252.147 6.75122 251.78 6.75122 251.373C6.75122 250.967 6.83833 250.6 7.01254 250.289C7.18675 249.971 7.43223 249.723 7.74106 249.54C8.04989 249.357 8.40623 249.269 8.81008 249.269C9.45941 249.261 9.94246 249.452 10.275 249.835ZM10.1087 251.086C10.085 250.712 9.96621 250.409 9.73657 250.194C9.50693 249.979 9.20602 249.867 8.83384 249.867C8.46958 249.867 8.16867 249.979 7.92319 250.194C7.67771 250.417 7.53517 250.712 7.48766 251.078H10.1087V251.086Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M13.0624 249.5C13.3158 249.349 13.6088 249.269 13.9493 249.261V249.971C13.5296 249.955 13.1891 250.066 12.9278 250.297C12.6665 250.537 12.5161 250.847 12.4844 251.246V253.438H11.7559V249.285H12.4844V250.178C12.619 249.883 12.809 249.66 13.0624 249.5Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M18.0591 249.532C18.36 249.708 18.5976 249.963 18.7638 250.281C18.9301 250.6 19.0172 250.967 19.0172 251.381C19.0172 251.788 18.9381 252.155 18.7718 252.465C18.6055 252.784 18.3758 253.031 18.0749 253.207C17.774 253.382 17.4335 253.47 17.0455 253.47C16.6971 253.47 16.3882 253.398 16.1269 253.247C15.8577 253.103 15.6518 252.888 15.5013 252.617V254.968H14.7728V249.285H15.5013V250.114C15.6518 249.843 15.8577 249.636 16.119 249.484C16.3803 249.341 16.6812 249.261 17.0297 249.261C17.4177 249.261 17.7582 249.349 18.0591 249.532ZM17.6077 252.641C17.8215 252.513 17.9799 252.338 18.0987 252.115C18.2175 251.892 18.2729 251.636 18.2729 251.35C18.2729 251.071 18.2095 250.823 18.0908 250.592C17.972 250.369 17.8057 250.194 17.5998 250.066C17.3939 249.939 17.1564 249.875 16.8871 249.875C16.6179 249.875 16.3803 249.939 16.1744 250.066C15.9606 250.194 15.8023 250.369 15.6756 250.592C15.5568 250.815 15.4934 251.071 15.4934 251.35C15.4934 251.636 15.5568 251.892 15.6756 252.115C15.7943 252.338 15.9606 252.513 16.1744 252.641C16.3882 252.768 16.6258 252.832 16.8871 252.832C17.1564 252.832 17.3939 252.768 17.6077 252.641Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M23.6814 249.285V253.438H22.9449V252.561C22.6757 253.151 22.161 253.454 21.4166 253.462C20.9415 253.462 20.5614 253.318 20.2922 253.031C20.0229 252.744 19.8883 252.354 19.8883 251.852V249.285H20.6247V251.66C20.6247 252.011 20.7198 252.282 20.9098 252.481C21.0999 252.681 21.3612 252.776 21.6938 252.776C22.0818 252.768 22.3827 252.633 22.6123 252.362C22.842 252.091 22.9528 251.748 22.9528 251.326V249.285H23.6814Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M26.7855 249.955C26.5796 249.891 26.3817 249.859 26.1996 249.859C25.9778 249.859 25.8036 249.899 25.669 249.979C25.5344 250.058 25.471 250.186 25.471 250.353C25.471 250.521 25.5423 250.648 25.6928 250.736C25.8432 250.823 26.0649 250.911 26.3658 250.999C26.6509 251.086 26.8885 251.166 27.0706 251.254C27.2527 251.342 27.4111 251.461 27.5378 251.621C27.6645 251.78 27.7279 251.995 27.7279 252.266C27.7279 252.657 27.5774 252.96 27.2844 253.159C26.9914 253.366 26.6272 253.462 26.1916 253.462C25.8828 253.462 25.5898 253.414 25.2968 253.31C25.0117 253.215 24.7663 253.071 24.5683 252.888L24.8375 252.354C25.0117 252.513 25.2176 252.633 25.471 252.728C25.7244 252.824 25.9699 252.872 26.2075 252.872C26.445 252.872 26.643 252.824 26.7855 252.736C26.9281 252.649 27.0073 252.513 27.0073 252.33C27.0073 252.139 26.9281 252.003 26.7776 251.907C26.6272 251.812 26.3896 251.716 26.0808 251.628C25.8036 251.549 25.5819 251.469 25.4077 251.389C25.2335 251.31 25.083 251.19 24.9642 251.031C24.8455 250.871 24.7821 250.672 24.7821 250.417C24.7821 250.026 24.9246 249.739 25.2018 249.548C25.4789 249.357 25.8353 249.261 26.255 249.261C26.5084 249.261 26.7539 249.293 26.9914 249.365C27.2369 249.437 27.4428 249.532 27.617 249.652L27.3478 250.21C27.1815 250.098 26.9914 250.018 26.7855 249.955Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M30.9033 253.167C30.5865 253.374 30.2618 253.47 29.9372 253.47C29.6125 253.47 29.3433 253.374 29.1374 253.175C28.9315 252.976 28.8365 252.681 28.8365 252.282V249.979H28.2505V249.421H28.8365V248.305H29.5729V249.421H30.8399V249.979H29.5729V252.147C29.5729 252.378 29.6125 252.545 29.6996 252.641C29.7867 252.736 29.9055 252.792 30.0718 252.792C30.2698 252.792 30.4836 252.728 30.7132 252.593L30.9033 253.167Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M34.3003 253.438V252.856C33.9915 253.262 33.5085 253.47 32.8591 253.47C32.5741 253.47 32.3207 253.414 32.1069 253.302C31.8931 253.191 31.7268 253.039 31.608 252.848C31.4892 252.657 31.4338 252.434 31.4338 252.194C31.4338 251.82 31.5684 251.525 31.8455 251.31C32.1227 251.094 32.5028 250.983 32.9858 250.975H34.3003V250.776C34.3003 250.481 34.2132 250.258 34.0311 250.098C33.849 249.939 33.5877 249.859 33.2472 249.859C32.8275 249.859 32.4078 250.01 31.9722 250.305L31.6713 249.803C31.9643 249.612 32.2415 249.468 32.4949 249.381C32.7483 249.293 33.0492 249.253 33.3976 249.253C33.9123 249.253 34.3083 249.381 34.5933 249.636C34.8705 249.891 35.0209 250.242 35.0209 250.704L35.0289 253.438H34.3003ZM33.8727 252.617C34.1103 252.449 34.2528 252.234 34.3003 251.963V251.549H33.0888C32.7641 251.549 32.5186 251.597 32.3603 251.7C32.2019 251.796 32.1227 251.955 32.1227 252.171C32.1227 252.386 32.2019 252.553 32.3682 252.681C32.5266 252.808 32.7483 252.872 33.0175 252.872C33.3422 252.872 33.6352 252.784 33.8727 252.617Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M39.202 253.438L37.8796 251.421L37.0798 252.266V253.446H36.3513V247.619H37.0798V251.334L39.0754 249.293H39.9306L38.3943 250.903L40.081 253.446H39.202V253.438Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M43.1693 253.438V252.856C42.8605 253.262 42.3774 253.47 41.7281 253.47C41.443 253.47 41.1896 253.414 40.9758 253.302C40.762 253.191 40.5957 253.039 40.4769 252.848C40.3582 252.657 40.3027 252.434 40.3027 252.194C40.3027 251.82 40.4374 251.525 40.7145 251.31C40.9917 251.094 41.3718 250.983 41.8548 250.975H43.1693V250.776C43.1693 250.481 43.0822 250.258 42.9001 250.098C42.7179 249.939 42.4566 249.859 42.1161 249.859C41.6964 249.859 41.2767 250.01 40.8412 250.305L40.5403 249.803C40.8333 249.612 41.1104 249.468 41.3638 249.381C41.6172 249.293 41.9181 249.253 42.2666 249.253C42.7813 249.253 43.1772 249.381 43.4623 249.636C43.7394 249.891 43.8899 250.242 43.8899 250.704L43.8978 253.438H43.1693ZM42.7338 252.617C42.9713 252.449 43.1139 252.234 43.1614 251.963V251.549H41.9498C41.6252 251.549 41.3797 251.597 41.2213 251.7C41.0629 251.796 40.9837 251.955 40.9837 252.171C40.9837 252.386 41.0629 252.553 41.2292 252.681C41.3876 252.808 41.6093 252.872 41.8786 252.872C42.2111 252.872 42.4962 252.784 42.7338 252.617Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M47.7067 253.438V252.856C47.3979 253.262 46.9148 253.47 46.2655 253.47C45.9804 253.47 45.727 253.414 45.5132 253.302C45.2994 253.191 45.1331 253.039 45.0144 252.848C44.8956 252.657 44.8401 252.434 44.8401 252.194C44.8401 251.82 44.9748 251.525 45.2519 251.31C45.5291 251.094 45.9092 250.983 46.3922 250.975H47.7067V250.776C47.7067 250.481 47.6196 250.258 47.4375 250.098C47.2554 249.939 46.994 249.859 46.6535 249.859C46.2338 249.859 45.8141 250.01 45.3786 250.305L45.0777 249.803C45.3707 249.612 45.6479 249.468 45.9013 249.381C46.1547 249.293 46.4556 249.253 46.804 249.253C47.3187 249.253 47.7146 249.381 47.9997 249.636C48.2769 249.891 48.4273 250.242 48.4273 250.704L48.4352 253.438H47.7067ZM47.2712 252.617C47.5088 252.449 47.6513 252.234 47.6988 251.963V251.549H46.4872C46.1626 251.549 45.9171 251.597 45.7587 251.7C45.6003 251.796 45.5212 251.955 45.5212 252.171C45.5212 252.386 45.6003 252.553 45.7666 252.681C45.925 252.808 46.1467 252.872 46.416 252.872C46.7486 252.872 47.0336 252.784 47.2712 252.617Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M53.1548 249.692C53.4319 249.979 53.5665 250.369 53.5665 250.871V253.438H52.838V251.071C52.838 250.72 52.743 250.449 52.545 250.25C52.3471 250.05 52.0778 249.955 51.7373 249.955C51.3651 249.963 51.0642 250.082 50.8346 250.313C50.605 250.545 50.4703 250.847 50.4387 251.222V253.446H49.7101V249.293H50.4387V250.17C50.7158 249.58 51.2385 249.277 52.0066 249.269C52.4975 249.261 52.8776 249.405 53.1548 249.692Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M56.9716 247.946H57.8585L59.854 251.812L61.8336 247.946H62.7285V253.446H62.0237L62.0158 249.054L60.0757 252.864H59.6164L57.6684 249.054V253.446H56.9716V247.946Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M67.3372 249.835C67.6618 250.218 67.8281 250.744 67.8281 251.421C67.8281 251.525 67.8281 251.605 67.8202 251.66H64.5577C64.6131 252.035 64.7715 252.33 65.0249 252.545C65.2783 252.768 65.5871 252.872 65.9593 252.872C66.2048 252.872 66.4344 252.824 66.6403 252.736C66.8541 252.649 67.0363 252.521 67.1867 252.362L67.5827 252.776C67.3768 252.999 67.1313 253.167 66.8462 253.286C66.5611 253.406 66.2444 253.47 65.896 253.47C65.4921 253.47 65.1279 253.382 64.8111 253.207C64.4944 253.031 64.2489 252.784 64.0747 252.465C63.9005 252.147 63.8134 251.78 63.8134 251.373C63.8134 250.967 63.9005 250.6 64.0747 250.289C64.2489 249.971 64.4944 249.723 64.8032 249.54C65.112 249.357 65.4684 249.269 65.8722 249.269C66.5295 249.261 67.0125 249.452 67.3372 249.835ZM67.1788 251.086C67.155 250.712 67.0363 250.409 66.8066 250.194C66.577 249.979 66.2761 249.867 65.9039 249.867C65.5396 249.867 65.2387 249.979 64.9932 250.194C64.7478 250.417 64.6052 250.712 64.5577 251.078H67.1788V251.086Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M72.2547 249.692C72.5318 249.979 72.6665 250.369 72.6665 250.871V253.438H71.9379V251.071C71.9379 250.72 71.8429 250.449 71.645 250.25C71.447 250.05 71.1777 249.955 70.8372 249.955C70.4651 249.963 70.1642 250.082 69.9345 250.313C69.7049 250.545 69.5703 250.847 69.5386 251.222V253.446H68.8101V249.293H69.5386V250.17C69.8157 249.58 70.3384 249.277 71.1065 249.269C71.5974 249.261 71.9775 249.405 72.2547 249.692Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M73.3395 254.37C73.5375 254.378 73.688 254.307 73.7909 254.163C73.8938 254.02 73.9493 253.812 73.9493 253.541V249.285H74.6857V253.645C74.6857 254.076 74.5828 254.402 74.3769 254.641C74.171 254.873 73.878 254.992 73.5058 254.992C73.2683 254.992 73.0386 254.944 72.7932 254.849L72.9674 254.291C73.0782 254.331 73.2049 254.354 73.3395 254.37ZM74.6303 247.763C74.7174 247.85 74.757 247.962 74.757 248.098C74.757 248.233 74.7174 248.345 74.6303 248.432C74.5432 248.52 74.4402 248.568 74.3056 248.568C74.1789 248.568 74.0681 248.52 73.981 248.432C73.8939 248.345 73.8543 248.233 73.8543 248.098C73.8543 247.962 73.8939 247.85 73.981 247.763C74.0681 247.675 74.171 247.627 74.3056 247.627C74.4402 247.627 74.5511 247.675 74.6303 247.763Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M78.5421 253.438V252.856C78.2333 253.262 77.7502 253.47 77.1009 253.47C76.8158 253.47 76.5624 253.414 76.3486 253.302C76.1348 253.191 75.9685 253.039 75.8497 252.848C75.731 252.657 75.6755 252.434 75.6755 252.194C75.6755 251.82 75.8102 251.525 76.0873 251.31C76.3645 251.094 76.7446 250.983 77.2276 250.975H78.5421V250.776C78.5421 250.481 78.455 250.258 78.2729 250.098C78.0907 249.939 77.8294 249.859 77.4889 249.859C77.0692 249.859 76.6495 250.01 76.214 250.305L75.9131 249.803C76.2061 249.612 76.4832 249.468 76.7366 249.381C76.99 249.293 77.291 249.253 77.6394 249.253C78.1541 249.253 78.55 249.381 78.8351 249.636C79.1122 249.891 79.2627 250.242 79.2627 250.704L79.2706 253.438H78.5421ZM78.1066 252.617C78.3441 252.449 78.4867 252.234 78.5342 251.963V251.549H77.3226C76.998 251.549 76.7525 251.597 76.5941 251.7C76.4357 251.796 76.3565 251.955 76.3565 252.171C76.3565 252.386 76.4357 252.553 76.602 252.681C76.7604 252.808 76.9821 252.872 77.2514 252.872C77.5839 252.872 77.869 252.784 78.1066 252.617Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M83.9902 249.692C84.2673 249.979 84.402 250.369 84.402 250.871V253.438H83.6734V251.071C83.6734 250.72 83.5784 250.449 83.3804 250.25C83.1825 250.05 82.9132 249.955 82.5727 249.955C82.2006 249.963 81.8996 250.082 81.67 250.313C81.4404 250.545 81.3057 250.847 81.2741 251.222V253.446H80.5535V249.293H81.282V250.17C81.5591 249.58 82.0818 249.277 82.8499 249.269C83.3329 249.261 83.713 249.405 83.9902 249.692Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M89.3511 249.285V253.015C89.3511 253.414 89.264 253.765 89.0898 254.06C88.9156 254.354 88.6701 254.586 88.3533 254.745C88.0366 254.904 87.6644 254.984 87.2447 254.984C86.635 254.984 86.0728 254.793 85.5422 254.402L85.859 253.868C86.0648 254.036 86.2707 254.163 86.4925 254.243C86.7142 254.323 86.9517 254.362 87.2131 254.362C87.6407 254.362 87.9812 254.243 88.2346 254.004C88.488 253.765 88.6147 253.438 88.6147 253.039V252.457C88.4721 252.713 88.2742 252.904 88.0208 253.039C87.7674 253.175 87.4823 253.247 87.1655 253.247C86.7934 253.247 86.4687 253.159 86.1836 252.991C85.8986 252.824 85.6768 252.585 85.5264 252.282C85.368 251.979 85.2888 251.629 85.2888 251.238C85.2888 250.847 85.368 250.505 85.5264 250.21C85.6848 249.915 85.8986 249.676 86.1836 249.508C86.4687 249.341 86.7934 249.253 87.1655 249.253C87.4902 249.253 87.7832 249.325 88.0287 249.461C88.2742 249.596 88.4721 249.787 88.6147 250.042V249.277H89.3511V249.285ZM87.9812 252.481C88.1791 252.362 88.3375 252.194 88.4484 251.979C88.5592 251.764 88.6147 251.525 88.6147 251.262C88.6147 250.991 88.5592 250.744 88.4484 250.537C88.3375 250.321 88.1791 250.162 87.9812 250.034C87.7832 249.907 87.5536 249.851 87.3002 249.851C87.0468 249.851 86.8171 249.915 86.6192 250.034C86.4212 250.154 86.2628 250.321 86.144 250.537C86.0332 250.752 85.9698 250.991 85.9698 251.262C85.9777 251.668 86.0965 252.003 86.342 252.266C86.5875 252.529 86.9042 252.657 87.2922 252.657C87.5615 252.665 87.7832 252.601 87.9812 252.481Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M93.5639 253.438L92.2414 251.421L91.4337 252.258V253.438H90.7052V247.611H91.4337V251.326L93.4292 249.285H94.2765L92.7403 250.895L94.427 253.438H93.5639Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M97.5311 253.438V252.856C97.2223 253.262 96.7392 253.47 96.0899 253.47C95.8048 253.47 95.5514 253.414 95.3376 253.302C95.1238 253.191 94.9575 253.039 94.8388 252.848C94.72 252.657 94.6646 252.434 94.6646 252.194C94.6646 251.82 94.7992 251.525 95.0763 251.31C95.3535 251.094 95.7336 250.983 96.2166 250.975H97.5311V250.776C97.5311 250.481 97.444 250.258 97.2619 250.098C97.0798 249.939 96.8184 249.859 96.4779 249.859C96.0582 249.859 95.6386 250.01 95.203 250.305L94.9021 249.803C95.1951 249.612 95.4723 249.468 95.7257 249.381C95.9791 249.293 96.28 249.253 96.6284 249.253C97.1431 249.253 97.539 249.381 97.8241 249.636C98.1013 249.891 98.2517 250.242 98.2517 250.704L98.2596 253.438H97.5311ZM97.0956 252.617C97.3332 252.449 97.4757 252.234 97.5232 251.963V251.549H96.3116C95.987 251.549 95.7415 251.597 95.5831 251.7C95.4248 251.796 95.3456 251.955 95.3456 252.171C95.3456 252.386 95.4248 252.553 95.591 252.681C95.7494 252.808 95.9711 252.872 96.2404 252.872C96.573 252.872 96.858 252.784 97.0956 252.617Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M103.193 249.285V253.438H102.457V252.561C102.187 253.151 101.673 253.454 100.928 253.462C100.453 253.462 100.073 253.318 99.8038 253.031C99.5346 252.744 99.4 252.354 99.4 251.852V249.285H100.136V251.66C100.136 252.011 100.231 252.282 100.421 252.481C100.612 252.681 100.873 252.776 101.205 252.776C101.593 252.768 101.894 252.633 102.124 252.362C102.354 252.091 102.464 251.748 102.464 251.326V249.285H103.193Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M106.725 247.946H107.612L109.607 251.812L111.587 247.946H112.482V253.446H111.777L111.769 249.054L109.829 252.864H109.37L107.414 249.054V253.446H106.717V247.946H106.725Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M116.504 253.438V252.856C116.195 253.262 115.712 253.47 115.063 253.47C114.778 253.47 114.525 253.414 114.311 253.302C114.097 253.191 113.931 253.039 113.812 252.848C113.693 252.657 113.638 252.434 113.638 252.194C113.638 251.82 113.772 251.525 114.05 251.31C114.327 251.094 114.707 250.983 115.19 250.975H116.504V250.776C116.504 250.481 116.417 250.258 116.235 250.098C116.053 249.939 115.792 249.859 115.451 249.859C115.031 249.859 114.612 250.01 114.176 250.305L113.875 249.803C114.168 249.612 114.445 249.468 114.699 249.381C114.952 249.293 115.253 249.253 115.602 249.253C116.116 249.253 116.512 249.381 116.797 249.636C117.074 249.891 117.225 250.242 117.225 250.704L117.233 253.438H116.504ZM116.069 252.617C116.306 252.449 116.449 252.234 116.496 251.963V251.549H115.285C114.96 251.549 114.715 251.597 114.556 251.7C114.398 251.796 114.319 251.955 114.319 252.171C114.319 252.386 114.398 252.553 114.564 252.681C114.723 252.808 114.944 252.872 115.214 252.872C115.546 252.872 115.831 252.784 116.069 252.617Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M120.202 249.955C119.996 249.891 119.798 249.859 119.616 249.859C119.395 249.859 119.22 249.899 119.086 249.979C118.951 250.058 118.888 250.186 118.888 250.353C118.888 250.521 118.959 250.648 119.11 250.736C119.26 250.823 119.482 250.911 119.783 250.999C120.068 251.086 120.305 251.166 120.487 251.254C120.67 251.342 120.828 251.461 120.955 251.621C121.081 251.78 121.145 251.995 121.145 252.266C121.145 252.657 120.994 252.96 120.701 253.159C120.408 253.366 120.044 253.462 119.608 253.462C119.3 253.462 119.007 253.414 118.714 253.31C118.429 253.215 118.183 253.071 117.985 252.888L118.254 252.354C118.429 252.513 118.634 252.633 118.888 252.728C119.141 252.824 119.387 252.872 119.624 252.872C119.862 252.872 120.06 252.824 120.202 252.736C120.345 252.649 120.424 252.513 120.424 252.33C120.424 252.139 120.345 252.003 120.194 251.907C120.044 251.812 119.806 251.716 119.498 251.628C119.22 251.549 118.999 251.469 118.824 251.389C118.65 251.31 118.5 251.19 118.381 251.031C118.262 250.871 118.199 250.672 118.199 250.417C118.199 250.026 118.341 249.739 118.619 249.548C118.896 249.357 119.252 249.261 119.672 249.261C119.925 249.261 120.171 249.293 120.408 249.365C120.654 249.437 120.86 249.532 121.034 249.652L120.765 250.21C120.598 250.098 120.408 250.018 120.202 249.955Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M122.998 254.753C122.784 254.912 122.53 255 122.237 255C122.063 255 121.897 254.976 121.754 254.92C121.612 254.865 121.469 254.785 121.335 254.673L121.644 254.099C121.826 254.227 122.008 254.299 122.198 254.299C122.507 254.299 122.744 254.123 122.903 253.765L123.061 253.398L121.351 249.293H122.119L123.433 252.625L124.676 249.293H125.421L123.528 254.036C123.386 254.346 123.211 254.594 122.998 254.753Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M128.493 253.438V252.856C128.184 253.262 127.701 253.47 127.052 253.47C126.767 253.47 126.514 253.414 126.3 253.302C126.086 253.191 125.92 253.039 125.801 252.848C125.682 252.657 125.627 252.434 125.627 252.194C125.627 251.82 125.761 251.525 126.038 251.31C126.316 251.094 126.696 250.983 127.179 250.975H128.493V250.776C128.493 250.481 128.406 250.258 128.224 250.098C128.042 249.939 127.781 249.859 127.44 249.859C127.02 249.859 126.601 250.01 126.165 250.305L125.864 249.803C126.157 249.612 126.434 249.468 126.688 249.381C126.941 249.293 127.242 249.253 127.59 249.253C128.105 249.253 128.501 249.381 128.786 249.636C129.063 249.891 129.214 250.242 129.214 250.704L129.222 253.438H128.493ZM128.066 252.617C128.303 252.449 128.446 252.234 128.493 251.963V251.549H127.282C126.957 251.549 126.712 251.597 126.553 251.7C126.395 251.796 126.316 251.955 126.316 252.171C126.316 252.386 126.395 252.553 126.561 252.681C126.719 252.808 126.941 252.872 127.21 252.872C127.543 252.872 127.828 252.784 128.066 252.617Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M131.811 249.5C132.065 249.349 132.358 249.269 132.698 249.261V249.971C132.278 249.955 131.938 250.066 131.677 250.297C131.415 250.537 131.265 250.847 131.233 251.246V253.438H130.505V249.285H131.233V250.178C131.368 249.883 131.566 249.66 131.811 249.5Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M135.834 253.438V252.856C135.525 253.262 135.042 253.47 134.393 253.47C134.108 253.47 133.854 253.414 133.64 253.302C133.427 253.191 133.26 253.039 133.141 252.848C133.023 252.657 132.967 252.434 132.967 252.194C132.967 251.82 133.102 251.525 133.379 251.31C133.656 251.094 134.036 250.983 134.519 250.975H135.834V250.776C135.834 250.481 135.747 250.258 135.565 250.098C135.382 249.939 135.121 249.859 134.781 249.859C134.361 249.859 133.941 250.01 133.506 250.305L133.205 249.803C133.498 249.612 133.775 249.468 134.028 249.381C134.282 249.293 134.583 249.253 134.931 249.253C135.446 249.253 135.842 249.381 136.127 249.636C136.404 249.891 136.554 250.242 136.554 250.704L136.562 253.438H135.834ZM135.398 252.617C135.636 252.449 135.778 252.234 135.826 251.963V251.549H134.614C134.29 251.549 134.044 251.597 133.886 251.7C133.727 251.796 133.648 251.955 133.648 252.171C133.648 252.386 133.727 252.553 133.894 252.681C134.052 252.808 134.274 252.872 134.543 252.872C134.876 252.872 135.161 252.784 135.398 252.617Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M140.736 253.438L139.413 251.421L138.605 252.258V253.438H137.877V247.611H138.605V251.326L140.601 249.285H141.448L139.912 250.895L141.599 253.438H140.736Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M144.703 253.438V252.856C144.394 253.262 143.911 253.47 143.262 253.47C142.977 253.47 142.723 253.414 142.509 253.302C142.296 253.191 142.129 253.039 142.01 252.848C141.892 252.657 141.836 252.434 141.836 252.194C141.836 251.82 141.971 251.525 142.248 251.31C142.525 251.094 142.905 250.983 143.388 250.975H144.703V250.776C144.703 250.481 144.616 250.258 144.434 250.098C144.251 249.939 143.99 249.859 143.65 249.859C143.23 249.859 142.81 250.01 142.375 250.305L142.074 249.803C142.367 249.612 142.644 249.468 142.897 249.381C143.151 249.293 143.452 249.253 143.8 249.253C144.315 249.253 144.711 249.381 144.996 249.636C145.273 249.891 145.423 250.242 145.423 250.704L145.431 253.438H144.703ZM144.267 252.617C144.505 252.449 144.647 252.234 144.695 251.963V251.549H143.483C143.159 251.549 142.913 251.597 142.755 251.7C142.596 251.796 142.517 251.955 142.517 252.171C142.517 252.386 142.596 252.553 142.763 252.681C142.921 252.808 143.143 252.872 143.412 252.872C143.745 252.872 144.03 252.784 144.267 252.617Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M148.931 253.167C148.615 253.374 148.29 253.47 147.965 253.47C147.641 253.47 147.371 253.374 147.166 253.175C146.96 252.976 146.865 252.681 146.865 252.282V249.979H146.279V249.421H146.865V248.305H147.601V249.421H148.868V249.979H147.601V252.147C147.601 252.378 147.641 252.545 147.728 252.641C147.815 252.736 147.934 252.792 148.1 252.792C148.298 252.792 148.512 252.728 148.741 252.593L148.931 253.167Z"
                        fill="#43556B"
                    />
                    <Path
                        d="M14.5591 224.64C15.6677 225.421 16.2299 226.489 16.2299 227.844C16.2299 228.777 15.9607 229.59 15.4222 230.283C14.8837 230.977 14.1473 231.447 13.2208 231.702C14.3452 231.917 15.2321 232.427 15.8815 233.216C16.5308 234.013 16.8555 234.978 16.8555 236.134C16.8555 237.632 16.2695 238.82 15.0896 239.689C13.9097 240.557 12.3101 240.988 10.2909 240.988H2V223.46H10.0137C11.93 223.46 13.4504 223.851 14.5591 224.64ZM11.5658 230.012C11.9855 229.67 12.1993 229.191 12.1993 228.57C12.1993 227.972 11.9855 227.502 11.5658 227.167C11.1461 226.832 10.5601 226.673 9.81575 226.689H5.93559V230.522H9.81575C10.5601 230.522 11.1382 230.355 11.5658 230.012ZM12.0013 237.194C12.5319 236.819 12.8011 236.293 12.8011 235.632C12.8011 234.994 12.5319 234.5 12.0013 234.133C11.4708 233.766 10.7422 233.591 9.81575 233.607H5.93559V237.76H9.81575C10.7422 237.76 11.4708 237.568 12.0013 237.194Z"
                        fill="#184999"
                    />
                    <Path
                        d="M19.6349 227.517H23.4676V240.988H19.6349V227.517Z"
                        fill="#184999"
                    />
                    <Path
                        d="M39.1783 228.769C40.0652 229.701 40.5086 230.953 40.5086 232.523V240.988H36.676V233.655C36.676 232.802 36.4384 232.132 35.9633 231.638C35.4882 231.144 34.8467 230.897 34.039 230.897C33.0967 230.913 32.3444 231.24 31.798 231.885C31.2516 232.531 30.9745 233.352 30.9745 234.348V240.988H27.1418V227.518H30.9824V229.869C31.9089 228.235 33.453 227.398 35.6069 227.366C37.1036 227.366 38.2914 227.836 39.1783 228.769Z"
                        fill="#184999"
                    />
                    <Path
                        d="M52.1016 240.238C51.6027 240.541 51.0563 240.773 50.4466 240.924C49.8447 241.083 49.235 241.163 48.6173 241.163C47.3741 241.163 46.3605 240.804 45.5845 240.087C44.8084 239.37 44.4125 238.341 44.4125 237.01V230.777H42.5437V228.115H44.4125V224.409H48.2214V228.115H51.8561V230.793H48.2214V236.349C48.2214 237.385 48.6253 237.903 49.4409 237.903C50.0031 237.903 50.6287 237.736 51.3097 237.401L52.1016 240.238Z"
                        fill="#184999"
                    />
                    <Path
                        d="M62.1742 240.988V239.41C61.7228 239.976 61.1448 240.414 60.4321 240.709C59.7194 241.012 58.8958 241.163 57.9694 241.163C56.5598 241.163 55.4433 240.773 54.6118 239.999C53.7804 239.226 53.3686 238.206 53.3686 236.955C53.3686 235.687 53.812 234.699 54.6989 233.99C55.5858 233.28 56.8449 232.914 58.4682 232.898H62.1504V232.651C62.1504 231.933 61.9129 231.375 61.4377 230.985C60.9626 230.594 60.2737 230.395 59.363 230.395C58.1198 230.395 56.7895 230.785 55.3799 231.575L54.1842 228.92C55.2453 228.386 56.2431 227.996 57.1696 227.741C58.0961 227.494 59.1176 227.366 60.2262 227.366C62.0158 227.366 63.4095 227.789 64.3914 228.641C65.3813 229.494 65.8801 230.682 65.896 232.196L65.9197 240.988H62.1742ZM61.1052 237.831C61.6832 237.449 62.0316 236.963 62.1504 236.381V235.177H59.1413C58.397 235.177 57.8427 235.297 57.4863 235.544C57.13 235.783 56.9478 236.174 56.9478 236.708C56.9478 237.226 57.1379 237.64 57.5101 237.943C57.8822 238.254 58.397 238.405 59.0384 238.405C59.8382 238.413 60.5271 238.222 61.1052 237.831Z"
                        fill="#184999"
                    />
                    <Path
                        d="M81.2662 228.769C82.153 229.701 82.5965 230.953 82.5965 232.523V240.988H78.7638V233.655C78.7638 232.802 78.5263 232.132 78.0512 231.638C77.576 231.144 76.9346 230.897 76.1269 230.897C75.1846 230.913 74.4323 231.24 73.8859 231.885C73.3395 232.531 73.0624 233.352 73.0624 234.348V240.988H69.2297V227.518H73.0624V229.869C73.9889 228.235 75.533 227.398 77.6869 227.366C79.1915 227.366 80.3793 227.836 81.2662 228.769Z"
                        fill="#184999"
                    />
                    <Path
                        d="M98.4497 227.518V239.736C98.4497 241.004 98.1488 242.112 97.5549 243.052C96.961 243.993 96.1216 244.718 95.0447 245.228C93.9677 245.738 92.7245 245.993 91.3149 245.993C90.2222 245.993 89.1769 245.834 88.195 245.515C87.2051 245.196 86.3103 244.766 85.4947 244.216L86.9122 241.514C88.1712 242.415 89.557 242.869 91.0695 242.869C92.1623 242.869 93.0333 242.598 93.6826 242.056C94.332 241.514 94.6566 240.781 94.6566 239.864V238.334C94.2053 238.971 93.643 239.449 92.962 239.784C92.281 240.119 91.505 240.286 90.626 240.286C89.4778 240.286 88.4642 240.015 87.5773 239.473C86.6904 238.931 86.0015 238.166 85.5105 237.186C85.0196 236.197 84.7741 235.066 84.7741 233.782C84.7741 232.515 85.0196 231.399 85.5105 230.443C86.0015 229.486 86.6904 228.737 87.5773 228.203C88.4642 227.669 89.4778 227.398 90.626 227.398C91.4892 227.398 92.2573 227.565 92.9383 227.908C93.6193 228.251 94.1894 228.729 94.6566 229.351V227.525H98.4497V227.518ZM93.7697 236.397C94.3478 235.751 94.6408 234.914 94.6408 233.878C94.6408 232.842 94.3478 231.997 93.7697 231.351C93.1917 230.698 92.4315 230.371 91.505 230.371C90.5785 230.371 89.8183 230.698 89.2402 231.351C88.6622 232.005 88.3613 232.842 88.3454 233.878C88.3613 234.914 88.6543 235.751 89.2323 236.397C89.8025 237.042 90.5627 237.361 91.505 237.361C92.4315 237.361 93.1917 237.034 93.7697 236.397Z"
                        fill="#184999"
                    />
                    <Path
                        d="M114.564 225.055C115.776 226.115 116.378 227.613 116.378 229.55C116.378 231.59 115.768 233.169 114.548 234.292C113.329 235.416 111.619 235.982 109.409 235.982H105.949V240.988H102.021V223.46H109.409C111.642 223.46 113.353 223.994 114.564 225.055ZM111.769 231.909C112.355 231.399 112.656 230.65 112.656 229.67C112.656 228.705 112.363 227.972 111.769 227.478C111.183 226.983 110.328 226.736 109.219 226.736H105.957V232.674H109.219C110.328 232.674 111.183 232.419 111.769 231.909Z"
                        fill="#184999"
                    />
                    <Path
                        d="M131.534 227.517V240.988H127.725V238.613C126.814 240.302 125.318 241.139 123.251 241.139C121.778 241.139 120.598 240.677 119.727 239.752C118.856 238.828 118.421 237.568 118.421 235.982V227.517H122.253V234.882C122.253 235.735 122.475 236.405 122.926 236.883C123.378 237.369 123.988 237.608 124.764 237.608C125.674 237.592 126.395 237.265 126.925 236.628C127.456 235.99 127.725 235.177 127.725 234.173V227.51H131.534V227.517Z"
                        fill="#184999"
                    />
                    <Path
                        d="M141.512 230.506C140.799 230.283 140.157 230.172 139.595 230.172C139.128 230.172 138.756 230.251 138.479 230.419C138.194 230.586 138.059 230.833 138.059 231.168C138.059 231.535 138.249 231.822 138.629 232.021C139.009 232.22 139.635 232.443 140.498 232.698C141.44 232.985 142.224 233.264 142.834 233.535C143.444 233.814 143.982 234.221 144.426 234.763C144.877 235.305 145.099 236.022 145.099 236.907C145.099 238.246 144.592 239.282 143.57 240.023C142.549 240.765 141.242 241.139 139.651 241.139C138.558 241.139 137.505 240.98 136.491 240.653C135.478 240.326 134.599 239.864 133.854 239.266L135.097 236.66C135.778 237.194 136.539 237.608 137.37 237.903C138.209 238.198 139.001 238.342 139.746 238.342C140.26 238.342 140.672 238.254 140.981 238.078C141.29 237.903 141.44 237.64 141.44 237.289C141.44 236.907 141.242 236.604 140.854 236.389C140.466 236.174 139.841 235.934 138.978 235.687C138.067 235.424 137.307 235.153 136.705 234.882C136.103 234.619 135.58 234.213 135.153 233.671C134.725 233.129 134.504 232.419 134.504 231.559C134.504 230.22 135.002 229.191 135.992 228.45C136.99 227.717 138.249 227.35 139.777 227.35C140.688 227.35 141.599 227.478 142.517 227.741C143.428 227.996 144.259 228.37 145.004 228.857L143.681 231.487C142.945 231.056 142.224 230.738 141.512 230.506Z"
                        fill="#184999"
                    />
                    <Path
                        d="M159.677 228.769C160.564 229.701 161.007 230.953 161.007 232.523V240.988H157.175V233.655C157.175 232.802 156.937 232.132 156.462 231.638C155.987 231.144 155.346 230.897 154.538 230.897C153.595 230.913 152.843 231.24 152.297 231.885C151.75 232.531 151.473 233.352 151.473 234.348V240.988H147.641V227.518H151.473V229.869C152.4 228.235 153.944 227.398 156.098 227.366C157.602 227.366 158.79 227.836 159.677 228.769Z"
                        fill="#184999"
                    />
                    <Path
                        d="M172.078 240.988V239.41C171.626 239.976 171.048 240.414 170.336 240.709C169.623 241.012 168.799 241.163 167.873 241.163C166.463 241.163 165.347 240.773 164.515 239.999C163.684 239.226 163.272 238.206 163.272 236.955C163.272 235.687 163.716 234.699 164.602 233.99C165.489 233.28 166.748 232.914 168.372 232.898H172.054V232.651C172.054 231.933 171.816 231.375 171.341 230.985C170.866 230.594 170.177 230.395 169.267 230.395C168.023 230.395 166.693 230.785 165.284 231.575L164.088 228.92C165.149 228.386 166.147 227.996 167.073 227.741C168 227.494 169.021 227.366 170.13 227.366C171.919 227.366 173.313 227.789 174.295 228.641C175.285 229.494 175.784 230.682 175.8 232.196L175.823 240.988H172.078ZM171.009 237.831C171.587 237.449 171.935 236.963 172.054 236.381V235.177H169.045C168.301 235.177 167.746 235.297 167.39 235.544C167.034 235.783 166.851 236.174 166.851 236.708C166.851 237.226 167.041 237.64 167.414 237.943C167.786 238.254 168.301 238.405 168.942 238.405C169.742 238.413 170.431 238.222 171.009 237.831Z"
                        fill="#184999"
                    />
                    <Path
                        d="M185.413 230.506C184.7 230.283 184.059 230.172 183.497 230.172C183.029 230.172 182.657 230.251 182.38 230.419C182.095 230.586 181.96 230.833 181.96 231.168C181.96 231.535 182.15 231.822 182.53 232.021C182.911 232.22 183.536 232.443 184.399 232.698C185.342 232.985 186.126 233.264 186.735 233.535C187.345 233.814 187.884 234.221 188.327 234.763C188.778 235.305 189 236.022 189 236.907C189 238.246 188.493 239.282 187.472 240.023C186.45 240.765 185.144 241.139 183.552 241.139C182.459 241.139 181.406 240.98 180.392 240.653C179.379 240.326 178.5 239.864 177.755 239.266L178.999 236.66C179.68 237.194 180.44 237.608 181.271 237.903C182.111 238.198 182.903 238.342 183.647 238.342C184.162 238.342 184.573 238.254 184.882 238.078C185.191 237.903 185.342 237.64 185.342 237.289C185.342 236.907 185.144 236.604 184.756 236.389C184.368 236.174 183.742 235.934 182.879 235.687C181.968 235.424 181.208 235.153 180.606 234.882C180.004 234.619 179.482 234.213 179.054 233.671C178.627 233.129 178.405 232.419 178.405 231.559C178.405 230.22 178.904 229.191 179.894 228.45C180.891 227.717 182.15 227.35 183.679 227.35C184.589 227.35 185.5 227.478 186.419 227.741C187.329 227.996 188.161 228.37 188.905 228.857L187.583 231.487C186.846 231.056 186.126 230.738 185.413 230.506Z"
                        fill="#184999"
                    />
                    <Path
                        d="M21.5513 216C21.7176 216.263 21.876 216.526 22.0423 216.781C22.3432 217.259 22.6599 217.738 22.9054 218.248C23.0638 218.582 23.1905 218.941 23.2301 219.308C23.2617 219.555 23.2538 219.81 23.2142 220.065L23.238 220.057C23.1746 220.105 23.135 220.177 23.0875 220.232C22.6045 220.894 22.1294 221.548 21.6463 222.209C21.6146 222.026 21.5592 221.842 21.48 221.675C21.3454 221.38 21.1474 221.125 20.9495 220.878C20.7198 220.591 20.4823 220.312 20.2526 220.017C20.6882 218.678 21.1158 217.339 21.5513 216Z"
                        fill="#169B48"
                    />
                    <Path
                        d="M23.2459 220.057C23.6498 219.882 24.0932 219.802 24.5367 219.818C25.083 219.826 25.6215 219.945 26.1521 220.065C26.6351 220.177 27.1182 220.272 27.6012 220.368C26.4451 221.213 25.2889 222.05 24.1407 222.887C23.8081 222.775 23.4914 222.632 23.1667 222.512C22.6916 222.329 22.1848 222.161 21.6622 222.225L21.6701 222.217C22.1531 221.563 22.6283 220.902 23.1113 220.24C23.143 220.169 23.1826 220.097 23.2459 220.057Z"
                        fill="#11743B"
                    />
                    <Path
                        d="M15.5014 220.24C16.5308 220.24 17.5682 220.24 18.5976 220.24C19.0252 220.24 19.4608 220.248 19.8884 220.24C20.0467 220.44 20.2051 220.639 20.3635 220.83C20.5535 221.061 20.7436 221.285 20.9099 221.532C21.0603 221.755 21.1791 222.002 21.2266 222.265C21.2504 222.416 21.2425 222.576 21.2583 222.727C21.2583 222.759 21.2345 222.783 21.2187 222.815C20.5694 223.692 19.9359 224.584 19.2945 225.469C18.8352 226.099 18.3838 226.728 17.9245 227.358C17.9008 227.39 17.8849 227.422 17.8533 227.438C17.8612 227.382 17.8849 227.334 17.9008 227.286C18.368 225.86 18.8273 224.425 19.2945 222.998C18.3046 222.281 17.3227 221.563 16.3329 220.838C16.0557 220.647 15.7706 220.448 15.5014 220.24Z"
                        fill="#215FAC"
                    />
                    <Path
                        d="M21.2583 222.743C21.4405 222.695 21.6226 222.648 21.8126 222.648C22.1452 222.632 22.4699 222.719 22.7787 222.831C23.2142 222.982 23.626 223.174 24.0615 223.325C24.5129 224.72 24.9643 226.107 25.4156 227.502C25.1781 227.334 24.9484 227.159 24.7109 226.991C23.6814 226.234 22.652 225.485 21.6226 224.72C20.3556 225.644 19.0965 226.577 17.8295 227.502C17.8295 227.486 17.8374 227.462 17.8374 227.446L17.8454 227.454C17.877 227.43 17.8929 227.398 17.9166 227.374C18.3759 226.744 18.8273 226.115 19.2866 225.485C19.928 224.6 20.5694 223.716 21.2108 222.831C21.2346 222.799 21.2583 222.775 21.2583 222.743Z"
                        fill="#184999"
                    />
                </G>
                <Defs>
                    <ClipPath id="clip0_99_1056">
                        <Rect
                            width={187}
                            height={39}
                            fill="white"
                            transform="translate(2 216)"
                        />
                    </ClipPath>
                </Defs>
            </Svg>
            // <Svg
            //     width={ this.props.width }
            //     height={ this.props.height }
            //     viewBox="0 0 187 39"
            //     fill="none"
            //     xmlns="http://www.w3.org/2000/svg"
            // >
            //     <G clipPath="url(#clip0_10_228)">
            //         <Path
            //             d="M3.69011 32.4323C4.06229 32.759 4.24442 33.2134 4.24442 33.7952C4.24442 34.409 4.05437 34.8872 3.68219 35.2219C3.31001 35.5567 2.77946 35.7321 2.10637 35.7321H0.752276V37.4457H0V31.946H2.10637C2.78738 31.946 3.31793 32.1055 3.69011 32.4323ZM3.15164 34.7357C3.40504 34.5285 3.52382 34.2256 3.52382 33.8191C3.52382 33.4286 3.39712 33.1257 3.15164 32.9264C2.89824 32.7272 2.5419 32.6235 2.0747 32.6235L0.744357 32.6315V35.0466H2.0747C2.5419 35.0466 2.90616 34.943 3.15164 34.7357Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M8.27504 33.8351C8.59971 34.2177 8.766 34.7437 8.766 35.4212C8.766 35.5248 8.766 35.6045 8.75808 35.6603H5.49558C5.55101 36.0349 5.70938 36.3299 5.96278 36.5451C6.21618 36.7682 6.52501 36.8719 6.89719 36.8719C7.14267 36.8719 7.37231 36.824 7.57819 36.7364C7.792 36.6487 7.97413 36.5212 8.12459 36.3617L8.52052 36.7762C8.31463 36.9994 8.06915 37.1668 7.78408 37.2863C7.49901 37.4059 7.18226 37.4697 6.83384 37.4697C6.42998 37.4697 6.06572 37.382 5.74898 37.2066C5.43223 37.0313 5.18675 36.7842 5.01254 36.4654C4.83833 36.1465 4.75122 35.7799 4.75122 35.3734C4.75122 34.9669 4.83833 34.6002 5.01254 34.2894C5.18675 33.9706 5.43223 33.7235 5.74106 33.5402C6.04989 33.3568 6.40623 33.2692 6.81008 33.2692C7.45941 33.2612 7.94246 33.4525 8.27504 33.8351ZM8.10875 35.0865C8.08499 34.7118 7.96621 34.409 7.73657 34.1937C7.50693 33.9785 7.20602 33.867 6.83384 33.867C6.46958 33.867 6.16867 33.9785 5.92319 34.1937C5.67771 34.4169 5.53517 34.7118 5.48766 35.0785H8.10875V35.0865Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M11.0624 33.5003C11.3158 33.3489 11.6088 33.2692 11.9493 33.2612V33.9706C11.5296 33.9547 11.1891 34.0663 10.9278 34.2974C10.6665 34.5365 10.516 34.8474 10.4843 35.2459V37.4378H9.75583V33.2851H10.4843V34.1778C10.619 33.8829 10.809 33.6598 11.0624 33.5003Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M16.0591 33.5322C16.36 33.7076 16.5976 33.9626 16.7639 34.2815C16.9302 34.6003 17.0173 34.9669 17.0173 35.3814C17.0173 35.7879 16.9381 36.1545 16.7718 36.4654C16.6055 36.7842 16.3759 37.0313 16.075 37.2067C15.774 37.382 15.4335 37.4697 15.0455 37.4697C14.6971 37.4697 14.3883 37.398 14.127 37.2465C13.8577 37.103 13.6518 36.8878 13.5014 36.6168V38.9682H12.7729V33.2851H13.5014V34.1141C13.6518 33.8431 13.8577 33.6358 14.119 33.4844C14.3804 33.3409 14.6813 33.2612 15.0297 33.2612C15.4177 33.2612 15.7582 33.3489 16.0591 33.5322ZM15.6078 36.6408C15.8216 36.5132 15.9799 36.3379 16.0987 36.1147C16.2175 35.8915 16.2729 35.6365 16.2729 35.3495C16.2729 35.0706 16.2096 34.8235 16.0908 34.5923C15.972 34.3691 15.8057 34.1938 15.5998 34.0663C15.3939 33.9387 15.1564 33.875 14.8871 33.875C14.6179 33.875 14.3804 33.9387 14.1745 34.0663C13.9607 34.1938 13.8023 34.3691 13.6756 34.5923C13.5568 34.8155 13.4935 35.0706 13.4935 35.3495C13.4935 35.6365 13.5568 35.8915 13.6756 36.1147C13.7944 36.3379 13.9607 36.5132 14.1745 36.6408C14.3883 36.7683 14.6258 36.832 14.8871 36.832C15.1564 36.832 15.3939 36.7683 15.6078 36.6408Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M21.6814 33.2852V37.4378H20.945V36.5611C20.6757 37.1509 20.161 37.4538 19.4166 37.4617C18.9415 37.4617 18.5614 37.3183 18.2922 37.0313C18.023 36.7444 17.8883 36.3538 17.8883 35.8517V33.2852H18.6248V35.6604C18.6248 36.0111 18.7198 36.2821 18.9098 36.4814C19.0999 36.6806 19.3612 36.7763 19.6938 36.7763C20.0818 36.7683 20.3827 36.6328 20.6124 36.3618C20.842 36.0908 20.9529 35.7481 20.9529 35.3256V33.2852H21.6814Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M24.7855 33.9547C24.5796 33.8909 24.3817 33.859 24.1996 33.859C23.9778 33.859 23.8036 33.8989 23.669 33.9786C23.5344 34.0583 23.471 34.1858 23.471 34.3532C23.471 34.5206 23.5423 34.6481 23.6928 34.7358C23.8432 34.8235 24.0649 34.9111 24.3658 34.9988C24.6509 35.0865 24.8885 35.1662 25.0706 35.2539C25.2527 35.3415 25.4111 35.4611 25.5378 35.6205C25.6645 35.7799 25.7279 35.9951 25.7279 36.2661C25.7279 36.6567 25.5774 36.9596 25.2844 37.1588C24.9914 37.3661 24.6272 37.4617 24.1916 37.4617C23.8828 37.4617 23.5898 37.4139 23.2968 37.3103C23.0117 37.2146 22.7663 37.0712 22.5683 36.8878L22.8375 36.3538C23.0117 36.5132 23.2176 36.6328 23.471 36.7284C23.7244 36.8241 23.9699 36.8719 24.2075 36.8719C24.445 36.8719 24.643 36.8241 24.7855 36.7364C24.9281 36.6487 25.0073 36.5132 25.0073 36.3299C25.0073 36.1386 24.9281 36.0031 24.7776 35.9075C24.6272 35.8118 24.3896 35.7162 24.0808 35.6285C23.8036 35.5488 23.5819 35.4691 23.4077 35.3894C23.2335 35.3097 23.083 35.1901 22.9642 35.0307C22.8455 34.8713 22.7821 34.672 22.7821 34.417C22.7821 34.0264 22.9246 33.7395 23.2018 33.5482C23.4789 33.3569 23.8353 33.2612 24.255 33.2612C24.5084 33.2612 24.7539 33.2931 24.9914 33.3648C25.2369 33.4366 25.4428 33.5322 25.617 33.6518L25.3478 34.2097C25.1815 34.0981 24.9914 34.0184 24.7855 33.9547Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M28.9033 37.1669C28.5865 37.3741 28.2618 37.4697 27.9372 37.4697C27.6125 37.4697 27.3433 37.3741 27.1374 37.1748C26.9315 36.9756 26.8365 36.6807 26.8365 36.2821V33.9786H26.2505V33.4207H26.8365V32.3048H27.5729V33.4207H28.8399V33.9786H27.5729V36.1466C27.5729 36.3778 27.6125 36.5452 27.6996 36.6408C27.7867 36.7364 27.9055 36.7922 28.0718 36.7922C28.2698 36.7922 28.4836 36.7285 28.7132 36.593L28.9033 37.1669Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M32.3003 37.4378V36.856C31.9915 37.2625 31.5085 37.4697 30.8591 37.4697C30.5741 37.4697 30.3207 37.4139 30.1069 37.3023C29.8931 37.1908 29.7268 37.0393 29.608 36.848C29.4892 36.6567 29.4338 36.4336 29.4338 36.1944C29.4338 35.8198 29.5684 35.5249 29.8455 35.3097C30.1227 35.0945 30.5028 34.9829 30.9858 34.9749H32.3003V34.7757C32.3003 34.4808 32.2132 34.2576 32.0311 34.0982C31.849 33.9388 31.5877 33.8591 31.2472 33.8591C30.8275 33.8591 30.4078 34.0105 29.9722 34.3054L29.6713 33.8033C29.9643 33.612 30.2415 33.4685 30.4949 33.3808C30.7483 33.2931 31.0492 33.2533 31.3976 33.2533C31.9123 33.2533 32.3083 33.3808 32.5933 33.6359C32.8705 33.8909 33.0209 34.2416 33.0209 34.7039L33.0289 37.4378H32.3003ZM31.8727 36.6169C32.1103 36.4495 32.2528 36.2343 32.3003 35.9633V35.5488H31.0888C30.7641 35.5488 30.5186 35.5966 30.3603 35.7003C30.2019 35.7959 30.1227 35.9553 30.1227 36.1705C30.1227 36.3857 30.2019 36.5531 30.3682 36.6806C30.5266 36.8082 30.7483 36.8719 31.0175 36.8719C31.3422 36.8719 31.6352 36.7843 31.8727 36.6169Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M37.202 37.4378L35.8796 35.4212L35.0798 36.2661V37.4457H34.3513V31.6193H35.0798V35.3335L37.0754 33.2931H37.9306L36.3943 34.9031L38.081 37.4457H37.202V37.4378Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M41.1693 37.4378V36.856C40.8605 37.2625 40.3774 37.4697 39.7281 37.4697C39.443 37.4697 39.1896 37.4139 38.9758 37.3023C38.762 37.1908 38.5957 37.0393 38.4769 36.848C38.3582 36.6567 38.3027 36.4336 38.3027 36.1944C38.3027 35.8198 38.4374 35.5249 38.7145 35.3097C38.9917 35.0945 39.3718 34.9829 39.8548 34.9749H41.1693V34.7757C41.1693 34.4808 41.0822 34.2576 40.9001 34.0982C40.7179 33.9388 40.4566 33.8591 40.1161 33.8591C39.6964 33.8591 39.2767 34.0105 38.8412 34.3054L38.5403 33.8033C38.8333 33.612 39.1104 33.4685 39.3638 33.3808C39.6172 33.2931 39.9181 33.2533 40.2666 33.2533C40.7813 33.2533 41.1772 33.3808 41.4623 33.6359C41.7394 33.8909 41.8899 34.2416 41.8899 34.7039L41.8978 37.4378H41.1693ZM40.7338 36.6169C40.9713 36.4495 41.1139 36.2343 41.1614 35.9633V35.5488H39.9498C39.6252 35.5488 39.3797 35.5966 39.2213 35.7003C39.0629 35.7959 38.9837 35.9553 38.9837 36.1705C38.9837 36.3857 39.0629 36.5531 39.2292 36.6806C39.3876 36.8082 39.6093 36.8719 39.8786 36.8719C40.2111 36.8719 40.4962 36.7843 40.7338 36.6169Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M45.7067 37.4378V36.856C45.3979 37.2625 44.9148 37.4697 44.2655 37.4697C43.9804 37.4697 43.727 37.4139 43.5132 37.3023C43.2994 37.1908 43.1331 37.0393 43.0144 36.848C42.8956 36.6567 42.8401 36.4336 42.8401 36.1944C42.8401 35.8198 42.9748 35.5249 43.2519 35.3097C43.5291 35.0945 43.9092 34.9829 44.3922 34.9749H45.7067V34.7757C45.7067 34.4808 45.6196 34.2576 45.4375 34.0982C45.2554 33.9388 44.994 33.8591 44.6535 33.8591C44.2338 33.8591 43.8141 34.0105 43.3786 34.3054L43.0777 33.8033C43.3707 33.612 43.6479 33.4685 43.9013 33.3808C44.1547 33.2931 44.4556 33.2533 44.804 33.2533C45.3187 33.2533 45.7146 33.3808 45.9997 33.6359C46.2769 33.8909 46.4273 34.2416 46.4273 34.7039L46.4352 37.4378H45.7067ZM45.2712 36.6169C45.5088 36.4495 45.6513 36.2343 45.6988 35.9633V35.5488H44.4872C44.1626 35.5488 43.9171 35.5966 43.7587 35.7003C43.6003 35.7959 43.5212 35.9553 43.5212 36.1705C43.5212 36.3857 43.6003 36.5531 43.7666 36.6806C43.925 36.8082 44.1467 36.8719 44.416 36.8719C44.7486 36.8719 45.0336 36.7843 45.2712 36.6169Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M51.1548 33.6917C51.4319 33.9786 51.5665 34.3692 51.5665 34.8713V37.4378H50.838V35.0706C50.838 34.7199 50.743 34.4489 50.545 34.2496C50.3471 34.0504 50.0778 33.9547 49.7373 33.9547C49.3651 33.9627 49.0642 34.0822 48.8346 34.3134C48.605 34.5445 48.4703 34.8474 48.4387 35.222V37.4458H47.7101V33.2931H48.4387V34.1699C48.7158 33.5801 49.2385 33.2772 50.0066 33.2692C50.4975 33.2613 50.8776 33.4047 51.1548 33.6917Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M54.9716 31.946H55.8585L57.854 35.8118L59.8337 31.946H60.7285V37.4457H60.0237L60.0158 33.054L58.0757 36.8639H57.6164L55.6684 33.054V37.4457H54.9716V31.946Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M65.3371 33.8351C65.6618 34.2177 65.8281 34.7437 65.8281 35.4212C65.8281 35.5248 65.8281 35.6045 65.8202 35.6603H62.5577C62.6131 36.0349 62.7715 36.3299 63.0249 36.5451C63.2783 36.7682 63.5871 36.8719 63.9593 36.8719C64.2048 36.8719 64.4344 36.824 64.6403 36.7364C64.8541 36.6487 65.0362 36.5212 65.1867 36.3617L65.5826 36.7762C65.3767 36.9994 65.1313 37.1668 64.8462 37.2863C64.5611 37.4059 64.2444 37.4697 63.8959 37.4697C63.4921 37.4697 63.1278 37.382 62.8111 37.2066C62.4943 37.0313 62.2489 36.7842 62.0746 36.4654C61.9004 36.1465 61.8133 35.7799 61.8133 35.3734C61.8133 34.9669 61.9004 34.6002 62.0746 34.2894C62.2489 33.9706 62.4943 33.7235 62.8032 33.5402C63.112 33.3568 63.4683 33.2692 63.8722 33.2692C64.5294 33.2612 65.0125 33.4525 65.3371 33.8351ZM65.1788 35.0865C65.155 34.7118 65.0362 34.409 64.8066 34.1937C64.577 33.9785 64.276 33.867 63.9039 33.867C63.5396 33.867 63.2387 33.9785 62.9932 34.1937C62.7477 34.4169 62.6052 34.7118 62.5577 35.0785H65.1788V35.0865Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M70.2547 33.6917C70.5318 33.9786 70.6664 34.3692 70.6664 34.8713V37.4378H69.9379V35.0706C69.9379 34.7199 69.8429 34.4489 69.6449 34.2496C69.447 34.0504 69.1777 33.9547 68.8372 33.9547C68.465 33.9627 68.1641 34.0822 67.9345 34.3134C67.7048 34.5445 67.5702 34.8474 67.5385 35.222V37.4458H66.81V33.2931H67.5385V34.1699C67.8157 33.5801 68.3383 33.2772 69.1064 33.2692C69.5974 33.2613 69.9775 33.4047 70.2547 33.6917Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M71.3395 38.3704C71.5375 38.3784 71.688 38.3067 71.7909 38.1632C71.8938 38.0197 71.9493 37.8125 71.9493 37.5415V33.2852H72.6857V37.6451C72.6857 38.0755 72.5828 38.4023 72.3769 38.6414C72.171 38.8726 71.878 38.9921 71.5058 38.9921C71.2683 38.9921 71.0386 38.9443 70.7932 38.8487L70.9674 38.2907C71.0782 38.3306 71.2049 38.3545 71.3395 38.3704ZM72.6303 31.7628C72.7174 31.8505 72.757 31.9621 72.757 32.0976C72.757 32.2331 72.7174 32.3447 72.6303 32.4323C72.5432 32.52 72.4402 32.5678 72.3056 32.5678C72.1789 32.5678 72.0681 32.52 71.981 32.4323C71.8939 32.3447 71.8543 32.2331 71.8543 32.0976C71.8543 31.9621 71.8939 31.8505 71.981 31.7628C72.0681 31.6751 72.171 31.6273 72.3056 31.6273C72.4402 31.6273 72.5511 31.6751 72.6303 31.7628Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M76.5421 37.4378V36.856C76.2333 37.2625 75.7502 37.4697 75.1009 37.4697C74.8158 37.4697 74.5624 37.4139 74.3486 37.3023C74.1348 37.1908 73.9685 37.0393 73.8497 36.848C73.731 36.6567 73.6755 36.4336 73.6755 36.1944C73.6755 35.8198 73.8102 35.5249 74.0873 35.3097C74.3645 35.0945 74.7446 34.9829 75.2276 34.9749H76.5421V34.7757C76.5421 34.4808 76.455 34.2576 76.2729 34.0982C76.0907 33.9388 75.8294 33.8591 75.4889 33.8591C75.0692 33.8591 74.6495 34.0105 74.214 34.3054L73.9131 33.8033C74.2061 33.612 74.4832 33.4685 74.7366 33.3808C74.99 33.2931 75.291 33.2533 75.6394 33.2533C76.1541 33.2533 76.55 33.3808 76.8351 33.6359C77.1122 33.8909 77.2627 34.2416 77.2627 34.7039L77.2706 37.4378H76.5421ZM76.1066 36.6169C76.3441 36.4495 76.4867 36.2343 76.5342 35.9633V35.5488H75.3226C74.998 35.5488 74.7525 35.5966 74.5941 35.7003C74.4357 35.7959 74.3565 35.9553 74.3565 36.1705C74.3565 36.3857 74.4357 36.5531 74.602 36.6806C74.7604 36.8082 74.9821 36.8719 75.2514 36.8719C75.5839 36.8719 75.869 36.7843 76.1066 36.6169Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M81.9902 33.6917C82.2673 33.9786 82.402 34.3692 82.402 34.8713V37.4378H81.6734V35.0706C81.6734 34.7199 81.5784 34.4489 81.3804 34.2496C81.1825 34.0504 80.9132 33.9547 80.5727 33.9547C80.2006 33.9627 79.8996 34.0822 79.67 34.3134C79.4404 34.5445 79.3057 34.8474 79.2741 35.222V37.4458H78.5535V33.2931H79.282V34.1699C79.5591 33.5801 80.0818 33.2772 80.8499 33.2692C81.3329 33.2613 81.713 33.4047 81.9902 33.6917Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M87.3511 33.2852V37.0154C87.3511 37.4139 87.264 37.7646 87.0898 38.0595C86.9156 38.3545 86.6701 38.5856 86.3534 38.745C86.0366 38.9044 85.6645 38.9841 85.2448 38.9841C84.635 38.9841 84.0728 38.7928 83.5422 38.4023L83.859 37.8683C84.0649 38.0356 84.2708 38.1632 84.4925 38.2429C84.7142 38.3226 84.9518 38.3624 85.2131 38.3624C85.6407 38.3624 85.9812 38.2429 86.2346 38.0038C86.488 37.7646 86.6147 37.4378 86.6147 37.0393V36.4575C86.4722 36.7125 86.2742 36.9038 86.0208 37.0393C85.7674 37.1748 85.4823 37.2465 85.1656 37.2465C84.7934 37.2465 84.4687 37.1589 84.1837 36.9915C83.8986 36.8241 83.6769 36.585 83.5264 36.2821C83.368 35.9792 83.2888 35.6285 83.2888 35.238C83.2888 34.8474 83.368 34.5047 83.5264 34.2098C83.6848 33.9149 83.8986 33.6757 84.1837 33.5084C84.4687 33.341 84.7934 33.2533 85.1656 33.2533C85.4902 33.2533 85.7832 33.325 86.0287 33.4605C86.2742 33.596 86.4722 33.7873 86.6147 34.0424V33.2772H87.3511V33.2852ZM85.9812 36.4814C86.1792 36.3618 86.3375 36.1944 86.4484 35.9792C86.5593 35.764 86.6147 35.5249 86.6147 35.2619C86.6147 34.9909 86.5593 34.7438 86.4484 34.5366C86.3375 34.3214 86.1792 34.1619 85.9812 34.0344C85.7832 33.9069 85.5536 33.8511 85.3002 33.8511C85.0468 33.8511 84.8172 33.9149 84.6192 34.0344C84.4212 34.154 84.2628 34.3214 84.1441 34.5366C84.0332 34.7518 83.9699 34.9909 83.9699 35.2619C83.9778 35.6684 84.0965 36.0031 84.342 36.2662C84.5875 36.5292 84.9043 36.6567 85.2923 36.6567C85.5615 36.6647 85.7832 36.6009 85.9812 36.4814Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M91.5639 37.4378L90.2415 35.4213L89.4338 36.2582V37.4378H88.7052V31.6113H89.4338V35.3256L91.4293 33.2851H92.2766L90.7403 34.8952L92.427 37.4378H91.5639Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M95.5312 37.4378V36.856C95.2223 37.2625 94.7393 37.4697 94.0899 37.4697C93.8049 37.4697 93.5515 37.4139 93.3377 37.3023C93.1239 37.1908 92.9576 37.0393 92.8388 36.848C92.72 36.6567 92.6646 36.4336 92.6646 36.1944C92.6646 35.8198 92.7992 35.5249 93.0764 35.3097C93.3535 35.0945 93.7336 34.9829 94.2167 34.9749H95.5312V34.7757C95.5312 34.4808 95.444 34.2576 95.2619 34.0982C95.0798 33.9388 94.8185 33.8591 94.478 33.8591C94.0583 33.8591 93.6386 34.0105 93.2031 34.3054L92.9021 33.8033C93.1951 33.612 93.4723 33.4685 93.7257 33.3808C93.9791 33.2931 94.28 33.2533 94.6284 33.2533C95.1431 33.2533 95.5391 33.3808 95.8241 33.6359C96.1013 33.8909 96.2518 34.2416 96.2518 34.7039L96.2597 37.4378H95.5312ZM95.0956 36.6169C95.3332 36.4495 95.4757 36.2343 95.5232 35.9633V35.5488H94.3117C93.987 35.5488 93.7415 35.5966 93.5832 35.7003C93.4248 35.7959 93.3456 35.9553 93.3456 36.1705C93.3456 36.3857 93.4248 36.5531 93.5911 36.6806C93.7494 36.8082 93.9712 36.8719 94.2404 36.8719C94.573 36.8719 94.8581 36.7843 95.0956 36.6169Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M101.193 33.2852V37.4378H100.457V36.5611C100.187 37.1509 99.6726 37.4538 98.9283 37.4617C98.4532 37.4617 98.0731 37.3183 97.8038 37.0313C97.5346 36.7444 97.4 36.3538 97.4 35.8517V33.2852H98.1364V35.6604C98.1364 36.0111 98.2314 36.2821 98.4215 36.4814C98.6115 36.6806 98.8728 36.7763 99.2054 36.7763C99.5934 36.7683 99.8944 36.6328 100.124 36.3618C100.354 36.0908 100.464 35.7481 100.464 35.3256V33.2852H101.193Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M104.725 31.946H105.612L107.607 35.8118L109.587 31.946H110.482V37.4457H109.777L109.769 33.054L107.829 36.8639H107.37L105.414 33.054V37.4457H104.717V31.946H104.725Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M114.504 37.4378V36.856C114.196 37.2625 113.712 37.4697 113.063 37.4697C112.778 37.4697 112.525 37.4139 112.311 37.3023C112.097 37.1908 111.931 37.0393 111.812 36.848C111.693 36.6567 111.638 36.4336 111.638 36.1944C111.638 35.8198 111.772 35.5249 112.05 35.3097C112.327 35.0945 112.707 34.9829 113.19 34.9749H114.504V34.7757C114.504 34.4808 114.417 34.2576 114.235 34.0982C114.053 33.9388 113.792 33.8591 113.451 33.8591C113.031 33.8591 112.612 34.0105 112.176 34.3054L111.875 33.8033C112.168 33.612 112.445 33.4685 112.699 33.3808C112.952 33.2931 113.253 33.2533 113.602 33.2533C114.116 33.2533 114.512 33.3808 114.797 33.6359C115.074 33.8909 115.225 34.2416 115.225 34.7039L115.233 37.4378H114.504ZM114.069 36.6169C114.306 36.4495 114.449 36.2343 114.496 35.9633V35.5488H113.285C112.96 35.5488 112.715 35.5966 112.556 35.7003C112.398 35.7959 112.319 35.9553 112.319 36.1705C112.319 36.3857 112.398 36.5531 112.564 36.6806C112.723 36.8082 112.944 36.8719 113.214 36.8719C113.546 36.8719 113.831 36.7843 114.069 36.6169Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M118.202 33.9547C117.996 33.8909 117.799 33.859 117.616 33.859C117.395 33.859 117.22 33.8989 117.086 33.9786C116.951 34.0583 116.888 34.1858 116.888 34.3532C116.888 34.5206 116.959 34.6481 117.11 34.7358C117.26 34.8235 117.482 34.9111 117.783 34.9988C118.068 35.0865 118.305 35.1662 118.487 35.2539C118.67 35.3415 118.828 35.4611 118.955 35.6205C119.081 35.7799 119.145 35.9951 119.145 36.2661C119.145 36.6567 118.994 36.9596 118.701 37.1588C118.408 37.3661 118.044 37.4617 117.608 37.4617C117.3 37.4617 117.007 37.4139 116.714 37.3103C116.429 37.2146 116.183 37.0712 115.985 36.8878L116.254 36.3538C116.429 36.5132 116.634 36.6328 116.888 36.7284C117.141 36.8241 117.387 36.8719 117.624 36.8719C117.862 36.8719 118.06 36.8241 118.202 36.7364C118.345 36.6487 118.424 36.5132 118.424 36.3299C118.424 36.1386 118.345 36.0031 118.194 35.9075C118.044 35.8118 117.806 35.7162 117.498 35.6285C117.22 35.5488 116.999 35.4691 116.825 35.3894C116.65 35.3097 116.5 35.1901 116.381 35.0307C116.262 34.8713 116.199 34.672 116.199 34.417C116.199 34.0264 116.341 33.7395 116.619 33.5482C116.896 33.3569 117.252 33.2612 117.672 33.2612C117.925 33.2612 118.171 33.2931 118.408 33.3648C118.654 33.4366 118.86 33.5322 119.034 33.6518L118.765 34.2097C118.598 34.0981 118.408 34.0184 118.202 33.9547Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M120.998 38.7529C120.784 38.9123 120.53 39 120.237 39C120.063 39 119.897 38.9761 119.754 38.9203C119.612 38.8645 119.469 38.7848 119.335 38.6732L119.644 38.0993C119.826 38.2269 120.008 38.2986 120.198 38.2986C120.507 38.2986 120.744 38.1233 120.903 37.7646L121.061 37.3979L119.351 33.2931H120.119L121.433 36.6248L122.676 33.2931H123.421L121.528 38.0356C121.386 38.3464 121.211 38.5935 120.998 38.7529Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M126.493 37.4378V36.856C126.184 37.2625 125.701 37.4697 125.052 37.4697C124.767 37.4697 124.514 37.4139 124.3 37.3023C124.086 37.1908 123.92 37.0393 123.801 36.848C123.682 36.6567 123.627 36.4336 123.627 36.1944C123.627 35.8198 123.761 35.5249 124.038 35.3097C124.316 35.0945 124.696 34.9829 125.179 34.9749H126.493V34.7757C126.493 34.4808 126.406 34.2576 126.224 34.0982C126.042 33.9388 125.781 33.8591 125.44 33.8591C125.02 33.8591 124.601 34.0105 124.165 34.3054L123.864 33.8033C124.157 33.612 124.434 33.4685 124.688 33.3808C124.941 33.2931 125.242 33.2533 125.591 33.2533C126.105 33.2533 126.501 33.3808 126.786 33.6359C127.063 33.8909 127.214 34.2416 127.214 34.7039L127.222 37.4378H126.493ZM126.066 36.6169C126.303 36.4495 126.446 36.2343 126.493 35.9633V35.5488H125.282C124.957 35.5488 124.712 35.5966 124.553 35.7003C124.395 35.7959 124.316 35.9553 124.316 36.1705C124.316 36.3857 124.395 36.5531 124.561 36.6806C124.719 36.8082 124.941 36.8719 125.21 36.8719C125.543 36.8719 125.828 36.7843 126.066 36.6169Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M129.811 33.5003C130.065 33.3489 130.358 33.2692 130.698 33.2612V33.9706C130.278 33.9547 129.938 34.0663 129.677 34.2974C129.415 34.5365 129.265 34.8474 129.233 35.2459V37.4378H128.505V33.2851H129.233V34.1778C129.368 33.8829 129.566 33.6598 129.811 33.5003Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M133.834 37.4378V36.856C133.525 37.2625 133.042 37.4697 132.393 37.4697C132.108 37.4697 131.854 37.4139 131.64 37.3023C131.427 37.1908 131.26 37.0393 131.141 36.848C131.023 36.6567 130.967 36.4336 130.967 36.1944C130.967 35.8198 131.102 35.5249 131.379 35.3097C131.656 35.0945 132.036 34.9829 132.519 34.9749H133.834V34.7757C133.834 34.4808 133.747 34.2576 133.565 34.0982C133.382 33.9388 133.121 33.8591 132.781 33.8591C132.361 33.8591 131.941 34.0105 131.506 34.3054L131.205 33.8033C131.498 33.612 131.775 33.4685 132.028 33.3808C132.282 33.2931 132.583 33.2533 132.931 33.2533C133.446 33.2533 133.842 33.3808 134.127 33.6359C134.404 33.8909 134.554 34.2416 134.554 34.7039L134.562 37.4378H133.834ZM133.398 36.6169C133.636 36.4495 133.778 36.2343 133.826 35.9633V35.5488H132.614C132.29 35.5488 132.044 35.5966 131.886 35.7003C131.727 35.7959 131.648 35.9553 131.648 36.1705C131.648 36.3857 131.727 36.5531 131.894 36.6806C132.052 36.8082 132.274 36.8719 132.543 36.8719C132.876 36.8719 133.161 36.7843 133.398 36.6169Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M138.736 37.4378L137.413 35.4213L136.605 36.2582V37.4378H135.877V31.6113H136.605V35.3256L138.601 33.2851H139.448L137.912 34.8952L139.599 37.4378H138.736Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M142.703 37.4378V36.856C142.394 37.2625 141.911 37.4697 141.262 37.4697C140.977 37.4697 140.723 37.4139 140.509 37.3023C140.296 37.1908 140.129 37.0393 140.01 36.848C139.892 36.6567 139.836 36.4336 139.836 36.1944C139.836 35.8198 139.971 35.5249 140.248 35.3097C140.525 35.0945 140.905 34.9829 141.388 34.9749H142.703V34.7757C142.703 34.4808 142.616 34.2576 142.434 34.0982C142.251 33.9388 141.99 33.8591 141.65 33.8591C141.23 33.8591 140.81 34.0105 140.375 34.3054L140.074 33.8033C140.367 33.612 140.644 33.4685 140.897 33.3808C141.151 33.2931 141.452 33.2533 141.8 33.2533C142.315 33.2533 142.711 33.3808 142.996 33.6359C143.273 33.8909 143.423 34.2416 143.423 34.7039L143.431 37.4378H142.703ZM142.267 36.6169C142.505 36.4495 142.647 36.2343 142.695 35.9633V35.5488H141.483C141.159 35.5488 140.913 35.5966 140.755 35.7003C140.596 35.7959 140.517 35.9553 140.517 36.1705C140.517 36.3857 140.596 36.5531 140.763 36.6806C140.921 36.8082 141.143 36.8719 141.412 36.8719C141.745 36.8719 142.03 36.7843 142.267 36.6169Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M146.931 37.1669C146.615 37.3741 146.29 37.4697 145.965 37.4697C145.641 37.4697 145.371 37.3741 145.166 37.1748C144.96 36.9756 144.865 36.6807 144.865 36.2821V33.9786H144.279V33.4207H144.865V32.3048H145.601V33.4207H146.868V33.9786H145.601V36.1466C145.601 36.3778 145.641 36.5452 145.728 36.6408C145.815 36.7364 145.934 36.7922 146.1 36.7922C146.298 36.7922 146.512 36.7285 146.741 36.593L146.931 37.1669Z"
            //             fill="#43556B"
            //         />
            //         <Path
            //             d="M12.5591 8.64009C13.6677 9.42121 14.2299 10.4893 14.2299 11.8443C14.2299 12.7768 13.9607 13.5898 13.4222 14.2833C12.8837 14.9767 12.1473 15.447 11.2208 15.702C12.3452 15.9172 13.2321 16.4273 13.8815 17.2164C14.5308 18.0135 14.8555 18.9779 14.8555 20.1337C14.8555 21.6321 14.2695 22.8197 13.0896 23.6885C11.9097 24.5573 10.3101 24.9877 8.29087 24.9877H0V7.46045H8.01372C9.93004 7.46045 11.4504 7.85101 12.5591 8.64009ZM9.56578 14.0123C9.98548 13.6695 10.1993 13.1913 10.1993 12.5696C10.1993 11.9718 9.98548 11.5015 9.56578 11.1668C9.14609 10.832 8.56011 10.6726 7.81575 10.6885H3.93559V14.5224H7.81575C8.56011 14.5224 9.13818 14.355 9.56578 14.0123ZM10.0013 21.1937C10.5319 20.8191 10.8011 20.2931 10.8011 19.6315C10.8011 18.9939 10.5319 18.4997 10.0013 18.133C9.47076 17.7664 8.74224 17.591 7.81575 17.607H3.93559V21.7597H7.81575C8.74224 21.7597 9.47076 21.5684 10.0013 21.1937Z"
            //             fill="#184999"
            //         />
            //         <Path
            //             d="M17.6349 11.5175H21.4676V24.9877H17.6349V11.5175Z"
            //             fill="#184999"
            //         />
            //         <Path
            //             d="M37.1783 12.7689C38.0652 13.7015 38.5086 14.9528 38.5086 16.523V24.9878H34.676V17.6549C34.676 16.802 34.4384 16.1325 33.9633 15.6383C33.4882 15.1441 32.8467 14.8971 32.039 14.8971C31.0967 14.913 30.3444 15.2398 29.798 15.8854C29.2516 16.531 28.9745 17.352 28.9745 18.3483V24.9878H25.1418V11.5175H28.9824V13.8688C29.9089 12.2349 31.453 11.398 33.6069 11.3661C35.1036 11.3661 36.2914 11.8364 37.1783 12.7689Z"
            //             fill="#184999"
            //         />
            //         <Path
            //             d="M50.1016 24.2385C49.6027 24.5414 49.0563 24.7725 48.4466 24.924C47.8448 25.0834 47.235 25.1631 46.6174 25.1631C45.3741 25.1631 44.3605 24.8044 43.5845 24.087C42.8085 23.3697 42.4125 22.3415 42.4125 21.0104V14.7774H40.5437V12.1153H42.4125V8.40894H46.2214V12.1153H49.8561V14.7934H46.2214V20.3489C46.2214 21.385 46.6253 21.9031 47.4409 21.9031C48.0031 21.9031 48.6287 21.7357 49.3097 21.401L50.1016 24.2385Z"
            //             fill="#184999"
            //         />
            //         <Path
            //             d="M60.1742 24.9878V23.4096C59.7228 23.9755 59.1448 24.4139 58.4321 24.7088C57.7194 25.0117 56.8958 25.1631 55.9694 25.1631C54.5598 25.1631 53.4433 24.7726 52.6118 23.9994C51.7804 23.2263 51.3686 22.2061 51.3686 20.9547C51.3686 19.6874 51.812 18.699 52.6989 17.9896C53.5858 17.2803 54.8449 16.9136 56.4682 16.8977H60.1504V16.6506C60.1504 15.9332 59.9129 15.3753 59.4377 14.9847C58.9626 14.5942 58.2737 14.3949 57.363 14.3949C56.1198 14.3949 54.7895 14.7855 53.3799 15.5745L52.1842 12.9204C53.2453 12.3863 54.2431 11.9958 55.1696 11.7407C56.0961 11.4936 57.1176 11.3661 58.2262 11.3661C60.0158 11.3661 61.4095 11.7885 62.3914 12.6414C63.3813 13.4942 63.8801 14.6818 63.896 16.1963L63.9197 24.9878H60.1742ZM59.1052 21.8314C59.6832 21.4489 60.0316 20.9627 60.1504 20.3808V19.1772H57.1413C56.397 19.1772 55.8427 19.2968 55.4863 19.5439C55.13 19.783 54.9478 20.1736 54.9478 20.7076C54.9478 21.2257 55.1379 21.6402 55.5101 21.943C55.8822 22.2539 56.397 22.4053 57.0384 22.4053C57.8382 22.4133 58.5271 22.222 59.1052 21.8314Z"
            //             fill="#184999"
            //         />
            //         <Path
            //             d="M79.2662 12.7689C80.153 13.7015 80.5965 14.9528 80.5965 16.523V24.9878H76.7638V17.6549C76.7638 16.802 76.5263 16.1325 76.0512 15.6383C75.576 15.1441 74.9346 14.8971 74.1269 14.8971C73.1846 14.913 72.4323 15.2398 71.8859 15.8854C71.3395 16.531 71.0624 17.352 71.0624 18.3483V24.9878H67.2297V11.5175H71.0624V13.8688C71.9889 12.2349 73.533 11.398 75.6869 11.3661C77.1915 11.3661 78.3793 11.8364 79.2662 12.7689Z"
            //             fill="#184999"
            //         />
            //         <Path
            //             d="M96.4497 11.5175V23.7364C96.4497 25.0037 96.1488 26.1116 95.5549 27.0521C94.961 27.9927 94.1216 28.718 93.0447 29.2281C91.9677 29.7382 90.7245 29.9933 89.315 29.9933C88.2222 29.9933 87.1769 29.8339 86.195 29.5151C85.2052 29.1962 84.3104 28.7658 83.4947 28.2159L84.9122 25.5138C86.1713 26.4145 87.557 26.8688 89.0695 26.8688C90.1623 26.8688 91.0333 26.5978 91.6827 26.0558C92.332 25.5138 92.6567 24.7805 92.6567 23.8639V22.3336C92.2053 22.9712 91.6431 23.4495 90.9621 23.7842C90.2811 24.119 89.505 24.2864 88.626 24.2864C87.4778 24.2864 86.4642 24.0154 85.5774 23.4734C84.6905 22.9314 84.0015 22.1662 83.5106 21.1858C83.0196 20.1975 82.7741 19.0656 82.7741 17.7824C82.7741 16.5151 83.0196 15.3992 83.5106 14.4427C84.0015 13.4862 84.6905 12.737 85.5774 12.203C86.4642 11.6689 87.4778 11.3979 88.626 11.3979C89.4892 11.3979 90.2573 11.5653 90.9383 11.9081C91.6193 12.2508 92.1895 12.729 92.6567 13.3507V11.5255H96.4497V11.5175ZM91.7698 20.3967C92.3478 19.7511 92.6408 18.9142 92.6408 17.878C92.6408 16.8418 92.3478 15.997 91.7698 15.3514C91.1917 14.6978 90.4315 14.371 89.505 14.371C88.5785 14.371 87.8183 14.6978 87.2403 15.3514C86.6622 16.0049 86.3613 16.8418 86.3455 17.878C86.3613 18.9142 86.6543 19.7511 87.2324 20.3967C87.8025 21.0423 88.5627 21.3612 89.505 21.3612C90.4315 21.3612 91.1917 21.0344 91.7698 20.3967Z"
            //             fill="#184999"
            //         />
            //         <Path
            //             d="M112.564 9.05456C113.776 10.1146 114.378 11.6131 114.378 13.55C114.378 15.5904 113.768 17.1686 112.548 18.2925C111.329 19.4163 109.619 19.9822 107.409 19.9822H103.949V24.9877H100.021V7.46045H107.409C109.642 7.46045 111.353 7.99448 112.564 9.05456ZM109.769 15.9093C110.355 15.3991 110.656 14.6499 110.656 13.6695C110.656 12.7051 110.363 11.9718 109.769 11.4776C109.183 10.9834 108.328 10.7364 107.219 10.7364H103.957V16.6744H107.219C108.328 16.6744 109.183 16.4194 109.769 15.9093Z"
            //             fill="#184999"
            //         />
            //         <Path
            //             d="M129.534 11.5175V24.9878H125.725V22.6125C124.814 24.3023 123.318 25.1392 121.251 25.1392C119.778 25.1392 118.598 24.6769 117.727 23.7523C116.856 22.8277 116.421 21.5684 116.421 19.9822V11.5175H120.253V18.8823C120.253 19.7351 120.475 20.4047 120.926 20.8829C121.378 21.3691 121.988 21.6082 122.764 21.6082C123.674 21.5923 124.395 21.2655 124.925 20.6279C125.456 19.9902 125.725 19.1772 125.725 18.1729V11.5095H129.534V11.5175Z"
            //             fill="#184999"
            //         />
            //         <Path
            //             d="M139.512 14.5064C138.799 14.2833 138.157 14.1717 137.595 14.1717C137.128 14.1717 136.756 14.2514 136.479 14.4188C136.194 14.5861 136.059 14.8332 136.059 15.168C136.059 15.5346 136.249 15.8216 136.629 16.0209C137.009 16.2201 137.635 16.4433 138.498 16.6984C139.44 16.9853 140.224 17.2643 140.834 17.5353C141.444 17.8142 141.982 18.2207 142.426 18.7627C142.877 19.3047 143.099 20.0221 143.099 20.9068C143.099 22.2459 142.592 23.282 141.57 24.0233C140.549 24.7646 139.242 25.1392 137.651 25.1392C136.558 25.1392 135.505 24.9798 134.491 24.653C133.478 24.3262 132.599 23.8639 131.854 23.2661L133.097 20.6597C133.778 21.1938 134.539 21.6082 135.37 21.9031C136.209 22.198 137.001 22.3415 137.746 22.3415C138.26 22.3415 138.672 22.2538 138.981 22.0785C139.29 21.9031 139.44 21.6401 139.44 21.2894C139.44 20.9068 139.242 20.6039 138.854 20.3887C138.466 20.1735 137.841 19.9344 136.978 19.6873C136.067 19.4243 135.307 19.1533 134.705 18.8823C134.103 18.6193 133.58 18.2128 133.153 17.6708C132.725 17.1288 132.504 16.4194 132.504 15.5586C132.504 14.2195 133.002 13.1913 133.992 12.45C134.99 11.7167 136.249 11.3501 137.777 11.3501C138.688 11.3501 139.599 11.4776 140.517 11.7407C141.428 11.9957 142.259 12.3703 143.004 12.8565L141.681 15.4868C140.945 15.0564 140.224 14.7376 139.512 14.5064Z"
            //             fill="#184999"
            //         />
            //         <Path
            //             d="M157.677 12.7689C158.564 13.7015 159.007 14.9528 159.007 16.523V24.9878H155.175V17.6549C155.175 16.802 154.937 16.1325 154.462 15.6383C153.987 15.1441 153.346 14.8971 152.538 14.8971C151.596 14.913 150.843 15.2398 150.297 15.8854C149.75 16.531 149.473 17.352 149.473 18.3483V24.9878H145.641V11.5175H149.473V13.8688C150.4 12.2349 151.944 11.398 154.098 11.3661C155.602 11.3661 156.79 11.8364 157.677 12.7689Z"
            //             fill="#184999"
            //         />
            //         <Path
            //             d="M170.078 24.9878V23.4096C169.626 23.9755 169.048 24.4139 168.336 24.7088C167.623 25.0117 166.799 25.1631 165.873 25.1631C164.463 25.1631 163.347 24.7726 162.515 23.9994C161.684 23.2263 161.272 22.2061 161.272 20.9547C161.272 19.6874 161.716 18.699 162.602 17.9896C163.489 17.2803 164.748 16.9136 166.372 16.8977H170.054V16.6506C170.054 15.9332 169.816 15.3753 169.341 14.9847C168.866 14.5942 168.177 14.3949 167.267 14.3949C166.023 14.3949 164.693 14.7855 163.284 15.5745L162.088 12.9204C163.149 12.3863 164.147 11.9958 165.073 11.7407C166 11.4936 167.021 11.3661 168.13 11.3661C169.919 11.3661 171.313 11.7885 172.295 12.6414C173.285 13.4942 173.784 14.6818 173.8 16.1963L173.823 24.9878H170.078ZM169.009 21.8314C169.587 21.4489 169.935 20.9627 170.054 20.3808V19.1772H167.045C166.301 19.1772 165.746 19.2968 165.39 19.5439C165.034 19.783 164.851 20.1736 164.851 20.7076C164.851 21.2257 165.041 21.6402 165.414 21.943C165.786 22.2539 166.301 22.4053 166.942 22.4053C167.742 22.4133 168.431 22.222 169.009 21.8314Z"
            //             fill="#184999"
            //         />
            //         <Path
            //             d="M183.413 14.5064C182.7 14.2833 182.059 14.1717 181.497 14.1717C181.029 14.1717 180.657 14.2514 180.38 14.4188C180.095 14.5861 179.96 14.8332 179.96 15.168C179.96 15.5346 180.15 15.8216 180.53 16.0209C180.911 16.2201 181.536 16.4433 182.399 16.6984C183.342 16.9853 184.126 17.2643 184.735 17.5353C185.345 17.8142 185.883 18.2207 186.327 18.7627C186.778 19.3047 187 20.0221 187 20.9068C187 22.2459 186.493 23.282 185.472 24.0233C184.45 24.7646 183.144 25.1392 181.552 25.1392C180.459 25.1392 179.406 24.9798 178.392 24.653C177.379 24.3262 176.5 23.8639 175.755 23.2661L176.999 20.6597C177.68 21.1938 178.44 21.6082 179.271 21.9031C180.111 22.198 180.903 22.3415 181.647 22.3415C182.162 22.3415 182.573 22.2538 182.882 22.0785C183.191 21.9031 183.342 21.6401 183.342 21.2894C183.342 20.9068 183.144 20.6039 182.756 20.3887C182.368 20.1735 181.742 19.9344 180.879 19.6873C179.968 19.4243 179.208 19.1533 178.606 18.8823C178.004 18.6193 177.482 18.2128 177.054 17.6708C176.627 17.1288 176.405 16.4194 176.405 15.5586C176.405 14.2195 176.904 13.1913 177.894 12.45C178.891 11.7167 180.15 11.3501 181.679 11.3501C182.589 11.3501 183.5 11.4776 184.419 11.7407C185.329 11.9957 186.161 12.3703 186.905 12.8565L185.583 15.4868C184.846 15.0564 184.126 14.7376 183.413 14.5064Z"
            //             fill="#184999"
            //         />
            //         <Path
            //             d="M19.5513 0C19.7176 0.263029 19.8759 0.526058 20.0422 0.781116C20.3431 1.25935 20.6599 1.73758 20.9054 2.2477C21.0637 2.58246 21.1904 2.94114 21.23 3.30779C21.2617 3.55487 21.2538 3.80993 21.2142 4.06499L21.2379 4.05702C21.1746 4.10484 21.135 4.17658 21.0875 4.23237C20.6044 4.89393 20.1293 5.54752 19.6463 6.20907C19.6146 6.02575 19.5592 5.84243 19.48 5.67505C19.3454 5.38014 19.1474 5.12508 18.9494 4.87799C18.7198 4.59105 18.4822 4.31208 18.2526 4.01717C18.6881 2.67811 19.1157 1.33906 19.5513 0Z"
            //             fill="#169B48"
            //         />
            //         <Path
            //             d="M21.2458 4.05703C21.6497 3.88168 22.0931 3.80197 22.5366 3.81791C23.083 3.82588 23.6215 3.94544 24.152 4.065C24.6351 4.17659 25.1181 4.27223 25.6011 4.36788C24.445 5.21276 23.2889 6.04967 22.1407 6.88658C21.8081 6.77499 21.4913 6.63152 21.1667 6.51196C20.6915 6.32864 20.1847 6.16126 19.6621 6.22502L19.67 6.21705C20.1531 5.56347 20.6282 4.90191 21.1112 4.24035C21.1429 4.16862 21.1825 4.09688 21.2458 4.05703Z"
            //             fill="#11743B"
            //         />
            //         <Path
            //             d="M13.5014 4.24036C14.5308 4.24036 15.5682 4.24036 16.5976 4.24036C17.0252 4.24036 17.4607 4.24833 17.8883 4.24036C18.0467 4.43962 18.2051 4.63888 18.3635 4.83018C18.5535 5.06133 18.7435 5.2845 18.9098 5.53159C19.0603 5.75476 19.1791 6.00185 19.2266 6.26488C19.2503 6.41632 19.2424 6.57573 19.2583 6.72717C19.2583 6.75906 19.2345 6.78297 19.2187 6.81485C18.5693 7.69161 17.9358 8.58432 17.2944 9.46905C16.8351 10.0987 16.3838 10.7284 15.9245 11.3581C15.9007 11.39 15.8849 11.4218 15.8532 11.4378C15.8611 11.382 15.8849 11.3342 15.9007 11.2863C16.3679 9.85961 16.8272 8.42491 17.2944 6.99817C16.3046 6.28082 15.3227 5.56347 14.3328 4.83815C14.0557 4.64686 13.7706 4.44759 13.5014 4.24036Z"
            //             fill="#215FAC"
            //         />
            //         <Path
            //             d="M19.2583 6.74315C19.4404 6.69533 19.6225 6.64751 19.8126 6.64751C20.1452 6.63157 20.4698 6.71924 20.7787 6.83083C21.2142 6.98227 21.626 7.17356 22.0615 7.32501C22.5128 8.71986 22.9642 10.1067 23.4156 11.5016C23.178 11.3342 22.9484 11.1588 22.7108 10.9915C21.6814 10.2343 20.652 9.48503 19.6225 8.71986C18.3555 9.64444 17.0965 10.577 15.8295 11.5016C15.8295 11.4856 15.8374 11.4617 15.8374 11.4458L15.8453 11.4538C15.877 11.4298 15.8928 11.398 15.9166 11.3741C16.3759 10.7444 16.8272 10.1147 17.2865 9.48503C17.9279 8.6003 18.5693 7.71556 19.2108 6.83083C19.2345 6.79895 19.2583 6.77504 19.2583 6.74315Z"
            //             fill="#184999"
            //         />
            //     </G>
            //     <Defs>
            //         <ClipPath id="clip0_10_228">
            //             <Rect width={187} height={39} fill="white" />
            //         </ClipPath>
            //     </Defs>
            // </Svg>
        )
    }
}


export default LogoSplash;
