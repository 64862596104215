import React, { Component } from 'react';
import { Dimensions, StyleSheet, View, Image, ScrollView, FlatList } from 'react-native';
import { Alert, AlertTitle, Typography, Avatar, IconButton, Skeleton, Button, useMediaQuery, useTheme, CardActionArea } from '@mui/material';
import * as Identifier from "../constants/PageIdentifiers";
import * as Command from "../constants/Commands";
import { executeGet, executePost, removeCredentials, executeMultipartPost, saveLocalParameters, truncateText } from '../utilities/Utilities';
import {
    code_ok, code_empty, code_error, code_auth_error, homepage, homepage_banner,
    homepageContentType, homepageCategories, notificationCheck, updateFBToken, updateAvatar
} from '../constants/api';
import { primary, color_text, sub_text, danger } from '../constants/colors';
import SkeletonMain from '../skeletons/SkeletonMain';
import AlertDialog from '../components/AlertDialog';
import SearchIcon from '../assets/images/SearchIcon';
import NotificationIcon from '../assets/images/NotificationIcon';
import HomepageBanners from '../components/HomepageBanners';
import HomepageContent from '../components/HomepageContent';
import HomepageContentType from '../components/HomepageContentType';
import HomepageContentCategories from '../components/HomepageContentCategories';
import HomepageAuthors from '../components/HomepageAuthors';
import HomepageRecommended from '../components/HomepageRecommended';
import HomepageNews from '../components/HomepageNews';
import NavigationMenu from '../components/NavigationMenu';
import { BookRack } from '../assets/Icons';
import * as DocumentPicker from 'expo-document-picker';
import MenuStatistik from '../components/homepage/MenuStatistik';
import MainMenu from '../components/homepage/MainMenu'
import MenuSaranaKomunikasi from '../components/homepage/MenuSaranaKomunikasi'
import MenuNewsPilihan from '../components/homepage/MenuNewsPilihan'
import MenuYoutube from '../components/homepage/MenuYoutube'
import MenuYoutubeUtama from '../components/homepage/MenuYoutubeUtama'

import MenuKoleksiSekolah from '../components/homepage/MenuKoleksiSekolah'
import MenuKontenInteropobilitas from '../components/homepage/MenuKontenInteropobilitas';

import LogoPusnas from '../assets/images/LogoPusnas';
import LogoPusnasNew from '../assets/images/LogoPusnasNew';
import MenuKontenEdukasi from '../components/homepage/MenuKontenEdukasi';
import MenuKoleksiPerguruanTinggi from '../components/homepage/MenuKoleksiPerguruanTinggi';
import MenuKoleksiBSE from '../components/homepage/MenuKoleksiBSE';
import MenuKoleksiDigital from '../components/homepage/MenuKoleksiDigital';
import RakDigital from '../components/homepage/RakDigital';
import HomeBg from '../assets/images/HomeBg';
import MenuAudiobook from '../components/homepage/MenuAudiobook';
import MenuTutorialEdukasi from '../components/homepage/MenuTutorialEdukasi';
import MenuInfoLiterasi from '../components/homepage/MenuInfoLiterasi';
import WidgetInfoLiterasi from '../components/homepage/WidgetInfoLiterasi';
import LogoKeluarga from '../assets/logo_literasikeluarga.png';
import musik from "../assets/images/musik.png";
import MenuIntegrasi from '../components/homepage/MenuIntegrasi';
import MenuVideobook from '../components/homepage/MenuVideobook';
import SectionBook from '../components/homepage/SectionBook';

var _is_mounted = false;

//== Types of homepage ==// 
const h_banner = "banner";
const h_content = "content";
const h_content_type = "content-types";
const h_categories = "categories";
const h_authors = "authors";
const h_navigation = "main-menu";
const h_recommended = "recommended";
const h_news = "news";

class MainHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            flagUpdate: true,
            showLoading: true,
            showAlert: false,
            loadingMessage: "",
            alertMessage: "",
            fullname: "Anonymous",
            avatar: true,
            photo: "https://bintangpusnas.perpusnas.go.id/",
            homepage_data: [
                { "code": "HMP-0000", "title": "Video Untukmu", "type": "youtubeUtama", "sort_no": "1" },
                { "code": "HMP-00X0", "title": "Koleksi Sekolah/Madrasah", "type": "koleksiSekolah", "sort_no": "1" },
                { "code": "HMP-00X1", "title": "Koleksi Perpustakaan Perguruan Tinggi", "type": "koleksiPT", "sort_no": "1" },
                { "code": "HMP-00X2", "title": "Koleksi BSE", "type": "koleksiBSE", "sort_no": "1" },
                { "code": "HMP-00X3", "title": "Koleksi Digital", "type": "koleksiDigital", "sort_no": "1" },
                { "code": "HMP-00X5", "title": "Statistik Koleksi", "type": "statistik", "sort_no": "1" },
                { "code": "HMP-0003", "title": "Koleksi Tutorial Edukasi", "type": "kontenEdukasi", "sort_no": "1" },
                { "code": "HMP-0004", "title": "Rak Pinjam", "type": "rakDigital", "sort_no": "1" },
                { "code": "HMP-00X9", "title": "Koleksi Konten Kreator ", "type": "kontenInteroperabilitas", "sort_no": "1" },
                { "code": "HMP-0008", "title": "Berita Pilihan", "type": "beritaPilihan", "sort_no": "1" },
                { "code": "HMP-00X4", "title": "Pengembangan Dan Pembinaan P3SMPT", "type": "mainMenu", "sort_no": "1" },
                { "code": "HMP-0002", "title": "Sarana Komunikasi", "type": "saranaKomunikasi", "sort_no": "1" },
                { "code": "HMP-0001", "title": "Promosi & Informasi", "type": "banner", "sort_no": "1" },
                { "code": "HMP-0005", "title": "Youtube", "type": "youtube", "sort_no": "1" }

            ],
            banners: [],
            total_unread: 0,
            total_read: 0,
            uploadingAvatar: false

        }

        this.getHomepage = this.getHomepage.bind(this);
        this.renderHomepage = this.renderHomepage.bind(this);

        this.getHomepageBanner = this.getHomepageBanner.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleNotificationClick = this.handleNotificationClick.bind(this);

        this.checkNotification = this.checkNotification.bind(this);
        this.handleAlertClose = this.handleAlertClose.bind(this);
        this.sendToken = this.sendToken.bind(this);

        this.handleAvatarClick = this.handleAvatarClick.bind(this);
        this.saveLocalParams = this.saveLocalParams.bind(this);
    }

    saveLocalParams = async () => {
        //console.log(JSON.stringify(this.props.route.params));
        let obj = this.props.route.params;
        obj.credential = global.defaultHeaders;
        await saveLocalParameters(obj);
    }

    hideNotifUpdate = () => {
        this.setState({
            flagUpdate: false
        })
    }

    componentDidMount = () => {
        _is_mounted = true;

        if (global.os == "ANDROID") {
            setTimeout(this.hideNotifUpdate, 5000);
        }

        if (_is_mounted) {
            this.saveLocalParams();
            let photo;

            if (this.props.route.params.email == "bintangpusnasedu@bintangpusnas.com") {
                this.setState({ avatar: false })
            }
            if (this.props.route.params.gender == "L") {
                photo = "https://bintangpusnas.perpusnas.go.id/images/avatar/userL.png";
            } else {
                photo = "https://bintangpusnas.perpusnas.go.id/images/avatar/userP.png";
            }

            this.setState({
                fullname: this.props.route.params.fullname,
                photo: this.props.route.params.photo,
            })

            window.addEventListener("message", (event) => {
                if (event.data.id == "update_fullname") {
                    let fullname = event.data.fullname;
                    this.setState({
                        fullname: fullname
                    })
                } else if (event.data.id == "check_notifications") {
                    this.checkNotification();
                } else if (event.data.id == "sign_out") {
                    console.log("SIGN OUT MESSAGE RECEIVED");
                    //console.log(this.props.route.params.options);
                    removeCredentials(() => {
                        // this.props.navigation.reset({
                        //     index: 0,
                        //     routes: [{ name: "Login", params: "" }]
                        // })
                        console.log("LOGOUT NIH = " + JSON.stringify(this.props.route.params));
                        localStorage.clear();
                        document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                        // window.location.reload(true)
                        this.props.navigation.reset({
                            index: 0,
                            routes: [{ name: "Login", params: "" }]
                        })
                    });
                } else if (event.data.id == "send_fb_token") {
                    let fb_token = event.data.token;
                    this.sendToken(fb_token);
                } else if (event.data.id == "go_back" && global.currentRouteName == Identifier.MAIN) {
                    if (global.appAccess == 1) {
                        try {
                            JSBridge.launchFunction(Command.CLOSE_APP, "-");
                        } catch (err) {

                        }
                    }
                }
            })

            //== FCM INIT ==//
            if (global.os == "ANDROID") {
                //JSBridge.launchFunction(Command.INIT_FCM, "-");
            } else {
                //TODO: IOS Init FCM 
            }

            this.checkNotification();
        }
        this.getHomepage();
    }

    componentWillUnmount = () => {
        _is_mounted = false;
        window.removeEventListener("message", null);
    }

    getHomepage = async () => {
        const response = await executeGet(homepage);
        if (response.code == code_ok) {
            if (response.data.length > 0) {
                this.setState({
                    showLoading: false,
                    // homepage_data: response.data
                });

                console.log('respone = ' + JSON.stringify(response.data))
            }
        } else {
            if (response.code == code_auth_error) {
                //TODO - Force Logout 
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                // this.setState({
                //     showLoading: false,
                //     showAlert: true,
                //     alertMessage: response.msg
                // })
                window.postMessage({ id: "sign_out" });
            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }
        }
    }

    getHomepageBanner = async (code) => {

    }

    gotoLiterasiKeluarga = async (code, title) => {
        var nomorKTP = "";
        localStorage.setItem('ktp', '0000000000000000');
        let params = { code: code, title: title, origins: "CONTENT_SECTION" };
        if (window.localStorage.getItem('ktp') == null) {
            this.props.navigation.navigate("InputKTP", params);
        } else {
            nomorKTP = window.localStorage.getItem('ktp');
            //validasi KTP
            if (!isNaN(+nomorKTP)) {
                if (nomorKTP.length != 16) {
                    this.props.navigation.navigate("InputKTP", params);
                } else {
                    //3576014403910003
                    var umurValid = true;
                    const batasUmur = 18;
                    const currYear = Number(new Date().getFullYear().toString().substr(-2));
                    const batasLahir = currYear - batasUmur;
                    console.log("Tahun Ini: " + currYear)

                    let tahun = nomorKTP.substr(10, 2);
                    if (Number(tahun) < currYear) {
                        //ini kelahiran 2023 s/d 2000
                        if (Number(tahun) > batasLahir) {
                            umurValid = false;
                            this.props.navigation.navigate("InputKTP", params);
                        }
                    }
                    if (umurValid == true) {
                        this.props.navigation.navigate("SectionDetail", params);
                    }

                }
            } else {
                this.props.navigation.navigate("InputKTP", params);
            }
        }
        //let params = { code : code, title : title, origins : "CONTENT_SECTION"}; 
        //this.props.navigation.navigate("SectionDetail", params);

    }
    renderHomepage = ({ item, index, separators }) => {

        if ((item.type == "mainMenu")) {
            return (
                <MainMenu
                    code={item.code}
                    title={item.title}
                    navigation={this.props.navigation}
                />
            )
        } else if (item.type == "youtubeUtama") {
            return (
                <>
                    {
                        this.state.flagUpdate == true &&
                        <>
                            {
                                global.os == "ANDROID" &&
                                <View style={{ marginLeft: 16, marginRight: 16 }}>
                                    <CardActionArea
                                        onClick={() => {
                                            let params = { code: "HMP-PERTI", title: "Koleksi Perguruan Tinggi", origins: "CONTENT_SECTION" };
                                        }}
                                    >
                                        <div style={{
                                            height: '130px', width: '100%', position: 'relative', backgroundColor: '#a0bbe8', borderRadius: 10,
                                        }}>
                                            <div style={{ margin: '0px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%' }}>
                                                <View style={{ flex: 1, flexDirection: 'row' }}>
                                                    <div style={{ flex: 0.8, marginLeft: 5, alignSelf: 'center', marginTop: 10, marginLeft: 10 }}>
                                                        <Typography
                                                            fontFamily="Open Sans"
                                                            fontSize={14}
                                                            color={'#184999'}
                                                            fontWeight={"600"}
                                                        >
                                                            Silahkan update aplikasi pada Play Store Anda untuk penglaman yang lebih menyenangkan
                                                        </Typography>
                                                        <View
                                                            style={{
                                                                backgroundColor: "#ffffff",
                                                                width: "50%",
                                                                padding: 5,
                                                                borderColor: "#184999",
                                                                borderRadius: "20px",
                                                                borderWidth: '2px',
                                                                marginTop: 5
                                                            }}
                                                            onClick={() => { window.location.href = "https://play.google.com/store/apps/details?id=id.kubuku.bintangpusnas&hl=en-ID" }}>
                                                            <Typography
                                                                fontFamily="Open Sans"
                                                                fontSize={13}
                                                                color={'#184999'}
                                                                fontWeight={"600"}
                                                                style={{ textAlign: 'center' }}
                                                            >
                                                                Update Aplikasi
                                                            </Typography>
                                                        </View>
                                                    </div>
                                                    <div style={{ flex: 0.2 }}>
                                                        {/* <Image
                                                                source={{ uri: 'https://bintangpusnas.perpusnas.go.id/images/cover/phone-update.png' }}
                                                                style={{width: '100px',
                                                                height: '100px',
                                                                }}
                                                            /> */}
                                                        <View
                                                            onClick={() => { this.setState({ flagUpdate: false }) }}
                                                            style={{ borderRadius: "10px", position: 'absolute', top: "5px", right: "5px", height: "42px", width: "50px", color: "white", zIndex: 1 }}>
                                                            <Typography
                                                                fontFamily="Open Sans"
                                                                fontSize={24}
                                                                fontWeight="600"
                                                                style={{ position: "absolute", top: "2px", left: "16px" }}
                                                            >
                                                                X
                                                            </Typography>
                                                        </View>
                                                    </div>

                                                </View>


                                            </div>
                                        </div>
                                    </CardActionArea>
                                </View>
                            }
                        </>

                    }


                    <MenuYoutubeUtama
                        code={item.code}
                        title={item.title}
                        navigation={this.props.navigation}
                    />
                    {
                        //ANCHOR - KOLEKSI BINTANG PUSNAS
                    }
                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 8, marginLeft: 12, marginRight: 12, marginTop: 8, padding: 12, borderRadius: 10, boxShadow: "0px 0.9131946563720703px 2.047769784927368px 0px rgba(0, 0, 0, 0.07), 0px 2.194533586502075px 4.921075344085693px 0px rgba(0, 0, 0, 0.0503), 0px 9.82798px 22.0385px rgba(0, 0, 0, 0.035), 0px 5.50948px 12.3546px rgba(0, 0, 0, 0.0417275), 0px 2.92604px 6.56143px rgba(0, 0, 0, 0.0503198), 0px 1.21759px 2.73036px rgba(0, 0, 0, 0.07)" }}>
                        <View style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: 8, paddingBottom: 8, margin: "auto" }}>
                            <img src={LogoKeluarga} alt="literasi keluarga" style={{ maxWidth: 160, marginLeft: Dimensions.get("window").width < '360' ? 0 : 18 }} />
                            {(window.innerWidth < 720) ?
                                <View style={{ display: "flex", flexDirection: "column", marginBottom: 0, marginLeft: 12, marginRight: 2, marginTop: 2 }}>
                                    <Button
                                        onClick={() => {
                                            // this.gotoLiterasiKeluarga("HMP-PRA", "Koleksi Pra Nikah");
                                            let params = { code: "HMP-PRA", title: "Koleksi Pra Nikah", origins: "CONTENT_SECTION" };
                                            this.props.navigation.navigate("SectionDetail", params);
                                        }}
                                        style={{ backgroundImage: 'linear-gradient(to right,#9D50BB, #6E48AA)', display: "flex", marginRight: 5, flexDirection: "row", overflow: "hidden", marginBottom: 12, paddingBottom: 8, paddingTop: 8, paddingLeft: 2, paddingRight: 2, width: "35vw", height: 105, display: "flex", flexDirection: "row", flex: 0.5, borderRadius: 5, boxShadow: "0px 0.9131946563720703px 2.047769784927368px 0px rgba(0, 0, 0, 0.07), 0px 2.194533586502075px 4.921075344085693px 0px rgba(0, 0, 0, 0.0503), 0px 9.82798px 22.0385px rgba(0, 0, 0, 0.035), 0px 5.50948px 12.3546px rgba(0, 0, 0, 0.0417275), 0px 2.92604px 6.56143px rgba(0, 0, 0, 0.0503198), 0px 1.21759px 2.73036px rgba(0, 0, 0, 0.07)" }}>
                                        <Typography
                                            style={{ flex: 1, marginLeft: 5 }}
                                            fontSize={16}
                                            fontWeight={"800"}
                                            textAlign={"center"}
                                            color={"white"}
                                        >
                                            {"Pra Nikah"}
                                        </Typography>
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            // this.gotoLiterasiKeluarga("HMP-NIKAH", "Koleksi Nikah");
                                            let params = { code: "HMP-NIKAH", title: "Koleksi Nikah", origins: "CONTENT_SECTION" };
                                            this.props.navigation.navigate("SectionDetail", params);
                                        }}
                                        style={{ backgroundColor: "#00c6ff", backgroundImage: 'linear-gradient(to right,#00c6ff, #0072ff)', display: "flex", marginRight: 5, flexDirection: "row", overflow: "hidden", marginBottom: 12, paddingBottom: 8, paddingTop: 8, paddingLeft: 2, paddingRight: 2, width: "35vw", height: 105, display: "flex", flexDirection: "row", flex: 0.5, borderRadius: 5, boxShadow: "0px 0.9131946563720703px 2.047769784927368px 0px rgba(0, 0, 0, 0.07), 0px 2.194533586502075px 4.921075344085693px 0px rgba(0, 0, 0, 0.0503), 0px 9.82798px 22.0385px rgba(0, 0, 0, 0.035), 0px 5.50948px 12.3546px rgba(0, 0, 0, 0.0417275), 0px 2.92604px 6.56143px rgba(0, 0, 0, 0.0503198), 0px 1.21759px 2.73036px rgba(0, 0, 0, 0.07)" }}>
                                        <Typography
                                            style={{ flex: 1, marginLeft: 5 }}
                                            fontSize={16}
                                            fontWeight={"800"}
                                            textAlign={"center"}
                                            color={"white"}
                                        >
                                            {"Menikah"}
                                        </Typography>
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            let params = { code: "HMP-GOLD", title: "Koleksi Golden Age", origins: "CONTENT_SECTION" };
                                            this.props.navigation.navigate("SectionDetail", params);
                                        }}
                                        style={{ backgroundColor: "#FDC830", backgroundImage: 'linear-gradient(to right,#FDC830, #F37335)', display: "flex", marginRight: 5, flexDirection: "row", overflow: "hidden", marginBottom: 12, paddingBottom: 8, paddingTop: 8, paddingLeft: 2, paddingRight: 2, width: "35vw", height: 105, display: "flex", flexDirection: "row", flex: 0.5, borderRadius: 5, boxShadow: "0px 0.9131946563720703px 2.047769784927368px 0px rgba(0, 0, 0, 0.07), 0px 2.194533586502075px 4.921075344085693px 0px rgba(0, 0, 0, 0.0503), 0px 9.82798px 22.0385px rgba(0, 0, 0, 0.035), 0px 5.50948px 12.3546px rgba(0, 0, 0, 0.0417275), 0px 2.92604px 6.56143px rgba(0, 0, 0, 0.0503198), 0px 1.21759px 2.73036px rgba(0, 0, 0, 0.07)" }}>
                                        <Typography
                                            style={{ flex: 1, marginLeft: 5 }}
                                            fontSize={16}
                                            fontWeight={"800"}
                                            textAlign={"center"}
                                            color={"white"}
                                        >
                                            {"Golden Age"}
                                        </Typography>
                                    </Button>
                                </View>
                                :
                                <View style={{ display: "flex", flexDirection: "row", marginBottom: 0, marginLeft: 12, marginRight: 2, marginTop: 2 }}>
                                    <Button
                                        onClick={() => {
                                            // this.gotoLiterasiKeluarga("HMP-PRA", "Koleksi Pra Nikah");
                                            let params = { code: "HMP-PRA", title: "Koleksi Pra Nikah", origins: "CONTENT_SECTION" };
                                            this.props.navigation.navigate("SectionDetail", params);
                                        }}
                                        style={{ backgroundImage: 'linear-gradient(to right,#9D50BB, #6E48AA)', display: "flex", marginLeft: 25, marginRight: 15, flexDirection: "row", overflow: "hidden", marginBottom: 12, paddingBottom: 8, paddingTop: 8, paddingLeft: 2, paddingRight: 2, width: "20vw", height: 105, display: "flex", flexDirection: "row", borderRadius: 5, boxShadow: "0px 0.9131946563720703px 2.047769784927368px 0px rgba(0, 0, 0, 0.07), 0px 2.194533586502075px 4.921075344085693px 0px rgba(0, 0, 0, 0.0503), 0px 9.82798px 22.0385px rgba(0, 0, 0, 0.035), 0px 5.50948px 12.3546px rgba(0, 0, 0, 0.0417275), 0px 2.92604px 6.56143px rgba(0, 0, 0, 0.0503198), 0px 1.21759px 2.73036px rgba(0, 0, 0, 0.07)" }}>
                                        <Typography
                                            style={{ flex: 1, marginLeft: 5 }}
                                            fontSize={16}
                                            fontWeight={"800"}
                                            textAlign={"center"}
                                            color={"white"}
                                        >
                                            {"Pra Nikah"}
                                        </Typography>
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            // this.gotoLiterasiKeluarga("HMP-NIKAH", "Koleksi Nikah");
                                            let params = { code: "HMP-NIKAH", title: "Koleksi Nikah", origins: "CONTENT_SECTION" };
                                            this.props.navigation.navigate("SectionDetail", params);
                                        }}
                                        style={{ backgroundColor: "#00c6ff", backgroundImage: 'linear-gradient(to right,#00c6ff, #0072ff)', display: "flex", marginRight: 15, flexDirection: "row", overflow: "hidden", marginBottom: 12, paddingBottom: 8, paddingTop: 8, paddingLeft: 2, paddingRight: 2, width: "20vw", height: 105, display: "flex", flexDirection: "row", borderRadius: 5, boxShadow: "0px 0.9131946563720703px 2.047769784927368px 0px rgba(0, 0, 0, 0.07), 0px 2.194533586502075px 4.921075344085693px 0px rgba(0, 0, 0, 0.0503), 0px 9.82798px 22.0385px rgba(0, 0, 0, 0.035), 0px 5.50948px 12.3546px rgba(0, 0, 0, 0.0417275), 0px 2.92604px 6.56143px rgba(0, 0, 0, 0.0503198), 0px 1.21759px 2.73036px rgba(0, 0, 0, 0.07)" }}>
                                        <Typography
                                            style={{ flex: 1, marginLeft: 5 }}
                                            fontSize={16}
                                            fontWeight={"800"}
                                            textAlign={"center"}
                                            color={"white"}
                                        >
                                            {"Menikah"}
                                        </Typography>
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            let params = { code: "HMP-GOLD", title: "Koleksi Golden Age", origins: "CONTENT_SECTION" };
                                            this.props.navigation.navigate("SectionDetail", params);
                                        }}
                                        style={{ backgroundColor: "#FDC830", backgroundImage: 'linear-gradient(to right,#FDC830, #F37335)', display: "flex", marginRight: 5, flexDirection: "row", overflow: "hidden", marginBottom: 12, paddingBottom: 8, paddingTop: 8, paddingLeft: 2, paddingRight: 2, width: "20vw", height: 105, display: "flex", flexDirection: "row", borderRadius: 5, boxShadow: "0px 0.9131946563720703px 2.047769784927368px 0px rgba(0, 0, 0, 0.07), 0px 2.194533586502075px 4.921075344085693px 0px rgba(0, 0, 0, 0.0503), 0px 9.82798px 22.0385px rgba(0, 0, 0, 0.035), 0px 5.50948px 12.3546px rgba(0, 0, 0, 0.0417275), 0px 2.92604px 6.56143px rgba(0, 0, 0, 0.0503198), 0px 1.21759px 2.73036px rgba(0, 0, 0, 0.07)" }}>
                                        <Typography
                                            style={{ flex: 1, marginLeft: 5 }}
                                            fontSize={16}
                                            fontWeight={"800"}
                                            textAlign={"center"}
                                            color={"white"}
                                        >
                                            {"Golden Age"}
                                        </Typography>
                                    </Button>
                                </View>
                            }
                        </View>
                    </View>
                </>
            )
        } else if (item.type == "koleksiSekolah") {
            return (
                <MenuKoleksiSekolah
                    code={item.code}
                    title={item.title}
                    navigation={this.props.navigation}
                />
            )
        } else if (item.type == "koleksiPT") {
            return (
                <MenuKoleksiPerguruanTinggi
                    code={item.code}
                    title={item.title}
                    navigation={this.props.navigation}
                />
            )
        } else if (item.type == "koleksiBSE") {
            return (
                <>
                    <MenuKoleksiBSE
                        code={item.code}
                        title={item.title}
                        navigation={this.props.navigation}
                    />
                    <SectionBook
                        code={"homepageRecommended"}
                        title={"Buku Rekomendasi"}
                        navigation={this.props.navigation}
                    />
                    
                    <MenuInfoLiterasi
                        code={item.code}
                        title={"Ragam Perpustakaan"}
                        navigation={this.props.navigation}
                    />
                    <MenuIntegrasi
                        code={item.code}
                        title={"Gamifikasi/Permainan Edukasi"}
                        navigation={this.props.navigation}
                    />
                </>
            )
        } else if (item.type == "koleksiDigital") {
            return (
                <>
                    <MenuKoleksiDigital
                        code={item.code}
                        title={item.title}
                        navigation={this.props.navigation}
                    />
                    <SectionBook
                        code={"homepageFavorite"}
                        title={"Buku Favorite"}
                        navigation={this.props.navigation}
                    />
                    <View style={{ width: "100%" }}>
                        <View style={{ marginTop: 16, marginLeft: 16, marginRight: 16 }}>
                            <View class="flex noborder" style={{ borderRadius: "10px" }}>
                                <div style={{ position: "relative", maxHeight: "300px" }}
                                    onClick={() => {
                                        let params = { code: "HMP-Edukasi", title: "Koleksi Musik Keluarga", origins: "CONTENT_SECTION" };
                                        this.props.navigation.navigate("SectionMusik", params);
                                        // window.location.href = "http://depok.kubuku.id/tutorial-edu/";
                                    }}
                                >
                                    <img src={musik} style={{ width: "calc(100vw - 32px", borderRadius: "10px", boxShadow: "0px 0.9131946563720703px 2.047769784927368px 0px rgba(0, 0, 0, 0.07), 0px 2.194533586502075px 4.921075344085693px 0px rgba(0, 0, 0, 0.0503), 0px 9.82798px 22.0385px rgba(0, 0, 0, 0.035), 0px 5.50948px 12.3546px rgba(0, 0, 0, 0.0417275), 0px 2.92604px 6.56143px rgba(0, 0, 0, 0.0503198), 0px 1.21759px 2.73036px rgba(0, 0, 0, 0.07)" }}>
                                    </img>
                                    <div style={{ position: "absolute", bottom: "10px", left: "10px" }}>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={16}
                                            color={'black'}
                                            fontWeight={"600"}
                                        >
                                            Musik Keluarga
                                        </Typography>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={13}
                                            color={'black'}
                                            fontWeight={"400"}
                                            style={{ paddingTop: 5 }}
                                        >
                                            Musik pendukung Literasi Keluarga
                                        </Typography>
                                    </div>
                                </div>
                            </View>

                        </View>
                    </View>
                    <MenuVideobook
                        code={item.code}
                        title={item.title}
                        navigation={this.props.navigation}
                    />
                </>
            )
        } else if ((item.type == h_banner)) {
            return (
                <HomepageBanners
                    code={item.code}
                    title={item.title}
                    navigation={this.props.navigation}
                />
            )
        } else if ((item.type == 'saranaKomunikasi')) {
            return (
                <MenuSaranaKomunikasi
                    code={item.code}
                    title={item.title}
                    navigation={this.props.navigation}
                />
            )
        } else if ((item.type == 'kontenEdukasi')) {
            return (
                <>
                    <MenuKontenEdukasi
                        code={item.code}
                        title={item.title}
                        navigation={this.props.navigation}
                    />
                    {/* <WidgetInfoLiterasi 
                   code={ item.code }
                   title={ item.title } 
                   navigation={ this.props.navigation }
               /> */}
                </>
            )
        } else if ((item.type == 'rakDigital')) {
            return (
                <RakDigital
                    code={item.code}
                    title={item.title}
                    navigation={this.props.navigation}
                />
            )
        } else if ((item.type == 'beritaPilihan')) {
            return (
                <>
                    <MenuTutorialEdukasi
                        code={item.code}
                        title={item.title}
                        navigation={this.props.navigation}
                    />

                    <MenuAudiobook
                        code={item.code}
                        title={item.title}
                        navigation={this.props.navigation}
                    />
                    <MenuNewsPilihan
                        code={item.code}
                        title={item.title}
                        navigation={this.props.navigation}
                    />
                </>
            )
        } else if ((item.type == 'youtube')) {
            return (
                <>

                    <MenuYoutube
                        code={item.code}
                        title={item.title}
                        navigation={this.props.navigation}
                    />
                </>
            )
        } else if ((item.type == 'statistik')) {
            /*
            return (
                <MenuStatistik
                    code={ item.code }
                    title={ item.title } 
                    navigation={ this.props.navigation }
                />
            )
            */

        } else if ((item.type == 'kontenInteroperabilitas')) {
            if (dummy == 1) {

            } else {
                return (
                    <MenuKontenInteropobilitas
                        code={item.code}
                        title={item.title}
                        navigation={this.props.navigation}
                    />
                )
            }
        }

        // if (item.type == h_categories) {
        //     return (
        //         <HomepageContentCategories
        //             code={item.code}
        //             title={item.title}
        //             navigation={this.props.navigation}
        //         />
        //     )
        // } 
        // else if (item.type == h_authors) {
        //     return (
        //         <HomepageAuthors
        //             code={item.code}
        //             title={item.title}
        //             navigation={this.props.navigation}
        //         />
        //     )
        // } else if ((item.type == h_navigation)) {
        //     return (
        //         <NavigationMenu
        //             navigation={this.props.navigation}
        //         />
        //     )
        // } else if (item.type == h_recommended) {
        //     return (
        //         <HomepageRecommended
        //             code={item.code}
        //             title={item.title}
        //             navigation={this.props.navigation}
        //         />
        //     )
        // } else if (item.type == h_news) {
        //     return (
        //         <HomepageNews
        //             code={item.code}
        //             title={item.title}
        //             navigation={this.props.navigation}
        //         />
        //     )
        // } else if (item.type == h_banner) {
        //     return (
        //         <HomepageBanners
        //             code={item.code}
        //             navigation={this.props.navigation}
        //         />
        //     )
        // }
    }

    handleSearchClick = () => {
        this.props.navigation.navigate("SearchPage", { identifier: Identifier.MAIN });
    }

    handleNotificationClick = () => {
        this.props.navigation.navigate("Notification", { identifier: Identifier.MAIN });
    }

    checkNotification = async () => {
        if (_is_mounted) {
            const response = await executeGet(notificationCheck);
            if (response.code == code_ok) {
                this.setState({
                    total_unread: parseInt(response.data.unread),
                    total_read: parseInt(response.data.read)
                })
            }
        }
    }
    handleAlertClose = () => {
        this.setState({
            showAlert: false
        })
    }

    sendToken = async (token) => {

        let formData = new FormData();
        formData.append("token", token);
        const response = await executePost(updateFBToken, formData);

    }

    handleAvatarClick = async () => {
        let result = await DocumentPicker.getDocumentAsync({
            type: "image/*"
        });
        if (result.type == "success") {
            this.setState({
                uploadingAvatar: true
            })

            let nameParts = result.name.split('.');
            let fileType = nameParts[nameParts.length - 1];

            let formData = new FormData();
            formData.append("avatar", {
                uri: result.uri,
                name: result.name,
                type: "image/".fileType,
                mime: result.mimeType
            })
            let contentType = { "Content-Type": "multipart/form-data" }
            let headers = { ...global.defaultHeaders, ...contentType }

            const response = await executeMultipartPost(updateAvatar, formData, headers);
            console.log(JSON.stringify(response));
            if (response.code == code_ok) {
                this.setState({
                    uploadingAvatar: false,
                    photo: response.url
                })
            } else {
                this.setState({
                    uploadingAvatar: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }

        }
    }

    goProfile = () => {
        if (global.versiIos != '1') {
            this.props.navigation.navigate("Profile", { identifier: Identifier.PROFILE });
        }

    }
    render() {
        return (
            <View style={{ flex: 1 }}>
                {
                    this.state.showLoading &&
                    <View style={styles.container}>
                        <SkeletonMain />
                    </View>
                }
                {
                    !this.state.showLoading &&
                    <View style={{ backgroundColor: primary }}>
                        <View
                            style={[styles.fixed, styles.containter, { backgroundColor: primary }]}
                        >
                            {/* <View style={{position: 'absolute', top: -20, left: 0, right : 0 }}>
                                <HomeBg 
                                        width={ Dimensions.get("window").width }
                                        height={ 180 }
                                    />
                            </View> */}
                            {
                                global.versiIos != 1 &&
                                <View style={{ position: 'absolute', top: -20, left: 0, right: 0 }}>
                                    <HomeBg
                                        width={ Dimensions.get("window").width > '430' ? '1000' : Dimensions.get("window").width  }
                                        height={180}
                                    />
                                </View>
                            }

                            {
                                global.versiIos != '1' && this.state.avatar == true &&
                                <View style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginLeft: 24,
                                    marginTop: (global.os == "IOS" ? 64 : 32), marginRight: 24,
                                }}
                                >
                                    {
                                        !this.state.uploadingAvatar &&
                                        <Avatar
                                            src={this.state.photo}
                                            style={{ width: 56, height: 56 }}
                                            onClick={() => this.goProfile()}
                                        />
                                    }
                                    {
                                        this.state.uploadingAvatar &&
                                        <Skeleton
                                            variant="circular"
                                            animation="wave"
                                            width={56}
                                            height={56}
                                        />
                                    }


                                    <View
                                        style={{ marginLeft: 16, justifyContent: "center", flex: 1 }}
                                        // onClick={() => this.goProfile()}
                                    >
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={14}
                                            variant="h3"
                                            color={"white"}
                                        >
                                            Halo
                                        </Typography>

                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={16}
                                            variant="h3"
                                            fontWeight="600"
                                            color={"white"}
                                        >
                                            {truncateText(this.state.fullname, 50)}
                                        </Typography>
                                        <View
                                            style={{
                                                marginTop: 5,
                                                padding: 3,
                                                borderColor: '#f2f2f2',
                                                borderWidth: 1,
                                                borderRadius: 20,
                                                width: '150px',
                                                paddingLeft: 10
                                            }}
                                            onClick={() => this.handleSearchClick()}
                                            >
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={11}
                                                variant="h4"
                                                color={"white"}
                                            >
                                                Cari buku...
                                            </Typography>
                                        </View>
                                    </View>

                                </View>

                            }

                        </View>
                        <ScrollView style={[styles.fixed, styles.scrollview, styles.container], { position: 'relative', marginTop: 100 }}>
                            <View style={{ backgroundColor: "white", flex: 1, borderTopRightRadius: 20, borderTopLeftRadius: 20, marginTop: (global.os == "IOS" ? 48 : 16) }}>
                                <View style={{ marginTop: 16, marginBottom: 16, alignSelf: "center", height: 3, backgroundColor: "#E3E3E3", width: 46, borderRadius: 8 }} />
                                <View style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: 16
                                }}>
                                    <LogoPusnasNew
                                        width={187}
                                        height={39}
                                    />
                                </View>

                                <FlatList
                                    keyExtractor={(item, index) => item.code}
                                    data={this.state.homepage_data}
                                    renderItem={this.renderHomepage}
                                    showsVerticalScrollIndicator={false}
                                />

                            </View>
                        </ScrollView>
                    </View>
                }
                {
                    this.state.showAlert &&
                    <Alert
                        onClose={this.handleAlertClose}
                        severity="error"
                    >
                        <AlertTitle>Error</AlertTitle>
                        {this.state.alertMessage}
                    </Alert>
                }
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flexGrow: 1,
        backgroundColor: primary
    },
    // scrollview: {
    //     flex: 1,
    //     position: "absolute",
    //     top: 0,
    //     bottom: 0,
    //     left: 0,
    //     right: 0,

    // },
    main_container: {
        height: "100%",
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        backgroundColor: "white"
    },
    containter: {
        width: Dimensions.get("window").width, //for full screen
        height: Dimensions.get("window").height, //for full screen,
        backgroundColor: primary
    },
    fixed: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    },
    scrollview: {
        backgroundColor: 'transparent'
    }
})

export default MainHome;
