const SPLASH = "Splash"; 
const LOGIN = "Login";
const SIGNUP = "SignUp";
const FORGOT_PASSWORD = "ForgotPassword";
const MAIN = "Main"; 
const SECTION_KEMENTRIAN = "SectionKementrian"; 
const SEARCH = "SearchPage"; 
const NOTIFICATION = "Notification"; 
const NEWS = "News";
const NEWS_DETAIL = "NewsDetail";
const DETAIL_EDUKASI = "DetailEdukasi";
const BOOK_RACK = "MyBookRack";
const PROFILE = "Profile";
const INPUTKTP = "InputKTP";
const UPDATE_PASSWORD = "UpdatePassword";
const EDIT_DATA = "EditData"; 
const READING_HISTORY = "ReadingHistory";
const CONTENT_TYPE_PAGE = "ContentTypePage"; 
const CONTENT_CATEGORY_PAGE = "ContentCategoryPage";
const SECTION_DETAIL = "SectionDetail";
const SECTION_EDUKASI = "SectionEdukasi";
const CATEGORY_PAGE = "CategoryPage"; 
const CONTENT_DETAIL = "ContentDetail";
const COMMENT_PAGE = "CommentPage"; 



const LOGIN_DESKTOP = "LoginDesktop"; 
const MAIN_DESKTOP = "MainDesktop"; 
const CONTENT_TYPE_PAGE_DESKTOP = "DesktopContentTypePage";
const SECTION_DETAIL_DESKTOP = "DesktopSectionDetail";

export { 
    SPLASH, LOGIN, SIGNUP, FORGOT_PASSWORD, MAIN, SEARCH, NOTIFICATION, NEWS, NEWS_DETAIL, DETAIL_EDUKASI,
    BOOK_RACK, PROFILE, UPDATE_PASSWORD, EDIT_DATA, READING_HISTORY, CONTENT_TYPE_PAGE,
    CONTENT_CATEGORY_PAGE, SECTION_DETAIL, SECTION_EDUKASI, CATEGORY_PAGE, CONTENT_DETAIL, COMMENT_PAGE,
    CONTENT_TYPE_PAGE_DESKTOP, MAIN_DESKTOP,SECTION_DETAIL_DESKTOP, INPUTKTP,
    SECTION_KEMENTRIAN
};