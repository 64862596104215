import React from 'react'; 
import { Platform } from 'react-native'; 
import  AsyncStorage  from '@react-native-async-storage/async-storage';
import uuid from 'react-native-uuid';   
import * as crypto from "crypto";
import { nanoid } from "nanoid";
const algorithm = 'aes-256-ctr';
const secretKey = 'zCYmyO9c7DMsrfFriFPM82jCQbUJyO52';
const iv = nanoid(16);
const storage_key = "@bintangPusnasPNRI"; 
const temporary_key = "@tempBinPusPNRI";



const checkLocalData = async(token, os)=>{
    try {
        const value = await AsyncStorage.getItem(storage_key); 
        console.log('checkLocalData = ' + value)
        if(value == null){
            createDefaultHeader(token, os);
        } else {
            const encrypted_headers = value; 
            let jsonObj = JSON.parse(decryptText(JSON.parse(encrypted_headers)));
            jsonObj.APPTOKEN = token;
            jsonObj.OS = os;
            global.defaultHeaders = jsonObj;
        }

    } catch(e){
        createDefaultHeader(token, os); 
    }
}

const checkLocalParameters = async()=>{
    try {
        const value = await AsyncStorage.getItem(temporary_key); 
        if(value != null){
            let jsonObj = JSON.parse(decryptText(JSON.parse(value))); 
            return jsonObj; 
        } else {
            return null; 
        }
    } catch(error){
        return null; 
    }
}

const saveLocalParameters = async(params)=>{
    try {
        let encryptedParams = encryptText(JSON.stringify(params)); 
        console.log("SIMPAN BRIN");
        console.log(encryptedParams);
        localStorage.setItem("main_data", JSON.stringify(params))
        await AsyncStorage.setItem(temporary_key, JSON.stringify(encryptedParams));  
    } catch(error){
        console.error("[ERROR] Code : 003");
    }
}

const clearLocalParams = async()=>{
    try {
        let value = await AsyncStorage.getItem(temporary_key);
        if(value != null) {
            await AsyncStorage.removeItem(temporary_key); 
        }
    } catch(error){
        console.error("[ERROR] Code : 004");
    }
}

const createDefaultHeader = async(token, os)=>{
    try {
        console.log('createDefaultHeader versiIos = ' + global.versiIos);
        if(global.versiIos == 'undefined' || global.versiIos == undefined || global.versiIos != '1') {
            console.log('createDefaultHeader versiIos disini');
            global.defaultHeaders = {
                "UUID": uuid.v4(), 
                "OS": os, 
                "APPTOKEN": token,
                "TOKEN": token,
                // "ACCESSID": 3,
                // "CLIENTID": 4838
            }
        } else {
            global.defaultHeaders = {
                "UUID": uuid.v4(), 
                "OS": os, 
                "APPTOKEN": 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NzU3NDYyNTMsImV4cCI6MTczNzk1NDI1MywiaXNzIjoia3VidWt1LmNvLmlkIiwiYXVkIjoia3VidWt1LmNvLmlkIiwiZGF0YSI6eyJpZGQiOiJTVVF0TWpBeU1qRXlNakF4U0U4M1dqSlhUQT09IiwidXVpZCI6Ik9USXpNemcyWVRndFltVTVaUzAwWVdReExXRTJNVGN0TXpGak1XRXpOekV3TURGaCIsIm9zIjoiZGFyd2luIiwidXNlciI6eyJpZCI6Ijg1IiwiY29kZSI6IklELTIwMjIxMjIwMUhPN1oyV0wiLCJmdWxsbmFtZSI6IkFkbWluIFB1c2F0IDEiLCJnZW5kZXIiOm51bGwsImVtYWlsIjoiYWRtaW5wdXNhdDFAZ21haWwuY29tIiwicGFzc3dvcmQiOiIiLCJwaG90byI6Imh0dHBzOlwvXC9iaW50YW5ncHVzbmFzLnBlcnB1c25hcy5nby5pZFwvaW1hZ2VzXC9hdmF0YXJcL3VzZXJQLnBuZyIsInN0YXR1cyI6ImFwcHJvdmVkIiwiZXhwaXJlZF9kYXRlIjoiMjAyNy0xMi0yMCAxMjo1ODo0OCIsImFjdGl2ZSI6IjEifX0sIkFQUElEIjoxLCJVSUQiOiJTVVF0TWpBeU1qRXlNakF4U0U4M1dqSlhUQT09IiwiVVVJRCI6Ik9USXpNemcyWVRndFltVTVaUzAwWVdReExXRTJNVGN0TXpGak1XRXpOekV3TURGaCIsIk9TIjoiZGFyd2luIiwiRU1BSUwiOiJhZG1pbnB1c2F0MUBnbWFpbC5jb20iLCJOQU1BIjoiQWRtaW4gUHVzYXQgMSIsIkdFTkRFUiI6bnVsbH0.w6MJ5ib9zJzeYBh1wMP3Amr5Pmd7phcCTqV2SgxAONA',
                "TOKEN": 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NzU3NDYyNTMsImV4cCI6MTczNzk1NDI1MywiaXNzIjoia3VidWt1LmNvLmlkIiwiYXVkIjoia3VidWt1LmNvLmlkIiwiZGF0YSI6eyJpZGQiOiJTVVF0TWpBeU1qRXlNakF4U0U4M1dqSlhUQT09IiwidXVpZCI6Ik9USXpNemcyWVRndFltVTVaUzAwWVdReExXRTJNVGN0TXpGak1XRXpOekV3TURGaCIsIm9zIjoiZGFyd2luIiwidXNlciI6eyJpZCI6Ijg1IiwiY29kZSI6IklELTIwMjIxMjIwMUhPN1oyV0wiLCJmdWxsbmFtZSI6IkFkbWluIFB1c2F0IDEiLCJnZW5kZXIiOm51bGwsImVtYWlsIjoiYWRtaW5wdXNhdDFAZ21haWwuY29tIiwicGFzc3dvcmQiOiIiLCJwaG90byI6Imh0dHBzOlwvXC9iaW50YW5ncHVzbmFzLnBlcnB1c25hcy5nby5pZFwvaW1hZ2VzXC9hdmF0YXJcL3VzZXJQLnBuZyIsInN0YXR1cyI6ImFwcHJvdmVkIiwiZXhwaXJlZF9kYXRlIjoiMjAyNy0xMi0yMCAxMjo1ODo0OCIsImFjdGl2ZSI6IjEifX0sIkFQUElEIjoxLCJVSUQiOiJTVVF0TWpBeU1qRXlNakF4U0U4M1dqSlhUQT09IiwiVVVJRCI6Ik9USXpNemcyWVRndFltVTVaUzAwWVdReExXRTJNVGN0TXpGak1XRXpOekV3TURGaCIsIk9TIjoiZGFyd2luIiwiRU1BSUwiOiJhZG1pbnB1c2F0MUBnbWFpbC5jb20iLCJOQU1BIjoiQWRtaW4gUHVzYXQgMSIsIkdFTkRFUiI6bnVsbH0.w6MJ5ib9zJzeYBh1wMP3Amr5Pmd7phcCTqV2SgxAONA',
                "CLIENTTOKEN": 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NzU3NDYyNTMsImV4cCI6MTczNzk1NDI1MywiaXNzIjoia3VidWt1LmNvLmlkIiwiYXVkIjoia3VidWt1LmNvLmlkIiwiZGF0YSI6eyJpZGQiOiJTVVF0TWpBeU1qRXlNakF4U0U4M1dqSlhUQT09IiwidXVpZCI6Ik9USXpNemcyWVRndFltVTVaUzAwWVdReExXRTJNVGN0TXpGak1XRXpOekV3TURGaCIsIm9zIjoiZGFyd2luIiwidXNlciI6eyJpZCI6Ijg1IiwiY29kZSI6IklELTIwMjIxMjIwMUhPN1oyV0wiLCJmdWxsbmFtZSI6IkFkbWluIFB1c2F0IDEiLCJnZW5kZXIiOm51bGwsImVtYWlsIjoiYWRtaW5wdXNhdDFAZ21haWwuY29tIiwicGFzc3dvcmQiOiIiLCJwaG90byI6Imh0dHBzOlwvXC9iaW50YW5ncHVzbmFzLnBlcnB1c25hcy5nby5pZFwvaW1hZ2VzXC9hdmF0YXJcL3VzZXJQLnBuZyIsInN0YXR1cyI6ImFwcHJvdmVkIiwiZXhwaXJlZF9kYXRlIjoiMjAyNy0xMi0yMCAxMjo1ODo0OCIsImFjdGl2ZSI6IjEifX0sIkFQUElEIjoxLCJVSUQiOiJTVVF0TWpBeU1qRXlNakF4U0U4M1dqSlhUQT09IiwiVVVJRCI6Ik9USXpNemcyWVRndFltVTVaUzAwWVdReExXRTJNVGN0TXpGak1XRXpOekV3TURGaCIsIk9TIjoiZGFyd2luIiwiRU1BSUwiOiJhZG1pbnB1c2F0MUBnbWFpbC5jb20iLCJOQU1BIjoiQWRtaW4gUHVzYXQgMSIsIkdFTkRFUiI6bnVsbH0.w6MJ5ib9zJzeYBh1wMP3Amr5Pmd7phcCTqV2SgxAONA',
                // "ACCESSID": 3,
                // "CLIENTID": 4838
            }
        }
        
        const encrypted_headers = encryptText(JSON.stringify(global.defaultHeaders)); 
        await AsyncStorage.setItem(storage_key, JSON.stringify(encrypted_headers));  
    } catch(e){
        console.error("[ERROR] Code : 002"); 
    }
    
}

const createAuthHeader = async(params)=>{
    try {
        console.log("token login util = " + params.token);
        console.log(params);
        global.token = params.token;

        let token = params.token;
        let user = params.user; 

        let os = global.defaultHeaders.OS; 
        let uuid = global.defaultHeaders.UUID;
        let appToken = params.token; 

        global.defaultHeaders = {
            "UUID": uuid, 
            "OS": os, 
            "APPTOKEN": appToken, 
            "TOKEN": appToken,
            "CLIENTTOKEN": token, 
            "UID": user.code,
            // "ACCESSID": 3,
            // "CLIENTID": 4838
        }
        console.log("HEADERS");
        console.log(JSON.stringify(global.defaultHeaders));

        // const encrypted_headers = encryptText(JSON.stringify(global.defaultHeaders)); 
        // await AsyncStorage.setItem(storage_key, JSON.stringify(encrypted_headers));  
        
    } catch(e){
        console.error("[ERROR] Code : 057");
    }
}

const createAuthHeaderReader = async(params, uidParam, uuidParam)=>{
    try {
        console.log("createAuthHeaderReader = " + params);
        console.log(params);
        global.token = params;

        let token = params;
        let uid = uidParam; 

        let os = global.defaultHeaders.OS; 
        let uuid = uuidParam;

        global.defaultHeaders = {
            "UUID": uuid, 
            "OS": os, 
            "APPTOKEN": token, 
            "TOKEN": token,
            "CLIENTTOKEN": token, 
            "UID": uid,
            // "ACCESSID": 3,
            // "CLIENTID": 4838
        }
        console.log("HEADERS");
        console.log(JSON.stringify(global.defaultHeaders));

        // const encrypted_headers = encryptText(JSON.stringify(global.defaultHeaders)); 
        // await AsyncStorage.setItem(storage_key, JSON.stringify(encrypted_headers));  
        
    } catch(e){
        console.error("[ERROR] Code : 057");
    }
}

const updateAuthHeader = async(params)=>{
    try {
        console.log("client_token = " + params.client_token);
        console.log("app_token_new = " + params.app_token_new);
        global.token = params.app_token_new;

        // let token = params.app_token_new;
        let user = params.user; 
        let os = global.defaultHeaders.OS; 
        let uuid = global.defaultHeaders.UUID;
        // let appToken = global.defaultHeaders.APPTOKEN; 

        global.defaultHeaders = {
            "UUID": uuid, 
            "OS": os, 
            "APPTOKEN": params.app_token_new, 
            "TOKEN": params.app_token_new,
            "CLIENTTOKEN": params.client_token, 
            "UID": user.code,
            // "ACCESSID": 3,
            // "CLIENTID": 4838
        }
        console.log("HEADERS");
        console.log(JSON.stringify(global.defaultHeaders));
        //global.token = 
        const encrypted_headers = encryptText(JSON.stringify(global.defaultHeaders)); 
        await AsyncStorage.setItem(storage_key, JSON.stringify(encrypted_headers)); 
        await AsyncStorage.setItem(temporary_key.stringify(encrypted_headers));  
        
    } catch(e){
        console.error("[ERROR] Code : 057 UPDATE");
    }
}

const removeCredentials = async(callback)=>{
    await AsyncStorage.removeItem(storage_key);
    callback();
}

const clearStorage = async(token, os)=>{
    try {
        await AsyncStorage.removeItem(storage_key); 
        createDefaultHeader(token, os); 
    } catch(e){
        console.error("[ERROR] code 83");
    }
}


const encryptText = (text) => {
    
    const cipher = crypto.createCipheriv(algorithm, secretKey, iv);
    const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);
    return {
        iv: iv.toString('hex'),
        content: encrypted.toString('hex'),
    };
};
const decryptText = (hash) => {
    const decipher = crypto.createDecipheriv(algorithm, secretKey, Buffer.from(hash.iv, 'hex'));
    const decrpyted = Buffer.concat([decipher.update(Buffer.from(hash.content, 'hex')), decipher.final()]);
    return decrpyted.toString();
};

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const executePost = async(url, formData )=>{
    try {

        // let cacheName =  "post_cache_" + url.replace("https://bintangpusnas.perpusnas.go.id/api/","");
        // let caching=false;

        // if(!window.localStorage.getItem("@bintangPusnasPNRI")){
        //     window.localStorage.clear();
        // }
        // if(cacheName=="post_cache_homepageRecommended" || cacheName == "post_cache_homepageBanner" || cacheName=="post_cache_sectionContent" || cacheName=="post_cache_contentDetail"){
        //     caching=true;

        //     if(formData.get('page')) cacheName+=`_${formData.get('page')}`;
        //     if(formData.get('limit')) cacheName+=`_${formData.get('limit')}`;
        //     if(formData.get('code')) cacheName+=`_${formData.get('code')}`;
        //     if(formData.get('code_sub_category')) cacheName+=`_${formData.get('code_sub_category')}`;
        //     if(formData.get('keyword')) cacheName+=`_${formData.get('keyword')}`;
        //     if(formData.get('sort_by')) cacheName+=`_${formData.get('sort_by')}`;
        //     // cacheName+=`${page}_${limit}_${code}_${code_sub_category}_${keyword}_${sort_by}`;

        //     // if(cacheName=="post_cache_sectionContent"){
        //     //     // page: limit: code: code_sub_category: keyword: 
        //     //     const page =formData.get('page');
        //     //     const limit = formData.get('limit');
        //     //     const code = formData.get('code');
        //     //     const code_sub_category = formData.get('code_sub_category');
        //     //     const keyword = formData.get('keyword');
        //     //     const sort_by = formData.get('sort_by');
        //     //     cacheName+=`${page}_${limit}_${code}_${code_sub_category}_${keyword}_${sort_by}`;
        //     // }
        //     // else if(cacheName=="post_cache_contentDetail"){
        //     //     const code = formData.get('code');
        //     //     // console.log(cacheName, code);
        //     //     cacheName+=`_${code}`;   
        //     // }
        //     console.log(`Checking POST Cache Status ${cacheName}`);           

        // }

        // if(caching==true){
        //     const dataCache = window.localStorage.getItem(cacheName);    
        //     if(dataCache){
        //         var object = JSON.parse(dataCache),
        //         dateString = object.cachetimestamp,
        //         now = new Date().getTime().toString();
        //         if((now-dateString)<3600000) {
        //             // console.log(`POST Using ${cacheName} : Cache Age 3600000 > ${(now-dateString)}  `); 
        //             return JSON.parse(dataCache);
        //         }
        //     }    
        // }

        let postData = {
            method : 'post', 
            headers : global.defaultHeaders, 
            body : formData
        }

        const response = await fetch(url, postData); 
        const json = await response.text(); 
        const jsonCache = JSON.parse(json);
        // if(caching==true){
        //     if(jsonCache.code) {
        //         if(jsonCache.code == '200') {
        //             console.log("Try Saving cache", cacheName, jsonCache);           
        //             if(jsonCache.data.length>0){
        //                 const now = new Date().getTime().toString();            
        //                 jsonCache.cachetimestamp=now;
        //                 const data =JSON.stringify(jsonCache);
        //                 console.log("Saving cache", cacheName, data);           
        //                 localStorage.setItem(cacheName, data);     
        //             } else if(jsonCache.data.code){
        //                 const data =JSON.stringify(jsonCache);
        //                 // console.log("Saving cache", cacheName, data);           
        //                 localStorage.setItem(cacheName, data);     
        //             }
        //         }
        //     }
        // }

        return jsonCache; //JSON.parse(json); 

    } catch(error){
        localStorage.clear();
        document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        window.postMessage({ id: "sign_out" })
        
        // let errorObj = {
        //     code : 500, 
        //     msg : error
        // }
        // return errorObj; 
    }
}
const executeMultipartPost = async(url, formData, headers )=>{
    try {
        let postData = {
            method : 'post', 
            headers : headers, 
            body : formData
        }
        const response = await fetch(url, postData); 
        const json = await response.text(); 
        //console.log(json);
        return JSON.parse(json); 

    } catch(error){
        localStorage.clear();
        document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        window.postMessage({ id: "sign_out" })
        // let errorObj = {
        //     code : 500, 
        //     msg : 'Network Error'
        // }
        // return errorObj; 
    }
}
const executeGet = async(url)=>{
    try {
        console.log('Headers Get= ' + JSON.stringify(global.defaultHeaders))
        let postData = {
            method : 'get', 
            headers : global.defaultHeaders, 
        }

        // const cacheName =  "get_cache_" + url.replace("https://bintangpusnas.perpusnas.go.id/api/","");
        // let caching=false;
       
        // if(cacheName=="get_cache_homepage"){
        //     caching=true;
        // }

        // if(caching==true){
        //     const dataCache = window.localStorage.getItem(cacheName);    
        //     // console.log(`Checking ${cacheName} Exists....`, dataCache);    
        //     if(dataCache){
        //         var object = JSON.parse(dataCache),
        //         dateString = object.cachetimestamp,
        //         now = new Date().getTime().toString();
        //         if((now-dateString)<3600000) {
        //             // console.log(`GET Using ${cacheName} : Cache Age 3600000 > ${(now-dateString)}  `); 
        //             return JSON.parse(dataCache);
        //         }
        //     }    
        // }

        const response = await fetch(url, postData); 
        const json = await response.json(); 
        console.log("RES FETCH 1 = " + JSON.stringify(response))
        console.log("RES FETCH 2 = " + url + " - " + JSON.stringify(json))
        // if(caching==true){
        //     const now = new Date().getTime().toString();
        //     json.cachetimestamp=now;
        //     const data =JSON.stringify(json);
        //     localStorage.setItem(cacheName, data);     
        //     // console.log("Saving cache", cacheName);           
        // }
        return json; 

    } catch(error){
        /*
        localStorage.clear();
        document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        window.postMessage({ id: "sign_out" })
        */
        console.error("[ERROR] Execute Get"); 
        console.error(error.message);
        // let errorObj = {
        //     code : 500, 
        //     msg : 'Network Error'
        // }
        // return errorObj; 
    }
}

const nameOfMonth = (month)=>{
    let name = ''; 
    switch(month){
        case 1 : 
            name = 'Januari';
            break;
        case 2 : 
            name = 'Februari';
            break;   
        case 3 : 
            name = 'Maret';
            break; 
        case 4 : 
            name = 'April';
            break;
        case 5 : 
            name = 'Mei';
            break;
        case 6 : 
            name = 'Juni';
            break;    
        case 7 : 
            name = 'Juli';
            break;
        case 8 : 
            name = 'Agustus';
            break;    
        case 9 : 
            name = 'September';
            break;
        case 10 : 
            name = 'Oktober';
            break;
        case 11 : 
            name = 'November';
            break;
        case 12 : 
            name = 'Desember';
            break;
        default:
            name = 'Undefined';
            break;     
    }

    return name;
}  

const nameOfDay = (date)=>{
    var days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
    return days[date.getDay()];
} 

const truncateText = (text, length) =>{
    try {
        let txt = text;
        if(text.length > length) {
            txt = text.substring(0, length) + '...';
        }

        txt = txt.replace(/(\r\n|\n|\r)/gm, "");
        return txt; 
    } catch(err){
        return "-";
    }
}
const isEven = (number)=>{
    return (number % 2) == 0; 
}

const isEvenThree = (number)=>{
    // console.log("isEvenThree = " + number % 3)
    return (number % 3) == 0; 
}

const isEvenThreeLast = (number)=>{
    // console.log("isEvenThree = " + number % 3)
    return (number % 3) == 2; 
}

const navigateContentDetail = (navigation, params)=>{
    if(global.platform == "mobile") {
        navigation.navigate("ContentDetail", params); 
    } else {
        
        navigation.navigate("DesktopContentDetail", params);
    }
}

const dataURIToBlob = (dataURI)=>{
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
}

const getQueryStrings = (text)=> { 
    var assoc  = {};
    var decode = function (s) { return decodeURIComponent(s.replace(/\+/g, " ")); };
    var queryString = text.split("?")[1]; 
    var keyValues = queryString.split('&'); 
  
    for(var i in keyValues) { 
      var key = keyValues[i].split('=');
      if (key.length > 1) {
        assoc[decode(key[0])] = decode(key[1]);
      }
    } 
  
    return assoc; 
} 

export { checkLocalData, checkLocalParameters, saveLocalParameters,  createDefaultHeader, clearStorage, capitalizeFirstLetter, executePost, executeGet, 
    nameOfMonth, nameOfDay, createAuthHeader, createAuthHeaderReader, updateAuthHeader, truncateText, isEven, isEvenThree, isEvenThreeLast, removeCredentials,
     navigateContentDetail, executeMultipartPost, dataURIToBlob, clearLocalParams , getQueryStrings} 
