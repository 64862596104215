import React, { Component } from 'react'; 
import { View, StyleSheet, FlatList, Image , Pressable } from 'react-native'; 
import { Alert, AlertTitle, Button, Card, CardActionArea, CardContent, 
    Typography, Skeleton, Avatar, Dialog, DialogActions, DialogContent, DialogContentText, 
     DialogTitle , IconButton } from '@mui/material';
import { code_ok, code_empty, code_error, code_auth_error, profile , userSignOut, userDelete } from '../constants/api';
import { color_text, sub_text } from '../constants/colors';
import * as Identifier from '../constants/PageIdentifiers';
import * as Command from '../constants/Commands';
import { executeGet, executePost } from '../utilities/Utilities';
import { IconProfilRakSaya, IconProfilArrow, IconProfilBukuTerakhir, IconProfilLogout,IconProfileDelete,
    SettingsIcon , PasswordIcon, AboutIcon , ShareIcon, GraphIcon, IconRakDigital } from '../assets/Icons';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LoadingDialog from '../components/LoadingDialog';

var _is_mounted = false;
class Profile extends Component {
    constructor(props){
        super(props) 
        this.state = {
            showLoading : true, 
            showLoadingDialog: false,
            loadingDialogMessage: "",
            showAlert : false, 
            moreLoading :false, 
            loadingMessage : "", 
            alertMessage : "",
            data : [], 
            dummy_data : [
                {i : 0},{i : 1},{i : 2},{i : 3},{i : 4},{i : 5},{i : 6},{i : 7}
            ],
            data : {},
            showConfirmLogout : false,
            showConfirmDelete : false,
            fullname: localStorage.getItem('fullname'),
            email: localStorage.getItem('email')
        }


        this.goBack = this.goBack.bind(this);
        this.fetchProfile = this.fetchProfile.bind(this);  
        this.navigateUpdatePassword = this.navigateUpdatePassword.bind(this); 
        this.navigateEditProfile = this.navigateEditProfile.bind(this);
        this.navigateReadingHistory = this.navigateReadingHistory.bind(this); 
        this.shareApp = this.shareApp.bind(this);
        this.changeAvatar = this.changeAvatar.bind(this); 
        this.confirmLogout = this.confirmLogout.bind(this); 
        this.handleConfirmClose = this.handleConfirmClose.bind(this); 
        this.handleLogout = this.handleLogout.bind(this);
        this.executeLogout = this.executeLogout.bind(this); 

    }

    componentDidMount = ()=>{
        console.log("PROFILE MOUNTED");
        if (global.os == "ANDROID") {
            JSBridge.launchFunction(Command.SET_PAGE, Identifier.PROFILE);    
        }
        _is_mounted = true;
        window.addEventListener("message", (event)=>{
            if(event.data.id == "go_back"){
                if(event.data.page == Identifier.PROFILE){
                    this.goBack(); 
                }
            } else if (event.data.id == "update_fullname"){
                // let fullname = event.data.fullname; 
                // let data = { ...this.state.data } 
                // data.fullname = fullname;
                // this.setState({
                //     data : data
                // })

            }
        });
        this.fetchProfile();
    
    }
    componentWillUnmount = ()=>{
        _is_mounted = false;
        window.removeEventListener("message", null);
    }
    //ANCHOR Go Back
    goBack = ()=>{
        // this.props.navigation.navigate(this.props.route.params.identifier);
        this.props.navigation.goBack();
    }

    //ANCHOR Fetch Profile 
    fetchProfile = async()=>{
        const response = await executeGet(profile); 
        console.log("profile = " + JSON.stringify(response))
        if(response.code == code_ok){
            this.setState({
                showLoading : false, 
                data : response.data 
            })
        } else {
            if(response.code == code_auth_error){
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                
                window.postMessage({ id: "sign_out"});
            } else {
                this.setState({
                    showLoading : false, 
                    showAlert : true, 
                    alertMessage : response.msg
                })
            }
        }
    }
    
    navigateUpdatePassword = ()=>{
        this.props.navigation.navigate("UpdatePassword", { identifier : Identifier.PROFILE });
    }
    navigateEditProfile = ()=>{
        let params = { ...this.state.data , identifier : Identifier.PROFILE }
        this.props.navigation.navigate("EditData", params); 
    }
    navigateReadingHistory = ()=>{
        this.props.navigation.navigate("ReadingHistory", { identifier : Identifier.PROFILE}); 
    }
    shareApp = ()=>{
        // if(global.os == "ANDROID") {
        //     JSBridge.launchFunction(Command.SHARE_APP, "-");
        // } else {
        //     //TODO: IOS Bridge 
            
        // }
    }

    changeAvatar = ()=>{
        //TODO - Change Avatar Click
        
    }

    confirmLogout = ()=>{
        if(_is_mounted){
            this.setState({
                showConfirmLogout : true
            })
        }
    }
    handleConfirmClose = ()=>{
        if(_is_mounted){
            this.setState({
                showConfirmLogout : false
            })
        }
    }
    handleLogout = ()=>{
        if(_is_mounted ){
            this.setState({
                showConfirmLogout : false
            }, ()=>{
                this.executeLogout()
            })
        }
    }
    executeLogout = async()=>{
        this.setState({
            showLoadingDialog : true,
            loadingDialogMessage : "Mohon menunggu"
        });
        const response = await executeGet(userSignOut);
        if(response.code == code_ok){
            this.setState({
                showLoadingDialog : false, 
            },()=>{
                // window.postMessage({ id: "sign_out"});
                if(global.os == "ANDROID") {
                    JSBridge.launchFunction(Command.SIGN_OUT, "Berhasil Logout");
                    window.postMessage({ id: "sign_out"});
                } else {
                    window.postMessage({ id: "sign_out"});
                }
            });
        } else {
            this.setState({
                showLoadingDialog : false, 
            },()=>{
                window.postMessage({ id: "sign_out"});
            });
        }
    }

    confirmDelete = ()=>{
        if(_is_mounted){
            this.setState({
                showConfirmDelete : true
            })
        }
    }
    handleConfirmDeleteClose = ()=>{
        if(_is_mounted){
            this.setState({
                showConfirmDelete : false
            })
        }
    }
    handleDelete = ()=>{
        if(_is_mounted ){
            this.setState({
                showConfirmDelete : false
            }, ()=>{
                this.executeDelete()
            })
        }
    }
    executeDelete = async()=>{
        this.setState({
            showLoadingDialog : true,
            loadingDialogMessage : "Mohon menunggu"
        });
        const response = await executePost(userDelete);
        if(response.code == code_ok){
            this.setState({
                showLoadingDialog : false, 
            },()=>{
                window.postMessage({ id: "sign_out"});
            });
        } else {
            this.setState({
                showLoadingDialog : false, 
            },()=>{
                window.postMessage({ id: "sign_out"});
            });
        }
    }

    render(){
        return(
            <View style={{ flex : 1}}>
                <View style={{
                    height : 56 , 
                    flexDirection : "row", 
                    alignItems : "center", 
                    backgroundColor : "white" ,
                    padding : 12,
                    // marginTop: (global.os == "IOS" ? 48 : 0),
                }}>
                    
                    <IconButton
                        style={{ marginRight : 16 }}
                        onClick={()=>{ this.goBack() }}
                    >
                        <ChevronLeftIcon 
                            sx={{color : color_text }}
                        />
                    </IconButton>                    
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 18 }
                        fontWeight={"600"}
                        style={{ marginLeft : 15 }}
                    >
                        Profil Saya
                    </Typography>
                </View>
                <View style={{ flex : 0.95}}>
                {
                    this.state.showLoading && 
                    <View style={{
                        marginLeft : 16, 
                        marginRight : 16, 
                        marginTop : 8
                    }}>

                        <View style={{flexDirection : "row", alignItems : "center"}}>
                            <Skeleton 
                                animation="wave"
                                variant="circular"
                                width={64}
                                height={64}
                            />
                            <View style={{marginLeft : 16}}>
                                <Skeleton
                                    animation="wave" 
                                    variant="text"
                                    sx={{fontSize : "1.5rem", width : 180}} 
                                    style={{marginBottom : 2}}
                                />
                                <Skeleton 
                                    animation="wave"
                                    variant="text"
                                    sx={{fontSize : "1.5rem", width : "100%" }} 
                                />
                            </View>
                        </View> 
                        <Skeleton 
                            animation="wave"
                            variant="rectangular"
                            style={{ marginTop : 32, borderRadius : 8 }}
                            sx={{ width : "100%", height : 64 }} 
                        />
                        <Skeleton 
                            animation="wave"
                            variant="rectangular"
                            style={{ marginTop : 8, borderRadius : 8 }}
                            sx={{ width : "100%", height : 64 }} 
                        />
                        <Skeleton 
                            animation="wave"
                            variant="rectangular"
                            style={{ marginTop : 8, borderRadius : 8 }}
                            sx={{ width : "100%", height : 64 }} 
                        />
                        <Skeleton 
                            animation="wave"
                            variant="rectangular"
                            style={{ marginTop : 8, borderRadius : 8 }}
                            sx={{ width : "100%", height : 64 }} 
                        />

                    </View>
                }
                {
                    !this.state.showLoading &&
                    <View style={{
                        flex : 1,
                    }}>
                        <View style={{flexDirection : "row", alignItems : "center", backgroundColor: 'white',
                                paddingLeft : 16, 
                                paddingRight : 16, 
                                paddingTop : 5,
                                paddingBottom: 16,
                                marginBottom: 10
                            }}>
                            <Avatar 
                                src={ this.state.data.photo }
                                style={{ width : 64, height : 64  }}
                                onClick={ this.changeAvatar }
                            />
                            <View style={{marginLeft : 16}}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 16 }
                                    fontWeight={"600"}
                                    color={ color_text }
                                    style={{ marginBottom : 4}}
                                >
                                    { this.state.data.user.name }
                                </Typography>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 14 }
                                    fontWeight={"500"}
                                    color={ sub_text }
                                >
                                    { this.state.data.user.email }
                                </Typography>
                            </View>
                        </View>
                        <View 
                            style={{ marginTop : 8, marginBottom : 8 , height : 0.5, backgroundColor : "silver"}}
                        />

                        
                        {
                            //ANCHOR == Edit Data ==// 
                        }
                        <View
                            style={{ backgroundColor: 'white' }}
                        >
                            <CardActionArea
                                onClick={ this.navigateEditProfile }
                            >
                                <View style={{flexDirection : "row", alignItems : "center", padding : 16 }} >
                                    <IconProfilBukuTerakhir 
                                        width={30}
                                        height={30}/> 
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={ 14 }
                                        fontWeight={"600"}
                                        style={{ marginLeft : 16 , flex : 1}}
                                    >
                                        Edit Data
                                    </Typography>
                                </View>
                                <View 
                                    style={{ backgroundColor : "silver", height : 0.5 }}
                                />
                            </CardActionArea>
                        </View>
                        {/* <View
                            style={{ backgroundColor: 'white' }}
                        >
                            <CardActionArea
                                // onClick={ this.navigateEditProfile }
                                onClick={()=>{ this.props.navigation.navigate("MyBookRack", { identifier : Identifier.MAIN })}}
                            >
                                <View style={{flexDirection : "row", alignItems : "center", padding : 16 }} >
                                    <IconProfilRakSaya 
                                        width={30}
                                        height={30}/> 
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={ 14 }
                                        fontWeight={"600"}
                                        style={{ marginLeft : 16 , flex : 1}}
                                    >
                                        Rak Pinjam
                                    </Typography>
                                </View>
                                <View 
                                    style={{ backgroundColor : "silver", height : 0.5 }}
                                />
                            </CardActionArea>
                        </View> */}
                        {
                            //ANCHOR == Ganti Password ==// 
                        }
                        <View
                            style={{ backgroundColor: 'white' }}
                        >
                            <CardActionArea
                                onClick={ this.navigateUpdatePassword }
                            >
                                <View style={{flexDirection : "row", alignItems : "center", padding : 16 }} >
                                    <PasswordIcon /> 
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={ 14 }
                                        fontWeight={"600"}
                                        style={{ marginLeft : 16 , flex : 1}}
                                    >
                                        Ganti Password
                                    </Typography>
                                </View>
                                <View 
                                    style={{ backgroundColor : "silver", height : 0.5, paddingLeft: 16, paddingRight: 16 }}
                                />
                            </CardActionArea>
                        </View>
                        <View
                            style={{ backgroundColor: 'white' }}
                        >
                            <CardActionArea
                                onClick={ this.confirmLogout }
                            >
                                <View style={{flexDirection : "row", alignItems : "center", padding : 16 }} >
                                    <IconProfilLogout 
                                        width={30}
                                        height={30}/> 
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={ 14 }
                                        fontWeight={"600"}
                                        style={{ marginLeft : 16 , flex : 1}}
                                    >
                                        Keluar

                                    </Typography>
                                </View>
                                <View 
                                    style={{ backgroundColor : "silver", height : 0.5, paddingLeft: 16, paddingRight: 16 }}
                                />
                            </CardActionArea>
                        </View>
                        <View
                            style={{ backgroundColor: 'white' }}
                        >
                            <CardActionArea
                                onClick={ this.confirmDelete }
                            >
                                <View style={{flexDirection : "row", alignItems : "center", padding : 16 }} >
                                    <IconProfileDelete 
                                        width={30}
                                        height={30}/> 
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={ 14 }
                                        fontWeight={"600"}
                                        style={{ marginLeft : 16 , flex : 1}}
                                    >
                                        Hapus Akun

                                    </Typography>
                                </View>
                                <View 
                                    style={{ backgroundColor : "silver", height : 0.5, paddingLeft: 16, paddingRight: 16 }}
                                />
                            </CardActionArea>
                        </View>
                        {
                            //ANCHOR == Aktivitas Baca ==// 
                        }
                        {/* <View
                            style={{ borderRadius : 8, marginBottom : 8 }}
                        >
                            <CardActionArea
                                onClick={ this.navigateReadingHistory }
                            >
                                <View style={{flexDirection : "row", alignItems : "center", padding : 12 }} >
                                    <GraphIcon /> 
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={ 14 }
                                        fontWeight={"600"}
                                        style={{ marginLeft : 16 , flex : 1}}
                                    >
                                        Aktivitas Baca

                                    </Typography>
                                </View>
                                <View 
                                    style={{ backgroundColor : "silver", height : 0.5 }}
                                />
                            </CardActionArea>
                        </View> */}
                        
                        {
                            //ANCHOR == Bagikan Aplikasi ==// 
                        }
                        {/* <View
                            style={{ borderRadius : 8, marginBottom : 8 }}
                        >
                            <CardActionArea
                                onClick={ this.shareApp }
                            >
                                <View style={{flexDirection : "row", alignItems : "center", padding : 12 }} >
                                    <ShareIcon /> 
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={ 14 }
                                        fontWeight={"600"}
                                        style={{ marginLeft : 16 , flex : 1}}
                                    >
                                        Bagikan
                                    </Typography>
                                </View>
                                <View 
                                    style={{ backgroundColor : "silver", height : 0.5 }}
                                />
                            </CardActionArea>
                        </View> */}
                        {
                            //ANCHOR == About ==// 
                        }
                        {/* <View
                            style={{ borderRadius : 8, marginBottom : 8 }}
                        >
                            <CardActionArea>
                                <View style={{flexDirection : "row", alignItems : "center", padding : 12 }} >
                                    <AboutIcon /> 
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={ 14 }
                                        fontWeight={"600"}
                                        style={{ marginLeft : 16 , flex : 1}}
                                    >
                                        Tentang Aplikasi
                                    </Typography>
                                </View>
                                <View 
                                    style={{ backgroundColor : "silver", height : 0.5 }}
                                />
                            </CardActionArea>
                        </View> */}

                        {/* <Button
                            style={{
                                position : "absolute", 
                                bottom : 16, 
                                right : 0, 
                                left : 0,
                                textTransform : "none", 
                                borderRadius : 8, 
                                padding : 10
                            }}
                            variant="contained"
                            onClick={ this.confirmLogout }
                        >
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 16 }
                                fontWeight="500"
                            >
                            Keluar
                            </Typography>
                        </Button> */}
                    </View>
                }
                </View>
                <Dialog 
                    open={ this.state.showConfirmLogout } 
                    onClose={ this.handleConfirmClose }
                >
                    <DialogTitle>
                        Konfirmasi
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Anda diharuskan untuk login kembali ketika menjalankan aplikasi ini.{"\r\n"}Lanjutkan?
                        </DialogContentText>    
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ this.handleConfirmClose }>Batal</Button>
                        <Button onClick={ this.handleLogout }>Lanjutkan</Button>
                    </DialogActions>

                </Dialog>

                <Dialog 
                    open={ this.state.showConfirmDelete } 
                    onClose={ this.handleConfirmDeleteClose }
                >
                    <DialogTitle>
                        Konfirmasi
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Akun dan semua data yang didalamnya akan secara otomatis terhapus.{"\r\n"}Lanjutkan?
                        </DialogContentText>    
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={ this.handleConfirmDeleteClose }>Batal</Button>
                        <Button onClick={ this.handleDelete }>Lanjutkan</Button>
                    </DialogActions>

                </Dialog>
                <LoadingDialog
                    open={this.state.showLoadingDialog}
                    message={this.state.loadingDialogMessage}
                />
                {
                    this.state.showAlert && 
                    <Alert 
                        onClose={()=>{ this.goBack() }}
                        severity="error"
                    > 
                        <AlertTitle>Error</AlertTitle>
                        { this.state.alertMessage }
                    </Alert>
                }
            </View>
        )
    }
}


export default Profile;