import React, { Component } from 'react';
import { StyleSheet, View, FlatList, Image, Dimensions } from 'react-native';
import { code_ok, code_error, code_auth_error, code_empty, homepageNews, widgetTutorialEdukasi } from '../../constants/api';
import { Skeleton, Typography, Card, CardActionArea, CardMedia, CardContent, Button } from '@mui/material';
import { executeGet, executePost, truncateText, getQueryStrings, navigateContentDetail } from '../../utilities/Utilities';
import AlertDialog from '../AlertDialog';
import { color_text, sub_text, primary } from '../../constants/colors';
import * as Identifier from '../../constants/PageIdentifiers';

class MenuTutorialEdukasiDesktop extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            showAlert: false,
            alertMessage: "",
            loadingMessage: "",
            code: this.props.code,
            data: [],
            dummy_data: [
                { i: 0 }, { i: 1 }, { i: 2 }
            ]
        }

        this.renderSkeleton = this.renderSkeleton.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.fetchNews = this.fetchNews.bind(this);
        this.handleNewsClick = this.handleNewsClick.bind(this);
        this.handleMoreClick = this.handleMoreClick.bind(this);
    }

    componentDidMount = () => {
        //console.log("MENU YOUTUBE DESKTOP");
        this.fetchNews();
    }

    handleMoreClick = (code, title) => {
        console.log("CODE ");
        console.log(code);
        this.props.navigation.navigate("NewsDesktop", { identifier : Identifier.NEWS, code : code, title : title, origins : "NEWS"}); 
    }

    renderSkeleton = ({ item, index, separators }) => {
        return (
            <View style={{
                width: 220,
                marginLeft: (index == 0 ? 16 : 0),
                marginRight: (index == (this.state.dummy_data.length - 1) ? 16 : 8)
            }}>
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    style={{ borderRadius: 8, width: 220, height: 110, marginBottom: 8 }}
                />
                <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "1.5rem", width: 180 }}
                    style={{ marginBottom: 4 }}
                />
                <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "1.2rem", width: 120 }}
                    style={{ marginBottom: 4 }}
                />
            </View>
        )
    }

    renderItem = ({ item, index, separators }) => {
        //== ATTENTION FOR YOUTUBE TYPE ==//
        let window_width = Dimensions.get("window").width; 
        let book_width = (window_width / 6) - 10 ; 
        let book_height = book_width + 5;
        return (
            // <Card
            //     sx={{ maxWidth: 220 }}
            //     style={{
            //         borderRadius: 8,
            //         marginLeft: (index == 0 ? 16 : 0),
            //         marginRight: (index == (this.state.data.length - 1) ? 26 : 26),
            //         marginBottom: 8,
            //         marginTop: 4,
            //         boxShadow: "0px 33px 74px rgba(0, 0, 0, 0.0196802), 0px 13.7866px 30.9154px rgba(0, 0, 0, 0.0282725), 0px 7.37098px 16.5289px rgba(0, 0, 0, 0.035), 0px 4.13211px 9.26595px rgba(0, 0, 0, 0.0417275), 0px 2.19453px 4.92108px rgba(0, 0, 0, 0.0503198), 0px 0.913195px 2.04777px rgba(0, 0, 0, 0.07)"
            //     }}>
            //     <CardActionArea
            //         style={{flexDirection : "row"}}
            //         onClick={() => { this.handleNewsClick(item, index) }}
            //     >
            //         <View style={{flex:0.3}}>
            //         <CardMedia
            //             image={item.cover}
            //             height={110}
            //             component="img"
            //             style={{top:0}}
            //         />
            //         </View>
            //         <CardContent
            //             style={{ flex: 0.7 }}
            //         >
            //             <Typography
            //                 fontFamily="Open Sans"
            //                 fontSize={14}
            //                 fontWeight={"600"}
            //                 color={color_text}
            //                 style={{ marginBottom: 8, flex: 1 }}
            //             >
            //                 {item.title}
            //             </Typography>

            //             <Typography
            //                 fontFamily="Open Sans"
            //                 fontSize={12}
            //                 fontWeight={"400"}
            //                 color={sub_text}
            //                 style={{ marginBottom: 8 }}
            //             >
            //                 {item.author}
            //             </Typography>
            //         </CardContent>
            //     </CardActionArea>
            // </Card>
            <Card
                style={{ 
                    width : book_width, 
                    borderRadius : 8, 
                    marginLeft : 16, 
                    marginRight : 16,
                    marginBottom : 8,
                    
                }}
            >
                <CardActionArea
                    sx={{ height : "100%"}}
                    onClick={()=>{ this.contentDetail(item, index)}}
                >
                    <Image
                        style={{
                            width : book_width,
                            height : book_height,
                        }}
                        resizeMode={"cover"}
                        source={{ uri : item.cover }}
                        onError={(error)=>{ this.onImageError(item, index)}}
                    />


                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 14 }
                        color={ color_text } 
                        fontWeight={"600"}
                        style={{ 
                            marginLeft : 12, marginTop : 8, marginBottom : 8, marginRight : 12 
                        }}
                    >
                        { truncateText(item.title, 32)  }
                    </Typography>
                    <Typography
                        fontFamily="Open Sans"
                        color={ sub_text }
                        fontSize={ 12 }
                        style={{
                            marginLeft : 12 ,
                            marginBottom : 12, 
                            marginRight : 12
                        }}
                    >
                        { truncateText(item.full_name, 20 )}
                    </Typography>
                </CardActionArea>
            </Card>
        )
    }

    //ANCHOR - Content Detail
    contentDetail = (item,index)=>{
        console.log("contentDetail = " + JSON.stringify(item))
        // let params = {
        //     previous : Identifier.MAIN, 
        //     parameters : { code: "HMP-Audiobook", title: "Koleksi Audio Book", origins: "CONTENT_SECTION" }, 
        //     content_code : item.content_code
        // }
        // navigateContentDetail(this.props.navigation, params); 
        this.props.navigation.navigate("DesktopDetailEdukasi", item);
    }

    fetchNews = async () => {
        const response = await executePost(widgetTutorialEdukasi);
        console.log("widgetTutorialEdukasi news = " + JSON.stringify(response))
        if (response.code == code_ok) {
            this.setState({
                showLoading: false,
                data: response.data
            })

        } else {
            if (response.code != code_auth_error) {
                this.setState({
                    showLoading: false,
                })
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, response.msg);
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : response.msg })
                // }
            } else {
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            }
        }

    }

    handleNewsClick = (item, index) => {
        item.identifier = Identifier.MAIN;
        this.props.navigation.navigate("DesktopNewsDetail", item);
    }
    render() {
        return (
            <View style={{ width: "100%", paddingLeft: 147, paddingRight: 147, marginBottom: 30 }}>
                {
                    this.state.showLoading &&
                    <View>
                        <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: "2rem", width: 150 }}
                            style={{ marginBottom: 8, marginLeft: 16 }}
                        />
                        <FlatList
                            keyExtractor={(i, x) => "news_skeleton_" + x}
                            data={this.state.dummy_data}
                            renderItem={this.renderSkeleton}
                            horizontal={true}
                        />
                    </View>
                }
                {
                    !this.state.showLoading &&
                    <View style={{ marginTop: 30 }}>
                        <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 20 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={28}
                                variant="h3"
                                style={{ flex: 1, marginLeft: 10 }}
                                color={color_text}
                                fontWeight={"600"}
                            >
                                {truncateText('Video Edukasi', 30)}
                            </Typography>
                            <Button
                                style={{ textTransform: "none", marginRight: 16 }}
                                onClick={() => {
                                    let params = { code: "HMP-Audiobook", title: "Koleksi Audio Book", origins: "CONTENT_SECTION" };
                                    // this.props.navigation.navigate("DesktopSectionDetail", params);
                                    window.location.href = "http://depok.kubuku.id/tutorial-edu/";
                                }}
                            >
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={16}
                                    fontWeight="500"
                                    color={primary}
                                >
                                    Lihat Semua
                                </Typography>
                            </Button>
                        </View>
                        <FlatList
                            keyExtractor={(item, index) => index}
                            data={this.state.data}
                            horizontal={true}
                            renderItem={this.renderItem}
                        />
                    </View>
                }

            </View>
        )
    }
}

export default MenuTutorialEdukasiDesktop;