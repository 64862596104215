import React, { Component } from 'react'; 
import { StyleSheet, View, Image } from 'react-native'; 
import { Typography, Stack, Card, CardActionArea, CardContent  } from '@mui/material';
import { NotificationIcon, NewsIcon, BookIcon, ProfileIcon, NavNewsIcon , NavProfileIcon , NavRackIcon } from '../assets/Icons';
import { primary } from '../constants/colors';
import { executeGet } from '../utilities/Utilities';
import { code_ok, code_empty, code_error, code_auth_error, notificationCheck } from '../constants/api';
import * as Identifier from '../constants/PageIdentifiers';


var _is_mounted = false;
class NavigationMenu extends Component { 
    constructor(props){
        super(props)
        this.state = {
            total_unread : 0, 
            total_read : 0
        }

        this.checkNotification = this.checkNotification.bind(this); 
    }

    componentDidMount = ()=>{
        _is_mounted = true;
        this.checkNotification();
        window.addEventListener("message", (event)=>{
            if(event.data.id == "check_notifications"){
                this.checkNotification(); 
            }
        });
    }

    componentWillUnmount = ()=>{
        _is_mounted = false;
        window.removeEventListener("message", null); 
    }

    checkNotification = async()=>{
        if(_is_mounted){
            const response = await executeGet(notificationCheck); 
            if(response.code == code_ok){
                this.setState({
                    total_unread : parseInt(response.data.unread), 
                    total_read : parseInt(response.data.read)
                })
            }
        }
    }


    render(){
        return(
           <View style={{ 
                marginLeft : 16, 
                marginRight : 16 , 
                marginTop : 16 ,
                flexDirection : "row"
            }}>
                
                <View 
                    style={{
                        flex : 0.33,
                    }}>
                    <CardActionArea
                        sx={{ borderRadius : 24, padding : 2 }}
                        onClick={()=>{ this.props.navigation.navigate("News", { identifier : Identifier.MAIN })}}
                    >
                        <View style={{ 
                            alignItems : "center", 
                            justifyContent : "center"
                        }}>
                        <NavNewsIcon />
                        <Typography
                            fontFamily="Open Sans"
                            fontWeight="500"
                            fontSize={ 14 }
                            style={{ marginTop : 4}}
                        >
                            Berita
                        </Typography>
                        </View>
                    </CardActionArea>
                </View>

                <View 
                    style={{
                        flex : 0.33,
                    }}>
                    <CardActionArea
                        sx={{ borderRadius : 24, padding : 2 }}
                        onClick={()=>{ this.props.navigation.navigate("MyBookRack", { identifier : Identifier.MAIN })}}
                    >
                        <View style={{ 
                            alignItems : "center", 
                            justifyContent : "center"
                        }}>
                        <NavRackIcon />
                        <Typography
                            fontFamily="Open Sans"
                            fontWeight="500"
                            fontSize={ 14 }
                            style={{ marginTop : 4}}
                        >
                            Rak Buku
                        </Typography>
                        </View>
                    </CardActionArea>
                </View>
                <View 
                    style={{
                        flex : 0.33,
                    }}>
                    <CardActionArea
                        sx={{ borderRadius : 24, padding : 2 }}
                        onClick={()=>{ this.props.navigation.navigate("Profile", { identifier : Identifier.MAIN })}}
                    >
                        <View style={{ 
                            alignItems : "center", 
                            justifyContent : "center"
                        }}>
                        <NavProfileIcon />
                        <Typography
                            fontFamily="Open Sans"
                            fontWeight="500"
                            fontSize={ 14 }
                            style={{ marginTop : 4}}
                        >
                            Profil
                        </Typography>
                        </View>
                    </CardActionArea>
                </View>

                

                { /*
                <View style={{flexDirection : "row", alignItems : "center"}}>
                    <Card style={{
                        flex : 0.5, 
                        marginRight : 4 , 
                        borderRadius : 8, 
                        marginTop : 4 , 
                        marginBottom : 4 ,
                        backgroundColor : "#ff7979"
                        }}>
                        <CardActionArea
                            onClick={()=>{ this.props.navigation.navigate("Notification", { identifier : Identifier.MAIN})}}
                        >
                            <CardContent>
                                <View style={{flexDirection : "row", alignItems : "center" }}>
                                    <NotificationIcon />
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={ 14 } 
                                        style={{paddingRight : 8, marginLeft : 8  }}
                                        fontWeight={"600"}
                                        color={"#ffffff"}
                                    >
                                        Notifikasi
                                    </Typography>
                                    {
                                        (this.state.total_unread > 0) && 
                                        <View
                                          style={{position : "absolute", top : 2 , right : 2 , backgroundColor : "red" , width : 8, height : 8 , borderRadius : 8 }}
                                        />
                                    }
                                    
                                </View>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    <Card style={{
                        flex : 0.5 , 
                        marginLeft : 4 , 
                        borderRadius : 8, 
                        marginTop : 4, 
                        marginBottom : 4,
                        backgroundColor : "#3498db"
                        }}>
                        <CardActionArea
                            onClick={()=>{ this.props.navigation.navigate("News", { identifier : Identifier.MAIN })}}
                        >
                            <CardContent>
                                <View style={{flexDirection : "row", alignItems : "center" }}>
                                    <NewsIcon />
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={ 14 } 
                                        style={{paddingRight : 8, marginLeft : 8  }}
                                        fontWeight={"600"}
                                        color={"#ffffff"}
                                    >
                                        Berita
                                    </Typography>
                                </View>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </View>       
                */}

                {/*
                <View style={{flexDirection : "row", alignItems : "center"}}>
                    <Card style={{
                        flex : 0.5, 
                        marginRight : 4 , 
                        borderRadius : 8, 
                        marginTop : 4 , 
                        marginBottom : 4 ,
                        backgroundColor : "#1289A7"
                        }}>
                        <CardActionArea
                            onClick={()=>{ this.props.navigation.navigate("MyBookRack", { identifier : Identifier.MAIN })}}
                        >
                            <CardContent>
                                <View style={{flexDirection : "row", alignItems : "center" }}>
                                    <BookIcon />
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={ 14 } 
                                        style={{paddingRight : 8, marginLeft : 8  }}
                                        fontWeight={"600"}
                                        color={"#ffffff"}
                                    >
                                        Rak Buku
                                    </Typography>
                                </View>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                    <Card style={{
                        flex : 0.5 , 
                        marginLeft : 4 , 
                        borderRadius : 8, 
                        marginTop : 4, 
                        marginBottom : 4,
                        backgroundColor : "#30336b"
                        }}>
                        <CardActionArea
                            onClick={()=>{ this.props.navigation.navigate("Profile", { identifier : Identifier.MAIN })}}
                        >
                            <CardContent>
                                <View style={{flexDirection : "row", alignItems : "center" }}>
                                    <ProfileIcon />
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={ 14 } 
                                        style={{paddingRight : 8, marginLeft : 8  }}
                                        fontWeight={"600"}
                                        color={"#ffffff"}
                                    >
                                        Profil
                                    </Typography>
                                </View>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </View>   
                */}     
           </View>
        )   
    }
}

export default NavigationMenu; 