import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native'; 
import { Typography , List, ListItemButton, ListItemIcon, ListItemText, Modal } from '@mui/material'; 
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess'; 
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

class IdentityDialog extends Component { 
    constructor(props){
        super(props)
    }

    render(){
        return(
            <Modal
                open={ this.props.showing }
                onClose={ this.props.onClose }
                
            >
            <View style={{ justifyContent : "center", alignItems : "center", height : "100%", width : "100%"}}>
                <View style={ styles.container}>
                    <Typography
                        variant="h3" 
                        fontFamily="Open Sans" 
                        fontSize={ 18 }
                        fontWeight={"600"}
                        style={{ margin : 16 }}
                    >
                        Pilih Jenis Identitas
                    </Typography>
                    <List>
                        {
                            this.props.data.map((item, index)=>(
                                <ListItemButton key={ index } onClick={()=>{ this.props.onItemClick(item)}}>
                                    <ListItemIcon>
                                        <ChevronRightIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={ item.name }/>
                                </ListItemButton>
                            ))
                        }
                    </List>
                    

                </View>
            </View>

            </Modal>
        )
    }
}

const styles = StyleSheet.create({
    container : {
        backgroundColor : "white",
        borderRadius : 20 , 
        margin : 16 
    }
})

export default IdentityDialog; 