import React, { Component } from 'react'; 
import { View, StyleSheet , Image, FlatList, Dimensions, ScrollView, TextInput  } from 'react-native'; 
import { Alert, AlertTitle, Typography, Skeleton, Card, CardActionArea , CircularProgress,
     IconButton, Chip, Button } from '@mui/material'; 
import LoadingPage from '../components/LoadingPage'; 
import AlertDialog from '../components/AlertDialog'; 
import * as Identifier from '../constants/PageIdentifiers';
import * as Command from '../constants/Commands'; 
import { code_ok, code_empty, code_error, code_auth_error, sectionContent, 
    sectionContentRecommended, readContent } from '../constants/api';
import { executeGet, executePost , isEven, truncateText, navigateContentDetail } from '../utilities/Utilities'; 
import  EmptySearchPage  from '../components/EmptySearchPage';
import { color_text, sub_text, primary } from '../constants/colors';
import SortIcon from '@mui/icons-material/Sort';
import SortDialog from '../components/SortDialog';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { NavSearchIcon } from '../assets/NavIcons';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LoadingDialog from '../components/LoadingDialog';

var _is_mounted = false; 
var window_width;
var book_width;
var book_height;


class SearchKalimat extends Component {
    constructor(props){
        super(props)
        this.state = {
            showLoading : true, 
            moreLoading : false, 
            loadingMessage : "", 
            showAlert : false,
            alertTitle : "Perhatian", 
            alertMessage : "",
            showLoadingDialog : false, 
            loadingDialogMessage : "", 
            code : this.props.route.params.code,
            title : this.props.route.params.title,
            data : [], 
            dummy_data : [
                {i : 0}, { i : 1 }, {i : 2 }, { i : 3 }, {i : 0}
            ],
            page : 1, 
            total : 0, 
            show : 0, 
            showSortDialog : true,
            sort_type : -1,
            sort_menu : [
                {id : "001", type: 1, title : "Judul A-Z"},{id : "002", type : 2, title : "Judul Z-A"}
                
            ],
            search_types : [{ id : 1, alias : "umum", title : "Umum"}, {id : 2, alias: "kalimat", title : "Kalimat"}],
            searchType : "umum", 
            data_sub_kategori :[] ,
            selected_sub_kategori : "",  
            selected_kode_sub_kategori : "",
            keyword : "",
            searchMode : false,
            searchFocus : false
        }
        this.goBack = this.goBack.bind(this); 
        this.renderSkeleton = this.renderSkeleton.bind(this);
        this.renderItem = this.renderItem.bind(this); 
        this.fetchContent = this.fetchContent.bind(this); 
        this.onImageError = this.onImageError.bind(this); 
        this.fetchMoreData = this.fetchMoreData.bind(this); 
        this.handleSortClick = this.handleSortClick.bind(this); 
        this.handleSortMenuClick = this.handleSortMenuClick.bind(this); 
        this.renderSortMenu = this.renderSortMenu.bind(this);
        this.contentDetail = this.contentDetail.bind(this);
        
        this.onSearchInputFocus = this.onSearchInputFocus.bind(this);
        this.onSearchInputBlur = this.onSearchInputBlur.bind(this);
        this.handleSearchPress = this.handleSearchPress.bind(this); 
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this); 

        this.directRead = this.directRead.bind(this);
        
    }

    componentDidMount = ()=>{
        _is_mounted = true; 
        this.setState({
            code : this.props.route.params.code,
            title : this.props.route.params.title,
            selected_kode_sub_kategori : this.props.route.params.selected_kode_sub_kategori,
            keyword : this.props.route.params.keyword
        },()=>{
            this.fetchContent();
        })
    }

    componentWillUnmount = ()=>{
        _is_mounted = false;
        window.addEventListener("message", (event)=>{
            if(event.data.id == "go_back"){
                this.goBack(); 
            }
        })
    }
    goBack = ()=>{
        this.props.navigation.goBack(); 
    }
    onImageError = (item, index)=>{
        if(_is_mounted){
            let data = [...this.state.data]; 
            let obj = { ...data[index]}; 
            obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
            data[index] = obj; 
            this.setState({
                data : data
            })
        }
    }
    handleSortMenuClick = ()=>{
        if(_is_mounted){
            if(this.state.showSortDialog){
                this.setState({
                    showSortDialog : false
                })
            } else {
                this.setState({
                    showSortDialog : true
                })
            }
        }
        
    }
    contentDetail = (item,index)=>{
        let params = {
            content_code : item.kode_konten
        }
        navigateContentDetail(this.props.navigation, params); 
    }
    fetchMoreData = ()=>{
        if(_is_mounted && !this.state.showLoading && !this.state.moreLoading){
            if(this.state.show < this.state.total ) {
                let page = parseInt(this.state.page) + 1; 
                this.setState({
                    page : page
                }, ()=>{
                    this.fetchContent(); 
                })

            }
        }
    }
    onSearchInputFocus = (e)=>{
        this.setState({
            searchFocus : true
        })
    }
    onSearchInputBlur = ()=>{
        this.setState({
            searchFocus : false
        })
    }
    //ANCHOR - Handle Search Text Changed
    handleSearchTextChange = (text)=>{
        if(_is_mounted){
            this.setState({
                keyword : text
            })
        }
    }
    handleSearchPress = (e)=>{
        let key = e.nativeEvent.key; 
        if(key == "Enter"){
            if(this.state.keyword.length > 5 ) {
                let params = {
                    code : this.props.route.params.code,
                    title : this.props.route.params.title,
                    selected_kode_sub_kategori : this.state.selected_kode_sub_kategori,
                    keyword : this.state.keyword,
                    origins : "CONTENT_SECTION"
                }
                this.props.navigation.navigate("SearchKalimat", params); 
            } else {
                this.setState({
                    showWarning : true, 
                    warningMessage : "Kalimat yang dicari minimal lebih dari 5 huruf"
                })
            }
        }
    }
    //ANCHOR - Fetch Content
    fetchContent = async()=>{
        if(_is_mounted){
            if(this.state.page == 1) {
                this.setState({
                    showLoading : true 
                })
            } else {
                this.setState({
                    moreLoading : true 
                })
            }

            let formData = new FormData(); 
            formData.append("page", this.state.page);
            formData.append("limit", 48);
            formData.append("code", this.state.code);
            if(this.state.sort_type != -1) {
                formData.append("sort_by", this.state.sort_type); 
            }   
            let url;
            if(this.props.route.params.origins == "CONTENT_SECTION") {
                url = sectionContent; 
                if(this.state.selected_kode_sub_kategori != "") {
                    formData.append("code_sub_category", this.state.selected_kode_sub_kategori); 
                }
                formData.append("keyword", this.state.keyword);    
            } else {
                url = sectionContentRecommended; 
            }
            const response = await executePost(url, formData); 
            
            if(response.data_searchingKalimat.code == code_ok){
                //console.log(JSON.stringify(response));
                if(parseInt(response.data_searchingKalimat.total_show) > 0 ) {
                    let show = parseInt(response.data_searchingKalimat.total_show ) + parseInt(this.state.show); 
                    let total = parseInt(response.data_searchingKalimat.total_rows); 
                    //let mod = response.data_searchingKalimat.total_show % 2; 
                    let data = [...this.state.data, ...response.data_searchingKalimat.data]; 
                    
                    

                    this.setState({
                        total : total, 
                        show : show , 
                        data : data ,
                        moreLoading : false, 
                        showLoading : false,
                        //data_sub_kategori : data_sub_kategori

                    })
                } 
                
                if(parseInt(response.data_searchingKalimat.total_rows) == 0) {
                    this.setState({
                        showLoading : false, 
                        moreLoading : false, 
                        alertTitle : "Tidak ditemukan", 
                        alertMessage : "Mohon maaf, data dihalaman ini tidak ditemukan"
                    })
                }
            } else {
                if(response.data_searchingKalimat.code == code_auth_error){
                    // if(global.os == "ANDROID"){
                    //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                    // } else {
                    //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                    // }
                    window.postMessage({ id: "sign_out"});
                } else {
                    this.setState({
                        showLoading : false, 
                        showAlert : true, 
                        alertMessage : response.data_searchingKalimat.msg
                    })
                }
            }

        }
    }

    renderSkeleton = ({item, index, separators})=>{
        
        return(
            <View
                style={{ 
                    flex : 1,
                    borderRadius : 8, 
                    flexDirection : "row", 
                    alignItems : "center",
                    marginLeft : 16, 
                    marginRight : 16, 
                    marginBottom : 16
                }}
            >
                <Skeleton 
                    style={{
                        borderRadius : 8 
                    }}
                    width={ book_width }
                    height={ book_height }
                    variant="rectangular"
                    animation="wave"
                />
                <View style={{ flex : 1, height : "100%"}}>
                    <Skeleton 
                        variant="text"
                        animation="wave"
                        sx={{ fontSize : "1.5rem"}}
                        style={{ 
                            marginLeft : 12, marginTop : 8, marginRight : 12 
                        }}
                    />
                    <Skeleton 
                        variant="text"
                        animation="wave"
                        sx={{ fontSize : "1.5rem"}}
                        style={{
                            marginLeft : 12 ,
                            marginBottom : 12, 
                            marginRight : 12
                        }}
                    />
                    <Skeleton 
                        variant="text"
                        animation="wave"
                        sx={{ fontSize : "1.5rem"}}
                        style={{
                            marginLeft : 12 ,
                            marginBottom : 12, 
                            marginRight : 12
                        }}
                    />
                </View>
            </View>
        )
    }
    renderItem = ({item, index, separators})=>{
        return(
            <Card
                style={{ 
                    borderRadius : 8, 
                    marginLeft : 16, 
                    marginRight : 16,
                    marginBottom : 16,
                    
                }}
            >
                <CardActionArea
                    
                >
                    <View style={{flexDirection : "row", alignItems : "center"}}>
                        <Image
                            style={{
                                width : book_width,
                                height : book_height,
                                marginLeft : 8 ,
                                borderRadius : 8
                            }}
                            resizeMode={"cover"}
                            source={{ uri : item.cover }}
                            onError={(error)=>{ this.onImageError(item, index)}}
                        />

                        <View style={{flex : 1 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 14 }
                                color={ color_text } 
                                fontWeight={"600"}
                                style={{ 
                                    marginLeft : 12, marginTop : 12, marginBottom : 4, marginRight : 12 
                                }}
                            >
                                { truncateText(item.judul, 32)  }
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                color={ primary }
                                fontSize={ 14 }
                                style={{
                                    marginLeft : 12 ,
                                    marginBottom : 12, 
                                    marginRight : 12
                                }}
                            >
                                Halaman : { item.hal_idx }
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                color={ sub_text }
                                variant="body2"
                                fontSize={ 14 }
                                style={{
                                    marginLeft : 12 ,
                                    marginBottom : 12, 
                                    marginRight : 12
                                }}
                            >
                                { item.hal_txt}
                            </Typography>
                            <View style={{ flex : 1, flexDirection : "column-reverse" , marginBottom : 12}}>
                                <View style={{flexDirection : "row-reverse", alignItems : "center", marginRight : 16}}>
                                    <Button
                                        variant="contained"
                                        style={{ textTransform : "none", borderRadius : 25, marginRight : 0, 
                                        padding : 8, minWidth: 100  }}
                                        onClick={()=>{ this.directRead(item)}}
                                    >
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={ 14 }
                                            fontWeight="500"
                                        >
                                        Baca
                                        </Typography>
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        style={{ textTransform : "none", borderRadius : 25, marginRight : 16, 
                                        padding : 8, minWidth: 100  }}
                                        onClick={()=>{ this.contentDetail(item, index)}}
                                    >
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={ 14 }
                                            fontWeight="500"
                                        >
                                        Detail
                                        </Typography>
                                    </Button>
                                </View>
                            </View>
                        </View>

                    </View>
                </CardActionArea>
            </Card>
        )
    }

    //ANCHOR - Render Sort Menu
    renderSortMenu = ({ item, index, separators})=>{
        return(
            <Chip 
                style={{ 
                    marginRight : (index == 3 ? 16 : 8) ,
                    marginLeft : (index == 0 ? 16 : 0)
                }}
                variant={ this.state.sort_type == item.type ? "contained" : "outlined"} 
                label={ item.title } 
                onClick={()=>{ this.handleSortClick(item.type )}}
            />
        )
    }
    handleSortClick = (variant)=>{
        if(_is_mounted){
            this.setState({
                sort_type : variant,
                page : 1, 
                total : 0, 
                show : 0,
                data : [],
            },()=>{
                this.fetchContent();
            }); 
        }
    }

    directRead = async(item)=>{
        let code = item.kode_konten; 
        let formData = new FormData(); 
        formData.append("code", code); 
        this.setState({
            showLoadingDialog : true, 
            loadingDialogMessage : "Harap Tunggu"
        })


        let response = await executePost(readContent, formData);
        if(response.code == code_ok){
            this.setState({
                showLoadingDialog : false, 
            },()=>{
                let data = response.data; 
                data.mode = 'read';
                data.keyword = this.state.keyword;
                data.directIndex = item.hal_idx;
                this.props.navigation.navigate("ReaderMobile", data); 
            })
        } else {
            if(response.code != code_auth_error){
                this.setState({
                    showLoadingDialog : false,
                    showAlert : true, 
                    alertMessage : response.msg
                })
            } else {
                window.postMessage({ id : "sign_out"})
            }
        }


    }

    render(){
        window_width = Dimensions.get("window").width; 
        book_width = (window_width / 4) - 16 ; 
        book_height = book_width + (book_width * 0.3 );
        
        return(
            <View style={{ flex : 1 }}>
                <View style={{
                    marginLeft : 0 , 
                    //marginTop : (global.os == "ANDROID" ? 16 : 48 ) ,  
                    marginTop : 0 ,  
                    marginBottom : 16 ,
                    flex : 0.07,
                    backgroundColor : primary,
                    flexDirection : "row", 
                    alignItems : "center"
            
                }}>

                    <IconButton
                        style={{ marginRight : 16, marginLeft : 8 }}
                        onClick={()=>{ this.goBack() }}
                    >
                        <ChevronLeftIcon 
                            sx={{color : "white" }}
                        />
                    </IconButton>
                    <View style={ [styles.searchBar,{
                        borderColor : (this.state.searchFocus ? primary : "#F9FAFC")
                    }]}>
                        <View style={{ flex : 0.05}}>
                            <NavSearchIcon
                                width={ 18 }
                                height={ 18 } 
                                fillLogo={ (this.state.searchFocus ? primary : "#999999") }
                            />
                        </View>
                        <TextInput 
                            style={styles.searchInput }
                            onFocus={this.onSearchInputFocus }
                            onBlur={ this.onSearchInputBlur }
                            defaultValue={ this.props.route.params.keyword }
                            placeholder={"Pencarian"}
                            onKeyPress={ this.handleSearchPress }
                            onChangeText={ this.handleSearchTextChange }
                        />
                    </View>
                    <IconButton
                        style={{
                            marginRight : 16 ,
                        }}
                        onClick={ this.handleSortMenuClick }
                    >
                        {
                            this.state.showSortDialog &&
                            <ExpandLessIcon sx={{ color : "white"}} />
                        }
                        {
                            !this.state.showSortDialog && 
                            <ExpandMoreIcon sx={{ color : "white"}} />
                        }
                    </IconButton>
                </View>
                <View style={{ flex : 0.95}}>
                {
                    (!this.state.showLoading && this.state.showSortDialog) && 
                    <View style={{marginBottom : 16 }}>
                        <FlatList 
                            horizontal={ true }
                            keyExtractor={(k, l)=>"index_"+k.id}
                            data={ this.state.sort_menu }
                            renderItem={ this.renderSortMenu }
                            showsHorizontalScrollIndicator={false}
                            style={{marginBottom : 16 }}
                        />
                    </View>
                }
                {
                    (this.state.data.length == 0 && !this.state.showLoading ) && 
                    <EmptySearchPage 
                        title={ this.state.alertTitle }
                        message={ this.state.alertMessage }
                    />
                    
                }
                {
                    this.state.showLoading && 
                    <FlatList 
                        keyExtractor={(i, x)=>"index_"+x}
                        data={ this.state.dummy_data } 
                        renderItem={ this.renderSkeleton }
                        showsVerticalScrollIndicator={false}
                    />
                }
                {
                    (!this.state.showLoading) && 
                    <FlatList 
                        keyExtractor={(item , index)=>"item_index_"+index}
                        data={ this.state.data }
                        renderItem={ this.renderItem }
                        showsVerticalScrollIndicator={false}
                        onEndReachedThreshold={0.5}
                        onEndReached={this.fetchMoreData }
                        ListFooterComponent={
                            this.state.moreLoading && 
                            <View style={{alignItems : "center", padding : 8 }}>
                                <CircularProgress 
                                    size={ 32 }
                                />
                            </View>
                        }
                    />
                }
                <LoadingDialog
                    open={ this.state.showLoadingDialog }
                    message={ this.state.loadingDialogMessage}
                />


                </View>
            </View>
        )

    }


}

//ANCHOR -   Styles
const styles = StyleSheet.create({
    searchBar : {
        backgroundColor : "#F9FAFC", 
        borderRadius : 8, 
        borderWidth : 0.2,
        padding : 8, 
        flexDirection : "row",
        flex : 1
    },
    searchInput : {
        fontFamily : "Open Sans", 
        fontWeight : "400",
        color : color_text,
        fontSize : 14,
        flex : 0.95, 
        backgroundColor : "#F9FAFC",
        outlineColor : "#F9FAFC",
        marginLeft : 18
    },
})


export default SearchKalimat;