import React, { Component } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { Typography, Stack, Card, CardActionArea, CardContent } from '@mui/material';
import {
    IconKoleksiSD, IconKoleksiAudioBook, IconKoleksiVideobook, IconKoleksiLokal,
    NotificationIcon, NewsIcon, BookIcon, ProfileIcon, NavNewsIcon, NavProfileIcon, NavRackIcon, IconKoleksiSMP, IconKoleksiPerguruanTinggi, IconKoleksiSMA, IconKoleksiJurnal
} from '../../assets/Icons';
import { primary, color_text } from '../../constants/colors';
import { executeGet, truncateText } from '../../utilities/Utilities';
import { code_ok, code_empty, code_error, code_auth_error, notificationCheck } from '../../constants/api';
import * as Identifier from '../../constants/PageIdentifiers';


var _is_mounted = false;
class MenuKoleksiDigital extends Component {
    constructor(props) {
        super(props)
        this.state = {
            total_unread: 0,
            total_read: 0
        }

        this.checkNotification = this.checkNotification.bind(this);
    }

    componentDidMount = () => {
        _is_mounted = true;
        this.checkNotification();
        window.addEventListener("message", (event) => {
            if (event.data.id == "check_notifications") {
                this.checkNotification();
            }
        });
    }

    componentWillUnmount = () => {
        _is_mounted = false;
        window.removeEventListener("message", null);
    }

    checkNotification = async () => {
        if (_is_mounted) {
            const response = await executeGet(notificationCheck);
            if (response.code == code_ok) {
                this.setState({
                    total_unread: parseInt(response.data.unread),
                    total_read: parseInt(response.data.read)
                })
            }
        }
    }


    render() {
        return (
            <View>
                <Typography
                    fontFamily="Open Sans"
                    fontSize={16}
                    variant="h3"
                    color={color_text}
                    fontWeight={"600"}
                    style={{ flex: 1, marginLeft: 16, marginTop: 16, marginBottom: 8 }}
                >
                    {this.props.title}
                </Typography>

                <View style={{
                    marginLeft: 16,
                    marginRight: 16,
                    marginTop: 10,
                    flexDirection: "row"
                }}>

                    <View
                        style={{
                            flex: 0.25,
                        }}>
                        <CardActionArea
                            sx={{ borderRadius: 20, padding: 1 }}
                            // onClick={() => { this.props.navigation.navigate("News", { identifier: Identifier.MAIN }) }}
                            onClick={()=>{
                                let params = { code : "HMP-Audiobook", title : "Koleksi Audiobook", origins : "CONTENT_SECTION"}; 
                                this.props.navigation.navigate("SectionDetailAudiobook", params);
                            }}
                        >
                            <View style={{
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <IconKoleksiAudioBook />
                                <Typography
                                    fontFamily="Open Sans"
                                    fontWeight="500"
                                    fontSize={12}
                                    style={{ marginTop: 4, textAlign: 'center' }}
                                >
                                    Koleksi Audiobook
                        </Typography>
                            </View>
                        </CardActionArea>
                    </View>

                    <View
                        style={{
                            flex: 0.25,
                        }}>
                        <CardActionArea
                            sx={{ borderRadius: 20, padding: 1 }}
                            onClick={()=>{
                                let params = { code : "HMP-Videobook", title : "Koleksi Videobook", origins : "CONTENT_SECTION"}; 
                                this.props.navigation.navigate("SectionDetailAudiobook", params);
                            }}
                        >
                            <View style={{
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <IconKoleksiVideobook />
                                <Typography
                                    fontFamily="Open Sans"
                                    fontWeight="500"
                                    fontSize={12}
                                    style={{ marginTop: 4, textAlign: 'center' }}
                                >
                                    Koleksi Videobook
                        </Typography>
                            </View>
                        </CardActionArea>
                    </View>
                    <View
                        style={{
                            flex: 0.25,
                        }}>
                        <CardActionArea
                            sx={{ borderRadius: 20, padding: 1 }}
                            onClick={()=>{
                                let params = { code : "HMP-Lokal", title : "Koleksi Lokal", origins : "CONTENT_SECTION"}; 
                                this.props.navigation.navigate("SectionKementrian", params);
                            }}
                        >
                            <View style={{
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <IconKoleksiLokal />
                                <Typography
                                    fontFamily="Open Sans"
                                    fontWeight="500"
                                    fontSize={12}
                                    style={{ marginTop: 4, textAlign: 'center' }}
                                >
                                    Kementerian /Lembaga
                        </Typography>
                            </View>
                        </CardActionArea>
                    </View>
                    <View
                        style={{
                            flex: 0.25,
                        }}>
                        <CardActionArea
                            sx={{ borderRadius: 20, padding: 1 }}
                            onClick={()=>{
                                let params = { code : "HMP-Jurnal", title : "Koleksi Jurnal", origins : "CONTENT_SECTION"}; 
                                this.props.navigation.navigate("SectionDetailAudiobook", params);
                            }}
                        >
                            <View style={{
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <IconKoleksiJurnal width={60} height={60}/>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontWeight="500"
                                    fontSize={12}
                                    style={{ marginTop: 4, textAlign: 'center' }}
                                >
                                    Koleksi   Jurnal
                        </Typography>
                            </View>
                        </CardActionArea>
                    </View>
                </View>

            </View>

        )
    }
}

export default MenuKoleksiDigital;