import React, { Component } from 'react';
import { StyleSheet, View, FlatList, Image, Dimensions } from 'react-native';
import { code_ok, code_error, code_auth_error, code_empty, homepageRecommended, checkLembaga, refreshToken } from '../../constants/api';
import { Skeleton, Typography, Card, CardActionArea, CardMedia, CardContent, Button } from '@mui/material';
import { executeGet, executePost, truncateText, navigateContentDetail, updateAuthHeader } from '../../utilities/Utilities';
import AlertDialog from '../../components/AlertDialog';
import { color_text, sub_text, primary } from '../../constants/colors';
import * as Identifier from '../../constants/PageIdentifiers';
import { IconKoleksiLokal, IconInfoLiterasi } from '../../assets/Icons';


class MenuInfoLiterasi extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            showAlert: false,
            alertMessage: "",
            loadingMessage: "",
            code: this.props.code,
            data: [
                { "homepage_code": "HMP-0007", "content_code": "BK30118", "cover": "https://bintangpusnas.perpusnas.go.id/api/konten_pnri/cover/978-602-478-207-8.jpg", "title": "Peluncuran Buku Perdebatan Pasal 33 Dalam Amandemen UUD 1945", "synopsis": null, "year": "2018", "isbn": "978-602-478-207-8", "eisbn": null, "type_code": "TYP-0001", "sub_type_code": "STYP-0001", "category_code": "14", "sub_category_code": "91", "author_code": "22304", "publisher_code": "414", "author": "Kamis, 18/8/2022 21:34 WIB", "publisher": "CV. Cipta Media Edukasi", "type": "Books", "sub_type": "Buku", "category": "Pendidikan", "sub_category": "Pendidikan" },
                { "homepage_code": "HMP-0007", "content_code": "BK30104", "cover": "https://bintangpusnas.perpusnas.go.id/api/konten_pnri/cover/978-602-478-183-5.jpg", "title": "Humas Pemerintah Perlu Memahami Perannya", "synopsis": null, "year": "2018", "isbn": "978-602-478-183-5", "eisbn": "-", "type_code": "TYP-0001", "sub_type_code": "STYP-0001", "category_code": "14", "sub_category_code": "91", "author_code": "22292", "publisher_code": "414", "author": "Selasa, 18/8/2022 21:34 WIB", "publisher": "CV. Cipta Media Edukasi", "type": "Books", "sub_type": "Buku", "category": "Pendidikan", "sub_category": "Pendidikan" },
                { "homepage_code": "HMP-0007", "content_code": "BK30268", "cover": "https://bintangpusnas.perpusnas.go.id/api/konten_pnri/cover/978-602-5838-65-1.jpg", "title": "Membangun Kualitas SDM Melalui Perpustakaan", "synopsis": null, "year": "2018", "isbn": "978-602-5838-65-1", "eisbn": null, "type_code": "TYP-0001", "sub_type_code": "STYP-0001", "category_code": "14", "sub_category_code": "91", "author_code": "22448", "publisher_code": "414", "author": "Jumat, 18/8/2022 21:34 WIB", "publisher": "CV. Cipta Media Edukasi", "type": "Books", "sub_type": "Buku", "category": "Pendidikan", "sub_category": "Pendidikan" }],
            dummy_data: [
                { i: 0 }, { i: 1 }, { i: 2 }
            ]

        }

        this.handleAlertClose = this.handleAlertClose.bind(this);
        this.handleMoreClick = this.handleMoreClick.bind(this);
        this.fetchContents = this.fetchContents.bind(this);
        // this.renderItem = this.renderItem.bind(this);
        this.handleImageError = this.handleImageError.bind(this);
        this.contentDetail = this.contentDetail.bind(this);
    }

    componentDidMount = () => {
        this.fetchContents();
    }

    handleAlertClose = () => {

    }
    handleMoreClick = (code, title) => {
        this.props.navigation.navigate("SectionDetail", { identifier: Identifier.SECTION_DETAIL, code: code, title: title, origins: "CONTENT_RECOMMENDED" });
    }

    fetchContents = async () => {

        let formData = new FormData();
        formData.append("page", 1);
        formData.append("homepage_code", this.props.code)
        formData.append("limit", 3);

        const response = await executePost(homepageRecommended, formData);
        if (response.code == code_ok) {
            console.log(JSON.stringify(response));
            console.log(JSON.stringify(this.state.data));
            this.setState({
                showLoading: false,
                // data : response.data
            })
        } else {
            if (response.code != code_auth_error) {
                this.setState({
                    showLoading: false,
                    alertMessage: response.msg,
                    showAlert: true
                })
            } else {
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            }
        }
    }

    contentDetail = (item, index) => {
        let params = {
            previous: Identifier.MAIN,
            parameters: {},
            content_code: item.content_code
        }
        navigateContentDetail(this.props.navigation, params);
    }
    handleImageError = (item, index) => {
        let data = [...this.state.data];
        let obj = { ...data[index] };
        obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
        data[index] = obj;
        this.setState({
            data: data
        })
    }

    refreshToken = async () => {
        // refreshToken
        let response = await executeGet(refreshToken);
        console.log('refreshToken = ' + JSON.stringify(response))
        if (response.code == code_ok) {
            updateAuthHeader(response)
            this.checkBeforeEdukasi(response.client_token);
        }
    }

    //ANCHOR - Check to edukasi
    checkBeforeEdukasi = async () => {
        let url = checkLembaga;
        let response = await executeGet(url);
        console.log("checkLembaga = " + JSON.stringify(response))
        // code == 500 -> page verifikasi
        if (response.code == code_ok) {
            const domainName =  window.location.hostname;
            let adom = domainName.split(".");
            // window.location.href = "https://edukasi.bintangpusnas.com/login?token=" + global.token + "&platform=mobile&dev=" + adom[0];
            window.location.href = "https://interoperabilitas.perpusnas.go.id/archive/detail/9746/perpustakaan-nasional-ri"
        } else {
            // this.setState({
            //     showLoadingDialog : false,
            //     showAlert : true, 
            //     alertMessage : response.msg
            // })
            this.setState({
                showLoadingDialog: false,
            }, () => {
                let data = {
                    action: 'edukasi'
                };
                this.props.navigation.navigate("VerificationUser", data);
            });
        }
    }

    render() {
        return (
            <View style={{ width: "100%" }}>
                {
                    this.state.showLoading &&
                    <View>
                        <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: '2rem', maxWidth: 120 }}
                            style={{ marginLeft: 16, marginBottom: 8, marginRight: 16, marginTop: 16 }}
                        />
                        <Skeleton
                            variant="rectangular"
                            style={{ height: 90, marginTop: 8, borderRadius: 8, marginLeft: 16, marginRight: 16 }}
                            animation="wave"
                        />
                        <Skeleton
                            variant="rectangular"
                            style={{ height: 90, marginTop: 8, borderRadius: 8, marginLeft: 16, marginRight: 16 }}
                            animation="wave"
                        />
                        <Skeleton
                            variant="rectangular"
                            style={{ height: 90, marginTop: 8, borderRadius: 8, marginLeft: 16, marginRight: 16 }}
                            animation="wave"
                        />
                    </View>
                }
                {
                    !this.state.showLoading &&
                    <View style={{ marginTop: 16 }}>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={16}
                                variant="h3"
                                style={{ flex: 1, marginLeft: 16 }}
                                color={color_text}
                                fontWeight={"600"}
                            >
                                {truncateText(this.props.title, 70)}
                            </Typography>
                        </View>
                        
                        <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}
                            // onClick={() => { window.location.href = "https://interoperabilitas.perpusnas.go.id/archive/detail/9746/perpustakaan-nasional-ri"}}
                            onClick={() => { 
                                // window.location = "https://dev.bintangpusnas.com/?os=android&platform=mobile"
                                
                                // location.replace("https://bintangpusnas.perpusnas.go.id/publikasi/");
                                this.props.navigation.navigate("WebViewer", "https://bintangpusnas.perpusnas.go.id/publikasi/");
                            }}
                            // onClick={() => this.refreshToken()}
                        >
                            <View
                                style={{
                                    borderRadius: 8,
                                    marginLeft: 16,
                                    marginRight: 16
                                }}
                            >
                                {/*
                                <IconKoleksiLokal />
                            */}
                            <IconInfoLiterasi/>
                            </View>
                            <View style={{ flex: 1 }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    color={color_text}
                                    fontWeight={"600"}
                                >
                                    Ragam Perpustakaan
                                </Typography>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={12}
                                    color={sub_text}
                                    style={{ marginTop: '4px', marginRight: 30 }}
                                >
                                    Video Pendek Perpustakaan di Indonesia
                                </Typography>
                                <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px' }} />


                            </View>
                        </View>

                    </View>

                }


            </View>
        )
    }

}

const styles = {
    media: {
        height: 0,
        paddingTop: '56.25%' // 16:9
    },
    card: {
        position: 'relative',
        margin: '16px',
        borderRadius: '15px'
    },
    overlay: {
        position: 'absolute',
        bottom: '39px',
        left: '20px',
        color: 'white',
        fontSize: '18px'
    },
    overlaySub: {
        position: 'absolute',
        bottom: '12px',
        left: '20px',
        color: 'white',
        fontSize: '12px'
    }
}

export default MenuInfoLiterasi;