import React, { Component } from 'react'; 
import { View , StyleSheet, TextInput } from 'react-native'; 
import { primary , color_text, textbox, danger } from '../constants/colors';
import IconButton from '@mui/material/IconButton';


import { Typography } from '@mui/material'; 
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/700.css';

class OptionBox extends Component {
    constructor(props){
        super(props)
    }
    render(){
        return(
            <View style={ this.props.containerStyle }>
                <Typography
                    variant="h3"
                    fontWeight="400"
                    fontSize={ 14 } 
                    fontFamily="Open Sans"
                    marginLeft={0.5}
                >
                    { this.props.caption }
                </Typography>
                <View style={ styles.input_container}>
                    <TextInput
                        ref={ this.props.reference }
                        placeholder={ this.props.placeholder } 
                        style={ styles.input }
                        outlineColor={ primary }
                        value={ this.props.value }
                        editable={ this.props.editable }
                        onChangeText={ this.props.onChangeText }
                        onSubmitEditing={ this.props.onSubmitEditing }
                    />
                    {
                        this.props.usingIcon && 
                        <IconButton
                            style={{marginRight : 8, position : "absolute", right : 0 }}
                            onClick={ this.props.onIconClick }
                            size="small"
                        >   
                            { this.props.icon }
                        </IconButton>

                    }
                    
                </View>
                {
                    this.props.error && 
                    <View style={{flexDirection : "row-reverse"}}>
                        <Typography
                            variant="body1"
                            fontFamily="Open Sans"
                            fontSize={ 14 }
                            color={ danger }  
                        >
                            { this.props.errorMessage }
                        </Typography>
                    </View>

                }
            </View>

        )
    }
}

const styles = StyleSheet.create({
    input_container : {
        backgroundColor : textbox, 
        borderRadius : 8, 
        width : "100%",
        justifyContent : "center",
        marginTop : 8 
    },
    input : {
        fontFamily : "Open Sans", 
        fontSize : 14, 
        padding : 12, 
        flex : 1,
        borderRadius : 8, 
        outlineColor : primary
    }
})


export default OptionBox; 