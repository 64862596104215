import React, { Component } from 'react'; 
import { StyleSheet, View, ScrollView, Dimensions, Image, Alert, Platform } from 'react-native';
import { primary, color_text } from '../constants/colors';
import { Typography, Button } from '@mui/material';
import LogoLogin from '../assets/images/LogoLogin';
import * as Identifier from "../constants/PageIdentifiers";
import * as Command from '../constants/Commands';
import TextBox from '../components/TextBox';
import PasswordBox from '../components/PasswordBox';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/700.css';
import { code_ok, code_empty, code_error, code_auth_error , userSignIn } from '../constants/api';
import { executeGet, executePost, createAuthHeader } from '../utilities/Utilities';
import LoadingPage from '../components/LoadingPage';
import AlertDialog from '../components/AlertDialog';

const width = Dimensions.get("window").width;
var _is_mounted = false;
class Login extends Component {
    constructor(props){
        super(props); 
        this.state = {
            email : "", 
            password : "", 
            errorEmail : false, 
            errorEmailMessage : "", 
            errorPassword : false, 
            errorPasswordMessage : "",
            isSubmitting : false, 
            progressText : "",
            showAlert : false, 
            alertMessage : ""
            
        }
        
        this.handleForgotPasswordClick = this.handleForgotPasswordClick.bind(this); 
        this.handleSignUpClick = this.handleSignUpClick.bind(this); 
        // this.handleLoginClick = this.handleLoginClick.bind(this); 

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this); 

        this.executeLogin = this.executeLogin.bind(this); 
        this.handleAlertClose = this.handleAlertClose.bind(this); 
    }

    componentDidMount = ()=>{
        _is_mounted = true; 
        localStorage.clear();
        document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        if (global.os == "ANDROID") {
            JSBridge.launchFunction(Command.SET_PAGE, Identifier.LOGIN);
        }
        window.addEventListener("message", (event) => {
            if(event.data.id == "go_back") {
                try {
                    if(global.os == "ANDROID") {
                        JSBridge.launchFunction("CLOSE_APP", "-")
                    }
                } catch(error) {
                    console.log("error")
                }
            }

        })
        console.log("LOGIN PAGE MOUNTED");
        //JSBridge.launchFunction(Command.SET_PAGE, Identifier.LOGIN);
    }
    componentWillUnmount = ()=>{
        _is_mounted = false;
        window.removeEventListener("message", null);
        //console.log("LOGIN PAGE UNMOUNTED");
    }

    handleForgotPasswordClick = ()=>{
        this.props.navigation.navigate("ForgotPassword"); 
    }

    handleSignUpClick = ()=>{
        this.props.navigation.navigate("SignUp", this.props.route.params );
    }

    handleLoginClick = (e)=>{
        console.log("ini e = " + e);
        e.preventdefault
        if(_is_mounted){
            if(this.state.email.length == 0 ) {
                this.setState({
                    errorEmail : true, 
                    errorEmailMessage : "E-mail harap diisi"
                })
                return;
            }

            if(this.state.password.length == 0 ) {
                this.setState({
                    errorPassword : true, 
                    errorPasswordMessage : "Password wajib diisi"
                })
                return;
            }
            this.executeLogin(); 

        }
        

    }

    handleEmailChange = (text)=>{
        if(_is_mounted){
            this.setState({
                email : text,
                errorEmail : false
            })
        }
    }

    handlePasswordChange = (text)=>{
        if(_is_mounted){
            this.setState({
                password : text, 
                errorPassword : false 
            })
        }
    }
    handleAlertClose = ()=>{
        this.setState({
            showAlert : false
        })
    }

    executeLogin = async()=>{
        let formData = new FormData(); 
        formData.append("username", this.state.email); 
        formData.append("password", this.state.password); 
        this.setState({
            isSubmitting : true,
            progressText : "Autentikasi"
        })

        const response = await executePost(userSignIn, formData); 
        if(response.code == code_ok) {
            console.log("respone signin" + JSON.stringify(response));
            this.setState({
                isSubmitting : false, 
                showAlert : true, 
                alertMessage : JSON.stringify(response)
            },()=>{
                global.email = response.data.user.email;
                localStorage.setItem('fullname', response.data.user.fullname)
                localStorage.setItem('email', response.data.user.email)
                localStorage.setItem('level', response.data.level)
                localStorage.setItem("main_data", JSON.stringify(response.data.user))
                global.level = response.data.level;
                let data = response.data ;
                if(global.os == "ANDROID") {
                    //sent with jsbridge
                    let uuid = global.defaultHeaders.UUID;
                    console.log('UUID = ' + uuid);
                    data.user.uuid = uuid;
                    JSBridge.launchFunction(Command.CREDENTIAL_LOGIN, JSON.stringify(response.data));
                    // JSBridge.launchFunction(Command.CREDENTIAL_LOGIN, "Autentikasi gagal! Harap login ulang!");
                }
                createAuthHeader(data);
                /*
                setTimeout(() => {
                    this.props.navigation.reset({
                        index: 0,
                        routes:[{name:'Intro', params : response.data.user}],
                    });
                }, 5);
                */
                this.props.navigation.reset({
                    index : 0, 
                    routes:[{ name : "Main" , params : response.data.user }]
                });
                // setTimeout(() => {
                //     this.props.navigation.reset({
                //         index : 0, 
                //         routes:[{ name : "Main" , params : response.data.user }]
                //     });
                // }, 10);
                
            })

        } else {
            this.setState({
                isSubmitting : false, 
                showAlert : true, 
                alertMessage : response.msg
            })
        }
    }

    render(){
        
        return(
            <View style={{flex : 1}}>
                {
                    !this.state.isSubmitting && 
                    <View style={ styles.container }> 
                        <ScrollView>
                            <View style={ styles.caption_container}>
                                {/* <Image 
                                    source={require("../assets/images/login.png")}
                                    style={ styles.logo }
                                /> */}
                                <View style={{marginTop : 64 }}>
                                    <LogoLogin 
                                        width={ 260 }
                                        height={ 270 }
                                    />
                                </View>
                            </View>
                            <View style={styles.form_container}>
                                <TextBox 
                                    caption={"Email"}
                                    editable={ true }
                                    containerStyle={{ marginTop : 8 }}
                                    placeholder={"Masukkan email"}
                                    keyboardType={"email-address"}
                                    value={this.state.email}
                                    error={ this.state.errorEmail }
                                    errorMessage={ this.state.errorEmailMessage }
                                    onChangeText={ this.handleEmailChange }
                                />

                                <PasswordBox
                                    caption={"Password"}
                                    containerStyle={{ marginTop : 16 }}
                                    placeholder={"Masukkan password"}
                                    error={ this.state.errorPassword }
                                    errorMessage={ this.state.errorPasswordMessage }
                                    onChangeText={ this.handlePasswordChange }
                                />
                            </View>
                            <View style={{ flexDirection : "row-reverse", flex : 1, alignItems : "center", marginTop : 8, marginBottom : 16, marginRight : 16 }}>
                                <Typography
                                    variant="h3"
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    color={ primary }
                                    fontWeight="600"
                                    onClick={ this.handleForgotPasswordClick }
                                >
                                    Lupa Password?
                                </Typography>
                            </View>
                            <Button
                                variant="contained"
                                style={{marginTop : 16, marginLeft : 16, marginRight : 16, textTransform : "none", borderRadius : 8, padding : 10  }}
                                onClick={ (e) => this.handleLoginClick(e) }
                            >
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 16 }
                                    fontWeight="500"
                                >
                                Masuk
                                </Typography>
                            </Button>
                            <View style={{ flex : 1, alignItems : "center", justifyContent : "center", flexDirection : "row", marginTop : 24, marginBottom : 16 }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 14 }
                                    variant="body1"
                                >
                                    Belum punya akun?
                                </Typography>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 14 }
                                    variant="body1"
                                    fontWeight="600"
                                    marginLeft={ 0.5 }
                                    color={ primary }
                                    onClick={ this.handleSignUpClick }
                                >
                                    Daftar disini
                                </Typography>
                            </View>

                        </ScrollView>
                    </View>
                }
                {
                    this.state.isSubmitting && 
                    <LoadingPage message={ this.state.progressText} />
                }
                <AlertDialog
                    showing={ this.state.showAlert } 
                    title={ "Perhatian!" }
                    message={ this.state.alertMessage }
                    onClose={ this.handleAlertClose }
                    onClick={ this.handleAlertClose }
                    buttonCaption={"OK"}
                />
                
            </View>
            
        )
    }

}

const styles = StyleSheet.create({
    container : {
        flex : 1,
        backgroundColor : "white"
    },
    headerContainer : {
        alignItems : "center"
    }, 
    header : {
        width : width, 
        backgroundColor : primary,
        borderRadius : width /2, 
        height : width,
        position : "absolute",
        top : (width / 2 ) 
    },
    caption_container : {
        fontSize : 14,
        alignItems : "center"
    },
    logo : {
        width : 275,
        height : 226,
        marginTop: 64
    },
    form_container : {
        alignItems : "left",
        marginLeft : 16,
        marginRight : 16,  
        marginTop : 57
    },
    
    
    
})



export default Login; 