import React, { Component } from 'react';
import { StyleSheet, View, Image, FlatList, Dimensions, ScrollView } from 'react-native';
import { Skeleton, Typography, Card, CardActionArea, CardMedia, CardContent, Button, IconButton } from '@mui/material';
import * as Identifier from '../constants/PageIdentifiers';
import * as Command from '../constants/Commands';
import { primary, color_text, sub_text } from '../constants/colors';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { CalendarIcon, TimeIcon } from '../assets/Icons';
import { code_ok, code_error, code_auth_error, code_empty, homepageNews } from '../constants/api';
import { executeGet, executePost, truncateText, getQueryStrings } from '../utilities/Utilities';
import Plyr from "plyr-react";
import "plyr-react/plyr.css"

var _is_mounted = false;
class DesktopNewsDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: false,
            showAlert: false,
            alertMessage: "",
            title: "",
            code: "",
            description: "",
            image: "",
            date: "",
            time: "",
            videoSrc: {},
            dataLainnya: []
        }

        this.goBack = this.goBack.bind(this);
        this.fetchNews = this.fetchNews.bind(this);
        this.renderItem = this.renderItem.bind(this);
    }


    componentDidMount = () => {
        // console.log("berita lainnya = " + JSON.stringify(this.props.route.params.dataLainnya))
        this.fetchNews();
        _is_mounted = true;
        let dates = this.props.route.params.input_time.split(" ");
        let date = dates[0] + " " + dates[1];
        let time = dates[2];
        if (_is_mounted) {
            if (this.props.route.params.tipe == "berita") {
                this.setState({
                    code: this.props.route.params.code,
                    title: this.props.route.params.title,
                    description: this.props.route.params.description,
                    image: this.props.route.params.content_image,
                    date: date,
                    time: time
                })
            } else if (this.props.route.params.tipe == "youtubeUtama") {
                console.log("YOUTUBE UTAMA")
                console.log(this.props.route.params)
                let youtube_url = this.props.route.params.url_video;
                let videoID = getQueryStrings(youtube_url)["v"];
                this.setState({
                    code: this.props.route.params.id,
                    title: this.props.route.params.title,
                    description: "",
                    image: this.props.route.params.content_image,
                    date: date,
                    time: time,
                    videoSrc: {
                        type: "video",
                        sources: [
                            {
                                src: videoID,
                                provider: "youtube"
                            }
                        ]
                    }
                })
            } else {
                let youtube_url = this.props.route.params.url_youtube;
                let videoID = getQueryStrings(youtube_url)["v"];
                this.setState({
                    code: this.props.route.params.code,
                    title: this.props.route.params.title,
                    description: this.props.route.params.description,
                    image: this.props.route.params.content_image,
                    date: date,
                    time: time,
                    videoSrc: {
                        type: "video",
                        sources: [
                            {
                                src: videoID,
                                provider: "youtube"
                            }
                        ]
                    }
                })
            }
        }


    }

    fetchNews = async () => {
        let formData = new FormData();
        let code = this.props.route.params.homepage_code
        console.log("CODE: " + code);
        console.log(this.props.route.params);
        if (this.props.route.params.tipe!="youtubeUtama") {
            formData.append("homepage_code", code);
            formData.append("page", 1);
            formData.append("limit", 12);
            // formData.append("news_code", 4);

            const response = await executePost(homepageNews, formData);
            console.log('fetchNews = ' + JSON.stringify(response))
            if (response.code == code_ok) {
                console.log(JSON.stringify(response));
                this.setState({
                    showLoading: false,
                    dataLainnya: response.data
                })
                // data: response.data

            } else {
                if (response.code != code_auth_error) {
                    this.setState({
                        showLoading: false,
                    })
                    // if(global.os == "ANDROID") {
                    //     JSBridge.launchFunction(Command.SHOW_TOAST, response.msg);
                    // } else {
                    //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : response.msg })
                    // }
                } else {
                    // if(global.os == "ANDROID"){
                    //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                    // } else {
                    //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                    // }
                    window.postMessage({ id: "sign_out" });
                }
            }
        } else {
            const response = await executeGet("https://bintangpusnas.perpusnas.go.id/api/youtubeUtama");
            console.log('fetchNews = ' + JSON.stringify(response))
            if (response.code == code_ok) {
                console.log(JSON.stringify(response));
                this.setState({
                    showLoading: false,
                    dataLainnya: response.data
                })
                // data: response.data

            } else {
                if (response.code != code_auth_error) {
                    this.setState({
                        showLoading: false,
                    })
                    // if(global.os == "ANDROID") {
                    //     JSBridge.launchFunction(Command.SHOW_TOAST, response.msg);
                    // } else {
                    //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : response.msg })
                    // }
                } else {
                    // if(global.os == "ANDROID"){
                    //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                    // } else {
                    //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                    // }
                    window.postMessage({ id: "sign_out" });
                }
            }
        }

    }

    handleNewsClick = (item, index) => {
        item.identifier = Identifier.MAIN;
        this.props.route.params = item;
        console.log("KLIKKK");
        console.log(item);
        //this.props.navigation.navigate("DesktopNewsDetail", item);
        let dates = this.props.route.params.input_time.split(" ");
        let date = dates[0] + " " + dates[1];
        let time = dates[2];
        if (this.props.route.params.tipe == "berita") {
            this.setState({
                code: this.props.route.params.code,
                title: this.props.route.params.title,
                description: this.props.route.params.description,
                image: this.props.route.params.content_image,
                date: date,
                time: time
            })
        } else if (this.props.route.params.tipe="youtubeUtama") {
            let youtube_url = this.props.route.params.url_video;
            let videoID = getQueryStrings(youtube_url)["v"];
            this.setState({
                code: this.props.route.params.id,
                title: this.props.route.params.title,
                description: "",
                image: this.props.route.params.content_image,
                date: date,
                time: time,
                videoSrc: {
                    type: "video",
                    sources: [
                        {
                            src: videoID,
                            provider: "youtube"
                        }
                    ]
                }
            })
        } else {
            let youtube_url = this.props.route.params.url_youtube;
            let videoID = getQueryStrings(youtube_url)["v"];
            this.setState({
                code: this.props.route.params.code,
                title: this.props.route.params.title,
                description: this.props.route.params.description,
                image: this.props.route.params.content_image,
                date: date,
                time: time,
                videoSrc: {
                    type: "video",
                    sources: [
                        {
                            src: videoID,
                            provider: "youtube"
                        }
                    ]
                }
            })
        }
    }

    handleImageError = (item, index) => {
        let data = [...this.state.dataLainnya];
        let obj = { ...data[index] };
        obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
        data[index] = obj;
        this.setState({
            dataLainnya: data
        })
    }

    renderItem = ({ item, index, separators }) => {
        return (
            <>
                {
                    index < 3 &&
                    <Card
                        style={{
                            borderRadius: 8,
                            marginLeft: 16,
                            marginRight: 16,
                            marginTop: (index == 0 ? 0 : 8),
                            marginBottom: (index == (this.state.dataLainnya.length - 1) ? 8 : 0),
                            boxShadow: "0px 33px 74px rgba(0, 0, 0, 0.0196802), 0px 13.7866px 30.9154px rgba(0, 0, 0, 0.0282725), 0px 7.37098px 16.5289px rgba(0, 0, 0, 0.035), 0px 4.13211px 9.26595px rgba(0, 0, 0, 0.0417275), 0px 2.19453px 4.92108px rgba(0, 0, 0, 0.0503198), 0px 0.913195px 2.04777px rgba(0, 0, 0, 0.07)"
                        }}
                    >
                        <CardActionArea
                            onClick={() => { this.handleNewsClick(item, index) }}
                        >
                            <View style={{ flexDirection: "row", padding: 8 }}>
                                <Image
                                    style={{
                                        width: 90,
                                        height: 90,
                                        borderRadius: 8
                                    }}
                                    source={{ uri: item.content_image }}
                                    onError={(error) => { this.handleImageError(item, index) }}
                                />
                                <View style={{ flex: 1, marginLeft: 10 }}>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={12}
                                        color={color_text}
                                        fontWeight={"600"}
                                    >
                                        { truncateText(item.title, 45) }
                                        {/* {item.title} */}
                                    </Typography>
                                    <View style={{ flexDirection: "row", alignItems: "center", marginTop: 4 }}>
                                        <CalendarIcon />
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={9}
                                            fontWeight={"400"}
                                            color={sub_text}
                                            style={{
                                                marginLeft: 4
                                            }}
                                        >
                                            {this.state.date}
                                        </Typography>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={9}
                                            fontWeight={"400"}
                                            color={sub_text}
                                            style={{
                                                marginLeft: 4,
                                            }}
                                        >
                                            {this.state.time}
                                        </Typography>
                                    </View>

                                </View>
                            </View>
                        </CardActionArea>
                    </Card>

                }

            </>
        )
    }

    componentWillUnmount = () => {
        _is_mounted = false;
        //window.removeEventListener("message", null); 

    }

    goBack = () => {
        this.props.navigation.goBack();
    }

    render() {
        const image_width = Dimensions.get("window").width;
        const image_height = image_width - (image_width * 0.50);
        return (
            <View style={{ flex: 1 }}>
                <View style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    flex: 0.05,
                    backgroundColor: "white",
                    flexDirection: "row",
                    alignItems: "center",
                    boxShadow: "0 4px 6px -1px rgba(0,0,0, 0.07)"
            
                }}>
                    <IconButton
                        style={{ marginRight: 16, marginLeft: 16 }}
                        onClick={()=>{ this.goBack() }}
                    >
                        <ChevronLeftIcon 
                            sx={{color : color_text }}
                        />
                    </IconButton>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 18 }
                        fontWeight={"600"}
                    >
                        Detail Berita
                    </Typography>
                </View>
                <View style={{
                    flex: 1,
                    backgroundColor: "white",
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    paddingLeft: '147px',
                    paddingRight: '147px',
                    paddingTop: '30px'
                }}>
                    <ScrollView>
                        <View style={{ flex: 1, flexDirection: "row" }}>
                            <View style={{ flex: 0.7 }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={28}
                                    fontWeight={"600"}
                                    color={color_text}
                                    style={{
                                        marginTop: 24,
                                        marginLeft: 16,
                                        marginRight: 16,
                                        marginBottom: 8
                                    }}
                                >
                                    {this.state.title}
                                </Typography>

                                <View style={{ flexDirection: "row", alignItems: "center", marginLeft: 16, marginRight: 16, marginBottom: 30 }}>
                                    <CalendarIcon />
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={14}
                                        fontWeight={"400"}
                                        color={sub_text}
                                        style={{
                                            marginLeft: 4,
                                            marginRight: 24,
                                        }}
                                    >
                                        {this.state.date}
                                    </Typography>
                                    <TimeIcon />
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={14}
                                        fontWeight={"400"}
                                        color={sub_text}
                                        style={{
                                            marginLeft: 4,
                                        }}
                                    >
                                        {this.state.time}
                                    </Typography>
                                </View>

                                {
                                    this.props.route.params.tipe == "berita" &&
                                    <Image
                                        style={{
                                            height: '500px',
                                            borderRadius: 8,
                                            marginLeft: 16,
                                            marginRight: 16,
                                            marginBottom: 16
                                        }}
                                        resizeMode={"stretch"}
                                        source={{ uri: this.state.image }}
                                    />
                                }
                                {
                                    this.props.route.params.tipe == "youtube" &&
                                    <Plyr source={this.state.videoSrc} />
                                    // <></>
                                }
                                {
                                    this.props.route.params.tipe == "youtubeUtama" &&
                                    <Plyr source={this.state.videoSrc} />
                                }

                                <View style={{ marginLeft: 16, marginRight: 16 }}>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={14}
                                        component={'span'}
                                        variant={'body2'}
                                    >
                                        <div
                                            dangerouslySetInnerHTML={{ __html: this.state.description }}
                                        />
                                    </Typography>
                                </View>

                            </View>
                            <View style={{ flex: 0.3, marginTop: 100, marginLeft: 16 }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={20}
                                    fontWeight={"600"}
                                    color={color_text}
                                    style={{
                                        marginTop: 24,
                                        marginLeft: 16,
                                        marginRight: 16,
                                        marginBottom: 8
                                    }}
                                >
                                    Berita Lainnya
                                </Typography>
                                <View style={{ flex: 1 }} >
                                    <FlatList
                                        keyExtractor={(item, index) => "rec_" + item.content_code}
                                        data={this.state.dataLainnya}
                                        renderItem={this.renderItem}
                                        showsVerticalScrollIndicator={false}
                                    />
                                </View>
                            </View>
                        </View>

                    </ScrollView>
                </View>

            </View>
        )
    }
}

export default DesktopNewsDetail;