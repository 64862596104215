import React, { Component } from "react"
import Svg, { G, Path, Defs, ClipPath, Rect } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */

class LogoPusnasNew extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
          <Svg width={250} height={43} viewBox="0 0 250 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...this.props}>
        <G clipPath="url(#clip0_731_1156)">
          <Path d="M25.5128 33.045C26.0439 33.5085 26.3046 34.1459 26.3046 34.9763C26.3046 35.8454 26.0343 36.5214 25.5031 36.9946C24.972 37.4774 24.2188 37.7092 23.2531 37.7092H21.3218V40.133H20.2499V32.3401H23.2531C24.2285 32.3594 24.9817 32.5912 25.5128 33.045ZM24.7499 36.309C25.1072 36.0193 25.281 35.5847 25.281 35.015C25.281 34.4549 25.1072 34.0397 24.7499 33.75C24.3926 33.4603 23.8808 33.3251 23.2145 33.3251L21.3218 33.3347V36.7532H23.2145C23.8808 36.7532 24.3926 36.6083 24.7499 36.309Z" fill="#43556B" />
          <Path
            d="M32.0502 35.0343C32.5137 35.5751 32.7552 36.3186 32.7552 37.2746C32.7552 37.4195 32.7552 37.5354 32.7455 37.6126H28.091C28.1683 38.1437 28.3904 38.559 28.7573 38.868C29.1146 39.177 29.5588 39.3315 30.0899 39.3315C30.4376 39.3315 30.7659 39.2639 31.0652 39.1384C31.3646 39.0128 31.6253 38.839 31.8474 38.6072L32.4172 39.1963C32.1275 39.5053 31.7798 39.7467 31.3743 39.9205C30.9687 40.0944 30.5148 40.1813 30.0127 40.1813C29.4333 40.1813 28.9215 40.0557 28.4676 39.8047C28.0234 39.5536 27.6661 39.206 27.4247 38.7521C27.1736 38.2982 27.0481 37.7864 27.0481 37.207C27.0481 36.6276 27.1736 36.1158 27.4247 35.662C27.6758 35.2178 28.0234 34.8605 28.4676 34.6094C28.9118 34.3487 29.4236 34.2231 29.9934 34.2231C30.8914 34.2231 31.5867 34.4935 32.0502 35.0343ZM31.8185 36.8111C31.7895 36.28 31.606 35.8551 31.2873 35.5461C30.959 35.2371 30.5341 35.0826 29.9934 35.0826C29.4719 35.0826 29.0374 35.2371 28.6994 35.5461C28.3517 35.8551 28.1489 36.28 28.0814 36.8015H31.8185V36.8111Z"
            fill="#43556B"
          />
          <Path d="M36.0192 34.5706C36.3765 34.3485 36.8014 34.2423 37.2842 34.2327V35.237C36.6855 35.2176 36.193 35.3721 35.826 35.7005C35.4591 36.0385 35.2467 36.4827 35.1887 37.0427V40.1425H34.1555V34.252H35.1887V35.517C35.3915 35.1018 35.6619 34.7831 36.0192 34.5706Z" fill="#43556B" />
          <Path
            d="M43.1554 34.6091C43.59 34.8602 43.9183 35.2175 44.1597 35.6617C44.4011 36.1155 44.517 36.637 44.517 37.2164C44.517 37.7958 44.4011 38.3076 44.1694 38.7615C43.9376 39.2153 43.6093 39.563 43.1748 39.8044C42.7499 40.0554 42.2574 40.181 41.7069 40.181C41.2145 40.181 40.7703 40.0748 40.3936 39.872C40.017 39.6595 39.7177 39.3698 39.5052 38.9836V42.3151H38.472V34.2711H39.5052V35.4396C39.7177 35.0533 40.017 34.754 40.3936 34.5512C40.7703 34.3484 41.2048 34.2422 41.6876 34.2422C42.2381 34.2229 42.7209 34.3484 43.1554 34.6091ZM42.5085 39.0125C42.8078 38.8387 43.0396 38.5876 43.2134 38.269C43.3775 37.9503 43.4645 37.593 43.4645 37.1874C43.4645 36.7915 43.3775 36.4342 43.2037 36.1156C43.0299 35.7969 42.7981 35.5458 42.4988 35.372C42.1994 35.1982 41.8615 35.1016 41.4848 35.1016C41.1082 35.1016 40.7703 35.1885 40.4709 35.372C40.1715 35.5458 39.9301 35.7969 39.766 36.1156C39.5922 36.4342 39.5052 36.7915 39.5052 37.1874C39.5052 37.593 39.5922 37.96 39.766 38.269C39.9398 38.5876 40.1715 38.8291 40.4709 39.0125C40.7703 39.1864 41.1082 39.2829 41.4848 39.2829C41.8615 39.2829 42.1994 39.1864 42.5085 39.0125Z"
            fill="#43556B"
          />
          <Path d="M51.1704 34.2616V40.1521H50.1178V38.9064C49.7315 39.7466 49.0073 40.1715 47.9354 40.1908C47.2498 40.1908 46.7187 39.988 46.3324 39.5824C45.9461 39.1768 45.753 38.6167 45.753 37.9118V34.2713H46.7959V37.6318C46.7959 38.1243 46.9311 38.5202 47.2015 38.7906C47.4719 39.0706 47.8485 39.2058 48.3216 39.2058C48.8721 39.1961 49.3066 39.003 49.6253 38.6167C49.944 38.2305 50.1081 37.7476 50.1081 37.1489V34.252H51.1704V34.2616Z" fill="#43556B" />
          <Path
            d="M55.5931 35.2081C55.3034 35.1212 55.0233 35.0729 54.753 35.0729C54.4439 35.0729 54.1929 35.1309 53.9997 35.2467C53.8066 35.3626 53.7197 35.5364 53.7197 35.7779C53.7197 36.0193 53.8259 36.1931 54.0384 36.3186C54.2508 36.4442 54.5695 36.5697 55.004 36.6952C55.4096 36.8111 55.7476 36.9367 56.0083 37.0525C56.269 37.1684 56.4911 37.3422 56.6746 37.574C56.8581 37.8057 56.945 38.1051 56.945 38.4817C56.945 39.0418 56.7326 39.457 56.3173 39.7467C55.9021 40.0364 55.3806 40.1813 54.753 40.1813C54.3184 40.1813 53.8935 40.1137 53.4783 39.9688C53.063 39.824 52.7154 39.6308 52.4354 39.3701L52.8216 38.6169C53.063 38.839 53.3721 39.0225 53.7294 39.148C54.0866 39.2832 54.4439 39.3508 54.7819 39.3508C55.1199 39.3508 55.3999 39.2832 55.6027 39.1577C55.8152 39.0321 55.9117 38.839 55.9117 38.5783C55.9117 38.3079 55.8055 38.1148 55.5834 37.9796C55.3613 37.8444 55.033 37.7092 54.5888 37.5836C54.1929 37.4678 53.8742 37.3615 53.6231 37.2457C53.3721 37.1298 53.1596 36.9656 52.9858 36.7435C52.812 36.5214 52.7251 36.2317 52.7251 35.8744C52.7251 35.324 52.9279 34.9184 53.3238 34.6384C53.7197 34.368 54.2218 34.2231 54.8302 34.2231C55.1875 34.2231 55.5351 34.2714 55.8828 34.3777C56.2304 34.4742 56.5298 34.6094 56.7808 34.7832L56.4042 35.5751C56.1532 35.4109 55.8828 35.295 55.5931 35.2081Z"
            fill="#43556B"
          />
          <Path d="M61.4643 39.7563C61.0105 40.046 60.5566 40.1908 60.0835 40.1908C59.6199 40.1908 59.2337 40.0556 58.944 39.7756C58.6543 39.4955 58.5094 39.0803 58.5094 38.5106V35.2466H57.679V34.4548H58.5094V32.8711H59.5523V34.4548H61.3581V35.2466H59.5523V38.3174C59.5523 38.6457 59.6103 38.8775 59.7262 39.0224C59.842 39.1672 60.0255 39.2348 60.2573 39.2348C60.5373 39.2348 60.8463 39.1382 61.165 38.9548L61.4643 39.7563Z" fill="#43556B" />
          <Path
            d="M66.3119 40.1425V39.3217C65.8774 39.9011 65.1918 40.1908 64.2551 40.1908C63.8495 40.1908 63.4922 40.1136 63.1832 39.9591C62.8742 39.8046 62.6424 39.5921 62.4686 39.3121C62.3044 39.0417 62.2175 38.7327 62.2175 38.385C62.2175 37.8539 62.4107 37.4387 62.7969 37.1297C63.1832 36.8207 63.7336 36.6662 64.4289 36.6565H66.3023V36.3765C66.3023 35.9612 66.1767 35.6426 65.916 35.4108C65.6553 35.1887 65.2883 35.0728 64.7958 35.0728C64.2068 35.0728 63.5984 35.2853 62.9804 35.7102L62.5459 34.9956C62.9707 34.7252 63.357 34.5224 63.724 34.3969C64.0813 34.2713 64.5158 34.2134 65.0083 34.2134C65.7422 34.2134 66.3119 34.3969 66.7079 34.7541C67.1038 35.1114 67.3162 35.6136 67.3162 36.2606L67.3259 40.1329H66.3119V40.1425ZM65.6939 38.9837C66.0319 38.752 66.2347 38.443 66.3023 38.0567V37.4677H64.5737C64.1102 37.4677 63.7626 37.5353 63.5308 37.6801C63.3087 37.825 63.1929 38.0471 63.1929 38.3464C63.1929 38.6458 63.3087 38.8872 63.5405 39.0707C63.7722 39.2445 64.0813 39.341 64.4675 39.341C64.9503 39.3314 65.3559 39.2155 65.6939 38.9837Z"
            fill="#43556B"
          />
          <Path d="M73.3033 40.1426L71.4203 37.2843L70.2712 38.472V40.1426H69.2379V31.8862H70.2712V37.1491L73.1199 34.2521H74.3366L72.1446 36.5311L74.5491 40.133H73.3033V40.1426Z" fill="#43556B" />
          <Path
            d="M78.9524 40.1425V39.3217C78.5179 39.9011 77.8323 40.1908 76.8956 40.1908C76.49 40.1908 76.1327 40.1136 75.8237 39.9591C75.5147 39.8046 75.2829 39.5921 75.1091 39.3121C74.9449 39.0417 74.858 38.7327 74.858 38.385C74.858 37.8539 75.0512 37.4387 75.4374 37.1297C75.8237 36.8207 76.3741 36.6662 77.0694 36.6565H78.9428V36.3765C78.9428 35.9612 78.8172 35.6426 78.5565 35.4108C78.2958 35.1887 77.9288 35.0728 77.4363 35.0728C76.8473 35.0728 76.2389 35.2853 75.6209 35.7102L75.1864 34.9956C75.6112 34.7252 75.9975 34.5224 76.3645 34.3969C76.7218 34.2713 77.1563 34.2134 77.6488 34.2134C78.3827 34.2134 78.9524 34.3969 79.3484 34.7541C79.7443 35.1114 79.9567 35.6136 79.9567 36.2606L79.9664 40.1329H78.9524V40.1425ZM78.3344 38.9837C78.6724 38.752 78.8752 38.443 78.9428 38.0567V37.4677H77.2142C76.7507 37.4677 76.4031 37.5353 76.1713 37.6801C75.9492 37.825 75.8333 38.0471 75.8333 38.3464C75.8333 38.6458 75.9492 38.8872 76.181 39.0707C76.4127 39.2445 76.7218 39.341 77.108 39.341C77.5909 39.3314 77.9964 39.2155 78.3344 38.9837Z"
            fill="#43556B"
          />
          <Path
            d="M85.4128 40.1425V39.3217C84.9782 39.9011 84.2926 40.1908 83.3559 40.1908C82.9503 40.1908 82.593 40.1136 82.284 39.9591C81.975 39.8046 81.7433 39.5921 81.5694 39.3121C81.3956 39.032 81.3184 38.7327 81.3184 38.385C81.3184 37.8539 81.5115 37.4387 81.8978 37.1297C82.284 36.8207 82.8344 36.6662 83.5297 36.6565H85.4031V36.3765C85.4031 35.9612 85.2776 35.6426 85.0168 35.4108C84.7561 35.1887 84.3892 35.0728 83.8967 35.0728C83.3076 35.0728 82.6993 35.2853 82.0812 35.7102L81.6467 34.9956C82.0716 34.7252 82.4578 34.5224 82.8248 34.3969C83.1821 34.2713 83.6166 34.2134 84.1091 34.2134C84.843 34.2134 85.4128 34.3969 85.8087 34.7541C86.2046 35.1114 86.4171 35.6136 86.4171 36.2606L86.4267 40.1329H85.4128V40.1425ZM84.8044 38.9837C85.1424 38.752 85.3452 38.443 85.4128 38.0567V37.4677H83.6842C83.2207 37.4677 82.8731 37.5353 82.6413 37.6801C82.4192 37.825 82.3033 38.0471 82.3033 38.3464C82.3033 38.6458 82.4192 38.8872 82.651 39.0707C82.8827 39.2445 83.1917 39.341 83.578 39.341C84.0512 39.3314 84.4664 39.2155 84.8044 38.9837Z"
            fill="#43556B"
          />
          <Path d="M93.1863 34.8314C93.5822 35.237 93.7753 35.7971 93.7753 36.502V40.1425H92.7421V36.782C92.7421 36.2896 92.6069 35.8936 92.3269 35.6232C92.0468 35.3432 91.6605 35.208 91.1777 35.208C90.6466 35.2177 90.2217 35.3818 89.8934 35.7102C89.5651 36.0385 89.3719 36.473 89.3333 36.9945V40.1425H88.3V34.252H89.3236V35.4977C89.7196 34.6576 90.4631 34.2327 91.564 34.2134C92.2496 34.223 92.7904 34.4258 93.1863 34.8314Z" fill="#43556B" />
          <Path d="M98.6326 32.3594H99.8976L102.746 37.8347L105.576 32.3594H106.86V40.1523H105.856L105.846 33.9334L103.075 39.3314H102.418L99.6273 33.9334V40.1523H98.6326V32.3594Z" fill="#43556B" />
          <Path
            d="M113.417 35.0343C113.88 35.5751 114.122 36.3186 114.122 37.2746C114.122 37.4195 114.122 37.5354 114.112 37.6126H109.458C109.535 38.1437 109.757 38.559 110.124 38.868C110.481 39.177 110.926 39.3315 111.457 39.3315C111.804 39.3315 112.133 39.2639 112.432 39.1384C112.731 39.0128 112.992 38.839 113.214 38.6072L113.784 39.1963C113.494 39.5053 113.147 39.7467 112.741 39.9205C112.335 40.0944 111.882 40.1813 111.379 40.1813C110.8 40.1813 110.288 40.0557 109.834 39.8047C109.39 39.5536 109.033 39.206 108.791 38.7521C108.54 38.2982 108.415 37.7864 108.415 37.207C108.415 36.6276 108.54 36.1158 108.791 35.662C109.042 35.2178 109.39 34.8605 109.834 34.6094C110.279 34.3487 110.79 34.2231 111.36 34.2231C112.258 34.2231 112.944 34.4935 113.417 35.0343ZM113.185 36.8111C113.156 36.28 112.973 35.8551 112.654 35.5461C112.326 35.2371 111.901 35.0826 111.36 35.0826C110.839 35.0826 110.404 35.2371 110.066 35.5461C109.718 35.8551 109.516 36.28 109.448 36.8015H113.185V36.8111Z"
            fill="#43556B"
          />
          <Path d="M120.428 34.8314C120.823 35.237 121.017 35.7971 121.017 36.502V40.1425H119.983V36.782C119.983 36.2896 119.848 35.8936 119.568 35.6232C119.288 35.3432 118.902 35.208 118.419 35.208C117.888 35.2177 117.463 35.3818 117.135 35.7102C116.806 36.0385 116.613 36.473 116.575 36.9945V40.1425H115.541V34.252H116.575V35.4977C116.97 34.6576 117.714 34.2327 118.815 34.2134C119.491 34.223 120.032 34.4258 120.428 34.8314Z" fill="#43556B" />
          <Path d="M121.973 41.4559C122.253 41.4656 122.465 41.369 122.62 41.1662C122.765 40.9634 122.842 40.6737 122.842 40.2875V34.2521H123.885V40.4323C123.885 41.0407 123.74 41.5138 123.44 41.8422C123.141 42.1705 122.736 42.3347 122.204 42.3347C121.866 42.3347 121.528 42.2671 121.19 42.1319L121.432 41.34C121.596 41.4076 121.78 41.4462 121.973 41.4559ZM123.817 32.0986C123.943 32.2242 124.001 32.3883 124.001 32.5815C124.001 32.7746 123.943 32.9388 123.817 33.0643C123.692 33.1898 123.537 33.2574 123.354 33.2574C123.17 33.2574 123.016 33.1898 122.89 33.0643C122.765 32.9388 122.707 32.7746 122.707 32.5815C122.707 32.3883 122.765 32.2338 122.89 32.0986C123.016 31.9731 123.17 31.9055 123.354 31.9055C123.537 31.9152 123.692 31.9731 123.817 32.0986Z" fill="#43556B" />
          <Path
            d="M129.379 40.1425V39.3217C128.945 39.9011 128.259 40.1908 127.322 40.1908C126.917 40.1908 126.56 40.1136 126.251 39.9591C125.942 39.8046 125.71 39.5921 125.536 39.3121C125.372 39.0417 125.285 38.7327 125.285 38.385C125.285 37.8539 125.478 37.4387 125.864 37.1297C126.251 36.8207 126.801 36.6662 127.496 36.6565H129.37V36.3765C129.37 35.9612 129.244 35.6426 128.983 35.4108C128.723 35.1887 128.356 35.0728 127.863 35.0728C127.274 35.0728 126.666 35.2853 126.048 35.7102L125.613 34.9956C126.038 34.7252 126.424 34.5224 126.791 34.3969C127.149 34.2713 127.583 34.2134 128.076 34.2134C128.81 34.2134 129.379 34.3969 129.775 34.7541C130.171 35.1114 130.384 35.6136 130.384 36.2606L130.393 40.1329H129.379V40.1425ZM128.761 38.9837C129.099 38.752 129.302 38.443 129.37 38.0567V37.4677H127.641C127.178 37.4677 126.83 37.5353 126.598 37.6801C126.376 37.825 126.26 38.0471 126.26 38.3464C126.26 38.6458 126.376 38.8872 126.608 39.0707C126.84 39.2445 127.149 39.341 127.535 39.341C128.018 39.3314 128.423 39.2155 128.761 38.9837Z"
            fill="#43556B"
          />
          <Path d="M137.153 34.8314C137.549 35.237 137.742 35.7971 137.742 36.502V40.1425H136.709V36.782C136.709 36.2896 136.573 35.8936 136.293 35.6232C136.013 35.3432 135.627 35.208 135.144 35.208C134.613 35.2177 134.188 35.3818 133.86 35.7102C133.532 36.0385 133.338 36.473 133.3 36.9945V40.1425H132.267V34.252H133.3V35.4977C133.696 34.6576 134.439 34.2327 135.54 34.2134C136.216 34.223 136.757 34.4258 137.153 34.8314Z" fill="#43556B" />
          <Path
            d="M144.791 34.2617V39.5439C144.791 40.104 144.666 40.5965 144.415 41.0214C144.164 41.4463 143.816 41.7649 143.362 41.9967C142.908 42.2188 142.387 42.3347 141.778 42.3347C140.909 42.3347 140.098 42.0643 139.355 41.5139L139.808 40.7606C140.098 41.0021 140.397 41.1759 140.706 41.2918C141.015 41.4076 141.363 41.4656 141.73 41.4656C142.338 41.4656 142.821 41.2918 143.188 40.9538C143.555 40.6158 143.729 40.1523 143.729 39.5825V38.7617C143.517 39.119 143.237 39.3894 142.879 39.5922C142.522 39.7853 142.116 39.8915 141.653 39.8915C141.122 39.8915 140.658 39.7757 140.253 39.5343C139.847 39.2928 139.538 38.9549 139.316 38.53C139.094 38.0954 138.978 37.6029 138.978 37.0525C138.978 36.5021 139.094 36.0192 139.316 35.5944C139.538 35.1695 139.847 34.8411 140.253 34.5997C140.658 34.3583 141.122 34.2424 141.653 34.2424C142.116 34.2424 142.532 34.339 142.879 34.5321C143.227 34.7253 143.507 34.9956 143.719 35.3529V34.2714H144.791V34.2617ZM142.85 38.7907C143.13 38.6169 143.352 38.3851 143.517 38.0858C143.681 37.7864 143.758 37.4484 143.758 37.0718C143.758 36.6856 143.681 36.3476 143.517 36.0386C143.352 35.7392 143.14 35.4978 142.85 35.3336C142.57 35.1598 142.242 35.0729 141.885 35.0729C141.527 35.0729 141.199 35.1598 140.909 35.3336C140.62 35.5074 140.397 35.7392 140.233 36.0386C140.069 36.3379 139.992 36.6856 139.992 37.0718C140.002 37.6512 140.175 38.1244 140.523 38.501C140.871 38.8679 141.325 39.0611 141.885 39.0611C142.242 39.0418 142.561 38.9645 142.85 38.7907Z"
            fill="#43556B"
          />
          <Path d="M150.807 40.1426L148.924 37.2843L147.775 38.472V40.1426H146.742V31.8862H147.775V37.1491L150.624 34.2521H151.841L149.648 36.5311L152.053 40.133H150.807V40.1426Z" fill="#43556B" />
          <Path
            d="M156.456 40.1425V39.3217C156.022 39.9011 155.336 40.1908 154.4 40.1908C153.994 40.1908 153.637 40.1136 153.328 39.9591C153.019 39.8046 152.787 39.5921 152.613 39.3121C152.449 39.0417 152.362 38.7327 152.362 38.385C152.362 37.8539 152.555 37.4387 152.941 37.1297C153.328 36.8207 153.878 36.6662 154.573 36.6565H156.447V36.3765C156.447 35.9612 156.321 35.6426 156.06 35.4108C155.8 35.1887 155.433 35.0728 154.94 35.0728C154.351 35.0728 153.743 35.2853 153.125 35.7102L152.69 34.9956C153.115 34.7252 153.501 34.5224 153.868 34.3969C154.226 34.2713 154.66 34.2134 155.153 34.2134C155.887 34.2134 156.456 34.3969 156.852 34.7541C157.248 35.1114 157.461 35.6136 157.461 36.2606L157.47 40.1329H156.456V40.1425ZM155.838 38.9837C156.176 38.752 156.379 38.443 156.447 38.0567V37.4677H154.718C154.255 37.4677 153.907 37.5353 153.675 37.6801C153.453 37.825 153.337 38.0471 153.337 38.3464C153.337 38.6458 153.453 38.8872 153.685 39.0707C153.917 39.2445 154.226 39.341 154.612 39.341C155.095 39.3314 155.5 39.2155 155.838 38.9837Z"
            fill="#43556B"
          />
          <Path d="M164.539 34.2616V40.1521H163.486V38.9064C163.1 39.7466 162.376 40.1715 161.304 40.1908C160.618 40.1908 160.087 39.988 159.701 39.5824C159.315 39.1768 159.122 38.6167 159.122 37.9118V34.2713H160.165V37.6318C160.165 38.1243 160.3 38.5202 160.57 38.7906C160.841 39.0706 161.217 39.2058 161.69 39.2058C162.241 39.1961 162.675 39.003 162.994 38.6167C163.313 38.2305 163.477 37.7476 163.477 37.1489V34.252H164.539V34.2616Z" fill="#43556B" />
          <Path d="M169.56 32.3594H170.825L173.674 37.8347L176.504 32.3594H177.788V40.1523H176.784L176.774 33.9334L174.002 39.3314H173.346L170.555 33.9334V40.1523H169.56V32.3594Z" fill="#43556B" />
          <Path
            d="M183.505 40.1425V39.3217C183.07 39.9011 182.384 40.1908 181.448 40.1908C181.042 40.1908 180.685 40.1136 180.376 39.9591C180.067 39.8046 179.835 39.5921 179.661 39.3121C179.497 39.0417 179.41 38.7327 179.41 38.385C179.41 37.8539 179.603 37.4387 179.99 37.1297C180.376 36.8207 180.926 36.6662 181.622 36.6565H183.495V36.3765C183.495 35.9612 183.369 35.6426 183.109 35.4108C182.848 35.1887 182.481 35.0728 181.988 35.0728C181.399 35.0728 180.791 35.2853 180.173 35.7102L179.739 34.9956C180.163 34.7252 180.55 34.5224 180.917 34.3969C181.274 34.2713 181.708 34.2134 182.201 34.2134C182.935 34.2134 183.505 34.3969 183.901 34.7541C184.296 35.1114 184.509 35.6136 184.509 36.2606L184.519 40.1329H183.505V40.1425ZM182.887 38.9837C183.225 38.752 183.427 38.443 183.495 38.0567V37.4677H181.766C181.303 37.4677 180.955 37.5353 180.723 37.6801C180.501 37.825 180.386 38.0471 180.386 38.3464C180.386 38.6458 180.501 38.8872 180.733 39.0707C180.965 39.2445 181.274 39.341 181.66 39.341C182.143 39.3314 182.549 39.2155 182.887 38.9837Z"
            fill="#43556B"
          />
          <Path
            d="M188.787 35.2081C188.497 35.1212 188.217 35.0729 187.947 35.0729C187.638 35.0729 187.387 35.1309 187.193 35.2467C187 35.3626 186.913 35.5364 186.913 35.7779C186.913 36.0193 187.02 36.1931 187.232 36.3186C187.444 36.4442 187.763 36.5697 188.198 36.6952C188.603 36.8111 188.941 36.9367 189.202 37.0525C189.463 37.1684 189.685 37.3422 189.868 37.574C190.052 37.8057 190.139 38.1051 190.139 38.4817C190.139 39.0418 189.926 39.457 189.511 39.7467C189.096 40.0364 188.574 40.1813 187.947 40.1813C187.512 40.1813 187.087 40.1137 186.672 39.9688C186.257 39.824 185.909 39.6308 185.629 39.3701L186.015 38.6169C186.257 38.839 186.566 39.0225 186.923 39.148C187.28 39.2832 187.638 39.3508 187.976 39.3508C188.314 39.3508 188.594 39.2832 188.796 39.1577C189.009 39.0321 189.105 38.839 189.105 38.5783C189.105 38.3079 188.999 38.1148 188.777 37.9796C188.555 37.8444 188.227 37.7092 187.782 37.5836C187.387 37.4678 187.068 37.3615 186.817 37.2457C186.566 37.1298 186.353 36.9656 186.179 36.7435C186.006 36.5214 185.919 36.2317 185.919 35.8744C185.919 35.324 186.122 34.9184 186.517 34.6384C186.913 34.368 187.415 34.2231 188.024 34.2231C188.381 34.2231 188.729 34.2714 189.076 34.3777C189.424 34.4839 189.723 34.6094 189.975 34.7832L189.598 35.5751C189.347 35.4109 189.076 35.295 188.787 35.2081Z"
            fill="#43556B"
          />
          <Path d="M192.775 42.0063C192.466 42.2381 192.109 42.354 191.693 42.354C191.442 42.354 191.211 42.3153 191.008 42.2381C190.795 42.1608 190.602 42.0449 190.409 41.8904L190.853 41.0793C191.114 41.2628 191.375 41.3593 191.645 41.3593C192.089 41.3593 192.427 41.1083 192.649 40.6061L192.871 40.0847L190.438 34.2617H191.529L193.403 38.9838L195.17 34.2617H196.222L193.528 40.9827C193.325 41.4366 193.074 41.7746 192.775 42.0063Z" fill="#43556B" />
          <Path
            d="M200.606 40.1425V39.3217C200.172 39.9011 199.486 40.1908 198.55 40.1908C198.144 40.1908 197.787 40.1136 197.478 39.9591C197.169 39.8046 196.937 39.5921 196.763 39.3121C196.599 39.0417 196.512 38.7327 196.512 38.385C196.512 37.8539 196.705 37.4387 197.091 37.1297C197.478 36.8207 198.028 36.6662 198.723 36.6565H200.597V36.3765C200.597 35.9612 200.471 35.6426 200.211 35.4108C199.95 35.1887 199.583 35.0728 199.09 35.0728C198.501 35.0728 197.893 35.2853 197.275 35.7102L196.84 34.9956C197.265 34.7252 197.652 34.5224 198.018 34.3969C198.376 34.2713 198.81 34.2134 199.303 34.2134C200.037 34.2134 200.606 34.3969 201.002 34.7541C201.398 35.1114 201.611 35.6136 201.611 36.2606L201.62 40.1329H200.606V40.1425ZM199.988 38.9837C200.326 38.752 200.529 38.443 200.597 38.0567V37.4677H198.868C198.405 37.4677 198.057 37.5353 197.825 37.6801C197.603 37.825 197.487 38.0471 197.487 38.3464C197.487 38.6458 197.603 38.8872 197.835 39.0707C198.067 39.2445 198.376 39.341 198.762 39.341C199.245 39.3314 199.65 39.2155 199.988 38.9837Z"
            fill="#43556B"
          />
          <Path d="M205.338 34.5706C205.695 34.3485 206.12 34.2423 206.603 34.2327V35.237C206.005 35.2176 205.512 35.3721 205.145 35.7005C204.778 36.0385 204.566 36.4827 204.508 37.0427V40.1425H203.474V34.252H204.508V35.517C204.711 35.1018 204.981 34.7831 205.338 34.5706Z" fill="#43556B" />
          <Path
            d="M211.074 40.1425V39.3217C210.64 39.9011 209.954 40.1908 209.017 40.1908C208.612 40.1908 208.254 40.1136 207.945 39.9591C207.636 39.8046 207.405 39.5921 207.231 39.3121C207.057 39.032 206.98 38.7327 206.98 38.385C206.98 37.8539 207.173 37.4387 207.559 37.1297C207.945 36.8207 208.496 36.6662 209.191 36.6565H211.065V36.3765C211.065 35.9612 210.939 35.6426 210.678 35.4108C210.418 35.1887 210.051 35.0728 209.558 35.0728C208.969 35.0728 208.361 35.2853 207.743 35.7102L207.308 34.9956C207.733 34.7252 208.119 34.5224 208.486 34.3969C208.844 34.2713 209.278 34.2134 209.771 34.2134C210.504 34.2134 211.074 34.3969 211.47 34.7541C211.866 35.1114 212.078 35.6136 212.078 36.2606L212.088 40.1329H211.074V40.1425ZM210.456 38.9837C210.794 38.752 210.997 38.443 211.065 38.0567V37.4677H209.336C208.873 37.4677 208.525 37.5353 208.293 37.6801C208.071 37.825 207.955 38.0471 207.955 38.3464C207.955 38.6458 208.071 38.8872 208.303 39.0707C208.535 39.2445 208.844 39.341 209.23 39.341C209.703 39.3314 210.118 39.2155 210.456 38.9837Z"
            fill="#43556B"
          />
          <Path d="M218.056 40.1426L216.173 37.2843L215.024 38.472V40.1426H213.991V31.8862H215.024V37.1491L217.872 34.2521H219.089L216.897 36.5311L219.302 40.133H218.056V40.1426Z" fill="#43556B" />
          <Path
            d="M223.715 40.1425V39.3217C223.28 39.9011 222.595 40.1908 221.658 40.1908C221.252 40.1908 220.895 40.1136 220.586 39.9591C220.277 39.8046 220.045 39.5921 219.871 39.3121C219.698 39.032 219.62 38.7327 219.62 38.385C219.62 37.8539 219.813 37.4387 220.2 37.1297C220.586 36.8207 221.136 36.6662 221.832 36.6565H223.705V36.3765C223.705 35.9612 223.58 35.6426 223.319 35.4108C223.058 35.1887 222.691 35.0728 222.199 35.0728C221.61 35.0728 221.001 35.2853 220.383 35.7102L219.949 34.9956C220.374 34.7252 220.76 34.5224 221.127 34.3969C221.484 34.2713 221.919 34.2134 222.411 34.2134C223.145 34.2134 223.715 34.3969 224.111 34.7541C224.507 35.1114 224.719 35.6136 224.719 36.2606L224.729 40.1329H223.715V40.1425ZM223.097 38.9837C223.435 38.752 223.637 38.443 223.705 38.0567V37.4677H221.977C221.513 37.4677 221.165 37.5353 220.934 37.6801C220.712 37.825 220.596 38.0471 220.596 38.3464C220.596 38.6458 220.712 38.8872 220.943 39.0707C221.175 39.2445 221.484 39.341 221.87 39.341C222.343 39.3314 222.759 39.2155 223.097 38.9837Z"
            fill="#43556B"
          />
          <Path d="M229.74 39.7563C229.287 40.046 228.833 40.1908 228.36 40.1908C227.896 40.1908 227.51 40.0556 227.22 39.7756C226.93 39.4955 226.785 39.0803 226.785 38.5106V35.2466H225.955V34.4548H226.785V32.8711H227.828V34.4548H229.634V35.2466H227.828V38.3174C227.828 38.6457 227.886 38.8775 228.002 39.0224C228.118 39.1672 228.302 39.2348 228.533 39.2348C228.813 39.2348 229.122 39.1382 229.441 38.9548L229.74 39.7563Z" fill="#43556B" />
          <Path d="M20.2113 0C20.3851 0.270385 20.5492 0.54077 20.7231 0.801499C21.0321 1.29399 21.3604 1.78647 21.6211 2.30793C21.7853 2.65557 21.9205 3.01286 21.9591 3.39913C21.9881 3.65986 21.9784 3.92058 21.9398 4.17166L21.9688 4.162C21.9012 4.21028 21.8625 4.27788 21.8143 4.34547C21.3218 5.02144 20.8196 5.6974 20.3271 6.37336C20.2885 6.18023 20.2402 5.99676 20.1533 5.82294C20.0085 5.52358 19.8057 5.2532 19.6029 5.00212C19.3615 4.71243 19.1201 4.42273 18.8883 4.12337C19.3229 2.75213 19.7671 1.37124 20.2113 0Z" fill="#169B48" />
          <Path d="M21.9688 4.16179C22.3841 3.97831 22.8476 3.90106 23.3015 3.91071C23.8712 3.92037 24.4216 4.04591 24.9721 4.16179C25.4645 4.27766 25.9667 4.37423 26.4688 4.4708C25.2714 5.33989 24.0836 6.19933 22.8862 7.05877C22.5482 6.94289 22.2103 6.79804 21.8723 6.6725C21.3798 6.47937 20.8487 6.31521 20.3176 6.37315L20.3272 6.36349C20.8294 5.68753 21.3219 5.01157 21.8143 4.3356C21.8626 4.28732 21.9013 4.21007 21.9688 4.16179Z" fill="#11743B" />
          <Path d="M13.9538 4.35498C15.0257 4.35498 16.0879 4.35498 17.1598 4.35498C17.604 4.35498 18.0482 4.36464 18.4924 4.35498C18.6566 4.55777 18.8207 4.76056 18.9849 4.96335C19.178 5.19511 19.3808 5.43652 19.545 5.68759C19.6995 5.91935 19.825 6.17042 19.8733 6.44081C19.9023 6.59531 19.8926 6.75948 19.9023 6.92364C19.9023 6.96227 19.883 6.98158 19.8636 7.01055C19.1973 7.91827 18.531 8.82599 17.8744 9.73371C17.4012 10.3807 16.928 11.0277 16.4645 11.6747C16.4452 11.7037 16.4259 11.7326 16.3969 11.7616C16.4066 11.7037 16.4259 11.6554 16.4452 11.6071C16.928 10.1393 17.4109 8.67149 17.884 7.20368C16.8604 6.46978 15.8368 5.72622 14.8229 4.99232C14.5332 4.77022 14.2435 4.56743 13.9538 4.35498Z" fill="#215FAC" />
          <Path d="M19.912 6.92389C20.1051 6.8756 20.2886 6.82732 20.4817 6.81766C20.8197 6.79835 21.1577 6.89492 21.486 7.00114C21.9302 7.15565 22.3648 7.35844 22.8186 7.50328C23.2822 8.93246 23.7553 10.3616 24.2189 11.7908C23.9774 11.617 23.736 11.4432 23.4946 11.2694C22.4324 10.4872 21.3605 9.71465 20.2983 8.94212C18.9946 9.89812 17.6813 10.8445 16.3777 11.8005C16.3777 11.7812 16.3873 11.7619 16.3873 11.7425L16.397 11.7522C16.426 11.7329 16.4453 11.6943 16.4646 11.6653C16.9378 11.0183 17.4109 10.3713 17.8745 9.72431C18.5408 8.81658 19.1974 7.90886 19.8637 7.00114C19.8927 6.98183 19.912 6.96251 19.912 6.92389Z" fill="#184999" />
          <Path d="M12.9881 8.87431C14.1373 9.68547 14.707 10.7767 14.707 12.1672C14.707 13.1232 14.427 13.9633 13.8669 14.6779C13.3068 15.3925 12.5536 15.8754 11.5879 16.1361C12.7564 16.3582 13.6738 16.8796 14.3401 17.6908C15.0064 18.502 15.3444 19.5062 15.3444 20.6843C15.3444 22.2294 14.736 23.4461 13.5193 24.3345C12.3025 25.223 10.6512 25.6768 8.55576 25.6768H0V7.66724H8.28537C10.2746 7.66724 11.839 8.07281 12.9881 8.87431ZM9.88837 14.3882C10.3229 14.0406 10.545 13.5481 10.545 12.9108C10.545 12.2928 10.3229 11.8099 9.88837 11.4719C9.45382 11.1243 8.84546 10.9698 8.07293 10.9795H4.06543V14.9194H8.08258C8.84546 14.9194 9.45382 14.7455 9.88837 14.3882ZM10.3422 21.7755C10.8927 21.3893 11.163 20.8582 11.163 20.1629C11.163 19.5159 10.8927 18.9944 10.3422 18.6178C9.7918 18.2412 9.03859 18.0578 8.08258 18.0771H4.06543V22.3453H8.08258C9.03859 22.3549 9.7918 22.1618 10.3422 21.7755Z" fill="#184999" />
          <Path d="M18.2413 11.8293H22.2005V25.6673H18.2413V11.8293Z" fill="#184999" />
          <Path d="M38.443 13.1233C39.3604 14.0793 39.8239 15.3733 39.8239 16.9859V25.6769H35.8647V18.1351C35.8647 17.2563 35.6233 16.5707 35.1308 16.0686C34.6383 15.5664 33.9817 15.3057 33.1415 15.3057C32.1662 15.325 31.3937 15.663 30.8239 16.3196C30.2542 16.9763 29.9742 17.8261 29.9742 18.8497V25.6672H26.015V11.8293H29.9742V14.2435C30.9302 12.5632 32.5332 11.7038 34.7638 11.6748C36.2992 11.6748 37.5256 12.1576 38.443 13.1233Z" fill="#184999" />
          <Path d="M51.8077 24.9043C51.2959 25.2133 50.7262 25.4451 50.0985 25.6092C49.4708 25.7734 48.8431 25.8507 48.2058 25.8507C46.9215 25.8507 45.8689 25.4837 45.0674 24.7401C44.2659 24.0062 43.8603 22.944 43.8603 21.5728V15.1704H41.929V12.4473H43.8603V8.64258H47.8002V12.4473H51.5567V15.1994H47.8002V20.9065C47.8002 21.9687 48.2155 22.4998 49.0653 22.4998C49.6446 22.4998 50.2916 22.326 50.9966 21.988L51.8077 24.9043Z" fill="#184999" />
          <Path
            d="M62.2272 25.6769V24.0546C61.7637 24.634 61.165 25.0878 60.4311 25.3969C59.6972 25.7059 58.8474 25.8604 57.8817 25.8604C56.4236 25.8604 55.2648 25.4644 54.4054 24.6629C53.5459 23.8614 53.121 22.8282 53.121 21.5342C53.121 20.2306 53.5846 19.2166 54.5019 18.4827C55.4193 17.7585 56.7229 17.3819 58.4032 17.3625H62.2079V17.1018C62.2079 16.3679 61.9665 15.7982 61.474 15.3926C60.9815 14.987 60.2669 14.7842 59.3302 14.7842C58.0459 14.7842 56.6747 15.1898 55.2165 15.9913L53.9805 13.2681C55.0813 12.7177 56.1049 12.3121 57.0706 12.0611C58.0266 11.8003 59.0888 11.6748 60.238 11.6748C62.092 11.6748 63.5309 12.1094 64.5448 12.9881C65.5684 13.8669 66.0802 15.0836 66.0995 16.6383L66.1285 25.6672H62.2272V25.6769ZM61.1264 22.4323C61.7251 22.0364 62.0824 21.5439 62.2079 20.9355V19.6994H59.0985C58.3259 19.6994 57.7562 19.825 57.3893 20.0761C57.0223 20.3271 56.8388 20.7231 56.8388 21.2735C56.8388 21.8046 57.032 22.2295 57.4182 22.5482C57.8045 22.8668 58.3356 23.0213 59.0019 23.0213C59.8131 23.0213 60.518 22.8282 61.1264 22.4323Z"
            fill="#184999"
          />
          <Path d="M81.975 13.1233C82.8924 14.0793 83.3559 15.3733 83.3559 16.9859V25.6769H79.3967V18.1351C79.3967 17.2563 79.1553 16.5707 78.6628 16.0686C78.1703 15.5664 77.5136 15.3057 76.6735 15.3057C75.6982 15.325 74.9257 15.663 74.3559 16.3196C73.7862 16.9763 73.5061 17.8261 73.5061 18.8497V25.6672H69.5469V11.8293H73.5061V14.2435C74.4622 12.5632 76.0651 11.7038 78.2958 11.6748C79.8216 11.6748 81.0576 12.1576 81.975 13.1233Z" fill="#184999" />
          <Path
            d="M99.7335 11.8294V24.383C99.7335 25.6867 99.4245 26.8261 98.8064 27.7918C98.1884 28.7575 97.3193 29.5107 96.2088 30.0321C95.0983 30.5536 93.8043 30.8143 92.3462 30.8143C91.2163 30.8143 90.1348 30.6502 89.1209 30.3218C88.0973 29.9935 87.1702 29.5493 86.3301 28.9796L87.7979 26.1985C89.1015 27.1255 90.5307 27.589 92.0951 27.589C93.2249 27.589 94.123 27.309 94.7989 26.7489C95.4652 26.1888 95.8032 25.4452 95.8032 24.4989V22.9249C95.3397 23.5815 94.7603 24.074 94.0554 24.4216C93.3504 24.7693 92.5489 24.9334 91.6412 24.9334C90.4535 24.9334 89.4106 24.6534 88.4932 24.0933C87.5758 23.5332 86.8612 22.751 86.3591 21.7371C85.8569 20.7232 85.5962 19.5547 85.5962 18.2414C85.5962 16.9378 85.8473 15.7983 86.3591 14.8037C86.8612 13.8187 87.5758 13.0462 88.4932 12.5054C89.4106 11.955 90.4631 11.6846 91.6412 11.6846C92.5296 11.6846 93.3311 11.8584 94.0361 12.2157C94.741 12.5633 95.3301 13.0558 95.8129 13.6931V11.8198H99.7335V11.8294ZM94.8955 20.9549C95.4942 20.2983 95.7936 19.4292 95.7936 18.3669C95.7936 17.3047 95.4942 16.4356 94.8955 15.7693C94.2968 15.103 93.5146 14.765 92.5586 14.765C91.6026 14.765 90.8204 15.103 90.2217 15.7693C89.623 16.4356 89.314 17.3047 89.2947 18.3669C89.314 19.4292 89.6133 20.2886 90.2121 20.9549C90.8011 21.6116 91.5929 21.9495 92.5683 21.9495C93.5146 21.9399 94.2968 21.6116 94.8955 20.9549Z"
            fill="#184999"
          />
          <Path d="M116.41 9.2992C117.666 10.3904 118.284 11.9258 118.284 13.9151C118.284 16.0105 117.656 17.6329 116.391 18.7917C115.126 19.9504 113.359 20.5298 111.08 20.5298H107.507V25.6768H103.442V7.66724H111.08C113.378 7.66724 115.155 8.20801 116.41 9.2992ZM113.513 16.3485C114.122 15.8271 114.431 15.0545 114.431 14.0503C114.431 13.0556 114.122 12.3024 113.513 11.8003C112.905 11.2981 112.026 11.0374 110.877 11.0374H107.507V17.1307H110.877C112.026 17.1307 112.905 16.87 113.513 16.3485Z" fill="#184999" />
          <Path d="M133.947 11.8292V25.6672H130.007V23.224C129.061 24.9526 127.516 25.8217 125.372 25.8217C123.846 25.8217 122.629 25.3485 121.731 24.3925C120.833 23.4365 120.379 22.1522 120.379 20.5202V11.8292H124.339V19.3904C124.339 20.2691 124.57 20.9547 125.034 21.4472C125.497 21.9494 126.135 22.1908 126.936 22.1908C127.883 22.1715 128.626 21.8431 129.177 21.1865C129.727 20.5395 129.997 19.6897 129.997 18.6661V11.8196H133.947V11.8292Z" fill="#184999" />
          <Path
            d="M144.27 14.9099C143.536 14.6781 142.87 14.5622 142.29 14.5622C141.807 14.5622 141.421 14.6492 141.131 14.823C140.842 14.9968 140.697 15.2479 140.697 15.5955C140.697 15.9721 140.89 16.2618 141.286 16.4743C141.682 16.677 142.319 16.9088 143.217 17.1695C144.192 17.4592 145.004 17.7489 145.631 18.029C146.269 18.309 146.819 18.7339 147.273 19.294C147.736 19.8541 147.968 20.588 147.968 21.4957C147.968 22.8669 147.437 23.9388 146.385 24.7017C145.332 25.4646 143.98 25.8508 142.329 25.8508C141.199 25.8508 140.108 25.6867 139.065 25.3487C138.022 25.0107 137.114 24.5375 136.342 23.9195L137.626 21.2446C138.331 21.795 139.113 22.2199 139.982 22.5193C140.851 22.8186 141.662 22.9731 142.435 22.9731C142.966 22.9731 143.391 22.8862 143.71 22.7028C144.028 22.5193 144.183 22.2489 144.183 21.8916C144.183 21.4957 143.98 21.1867 143.574 20.9646C143.169 20.7425 142.522 20.5011 141.633 20.2403C140.687 19.9699 139.905 19.6899 139.277 19.4195C138.65 19.1491 138.118 18.7339 137.674 18.1738C137.23 17.6137 137.008 16.8895 137.008 16.0011C137.008 14.6298 137.52 13.5676 138.553 12.8144C139.586 12.0612 140.89 11.6846 142.464 11.6846C143.41 11.6846 144.347 11.8198 145.293 12.0805C146.24 12.3509 147.089 12.7275 147.862 13.2296L146.5 15.9335C145.747 15.47 145.004 15.1416 144.27 14.9099Z"
            fill="#184999"
          />
          <Path d="M163.062 13.1233C163.979 14.0793 164.442 15.3733 164.442 16.9859V25.6769H160.483V18.1351C160.483 17.2563 160.242 16.5707 159.749 16.0686C159.257 15.5664 158.6 15.3057 157.76 15.3057C156.785 15.325 156.012 15.663 155.442 16.3196C154.873 16.9763 154.593 17.8261 154.593 18.8497V25.6672H150.634V11.8293H154.593V14.2435C155.549 12.5632 157.152 11.7038 159.382 11.6748C160.908 11.6748 162.144 12.1576 163.062 13.1233Z" fill="#184999" />
          <Path
            d="M175.886 25.6769V24.0546C175.422 24.634 174.823 25.0878 174.089 25.3969C173.356 25.7059 172.506 25.8604 171.54 25.8604C170.082 25.8604 168.923 25.4644 168.064 24.6629C167.204 23.8614 166.779 22.8282 166.779 21.5342C166.779 20.2306 167.243 19.2166 168.16 18.4827C169.078 17.7585 170.381 17.3819 172.062 17.3625H175.866V17.1018C175.866 16.3679 175.625 15.7982 175.132 15.3926C174.64 14.987 173.925 14.7842 172.989 14.7842C171.704 14.7842 170.333 15.1898 168.875 15.9913L167.639 13.2681C168.74 12.7177 169.763 12.3121 170.729 12.0611C171.685 11.8003 172.747 11.6748 173.896 11.6748C175.75 11.6748 177.189 12.1094 178.203 12.9881C179.227 13.8669 179.739 15.0836 179.758 16.6383L179.787 25.6672H175.886V25.6769ZM174.775 22.4323C175.374 22.0364 175.731 21.5439 175.857 20.9355V19.6994H172.747C171.975 19.6994 171.405 19.825 171.038 20.0761C170.671 20.3271 170.487 20.7231 170.487 21.2735C170.487 21.8046 170.681 22.2295 171.067 22.5482C171.453 22.8668 171.984 23.0213 172.651 23.0213C173.462 23.0213 174.176 22.8282 174.775 22.4323Z"
            fill="#184999"
          />
          <Path
            d="M189.675 14.9099C188.941 14.6781 188.275 14.5622 187.696 14.5622C187.213 14.5622 186.826 14.6492 186.537 14.823C186.247 14.9968 186.102 15.2479 186.102 15.5955C186.102 15.9721 186.295 16.2618 186.691 16.4743C187.087 16.677 187.725 16.9088 188.623 17.1695C189.598 17.4592 190.409 17.7489 191.037 18.029C191.674 18.309 192.225 18.7339 192.678 19.294C193.142 19.8541 193.374 20.588 193.374 21.4957C193.374 22.8669 192.843 23.9388 191.79 24.7017C190.737 25.4646 189.385 25.8508 187.734 25.8508C186.604 25.8508 185.513 25.6867 184.47 25.3487C183.427 25.0107 182.52 24.5375 181.747 23.9195L183.031 21.2446C183.736 21.795 184.519 22.2199 185.388 22.5193C186.257 22.8186 187.068 22.9731 187.84 22.9731C188.372 22.9731 188.796 22.8862 189.115 22.7028C189.434 22.5193 189.588 22.2489 189.588 21.8916C189.588 21.4957 189.385 21.1867 188.98 20.9646C188.574 20.7425 187.927 20.5011 187.039 20.2403C186.093 19.9699 185.31 19.6899 184.683 19.4195C184.055 19.1491 183.524 18.7339 183.08 18.1738C182.636 17.6137 182.413 16.8895 182.413 16.0011C182.413 14.6298 182.925 13.5676 183.958 12.8144C184.992 12.0612 186.295 11.6846 187.869 11.6846C188.816 11.6846 189.752 11.8198 190.699 12.0805C191.645 12.3509 192.495 12.7275 193.267 13.2296L191.906 15.9335C191.153 15.47 190.409 15.1416 189.675 14.9099Z"
            fill="#184999"
          />
          <Path d="M202.123 7.66724H215.835V11.0374H206.188V14.9483H214.86V18.3185H206.188V22.3067H216.115V25.6768H202.123V7.66724Z" fill="#184999" />
          <Path d="M232.888 6.58569V25.6768H228.949V23.6489C228.446 24.3732 227.838 24.9139 227.095 25.2809C226.361 25.6478 225.521 25.8313 224.574 25.8313C223.271 25.8313 222.121 25.532 221.136 24.9429C220.151 24.3539 219.389 23.5137 218.848 22.4322C218.307 21.3507 218.037 20.1049 218.037 18.7047C218.037 17.3142 218.307 16.0878 218.838 15.0256C219.369 13.9633 220.122 13.1425 221.098 12.5535C222.073 11.9741 223.203 11.6747 224.468 11.6747C226.419 11.6747 227.915 12.4086 228.949 13.8571V6.58569H232.888ZM227.993 21.5728C228.64 20.8582 228.958 19.9408 228.958 18.811C228.958 17.6811 228.64 16.7541 227.993 16.0492C227.346 15.3346 226.525 14.9773 225.511 14.9773C224.478 14.9773 223.647 15.3346 223 16.0492C222.353 16.7638 222.025 17.6811 222.005 18.811C222.025 19.9408 222.353 20.8678 223 21.5728C223.647 22.2873 224.478 22.6446 225.511 22.6446C226.515 22.635 227.346 22.2777 227.993 21.5728Z" fill="#184999" />
          <Path d="M250 11.8292V25.6672H246.06V23.224C245.114 24.9526 243.569 25.8217 241.425 25.8217C239.899 25.8217 238.682 25.3485 237.784 24.3925C236.886 23.4365 236.432 22.1522 236.432 20.5202V11.8292H240.392V19.3904C240.392 20.2691 240.623 20.9547 241.087 21.4472C241.55 21.9494 242.188 22.1908 242.989 22.1908C243.936 22.1715 244.679 21.8431 245.23 21.1865C245.78 20.5395 246.05 19.6897 246.05 18.6661V11.8196H250V11.8292Z" fill="#184999" />
        </G>
        <Defs>
          <ClipPath id="clip0_731_1156">
            <Rect width={250} height={42.3539} fill="white" />
          </ClipPath>
        </Defs>
      </Svg>
        )
    }
}


export default LogoPusnasNew;
