import React,  { Component } from 'react'; 
import { View, StyleSheet, Dimensions } from 'react-native';
import LoadingDialog from '../components/LoadingDialog';
import { executeGet, executePost } from '../utilities/Utilities';
import { code_ok, code_error, code_empty, code_auth_error , getContent, closeBook } from '../constants/api';
import { Button , Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'; 
import { primary } from '../constants/colors';
import HeaderLogo from '../assets/HeaderLogo';

var _is_mounted = false;
class ReaderDesktop extends Component { 
    constructor(props){
        super(props)
        this.state = {
            showLoadingDialog : false, 
            loadingDialogMessage : "Harap Tunggu",
            id_baca : "", 
            id_baca_mitra : "", 
            token_baca : "",
            blobUrl : "",
            id_konten : "",
            kode_konten : "",
            lastPage : "1",
            mode : "-",
            showCitationDialog : false,
            citationData : {},
            voices : [],
            currentVoice : {},
            last_page_index : 0,
            keyword : ""
        }

        //this.fetchContent = this.fetchContent.bind(this); 
        this.onLoadIframe = this.onLoadIframe.bind(this);
        this.iframeRef = React.createRef(); 
        this.executeCloseBook = this.executeCloseBook.bind(this); 

        this.updateVoices = this.updateVoices.bind(this); 
        this.speakUp = this.speakUp.bind(this); 

        this.synth = React.createRef(); 
        
        
    }

    updateVoices = ()=>{
        this.setState({
            voices : this.synth.current.getVoices()
        },()=>{
            
        })
    }

    componentDidMount = ()=>{
        console.log("READER DESKTOP DID MOUNT");
        _is_mounted = true; 
        this.synth.current = window.speechSynthesis;
        this.synth.current.onvoiceschanged = this.updateVoices;
        console.log("PARAMS...");
        console.log(this.props.route.params);
        this.setState({
            id_baca : this.props.route.params.id_baca, 
            id_baca_mitra : this.props.route.params.id_baca_mitra,
            token_baca : this.props.route.params.token ,
            id_konten : this.props.route.params.id_konten,
            kode_konten : this.props.route.params.kode_konten,
            mode : this.props.route.params.mode,
            voices : this.synth.current.getVoices(),
            currentVoice : this.synth.current.getVoices()[6]
        }, ()=>{
            //this.fetchContent();
        })

        window.addEventListener("message",(event)=>{
            if(event.data.hasOwnProperty("command")){
                if(event.data.command == "close") {
                    //console.log("CLOSE BOOK")
                    if(this.synth.current != null){
                        
                        this.synth.current.cancel();
                    }
                    let page = event.data.page; 
                    this.setState({
                        lastPage : page
                    }, ()=>{
                        //if(this.state.mode == "read"){
                            this.executeCloseBook();
                        //} else {
                        //    this.props.navigation.goBack();
                        //}
                    })
                } else if(event.data.command == "cite") {
                    let data = event.data.data;
                    this.setState({
                        citationData : data,
                        showCitationDialog : true
                    })
                } else if(event.data.command == "log") {
                    console.log(event.data.message); 
                } else if(event.data.command == "add_highlight"){
                    this.setState({
                        showHighlightDialog : true
                    })
                } else if(event.data.command == "speak"){
                    console.log("SPEAK UP! " + event.data.message);
                    this.speakUp(event.data.message);
                } else if(event.data.command == "stop_speak") {
                    try {
                        if(this.synth.current.speaking){
                            this.synth.current.cancel();
                        }
                    } catch(error){
                        console.error(error.message);
                    }
                } else if(event.data.command == "close_dialog"){
                    this.setState({
                        showLoadingDialog : false
                    })
                }
            }
        })
    }
    speakUp = (text)=>{
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.voice = this.state.voices[6]; 
        utterance.pitch = 1;
        utterance.rate = 1;
        if(this.synth.current.speaking){
            this.synth.current.cancel(); 
        }
        this.synth.current.speak(utterance);
    }

    componentWillUnmount = ()=>{
        _is_mounted = false;
        window.removeEventListener("message", null);
        try {
            //console.log("READER WILL UNMOUNT"); 
            if(this.synth.current != null) {  
                this.synth.current.cancel();
            }
        } catch(err){
            console.error(error.message); 
        }
        this.synth.current.onvoiceschanged = null; 
    }

    
    //ANCHOR - ONLOAD IFRAME
    //Callback dari iFrame ketika halaman berhasil di LOAD
    onLoadIframe = ()=>{
        this.setState({
            showLoadingDialog : false, 
        }, ()=>{
            setTimeout(()=>{
                
                let reading_params = {
                    headers : global.defaultHeaders, 
                    token : this.props.route.params.token,
                    id_baca : this.props.route.params.id_baca, 
                    id_baca_mitra : this.props.route.params.id_baca_mitra,
                    id_konten : this.props.route.params.id_konten,
                    kode_konten : this.props.route.params.kode_konten,
                    mode : this.props.route.params.mode,
                    last_page_index : this.props.route.params.last_page_index,
                    local_content : this.props.route.params.local,
                    keyword : (this.props.route.params.hasOwnProperty("keyword") ? this.props.route.params.keyword : ""),
                    directIndex : (this.props.route.params.hasOwnProperty("directIndex") ? this.props.route.params.directIndex : 0)
                };
                console.log("READING PARAMS");
                console.log("READING PARAMS = " + JSON.stringify(reading_params)); 
                
                document.getElementById('readerFrame').contentWindow.postMessage({
                    headers : global.defaultHeaders, 
                    token : this.props.route.params.token,
                    id_baca : this.props.route.params.id_baca, 
                    id_baca_mitra : this.props.route.params.id_baca_mitra,
                    id_konten : this.props.route.params.id_konten,
                    kode_konten : this.props.route.params.kode_konten,
                    mode : this.props.route.params.mode,
                    last_page_index : this.props.route.params.last_page_index,
                    local_content : this.props.route.params.local,
                    keyword : (this.props.route.params.hasOwnProperty("keyword") ? this.props.route.params.keyword : ""),
                    directIndex : (this.props.route.params.hasOwnProperty("directIndex") ? this.props.route.params.directIndex : 0)
                },"*"); 
            }, 2000);
            
        })
    }

    executeCloseBook = async()=>{
        try {
            this.synth.current.cancel();
            
        } catch(err){
            console.log(err.message);
        }
        console.log("ID BACA: " + this.state.id_baca); 
        console.log("ID BACA MITRA: " + this.state.id_baca_mitra); 
        console.log("LAST PAGE: " + this.state.lastPage); 

        let formData = new FormData(); 
        formData.append("id_baca", this.state.id_baca); 
        formData.append("id_baca_mitra", this.state.id_baca_mitra); 
        formData.append("last_page", this.state.lastPage); 
        this.setState({
            showLoadingDialog : true,
            loadingDialogMessage : "Mengembalikan Buku"
        })
        const response = await executePost(closeBook, formData); 
        console.log("KEMBALI");
        
        if(response.code == code_ok){ 
            this.setState({
                showLoadingDialog : false
            }, ()=>{
                this.props.navigation.goBack();
            })
        } else {
            this.setState({
                showLoadingDialog : false
            }, ()=>{
                this.props.navigation.goBack();
            })
        }
    } 

    render(){
        let viewWidth = Dimensions.get("window").width; 
        let viewHeight = Dimensions.get("window").height; 
        return(
            <View style={ styles.container }>
                <LoadingDialog
                    open={ this.state.showLoadingDialog }
                    message={ this.state.loadingDialogMessage }
                />
                {
                    !this.state.showLoadingDialog && 
                    <iframe 
                        id="readerFrame"
                        src={ "https://bintangpusnas.perpusnas.go.id/web/reader/?platform=desktop" } 
                        //src={ "https://reader.bintangpusnas.com:3000/?platform=desktop" } 
                        //src={"http://localhost:3000/?platform=desktop"}
                        width={viewWidth } 
                        height="100%" 
                        frameBorder={0} onLoad={ this.onLoadIframe }></iframe>
                }
                <Dialog
                    open={ this.state.showCitationDialog } 
                    onClose={()=>{ this.setState({ showCitationDialog : false })} }  
                    style={{ width : "100%"}}  
                >
                    <DialogTitle>
                       Pilih Style Sitasi
                    </DialogTitle>
                    <DialogContent> 
                        <View style={{flex : 1, flexDirection : "column", alignItems : "center"}}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 14 } 
                                fontWeight="600"

                            >
                                MLA : 
                                <div 
                                    style={{ overflow : "hidden", color : primary }}
                                    dangerouslySetInnerHTML={{ __html: this.state.citationData.mla }} 
                                />
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 14 } 
                                fontWeight="600"
                            >
                                Chicago : 
                                <div 
                                    style={{ overflow : "hidden", color : primary }}
                                    dangerouslySetInnerHTML={{ __html: this.state.citationData.chg }} 
                                />
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 14 } 
                                fontWeight="600"
                            >
                                Turabian : 
                                <div 
                                    style={{ overflow : "hidden", color : primary }}
                                    dangerouslySetInnerHTML={{ __html: this.state.citationData.trb }} 
                                />
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 14 } 
                                fontWeight="600"
                            >
                                APA : 
                                <div 
                                    style={{ overflow : "hidden", color : primary }}
                                    dangerouslySetInnerHTML={{ __html: this.state.citationData.apa }} 
                                />
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 14 } 
                                fontWeight="600"
                            >
                                Harvard : 
                                <div 
                                    style={{ overflow : "hidden", color : primary }}
                                    dangerouslySetInnerHTML={{ __html: this.state.citationData.hrv }} 
                                />
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 14 } 
                                fontWeight="600"
                            >
                                IEEE : 
                                <div 
                                    style={{ overflow : "hidden", color : primary }}
                                    dangerouslySetInnerHTML={{ __html: this.state.citationData.ieee }} 
                                />
                            </Typography>
                        </View>

                    </DialogContent>


                </Dialog>
            </View>
        )
    }
}


const styles = StyleSheet.create({
    container : {
        flex : 1
    }
})

export default ReaderDesktop; 