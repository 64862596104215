import * as React from "react"
import Svg, { G, Path, Defs, LinearGradient, Stop } from "react-native-svg"

class EmptySearchPlaceholder extends React.Component {
    constructor(props){
        super(props)
    }

    render(){
        return(
            <Svg
                width={213}
                height={172}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                {...this.props}
            >
                <Path
                opacity={0.15}
                d="M153.075.483s57.928 6.552 36.019 54.873c-16.19 35.74 2.526 69.096 13.368 84.136 5.496 7.52 3.194 18.912-4.604 23.007-8.689 4.467-23.84 8.339-50.427 8.413-65.651.298-97.66 3.127-121.797-8.786-24.136-11.913-38.024-50.704-8.318-75.35 28.816-23.9 31.415-19.581 47.382-52.788C84.082-6.442 153.075.483 153.075.483Z"
                fill="url(#a)"
                />
                <Path
                d="M210.112 77.544c-1.634-1.787-4.085-2.382-6.461-1.787-3.565.968-9.284 4.17-10.323 14.52-1.56 15.412 13.293 36.483 13.293 36.483a3.08 3.08 0 0 0 4.011.67c1.114-.745 1.708-2.085 1.337-3.425-.149-.596-.297-1.191-.372-1.787-.371-3.276-.52-7.892-.742-13.402-.297-7.52.371-15.264.891-17.497.891-4.393 1.485-10.35-1.634-13.775Z"
                fill="#F7F7F7"
                />
                <Path
                d="M119.433 67.79c1.262-1.042 2.822-1.712 4.381-2.084 6.09-1.564 23.84-6.776 30.672-16.455 8.318-11.838 15.151-25.39 29.633-19.284 14.482 6.105 4.975 24.943 4.975 24.943s-9.431 20.922-43.594 31.718c0 0-25.399 6.85-30.895 2.68-5.124-3.871-3.936-14.519 4.828-21.517Z"
                fill="url(#b)"
                />
                <Path
                d="M177.806 45.23c-.446.522-19.532 35.07-47.605 39.537"
                stroke="url(#c)"
                strokeWidth={0.653}
                strokeMiterlimit={10}
                />
                <Path
                d="M168.597 78.587c-2.377-.298-5.273-.298-8.615.223-10.472 1.638-24.359 13.328-24.359 13.328s-3.788 6.626 6.312 3.5c10.1-3.128 23.543 2.01 23.543 2.01s15.67 6.328 16.413-3.351c0 0 1.559-13.923-13.294-15.71Z"
                fill="url(#d)"
                />
                <Path
                d="M138.816 92.957s15.967-10.87 32.083-4.468"
                stroke="url(#e)"
                strokeWidth={0.653}
                strokeMiterlimit={10}
                />
                <Path
                d="M91.063 122.218c-1.04-.819-2.303-1.415-3.64-1.787-5.05-1.266-19.68-5.584-25.324-13.625-6.833-9.754-12.551-20.997-24.508-15.934-11.957 5.063-4.085 20.624-4.085 20.624s7.798 17.274 36.02 26.283c0 0 21.017 5.659 25.547 2.234 4.159-3.202 3.194-12.062-4.01-17.795Z"
                fill="url(#f)"
                />
                <Path
                d="M42.715 103.53c.372.446 16.19 29.037 39.361 32.686"
                stroke="url(#g)"
                strokeWidth={0.54}
                strokeMiterlimit={10}
                />
                <Path
                d="M50.365 131.153c2.005-.224 4.381-.298 7.13.149 8.614 1.34 20.125 11.019 20.125 11.019s3.12 5.51-5.198 2.904c-8.318-2.606-19.458 1.638-19.458 1.638s-12.997 5.212-13.59-2.755c0 0-1.338-11.466 10.99-12.955Z"
                fill="url(#h)"
                />
                <Path
                d="M74.947 142.991s-13.22-9.009-26.513-3.723"
                stroke="url(#i)"
                strokeWidth={0.54}
                strokeMiterlimit={10}
                />
                <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M138.816 139.566H81.26c-4.085 0-7.353-3.35-7.353-7.445V49.847c0-4.095 3.342-7.446 7.353-7.446h57.556c4.085 0 7.352 3.35 7.352 7.446v82.274c0 4.095-3.267 7.445-7.352 7.445Z"
                fill="#E0E2EE"
                />
                <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M133.915 143.066H76.358c-4.085 0-7.352-3.351-7.352-7.446v-82.2c0-4.094 3.342-7.445 7.352-7.445h57.557c4.084 0 7.352 3.35 7.352 7.446v82.273c0 4.096-3.342 7.372-7.352 7.372Z"
                fill="#E8EBF2"
                />
                <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M129.087 145.895H71.382c-4.084 0-7.352-3.351-7.352-7.371V56.548c0-4.095 3.342-7.371 7.352-7.371h57.705c4.085 0 7.353 3.35 7.353 7.37v81.977c.074 4.02-3.268 7.371-7.353 7.371Z"
                fill="#D8DBEA"
                />
                <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M129.087 145.895H71.382c-4.084 0-7.352-3.351-7.352-7.371V56.548c0-4.095 3.342-7.371 7.352-7.371h57.705c4.085 0 7.353 3.35 7.353 7.37v81.977c.074 4.02-3.268 7.371-7.353 7.371Z"
                fill="#F1F2F7"
                />
                <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M120.844 74.12h-42.63c-1.188 0-2.227-.969-2.227-2.085 0-1.192.965-2.085 2.228-2.085h42.629c1.188 0 2.228.968 2.228 2.085-.075 1.117-1.04 2.084-2.228 2.084ZM120.844 86.777h-42.63c-1.188 0-2.227-.968-2.227-2.085 0-1.191.965-2.085 2.228-2.085h42.629c1.188 0 2.228.968 2.228 2.085a2.21 2.21 0 0 1-2.228 2.085ZM120.844 99.434h-42.63c-1.188 0-2.227-.968-2.227-2.084 0-1.192.965-2.085 2.228-2.085h42.629c1.188 0 2.228.968 2.228 2.085a2.21 2.21 0 0 1-2.228 2.084ZM120.844 112.166h-42.63c-1.188 0-2.227-.968-2.227-2.084 0-1.192.965-2.085 2.228-2.085h42.629c1.188 0 2.228.968 2.228 2.085-.075 1.116-1.04 2.084-2.228 2.084ZM100.42 124.824H78.215c-1.189 0-2.228-.968-2.228-2.085 0-1.191.965-2.085 2.228-2.085h22.205c1.189 0 2.228.968 2.228 2.085s-.965 2.085-2.228 2.085Z"
                fill="#E0E2EE"
                />
                <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M145.277 108.444a24.446 24.446 0 0 1-31.266 2.903c-1.188-.893-2.376-1.787-3.49-2.903-.594-.596-1.114-1.117-1.56-1.713a27.023 27.023 0 0 1-2.599-3.872c-1.263-2.233-2.08-4.616-2.6-7.147-1.485-7.744.743-16.083 6.759-22.114 6.015-6.03 14.333-8.264 22.057-6.775 2.451.447 4.827 1.34 7.129 2.606 1.337.744 2.6 1.638 3.788 2.606.594.52 1.188 1.042 1.708 1.563 1.114 1.117 2.079 2.234 2.896 3.5a24.501 24.501 0 0 1-2.822 31.346Z"
                fill="#fff"
                fillOpacity={0.1}
                />
                <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M142.901 106.731c-8.838 8.86-23.246 8.86-32.083 0-8.838-8.86-8.838-23.305 0-32.165 8.837-8.86 23.245-8.86 32.083 0 8.912 8.935 8.912 23.305 0 32.165Zm4.604-36.707c-11.362-11.391-29.781-11.391-41.218 0-11.362 11.392-11.362 29.932 0 41.323 10.323 10.35 26.588 11.318 38.025 2.83 1.114-.819 2.154-1.787 3.193-2.83a27.241 27.241 0 0 0 2.748-3.201c8.541-11.466 7.575-27.772-2.748-38.122Z"
                fill="#E0E2EE"
                />
                <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m192.214 154.308-.446.447a8.098 8.098 0 0 1-11.511 0l-29.187-29.261 11.957-11.987 29.187 29.261c3.193 3.201 3.193 8.413 0 11.54Z"
                fill="url(#j)"
                />
                <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m150.922 107.178 8.614 8.637-6.089 6.105-8.615-8.637a28.634 28.634 0 0 0 3.267-2.829 30.942 30.942 0 0 0 2.823-3.276Z"
                fill="#E0E2EE"
                />
                <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m191.842 142.47-11.882 11.913-2.154-2.159 11.883-11.913 2.153 2.159Z"
                fill="#EF545F"
                />
                <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m163.77 113.953-12.254 12.286-1.783-1.787 12.254-12.286 1.783 1.787Z"
                fill="url(#k)"
                />
                <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m137.034 71.513-30.004 30.081c-1.114-2.01-1.931-4.17-2.302-6.403l25.919-25.986c2.228.447 4.382 1.192 6.387 2.308ZM146.911 78.661l-32.454 30.676a18.558 18.558 0 0 1-3.268-2.606c-.52-.521-1.04-1.042-1.485-1.563l32.825-31.049c.595.447 1.114.894 1.634 1.415.966.968 1.931 2.01 2.748 3.127Z"
                fill="#fff"
                fillOpacity={0.5}
                />
                <Defs>
                <LinearGradient
                    id="a"
                    x1={68.072}
                    y1={20.835}
                    x2={150.082}
                    y2={186.15}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset={0.149} stopColor="#F2EDE7" />
                    <Stop offset={0.997} stopColor="#DCD7D2" />
                </LinearGradient>
                <LinearGradient
                    id="b"
                    x1={162.306}
                    y1={23.861}
                    x2={142.558}
                    y2={97.172}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset={0.235} stopColor="#C3DECB" />
                    <Stop offset={0.516} stopColor="#9DC9B2" />
                </LinearGradient>
                <LinearGradient
                    id="c"
                    x1={151.286}
                    y1={60.265}
                    x2={158.205}
                    y2={72.219}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset={0.295} stopColor="#fff" />
                    <Stop offset={0.33} stopColor="#F7FAF8" />
                    <Stop offset={0.457} stopColor="#DCECE2" />
                    <Stop offset={0.587} stopColor="#C9E2D2" />
                    <Stop offset={0.72} stopColor="#BEDCC8" />
                    <Stop offset={0.862} stopColor="#BADAC5" />
                </LinearGradient>
                <LinearGradient
                    id="d"
                    x1={163.093}
                    y1={76.874}
                    x2={156.4}
                    y2={101.718}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset={0.235} stopColor="#C3DECB" />
                    <Stop offset={0.516} stopColor="#9DC9B2" />
                </LinearGradient>
                <LinearGradient
                    id="e"
                    x1={159.921}
                    y1={81.738}
                    x2={149.712}
                    y2={99.375}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset={0.295} stopColor="#fff" />
                    <Stop offset={0.33} stopColor="#F7FAF8" />
                    <Stop offset={0.457} stopColor="#DCECE2" />
                    <Stop offset={0.587} stopColor="#C9E2D2" />
                    <Stop offset={0.72} stopColor="#BEDCC8" />
                    <Stop offset={0.862} stopColor="#BADAC5" />
                </LinearGradient>
                <LinearGradient
                    id="f"
                    x1={55.571}
                    y1={85.871}
                    x2={71.904}
                    y2={146.505}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset={0.235} stopColor="#C3DECB" />
                    <Stop offset={0.516} stopColor="#9DC9B2" />
                </LinearGradient>
                <LinearGradient
                    id="g"
                    x1={64.686}
                    y1={115.979}
                    x2={58.963}
                    y2={125.867}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset={0.295} stopColor="#fff" />
                    <Stop offset={0.33} stopColor="#F7FAF8" />
                    <Stop offset={0.457} stopColor="#DCECE2" />
                    <Stop offset={0.587} stopColor="#C9E2D2" />
                    <Stop offset={0.72} stopColor="#BEDCC8" />
                    <Stop offset={0.862} stopColor="#BADAC5" />
                </LinearGradient>
                <LinearGradient
                    id="h"
                    x1={54.92}
                    y1={129.716}
                    x2={60.456}
                    y2={150.265}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset={0.235} stopColor="#C3DECB" />
                    <Stop offset={0.516} stopColor="#9DC9B2" />
                </LinearGradient>
                <LinearGradient
                    id="i"
                    x1={57.544}
                    y1={133.739}
                    x2={65.987}
                    y2={148.326}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop offset={0.295} stopColor="#fff" />
                    <Stop offset={0.33} stopColor="#F7FAF8" />
                    <Stop offset={0.457} stopColor="#DCECE2" />
                    <Stop offset={0.587} stopColor="#C9E2D2" />
                    <Stop offset={0.72} stopColor="#BEDCC8" />
                    <Stop offset={0.862} stopColor="#BADAC5" />
                </LinearGradient>
                <LinearGradient
                    id="j"
                    x1={196.306}
                    y1={115.236}
                    x2={152.675}
                    y2={158.756}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#FF8960" />
                    <Stop offset={1} stopColor="#FF62A5" />
                </LinearGradient>
                <LinearGradient
                    id="k"
                    x1={163.732}
                    y1={112.136}
                    x2={149.658}
                    y2={126.175}
                    gradientUnits="userSpaceOnUse"
                >
                    <Stop stopColor="#FF404E" />
                    <Stop offset={1} stopColor="#FF62A5" />
                </LinearGradient>
                </Defs>
            </Svg>
        )
    }
}


class EmptyNewsPlaceholder extends React.Component {
    constructor(props){
        super(props)
    }

    render(){
        return(
            <Svg
            width={218}
            height={187}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...this.props}
        >
            <G opacity={0.2}>
            <Path
                d="M108.696 1.76c8.807 2.604 36.915 13.097 51.391 45.647 2.451 5.438 6.28 10.033 11.105 13.48 18.382 13.096 65.637 58.59 37.299 86.928-1.838 1.838-7.506 5.438-15.471 7.276-44.115 9.957-164.896 1.608-177.763-.536-4.902-.843-9.114-4.136-10.952-8.808-1.915-4.902-3.523-11.565-1.608-17.692 3.676-11.795 12.637-18.611 23.283-39.06 10.645-20.45-10.646-50.932 26.193-76.742 0 0 17.845-14.476 51.621-11.412 1.608.076 3.293.383 4.902.919Z"
                fill="url(#a)"
            />
            <Path
                d="M18.09 61.806a1.93 1.93 0 0 1 1.992.46c.92.918 1.991 3.063 1.379 8.424-.996 8.195-2.91 26.04-13.633 30.865-1.302.613-2.757.536-4.06-.153-1.99-1.072-4.441-3.6-3.293-9.267.46-2.528 1.532-4.902 2.987-6.97 1.992-2.834 5.821-8.578 8.578-14.245 3.37-6.817 4.52-8.655 6.05-9.114Z"
                fill="url(#b)"
            />
            </G>
            <Path
            d="M66.283 119.524c-1.025-.478-2.193-.728-3.38-.777-4.453-.07-17.483-.814-23.747-6.401-7.586-6.778-14.532-15.011-23.514-8.477-8.982 6.534.597 17.937.597 17.937s9.85 12.842 35.115 14.842c0 0 18.612.616 21.723-3.112 2.845-3.47.321-10.647-6.794-14.012Z"
            fill="url(#c)"
            />
            <Path
            d="M22.368 113.393c.396.299 19.125 20.988 39.142 19.514"
            stroke="url(#d)"
            strokeWidth={0.535}
            strokeMiterlimit={10}
            />
            <Path
            d="M34.1 134.866c1.629-.575 3.595-1.099 5.971-1.263 7.44-.561 18.91 5.246 18.91 5.246s3.668 3.973-3.77 3.425c-7.436-.549-15.896 5.145-15.896 5.145s-9.82 6.86-11.86.354c0 0-3.339-9.271 6.646-12.907Z"
            fill="url(#e)"
            />
            <Path
            d="M56.883 139.926s-12.764-4.918-22.817 2.062"
            stroke="url(#f)"
            strokeWidth={0.535}
            strokeMiterlimit={10}
            />
            <Path
            d="M155.767 77.538c1.08-.89 2.415-1.462 3.75-1.78 5.211-1.334 20.4-5.783 26.247-14.045 7.118-10.105 12.964-21.671 25.357-16.46 12.393 5.211 4.258 21.29 4.258 21.29s-8.071 17.858-37.305 27.073c0 0-21.735 5.847-26.438 2.288-4.385-3.304-3.368-12.392 4.131-18.366Z"
            fill="url(#g)"
            />
            <Path
            d="M205.719 58.281c-.381.445-16.714 29.933-40.737 33.747"
            stroke="url(#h)"
            strokeWidth={0.647}
            strokeMiterlimit={10}
            />
            <Path
            d="M197.839 86.753c-2.034-.254-4.513-.254-7.372.19-8.961 1.398-20.846 11.376-20.846 11.376s-3.241 5.656 5.402 2.987c8.643-2.67 20.146 1.716 20.146 1.716s13.41 5.402 14.045-2.86c0 0 1.335-11.884-11.375-13.41Z"
            fill="url(#i)"
            />
            <Path
            d="M172.354 99.018s13.664-9.278 27.455-3.813"
            stroke="url(#j)"
            strokeWidth={0.647}
            strokeMiterlimit={10}
            />
            <G filter="url(#k)">
            <Path
                d="m62.742 152.316 44.673-20.832a4.481 4.481 0 0 0 2.168-5.955L83.201 68.952 65.336 62.45 28.785 79.494a4.481 4.481 0 0 0-2.168 5.955l30.17 64.699a4.481 4.481 0 0 0 5.955 2.168Z"
                fill="url(#l)"
            />
            <Path
                d="M65.335 62.45 83.2 68.952l-12.183 5.682-5.682-12.184Z"
                fill="#D0D5DD"
            />
            </G>
            <G filter="url(#m)">
            <Path
                d="M84.097 137.092h49.292a4.48 4.48 0 0 0 4.481-4.481V70.185l-13.443-13.443h-40.33a4.481 4.481 0 0 0-4.48 4.481v71.388a4.48 4.48 0 0 0 4.48 4.481Z"
                fill="url(#n)"
            />
            <Path d="m124.427 56.742 13.443 13.443h-13.443V56.742Z" fill="#D0D5DD" />
            </G>
            <G filter="url(#o)">
            <Path
                d="m109.444 131.458 44.673 20.831a4.48 4.48 0 0 0 5.955-2.167l26.382-56.577-6.502-17.865-36.551-17.044a4.481 4.481 0 0 0-5.955 2.167l-30.17 64.7a4.481 4.481 0 0 0 2.168 5.955Z"
                fill="url(#p)"
            />
            <Path
                d="m179.952 75.68 6.502 17.865-12.184-5.682 5.682-12.183Z"
                fill="#D0D5DD"
            />
            </G>
            <Defs>
            <LinearGradient
                id="a"
                x1={51.459}
                y1={11.837}
                x2={136.796}
                y2={184.295}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.149} stopColor="#F2EDE7" />
                <Stop offset={0.997} stopColor="#DCD7D2" />
            </LinearGradient>
            <LinearGradient
                id="b"
                x1={4.733}
                y1={68.355}
                x2={18.548}
                y2={96.275}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.149} stopColor="#F2EDE7" />
                <Stop offset={0.997} stopColor="#DCD7D2" />
            </LinearGradient>
            <LinearGradient
                id="c"
                x1={29.655}
                y1={96.214}
                x2={54.968}
                y2={143.471}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.235} stopColor="#C3DECB" />
                <Stop offset={0.516} stopColor="#9DC9B2" />
            </LinearGradient>
            <LinearGradient
                id="d"
                x1={43.092}
                y1={119.469}
                x2={40.261}
                y2={128.807}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.295} stopColor="#fff" />
                <Stop offset={0.33} stopColor="#F7FAF8" />
                <Stop offset={0.457} stopColor="#DCECE2" />
                <Stop offset={0.587} stopColor="#C9E2D2" />
                <Stop offset={0.72} stopColor="#BEDCC8" />
                <Stop offset={0.862} stopColor="#BADAC5" />
            </LinearGradient>
            <LinearGradient
                id="e"
                x1={37.619}
                y1={132.786}
                x2={46.197}
                y2={148.801}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.235} stopColor="#C3DECB" />
                <Stop offset={0.516} stopColor="#9DC9B2" />
            </LinearGradient>
            <LinearGradient
                id="f"
                x1={40.585}
                y1={135.62}
                x2={50.428}
                y2={146.125}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.295} stopColor="#fff" />
                <Stop offset={0.33} stopColor="#F7FAF8" />
                <Stop offset={0.457} stopColor="#DCECE2" />
                <Stop offset={0.587} stopColor="#C9E2D2" />
                <Stop offset={0.72} stopColor="#BEDCC8" />
                <Stop offset={0.862} stopColor="#BADAC5" />
            </LinearGradient>
            <LinearGradient
                id="g"
                x1={192.456}
                y1={40.041}
                x2={175.637}
                y2={102.638}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.235} stopColor="#C3DECB" />
                <Stop offset={0.516} stopColor="#9DC9B2" />
            </LinearGradient>
            <LinearGradient
                id="h"
                x1={183.025}
                y1={71.114}
                x2={188.924}
                y2={81.331}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.295} stopColor="#fff" />
                <Stop offset={0.33} stopColor="#F7FAF8" />
                <Stop offset={0.457} stopColor="#DCECE2" />
                <Stop offset={0.587} stopColor="#C9E2D2" />
                <Stop offset={0.72} stopColor="#BEDCC8" />
                <Stop offset={0.862} stopColor="#BADAC5" />
            </LinearGradient>
            <LinearGradient
                id="i"
                x1={193.128}
                y1={85.29}
                x2={187.429}
                y2={106.504}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.235} stopColor="#C3DECB" />
                <Stop offset={0.516} stopColor="#9DC9B2" />
            </LinearGradient>
            <LinearGradient
                id="j"
                x1={190.414}
                y1={89.442}
                x2={181.712}
                y2={104.515}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.295} stopColor="#fff" />
                <Stop offset={0.33} stopColor="#F7FAF8" />
                <Stop offset={0.457} stopColor="#DCECE2" />
                <Stop offset={0.587} stopColor="#C9E2D2" />
                <Stop offset={0.72} stopColor="#BEDCC8" />
                <Stop offset={0.862} stopColor="#BADAC5" />
            </LinearGradient>
            <LinearGradient
                id="l"
                x1={60.117}
                y1={152.49}
                x2={24.516}
                y2={88.263}
                gradientUnits="userSpaceOnUse"
            >
                <Stop stopColor="#E4E7EC" />
                <Stop offset={1} stopColor="#F9FAFB" />
            </LinearGradient>
            <LinearGradient
                id="n"
                x1={81.645}
                y1={136.141}
                x2={76.523}
                y2={62.886}
                gradientUnits="userSpaceOnUse"
            >
                <Stop stopColor="#E4E7EC" />
                <Stop offset={1} stopColor="#F9FAFB" />
            </LinearGradient>
            <LinearGradient
                id="p"
                x1={107.623}
                y1={129.559}
                x2={133.94}
                y2={61.003}
                gradientUnits="userSpaceOnUse"
            >
                <Stop stopColor="#E4E7EC" />
                <Stop offset={1} stopColor="#F9FAFB" />
            </LinearGradient>
            </Defs>
        </Svg>

        )
    }
}

class AlertPlaceholder extends React.Component {
    constructor(props){
        super(props)
    }

    render(){
        return(
            <Svg
            width={266}
            height={117}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...this.props}
        >
            <Path
            opacity={0.2}
            d="M207.53 111.78c13.786 3.864 28.386 4.61 35.449-5.965C258.19 82.835 203.184-4.948 150.011.203c0 0-20.237.61-27.775 14.439-2.308 4.27-5.976 7.66-9.914 10.303-8.149 5.491-18.132 7.457-27.843 5.966-15.008-2.305-45.567-.475-61.05 46.434-10.39 31.792 27.57 37.147 68.248 34.503l115.853-.068Z"
            fill="url(#a)"
            />
            <Path
            d="m47.536 90.834-6.044 2.102v-6.169s3.6.407 4.754-3.254c.068-.203.068-.406.068-.61l.068-9.964a2.722 2.722 0 0 1 2.716-2.712c1.562 0 2.785 1.22 2.717 2.78l-.136 10.235v.475c.068.949 0 5.49-4.143 7.117Z"
            fill="url(#b)"
            />
            <Path
            d="M41.425 91.648v-3.322l3.26-1.084s.678-.204 1.357-.678c.612-.407 1.359-1.153 1.426-2.102v-.135c0-.475.204-11.117.204-12.202 0-1.152 1.426-1.83 1.494-1.898.95.475 1.29 1.152 1.426 1.56.068.135.068.338.068.474 0 1.084 0 10.439-.203 12.947-.136 1.898-1.427 3.186-2.174 3.728-.271.204-.543.407-.882.475l-5.976 2.237Zm.135-3.254v3.05l5.84-2.169c.34-.135.612-.27.883-.474.747-.543 2.038-1.763 2.106-3.66.135-2.509.203-11.864.203-12.948 0-.136 0-.271-.068-.407-.135-.339-.407-.949-1.29-1.423-.204.135-1.358.745-1.358 1.694 0 1.085-.136 11.727-.204 12.202v.136c-.136 1.965-2.784 2.847-2.92 2.914l-3.192 1.085Z"
            fill="#fff"
            />
            <Path
            d="m41.492 90.156-.067-.135c.068 0 4.142-1.424 5.229-2.237.135-.068.271-.204.34-.271.746-.543 1.357-1.017 1.697-2.102.068-.339.136-.745.136-1.152l.136-13.964h.135l-.136 13.964c0 .407-.067.813-.135 1.152-.34 1.153-.951 1.627-1.766 2.17-.136.067-.204.203-.34.27-1.018.882-5.025 2.238-5.228 2.305Z"
            fill="#fff"
            />
            <Path
            d="m31.782 105.137 7.334 2.576v-7.524s-4.414.474-5.773-3.932c-.068-.203-.135-.474-.135-.678l-.068-12.201a3.33 3.33 0 0 0-3.328-3.322c-1.833 0-3.395 1.492-3.327 3.39l.135 12.54v.542c0 1.085.068 6.644 5.162 8.609Z"
            fill="url(#c)"
            />
            <Path
            d="m39.252 106.154-7.335-2.779a3.457 3.457 0 0 1-1.086-.61c-.951-.678-2.513-2.237-2.649-4.542-.203-3.05-.203-14.506-.203-15.794 0-.204 0-.407.068-.61.135-.475.543-1.288 1.765-1.898h.136c.068.067 1.834.88 1.834 2.304 0 1.288.203 13.49.203 14.913v.204c.204 2.237 3.396 3.389 3.396 3.389l3.938 1.356v4.067h-.068Zm-9.44-25.962c-1.086.542-1.494 1.356-1.63 1.762-.068.136-.068.34-.068.543 0 1.287 0 12.743.204 15.726.136 2.237 1.698 3.728 2.58 4.406.34.271.68.407 1.087.61l7.063 2.644v-3.661l-3.803-1.287c-.136-.068-3.328-1.153-3.531-3.525v-.204c0-1.356-.204-13.625-.204-14.913 0-1.22-1.494-1.966-1.698-2.101Z"
            fill="#fff"
            />
            <Path
            d="M39.116 104.324c-.204-.068-5.093-1.695-6.452-2.779-.136-.136-.271-.204-.407-.339-.95-.746-1.766-1.288-2.105-2.644-.136-.407-.204-.881-.204-1.424l-.204-17.014h.204l.204 17.014c0 .475.068.95.203 1.356.408 1.288 1.155 1.898 2.038 2.576.136.136.271.203.475.339 1.358 1.017 6.316 2.712 6.383 2.712l-.135.203Z"
            fill="#fff"
            />
            <Path
            d="M44.888 115.305h-8.76l.475-51.314c0-1.898 1.562-3.457 3.531-3.457h.068a3.52 3.52 0 0 1 3.532 3.389l1.154 51.382Z"
            fill="url(#d)"
            />
            <Path
            d="M42.986 115.373h-4.685V63.381c0-1.83 1.765-2.915 1.833-2.915h.136c.068.068 1.902 1.152 1.902 2.711 0 1.56.95 51.586.95 52.061l-.136.135Zm-4.55-.203h4.415c-.068-2.237-.951-50.501-.951-51.993 0-1.355-1.494-2.44-1.766-2.576-.271.136-1.697 1.153-1.697 2.78v51.789Z"
            fill="#fff"
            />
            <Path
            d="m40.154 60.533-.136.002.638 54.698.136-.001-.639-54.699Z"
            fill="#fff"
            />
            <Path
            d="m248.276 75.989-9.643 3.39V69.48s5.772.678 7.605-5.151a3.99 3.99 0 0 0 .136-.95l.136-15.997c0-2.44 1.969-4.338 4.414-4.338 2.445 0 4.414 2.033 4.414 4.474l-.203 16.472v.678c-.068 1.423-.136 8.744-6.859 11.32Z"
            fill="url(#e)"
            />
            <Path
            d="M238.497 77.345V71.99l5.161-1.763s1.086-.407 2.173-1.085c1.019-.677 2.173-1.83 2.309-3.321v-.271c0-.746.272-17.828.272-19.59 0-1.83 2.308-2.983 2.444-2.983h.136c1.562.745 2.105 1.898 2.309 2.44.068.271.136.475.136.746 0 1.695 0 16.743-.272 20.743-.204 3.05-2.309 5.084-3.531 6.033-.408.339-.951.61-1.426.813l-9.711 3.593Zm.204-5.22v4.88l9.303-3.524c.543-.203.951-.475 1.426-.746 1.223-.881 3.192-2.847 3.396-5.83.271-3.999.271-19.047.271-20.742 0-.271-.068-.475-.136-.678-.203-.542-.679-1.56-2.105-2.305-.339.203-2.241 1.22-2.241 2.78 0 1.762-.271 18.844-.271 19.59v.271c-.272 3.118-4.482 4.61-4.618 4.677l-5.025 1.627Z"
            fill="#fff"
            />
            <Path
            d="m238.633 74.904-.068-.203c.068 0 6.655-2.237 8.42-3.593l.612-.406c1.222-.95 2.241-1.627 2.716-3.39.136-.542.272-1.152.272-1.83l.271-22.437h.272l-.272 22.437c0 .678-.068 1.288-.271 1.83-.544 1.83-1.562 2.576-2.785 3.525l-.611.407c-1.901 1.423-8.285 3.593-8.556 3.66Z"
            fill="#fff"
            />
            <Path
            d="m223.014 98.969 11.816 4.135V91.038s-7.063.813-9.236-6.304c-.136-.34-.136-.746-.204-1.153l-.135-19.523c0-2.982-2.445-5.355-5.365-5.355-2.988 0-5.433 2.44-5.365 5.423l.272 20.065v.881c-.136 1.83 0 10.71 8.217 13.897Z"
            fill="url(#f)"
            />
            <Path
            d="m234.966 100.596-11.749-4.407c-.679-.27-1.222-.542-1.765-.949-1.494-1.152-4.007-3.592-4.279-7.32-.339-4.881-.339-23.251-.339-25.285 0-.339.068-.678.136-.949.271-.746.95-2.034 2.784-2.983h.136c.136.068 2.92 1.424 2.92 3.66 0 2.102.271 21.693.339 23.93v.27c.272 3.593 5.433 5.424 5.433 5.424l6.316 2.101v6.508h.068Zm-15.144-41.69c-1.698.882-2.377 2.102-2.581 2.78-.068.271-.136.542-.136.881 0 2.102 0 20.404.34 25.285.204 3.66 2.716 6.033 4.142 7.117.476.407 1.087.678 1.698.95l11.341 4.27v-5.898l-6.112-2.033c-.204-.068-5.365-1.898-5.636-5.694v-.34c-.068-2.236-.34-21.827-.34-23.928 0-1.898-2.309-3.118-2.716-3.39Z"
            fill="#fff"
            />
            <Path
            d="M234.762 97.613c-.34-.136-8.149-2.78-10.322-4.474-.272-.203-.476-.339-.679-.542-1.562-1.153-2.785-2.102-3.396-4.27a8.093 8.093 0 0 1-.339-2.238l-.34-27.318h.272l.339 27.318c0 .746.136 1.492.34 2.17.611 2.1 1.833 2.982 3.327 4.134.204.204.476.34.679.543 2.173 1.627 10.187 4.338 10.255 4.406l-.136.271Z"
            fill="#fff"
            />
            <Path
            d="m227.02 57.619 7.742 2.711v-7.863s-4.618.542-6.112-4.135c-.068-.271-.136-.474-.136-.746l-.068-12.811a3.504 3.504 0 0 0-3.531-3.525c-1.969 0-3.531 1.627-3.531 3.592l.203 13.219v.542c0 1.085.068 6.982 5.433 9.016Z"
            fill="url(#g)"
            />
            <Path
            d="m234.898 58.771-7.742-2.915c-.407-.135-.815-.406-1.154-.61-.951-.745-2.649-2.372-2.785-4.813-.203-3.186-.203-15.252-.203-16.675 0-.204 0-.407.067-.61.136-.475.612-1.356 1.834-1.966h.136c.068.068 1.969.949 1.969 2.44 0 1.288.136 12.337.204 15.727v.203c.068 1.22 1.086 2.17 1.833 2.712.883.61 1.698.88 1.766.88l4.143 1.424v4.203h-.068Zm-9.983-27.454c-1.154.61-1.562 1.424-1.698 1.83-.068.204-.068.34-.068.543 0 1.356 0 13.422.204 16.608.136 2.372 1.766 3.931 2.716 4.677.34.271.68.475 1.155.61l7.47 2.847v-3.931l-4.007-1.356c-.136-.068-3.531-1.22-3.735-3.728v-.204c-.068-3.39-.203-14.438-.203-15.726 0-1.22-1.562-2.034-1.834-2.17Z"
            fill="#fff"
            />
            <Path
            d="M234.762 56.805c-.204-.067-5.365-1.83-6.791-2.914a2.083 2.083 0 0 0-.475-.34c-1.019-.745-1.834-1.355-2.241-2.846a5.27 5.27 0 0 1-.204-1.492l-.204-18.031h.204l.204 17.963c0 .543.067 1.017.203 1.424.408 1.423 1.155 1.966 2.173 2.711.136.136.34.204.476.34 1.426 1.084 6.655 2.846 6.723 2.914l-.068.271Z"
            fill="#fff"
            />
            <Path
            d="M244.065 115.238h-14.057l.747-82.294c0-3.05 2.513-5.558 5.637-5.558h.135c3.056 0 5.569 2.44 5.637 5.49l1.901 82.362Z"
            fill="url(#h)"
            />
            <Path
            d="M241.009 115.373h-7.537v-.135c0-.814-.068-80.396-.068-83.243 0-2.915 2.852-4.677 2.92-4.677l.068-.068.068.068c.135.068 2.988 1.898 2.988 4.406 0 2.44 1.494 82.768 1.561 83.581v.068Zm-7.266-.271h7.063c-.068-3.593-1.562-81.005-1.562-83.446 0-2.169-2.445-3.863-2.852-4.135-.408.272-2.717 1.898-2.717 4.407 0 2.847.068 79.649.068 83.174Z"
            fill="#fff"
            />
            <Path
            d="m236.551 27.386-.272.003 1.024 87.775.272-.003-1.024-87.775Z"
            fill="#fff"
            />
            <Path
            d="M80.269 117c0-9.219 8.013-16.337 16.773-14.778 2.445.407 4.822 1.017 6.927 1.763 8.896 3.321 33.411 5.084 37.689 3.728 4.143-1.22 15.008-2.033 24.176 3.051a21.686 21.686 0 0 0 5.025 2.033c1.834.475 4.075.949 6.52.949 5.568.068 11.001 3.254 11.001 3.254H80.269Z"
            fill="url(#i)"
            />
            <Path
            d="M118.366 105.07c-3.532-4.746-5.297-11.524-5.297-20.472 0-8.88 1.765-15.727 5.297-20.404 3.531-4.745 8.624-7.117 15.279-7.117 6.587 0 11.68 2.372 15.212 7.117 3.531 4.745 5.297 11.524 5.297 20.404 0 8.948-1.766 15.794-5.297 20.472-3.532 4.745-8.625 7.117-15.28 7.117-6.587-.068-11.68-2.44-15.211-7.117Zm20.576-7.457c1.087-2.644 1.63-6.982 1.63-13.015 0-5.965-.543-10.304-1.63-12.947-1.087-2.644-2.852-3.932-5.297-3.932s-4.21 1.288-5.297 3.932c-1.086 2.643-1.63 6.914-1.63 13.015 0 6.033.544 10.371 1.63 13.015 1.087 2.643 2.852 3.931 5.297 3.931 2.445-.067 4.21-1.355 5.297-3.999Z"
            fill="url(#j)"
            />
            <Path
            d="M163.865 105.07c-3.532-4.746-5.297-11.524-5.297-20.472 0-8.88 1.765-15.727 5.297-20.404 3.531-4.745 8.624-7.117 15.279-7.117 6.587 0 11.681 2.372 15.212 7.117 3.531 4.745 5.297 11.524 5.297 20.404 0 8.948-1.766 15.794-5.297 20.472-3.531 4.745-8.625 7.117-15.28 7.117-6.587-.068-11.68-2.44-15.211-7.117Zm20.576-7.457c1.087-2.644 1.63-6.982 1.63-13.015 0-5.965-.543-10.304-1.63-12.947-1.086-2.644-2.852-3.932-5.297-3.932-2.444 0-4.21 1.288-5.297 3.932-1.086 2.643-1.629 6.914-1.629 13.015 0 6.033.543 10.371 1.629 13.015 1.087 2.643 2.853 3.931 5.297 3.931 2.445-.067 4.211-1.355 5.297-3.999Z"
            fill="url(#k)"
            />
            <Path
            d="M110.692 115.983c-.34-3.999-7.538-10.575-7.538-10.575s2.58 7.999 4.889 10.101c.272.135.679.339 1.223.474.611.068 1.086 0 1.426 0Z"
            fill="url(#l)"
            />
            <Path
            d="M117.007 100.867s-.611 7.389-1.358 10.981c-.747 3.593-1.154 4.745-1.154 4.745l2.309.339s2.173-3.186 1.494-10.168c-.204-1.762-.747-3.728-1.291-5.897Z"
            fill="url(#m)"
            />
            <Path
            d="m112.729 116.322-2.037-.339s-2.988-4.542-3.871-6.778c-.883-2.237-1.019-7.932-1.019-7.932s3.396 6.305 5.297 8.745c1.902 2.508 2.92 6.507 2.92 6.507l-1.29-.203Z"
            fill="url(#n)"
            />
            <Path
            d="m116.328 116.797-2.852-.339c-1.969-12.066 1.019-18.438 1.019-18.438s.611 4.27 1.154 6.643c.543 2.44 1.223 10.846.679 12.134Z"
            fill="url(#o)"
            />
            <Path
            d="m115.174 116.797 3.192.203s7.334-8.202 7.266-12.405c0 0-8.76 9.558-10.458 12.066"
            fill="url(#p)"
            />
            <Path
            d="m118.026 116.932-3.26-.339 1.698-3.525c.136-.339.34-.61.475-.949.883-1.491 4.347-13.625 4.347-13.625s2.444 12.202-3.26 18.438Z"
            fill="url(#q)"
            />
            <Path
            d="M110.828 115.983c-1.766-9.49 1.154-14.438 1.154-14.438s.815 5.626 1.562 8.134c.747 2.508.475 6.846.475 6.846l-3.191-.542Z"
            fill="url(#r)"
            />
            <Path
            d="M118.433 116.932c2.649 1.085 8.15-9.558 8.15-9.558s-4.347 3.118-9.576 9.49l1.426.068Z"
            fill="url(#s)"
            />
            <Path
            d="M112.797 117s12.02-2.983 22.071-2.644c10.05.407 22.206-5.016 22.206-5.016s16.773-6.643 42.036.678c25.262 7.321 43.122 6.982 43.122 6.982H112.797Z"
            fill="url(#t)"
            />
            <Path
            d="M110.896 97.003c0 3.593-2.241 5.423-6.656 5.423h-1.697v2.576c0 2.304-.611 3.999-1.902 5.152-1.29 1.152-2.92 1.762-4.957 1.762s-3.667-.61-4.89-1.762c-1.222-1.153-1.901-2.915-1.901-5.152v-2.644H71.848c-1.834 0-3.26-.542-4.346-1.695-1.087-1.084-1.63-2.508-1.63-4.202 0-1.763.543-3.457 1.698-5.017L89.368 60.33c.68-1.017 1.63-1.762 2.717-2.304a7.581 7.581 0 0 1 3.395-.814c1.902 0 3.6.61 4.958 1.83 1.358 1.22 2.037 2.915 2.037 5.084v27.522h1.698c4.482.068 6.723 1.83 6.723 5.355Zm-31.17-5.287h9.167V78.565l-9.168 13.15Z"
            fill="url(#u)"
            />
            <Path
            d="M136.565 117s12.02-2.305 22.071-2.034c10.05.272 22.206-3.863 22.206-3.863s16.774-5.152 42.036.542S266 117.068 266 117.068H136.565V117Z"
            fill="url(#v)"
            />
            <Path
            d="M28.794 116.254c-1.698-3.389-10.255-6.643-10.255-6.643s5.025 6.101 7.81 7.118c.271.068.679.068 1.222 0 .543-.136.95-.339 1.223-.475Z"
            fill="url(#w)"
            />
            <Path
            d="M29.065 100.867s2.037 6.643 2.649 10.032c.61 3.39.61 4.542.61 4.542l2.106-.542s.815-3.525-2.241-9.423c-.747-1.355-1.901-2.915-3.124-4.609Z"
            fill="url(#x)"
            />
            <Path
            d="m30.695 115.848-1.901.406s-4.21-2.914-5.773-4.609c-1.562-1.695-3.599-6.575-3.599-6.575s5.093 4.338 7.674 5.829c2.512 1.492 4.821 4.677 4.821 4.677l-1.222.272Z"
            fill="url(#y)"
            />
            <Path
            d="m33.955 115.034-2.581.678c-5.908-9.829-5.5-16.472-5.5-16.472s2.037 3.525 3.327 5.423c1.29 1.898 4.822 9.015 4.754 10.371Z"
            fill="url(#z)"
            />
            <Path
            d="m33.004 115.373 2.852-.949s3.6-9.693 2.037-13.354c0 0-4.346 11.388-4.957 14.168"
            fill="url(#A)"
            />
            <Path
            d="m35.517 114.56-2.989.813.272-3.728c0-.339.068-.678.068-.949.272-1.627-.95-13.354-.95-13.354s6.45 9.829 3.599 17.218Z"
            fill="url(#B)"
            />
            <Path
            d="M28.861 116.254c-4.821-7.66-4.006-13.015-4.006-13.015s2.648 4.61 4.21 6.576c1.562 1.965 2.784 5.829 2.784 5.829l-2.988.61Z"
            fill="url(#C)"
            />
            <Path
            d="M35.856 114.424c2.716 0 3.803-11.185 3.803-11.185s-2.716 4.271-5.093 11.592l1.29-.407Z"
            fill="url(#D)"
            />
            <Path
            d="M0 117s13.107-2.983 24.04-2.644c11.001.407 24.175-5.016 24.175-5.016s18.268-6.643 45.771.678S140.98 117 140.98 117H0Z"
            fill="url(#E)"
            />
            <Path
            d="M238.633 116.322c-.815-3.525-8.082-8.405-8.082-8.405s3.396 6.778 5.705 8.337c.271.136.679.204 1.154.271.544-.067 1.019-.135 1.223-.203Z"
            fill="url(#F)"
            />
            <Path
            d="M242.3 102.019s.407 6.643.271 9.897c-.135 3.254-.407 4.338-.407 4.338h2.105s1.494-3.118 0-9.219c-.475-1.491-1.222-3.118-1.969-5.016Z"
            fill="url(#G)"
            />
            <Path
            d="m240.534 116.322-1.901-.068s-3.26-3.66-4.347-5.558c-1.086-1.898-1.969-6.914-1.969-6.914s3.803 5.151 5.84 7.117c2.038 1.966 3.464 5.423 3.464 5.423h-1.087Z"
            fill="url(#H)"
            />
            <Path
            d="m243.794 116.254-2.581.068c-3.395-10.439-1.562-16.54-1.562-16.54s1.087 3.728 1.97 5.762c.815 2.101 2.512 9.49 2.173 10.71Z"
            fill="url(#I)"
            />
            <Path
            d="m242.775 116.39 2.852-.271s5.433-8.202 4.822-11.998c0 0-6.519 9.625-7.742 12.133"
            fill="url(#J)"
            />
            <Path
            d="m245.288 116.187-2.92.135 1.018-3.389c.068-.271.204-.61.34-.881.611-1.492 2.037-12.677 2.037-12.677s3.803 10.507-.475 16.812Z"
            fill="url(#K)"
            />
            <Path
            d="M238.768 116.322c-2.852-8.202-.882-13.015-.882-13.015s1.494 4.881 2.512 7.05c1.019 2.169 1.291 6.033 1.291 6.033l-2.921-.068Z"
            fill="url(#L)"
            />
            <Path
            d="M245.695 116.187c2.513.61 5.976-9.558 5.976-9.558s-3.463 3.389-7.266 9.693l1.29-.135Z"
            fill="url(#M)"
            />
            <Path
            opacity={0.2}
            d="M99.147 7.253a18.799 18.799 0 0 1-4.753-.407c-4.754-1.016-16.366-2.779-18.2 4.542-2.309 9.22 2.309 13.829 12.699 13.829s24.311-5.762 28.318-13.829c1.155-1.356 1.087-3.39 0-4.61-.951-1.084-2.58-1.423-4.007-.88-1.018.474-2.376 1.016-14.057 1.355Z"
            fill="url(#N)"
            />
            <Defs>
            <LinearGradient
                id="a"
                x1={95.518}
                y1={13.873}
                x2={133.714}
                y2={139.807}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.149} stopColor="#F2EDE7" />
                <Stop offset={0.997} stopColor="#DCD7D2" />
            </LinearGradient>
            <LinearGradient
                id="b"
                x1={41.259}
                y1={77.005}
                x2={51.683}
                y2={87.447}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.167} stopColor="#C3DECB" />
                <Stop offset={0.21} stopColor="#BFDCC8" />
                <Stop offset={0.463} stopColor="#ACD1BC" />
                <Stop offset={0.723} stopColor="#A1CBB4" />
                <Stop offset={1} stopColor="#9DC9B2" />
            </LinearGradient>
            <LinearGradient
                id="c"
                x1={39.418}
                y1={88.281}
                x2={26.702}
                y2={101.02}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.167} stopColor="#C3DECB" />
                <Stop offset={0.21} stopColor="#BFDCC8" />
                <Stop offset={0.463} stopColor="#ACD1BC" />
                <Stop offset={0.723} stopColor="#A1CBB4" />
                <Stop offset={1} stopColor="#9DC9B2" />
            </LinearGradient>
            <LinearGradient
                id="d"
                x1={25.066}
                y1={74.098}
                x2={55.545}
                y2={104.632}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.167} stopColor="#C3DECB" />
                <Stop offset={0.21} stopColor="#BFDCC8" />
                <Stop offset={0.463} stopColor="#ACD1BC" />
                <Stop offset={0.723} stopColor="#A1CBB4" />
                <Stop offset={1} stopColor="#9DC9B2" />
            </LinearGradient>
            <LinearGradient
                id="e"
                x1={238.222}
                y1={53.836}
                x2={254.944}
                y2={70.587}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.167} stopColor="#C3DECB" />
                <Stop offset={0.21} stopColor="#BFDCC8" />
                <Stop offset={0.463} stopColor="#ACD1BC" />
                <Stop offset={0.723} stopColor="#A1CBB4" />
                <Stop offset={1} stopColor="#9DC9B2" />
            </LinearGradient>
            <LinearGradient
                id="f"
                x1={235.268}
                y1={71.924}
                x2={214.869}
                y2={92.36}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.167} stopColor="#C3DECB" />
                <Stop offset={0.21} stopColor="#BFDCC8" />
                <Stop offset={0.463} stopColor="#ACD1BC" />
                <Stop offset={0.723} stopColor="#A1CBB4" />
                <Stop offset={1} stopColor="#9DC9B2" />
            </LinearGradient>
            <LinearGradient
                id="g"
                x1={235.112}
                y1={39.853}
                x2={221.688}
                y2={53.301}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.527} stopColor="#C3DECB" />
                <Stop offset={0.552} stopColor="#BFDCC8" />
                <Stop offset={0.695} stopColor="#ACD1BC" />
                <Stop offset={0.843} stopColor="#A1CBB4" />
                <Stop offset={1} stopColor="#9DC9B2" />
            </LinearGradient>
            <LinearGradient
                id="h"
                x1={212.244}
                y1={49.172}
                x2={261.139}
                y2={98.155}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.167} stopColor="#C3DECB" />
                <Stop offset={0.21} stopColor="#BFDCC8" />
                <Stop offset={0.463} stopColor="#ACD1BC" />
                <Stop offset={0.723} stopColor="#A1CBB4" />
                <Stop offset={1} stopColor="#9DC9B2" />
            </LinearGradient>
            <LinearGradient
                id="i"
                x1={80.271}
                y1={109.518}
                x2={188.468}
                y2={109.518}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.492} stopColor="#FFD18F" />
                <Stop offset={0.569} stopColor="#FDC384" />
                <Stop offset={0.707} stopColor="#FAB076" />
                <Stop offset={0.849} stopColor="#F8A56E" />
                <Stop offset={1} stopColor="#F7A16B" />
            </LinearGradient>
            <LinearGradient
                id="j"
                x1={133.647}
                y1={112.143}
                x2={133.647}
                y2={57.055}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.167} stopColor="#2892FB" />
                <Stop offset={0.338} stopColor="#3DA1FB" />
                <Stop offset={0.549} stopColor="#50AFFB" />
                <Stop offset={0.767} stopColor="#5BB7FB" />
                <Stop offset={1} stopColor="#5FBAFB" />
            </LinearGradient>
            <LinearGradient
                id="k"
                x1={179.146}
                y1={112.143}
                x2={179.146}
                y2={57.055}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.167} stopColor="#2892FB" />
                <Stop offset={0.338} stopColor="#3DA1FB" />
                <Stop offset={0.549} stopColor="#50AFFB" />
                <Stop offset={0.767} stopColor="#5BB7FB" />
                <Stop offset={1} stopColor="#5FBAFB" />
            </LinearGradient>
            <LinearGradient
                id="l"
                x1={103.161}
                y1={110.687}
                x2={110.676}
                y2={110.687}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.096} stopColor="#D1E9B4" />
                <Stop offset={0.191} stopColor="#C8E2AE" />
                <Stop offset={0.45} stopColor="#B5D4A2" />
                <Stop offset={0.716} stopColor="#AACB9A" />
                <Stop offset={1} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="m"
                x1={116.445}
                y1={116.862}
                x2={116.445}
                y2={100.871}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.051} stopColor="#D1E9B4" />
                <Stop offset={0.158} stopColor="#C4DFAB" />
                <Stop offset={0.363} stopColor="#B0D09F" />
                <Stop offset={0.502} stopColor="#A9CA9A" />
                <Stop offset={0.865} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="n"
                x1={103.329}
                y1={103.716}
                x2={115.062}
                y2={115.47}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.096} stopColor="#D1E9B4" />
                <Stop offset={0.192} stopColor="#C8E2AE" />
                <Stop offset={0.45} stopColor="#B5D4A2" />
                <Stop offset={0.716} stopColor="#AACB9A" />
                <Stop offset={1} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="o"
                x1={110.092}
                y1={100.528}
                x2={118.67}
                y2={115.411}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.261} stopColor="#D1E9B4" />
                <Stop offset={0.299} stopColor="#C9E3AF" />
                <Stop offset={0.425} stopColor="#B6D4A2" />
                <Stop offset={0.552} stopColor="#AACB9B" />
                <Stop offset={0.681} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="p"
                x1={120.235}
                y1={110.503}
                x2={122.348}
                y2={112.28}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.007} stopColor="#D1E9B4" />
                <Stop offset={0.097} stopColor="#C8E2AE" />
                <Stop offset={0.339} stopColor="#B5D4A2" />
                <Stop offset={0.588} stopColor="#AACB9A" />
                <Stop offset={0.854} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="q"
                x1={115.105}
                y1={104.648}
                x2={122.706}
                y2={112.262}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.12} stopColor="#D1E9B4" />
                <Stop offset={0.213} stopColor="#C8E2AE" />
                <Stop offset={0.465} stopColor="#B5D4A2" />
                <Stop offset={0.724} stopColor="#AACB9A" />
                <Stop offset={1} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="r"
                x1={108.066}
                y1={105.437}
                x2={116.574}
                y2={113.961}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.205} stopColor="#D1E9B4" />
                <Stop offset={0.29} stopColor="#C8E2AE" />
                <Stop offset={0.517} stopColor="#B5D4A2" />
                <Stop offset={0.751} stopColor="#AACB9A" />
                <Stop offset={1} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="s"
                x1={121.774}
                y1={107.391}
                x2={121.774}
                y2={117.025}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.248} stopColor="#D1E9B4" />
                <Stop offset={0.317} stopColor="#C8E2AE" />
                <Stop offset={0.504} stopColor="#B5D4A2" />
                <Stop offset={0.697} stopColor="#AACB9A" />
                <Stop offset={0.902} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="t"
                x1={112.815}
                y1={111.831}
                x2={242.262}
                y2={111.831}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.686} stopColor="#FFD18F" />
                <Stop offset={0.734} stopColor="#FDC384" />
                <Stop offset={0.819} stopColor="#FAB076" />
                <Stop offset={0.907} stopColor="#F8A56E" />
                <Stop offset={1} stopColor="#F7A16B" />
            </LinearGradient>
            <LinearGradient
                id="u"
                x1={88.422}
                y1={111.914}
                x2={88.422}
                y2={57.284}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.167} stopColor="#2892FB" />
                <Stop offset={0.338} stopColor="#3DA1FB" />
                <Stop offset={0.549} stopColor="#50AFFB" />
                <Stop offset={0.767} stopColor="#5BB7FB" />
                <Stop offset={1} stopColor="#5FBAFB" />
            </LinearGradient>
            <LinearGradient
                id="v"
                x1={265.982}
                y1={112.985}
                x2={136.535}
                y2={112.985}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.333} stopColor="#FFD18F" />
                <Stop offset={0.432} stopColor="#FDC384" />
                <Stop offset={0.61} stopColor="#FAB076" />
                <Stop offset={0.794} stopColor="#F8A56E" />
                <Stop offset={0.99} stopColor="#F7A16B" />
            </LinearGradient>
            <LinearGradient
                id="w"
                x1={20.363}
                y1={114.245}
                x2={26.928}
                y2={111.635}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.096} stopColor="#D1E9B4" />
                <Stop offset={0.191} stopColor="#C8E2AE" />
                <Stop offset={0.45} stopColor="#B5D4A2" />
                <Stop offset={0.716} stopColor="#AACB9A" />
                <Stop offset={1} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="x"
                x1={34.119}
                y1={115.043}
                x2={28.581}
                y2={101.059}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.051} stopColor="#D1E9B4" />
                <Stop offset={0.158} stopColor="#C4DFAB" />
                <Stop offset={0.363} stopColor="#B0D09F" />
                <Stop offset={0.502} stopColor="#A9CA9A" />
                <Stop offset={0.865} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="y"
                x1={18.088}
                y1={108.093}
                x2={32.429}
                y2={114.298}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.096} stopColor="#D1E9B4" />
                <Stop offset={0.192} stopColor="#C8E2AE" />
                <Stop offset={0.45} stopColor="#B5D4A2" />
                <Stop offset={0.716} stopColor="#AACB9A" />
                <Stop offset={1} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="z"
                x1={22.891}
                y1={102.966}
                x2={35.557}
                y2={113.007}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.261} stopColor="#D1E9B4" />
                <Stop offset={0.299} stopColor="#C9E3AF" />
                <Stop offset={0.425} stopColor="#B6D4A2" />
                <Stop offset={0.552} stopColor="#AACB9B" />
                <Stop offset={0.681} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="A"
                x1={35.222}
                y1={108.173}
                x2={37.687}
                y2={108.993}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.007} stopColor="#D1E9B4" />
                <Stop offset={0.097} stopColor="#C8E2AE" />
                <Stop offset={0.339} stopColor="#B5D4A2" />
                <Stop offset={0.588} stopColor="#AACB9A" />
                <Stop offset={0.854} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="B"
                x1={28.704}
                y1={104.831}
                x2={37.995}
                y2={108.851}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.12} stopColor="#D1E9B4" />
                <Stop offset={0.213} stopColor="#C8E2AE" />
                <Stop offset={0.465} stopColor="#B5D4A2" />
                <Stop offset={0.724} stopColor="#AACB9A" />
                <Stop offset={1} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="C"
                x1={22.826}
                y1={107.958}
                x2={33.226}
                y2={112.457}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.205} stopColor="#D1E9B4" />
                <Stop offset={0.29} stopColor="#C8E2AE" />
                <Stop offset={0.517} stopColor="#B5D4A2" />
                <Stop offset={0.751} stopColor="#AACB9A" />
                <Stop offset={1} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="D"
                x1={35.486}
                y1={104.92}
                x2={38.823}
                y2={113.345}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.248} stopColor="#D1E9B4" />
                <Stop offset={0.317} stopColor="#C8E2AE" />
                <Stop offset={0.504} stopColor="#B5D4A2" />
                <Stop offset={0.697} stopColor="#AACB9A" />
                <Stop offset={0.902} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="E"
                x1={0}
                y1={111.828}
                x2={140.963}
                y2={111.828}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.561} stopColor="#FFD18F" />
                <Stop offset={0.627} stopColor="#FDC384" />
                <Stop offset={0.747} stopColor="#FAB076" />
                <Stop offset={0.869} stopColor="#F8A56E" />
                <Stop offset={1} stopColor="#F7A16B" />
            </LinearGradient>
            <LinearGradient
                id="F"
                x1={231.31}
                y1={112.59}
                x2={237.987}
                y2={111.593}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.096} stopColor="#D1E9B4" />
                <Stop offset={0.191} stopColor="#C8E2AE" />
                <Stop offset={0.45} stopColor="#B5D4A2" />
                <Stop offset={0.716} stopColor="#AACB9A" />
                <Stop offset={1} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="G"
                x1={243.934}
                y1={116.321}
                x2={241.82}
                y2={102.11}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.051} stopColor="#D1E9B4" />
                <Stop offset={0.158} stopColor="#C4DFAB" />
                <Stop offset={0.363} stopColor="#B0D09F" />
                <Stop offset={0.502} stopColor="#A9CA9A" />
                <Stop offset={0.865} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="H"
                x1={230.534}
                y1={106.373}
                x2={242.521}
                y2={115.266}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.096} stopColor="#D1E9B4" />
                <Stop offset={0.192} stopColor="#C8E2AE" />
                <Stop offset={0.45} stopColor="#B5D4A2" />
                <Stop offset={0.716} stopColor="#AACB9A" />
                <Stop offset={1} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="I"
                x1={236.121}
                y1={102.645}
                x2={245.716}
                y2={114.74}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.261} stopColor="#D1E9B4" />
                <Stop offset={0.299} stopColor="#C9E3AF" />
                <Stop offset={0.425} stopColor="#B6D4A2" />
                <Stop offset={0.552} stopColor="#AACB9B" />
                <Stop offset={0.681} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="J"
                x1={246.458}
                y1={110.169}
                x2={248.572}
                y2={111.468}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.007} stopColor="#D1E9B4" />
                <Stop offset={0.097} stopColor="#C8E2AE" />
                <Stop offset={0.339} stopColor="#B5D4A2" />
                <Stop offset={0.588} stopColor="#AACB9A" />
                <Stop offset={0.854} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="K"
                x1={241.123}
                y1={105.644}
                x2={248.888}
                y2={111.405}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.12} stopColor="#D1E9B4" />
                <Stop offset={0.213} stopColor="#C8E2AE" />
                <Stop offset={0.465} stopColor="#B5D4A2" />
                <Stop offset={0.724} stopColor="#AACB9A" />
                <Stop offset={1} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="L"
                x1={234.972}
                y1={107.276}
                x2={243.664}
                y2={113.725}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.205} stopColor="#D1E9B4" />
                <Stop offset={0.29} stopColor="#C8E2AE" />
                <Stop offset={0.517} stopColor="#B5D4A2" />
                <Stop offset={0.751} stopColor="#AACB9A" />
                <Stop offset={1} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="M"
                x1={247.413}
                y1={107.2}
                x2={248.687}
                y2={115.762}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.248} stopColor="#D1E9B4" />
                <Stop offset={0.317} stopColor="#C8E2AE" />
                <Stop offset={0.504} stopColor="#B5D4A2" />
                <Stop offset={0.697} stopColor="#AACB9A" />
                <Stop offset={0.902} stopColor="#A6C898" />
            </LinearGradient>
            <LinearGradient
                id="N"
                x1={89.608}
                y1={8.019}
                x2={95.598}
                y2={29.907}
                gradientUnits="userSpaceOnUse"
            >
                <Stop offset={0.149} stopColor="#F2EDE7" />
                <Stop offset={0.997} stopColor="#DCD7D2" />
            </LinearGradient>
            </Defs>
        </Svg>
        )
    }
}

export { EmptySearchPlaceholder, EmptyNewsPlaceholder, AlertPlaceholder }