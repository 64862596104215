import React, { Component } from 'react';
import { StyleSheet, View, Image, ScrollView, FlatList } from 'react-native'; 
import { Alert, AlertTitle, Typography, Avatar, IconButton, Skeleton } from '@mui/material'; 
import * as Identifier from "../constants/PageIdentifiers";
import * as Command from "../constants/Commands";
import { executeGet, executePost, removeCredentials , executeMultipartPost, saveLocalParameters } from '../utilities/Utilities';
import { code_ok, code_empty, code_error, _code_auth_error , homepage, homepage_banner, 
    homepageContentType, homepageCategories , notificationCheck, updateFBToken, updateAvatar } from '../constants/api';
import { primary , color_text , sub_text } from '../constants/colors';
import SkeletonMain from '../skeletons/SkeletonMain';
import AlertDialog from '../components/AlertDialog';
import SearchIcon from '../assets/images/SearchIcon';
import NotificationIcon from '../assets/images/NotificationIcon';
import HomepageBanners from '../components/HomepageBanners';
import HomepageContent from '../components/HomepageContent';
import HomepageContentType from '../components/HomepageContentType';
import HomepageContentCategories  from '../components/HomepageContentCategories';
import HomepageAuthors from '../components/HomepageAuthors';
import HomepageRecommended from '../components/HomepageRecommended'; 
import HomepageNews from '../components/HomepageNews';
import NavigationMenu from '../components/NavigationMenu';
import { BookRack } from '../assets/Icons';
import * as DocumentPicker from 'expo-document-picker';

var _is_mounted = false;

//== Types of homepage ==// 
const h_banner = "banner";
const h_content = "content"; 
const h_content_type = "content-types";
const h_categories = "categories";
const h_authors = "authors"; 
const h_navigation = "main-menu";
const h_recommended = "recommended";
const h_news = "news";

class Main extends Component { 
    constructor(props){
        super(props)
        this.state = {
            showLoading : true, 
            showAlert : false, 
            loadingMessage : "", 
            alertMessage : "",
            fullname : "Anonymous", 
            photo : "https://bintangpusnas.perpusnas.go.id/",
            homepage_data : [],
            banners : [],
            total_unread : 0,
            total_read : 0,
            uploadingAvatar : false

        }

        this.getHomepage = this.getHomepage.bind(this); 
        this.renderHomepage = this.renderHomepage.bind(this); 

        this.getHomepageBanner = this.getHomepageBanner.bind(this); 
        this.handleSearchClick = this.handleSearchClick.bind(this); 
        this.handleNotificationClick = this.handleNotificationClick.bind(this);

        this.checkNotification = this.checkNotification.bind(this);
        this.handleAlertClose = this.handleAlertClose.bind(this);
        this.sendToken = this.sendToken.bind(this);

        this.handleAvatarClick = this.handleAvatarClick.bind(this);
        this.saveLocalParams = this.saveLocalParams.bind(this);
    }

    componentDidMount = ()=>{
        _is_mounted = true;
        
        if(_is_mounted){
            this.saveLocalParams();
            let photo ;
            if(this.props.route.params.gender == "L"){
                photo = "https://bintangpusnas.perpusnas.go.id/images/avatar/userL.png"; 
            } else {
                photo = "https://bintangpusnas.perpusnas.go.id/images/avatar/userP.png"; 
            }

            this.setState({
                fullname : this.props.route.params.fullname, 
                photo : this.props.route.params.photo,
            })

            window.addEventListener("message", (event)=>{
                if (event.data.id == "update_fullname"){
                    let fullname = event.data.fullname; 
                    this.setState({
                        fullname : fullname
                    })
                } else if(event.data.id == "check_notifications"){
                    this.checkNotification(); 
                } else if(event.data.id == "sign_out"){
                    //console.log("SIGN OUT MESSAGE RECEIVED");
                    removeCredentials(()=>{
                        this.props.navigation.reset({
                            index : 0, 
                            routes:[{ name : "Login", params : this.props.route.params.options }]
                        })
                    });
                } else if(event.data.id == "send_fb_token") {
                    let fb_token = event.data.token; 
                    this.sendToken(fb_token);
                } else if(event.data.id == "go_back"){
                   
                }
            })

            //== FCM INIT ==//
            if(global.os == "ANDROID") {
                //JSBridge.launchFunction(Command.INIT_FCM, "-");
            } else {
                //TODO: IOS Init FCM 
            }

            this.checkNotification();
        }
        this.getHomepage(); 
    }

    saveLocalParams = async()=>{
        //console.log(JSON.stringify(this.props.route.params));
        let obj = this.props.route.params; 
        obj.credential = global.defaultHeaders;
        await saveLocalParameters(obj); 
    }

    componentWillUnmount = ()=>{
        _is_mounted = false;
        window.removeEventListener("message", null); 
    }

    getHomepage = async()=>{
        const response = await executeGet(homepage); 
        if(response.code == code_ok){
            if(response.data.length > 0 ) {
                this.setState({
                    showLoading : false,  
                    homepage_data : response.data
                });
            }
        } else {
            if(response.code == code_auth_error){
                //TODO - Force Logout 
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out"});
            } else {
                this.setState({
                    showLoading : false, 
                    showAlert : true, 
                    alertMessage : response.msg
                })
            }
        }
    }

    getHomepageBanner = async(code)=>{

    }
    renderHomepage = ({item ,index, separators})=>{
       if(item.type == h_banner){
            return(
                <HomepageBanners 
                    code={ item.code }
                    navigation={ this.props.navigation }
                />
            )
       } else if(item.type == h_content_type ) {
            return(
                <HomepageContentType 
                    code={ item.code } 
                    title={ item.title }
                    navigation={ this.props.navigation }
                />
            )
       } else if(item.type == h_content) {
            return(
                <HomepageContent 
                    code={ item.code }
                    title={ item.title }
                    navigation={ this.props.navigation }
                />
            )
       } else if(item.type == h_categories ){
            return(
                <HomepageContentCategories 
                    code={ item.code } 
                    title={ item.title }
                    navigation={ this.props.navigation }
                />
            )
       } else if(item.type == h_authors){
            return(
                <HomepageAuthors 
                    code={ item.code } 
                    title={ item.title }
                    navigation={ this.props.navigation }
                />
            )
       } else if((item.type == h_navigation)){
            return(
                <NavigationMenu 
                    navigation={ this.props.navigation }
                />
            )
       } else if(item.type == h_recommended) {
            return(
                <HomepageRecommended 
                    code={ item.code } 
                    title={ item.title } 
                    navigation={ this.props.navigation }
                />
            )
       } else if(item.type == h_news){
            return(
                <HomepageNews 
                    code={ item.code }
                    title={ item.title } 
                    navigation={ this.props.navigation }
                />
            )
       }
    }

    handleSearchClick = ()=>{
        this.props.navigation.navigate("SearchPage", { identifier : Identifier.MAIN });
    }
    
    handleNotificationClick  = ()=>{
        this.props.navigation.navigate("Notification", { identifier : Identifier.MAIN });
    }

    checkNotification = async()=>{
        if(_is_mounted){
            const response = await executeGet(notificationCheck); 
            if(response.code == code_ok){
                this.setState({
                    total_unread : parseInt(response.data.unread), 
                    total_read : parseInt(response.data.read)
                })
            }
        }
    }
    handleAlertClose = ()=>{
        this.setState({
            showAlert : false 
        })
    }

    sendToken = async(token)=>{
        
        let formData = new FormData();
        formData.append("token", token);
        const response = await executePost(updateFBToken, formData); 
        
    }

    handleAvatarClick = async()=>{
        let result = await DocumentPicker.getDocumentAsync({
            type : "image/*"
        });
        if(result.type == "success"){
            this.setState({
                uploadingAvatar : true
            })

            let nameParts = result.name.split('.');
            let fileType = nameParts[nameParts.length - 1];

            let formData = new FormData(); 
            formData.append("avatar", {
                uri : result.uri, 
                name : result.name, 
                type :"image/".fileType, 
                mime : result.mimeType
            })
            let contentType = { "Content-Type" : "multipart/form-data" }
            let headers = { ...global.defaultHeaders, ...contentType }
            
            const response = await executeMultipartPost(updateAvatar, formData, headers);
            console.log(JSON.stringify(response));
            if(response.code == code_ok){
                this.setState({
                    uploadingAvatar : false, 
                    photo : response.url
                })
            } else {
                this.setState({
                    uploadingAvatar : false,
                    showAlert : true, 
                    alertMessage : response.msg
                })
            }

        }
    }
    
    render(){
        return(
            <View style={{ flex : 1}}> 
            {
                this.state.showLoading && 
                <View style={ styles.container}>
                    <SkeletonMain />
                </View>
            }
            {
                !this.state.showLoading &&
                <ScrollView contentContainerStyle={ styles.container}>
                    <View   
                        style={{
                            height : 100, 
                            backgroundColor : primary 
                        }}
                    >
                        <View style={{flexDirection : "row" ,alignItems : "center",  marginLeft : 24, marginTop : (global.os == "IOS" ? 64 : 32 ), marginRight : 24,  }}>
                            {
                                !this.state.uploadingAvatar && 
                                <Avatar 
                                    src={ this.state.photo }
                                    style={{ width : 56, height : 56  }}
                                    onClick={ this.handleAvatarClick }
                                />
                            }
                            {
                                this.state.uploadingAvatar && 
                                <Skeleton 
                                    variant="circular"
                                    animation="wave"
                                    width={ 56 }
                                    height={ 56 }
                                />
                            }

                            
                            <View style={{marginLeft : 16 , justifyContent : "center", flex : 1}}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 14 }
                                    variant="h3"
                                    color={"white"}
                                >
                                    Hello, 
                                </Typography>

                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 16 }
                                    variant="h3"
                                    fontWeight="600"
                                    color={"white"}
                                >
                                    { this.state.fullname }
                                </Typography>
                            </View>

                            {/* <IconButton
                                onClick={ this.handleSearchClick }
                            >
                                <SearchIcon />
                            </IconButton>
                            <View>
                                <IconButton
                                    onClick={ this.handleNotificationClick }
                                >
                                    <NotificationIcon />
                                </IconButton>
                                {
                                    this.state.total_unread > 0 && 
                                    <View 
                                        style={{
                                            backgroundColor : "red",
                                            width : 8, 
                                            height : 8, 
                                            borderRadius : 8, 
                                            position : "absolute", 
                                            top : 8, 
                                            right : 8, 
                                        }}
                                    />
                                }
                            </View> */}

                        </View>
                    </View>
                    <View style={{backgroundColor : "white", flex : 1, borderTopRightRadius : 20, borderTopLeftRadius : 20, marginTop : (global.os == "IOS" ? 48 : 16 ) }}>
                        <View style={{marginTop  : 16 ,  marginBottom: 16, alignSelf : "center", height : 3 , backgroundColor : "#E3E3E3", width : 46 , borderRadius : 8 }} />
                        <FlatList
                            keyExtractor={(item, index)=>item.code}
                            data={ this.state.homepage_data }
                            renderItem={ this.renderHomepage }
                            showsVerticalScrollIndicator={ false }
                        />
                    </View>
                </ScrollView>
            }
            {
                this.state.showAlert && 
                <Alert 
                    onClose={ this.handleAlertClose }
                    severity="error"
                > 
                    <AlertTitle>Error</AlertTitle>
                    { this.state.alertMessage }
                </Alert>
            }
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container : {
        flexGrow : 1, 
        backgroundColor : primary
    },
    scrollview : {
        flex : 1, 
        position : "absolute", 
        top : 0,
        bottom : 0, 
        left : 0, 
        right : 0,  
        
    },
    main_container : {
        height : "100%", 
        borderTopLeftRadius : 20, 
        borderTopRightRadius : 20,
        backgroundColor : "white"
    }
})

export default Main; 
