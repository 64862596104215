import React, { Component } from 'react';
import { StyleSheet, View, FlatList, Image, Dimensions } from 'react-native'; 
import { code_ok, code_error, code_auth_error, code_empty , homepageContentType } from '../constants/api';
import { Skeleton, Typography, Chip,Button } from '@mui/material'; 
import { executePost , truncateText } from '../utilities/Utilities';
import AlertDialog from '../components/AlertDialog';
import { color_text, sub_text, primary, transparent } from '../constants/colors';
import * as Identifier from '../constants/PageIdentifiers';

class DesktopHomepageContentType extends Component {
    constructor(props){
        super(props)
        this.state = {
            showLoading : true, 
            loadingMessage : "", 
            showAlert : false, 
            alertMessage : "",
            code : this.props.code , 
            title : this.props.title,
            dummy_data : [
                {i : 0 }, { i : 1 }, { i : 2 }, { i : 3}
            ],
            data : []
        }

        this.renderSkeleton = this.renderSkeleton.bind(this); 
        this.renderItem = this.renderItem.bind(this); 
        this.fetchHomepageContentType = this.fetchHomepageContentType.bind(this); 
        this.handleClick = this.handleClick.bind(this); 
    }

    componentDidMount = ()=>{
        this.fetchHomepageContentType();
    }

    renderSkeleton = ({item, index, separators})=>{
        return(
            <Skeleton 
                variant="rectangular"
                sx={{width : 90, height : 40 , borderRadius : 2 }}
                animation="wave"
                style={{
                    marginLeft : (index == 0 ? 16 : 8 ),
                    marginRight : (index == 3 ? 16 : 8 )
                }}
            />
        )
    }

    renderItem = ({item, index, separators})=>{
        return(
            <Button
                variant="outlined"
                style={{marginLeft : (index == 0 ? 16 : 0 ), marginRight : (index == (this.state.data.length - 1 ) ? 16 : 8 ),
                     textTransform : "none", borderRadius : 15, padding : 10  }}
                onClick={()=>{ this.handleClick(item.content_type_code, item.title)} }
                sx={{ minWidth : 140 }}
            >
                <Typography
                    fontFamily="Open Sans"
                    fontSize={ 16 }
                    fontWeight="700"
                >
                    { item.title}
                </Typography>
            </Button>
        )
    }

    fetchHomepageContentType = async()=>{
        let formData = new FormData(); 
        formData.append("homepage_code", this.state.code ) 

        const response = await executePost(homepageContentType, formData); 
        if(response.code == code_ok){
            this.setState({
                showLoading : false, 
                data : response.data 
            })

        } else {
            if(response.code != code_auth_error){
                this.setState({
                    showLoading : false, 
                    showAlert : true, 
                    alertMessage : response.msg 
                })
            } else {
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out"});
            }
        }
    }

    handleClick = (code, title)=>{
        let params = { 
            code : code, 
            title : title 
        }
        this.props.navigation.navigate(Identifier.CONTENT_TYPE_PAGE_DESKTOP, params); 
    }

    render(){
        return(
            <View style={{width : "100%"}}> 
            {
                this.state.showLoading && 
                <View>
                    <Skeleton 
                        variant="text"
                        sx={{fontSize : '2rem' }}
                        style={{marginLeft : 16 , marginBottom : 8 , marginRight : 16,  marginTop : 16 }} 
                    />
                    <FlatList
                        keyExtractor={(i, x)=>"skeleton_"+ x }
                        data={ this.state.dummy_data}
                        renderItem={ this.renderSkeleton }
                        horizontal={ true }
                    />
                </View>
            }
            {
                !this.state.showLoading && 
                <View style={{ marginTop : 16 }}>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 16 }
                        variant="h3"
                        style={{flex : 1, marginLeft : 16 , marginBottom : 16 }}
                        fontWeight={"700"}
                    >
                        { this.props.title }
                    </Typography>  
                    <FlatList 
                        horizontal={ true }
                        keyExtractor={(item, index)=>"type_code_"+item.content_type_code } 
                        data={ this.state.data }
                        renderItem={ this.renderItem }
                    />
                </View>
            }

            </View>
        )
    }

}

export default DesktopHomepageContentType; 