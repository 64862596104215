import React, { Component } from 'react';
import { StyleSheet, View, FlatList, Image, Dimensions, Pressable } from 'react-native';
import { code_ok, code_error, code_auth_error, code_empty, homepageRecommended } from '../../constants/api';
import { Skeleton, Typography, Card, CardActionArea, CardMedia, CardContent, Button } from '@mui/material';
import { executeGet, executePost, truncateText, navigateContentDetail } from '../../utilities/Utilities';
import AlertDialog from '../AlertDialog';
import { color_text, sub_text, primary } from '../../constants/colors';
import * as Identifier from '../../constants/PageIdentifiers';
import { 
    IconSD, IconSMP, IconSMA,
    IconKoleksiSdMobile, IconKoleksiSmpMobile, IconKoleksiSmaMobile,
    IconKoleksiSD, IconKoleksiSMA, IconKoleksiSMP } from '../../assets/Icons';


class MenuKoleksiSekolah extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            showAlert: false,
            alertMessage: "",
            loadingMessage: "",
            code: this.props.code,
            data: [],
            dummy_data: [
                { i: 0 }, { i: 1 }, { i: 2 }
            ]

        }

        this.handleAlertClose = this.handleAlertClose.bind(this);
        this.handleMoreClick = this.handleMoreClick.bind(this);
        this.fetchContents = this.fetchContents.bind(this);
        // this.renderItem = this.renderItem.bind(this);
        this.handleImageError = this.handleImageError.bind(this);
        this.contentDetail = this.contentDetail.bind(this);
    }

    componentDidMount = () => {
        this.fetchContents();
    }

    handleAlertClose = () => {

    }
    handleMoreClick = (code, title) => {
        this.props.navigation.navigate("SectionDetail", { identifier: Identifier.SECTION_DETAIL, code: code, title: title, origins: "CONTENT_RECOMMENDED" });
    }

    fetchContents = async () => {

        let formData = new FormData();
        formData.append("page", 1);
        formData.append("homepage_code", this.props.code)
        formData.append("limit", 3);

        const response = await executePost(homepageRecommended, formData);
        if (response.code == code_ok) {
            console.log(JSON.stringify(response));
            console.log(JSON.stringify(this.state.data));
            this.setState({
                showLoading: false,
                // data : response.data
            })
        } else {
            if (response.code != code_auth_error) {
                this.setState({
                    showLoading: false,
                    alertMessage: response.msg,
                    showAlert: true
                })
            } else {
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            }
        }
    }

    contentDetail = (item, index) => {
        let params = {
            previous: Identifier.MAIN,
            parameters: {},
            content_code: item.content_code
        }
        navigateContentDetail(this.props.navigation, params);
    }
    handleImageError = (item, index) => {
        let data = [...this.state.data];
        let obj = { ...data[index] };
        obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
        data[index] = obj;
        this.setState({
            data: data
        })
    }

    render() {
        return (
            <View style={{ width: "100%" }}>
                {
                    this.state.showLoading &&
                    <View>
                        <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: '2rem', maxWidth: 120 }}
                            style={{ marginLeft: 16, marginBottom: 8, marginRight: 16, marginTop: 16 }}
                        />
                        <Skeleton
                            variant="rectangular"
                            style={{ height: 90, marginTop: 8, borderRadius: 8, marginLeft: 16, marginRight: 16 }}
                            animation="wave"
                        />
                        <Skeleton
                            variant="rectangular"
                            style={{ height: 90, marginTop: 8, borderRadius: 8, marginLeft: 16, marginRight: 16 }}
                            animation="wave"
                        />
                        <Skeleton
                            variant="rectangular"
                            style={{ height: 90, marginTop: 8, borderRadius: 8, marginLeft: 16, marginRight: 16 }}
                            animation="wave"
                        />
                    </View>
                }
                {
                    !this.state.showLoading &&
                    <View style={{ marginTop: 16 }}>
                        <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 8 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={16}
                                variant="h3"
                                style={{ flex: 1, marginLeft: 16, marginBottom: 16 }}
                                color={color_text}
                                fontWeight={"600"}
                            >
                                {truncateText(this.props.title, 30)}
                            </Typography>
                        </View>
                        {
                            //ANCHOR - KOLEKSI SD
                        }
                        <View style={{ flex: 1, flexDirection: "row", marginLeft: 16, marginRight: 16 }} >

                            <CardActionArea sx={{ cursor: "pointer" }}
                                style={{ display: 'flex' }}
                                onClick={() => {
                                    let params = { code : "HMP-SD", title : "Koleksi SD", origins : "CONTENT_SECTION"}; 
                                    this.props.navigation.navigate("SectionDetail", params);
                                }}
                            >
                                {/* <IconKoleksiSdMobile width={'100%'} height={140}/> */}
                                <div style={{ height: '130px', width: '100%', position: 'relative', backgroundColor: '#EA5455', borderRadius: 10}}>
                                    <div style={{margin: '0px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                                        <IconSD width={86} height={63}/>
                                        <div style={{color: 'white', textAlign: 'center', paddingTop: 5, fontFamily: 'Open Sans', fontSize: 15}}>
                                            SD/MI
                                        </div>
                                    </div>
                                </div>
                            </CardActionArea>
                            <CardActionArea sx={{ cursor: "pointer" }}
                                style={{ display: 'flex', marginLeft: '10px', marginRight: '10px' }}
                                onClick={() => {
                                    let params = { code: "HMP-SMP", title: "Koleksi SMP", origins: "CONTENT_SECTION" };
                                    this.props.navigation.navigate("SectionDetail", params);
                                }}

                            >
                                {/* <IconKoleksiSmpMobile width={'100%'} height={140}/> */}
                                <div style={{ height: '130px', width: '100%', position: 'relative', backgroundColor: '#184999', borderRadius: 10}}>
                                    <div style={{margin: '0px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                                        <IconSMP width={86} height={63}/>
                                        <div style={{color: 'white', textAlign: 'center', paddingTop: 5, fontFamily: 'Open Sans', fontSize: 15}}>
                                            SMP/MTS
                                        </div>
                                    </div>
                                </div>
                            </CardActionArea>
                            <CardActionArea sx={{ cursor: "pointer" }}
                                style={{ display: 'flex' }}
                                onClick={() => {
                                    let params = { code: "HMP-SMA", title: "Koleksi SMA", origins: "CONTENT_SECTION" };
                                    this.props.navigation.navigate("SectionDetail", params);
                                }}

                            >
                                {/* <IconKoleksiSmaMobile width={'100%'} height={140}/> */}
                                <div style={{ height: '130px', width: '100%', position: 'relative', backgroundColor: '#57B7EA', borderRadius: 10}}>
                                    <div style={{margin: '0px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                                        <IconSMA width={86} height={63}/>
                                        <div style={{color: 'white', textAlign: 'center', paddingTop: 5, fontFamily: 'Open Sans', fontSize: 15}}>
                                            SMA/SMK/MA
                                        </div>
                                    </div>
                                </div>
                            </CardActionArea>
                        </View>

                        {/* <CardActionArea sx={{ mb: 1}}
                            onClick={()=>{
                                let params = { code : "HMP-SD", title : "Koleksi SD", origins : "CONTENT_SECTION"}; 
                                this.props.navigation.navigate("SectionDetail", params);
                            }}
                        >
                            <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}>
                                <View
                                    style={{
                                        borderRadius: 8,
                                        marginLeft: 16,
                                        marginRight: 16
                                    }}
                                >
                                    <IconKoleksiSD />
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={14}
                                        color={color_text}
                                        fontWeight={"600"}
                                    >
                                        Koleksi SD
                                    </Typography>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={12}
                                        color={sub_text}
                                        style={{ marginTop: '4px' }}
                                    >
                                        Koleksi buku-buku SD
                                    </Typography>
                                    <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px'}}/>


                                </View>
                            </View>
                        </CardActionArea>
                        {
                            //ANCHOR - KOLEKSI SMP
                        }
                        <CardActionArea sx={{ mb : 1}}
                            onClick={()=>{
                                let params = { code : "HMP-SMP", title : "Koleksi SMP", origins : "CONTENT_SECTION"}; 
                                this.props.navigation.navigate("SectionDetail", params);
                            }}
                        >
                            <View style={{ flexDirection: "row", paddingLeft: 12, paddingRight: 12, paddingBottom: 12, alignItems: 'center' }}>
                                <View
                                    style={{
                                        borderRadius: 8,
                                        marginLeft: 16,
                                        marginRight: 16
                                    }}
                                >
                                    <IconKoleksiSMP />
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={14}
                                        color={color_text}
                                        fontWeight={"600"}
                                    >
                                        Koleksi SMP
                                    </Typography>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={12}
                                        color={sub_text}
                                        style={{ marginTop: '4px' }}
                                    >
                                        Koleksi buku-buku SMP
                                    </Typography>
                                    <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px'}}/>


                                </View>
                            </View>
                        </CardActionArea>
                        {
                            //ANCHOR - KOLEKSI SMA
                        }
                        <CardActionArea sx={{ mb : 1}}
                            onClick={()=>{
                                let params = { code : "HMP-SMA", title : "Koleksi SMA", origins : "CONTENT_SECTION"}; 
                                this.props.navigation.navigate("SectionDetail", params);
                            }}
                        >
                            <View style={{ flexDirection: "row", paddingLeft: 12, paddingRight: 12, paddingBottom: 12, alignItems: 'center' }}>
                                <View
                                    style={{
                                        borderRadius: 8,
                                        marginLeft: 16,
                                        marginRight: 16
                                    }}
                                >
                                    <IconKoleksiSMA />
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={14}
                                        color={color_text}
                                        fontWeight={"600"}
                                    >
                                        Koleksi SMA
                                    </Typography>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={12}
                                        color={sub_text}
                                        style={{ marginTop: '4px' }}
                                    >
                                        Koleksi buku-buku SMA
                                    </Typography>
                                    <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px'}}/>


                                </View>
                            </View>
                        </CardActionArea> */}

                    </View>

                }


            </View>
        )
    }

}

const styles = {
    media: {
        height: 0,
        paddingTop: '56.25%' // 16:9
    },
    card: {
        position: 'relative',
        margin: '16px',
        borderRadius: '15px'
    },
    overlay: {
        position: 'absolute',
        bottom: '39px',
        left: '20px',
        color: 'white',
        fontSize: '18px'
    },
    overlaySub: {
        position: 'absolute',
        bottom: '12px',
        left: '20px',
        color: 'white',
        fontSize: '12px'
    }
}

export default MenuKoleksiSekolah;