import React, { Component } from 'react';
import { StyleSheet, View, ScrollView, Dimensions, Image, Alert, Platform } from 'react-native';
import { primary, color_text } from '../constants/colors';
import { Typography, Button } from '@mui/material';
import LogoLogin from '../assets/images/LogoLogin';
import * as Identifier from "../constants/PageIdentifiers";
import * as Command from '../constants/Commands';
import TextBox from '../components/TextBox';
import PasswordBox from '../components/PasswordBox';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/700.css';
import { code_ok, code_empty, code_error, code_auth_error, userSignIn } from '../constants/api';
import { executeGet, executePost, createAuthHeader } from '../utilities/Utilities';
import LoadingPage from '../components/LoadingPage';
import AlertDialog from '../components/AlertDialog';

const width = Dimensions.get("window").width;
var _is_mounted = false;
class UpdatePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            errorEmail: false,
            errorEmailMessage: "",
            errorPassword: false,
            errorPasswordMessage: "",
            isSubmitting: false,
            progressText: "",
            showAlert: false,
            alertMessage: ""

        }

        this.handleAlertClose = this.handleAlertClose.bind(this);
    }

    componentDidMount = () => {
        _is_mounted = true;
        localStorage.clear();
        document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        if (global.os == "ANDROID") {
            JSBridge.launchFunction(Command.SET_PAGE, Identifier.LOGIN);
        }
        window.addEventListener("message", (event) => {
            if (event.data.id == "go_back") {
                try {
                    if (global.os == "ANDROID") {
                        JSBridge.launchFunction("CLOSE_APP", "-")
                    }
                } catch (error) {
                    console.log("error")
                }
            }

        })
        console.log("UPDATE PAGE MOUNTED");
        //JSBridge.launchFunction(Command.SET_PAGE, Identifier.LOGIN);
    }
    componentWillUnmount = () => {
        _is_mounted = false;
        window.removeEventListener("message", null);
        //console.log("LOGIN PAGE UNMOUNTED");
    }

    handleAlertClose = () => {
        this.setState({
            showAlert: false
        })
    }

    render() {

        return (
            <View style={{ flex: 1 }}>
                {
                    !this.state.isSubmitting &&
                    <View style={styles.container}>
                        <ScrollView>
                            <View style={{alignContent: 'center'}}>                            
                                <View style={styles.caption_container}>
                                    <View style={{ marginTop: 100 }}>
                                        {/* <LogoLogin
                                            width={260}
                                            height={270}
                                        /> */}
                                        <Image 
                                            source={require("../assets/images/update.png")}
                                            style={{
                                                width : 100,
                                                height : 100
                                            }}
                                        />
                                    </View>
                                </View>
                                <View style={styles.form_container}>
                                
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={22}
                                        color={'#184999'}
                                        fontWeight={"600"}
                                        style={{ marginTop: 10}}
                                    >
                                        Perbarui aplikasi 
                                    </Typography>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={22}
                                        color={'#184999'}
                                        fontWeight={"600"}
                                        style={{ marginBottom: 10}}
                                    >
                                        Anda ke versi terbaru
                                    </Typography>
                                    
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={16}
                                        color={'#184999'}
                                        fontWeight={"400"}
                                    >
                                        Silahkan update aplikasi pada Play Store Anda untuk penglaman yang lebih nyaman dan menyenangkan
                                    </Typography>
                                </View>
                            </View>

                        </ScrollView>
                        <Button
                                variant="contained"
                                style={{ marginBottom: 16, marginLeft: 16, marginRight: 16, textTransform: "none", borderRadius: 8, padding: 10 }}
                                onClick={() => { window.location.href = "https://play.google.com/store/apps/details?id=id.kubuku.bintangpusnas&hl=en-ID" }}
                            >
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={16}
                                    fontWeight="500"
                                >
                                    Update Aplikasi
                                </Typography>
                            </Button>
                    </View>
                }
                {
                    this.state.isSubmitting &&
                    <LoadingPage message={this.state.progressText} />
                }
                <AlertDialog
                    showing={this.state.showAlert}
                    title={"Perhatian!"}
                    message={this.state.alertMessage}
                    onClose={this.handleAlertClose}
                    onClick={this.handleAlertClose}
                    buttonCaption={"OK"}
                />

            </View>

        )
    }

}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "white",
        padding: 10
    },
    headerContainer: {
        alignItems: "center"
    },
    header: {
        width: width,
        backgroundColor: primary,
        borderRadius: width / 2,
        height: width,
        position: "absolute",
        top: (width / 2)
    },
    caption_container: {
        fontSize: 14,
        marginLeft: 16,
        marginTop: 50
        // alignItems: "center"
    },
    logo: {
        width: 275,
        height: 226,
        marginTop: 64
    },
    form_container: {
        alignItems: "left",
        marginLeft: 16,
        marginRight: 16,
        // marginTop: 57
    },



})



export default UpdatePage; 