import React, { Component } from 'react';
import { StyleSheet, View, FlatList, Image, Dimensions } from 'react-native';
import { code_ok, code_error, code_auth_error, code_empty, homepageRecommended, checkJWT } from '../../constants/api';
import { Skeleton, Typography, Card, CardActionArea, CardMedia, CardContent, Button } from '@mui/material';
import { executeGet, executePost, truncateText, navigateContentDetail } from '../../utilities/Utilities';
import AlertDialog from '../AlertDialog';
import { color_text, sub_text, primary } from '../../constants/colors';
import * as Identifier from '../../constants/PageIdentifiers';
import { IconKoleksiSD, IconMeet, IconMessenger } from '../../assets/Icons';
import LoadingDialog from '../LoadingDialog';

class MenuSaranaKomunikasiDesktop extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            showAlert: false,
            showLoadingDialog: false,
            loadingDialogMessage: "Loading",
            alertMessage: "",
            loadingMessage: "",
            code: this.props.code,
            data: [],
            dummy_data: [
                { i: 0 }, { i: 1 }, { i: 2 }
            ]

        }

        this.handleAlertClose = this.handleAlertClose.bind(this);
        this.handleMoreClick = this.handleMoreClick.bind(this);
        this.fetchContents = this.fetchContents.bind(this);
        // this.renderItem = this.renderItem.bind(this);
        this.handleImageError = this.handleImageError.bind(this);
        this.contentDetail = this.contentDetail.bind(this);
        this.checkJwtBefore = this.checkJwtBefore.bind(this);
    }

    componentDidMount = () => {
        this.fetchContents();
    }

    handleAlertClose = () => {

    }
    handleMoreClick = (code, title) => {
        this.props.navigation.navigate("SectionDetail", { identifier: Identifier.SECTION_DETAIL, code: code, title: title, origins: "CONTENT_RECOMMENDED" });
    }

    fetchContents = async () => {

        let formData = new FormData();
        formData.append("page", 1);
        formData.append("homepage_code", this.props.code)
        formData.append("limit", 3);

        const response = await executePost(homepageRecommended, formData);
        if (response.code == code_ok) {
            console.log(JSON.stringify(response));
            console.log(JSON.stringify(this.state.data));
            this.setState({
                showLoading: false,
                // data : response.data
            })
        } else {
            if (response.code != code_auth_error) {
                this.setState({
                    showLoading: false,
                    alertMessage: response.msg,
                    showAlert: true
                })
            } else {
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            }
        }
    }

    contentDetail = (item, index) => {
        let params = {
            previous: Identifier.MAIN,
            parameters: {},
            content_code: item.content_code
        }
        navigateContentDetail(this.props.navigation, params);
    }
    handleImageError = (item, index) => {
        let data = [...this.state.data];
        let obj = { ...data[index] };
        obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
        data[index] = obj;
        this.setState({
            data: data
        })
    }

    checkJwtBefore = async () => {
        // let formData = new FormData(); 
        // formData.append("code", this.props.route.params.content_code); 
        // this.executeReadContent(formData); 

        this.setState({
            showLoadingDialog : true,
            loadingDialogMessage : "Mohon Menunggu"
        });
        let url = checkJWT;
        let response = await executeGet(url);
        console.log("checkJWT = " + JSON.stringify(response))
        if (response.code == code_ok) {
            window.location.href = "https://bintangpusnas.perpusnas.go.id/web/chat?platform=" + global.platform + "&os=" + global.os + "&token=" + global.token
            // let uri = "https://bintangpusnas.perpusnas.go.id/web/chat?platform=" + global.platform + "&mtoken=" + global.token;
            // this.props.navigation.navigate("WebViewerP3smpt", uri);
        } else {
            window.location.href = "https://bintangpusnas.perpusnas.go.id/web/office/error?platform=" + "&os=" + global.os + global.platform + "&token=" + global.token
            // let uri = "https://bintangpusnas.perpusnas.go.id/web/office/error?platform=" + global.platform + "&token=" + global.token;
            // this.props.navigation.navigate("WebViewerP3smpt", uri);

            // this.setState({
            //     showLoadingDialog: false,
            //     showAlert: true,
            //     alertMessage: "Anda tidak dapat mengakses halaman ini"
            // })
        }
    }

    render() {
        return (
            <View style={{ width: "100%", paddingLeft: 147, paddingRight: 147 }}>
                <LoadingDialog
                    open={ this.state.showLoadingDialog }
                    message={ this.state.loadingMessage }
                />
                {
                    this.state.showLoading &&
                    <View>
                        <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: '2rem', maxWidth: 120 }}
                            style={{ marginLeft: 16, marginBottom: 8, marginRight: 16, marginTop: 16 }}
                        />
                        <Skeleton
                            variant="rectangular"
                            style={{ height: 90, marginTop: 8, borderRadius: 8, marginLeft: 16, marginRight: 16 }}
                            animation="wave"
                        />
                        <Skeleton
                            variant="rectangular"
                            style={{ height: 90, marginTop: 8, borderRadius: 8, marginLeft: 16, marginRight: 16 }}
                            animation="wave"
                        />
                        <Skeleton
                            variant="rectangular"
                            style={{ height: 90, marginTop: 8, borderRadius: 8, marginLeft: 16, marginRight: 16 }}
                            animation="wave"
                        />
                    </View>
                }
                {
                    !this.state.showLoading &&
                    <View style={{ marginTop: 30 }}>
                        <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 20 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={28}
                                variant="h3"
                                style={{ flex: 1, marginLeft: 10 }}
                                color={color_text}
                                fontWeight={"600"}
                            >
                                {truncateText(this.props.title, 30)}
                            </Typography>
                        </View>
                        <View style={{
                            // Try setting `flexDirection` to `"row"`.
                            flex: 1,
                            flexDirection: "row"
                        }}>
                            <View style={{ flex: 1 }} >
                                <CardActionArea sx={{ cursor: "pointer" }}
                                    onClick={() => this.checkJwtBefore()}
                                >
                                    <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}>
                                        <View
                                            style={{
                                                borderRadius: 8,
                                                marginLeft: 16,
                                                marginRight: 30
                                            }}
                                        >
                                            <IconMessenger width={80} height={80} />
                                        </View>
                                        <View style={{ flex: 1 }}>
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={20}
                                                color={color_text}
                                                fontWeight={"600"}
                                            >
                                                Messenger
                                            </Typography>
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={16}
                                                color={sub_text}
                                                style={{ marginTop: '4px', paddingRight: 8 }}
                                            >
                                                Sarana untuk komunikasi dan koordinasi Perpustakaan Nasional Republik Indonesia
                                            </Typography>
                                            <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px' }} />
                                        </View>
                                    </View>
                                    
                                </CardActionArea>
                            </View>
                            <View style={{ flex: 1 }} >
                                <CardActionArea sx={{ cursor: "pointer" }}
                                    onClick={() => { 
                                        // let uri = "https://bintangpusnas.perpusnas.go.id/web/office/live-chat-video?platform=" + global.platform + "&token=" + global.token;
                                        // this.props.navigation.navigate("WebViewerP3smpt", uri);
                                        window.location.href = "https://bintangpusnas.perpusnas.go.id/web/office/live-chat-video?platform=" + global.platform + "&os=" + global.os + "&token=" + global.token 
                                    }}
                                >
                                    <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}>
                                        <View
                                            style={{
                                                borderRadius: 8,
                                                marginLeft: 16,
                                                marginRight: 30
                                            }}
                                        >
                                            <IconMeet width={80} height={80} />
                                        </View>
                                        <View style={{ flex: 1 }}>
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={20}
                                                color={color_text}
                                                fontWeight={"600"}
                                            >
                                                Meet
                                            </Typography>
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={16}
                                                color={sub_text}
                                                style={{ marginTop: '4px', paddingRight: 8 }}
                                            >
                                                Sarana untuk komunikasi dan koordinasi Perpustakaan Nasional Republik Indonesia
                                            </Typography>
                                            <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px' }} />
                                        </View>
                                    </View>                                    
                                </CardActionArea>
                            </View>
                        </View>



                    </View>

                }


            </View>
        )
    }

}

const styles = {
    media: {
        height: 0,
        paddingTop: '56.25%' // 16:9
    },
    card: {
        position: 'relative',
        margin: '16px',
        borderRadius: '15px'
    },
    overlay: {
        position: 'absolute',
        bottom: '39px',
        left: '20px',
        color: 'white',
        fontSize: '18px'
    },
    overlaySub: {
        position: 'absolute',
        bottom: '12px',
        left: '20px',
        color: 'white',
        fontSize: '12px'
    }
}

export default MenuSaranaKomunikasiDesktop;