import React, { Component } from 'react'; 
import { View, StyleSheet, Image } from 'react-native';
import { Typography } from '@mui/material';
import { EmptySearchPlaceholder } from '../assets/Placeholders';

class EmptySearchPage extends Component {
    constructor(props){
        super(props)
        this.state = {
            showLoading : false, 
            loadingMessage : "", 
            showAlert : false, 
            alertMessage : "" 
        }
    }

    render(){
        return(
            <View style={{flex : 1, alignItems : "center", }}>
                <View style={{ marginTop : 0 , height : "100%", alignItems : "center", justifyContent : "center"}}>
                    <EmptySearchPlaceholder />
                    <Typography
                        fontFamily="Open Sans" 
                        fontSize={16} 
                        fontWeight="600"
                    >
                        { this.props.title }
                    </Typography>
                    
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={14}
                        fontWeight="500"
                        align="center"
                        style={{ marginLeft : 16, marginRight : 16 }}
                    >
                        { this.props.message }
                    </Typography>
                    
                </View>
            </View>
        )
    }
}

export default EmptySearchPage; 
