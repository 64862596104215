import React, { Component } from 'react';
import { View, StyleSheet, Dimensions, Image } from 'react-native';
import LoadingDialog from '../components/LoadingDialog';
import { executeGet, executePost, isEven, truncateText, navigateContentDetail, isEvenThree, isEvenThreeLast } from '../utilities/Utilities';
import { code_ok, code_error, code_empty, code_auth_error, getContent, closeBook } from '../constants/api';
import { Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import { color_text, sub_text, primary } from '../constants/colors';
import HeaderLogo from '../assets/HeaderLogo';
import Plyr from "plyr-react";
import "plyr-react/plyr.css"
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

var _is_mounted = false;
class ReaderPlayerDesktop extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoadingDialog: false,
            loadingDialogMessage: "Harap Tunggu",
            id_baca: "",
            id_baca_mitra: "",
            token_baca: "",
            blobUrl: "",
            id_konten: "",
            kode_konten: "",
            lastPage: "1",
            jumlahLagu: 1,
            nomorLagu: 1,
            sumberAudio: "",
            mode: "-",
            showCitationDialog: false,
            citationData: {},
            voices: [],
            currentVoice: {},
            last_page_index: 0,
            keyword: "",
            videoSrc: {
                type: "audio",
                sources: [
                    {
                        src: " https://bintangpusnas.perpusnas.go.id/api/konten_pnri/audiobook/" + this.props.route.params.nama_file
                    }
                ]
            },
            track: this.props.route.params.first_page,
            playlist: this.props.route.params.play_index == '0' ? this.props.route.params.playlist : this.props.route.params.playlist[this.props.route.params.play_index],
            currentTrack: this.props.route.params.play_index,
        }

        //this.fetchContent = this.fetchContent.bind(this); 
        this.onLoadIframe = this.onLoadIframe.bind(this);
        this.iframeRef = React.createRef();
        this.executeCloseBook = this.executeCloseBook.bind(this);

        this.updateVoices = this.updateVoices.bind(this);
        this.speakUp = this.speakUp.bind(this);
        this.goBack = this.goBack.bind(this);

        this.synth = React.createRef();


    }

    updateVoices = () => {
        this.setState({
            voices: this.synth.current.getVoices()
        }, () => {

        })
    }

    componentDidMount = () => {
        console.log("READER PLAYER DESKTOP");
        if (this.props.route.params.type == 'Video') {
            this.setState({
                videoSrc: {
                    type: "video",
                    sources: [
                        {
                            src: " https://bintangpusnas.perpusnas.go.id/api/konten_pnri/audiobook/" + this.props.route.params.playlist[0].multimedia_file // + ".mp4"
                        }
                    ]
                }
            })
        } else {
            if (this.props.route.params.local == "musik_keluarga") {
                console.log('playlist_isi = ' + this.props.route.params.nama_file)
                this.setState({
                    sumberAudio: "https://kubuku.id/multimedia/" + this.props.route.params.nama_file,
                    jumlahLagu: 1,
                    nomorLagu: 1
                });
            } else {
                if (this.props.route.params.playlist.length == undefined) {
                    this.setState({
                        sumberAudio: " https://bintangpusnas.perpusnas.go.id/api/konten_pnri/audiobook/" + this.props.route.params.nama_file,
                        jumlahLagu: 1,
                        nomorLagu: 1
                    });
                } else {
                    this.setState({
                        sumberAudio: " https://bintangpusnas.perpusnas.go.id/api/konten_pnri/audiobook/" + this.props.route.params.playlist[0].multimedia_file,
                        jumlahLagu: this.props.route.params.playlist.length,
                        nomorLagu: 1
                    });
                }
            }
        }

        _is_mounted = true;
        this.synth.current = window.speechSynthesis;
        this.synth.current.onvoiceschanged = this.updateVoices;

        this.setState({
            id_baca: this.props.route.params.id_baca,
            id_baca_mitra: this.props.route.params.id_baca_mitra,
            token_baca: this.props.route.params.token,
            id_konten: this.props.route.params.id_konten,
            kode_konten: this.props.route.params.kode_konten,
            mode: this.props.route.params.mode,
            main_konten: this.props.route.params.nama_file,
            voices: this.synth.current.getVoices(),
            currentVoice: this.synth.current.getVoices()[6]
        }, () => {
            //this.fetchContent();
        })

        window.addEventListener("message", (event) => {
            if (event.data.hasOwnProperty("command")) {
                if (event.data.command == "close") {
                    let page = event.data.page;
                    this.setState({
                        lastPage: page
                    }, () => {
                        if (this.state.mode == "read") {
                            this.executeCloseBook();
                        } else {
                            this.props.navigation.goBack();
                        }
                    })
                } else if (event.data.command == "cite") {
                    let data = event.data.data;
                    this.setState({
                        citationData: data,
                        showCitationDialog: true
                    })
                } else if (event.data.command == "log") {
                    console.log(event.data.message);
                } else if (event.data.command == "add_highlight") {
                    this.setState({
                        showHighlightDialog: true
                    })
                } else if (event.data.command == "speak") {
                    console.log("SPEAK UP! " + event.data.message);
                    this.speakUp(event.data.message);
                } else if (event.data.command == "close_dialog") {
                    this.setState({
                        showLoadingDialog: false
                    })
                }
            }
        })
    }
    speakUp = (text) => {
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.voice = this.state.voices[6];
        utterance.pitch = 1;
        utterance.rate = 1;
        this.synth.current.speak(utterance);
    }

    componentWillUnmount = () => {
        _is_mounted = false;
        window.removeEventListener("message", null);
        this.synth.current.onvoiceschanged = null;
    }

    handleClickPrevious = () => {
        console.log('click previous = ' + this.state.currentTrack)
        console.log('click previous = ' + this.state.playlist.length)
        this.setState({ currentTrack: this.state.currentTrack > 0 ? this.state.currentTrack - 1 : 0 })
        //   setTrackIndex((currentTrack) =>
        //       currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
        //   );
    };

    handleClickNext = () => {
        console.log('click next')
        this.setState({ currentTrack: this.state.currentTrack < this.state.playlist.length - 1 ? this.state.currentTrack + 1 : 0 })
        //   setTrackIndex((currentTrack) =>
        //       currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
        //   );
    };

    handleEnd = () => {
        console.log('end')
        this.setState({ currentTrack: this.state.currentTrack < this.state.playlist.length - 1 ? this.state.currentTrack + 1 : 0 })
        //   setTrackIndex((currentTrack) =>
        //           currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
        //       );
    }


    //ANCHOR - ONLOAD IFRAME
    //Callback dari iFrame ketika halaman berhasil di LOAD
    onLoadIframe = () => {
        this.setState({
            showLoadingDialog: false,
        }, () => {
            setTimeout(() => {

                let reading_params = {
                    headers: global.defaultHeaders,
                    token: this.props.route.params.token,
                    id_baca: this.props.route.params.id_baca,
                    id_baca_mitra: this.props.route.params.id_baca_mitra,
                    id_konten: this.props.route.params.id_konten,
                    kode_konten: this.props.route.params.kode_konten,
                    mode: this.props.route.params.mode,
                    last_page_index: this.props.route.params.last_page_index,
                    local_content: this.props.route.params.local,
                    keyword: (this.props.route.params.hasOwnProperty("keyword") ? this.props.route.params.keyword : ""),
                    directIndex: (this.props.route.params.hasOwnProperty("directIndex") ? this.props.route.params.directIndex : 0)
                };
                console.log("READING PARAMS");
                console.log("READING PARAMS = " + JSON.stringify(reading_params));

                document.getElementById('readerFrame').contentWindow.postMessage({
                    headers: global.defaultHeaders,
                    token: this.props.route.params.token,
                    id_baca: this.props.route.params.id_baca,
                    id_baca_mitra: this.props.route.params.id_baca_mitra,
                    id_konten: this.props.route.params.id_konten,
                    kode_konten: this.props.route.params.kode_konten,
                    mode: this.props.route.params.mode,
                    last_page_index: this.props.route.params.last_page_index,
                    local_content: this.props.route.params.local,
                    keyword: (this.props.route.params.hasOwnProperty("keyword") ? this.props.route.params.keyword : ""),
                    directIndex: (this.props.route.params.hasOwnProperty("directIndex") ? this.props.route.params.directIndex : 0)
                }, "*");
            }, 2000);

        })
    }

    executeCloseBook = async () => {
        // console.log("ID BACA: " + this.state.id_baca); 
        // console.log("ID BACA MITRA: " + this.state.id_baca_mitra); 
        // console.log("LAST PAGE: " + this.state.lastPage); 

        let formData = new FormData();
        formData.append("id_baca", this.state.id_baca);
        formData.append("id_baca_mitra", this.state.id_baca_mitra);
        formData.append("last_page", this.state.lastPage);
        this.setState({
            showLoadingDialog: true,
            loadingDialogMessage: "Mengembalikan Buku"
        })
        const response = await executePost(closeBook, formData);
        if (response.code == code_ok) {
            this.setState({
                showLoadingDialog: false
            }, () => {
                this.props.navigation.goBack();
            })
        } else {
            this.setState({
                showLoadingDialog: false
            }, () => {
                this.props.navigation.goBack();
            })
        }

    }

    goBack = () => {
        //JSBridge.launchFunction(Command.SET_PAGE, this.props.route.params.identifier );
        this.props.navigation.goBack();
        //this.props.navigation.navigate(this.props.route.params.identifier);
    }

    render() {
        let viewWidth = Dimensions.get("window").width / 2;
        let viewHeight = Dimensions.get("window").height;

        let window_width = Dimensions.get("window").width;
        let book_width = (window_width / 5) - 16;
        let book_height = book_width + 40;
        
        return (
            <View style={styles.container}>
                {
                    this.props.route.params.local == "musik_keluarga" ?
                        <View style={{ flex: 1 }}>
                            <View style={{
                                paddingTop: 16,
                                paddingBottom: 16,
                                marginBottom: 16,
                                flex: 0.05,
                                backgroundColor: "white",
                                flexDirection: "row",
                                alignItems: "center",
                                boxShadow: "0 4px 6px -1px rgba(0,0,0, 0.07)"

                            }}>
                                <IconButton
                                    style={{ marginRight: 16, marginLeft: 16 }}
                                    onClick={() => { this.goBack() }}
                                >
                                    <ChevronLeftIcon
                                        sx={{ color: color_text }}
                                    />
                                </IconButton>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={18}
                                    fontWeight={"600"}
                                >
                                    Musik Keluarga
                                </Typography>
                            </View>
                            <View style={{ flex: 0.95, alignSelf: 'center', padding: 40 }}>

                                <Image
                                    style={{
                                        width: book_width,
                                        height: book_height,
                                        margin: 'auto',
                                        borderRadius: 10
                                    }}
                                    resizeMode={"cover"}
                                    source={{ uri: this.props.route.params.cover }}
                                    onError={(error) => { this.onImageError(item, index) }}
                                />


                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    color={color_text}
                                    fontWeight={"600"}
                                    style={{
                                        marginLeft: 12, marginTop: 8, marginBottom: 8, marginRight: 12, textAlign: 'center'
                                    }}
                                >
                                    {truncateText(this.props.route.params.nama_file, 32)}
                                </Typography>
                                <Typography
                                    fontFamily="Open Sans"
                                    color={color_text}
                                    fontSize={12}
                                    style={{
                                        marginLeft: 12,
                                        marginBottom: 12,
                                        marginRight: 12,
                                        textAlign: 'center'
                                    }}
                                >
                                    {truncateText(this.props.route.params.pencipta, 20)}
                                </Typography>
                                <AudioPlayer
                                    autoPlay
                                    src={"https://kubuku.id/multimedia/" + this.props.route.params.nama_file}
                                    showSkipControls
                                    onClickPrevious={() => { this.handleClickPrevious() }}
                                    onClickNext={() => { this.handleClickNext() }}
                                    onEnded={() => { this.handleEnd() }}
                                // Try other props!
                                />
                            </View>
                        </View>
                        :
                        <>
                            <View style={{ flex: 0.5 }}>
                                <LoadingDialog
                                    open={this.state.showLoadingDialog}
                                    message={this.state.loadingDialogMessage}
                                />
                                {
                                    !this.state.showLoadingDialog &&
                                    <iframe
                                        id="readerFrame"
                                        //src={"https://reader.bintangpusnas.com:3000/?platform=desktop"}
                                        src={"https://bintangpusnas.perpusnas.go.id/web/reader/?platform=mobile"}
                                        width={viewWidth}
                                        height={viewHeight}
                                        frameBorder={0} onLoad={this.onLoadIframe}></iframe>
                                }
                                <Dialog
                                    open={this.state.showCitationDialog}
                                    onClose={() => { this.setState({ showCitationDialog: false }) }}
                                    style={{ width: "100%" }}
                                >
                                    <DialogTitle>
                                        Pilih Style Sitasi
                                    </DialogTitle>
                                    <DialogContent>
                                        <View style={{ flex: 1, flexDirection: "column", alignItems: "center" }}>
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={14}
                                                fontWeight="600"

                                            >
                                                MLA :
                                                <div
                                                    style={{ overflow: "hidden", color: primary }}
                                                    dangerouslySetInnerHTML={{ __html: this.state.citationData.mla }}
                                                />
                                            </Typography>
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={14}
                                                fontWeight="600"
                                            >
                                                Chicago :
                                                <div
                                                    style={{ overflow: "hidden", color: primary }}
                                                    dangerouslySetInnerHTML={{ __html: this.state.citationData.chg }}
                                                />
                                            </Typography>
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={14}
                                                fontWeight="600"
                                            >
                                                Turabian :
                                                <div
                                                    style={{ overflow: "hidden", color: primary }}
                                                    dangerouslySetInnerHTML={{ __html: this.state.citationData.trb }}
                                                />
                                            </Typography>
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={14}
                                                fontWeight="600"
                                            >
                                                APA :
                                                <div
                                                    style={{ overflow: "hidden", color: primary }}
                                                    dangerouslySetInnerHTML={{ __html: this.state.citationData.apa }}
                                                />
                                            </Typography>
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={14}
                                                fontWeight="600"
                                            >
                                                Harvard :
                                                <div
                                                    style={{ overflow: "hidden", color: primary }}
                                                    dangerouslySetInnerHTML={{ __html: this.state.citationData.hrv }}
                                                />
                                            </Typography>
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={14}
                                                fontWeight="600"
                                            >
                                                IEEE :
                                                <div
                                                    style={{ overflow: "hidden", color: primary }}
                                                    dangerouslySetInnerHTML={{ __html: this.state.citationData.ieee }}
                                                />
                                            </Typography>
                                        </View>

                                    </DialogContent>


                                </Dialog>
                            </View>
                            <View style={{ flex: 0.5, alignSelf: 'center' }}>
                                <View style={{ flexDirection: "col", marginBottom: 16 }}>
                                    <View style={{ justifyContent: "center", alignItems: "center" }}>
                                        {this.props.route.params.type == "Audio" &&
                                            <Image
                                                source={{ uri: this.props.route.params.cover }}
                                                style={{
                                                    marginTop: 16,
                                                    borderRadius: 8,
                                                    width: 480,
                                                    height: viewHeight - 150
                                                }}
                                                onError={(error) => { this.onImageError() }}
                                            />
                                        }
                                    </View>
                                    <View style={{ justifyContent: "center", alignItems: "center" }}>
                                        {/* konten */}

                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={18}
                                            fontWeight="600"
                                            style={{ marginTop: 10, marginBottom: 16 }}
                                            color={"primary"}
                                        >
                                            {this.props.route.params.title}
                                        </Typography>
                                        {
                                            this.props.route.params.type == 'Video' ?
                                                <Plyr source={this.state.videoSrc} />
                                                // <></>
                                                :
                                                <AudioPlayer
                                                    autoPlay
                                                    src={this.props.route.params.play_type == 'all' ? " https://bintangpusnas.perpusnas.go.id/api/konten_pnri/audiobook/" + this.state.playlist[this.state.currentTrack].multimedia_file : " https://bintangpusnas.perpusnas.go.id/api/konten_pnri/audiobook/" + this.state.playlist.multimedia_file}
                                                    showSkipControls
                                                    onClickPrevious={() => { this.handleClickPrevious() }}
                                                    onClickNext={() => { this.handleClickNext() }}
                                                    onEnded={() => { this.handleEnd() }}
                                                // Try other props!
                                                />
                                        }
                                    </View>
                                </View>
                            </View>
                        </>


                }

            </View>
        )
    }
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row'
    }
})

export default ReaderPlayerDesktop;