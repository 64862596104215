import React, { Component } from 'react'; 
import { View, StyleSheet , Image, FlatList, Dimensions, ScrollView,TextInput  } from 'react-native'; 
import { Alert, AlertTitle, Typography, Skeleton, Card, CardActionArea , 
    CircularProgress, IconButton, Chip, Radio, RadioGroup, FormControlLabel } from '@mui/material'; 
import LoadingPage from '../components/LoadingPage'; 
import AlertDialog from '../components/AlertDialog'; 
import * as Identifier from '../constants/PageIdentifiers';
import * as Command from '../constants/Commands'; 
import { code_ok, code_empty, code_error, code_auth_error, sectionContent, 
    sectionContentRecommended } from '../constants/api';
import { executeGet, executePost , isEven, truncateText, navigateContentDetail } from '../utilities/Utilities'; 
import  EmptySearchPage  from '../components/EmptySearchPage';
import { color_text, sub_text, primary } from '../constants/colors';
import SortIcon from '@mui/icons-material/Sort';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SortDialog from '../components/SortDialog';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SearchIcon from '../assets/images/SearchIcon';
import { NavSearchIcon } from '../assets/NavIcons';

var _is_mounted = false; 
var window_width;
var book_width;
var book_height;


class DesktopSectionDetail extends Component {
    constructor(props){
        super(props)
        //ANCHOR - STATE LIST
        this.state = {
            showLoading : true, 
            moreLoading : false, 
            loadingMessage : "", 
            showAlert : false,
            alertTitle : "Perhatian", 
            alertMessage : "",
            showWarning : false, 
            warningMessage : "",  
            code : this.props.route.params.code,
            title : this.props.route.params.title,
            data : [], 
            dummy_data : [
                {i : 0}, { i : 1 }, {i : 2 }, { i : 3 }, 
                {i : 0}, { i : 1 }, {i : 2 }, { i : 3 },
                {i : 0}, { i : 1 }, {i : 2 }, { i : 3 }
            ],
            page : 1, 
            total : 0, 
            show : 0, 
            showSortDialog : true,
            sort_type : -1,
            sort_menu : [
                {id : "001", type: 1, title : "Judul A-Z"},{id : "002", type : 2, title : "Judul Z-A"}, 
                {id:"003", type : 3, title : "Penulis A-Z"}, { id : "004", type : 4 , title : "Penulis Z-A"}
            ],
            data_sub_kategori :[] ,
            selected_sub_kategori : "",  
            selected_kode_sub_kategori : "",
            keyword : "",
            searchFocus : false,
            searchType : "umum"
        }
        this.goBack = this.goBack.bind(this); 
        this.renderSkeleton = this.renderSkeleton.bind(this);
        this.renderItem = this.renderItem.bind(this); 
        this.fetchContent = this.fetchContent.bind(this); 
        this.onImageError = this.onImageError.bind(this); 
        this.fetchMoreData = this.fetchMoreData.bind(this); 
        this.handleSortClick = this.handleSortClick.bind(this); 
        this.handleSortMenuClick = this.handleSortMenuClick.bind(this); 
        this.renderSortMenu = this.renderSortMenu.bind(this);
        this.contentDetail = this.contentDetail.bind(this);
        this.renderSubKategori = this.renderSubKategori.bind(this); 
        this.handleSubKategoriClick = this.handleSubKategoriClick.bind(this); 
        this.handleSearchPress = this.handleSearchPress.bind(this); 
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this); 
        this.onSearchInputFocus = this.onSearchInputFocus.bind(this);
        this.onSearchInputBlur = this.onSearchInputBlur.bind(this);
        this.radioGroupRef = React.createRef(); 
        
        
    }

    componentDidMount = ()=>{
        _is_mounted = true;
        this.fetchContent(); 
    }

    componentWillUnmount = ()=>{
        _is_mounted = false;
        window.removeEventListener("message", null); 
    }

    goBack = ()=>{
        //this.props.navigation.navigate(Identifier.MAIN);
        this.props.navigation.goBack(); 
    }

    //ANCHOR - Fetch Content
    fetchContent = async()=>{
        if(_is_mounted){
            if(this.state.page == 1) {
                this.setState({
                    showLoading : true 
                })
            } else {
                this.setState({
                    moreLoading : true 
                })
            }

            let formData = new FormData(); 
            formData.append("page", this.state.page);
            formData.append("limit", 54);
            formData.append("code", this.state.code);
            if(this.state.sort_type != -1) {
                formData.append("sort_by", this.state.sort_type); 
            }   
            let url;
            if(this.props.route.params.origins == "CONTENT_SECTION") {
                url = sectionContent; 
                if(this.state.selected_kode_sub_kategori != "") {
                    formData.append("code_sub_category", this.state.selected_kode_sub_kategori); 
                }
                formData.append("keyword", this.state.keyword); 
                
            } else {
                url = sectionContentRecommended; 
            }
            
            
            const response = await executePost(url, formData); 
            
            if(response.code == code_ok){
                if(parseInt(response.show) > 0 ) {
                    let show = parseInt(response.show ) + parseInt(this.state.show); 
                    let total = parseInt(response.total); 
                    let mod = response.show % 6; 
                    let data = [...this.state.data, ...response.data]; 
                    if(mod > 0 ) {
                        let add_factor = (6 - mod); 
                        for(let i = 0;i < add_factor;i++){
                            //added_data.push({ dummy : i }); 
                            data.push({ dummy : i })
                        }
                    }
                    //let data = [...this.state.data, ...response.data]; 
                    let data_sub_kategori;
                    if(response.hasOwnProperty("sub_category")) {
                        data_sub_kategori = [...response.sub_category]; 
                    } else {
                        data_sub_kategori = [...this.state.data_sub_kategori];
                    }

                    this.setState({
                        total : total, 
                        show : show , 
                        data : data ,
                        moreLoading : false, 
                        showLoading : false,
                        data_sub_kategori : data_sub_kategori

                    })
                } 
                
                if(parseInt(response.total) == 0) {
                    this.setState({
                        showLoading : false, 
                        moreLoading : false, 
                        alertTitle : "Tidak ditemukan", 
                        alertMessage : "Mohon maaf, data dihalaman ini tidak ditemukan"
                    })
                }
            } else {
                if(response.code == code_auth_error){
                    // if(global.os == "ANDROID"){
                    //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                    // } else {
                    //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                    // }
                    window.postMessage({ id: "sign_out"});
                } else {
                    this.setState({
                        showLoading : false, 
                        showAlert : true, 
                        alertMessage : response.msg
                    })
                }
            }

        }
    }
    //ANCHOR - Render Skeleton
    renderSkeleton = ({item, index, separators})=>{
        
        return(
            <View
                style={{ 
                    width : book_width, 
                    borderRadius : 8, 
                    marginBottom : 16                       
                }}
            >
                <Skeleton 
                    style={{
                        borderRadius : 8 
                    }}
                    width={ book_width }
                    height={ book_height }
                    variant="rectangular"
                    animation="wave"
                />

                <Skeleton 
                    variant="text"
                    animation="wave"
                    sx={{ fontSize : "1.5rem"}}
                    style={{ 
                        marginLeft : 12, marginTop : 8, marginRight : 12 
                    }}
                />
                <Skeleton 
                    variant="text"
                    animation="wave"
                    sx={{ fontSize : "1.5rem"}}
                    style={{
                        marginLeft : 12 ,
                        marginBottom : 12, 
                        marginRight : 12
                    }}
                />
            </View>
        )
    }

    //ANCHOR - Render Item
    renderItem = ({item, index, separators})=>{
        let book_width_new = (window_width / 6) - 16
        let book_height_new = book_width_new + 60
        if(!item.hasOwnProperty("dummy")) {
            return(
                <Card
                style={{
                    width: '95%',
                    borderRadius: 8,
                    margin: '10px'
                }}
            >
                <CardActionArea
                    sx={{ height: "100%" }}
                >
                    <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}>
                        <View
                            style={{
                                borderRadius: 8,
                                marginLeft: 16,
                                marginRight: 16
                            }}
                        >
                            {/* <IconBpEdukasi /> */}
                            <Image
                                style={{
                                    width: book_width_new,
                                    height: book_height_new,
                                }}
                                resizeMode={"cover"}
                                source={{ uri: item.cover }}
                                onError={(error) => { this.onImageError(item, index) }}
                            />
                        </View>
                        <View style={{ flex: 1 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={18}
                                color={color_text}
                                fontWeight={"600"}
                            >
                                Judul : {truncateText(item.title, 200)}
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={16}
                                color={sub_text}
                                style={{ marginTop: '4px', marginRight: 30 }}
                            >
                                Kategori : {truncateText(item.category, 20)}
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={16}
                                color={sub_text}
                                style={{ marginTop: '4px', marginRight: 30 }}
                            >
                                Penerbit : {truncateText(item.publisher, 20)}
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={16}
                                color={sub_text}
                                style={{ marginTop: '4px', marginRight: 30 }}
                            >
                                Penulis : {truncateText(item.author, 20)}
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={16}
                                color={sub_text}
                                style={{ marginTop: '4px', marginRight: 30 }}
                            >
                                Tahun : {truncateText(item.year, 20)}
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={16}
                                color={sub_text}
                                style={{ marginTop: '4px', marginRight: 30 }}
                            >
                                ISBN : {truncateText(item.isbn, 20)}
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={16}
                                color={sub_text}
                                style={{ marginTop: '4px', marginRight: 30 }}
                            >
                                EISBN : {truncateText(item.eisbn, 20)}
                            </Typography>
                            {/* <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px' }} /> */}


                        </View>
                    </View>
                </CardActionArea>
            </Card>
            )
        } else {
            return(
                <View
                    style={{ 
                        width : book_width, 
                        height : book_height + 100,
                        borderRadius : 8, 
                        marginBottom : 8,
                    }}
                >
                </View>
            )
        }
    }
    //ANCHOR - Content Detail
    contentDetail = (item,index)=>{
        let params = {
            previous : Identifier.SECTION_DETAIL, 
            parameters : this.props.route.params, 
            content_code : item.code
        }
        navigateContentDetail(this.props.navigation, params); 
    }
    //ANCHOR - On Image Error
    onImageError = (item, index)=>{
        if(_is_mounted){
            let data = [...this.state.data]; 
            let obj = { ...data[index]}; 
            obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
            data[index] = obj; 
            this.setState({
                data : data
            })
        }
    }
    //ANCHOR - Fetch More Data 
    fetchMoreData = ()=>{
        if(_is_mounted && !this.state.showLoading && !this.state.moreLoading){
            if(this.state.show < this.state.total ) {
                let page = parseInt(this.state.page) + 1; 
                this.setState({
                    page : page
                }, ()=>{
                    this.fetchContent(); 
                })

            }
        }
    }
    handleSortMenuClick = ()=>{
        if(_is_mounted){
            if(this.state.showSortDialog){
                this.setState({
                    showSortDialog : false
                })
            } else {
                this.setState({
                    showSortDialog : true
                })
            }
        }
        
    }

    handleSortClick = (variant)=>{
        if(_is_mounted){
            this.setState({
                sort_type : variant,
                page : 1, 
                total : 0, 
                show : 0,
                data : [],
            },()=>{
                this.fetchContent();
            }); 
        }
    }
    
    renderSortMenu = ({ item, index, separators})=>{
        return(
            <Chip 
                style={{ 
                    marginRight : (index == 3 ? 16 : 8) ,
                    marginLeft : (index == 0 ? 16 : 0)
                }}
                variant={ this.state.sort_type == item.type ? "contained" : "outlined"} 
                label={ item.title } 
                onClick={()=>{ this.handleSortClick(item.type )}}
            />
        )
    }

    renderSubKategori = ({item, index, separators})=>{
        return(
            <Chip 
                style={{ 
                    marginRight : (index == (this.state.data_sub_kategori.length - 1) ? 16 : 8) ,
                    marginLeft : (index == 0 ? 16 : 0)
                }}
                variant={ this.state.selected_kode_sub_kategori == item.code ? "contained" : "outlined"} 
                label={ item.title } 
                onClick={()=>{ this.handleSubKategoriClick(item)}}
            />
        )
    }
    //ANCHOR - HAndle Sub Kategori Click
    handleSubKategoriClick = (item)=>{
        var kode_sub_kategori = ""; 
        var sub_kategori = ""; 
        if(item.code != this.state.selected_kode_sub_kategori){
            kode_sub_kategori = item.code; 
            sub_kategori = item.title; 
        }
        this.setState({
            selected_sub_kategori : sub_kategori, 
            selected_kode_sub_kategori : kode_sub_kategori,
            page : 1, 
            data : [],
            show : 0, 
            total : 0,

        },()=>{
            //FETCH SUB KATEGORI
            this.fetchContent();

        })
    }

    //ANCHOR - Handle Search Text Changed
    handleSearchTextChange = (text)=>{
        if(_is_mounted){
            this.setState({
                keyword : text
            })
        }
    }
    //ANCHOR - Handle Search
    handleSearchPress = (e)=>{
        let key = e.nativeEvent.key; 
        if(key == "Enter"){
            if(this.state.searchType == "umum") {
                this.setState({
                    page : 1 , 
                    data : [], 
                    show : 0, 
                    total : 0,
                }, ()=>{
                    this.fetchContent();
                });
            } else {
                if(this.state.keyword.length > 5 ) {
                    let params = {
                        code : this.props.route.params.code,
                        title : this.props.route.params.title,
                        selected_kode_sub_kategori : this.state.selected_kode_sub_kategori,
                        keyword : this.state.keyword,
                        origins : "CONTENT_SECTION"
                    }
                    this.props.navigation.navigate("DesktopSearchKalimat", params); 
                } else {
                    this.setState({
                        showWarning : true, 
                        warningMessage : "Kalimat yang dicari minimal lebih dari 5 huruf"
                    })
                }
            }
        }
    }

    onSearchInputFocus = (e)=>{
        this.setState({
             searchFocus : true
        })
    }
    onSearchInputBlur = ()=>{
         this.setState({
             searchFocus : false
        })
    }

    
    render(){
        window_width = Dimensions.get("window").width; 
        book_width = (window_width / 6) - 16 ; 
        book_height = book_width + 60;
        
        return(
            <View style={{ flex : 1 }}>
                <View style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    flex : 0.05,
                    backgroundColor : "white",
                    flexDirection : "row", 
                    alignItems : "center",
                    boxShadow: "0 4px 6px -1px rgba(0,0,0, 0.07)"
                }}>
                    
                    <IconButton
                        style={{ marginRight: 16, marginLeft: 16 }}
                        onClick={()=>{ this.goBack() }}
                    >
                        <ChevronLeftIcon 
                            sx={{color : color_text }}
                        />
                    </IconButton>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 18 }
                        fontWeight="600"
                        style={{ flex : 0.7}}
                    >
                        { this.props.route.params.title }
                    </Typography>
                    {
                        //ANCHOR - Search bar
                    }
                    <View style={ [styles.searchBar,{
                        borderColor : (this.state.searchFocus ? primary : "#F2F2F2"),
                        marginRight : 16
                    }]}>
                        <View style={{ flex : 0.05}}>
                            <NavSearchIcon
                                width={ 18 }
                                height={ 18 } 
                                fillLogo={ (this.state.searchFocus ? primary : "#999999") }
                            />
                        </View>
                        <TextInput 
                            style={styles.searchInput }
                            onFocus={this.onSearchInputFocus }
                            onBlur={ this.onSearchInputBlur }
                            placeholder={"Pencarian"}
                            onKeyPress={ this.handleSearchPress }
                            onChangeText={ this.handleSearchTextChange }
                        />
                    </View>
                    <RadioGroup 
                        row
                        onChange={(e, value)=>{ this.setState({ searchType : value})}}
                    >
                        <FormControlLabel value="umum" control={<Radio checked={ this.state.searchType == "umum"}/>} label="Umum" />
                        <FormControlLabel value="kalimat" control={<Radio checked={ this.state.searchType == "kalimat"}/>} label="Kalimat" />

                    </RadioGroup>
                    <IconButton
                        style={{
                            marginRight : 16 ,
                            marginLeft : 16 
                        }}
                        onClick={ this.handleSortMenuClick }
                    >
                        {
                            this.state.showSortDialog &&
                            <ExpandLessIcon />
                        }
                        {
                            !this.state.showSortDialog && 
                            <ExpandMoreIcon />
                        }
                        
                    </IconButton>
                </View>
                <View style={{ flex : 0.95}}>
                    {
                        (!this.state.showLoading && this.state.showSortDialog) && 
                        <View style={{paddingBottom: 16, marginBottom: 16, backgroundColor: 'white', boxShadow: "0 4px 6px -1px rgba(0,0,0, 0.07)"}}>
                            <FlatList 
                                horizontal={ true }
                                keyExtractor={(k, l)=>"index_"+k.id}
                                data={ this.state.sort_menu }
                                renderItem={ this.renderSortMenu }
                                showsHorizontalScrollIndicator={ false }
                                style={{ marginBottom : 16 }}
                            />
                            {
                                this.state.data_sub_kategori.length > 0 && 
                                <FlatList 
                                    horizontal={ true }
                                    keyExtractor={(j,k)=>"sub_kat_"+ j.code }
                                    data={ this.state.data_sub_kategori } 
                                    renderItem={ this.renderSubKategori } 
                                    showsHorizontalScrollIndicator={ false }
                                />
                            }
                        </View>
                    }
                    {
                        (this.state.data.length == 0 && !this.state.showLoading ) && 
                        <EmptySearchPage 
                            title={ this.state.alertTitle }
                            message={ this.state.alertMessage }
                        />
                        
                    }
                    {
                        this.state.showLoading && 
                        <FlatList 
                            keyExtractor={(i, x)=>"index_"+x}
                            data={ this.state.dummy_data } 
                            style={{marginLeft : 16 , marginRight : 16 }}
                            renderItem={ this.renderSkeleton }
                            numColumns={ 6 }
                            columnWrapperStyle={{justifyContent: 'space-between'}} 
                        />
                        
                    }
                    {
                        (!this.state.showLoading) && 
                        <FlatList 
                            keyExtractor={(item , index)=>"item_index_"+index}
                            data={ this.state.data }
                            renderItem={ this.renderItem }
                            style={{marginLeft : 16 , marginRight : 16 }}
                            numColumns={ 2 }
                            onEndReachedThreshold={0.5}
                            onEndReached={this.fetchMoreData }
                            ListFooterComponent={
                                this.state.moreLoading && 
                                <View style={{alignItems : "center", padding : 8 }}>
                                    <CircularProgress 
                                        size={ 32 }
                                    />
                                </View>
                            }
                        />
                        
                    }
                </View>
                {
                    this.state.showAlert && 
                    <Alert 
                        onClose={()=>{ this.goBack() }}
                        severity="error"
                    > 
                        <AlertTitle>Error</AlertTitle>
                        { this.state.alertMessage }
                    </Alert>
                }
                {
                    this.state.showWarning && 
                    <Alert 
                        onClose={()=>{ this.setState({ showWarning : false })}}
                        severity="warning"
                    >
                        <AlertTitle>Perhatian</AlertTitle>
                        { this.state.warningMessage }
                    </Alert>

                }
                

            </View>

        )
    }
}

const styles = StyleSheet.create({
    searchBar : {
        backgroundColor : "#F9FAFC", 
        borderRadius : 8, 
        borderWidth : 0.2,
        padding : 8, 
        flexDirection : "row",
        flex : 0.3
    },
    searchInput : {
        fontFamily : "Open Sans", 
        fontWeight : "400",
        color : color_text,
        fontSize : 14,
        flex : 0.95, 
        backgroundColor : "#F9FAFC",
        outlineColor : "#F9FAFC",
        marginLeft : 18
    },
})

export default DesktopSectionDetail; 