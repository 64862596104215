import React, { Component } from 'react';
import { View, StyleSheet, FlatList, Image, Pressable } from 'react-native';
import {
    Alert, AlertTitle, Card, CardActionArea, CardContent, Typography, Skeleton,
    CardActions, Button, IconButton,
    Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { code_ok, code_empty, code_error, code_auth_error, borrowingList, bookReturn, readBorrowedContent, stockCheck, borrow } from '../constants/api';
import { color_text, sub_text } from '../constants/colors';
import * as Identifier from '../constants/PageIdentifiers';
import * as Command from '../constants/Commands';
import { executeGet, executePost } from '../utilities/Utilities';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LoadingDialog from '../components/LoadingDialog';

var _is_mounted = false;
class MyBookRack extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            showLoadingDialog: false,
            loadingDialogMessage: "",
            showAlert: false,
            moreLoading: false,
            loadingMessage: "",
            alertMessage: "",
            data: [],
            data_history: [],
            dummy_data: [
                { i: 0 }, { i: 1 }, { i: 2 }, { i: 3 }, { i: 4 }, { i: 5 }, { i: 6 }, { i: 7 }
            ],
            flagHistory: true,
            showBorrowDialog: false,
            showBorrowSuccess: false,
            successMessage: "",
            data_offline: "",
            flagShowNotifOffline: false
        }

        this.goBack = this.goBack.bind(this);
        this.renderSkeleton = this.renderSkeleton.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.fetchContents = this.fetchContents.bind(this);
        this.handleImageError = this.handleImageError.bind(this);
        this.executeReturn = this.executeReturn.bind(this);
        this.handleReadFromRack = this.handleReadFromRack.bind(this);
        this.readFromRack = this.readFromRack.bind(this);
        this.onTabBorrowPresent = this.onTabBorrowPresent.bind(this)
        this.onTabBorrowHistory = this.onTabBorrowHistory.bind(this)

        this.handleBorrowClick = this.handleBorrowClick.bind(this);
        this.handleBorrowConfirmationClose = this.handleBorrowConfirmationClose.bind(this);
        this.handleConfirmationClick = this.handleConfirmationClick.bind(this);

        this.executeLoan = this.executeLoan.bind(this);
    }

    componentDidMount = () => {
        _is_mounted = true;
        if (global.os == "ANDROID") {
            JSBridge.launchFunction(Command.SET_PAGE, Identifier.BOOK_RACK);
            window.addEventListener("message", (event) => {
                if (event.data.id == "rack_offline") {
                    console.log('Rack Offline')
                    console.log('[KUBUKU PWA] = ' + event.data.id)
                    console.log('[KUBUKU PWA] = ' + event.data.data)
                    // console.log('[KUBUKU PWA] = ' + this.state.data_offline.length)
                    if (event.data.data.length != 0) {
                        this.setState({ flagShowNotifOffline: true, data_offline: JSON.parse(event.data.data) })
                    } else {
                        this.setState({ flagShowNotifOffline: false })
                    }
                }
            });
        }
        window.addEventListener("message", (event) => {
            if (event.data.id == "go_back") {
                if (event.data.page == Identifier.BOOK_RACK) {
                    this.goBack();
                }
            }
            // else if(event.data.id == "rack_offline") {
            //     console.log('Rack Offline')
            //     console.log('[KUBUKU PWA] = ' + event.data.id)    
            //     console.log('[KUBUKU PWA] = ' + event.data.data)    
            //     this.setState({ data_offline: event.data.data  })
            // }
        });
        this.fetchContents();

    }
    componentWillUnmount = () => {
        _is_mounted = false;
        window.removeEventListener("message", null);
    }

    renderSkeleton = ({ item, index, separators }) => {
        return (
            <View style={{
                flexDirection: "row",
                alignItems: "center"
            }}>

                <Skeleton
                    variant="rectangular"
                    style={{
                        width: 90,
                        height: 130,
                        borderRadius: 8,
                        marginLeft: 16

                    }}
                />
                <View style={{ flex: 1, marginLeft: 16, marginRight: 16 }}>
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: "2rem" }}
                        animation="wave"
                        style={{ marginBottom: 8, borderRadius: 8 }}
                    />
                    <Skeleton
                        variant="rectangular"
                        sx={{ height: 90 }}
                        style={{ marginBottom: 8, borderRadius: 8 }}
                    />

                </View>
            </View>
        )
    }

    goBack = () => {
        //JSBridge.launchFunction(Command.SET_PAGE, this.props.route.params.identifier );
        //this.props.navigation.goBack(); 
        this.props.navigation.navigate(this.props.route.params.identifier);
    }

    checkDataOffline = (idBuku) => {
        // console.log("checkDataOffline id = " + idBuku);
        // console.log("checkDataOffline data = " + this.state.data_offline);
        // if (this.state.data_offline != "" || this.state.data_offline != "[]" || this.state.data_offline != []) {
        //     let jsObject = JSON.parse(this.state.data_offline)
        //     console.log("checkDataOffline lenght = " + jsObject.length);
        //     if (this.state.data_offline.length > 0) {
        //         // let jsObject = JSON.parse(this.state.data_offline)
        //         // console.log("checkDataOffline filename = " + jsObject);
        //         console.log("checkDataOffline filename = " + jsObject[0].filename);

        //         let items = jsObject
        //             .filter(i => idBuku.includes(i.filename))
        //             .map((i) => { return { id: i.filename } })

        //         console.log('checkDataOffline res = ' + items.length)
        //         if (items.length > 0) {
        //             return true;
        //         } else {
        //             return false;
        //         }
        //     } else {
        //         return false;
        //     }
        // } else {
        //     return false;
        // }
        let jsObject = this.state.data_offline
        console.log("checkDataOffline lenght = " + jsObject.length);
        if (this.state.data_offline.length > 0) {
            // let jsObject = JSON.parse(this.state.data_offline)
            // console.log("checkDataOffline filename = " + jsObject);
            console.log("checkDataOffline filename = " + jsObject[0].filename);

            let items = jsObject
                .filter(i => idBuku.includes(i.filename))
                .map((i) => { return { id: i.filename } })

            console.log('checkDataOffline res = ' + items.length)
            if (items.length > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }

    }

    renderItem = ({ item, index, separators }) => {
        return (
            <Card
                style={{
                    borderRadius: 8,
                    marginLeft: 16,
                    marginRight: 16,
                    marginTop: (index == 0 ? 0 : 8),
                    marginBottom: (index == (this.state.data.length - 1) ? 8 : 0)
                }}
            >
                <CardContent>
                    <View style={{ flexDirection: "row", padding: 0 }}>
                        <Image
                            style={{
                                width: 90,
                                height: 130,
                                borderRadius: 8
                            }}
                            source={{ uri: item.cover }}
                            onError={(error) => { this.handleImageError(item, index) }}
                        />
                        <View style={{ flex: 1, marginLeft: 8, marginRight: 8 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={14}
                                color={color_text}
                                fontWeight={"600"}
                            >
                                {item.title}
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={14}
                                color={sub_text}
                                style={{ marginTop: 8 }}
                            >
                                {item.author}
                            </Typography>
                            {
                                this.checkDataOffline(item.content_code) &&
                                <View
                                    style={{
                                        backgroundColor: "#c5ffe4",
                                        width: "70%",
                                        padding: 2,
                                        borderColor: "#28c770",
                                        borderRadius: "20px",
                                        borderWidth: '2px',
                                        marginTop: 5,
                                    }}
                                    onClick={() => { window.location.href = "https://play.google.com/store/apps/details?id=id.kubuku.bintangpusnas&hl=en-ID" }}>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={11}
                                        color={'#28c770'}
                                        style={{ textAlign: 'center' }}
                                    >
                                        Dapat Dibaca Offline
                                    </Typography>
                                </View>
                                // <Typography
                                //     fontFamily="Open Sans"
                                //     fontSize={14}
                                //     color={sub_text}
                                //     style={{ marginTop: 8 }}
                                // >
                                //     Buku dapat dibaca offline
                                // </Typography>

                            }
                        </View>
                    </View>
                </CardContent>
                {
                    this.state.flagHistory ?
                        <CardActions>
                            <Button size="small" onClick={() => { this.handleReadFromRack(item) }}>Baca</Button>
                            <Button size="small" onClick={() => { this.executeReturn(item, index) }}>Kembalikan</Button>
                            
                        </CardActions>
                        :
                        <CardActions>
                            <Button size="small" onClick={() => { this.handleBorrowClick(item) }}>Pinjam Lagi</Button>
                        </CardActions>
                }


            </Card>
        )
    }

    onTabBorrowPresent = () => {
        this.setState({
            flagHistory: true
        })
    }

    onTabBorrowHistory = () => {
        this.setState({
            flagHistory: false
        })
    }

    fetchContents = async () => {
        const response = await executeGet(borrowingList);
        //console.log(JSON.stringify(response));
        if (response.code == code_ok) {
            this.setState({
                showLoading: false,
                data: response.data,
                data_history: response.data_history
            })


        } else {
            if (response.code == code_auth_error) {
                if (global.os == "ANDROID") {
                    JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                } else {
                    window.webkit.messageHandlers.JSBridge.postMessage({ id: Command.SHOW_TOAST, params: "Autentikasi gagal! Harap login ulang!" })
                }
                window.postMessage({ id: "sign_out" });
            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }
        }
    }

    handleImageError = (item, index) => {
        let data = [...this.state.data];
        let obj = { ...data[index] };
        obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/no-cover.png";
        data[index] = obj;
        this.setState({
            data: data
        })
    }

    //ANCHOR - Borrow Click
    handleBorrowClick = (item) => {
        if (_is_mounted) {
            this.setState({
                contentCodeTemp: item.content_code,
                showBorrowDialog: true
            })
        }
    }
    //ANCHOR - Borrow Confirmation Close
    handleBorrowConfirmationClose = () => {
        if (_is_mounted) {
            this.setState({
                showBorrowDialog: false
            })
        }
    }
    //ANCHOR - Handle Confirmation Click 
    handleConfirmationClick = () => {
        if (_is_mounted) {
            this.setState({
                showBorrowDialog: false,
                showLoadingDialog: true,
                loadingDialogMessage: "memproses permintaan"
            }, () => {
                this.executeLoan();
            })
        }
    }
    //ANCHOR - Execute Loan
    executeLoan = async () => {
        let formData = new FormData();
        formData.append("content_code", this.state.contentCodeTemp);
        const stockResponse = await executePost(stockCheck, formData);
        if (stockResponse.code == code_ok) {
            if (stockResponse.stok > 0) {
                const response = await executePost(borrow, formData);
                if (response.code == code_ok) {
                    this.setState({
                        showLoadingDialog: false,
                        successMessage: response.msg,
                        showBorrowSuccess: true,
                    })
                    this.fetchContents();
                } else {
                    if (response.code != code_auth_error) {
                        this.setState({
                            showLoadingDialog: false,
                            showAlert: true,
                            alertMessage: response.msg
                        })
                    } else {
                        window.postMessage({ id: "sign_out" })
                    }
                }
            } else {
                this.setState({
                    showLoadingDialog: false,
                    showAlert: true,
                    alertMessage: "Stok kosong, silahkan coba beberapa waktu kemudian"
                })
            }
        } else {
            this.setState({
                showLoadingDialog: false,
                showAlert: true,
                alertMessage: "Stok buku ini kosong"
            })
        }
    }

    executeReturn = async (item, index) => {
        this.setState({
            showLoadingDialog: true,
            loadingDialogMessage: "memproses permintaan"
        })
        let formData = new FormData();
        formData.append("content_code", item.content_code);

        const response = await executePost(bookReturn, formData);
        if (response.code == code_ok) {
            let data = [...this.state.data];
            data.splice(index, 1);
            this.setState({
                showLoadingDialog: false,
                data: data
            })
            if (global.os == "ANDROID") {
                JSBridge.launchFunction(Command.RETURN_BOOK, item.content_code);
            }

        } else {
            if (response.code == code_auth_error) {
                if (global.os == "ANDROID") {
                    JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                } else {
                    window.webkit.messageHandlers.JSBridge.postMessage({ id: Command.SHOW_TOAST, params: "Autentikasi gagal! Harap login ulang!" })
                }
                window.postMessage({ id: "sign_out" });
            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }
        }
    }

    handleReadFromRack = (item) => {
        let content_code = item.content_code;
        this.readFromRack(content_code);
    }

    readFromRack = async (code) => {
        let formData = new FormData();
        formData.append("code", code);

        this.setState({
            showLoadingDialog: true,
            loadingDialogMessage: "Memeriksa Peminjaman"
        })

        const response = await executePost(readBorrowedContent, formData);
        //console.log(JSON.stringify(response));
        if (response.code == code_ok) {
            this.setState({
                showLoadingDialog: false
            })
            let data = response.data;
            data.mode = "borrow";
            this.props.navigation.navigate("ReaderMobile", data);

        } else {
            if (response.code == code_empty) {
                this.setState({
                    showAlert: true,
                    showLoadingDialog: false,
                    alertMessage: response.msg
                })
            } else {
                //TODO - Force Logout 
                window.postMessage({ id: "sign_out" })
            }
        }
    }

    render() {
        return (
            <View style={{ flex: 1 }}>
                <View style={{
                    height: 56,
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: "white",
                    padding: 12,
                    // marginTop: (global.os == "IOS" ? 48 : 0)

                }}>
                    <IconButton
                        style={{ marginRight: 16 }}
                        onClick={() => { this.goBack() }}
                    >
                        <ChevronLeftIcon
                            sx={{ color: color_text }}
                        />
                    </IconButton>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={18}
                        fontWeight={"600"}
                        style={{ marginLeft: 15 }}
                    >
                        Rak Digital Saya
                    </Typography>
                </View>
                <View style={{ flex: 0.95 }}>
                    <View
                        style={{
                            flexDirection: 'row',
                            marginBottom: 10
                        }}
                    >
                        <View
                            style={
                                this.state.flagHistory ?
                                    {
                                        backgroundColor: "white",
                                        width: '50%',
                                        height: 50,
                                        alignItems: "center", // ignore this - we'll come back to it
                                        justifyContent: "center",
                                        textAlign: 'center',
                                        borderBottomWidth: '5px',
                                        borderBottomColor: '#184999'
                                    } : {
                                        backgroundColor: "white",
                                        width: '50%',
                                        height: 50,
                                        alignItems: "center", // ignore this - we'll come back to it
                                        justifyContent: "center",
                                        textAlign: 'center',
                                        borderRightWidth: 1,
                                        borderRightColor: '#f2f2f2'
                                    }
                            }
                            onClick={() => { this.onTabBorrowPresent() }}
                        >
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={16}
                                fontWeight={"400"}
                            >
                                Sedang Dipinjam
                            </Typography>
                        </View>
                        <View
                            style={
                                !this.state.flagHistory ?
                                    {
                                        backgroundColor: "white",
                                        width: '50%',
                                        height: 50,
                                        alignItems: "center", // ignore this - we'll come back to it
                                        justifyContent: "center",
                                        textAlign: 'center',
                                        borderBottomWidth: '5px',
                                        borderBottomColor: '#184999'
                                    } :
                                    {
                                        backgroundColor: "white",
                                        width: '50%',
                                        height: 50,
                                        alignItems: "center", // ignore this - we'll come back to it
                                        justifyContent: "center",
                                        textAlign: 'center'
                                    }
                            }
                            onClick={() => { this.onTabBorrowHistory() }}
                        >
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={16}
                                fontWeight={"400"}
                            >
                                Pernah Dipinjam
                            </Typography>
                        </View>
                    </View>
                    {
                        this.state.showLoading &&
                        <FlatList
                            keyExtractor={(i, x) => "index_" + x}
                            data={this.state.dummy_data}
                            renderItem={this.renderSkeleton}
                            showsVerticalScrollIndicator={false}
                        />
                    }
                    {
                        this.state.flagHistory ?
                            <>
                                {
                                    !this.state.showLoading && this.state.data.length > 0 ?
                                        <FlatList
                                            keyExtractor={(item, index) => "rec_" + item.content_code}
                                            data={this.state.data}
                                            renderItem={this.renderItem}
                                            showsVerticalScrollIndicator={false}
                                        />
                                        :
                                        <View style={{ justifyContent: "center", alignItems: "center" }}>
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={18}
                                                margin={5}
                                            >
                                                Ini adalah rak buku digital anda, tetapi anda sampai saat ini anda belum pernah meminjam buku.
                                            </Typography>
                                        </View>
                                }
                            </>
                            :
                            <>
                                {
                                    !this.state.showLoading && this.state.data_history.length > 0 ?
                                        <FlatList
                                            keyExtractor={(item, index) => "rec_" + item.content_code}
                                            data={this.state.data_history}
                                            renderItem={this.renderItem}
                                            showsVerticalScrollIndicator={false}
                                        />
                                        :
                                        <View style={{ justifyContent: "center", alignItems: "center" }}>
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={18}
                                                margin={5}
                                            >
                                                Ini adalah history rak buku digital anda.
                                            </Typography>
                                        </View>
                                }
                            </>
                    }
                </View>
                {
                    this.state.showAlert &&
                    <Alert
                        onClose={() => { this.goBack() }}
                        severity="error"
                    >
                        <AlertTitle>Error</AlertTitle>
                        {this.state.alertMessage}
                    </Alert>
                }
                {
                    this.state.showBorrowSuccess &&
                    <Alert
                        onClose={() => {
                            this.setState({ showBorrowSuccess: false, flagHistory: true });
                            this.fetchContents();
                        }}
                        severity="success"
                        sx={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
                    >
                        <AlertTitle>Berhasil</AlertTitle>
                        {this.state.alertMessage}
                    </Alert>
                }
                {
                    //ANCHOR - Borrow Confirmation Dialog
                }
                <Dialog
                    open={this.state.showBorrowDialog}
                    onClose={this.handleBorrowConfirmationClose}
                >
                    <DialogTitle>
                        Konfirmasi Peminjaman
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Proses peminjaman akan dilakukan , hal ini akan mengurangi stok yang dimiliki oleh perpustakaan ini. Lanjutkan?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleBorrowConfirmationClose}>Batalkan</Button>
                        <Button onClick={this.handleConfirmationClick}>Lanjutkan</Button>
                    </DialogActions>
                </Dialog>
                <LoadingDialog
                    open={this.state.showLoadingDialog}
                    message={this.state.loadingDialogMessage}
                />
            </View>
        )
    }
}

export default MyBookRack; 