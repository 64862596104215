import React, { Component } from 'react';
import { View, StyleSheet, Image, FlatList, Dimensions, ScrollView, TextInput } from 'react-native';
import { Alert, AlertTitle, Typography, Skeleton, Card, CardActionArea, CircularProgress, IconButton, Chip } from '@mui/material';
import LoadingPage from '../components/LoadingPage';
import AlertDialog from '../components/AlertDialog';
import * as Identifier from '../constants/PageIdentifiers';
import * as Command from '../constants/Commands';
import { urlMusik } from '../constants/api';
import { executeGet, executePost, isEven, truncateText, navigateContentDetail, isEvenThree, isEvenThreeLast } from '../utilities/Utilities';
import EmptySearchPage from '../components/EmptySearchPage';
import { color_text, sub_text, primary } from '../constants/colors';
import SortIcon from '@mui/icons-material/Sort';
import SortDialog from '../components/SortDialog';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { NavSearchIcon } from '../assets/NavIcons';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';


var _is_mounted = false;
var window_width;
var book_width;
var book_height;


class DesktopSectionMusik extends Component {
    //ANCHOR - Constructor
    constructor(props) {
        super(props)
        this.state = {
            showLoading: false,
            moreLoading: false,
            loadingMessage: "",
            showAlert: false,
            alertTitle: "Perhatian",
            alertMessage: "",
            code: this.props.route.params.code,
            title: this.props.route.params.title,
            data: [],
            dummy_data: [
                { i: 0 }, { i: 1 }, { i: 2 }, { i: 3 }, { i: 0 }, { i: 1 }, { i: 2 }, { i: 3 }
            ],
            page: 1,
            total: 0,
            show: 0,
            showSortDialog: true,
            sort_type: -1,
            sort_menu: [
                { id: "001", type: 1, title: "Judul A-Z" }, { id: "002", type: 2, title: "Judul Z-A" },
                { id: "003", type: 3, title: "Penulis A-Z" }, { id: "004", type: 4, title: "Penulis Z-A" }
            ],
            search_types: [{ id: 1, alias: "umum", title: "Umum" }, { id: 2, alias: "kalimat", title: "Kalimat" }],
            searchType: "umum",
            data_sub_kategori: [],
            selected_sub_kategori: "",
            selected_kode_sub_kategori: "",
            keyword: "",
            searchMode: false,
            searchFocus: false
        }
        this.goBack = this.goBack.bind(this);
        this.renderSkeleton = this.renderSkeleton.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.fetchContent = this.fetchContent.bind(this);
        this.onImageError = this.onImageError.bind(this);
        this.fetchMoreData = this.fetchMoreData.bind(this);
        this.handleSortClick = this.handleSortClick.bind(this);
        this.handleSortMenuClick = this.handleSortMenuClick.bind(this);
        this.renderSortMenu = this.renderSortMenu.bind(this);
        this.contentDetail = this.contentDetail.bind(this);
        this.renderSubKategori = this.renderSubKategori.bind(this);
        this.handleSubKategoriClick = this.handleSubKategoriClick.bind(this);
        this.handleSearchIconClick = this.handleSearchIconClick.bind(this);
        this.onSearchInputFocus = this.onSearchInputFocus.bind(this);
        this.onSearchInputBlur = this.onSearchInputBlur.bind(this);
        this.handleSearchPress = this.handleSearchPress.bind(this);
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
        this.renderSearchTypes = this.renderSearchTypes.bind(this);
        this.handleSearchTypeClick = this.handleSearchTypeClick.bind(this);

    }

    componentDidMount = () => {

        //console.log("WIDTH : " +book_width); 
        //console.log("HEIGHT : " + book_height);
        _is_mounted = true;
        if (global.os == "ANDROID") {
            JSBridge.launchFunction(Command.SET_PAGE, Identifier.SECTION_DETAIL);
        }
        window.addEventListener("message", (event) => {
            //alert(JSON.stringify(event.data));
            if (event.data.id == "go_back") {
                //alert(global.currentRouteName);
                if (global.currentRouteName == Identifier.SECTION_DETAIL) {
                    this.goBack();
                }
            }
        });

        this.fetchContent();
    }



    componentWillUnmount = () => {
        _is_mounted = false;
        window.removeEventListener("message", null);
    }

    goBack = () => {
        if (!this.state.searchMode) {
            this.props.navigation.navigate("MainDesktop");
        } else {
            this.setState({
                searchMode: false
            })
        }
    }

    //ANCHOR - Fetch Content
    fetchContent = async () => {
        let url = "https://kubuku.id/api/titikBaca/getMusik/bintangpusnas";
        //const response = await executePost(widgetTutorialEdukasi);
        const response = await executeGet(urlMusik);
        //const response = await fetch(url);
        console.log(response);
        console.log("fetchContent desktopSectionMusik = " + JSON.stringify(response))
        console.log(response.code);
        console.log(response.data);
        if (response.code == "200") {
            this.setState({
                showLoading: false,
                data: response.data
            })

        } else {
            this.setState({
                showLoading: false,
            });
            if (response.code != code_auth_error) {
                this.setState({
                    showLoading: false,
                })
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, response.msg);
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : response.msg })
                // }
            } else {
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                //window.postMessage({ id: "sign_out" });
            }
        }
    }

    renderSkeleton = ({ item, index, separators }) => {

        return (
            <View
                style={{
                    width: book_width,
                    borderRadius: 8,
                    marginLeft : Dimensions.get("window").width < 430 ? (isEven(index) ? 16 : 4) : (isEvenThree(index) ? 16 : 4), 
                    marginRight : Dimensions.get("window").width < 430 ? (!isEven(index) ? 16 : 4) : (isEvenThreeLast(index) ? 16 : 4) ,
                    marginBottom: 8,
                }}
            >
                <Skeleton
                    style={{
                        borderRadius: 8
                    }}
                    width={book_width}
                    height={book_height}
                    variant="rectangular"
                    animation="wave"
                />

                <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "1.5rem" }}
                    style={{
                        marginLeft: 12, marginTop: 8, marginRight: 12
                    }}
                />
                <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "1.5rem" }}
                    style={{
                        marginLeft: 12,
                        marginBottom: 12,
                        marginRight: 12
                    }}
                />
            </View>
        )
    }
    renderItem = ({ item, index, separators }) => {
        return (
            <Card
                style={{
                    width: book_width,
                    borderRadius: 8,
                    marginLeft : 16, 
                    marginRight : 16,
                    marginBottom: 8,

                }}
            >
                <CardActionArea
                    sx={{ height: "100%" }}
                    onClick={() => { this.contentDetail(item, index) }}
                >
                    <Image
                        style={{
                            width: book_width,
                            height: book_height,
                        }}
                        resizeMode={"cover"}
                        source={{ uri: item.cover }}
                        onError={(error) => { this.onImageError(item, index) }}
                    />

                    <Typography
                        fontFamily="Open Sans"
                        color={color_text}
                        fontSize={14}
                        style={{
                            marginLeft: 12, marginTop: 8, marginRight: 12,
                        }}   
                    >
                        {truncateText(item.judul, 40)}
                    </Typography>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={12}
                        color={sub_text}
                        fontWeight={"600"}
                        style={{
                            marginLeft: 12,
                            marginBottom: 15,
                            marginRight: 12,
                            height: '15%'
                        }}
                    >
                        {truncateText(item.pencipta, 20)}
                    </Typography>
                </CardActionArea>
            </Card>
        )
    }
    renderSearchTypes = ({ item, index, separators }) => {
        return (
            <Chip
                style={{
                    marginRight: (index == 2 ? 16 : 8),
                    marginLeft: (index == 0 ? 16 : 0)
                }}
                variant={this.state.searchType == item.alias ? "contained" : "outlined"}
                label={item.title}
                onClick={() => { this.handleSearchTypeClick(item) }}
            />
        )
    }
    contentDetail = (item, index) => {
        console.log("edukasi 1 = " + JSON.stringify(this.props.navigation));
        console.log("edukasi 2 = " + JSON.stringify(item));

        // let params = { code : "HMP-Lokal", title : item.judul, origins : "CONTENT_SECTION", kementrian_code: item.kementrian_code}; 
        // this.props.navigation.navigate("SectionDetailAudiobook", params);

        let data = {};
        data.mode = 'read';
        data.local = "musik_keluarga";
        data.nama_file = item.multimedia;
        data.type = "mp3";
        data.playlist = "";
        data.first_page = 1;
        data.last_page = 1;
        data.play_index = 0;
        data.play_type = 'single';
        data.cover = item.cover;
        data.pencipta = item.pencipta;
        data.track
        console.log('data = ' + JSON.stringify(data))
        this.props.navigation.navigate("ReaderPlayerDesktop", data);
    }

    onImageError = (item, index) => {
        if (_is_mounted) {
            let data = [...this.state.data];
            let obj = { ...data[index] };
            obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
            data[index] = obj;
            this.setState({
                data: data
            })
        }
    }

    fetchMoreData = () => {
        if (_is_mounted && !this.state.showLoading && !this.state.moreLoading) {
            if (this.state.show < this.state.total) {
                let page = parseInt(this.state.page) + 1;
                this.setState({
                    page: page
                }, () => {
                    this.fetchContent();
                })

            }
        }
    }

    handleSearchTypeClick = (item) => {
        if (_is_mounted) {
            this.setState({
                searchType: item.alias
            })
        }
    }
    handleSortMenuClick = () => {
        if (_is_mounted) {
            if (this.state.showSortDialog) {
                this.setState({
                    showSortDialog: false
                })
            } else {
                this.setState({
                    showSortDialog: true
                })
            }
        }

    }
    handleSortClick = (variant) => {
        if (_is_mounted) {
            this.setState({
                sort_type: variant,
                page: 1,
                total: 0,
                show: 0,
                data: [],
            }, () => {
                this.fetchContent();
            });

        }
    }

    //ANCHOR - Render Sort Menu
    renderSortMenu = ({ item, index, separators }) => {
        return (
            <Chip
                style={{
                    marginRight: (index == 3 ? 16 : 8),
                    marginLeft: (index == 0 ? 16 : 0)
                }}
                variant={this.state.sort_type == item.type ? "contained" : "outlined"}
                label={item.title}
                onClick={() => { this.handleSortClick(item.type) }}
            />
        )
    }
    //ANCHOR - Render Sub Kategori
    renderSubKategori = ({ item, index, separators }) => {
        return (
            <Chip
                style={{
                    marginRight: (index == (this.state.data_sub_kategori.length - 1) ? 16 : 8),
                    marginLeft: (index == 0 ? 16 : 0)
                }}
                variant={this.state.selected_kode_sub_kategori == item.code ? "contained" : "outlined"}
                label={item.title}
                onClick={() => { this.handleSubKategoriClick(item) }}
            />
        )
    }

    //ANCHOR - Handle Sub Kategori Click
    handleSubKategoriClick = (item) => {
        if (_is_mounted) {
            var kode_sub_kategori = "";
            var sub_kategori = "";

            if (item.code != this.state.selected_kode_sub_kategori) {
                kode_sub_kategori = item.code;
                sub_kategori = item.title;
            }

            this.setState({
                selected_kode_sub_kategori: kode_sub_kategori,
                selected_sub_kategori: sub_kategori,
                page: 1,
                total: 0,
                show: 0,
                data: []
            }, () => {
                this.fetchContent()
            })
        }
    }

    //ANCHOR - Handle Search Icon Click 
    handleSearchIconClick = () => {

        if (_is_mounted) {
            if (this.state.searchMode) {
                this.setState({
                    searchMode: false
                })
            } else {
                this.setState({
                    searchMode: true
                })
            }
        }
    }
    onSearchInputFocus = (e) => {
        this.setState({
            searchFocus: true
        })
    }
    onSearchInputBlur = () => {
        this.setState({
            searchFocus: false
        })
    }
    //ANCHOR - Handle Search Text Changed
    handleSearchTextChange = (text) => {
        if (_is_mounted) {
            this.setState({
                keyword: text
            })
        }
    }
    //ANCHOR - Handle Search
    handleSearchPress = (e) => {
        let key = e.nativeEvent.key;
        if (key == "Enter") {
            if (this.state.searchType == "umum") {
                this.setState({
                    page: 1,
                    data: [],
                    show: 0,
                    total: 0,
                }, () => {
                    this.fetchContent();
                });
            } else {
                if (this.state.keyword.length > 5) {
                    let params = {
                        code: this.props.route.params.code,
                        title: this.props.route.params.title,
                        selected_kode_sub_kategori: this.state.selected_kode_sub_kategori,
                        keyword: this.state.keyword,
                        origins: "CONTENT_SECTION"
                    }
                    this.props.navigation.navigate("SearchKalimat", params);
                } else {
                    this.setState({
                        showWarning: true,
                        warningMessage: "Kalimat yang dicari minimal lebih dari 5 huruf"
                    })
                }

            }
        }
    }

    render() {
        window_width = Dimensions.get("window").width;
        book_width = Dimensions.get("window").width > 430 ? ((window_width / 6) - 16) : ((window_width / 3) - 16) ; 
        book_height = book_width;

        return (
            <View style={{ flex: 1 }}>
                <View style={{
                    marginLeft: 0,
                    //marginTop : (global.os == "ANDROID" ? 16 : 48 ) ,  
                    // marginTop: (global.os == "IOS" ? 48 : 0),
                    //flex : 0.07,
                    height: 64,
                    backgroundColor: "white",
                    flexDirection: "row",
                    alignItems: "center"

                }}>

                    <IconButton
                        style={{ marginRight: 16, marginLeft: 8 }}
                        onClick={() => { this.goBack() }}
                    >
                        <ChevronLeftIcon
                            sx={{ color: "black" }}
                        />
                    </IconButton>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={18}
                        color="black"
                        fontWeight={"500"}
                        style={{ marginLeft: 15 }}
                    >
                        Musik Keluarga
                    </Typography>

                </View>
                <View style={{ flex: 1, marginTop: 10 }}>

                    {
                        (this.state.data.length == 0 && !this.state.showLoading) &&
                        <EmptySearchPage
                            title={this.state.alertTitle}
                            message={this.state.alertMessage}
                        />

                    }
                    {
                        this.state.showLoading &&
                        <FlatList
                            keyExtractor={(i, x) => "index_" + x}
                            data={this.state.dummy_data}
                            renderItem={this.renderSkeleton}
                            numColumns={ Dimensions.get("window").width > 430 ? 3 : 2 }
                            showsVerticalScrollIndicator={false}
                        />
                    }
                    {
                        (!this.state.showLoading) &&
                        <FlatList
                            keyExtractor={(item, index) => "item_index_" + index}
                            data={this.state.data}
                            renderItem={this.renderItem}
                            numColumns={6}
                            showsVerticalScrollIndicator={false}
                            ListFooterComponent={
                                this.state.moreLoading &&
                                <View style={{ alignItems: "center", padding: 8 }}>
                                    <CircularProgress
                                        size={32}
                                    />
                                </View>
                            }
                        />
                    }
                </View>
                {
                    this.state.showAlert &&
                    <Alert
                        onClose={() => { this.goBack() }}
                        severity="error"
                    >
                        <AlertTitle>Error</AlertTitle>
                        {this.state.alertMessage}
                    </Alert>
                }


            </View>

        )
    }
}
//ANCHOR -   Styles
const styles = StyleSheet.create({
    searchBar: {
        backgroundColor: "#F9FAFC",
        borderRadius: 8,
        borderWidth: 0.2,
        padding: 8,
        flexDirection: "row",
        flex: 1
    },
    searchInput: {
        fontFamily: "Open Sans",
        fontWeight: "400",
        color: color_text,
        fontSize: 14,
        flex: 0.95,
        backgroundColor: "#F9FAFC",
        outlineColor: "#F9FAFC",
        marginLeft: 18
    },
})
export default DesktopSectionMusik; 