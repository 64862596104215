import React, { Component } from 'react';
import { StyleSheet, View, FlatList, Image, Dimensions } from 'react-native'; 
import { code_ok, code_error, code_auth_error, code_empty , homepageRecommended } from '../constants/api';
import { Skeleton, Typography, Card, CardActionArea, CardMedia, CardContent, Button } from '@mui/material'; 
import { executeGet, executePost , truncateText, navigateContentDetail } from '../utilities/Utilities';
import AlertDialog from '../components/AlertDialog';
import { color_text, sub_text, primary } from '../constants/colors';
import * as Identifier from '../constants/PageIdentifiers';


class HomepageRecommended extends Component {
    constructor(props){
        super(props)
        this.state = {
            showLoading : true, 
            showAlert : false, 
            alertMessage : "", 
            loadingMessage : "",
            code : this.props.code ,
            data : [], 
            dummy_data : [
                { i : 0 }, { i : 1}, {i : 2 } 
            ]

        }

        this.handleAlertClose = this.handleAlertClose.bind(this); 
        this.handleMoreClick = this.handleMoreClick.bind(this); 
        this.fetchContents = this.fetchContents.bind(this); 
        this.renderItem = this.renderItem.bind(this); 
        this.handleImageError = this.handleImageError.bind(this); 
        this.contentDetail = this.contentDetail.bind(this);
    }

    componentDidMount = ()=>{
        this.fetchContents(); 
    }

    handleAlertClose = ()=>{

    }
    handleMoreClick = (code, title)=>{
        this.props.navigation.navigate("SectionDetail", { identifier : Identifier.SECTION_DETAIL, code : code, title : title, origins : "CONTENT_RECOMMENDED"}); 
    }

    fetchContents = async()=>{

        let formData = new FormData();
        formData.append("page", 1);
        formData.append("homepage_code", this.props.code) 
        formData.append("limit", 3); 
        
        const response = await executePost(homepageRecommended, formData); 
        if(response.code == code_ok){
            console.log(JSON.stringify(response));
            this.setState({
                showLoading : false, 
                data : response.data
            })
        } else {
            if(response.code != code_auth_error){
                this.setState({
                    showLoading : false, 
                    alertMessage : response.msg, 
                    showAlert : true
                })
            } else {
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out"});
            }
        }   
    }

    renderItem = ({item, index, separators})=>{
        return(
            <Card
                style={{
                    borderRadius : 8, 
                    marginLeft : 16, 
                    marginRight : 16,
                    marginTop : (index == 0 ? 0 : 8 ), 
                    marginBottom : (index == (this.state.data.length - 1) ?  8 : 0 )
                }}
            >
                <CardActionArea
                    onClick={()=>{ this.contentDetail(item, index)}}
                >
                    <View style={{flexDirection : "row", padding : 12 }}>
                        <Image 
                            style={{
                                width : 90, 
                                height : 130 , 
                                borderRadius : 8 
                            }}
                            source={{uri : item.cover }} 
                            onError={(error)=>{ this.handleImageError(item, index)}}
                        />
                        <View style={{flex : 1, marginLeft : 8 , marginRight : 8 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 14 } 
                                color={ color_text }
                                fontWeight={"600"}
                            >
                                { item.title }
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 14 } 
                                color={ sub_text }
                                style={{marginTop : 8 }}
                            >
                                { item.author }
                            </Typography>

                        </View>
                    </View>
                </CardActionArea>
            </Card>
        )
    }

    contentDetail = (item, index)=>{
        let params = {
            previous : Identifier.MAIN, 
            parameters : {}, 
            content_code : item.content_code 
        }
        navigateContentDetail(this.props.navigation, params); 
    }
    handleImageError = (item, index)=>{
        let data = [...this.state.data]; 
        let obj = { ...data[index]}; 
        obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
        data[index] = obj; 
        this.setState({
            data : data
        })
    }

    render(){
        return(
            <View style={{width : "100%"}}>
                {
                    this.state.showLoading && 
                    <View>
                        <Skeleton 
                            variant="text"
                            animation="wave"
                            sx={{fontSize : '2rem' , maxWidth : 120 }}
                            style={{marginLeft : 16 , marginBottom : 8 , marginRight : 16,  marginTop : 16 }} 
                        />
                        <Skeleton 
                            variant="rectangular"
                            style={{height : 90, marginTop : 8, borderRadius : 8 , marginLeft : 16, marginRight : 16 }}
                            animation="wave"
                        />
                        <Skeleton 
                            variant="rectangular"
                            style={{height : 90, marginTop : 8, borderRadius : 8 , marginLeft : 16, marginRight : 16 }}
                            animation="wave"
                        />
                        <Skeleton 
                            variant="rectangular"
                            style={{height : 90, marginTop : 8, borderRadius : 8 , marginLeft : 16, marginRight : 16 }}
                            animation="wave"
                        />
                    </View>
                }
                {
                    !this.state.showLoading && 
                    <View style={{ marginTop : 16 }}>
                        <View style={{flexDirection : "row", alignItems : "center", marginBottom : 8 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 16 }
                                variant="h3"
                                style={{flex : 1, marginLeft : 16 }}
                                color={ color_text }
                                fontWeight={"600"}
                            >
                                { truncateText(this.props.title, 20) }
                            </Typography>    
                            <Button
                                style={{textTransform : "none", marginRight : 16  }}
                                onClick={()=>{ this.handleMoreClick(this.state.code, this.props.title )}}
                            >
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 12 }
                                    fontWeight="500"
                                    color={ primary }
                                >
                                selebihnya
                                </Typography>
                            </Button>
                        </View>
                        <FlatList
                            keyExtractor={(item ,index)=>"rec_"+item.content_code }
                            data={ this.state.data }
                            renderItem={ this.renderItem }
                            showsVerticalScrollIndicator={false}
                        />

                    </View>
                    
                }

                
            </View>
        )
    }
}

export default HomepageRecommended; 