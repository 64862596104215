import React, { Component } from "react"
import Svg, { G, Path, Circle, Defs, ClipPath } from "react-native-svg"


class SearchIcon extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Svg
                width={this.props.width}
                height={this.props.height}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <Circle cx={17} cy={17} r={17} fill={ this.props.fillLogo} fillOpacity={0.35} />
                <G clipPath="url(#a)" fill={ this.props.fillLogo}>
                    <Path d="M15.001 11.602a.562.562 0 1 0-.176-1.111 4.313 4.313 0 0 0-3.585 3.584.562.562 0 0 0 1.112.176 3.188 3.188 0 0 1 2.65-2.65Z" />
                    <Path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.25 8.188a7.312 7.312 0 1 0 0 14.624 7.312 7.312 0 0 0 0-14.625ZM10.062 15.5a6.187 6.187 0 1 1 12.375 0 6.187 6.187 0 0 1-12.375 0Z"
                    />
                    <Path d="M22.648 21.102a.563.563 0 0 0-.796.796l3 3a.563.563 0 0 0 .796-.796l-3-3Z" />
                </G>
                <Defs>
                    <ClipPath id="a">
                        <Path fill={ this.props.fillLogo} transform="translate(8 8)" d="M0 0h18v18H0z" />
                    </ClipPath>
                </Defs>
            </Svg>
        )
    }
}


export default SearchIcon;
