import React, { Component } from 'react';
import { StyleSheet, View, FlatList, Image, Dimensions } from 'react-native';
import { code_ok, code_error, code_auth_error, code_empty, homepageNews, statistikKoleksi } from '../../constants/api';
import { Skeleton, Typography, Card, CardActionArea, CardMedia, CardContent, Button } from '@mui/material';
import { executeGet, executePost, truncateText, navigateContentDetail } from '../../utilities/Utilities';
import AlertDialog from '../../components/AlertDialog';
import { color_text, sub_text, primary } from '../../constants/colors';
import * as Identifier from '../../constants/PageIdentifiers';


class MenuStatistik extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            showAlert: false,
            alertMessage: "",
            loadingMessage: "",
            code: this.props.code,
            data_utama: [],
            data: [],
            dummy_data: [
                { i: 0 }, { i: 1 }, { i: 2 }
            ]

        }

        this.handleAlertClose = this.handleAlertClose.bind(this);
        this.handleMoreClick = this.handleMoreClick.bind(this);
        this.fetchContents = this.fetchContents.bind(this);
        // this.renderItem = this.renderItem.bind(this);
        // this.handleImageError = this.handleImageError.bind(this);
        // this.contentDetail = this.contentDetail.bind(this);
    }

    componentDidMount = () => {
        this.fetchContents();
        // setTimeout(() => {
        //     this.setState({
        //         showLoading: false
        //     })
        // }, 3000);
    }

    fetchContents = async () => {

        const response = await executeGet(statistikKoleksi);
        if (response.code == code_ok) {
            console.log(JSON.stringify(response));
            console.log(JSON.stringify(this.state.data));
            this.setState({
                showLoading: false,
                data : response.data
            })
        } else {
            if (response.code != code_auth_error) {
                this.setState({
                    showLoading: false,
                    alertMessage: response.msg,
                    showAlert: true
                })
            } else {
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            }
        }
    }

    handleAlertClose = () => {

    }
    handleMoreClick = (code, title) => {

        //this.props.navigation.navigate("SectionDetail", { identifier : Identifier.SECTION_DETAIL, code : code, title : title, origins : "CONTENT_RECOMMENDED"}); 
    }



    render() {
        return (
            <View style={{ width: "100%" }}>
                {
                    this.state.showLoading &&
                    <View>
                        <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: '2rem', maxWidth: 120 }}
                            style={{ marginLeft: 16, marginBottom: 8, marginRight: 16, marginTop: 16 }}
                        />
                        <Skeleton
                            variant="rectangular"
                            style={{ height: 200, marginTop: 8, borderRadius: 8, marginLeft: 16, marginRight: 16 }}
                            animation="wave"
                        />
                    </View>
                }
                {
                    !this.state.showLoading &&
                    <View style={{ marginTop: 16 }}>
                        <Card
                            style={{
                                borderRadius: 8,
                                marginLeft: 16,
                                marginRight: 16,
                                marginTop: 8,
                                marginBottom: 8,
                                boxShadow: "0px 33px 74px rgba(0, 0, 0, 0.0196802), 0px 13.7866px 30.9154px rgba(0, 0, 0, 0.0282725), 0px 7.37098px 16.5289px rgba(0, 0, 0, 0.035), 0px 4.13211px 9.26595px rgba(0, 0, 0, 0.0417275), 0px 2.19453px 4.92108px rgba(0, 0, 0, 0.0503198), 0px 0.913195px 2.04777px rgba(0, 0, 0, 0.07)"
                            }}
                        >
                            <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 4, marginTop: 13 }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={16}
                                    variant="h3"
                                    style={{ flex: 1, marginLeft: 16 }}
                                    color={color_text}
                                    fontWeight={"600"}
                                >
                                    {truncateText(this.props.title, 30)}
                                </Typography>
                            </View>
                            {
                                this.state.data.map((item, index) => {
                                    return (<CardActionArea>
                                        <View style={{ flexDirection: "row", padding: 12 }}>
                                            <Image
                                                style={{
                                                    width: 45,
                                                    height: 45
                                                }}
                                                source={{ uri: item.cover }}
                                            />
                                            <View style={{ flex: 0.7, marginLeft: 10 }}>
                                                <Typography
                                                    fontFamily="Open Sans"
                                                    fontSize={14}
                                                    color={color_text}
                                                    fontWeight={"600"}
                                                >
                                                    { item.title }
                                            </Typography>
                                                

                                            </View>
                                            <View style={{ flex: 0.3, textAlign: 'right' }}>
                                                <Typography
                                                    fontFamily="Open Sans"
                                                    fontSize={14}
                                                    color={color_text}
                                                    fontWeight={"600"}
                                                >
                                                    { item.total1 }
                                            </Typography>
                                                

                                            </View>
                                        </View>
                                    </CardActionArea>)

                                })
                            }

                        </Card>

                    </View>

                }


            </View>
        )
    }

}

const styles = {
    media: {
        height: 0,
        paddingTop: '56.25%' // 16:9
    },
    card: {
        position: 'relative',
        margin: '16px',
        borderRadius: '15px'
    },
    overlay: {
        position: 'absolute',
        bottom: '39px',
        left: '20px',
        color: 'white',
        fontSize: '18px'
    },
    overlaySub: {
        position: 'absolute',
        bottom: '12px',
        left: '20px',
        color: 'white',
        fontSize: '12px'
    }
}

export default MenuStatistik;