import React, { Component } from 'react'; 
import { StyleSheet, View } from 'react-native'; 
import { Typography, Modal, Box , CircularProgress} from '@mui/material'; 

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 150,
    height : 150, 
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius : 8,
    alignItems : "center"
};


class LoadingDialog extends Component {
    constructor(props){
        super(props)
    }

    render(){
        return(
            <Modal
                open={ this.props.open }
            >
                <View style={ styles.container }>
                    <CircularProgress 
                        size={ 64 }
                    />
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 14 }
                        fontWeight="500"
                        style={{ marginTop : 16 }}
                    >
                        { this.props.message }
                    </Typography>
                </View>
            </Modal>
        )
    }
}

const styles = StyleSheet.create({
    container : {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: (global.platform == "desktop" ? 350 : 200 ),
        height : (global.platform == "desktop" ? 350 : 200 ), 
        backgroundColor: 'white',
        boxShadow: 24,
        borderRadius : 15, 
        alignItems : "center", 
        justifyContent : "center"
    
    }
})

export default LoadingDialog; 