import React, { Component } from 'react'; 
import { View, StyleSheet , Image, FlatList, Dimensions, ScrollView  } from 'react-native'; 
import { Alert, AlertTitle, Typography, Skeleton, Card, CardActionArea , CircularProgress, 
    IconButton, Chip } from '@mui/material'; 
import LoadingPage from '../components/LoadingPage'; 
import AlertDialog from '../components/AlertDialog'; 
import * as Identifier from '../constants/PageIdentifiers';
import * as Command from '../constants/Commands'; 
import { code_ok, code_empty, code_error, code_auth_error, contentTypeList } from '../constants/api';
import { executeGet, executePost , isEven, truncateText, navigateContentDetail } from '../utilities/Utilities'; 
import  EmptySearchPage  from '../components/EmptySearchPage';
import { color_text, sub_text, primary } from '../constants/colors';
import SortIcon from '@mui/icons-material/Sort';
import SortDialog from '../components/SortDialog';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

var _is_mounted = false; 
var window_width;
var book_width;
var book_height;

class ContentTypePage extends Component { 
    constructor(props){
        super(props)
        this.state = {
            showLoading : true, 
            moreLoading : false, 
            loadingMessage : "", 
            showAlert : false,
            alertMessage : "",
            type_code : this.props.route.params.code,
            type_name : this.props.route.params.title,
            data : [], 
            dummy_data : [
                {i : 0}, { i : 1 }, {i : 2 }, { i : 3 }, {i : 0}, { i : 1 }, {i : 2 }, { i : 3 }
            ],
            page : 1, 
            total : 0, 
            show : 0, 
            showSortDialog : false,
            sort_type : -1,
            sort_menu : [
                {id : "001", type: 1, title : "Judul A-Z"},{id : "002", type : 2, title : "Judul Z-A"}, 
                {id:"003", type : 3, title : "Penulis A-Z"}, { id : "004", type : 4 , title : "Penulis Z-A"}
            ]
        }
        this.goBack = this.goBack.bind(this); 
        this.renderSkeleton = this.renderSkeleton.bind(this);
        this.renderItem = this.renderItem.bind(this); 
        this.fetchContent = this.fetchContent.bind(this); 
        this.onImageError = this.onImageError.bind(this); 
        this.fetchMoreData = this.fetchMoreData.bind(this); 
        this.handleSortClick = this.handleSortClick.bind(this); 
        this.handleSortMenuClick = this.handleSortMenuClick.bind(this); 
        this.renderSortMenu = this.renderSortMenu.bind(this);
        this.contentDetail = this.contentDetail.bind(this); 

    }

    componentDidMount = ()=>{

        //console.log("WIDTH : " +book_width); 
        //console.log("HEIGHT : " + book_height);
        _is_mounted = true;
        if (global.os == "ANDROID") {
            JSBridge.launchFunction(Command.SET_PAGE, Identifier.CONTENT_TYPE_PAGE);    
        }
        window.addEventListener("message", (event)=>{
            if(event.data.id == "go_back"){
                if(event.data.page == Identifier.CONTENT_TYPE_PAGE){
                    this.goBack(); 
                }
            }
        }); 
        this.fetchContent(); 
    }



    componentWillUnmount = ()=>{
        _is_mounted = false;
        window.removeEventListener("message", null); 
    }
    goBack = ()=>{
        this.props.navigation.navigate(Identifier.MAIN);
    }

    fetchContent = async()=>{
        if(_is_mounted){
            if(this.state.page == 1) {
                this.setState({
                    showLoading : true 
                })
            } else {
                this.setState({
                    moreLoading : true 
                })
            }

            let formData = new FormData(); 
            formData.append("page", this.state.page);
            formData.append("limit", 50);
            formData.append("code", this.state.type_code);
            if(this.state.sort_type != -1) {
                formData.append("sort_by", this.state.sort_type); 
            }
            
            const response = await executePost(contentTypeList, formData); 
            //console.log(JSON.stringify(response)); 
            if(response.code == code_ok){
                if(parseInt(response.show) > 0 ) {
                    let show = parseInt(response.show ) + parseInt(this.state.show); 
                    let total = parseInt(response.total); 
                    let data = [...this.state.data, ...response.data]; 
                    this.setState({
                        total : total, 
                        show : show , 
                        data : data ,
                        moreLoading : false, 
                        showLoading : false
                    })
                } 
                
                if(parseInt(response.total) == 0) {
                    this.setState({
                        showLoading : false, 
                        moreLoading : false, 
                        alertTitle : "Tidak ditemukan", 
                        alertMessage : "Mohon maaf, data dihalaman ini tidak ditemukan"
                    })
                }
            } else {
                if(response.code == code_auth_error){
                    // if(global.os == "ANDROID"){
                    //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                    // } else {
                    //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                    // }
                    window.postMessage({ id: "sign_out"});
                } else {
                    this.setState({
                        showLoading : false, 
                        showAlert : true, 
                        alertMessage : response.msg
                    })
                }
                
            }
        }
    }


    renderSkeleton = ({item, index, separators})=>{
        
        return(
            <View
                style={{ 
                    width : book_width, 
                    borderRadius : 8, 
                    marginLeft : (isEven(index) ? 12 : 4), 
                    marginRight : (!isEven(index)) ? 16 : 4,
                    marginBottom : 8,                
                }}
            >
                <Skeleton 
                    style={{
                        borderRadius : 8 
                    }}
                    width={ book_width }
                    height={ book_height }
                    variant="rectangular"
                    animation="wave"
                />

                <Skeleton 
                    variant="text"
                    animation="wave"
                    sx={{ fontSize : "1.5rem"}}
                    style={{ 
                        marginLeft : 12, marginTop : 8, marginRight : 12 
                    }}
                />
                <Skeleton 
                    variant="text"
                    animation="wave"
                    sx={{ fontSize : "1.5rem"}}
                    style={{
                        marginLeft : 12 ,
                        marginBottom : 12, 
                        marginRight : 12
                    }}
                />
            </View>
        )
    }
    renderItem = ({item, index, separators})=>{
        return(
            <Card
                style={{ 
                    width : book_width, 
                    borderRadius : 8, 
                    marginLeft : (isEven(index) ? 16 : 4), 
                    marginRight : (!isEven(index)) ? 16 : 4,
                    marginBottom : 8,
                    
                }}
            >
                <CardActionArea
                    sx={{ height : "100%"}}
                    onClick={()=>{ this.contentDetail(item, index)}}
                >
                    <Image
                        style={{
                            width : book_width,
                            height : book_height,
                        }}
                        resizeMode={"cover"}
                        source={{ uri : item.cover }}
                        onError={(error)=>{ this.onImageError(item, index)}}
                    />


                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 14 }
                        color={ color_text } 
                        fontWeight={"600"}
                        style={{ 
                            marginLeft : 12, marginTop : 8, marginBottom : 8, marginRight : 12 
                        }}
                    >
                        { truncateText(item.title, 32)  }
                    </Typography>
                    <Typography
                        fontFamily="Open Sans"
                        color={ sub_text }
                        fontSize={ 12 }
                        style={{
                            marginLeft : 12 ,
                            marginBottom : 12, 
                            marginRight : 12
                        }}
                    >
                        { truncateText(item.author, 20 )}
                    </Typography>
                </CardActionArea>
            </Card>
        )
    }

    contentDetail = (item ,index)=>{
        let params = {
            previous : Identifier.CONTENT_TYPE_PAGE, 
            parameters : this.props.route.params, 
            content_code : item.code
        }
        navigateContentDetail(this.props.navigation, params); 
    }
    onImageError = (item, index)=>{
        if(_is_mounted){
            let data = [...this.state.data]; 
            let obj = { ...data[index]}; 
            obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
            data[index] = obj; 
            this.setState({
                data : data
            })
        }
    }

    fetchMoreData = ()=>{
        if(_is_mounted && !this.state.showLoading && !this.state.moreLoading){
            if(this.state.show < this.state.total ) {
                let page = parseInt(this.state.page) + 1; 
                this.setState({
                    page : page
                }, ()=>{
                    this.fetchContent(); 
                })

            }
        }
    }
    handleSortMenuClick = ()=>{
        if(_is_mounted){
            if(this.state.showSortDialog){
                this.setState({
                    showSortDialog : false
                })
            } else {
                this.setState({
                    showSortDialog : true
                })
            }
        }
        
    }
    handleSortClick = (variant)=>{
        if(_is_mounted){
            this.setState({
                sort_type : variant,
                page : 1, 
                total : 0, 
                show : 0,
                data : [],
            },()=>{
                this.fetchContent();
            }); 

        }
    }
    

    renderSortMenu = ({ item, index, separators})=>{
        return(
            <Chip 
                style={{ 
                    marginRight : (index == 3 ? 16 : 8) ,
                    marginLeft : (index == 0 ? 16 : 0)
                }}
                variant={ this.state.sort_type == item.type ? "contained" : "outlined"} 
                label={ item.title } 
                onClick={()=>{ this.handleSortClick(item.type )}}
            />
        )
    }
    render(){
        window_width = Dimensions.get("window").width; 
        book_width = (window_width / 2) - 16 ; 
        book_height = book_width + 40;
        
        return(
            <View style={{ flex : 1 }}>
                <View style={{
                    marginLeft : 16 , 
                    //marginTop : (global.os == "ANDROID" ? 16 : 48 ) , 
                    marginTop : 16 , 
                    marginBottom : 16 ,
                    flex : 0.05,
                    backgroundColor : "#F2F2F2",
                    flexDirection : "row", 
                    alignItems : "center"
            
                }}>
                    
                    <IconButton
                        style={{ marginRight : 16 }}
                        onClick={()=>{ this.goBack() }}
                    >
                        <ChevronLeftIcon 
                            sx={{color : color_text }}
                        />
                    </IconButton>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 18 }
                        fontWeight="600"
                        style={{ flex : 1}}
                    >
                        { this.props.route.params.title }
                    </Typography>
                    <IconButton
                        style={{
                            marginRight : 16 
                        }}
                        onClick={ this.handleSortMenuClick }
                    >
                        <SortIcon />
                    </IconButton>
                </View>
                <View style={{ flex : 0.95}}>
                    {
                        (!this.state.showLoading && this.state.showSortDialog) && 
                        <View style={{marginBottom : 16 }}>
                            <FlatList 
                                horizontal={ true }
                                keyExtractor={(k, l)=>"index_"+k.id}
                                data={ this.state.sort_menu }
                                renderItem={ this.renderSortMenu }
                                showsHorizontalScrollIndicator={false}
                            />
                        </View>

                    }
                    {
                        (this.state.data.length == 0 && !this.state.showLoading ) && 
                        <EmptySearchPage 
                            title={ this.state.alertTitle }
                            message={ this.state.alertMessage }
                        />
                        
                    }
                    {
                        this.state.showLoading && 
                        <FlatList 
                            keyExtractor={(i, x)=>"index_"+x}
                            data={ this.state.dummy_data } 
                            renderItem={ this.renderSkeleton }
                            numColumns={ 2 }
                            showsVerticalScrollIndicator={false}
                        />
                    }
                    {
                        (!this.state.showLoading) && 
                        <FlatList 
                            keyExtractor={(item , index)=>"item_index_"+index}
                            data={ this.state.data }
                            renderItem={ this.renderItem }
                            numColumns={ 2 }
                            onEndReachedThreshold={0.5}
                            showsVerticalScrollIndicator={false}
                            onEndReached={this.fetchMoreData }
                            ListFooterComponent={
                                this.state.moreLoading && 
                                <View style={{alignItems : "center", padding : 8 }}>
                                    <CircularProgress 
                                        size={ 32 }
                                    />
                                </View>
                            }
                        />
                    }
                </View>
                {
                    this.state.showAlert && 
                    <Alert 
                        onClose={()=>{ this.goBack() }}
                        severity="error"
                    > 
                        <AlertTitle>Error</AlertTitle>
                        { this.state.alertMessage }
                    </Alert>
                }
            </View>
        )
    }
}


export default ContentTypePage; 