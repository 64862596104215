import React, { Component } from 'react';
import { StyleSheet, View, FlatList, Image, Dimensions } from 'react-native';
import { code_ok, code_error, code_auth_error, code_empty, homepageRecommended, refreshToken } from '../../constants/api';
import { Skeleton, Typography, Card, CardActionArea, CardMedia, CardContent, Button } from '@mui/material';
import { executeGet, executePost, truncateText, navigateContentDetail, updateAuthHeader } from '../../utilities/Utilities';
import AlertDialog from '../AlertDialog';
import { color_text, sub_text, primary } from '../../constants/colors';
import * as Identifier from '../../constants/PageIdentifiers';
import {
    IconKoleksiSD, IconKoleksiSMA, IconKoleksiSMP, IconStatistikBp,
    IconP3smpt, IconProgramApresiasi, IconFileManajemen, IconProgramPembinaan, IconStatistikPembinaan, IconTutorial, IconFaq,
    IconDashboardTitikbaca
} from '../../assets/Icons';
import LoadingDialog from '../LoadingDialog';

var subdomain = "pwa";
const email = localStorage.getItem('email');
console.log('email di local_desk = ' + email)
console.log('email di global_desk = ' + global.email)

class MainMenuDesktop extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            showAlert: false,
            showLoadingDialog: false,
            loadingMessage: '',
            alertMessage: "",
            loadingMessage: "",
            code: this.props.code,
            data: [],
            dummy_data: [
                { i: 0 }, { i: 1 }, { i: 2 }
            ]

        }

        this.handleAlertClose = this.handleAlertClose.bind(this);
        this.handleMoreClick = this.handleMoreClick.bind(this);
        this.fetchContents = this.fetchContents.bind(this);
        // this.renderItem = this.renderItem.bind(this);
        this.handleImageError = this.handleImageError.bind(this);
        this.contentDetail = this.contentDetail.bind(this);
    }

    componentDidMount = () => {

        const domainName = window.location.hostname;
        let adom = domainName.split(".");
        subdomain = adom[0];
        this.fetchContents();
    }

    handleAlertClose = () => {

    }
    handleMoreClick = (code, title) => {
        this.props.navigation.navigate("SectionDetail", { identifier: Identifier.SECTION_DETAIL, code: code, title: title, origins: "CONTENT_RECOMMENDED" });
    }

    fetchContents = async () => {

        let formData = new FormData();
        formData.append("page", 1);
        formData.append("homepage_code", this.props.code)
        formData.append("limit", 3);

        const response = await executePost(homepageRecommended, formData);
        if (response.code == code_ok) {
            console.log(JSON.stringify(response));
            console.log(JSON.stringify(this.state.data));
            this.setState({
                showLoading: false,
                // data : response.data
            })
        } else {
            if (response.code != code_auth_error) {
                this.setState({
                    showLoading: false,
                    alertMessage: response.msg,
                    showAlert: true
                })
            } else {
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            }
        }
    }

    contentDetail = (item, index) => {
        let params = {
            previous: Identifier.MAIN,
            parameters: {},
            content_code: item.content_code
        }
        navigateContentDetail(this.props.navigation, params);
    }
    handleImageError = (item, index) => {
        let data = [...this.state.data];
        let obj = { ...data[index] };
        obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
        data[index] = obj;
        this.setState({
            data: data
        })
    }

    actionP3SMPT = async (mainUrl) => {
        this.setState({
            showLoadingDialog : true,
            loadingMessage : "Mohon Menunggu"
        });
        // refreshToken
        let response = await executeGet(refreshToken);
        console.log('refreshToken = ' + JSON.stringify(response))
        if (response.code == code_ok) {
            updateAuthHeader(response)
            console.log("refreshToken = " + response.client_token)
            // this.checkBeforeEdukasi();
            this.setState({
                showLoadingDialog : false
            }, () => {
                window.location.href = mainUrl + "?platform=" + global.platform + "&os=" + global.os + "&token=" + response.client_token + "&dev=" + subdomain;
            });
            
            // let uri = mainUrl + "?platform=" + global.platform + "&token=" + response.client_token + "&dev=" + subdomain;
            // this.props.navigation.navigate("WebViewerP3smpt", uri);
        } else {
            if (response.code == code_auth_error) {
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    showLoadingDialog: false,
                    alertMessage: response.msg
                })
            }
        }
    }

    render() {
        const level = localStorage.getItem('level');
        return (
            <View style={{ width: "100%", paddingLeft: 147, paddingRight: 147 }}>
                <LoadingDialog
                    open={ this.state.showLoadingDialog }
                    message={ this.state.loadingMessage }
                />
                {
                    this.state.showLoading &&
                    <View>
                        <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: '2rem', maxWidth: 120 }}
                            style={{ marginLeft: 16, marginBottom: 8, marginRight: 16, marginTop: 16 }}
                        />
                        <Skeleton
                            variant="rectangular"
                            style={{ height: 90, marginTop: 8, borderRadius: 8, marginLeft: 16, marginRight: 16 }}
                            animation="wave"
                        />
                        <Skeleton
                            variant="rectangular"
                            style={{ height: 90, marginTop: 8, borderRadius: 8, marginLeft: 16, marginRight: 16 }}
                            animation="wave"
                        />
                        <Skeleton
                            variant="rectangular"
                            style={{ height: 90, marginTop: 8, borderRadius: 8, marginLeft: 16, marginRight: 16 }}
                            animation="wave"
                        />
                    </View>
                }
                {
                    !this.state.showLoading &&
                    <>
                        {
                            level == "Admin Pusat" &&
                            <>
                                <View style={{ marginTop: 30 }}>
                                    <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 20 }}>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={28}
                                            variant="h3"
                                            style={{ flex: 1, marginLeft: 10 }}
                                            color={color_text}
                                            fontWeight={"600"}
                                        >
                                            {truncateText(this.props.title, 100)}
                                        </Typography>
                                    </View>
                                    <View style={[styles.container, {
                                        // Try setting `flexDirection` to `"row"`.
                                        flexDirection: "row"
                                    }]}>
                                        <View style={{ flex: 1 }} >
                                            <CardActionArea sx={{ cursor: "pointer" }}
                                                // onClick={() => { window.location.href = "https://bintangpusnas.perpusnas.go.id/web/office/p3smpt-menu?platform=" + global.platform + "&token=" + global.token + "&dev=" + subdomain }}
                                                onClick={() => this.actionP3SMPT("https://bintangpusnas.perpusnas.go.id/web/office/p3smpt-menu?platform=" + global.platform + "&token=" + global.token + "&dev=" + subdomain)}
                                            >
                                                <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}>
                                                    <View
                                                        style={{
                                                            borderRadius: 8,
                                                            marginLeft: 16,
                                                            marginRight: 30
                                                        }}
                                                    >
                                                        <IconP3smpt width={80} height={80} />
                                                    </View>
                                                    <View style={{ flex: 1 }}>
                                                        <Typography
                                                            fontFamily="Open Sans"
                                                            fontSize={20}
                                                            color={color_text}
                                                            fontWeight={"600"}
                                                        >
                                                            P3SMPT
                                                        </Typography>
                                                        <Typography
                                                            fontFamily="Open Sans"
                                                            fontSize={16}
                                                            color={sub_text}
                                                            style={{ marginTop: '4px', paddingRight: 8 }}
                                                        >
                                                            Pengembangan Pembinaan Perpustakaan Sekolah/Madrasah dan Perguruan Tinggi
                                                        </Typography>
                                                        <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px' }} />
                                                    </View>
                                                </View>
                                            </CardActionArea>

                                            <CardActionArea sx={{ cursor: "pointer" }}
                                                // onClick={() => { window.location.href = "https://bintangpusnas.perpusnas.go.id/web/office/apresiasi-menu?platform=" + global.platform + "&token=" + global.token + "&dev=" + subdomain }}
                                                onClick={() => this.actionP3SMPT("https://bintangpusnas.perpusnas.go.id/web/office/apresiasi-menu?platform=" + global.platform + "&token=" + global.token + "&dev=" + subdomain)}
                                            >
                                                <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}>
                                                    <View
                                                        style={{
                                                            borderRadius: 8,
                                                            marginLeft: 16,
                                                            marginRight: 30
                                                        }}
                                                    >
                                                        <IconProgramApresiasi width={80} height={80} />
                                                    </View>
                                                    <View style={{ flex: 1 }}>
                                                        <Typography
                                                            fontFamily="Open Sans"
                                                            fontSize={20}
                                                            color={color_text}
                                                            fontWeight={"600"}
                                                        >
                                                            Program Apresiasi
                                                        </Typography>
                                                        <Typography
                                                            fontFamily="Open Sans"
                                                            fontSize={16}
                                                            color={sub_text}
                                                            style={{ marginTop: '4px', paddingRight: 8 }}
                                                        >
                                                            Program untuk memberikan apresiasi kepada lembaga perpustakaan, pustakawan, dan pemustaka
                                                        </Typography>
                                                        <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px' }} />
                                                    </View>
                                                </View>
                                            </CardActionArea>

                                            <CardActionArea sx={{ cursor: "pointer" }}
                                                // onClick={() => { window.location.href = "https://bintangpusnas.perpusnas.go.id/web/office/kegiatan-pembinaan?platform=" + global.platform + "&token=" + global.token + "&dev=" + subdomain }}
                                                onClick={() => this.actionP3SMPT("https://bintangpusnas.perpusnas.go.id/web/office/kegiatan-pembinaan?platform=" + global.platform + "&token=" + global.token + "&dev=" + subdomain)}
                                            >
                                                <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}>
                                                    <View
                                                        style={{
                                                            borderRadius: 8,
                                                            marginLeft: 16,
                                                            marginRight: 30
                                                        }}
                                                    >
                                                        <IconProgramPembinaan width={80} height={80} />
                                                    </View>
                                                    <View style={{ flex: 1 }}>
                                                        <Typography
                                                            fontFamily="Open Sans"
                                                            fontSize={20}
                                                            color={color_text}
                                                            fontWeight={"600"}
                                                        >
                                                            Program Pembinaan
                                                        </Typography>
                                                        <Typography
                                                            fontFamily="Open Sans"
                                                            fontSize={16}
                                                            color={sub_text}
                                                            style={{ marginTop: '4px', paddingRight: 8 }}
                                                        >
                                                            Program untuk melakukan pembinaan perpustakaan sehingga memenuhi Standar Nasional Perpustakaan
                                                        </Typography>
                                                        <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px' }} />
                                                    </View>
                                                </View>
                                            </CardActionArea>
                                        </View>
                                        <View style={{ flex: 1 }} >
                                            <CardActionArea sx={{ cursor: "pointer" }}
                                                // onClick={() => { window.location.href = "https://bintangpusnas.perpusnas.go.id/web/office/statistik-pembinaan-menu?platform=" + global.platform + "&token=" + global.token + "&dev=" + subdomain }}
                                                onClick={() => this.actionP3SMPT("https://bintangpusnas.perpusnas.go.id/web/office/statistik-pembinaan-menu?platform=" + global.platform + "&token=" + global.token + "&dev=" + subdomain)}
                                            >
                                                <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}>
                                                    <View
                                                        style={{
                                                            borderRadius: 8,
                                                            marginLeft: 16,
                                                            marginRight: 30
                                                        }}
                                                    >
                                                        <IconStatistikPembinaan width={80} height={80} />
                                                    </View>
                                                    <View style={{ flex: 1 }}>
                                                        <Typography
                                                            fontFamily="Open Sans"
                                                            fontSize={20}
                                                            color={color_text}
                                                            fontWeight={"600"}
                                                        >
                                                            Statistik Pembinaan
                                                        </Typography>
                                                        <Typography
                                                            fontFamily="Open Sans"
                                                            fontSize={16}
                                                            color={sub_text}
                                                            style={{ marginTop: '4px', paddingRight: 8 }}
                                                        >
                                                            Capaian pembinaan, dan analisa pembinaan digunakan untuk menentukan program pembinaan
                                                        </Typography>
                                                        <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px' }} />
                                                    </View>
                                                </View>
                                            </CardActionArea>

                                            <CardActionArea sx={{ cursor: "pointer" }}
                                                // onClick={() => { window.location.href = "https://bintangpusnas.perpusnas.go.id/web/office/bp-statistik-koleksi-menu?platform=" + global.platform + "&token=" + global.token + "&dev=" + subdomain }}
                                                onClick={() => this.actionP3SMPT("https://bintangpusnas.perpusnas.go.id/web/office/bp-statistik-koleksi-menu?platform=" + global.platform + "&token=" + global.token + "&dev=" + subdomain)}
                                            >
                                                <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}>
                                                    <View
                                                        style={{
                                                            borderRadius: 8,
                                                            marginLeft: 16,
                                                            marginRight: 30
                                                        }}
                                                    >
                                                        <IconStatistikBp width={80} height={80} />
                                                    </View>
                                                    <View style={{ flex: 1 }}>
                                                        <Typography
                                                            fontFamily="Open Sans"
                                                            fontSize={20}
                                                            color={color_text}
                                                            fontWeight={"600"}
                                                        >
                                                            Statistik BintangPusnas Edu
                                                        </Typography>
                                                        <Typography
                                                            fontFamily="Open Sans"
                                                            fontSize={16}
                                                            color={sub_text}
                                                            style={{ marginTop: '4px', paddingRight: 8 }}
                                                        >
                                                            Semua data koleksi dan pemanfaatannya, karakter pemustaka dan tingkat antusias pemustaka
                                                        </Typography>
                                                        <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px' }} />
                                                    </View>
                                                </View>
                                            </CardActionArea>

                                            <CardActionArea sx={{ cursor: "pointer" }}
                                                // onClick={() => { window.location.href = "https://bintangpusnas.perpusnas.go.id/web/office/administrasi?platform=" + global.platform + "&token=" + global.token + "&dev=" + subdomain }}
                                                onClick={() => this.actionP3SMPT("https://bintangpusnas.perpusnas.go.id/web/office/administrasi?platform=" + global.platform + "&token=" + global.token + "&dev=" + subdomain)}
                                            >
                                                <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}>
                                                    <View
                                                        style={{
                                                            borderRadius: 8,
                                                            marginLeft: 16,
                                                            marginRight: 30
                                                        }}
                                                    >
                                                        <IconFileManajemen width={80} height={80} />
                                                    </View>
                                                    <View style={{ flex: 1 }}>
                                                        <Typography
                                                            fontFamily="Open Sans"
                                                            fontSize={20}
                                                            color={color_text}
                                                            fontWeight={"600"}
                                                        >
                                                            Regulasi Perpustakaan
                                                        </Typography>
                                                        <Typography
                                                            fontFamily="Open Sans"
                                                            fontSize={16}
                                                            color={sub_text}
                                                            style={{ marginTop: '4px', paddingRight: 8 }}
                                                        >
                                                            Berisi regulasi dan kebijakan publik tentang Perpustakaan Nasional Indonesia
                                                        </Typography>
                                                        <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px' }} />
                                                    </View>
                                                </View>
                                            </CardActionArea>
                                        </View>
                                    </View>
                                </View>
                            </>
                        }
                        {
                            level == "Admin Pusat" &&
                            <>
                                <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 20, marginTop: 20 }}>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={28}
                                        variant="h3"
                                        style={{ flex: 1, marginLeft: 10 }}
                                        color={color_text}
                                        fontWeight={"600"}
                                    >
                                        {truncateText('Titikbaca Perpusnas RI', 100)}
                                    </Typography>
                                </View>
                                <View style={[styles.container, {
                                    // Try setting `flexDirection` to `"row"`.
                                    flexDirection: "row"
                                }]}>
                                    <View style={{ flex: 1 }} >
                                        <CardActionArea sx={{ cursor: "pointer" }}
                                            onClick={() => this.actionP3SMPT('https://kubuku.id/titikbacaPnriDashboard/public/')}
                                        >
                                            <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}>
                                                <View
                                                    style={{
                                                        borderRadius: 8,
                                                        marginLeft: 16,
                                                        marginRight: 30
                                                    }}
                                                >
                                                    <IconDashboardTitikbaca width={80} height={80} />
                                                </View>
                                                <View style={{ flex: 1 }}>
                                                    <Typography
                                                        fontFamily="Open Sans"
                                                        fontSize={20}
                                                        color={color_text}
                                                        fontWeight={"600"}
                                                    >
                                                        Dashboard Titikbaca Perpusnas RI
                                                    </Typography>
                                                    {/* <Typography
                                                        fontFamily="Open Sans"
                                                        fontSize={16}
                                                        color={sub_text}
                                                        style={{ marginTop: '4px', paddingRight: 8 }}
                                                    >
                                                        Pengembangan Pembinaan Perpustakaan Sekolah/Madrasah dan Perguruan Tinggi
                                                    </Typography> */}
                                                    <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px' }} />
                                                </View>
                                            </View>
                                        </CardActionArea>
                                        <CardActionArea sx={{ cursor: "pointer" }}
                                            onClick={() => this.actionP3SMPT('https://bintangpusnas.perpusnas.go.id/web/office/literasi')}
                                        >
                                            <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}>
                                                <View
                                                    style={{
                                                        borderRadius: 8,
                                                        marginLeft: 16,
                                                        marginRight: 30
                                                    }}
                                                >
                                                    <IconP3smpt width={80} height={80} />
                                                </View>
                                                <View style={{ flex: 1 }}>
                                                    <Typography
                                                        fontFamily="Open Sans"
                                                        fontSize={20}
                                                        color={color_text}
                                                        fontWeight={"600"}
                                                    >
                                                        Statistik Titikbaca Perpusnas RI
                                                    </Typography>
                                                    {/* <Typography
                                                        fontFamily="Open Sans"
                                                        fontSize={16}
                                                        color={sub_text}
                                                        style={{ marginTop: '4px', paddingRight: 8 }}
                                                    >
                                                        Pengembangan Pembinaan Perpustakaan Sekolah/Madrasah dan Perguruan Tinggi
                                                    </Typography> */}
                                                    <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px' }} />
                                                </View>
                                            </View>
                                        </CardActionArea>
                                    </View>
                                </View>


                            </>
                        }

                        {
                            level == "Admin PUPK" &&
                            <>
                                <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 20 }}>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={28}
                                        variant="h3"
                                        style={{ flex: 1, marginLeft: 10 }}
                                        color={color_text}
                                        fontWeight={"600"}
                                    >
                                        {truncateText('Titikbaca Perpusnas RI', 100)}
                                    </Typography>
                                </View>
                                <View style={[styles.container, {
                                    // Try setting `flexDirection` to `"row"`.
                                    flexDirection: "row"
                                }]}>
                                    <View style={{ flex: 1 }} >
                                        <CardActionArea sx={{ cursor: "pointer" }}
                                            onClick={() => this.actionP3SMPT('https://kubuku.id/titikbacaPnriDashboard/public/')}
                                        >
                                            <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}>
                                                <View
                                                    style={{
                                                        borderRadius: 8,
                                                        marginLeft: 16,
                                                        marginRight: 30
                                                    }}
                                                >
                                                    <IconDashboardTitikbaca width={80} height={80} />
                                                </View>
                                                <View style={{ flex: 1 }}>
                                                    <Typography
                                                        fontFamily="Open Sans"
                                                        fontSize={20}
                                                        color={color_text}
                                                        fontWeight={"600"}
                                                    >
                                                        Dashboard Titikbaca Perpusnas RI
                                                    </Typography>
                                                    {/* <Typography
                                                        fontFamily="Open Sans"
                                                        fontSize={16}
                                                        color={sub_text}
                                                        style={{ marginTop: '4px', paddingRight: 8 }}
                                                    >
                                                        Pengembangan Pembinaan Perpustakaan Sekolah/Madrasah dan Perguruan Tinggi
                                                    </Typography> */}
                                                    <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px' }} />
                                                </View>
                                            </View>
                                        </CardActionArea>
                                        <CardActionArea sx={{ cursor: "pointer" }}
                                            onClick={() => this.actionP3SMPT('https://bintangpusnas.perpusnas.go.id/web/office/literasi')}
                                        >
                                            <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}>
                                                <View
                                                    style={{
                                                        borderRadius: 8,
                                                        marginLeft: 16,
                                                        marginRight: 30
                                                    }}
                                                >
                                                    <IconP3smpt width={80} height={80} />
                                                </View>
                                                <View style={{ flex: 1 }}>
                                                    <Typography
                                                        fontFamily="Open Sans"
                                                        fontSize={20}
                                                        color={color_text}
                                                        fontWeight={"600"}
                                                    >
                                                        {/* Kelola Literasi Keluarga */}
                                                        Statistik Titikbaca Perpusnas RI
                                                    </Typography>
                                                    {/* <Typography
                                                        fontFamily="Open Sans"
                                                        fontSize={16}
                                                        color={sub_text}
                                                        style={{ marginTop: '4px', paddingRight: 8 }}
                                                    >
                                                        Pengembangan Pembinaan Perpustakaan Sekolah/Madrasah dan Perguruan Tinggi
                                                    </Typography> */}
                                                    <View style={{ border: '0.5px solid #E8E8F2', marginTop: '10px' }} />
                                                </View>
                                            </View>
                                        </CardActionArea>
                                    </View>
                                </View>


                            </>

                        }

                    </>
                }


            </View>
        )
    }

}

const styles = {
    container: {
        flex: 1
    },
    media: {
        height: 0,
        paddingTop: '56.25%' // 16:9
    },
    card: {
        position: 'relative',
        margin: '16px',
        borderRadius: '15px'
    },
    overlay: {
        position: 'absolute',
        bottom: '39px',
        left: '20px',
        color: 'white',
        fontSize: '18px'
    },
    overlaySub: {
        position: 'absolute',
        bottom: '12px',
        left: '20px',
        color: 'white',
        fontSize: '12px'
    }
}

export default MainMenuDesktop;