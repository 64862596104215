import React, { Component } from 'react';
import { StyleSheet, View, FlatList, Image, Dimensions } from 'react-native';
import { code_ok, code_error, code_auth_error, code_empty, homepageNews, widgetTutorialEdukasi } from '../../constants/api';
import { Skeleton, Typography, Card, CardActionArea, CardMedia, CardContent, Button } from '@mui/material';
import { executeGet, executePost, truncateText, getQueryStrings, isEven, navigateContentDetail } from '../../utilities/Utilities';
import AlertDialog from '../../components/AlertDialog';
import { color_text, sub_text, primary } from '../../constants/colors';
import * as Identifier from '../../constants/PageIdentifiers';

class WidgetInfoLiterasi extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            showAlert: false,
            alertMessage: "",
            loadingMessage: "",
            code: this.props.code,
            data: [
                    {
                        "id": "7",
                        "creator_id": "1021",
                        "title": "Perpustakaan Daerah Kendal",
                        "full_name": "Perpustakaan Daerah Kendal",
                        "thumb": "https://bintangpusnas.perpusnas.go.id/images/kendal.png",
                        "cover": "https://bintangpusnas.perpusnas.go.id/images/kendal.png",
                        "video_url": "0",
                        "video_folder": "Seni%20dan%20Kiat%20bernegosiasi"
                    },
                    {
                        "id": "5",
                        "creator_id": "1021",
                        "title": "Do and Don't di perpustakaan",
                        "full_name": "Kompas.com",
                        "thumb": "https://bintangpusnas.perpusnas.go.id/images/kompas.png",
                        "cover": "https://bintangpusnas.perpusnas.go.id/images/kompas.png",
                        "video_url": "1",
                        "video_folder": "Strategi%20Menjual%20Apapun"
                    },
                    {
                        "id": "3",
                        "creator_id": "1021",
                        "title": "Layanan Perpustakaan Bung Karno",
                        "full_name": "Perpustakaan Bung Karno",
                        "thumb": "https://bintangpusnas.perpusnas.go.id/images/bungkarno.png",
                        "cover": "https://bintangpusnas.perpusnas.go.id/images/bungkarno.png",
                        "video_url": "2",
                        "video_folder": "Data%20Science%20and%20AI"
                    },
                    {
                        "id": "6",
                        "creator_id": "1021",
                        "title": "Literasi setiap akhir bulan",
                        "full_name": "SMA3 Kota Bekasi TV ",
                        "thumb": "https://bintangpusnas.perpusnas.go.id/images/sma.png",
                        "cover": "https://bintangpusnas.perpusnas.go.id/images/sma.png",
                        "video_url": "3",
                        "video_folder": "Kesalahan%20Utama%20dalam%20Menjual"
                    }
                ]
                ,
            dummy_data: [
                { i: 0 }, { i: 1 }, { i: 2 }
            ]
        }

        this.renderSkeleton = this.renderSkeleton.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.fetchNews = this.fetchNews.bind(this);
        this.handleNewsClick = this.handleNewsClick.bind(this);
        this.handleMoreClick = this.handleMoreClick.bind(this); 
    }

    componentDidMount = () => {
        this.fetchNews();
    }

    handleMoreClick = (code, title)=>{
        console.log("CODE ");
        console.log(code);
        this.props.navigation.navigate("News", { identifier : Identifier.NEWS, code : code, title : title, origins : "NEWS"});
    }
    
    gotoVideo = (item,index) => {
        location.replace("https://bintangpusnas.perpusnas.go.id/infoliterasi/?video=" + item.video_url);
    }
    contentDetail = (item,index)=>{
        console.log("edukasi 1 = " + JSON.stringify(this.props.navigation));
        console.log("edukasi 2 = " + JSON.stringify(item));
        // navigateContentDetail(this.props.navigation, item); 
        this.props.navigation.navigate("DetailEdukasi", item); 
    }

    renderSkeleton = ({ item, index, separators }) => {
        return (
            <View style={{
                width: 220,
                marginLeft: (index == 0 ? 16 : 0),
                marginRight: (index == (this.state.dummy_data.length - 1) ? 16 : 8)
            }}>
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    style={{ borderRadius: 8, width: 220, height: 110, marginBottom: 8 }}
                />
                <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "1.5rem", width: 180 }}
                    style={{ marginBottom: 4 }}
                />
                <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "1.2rem", width: 120 }}
                    style={{ marginBottom: 4 }}
                />
            </View>
        )
    }

    renderItem = ({ item, index, separators }) => {
        console.log("YESS")
        let window_width = Dimensions.get("window").width; 
        let book_width = (window_width / 2.2) - 16 ; 
        let book_height = book_width + 10;
        console.log("KONTENNN");
        console.log(item);
        return (
            
            <Card
                style={{ 
                    width : book_width, 
                    borderRadius : 8, 
                    marginLeft : 4, 
                    marginRight : 4,
                    marginTop : 8,
                    marginBottom: 8
                }}
            >
                <CardActionArea
                    sx={{ height : "100%"}}
                    onClick={()=>{ this.gotoVideo(item, index)}}
                >
                    <Image
                        style={{
                            width : book_width,
                            height : book_height
                        }}
                        resizeMode={"cover"}
                        source={{ uri : item.cover }}
                        onError={(error)=>{ this.onImageError(item, index)}}
                    />


                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 14 }
                        color={ color_text } 
                        fontWeight={"600"}
                        style={{ 
                            marginLeft : 12, marginTop : 8, marginBottom : 8, marginRight : 12 
                        }}
                    >
                        { truncateText(item.title, 24)  }
                    </Typography>
                    <Typography
                        fontFamily="Open Sans"
                        color={ sub_text }
                        fontSize={ 12 }
                        style={{
                            marginLeft : 12 ,
                            marginBottom : 12, 
                            marginRight : 12
                        }}
                    >
                        { truncateText(item.full_name, 20 )}
                    </Typography>
                </CardActionArea>
            </Card>
        )
    }
    fetchNews = async () => {
        
        const response = await executePost(widgetTutorialEdukasi);
        console.log("widgetTutorialEdukasi news = " + JSON.stringify(response))
        console.log("FETCH");
        console.log(response);
        console.log(response.data);
        if (response.code == code_ok) {
            this.setState({
                showLoading: false,
                //data: response.data
            })

        } else {
            if (response.code != code_auth_error) {
                this.setState({
                    showLoading: false,
                })
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, response.msg);
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : response.msg })
                // }
            } else {
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            }
        }
    }

    handleNewsClick = (item, index) => {
        item.identifier = Identifier.MAIN;
        this.props.navigation.navigate("NewsDetail", item);
    }

    render() {
        return (
            <View style={{ width: "100%" }}>
                
                {
                    
                    <View style={{ marginBottom: 16 }}>
                        <View style={{flexDirection : "row", alignItems : "center"}}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={16}
                                variant="h3"
                                color={color_text}
                                fontWeight={"600"}
                                style={{ flex: 1, marginLeft: 16, marginTop: 16, marginBottom: 16 }}
                            >
                                {truncateText('Ragam Perpustakaan', 20)}
                            </Typography>
                            
                        </View>
                        {/* <HomeYoutube /> */}
                        {console.log(this.state.data)}
                        <FlatList
                            keyExtractor={(index) => index}
                            data={this.state.data}
                            horizontal={true}
                            renderItem={this.renderItem}
                        />
                    </View>
                }

            </View>
        )
    }
}

export default WidgetInfoLiterasi;