import React, { Component } from 'react';
import { View, StyleSheet, Image, FlatList, Dimensions, ScrollView, TextInput } from 'react-native';
import { Alert, AlertTitle, Typography, Skeleton, Card, CardActionArea, CircularProgress, IconButton, Chip } from '@mui/material';
import LoadingPage from '../components/LoadingPage';
import AlertDialog from '../components/AlertDialog';
import * as Identifier from '../constants/PageIdentifiers';
import * as Command from '../constants/Commands';
import { code_ok, code_empty, code_error, code_auth_error, sectionContent, sectionContentRecommended } from '../constants/api';
import { executeGet, executePost, isEven, truncateText, navigateContentDetail } from '../utilities/Utilities';
import EmptySearchPage from '../components/EmptySearchPage';
import { color_text, sub_text, primary } from '../constants/colors';
import SortIcon from '@mui/icons-material/Sort';
import SortDialog from '../components/SortDialog';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { NavSearchIcon } from '../assets/NavIcons';
import Plyr from "plyr-react";
import "plyr-react/plyr.css"

// import "plyr-react/dist/plyr.css"

var _is_mounted = false;
var window_width;
var book_width;
var book_height;


class DesktopPlayer extends Component {
    //ANCHOR - Constructor
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            moreLoading: false,
            loadingMessage: "",
            showAlert: false,
            alertTitle: "Perhatian",
            alertMessage: "",
            code: this.props.route.params.code,
            title: this.props.route.params.title,
            videoSrc: {
                type: "audio",
                sources: [
                    {
                        src: " https://bintangpusnas.perpusnas.go.id/api/konten_pnri/audiobook/" + this.props.route.params.eisbn + ".mp3"
                    }
                ]
            },
            data: [],
            dummy_data: [
                { i: 0 }, { i: 1 }, { i: 2 }, { i: 3 }, { i: 0 }, { i: 1 }, { i: 2 }, { i: 3 }
            ],
            page: 1,
            total: 0,
            show: 0,
            showSortDialog: true,
            sort_type: -1,
            sort_menu: [
                { id: "001", type: 1, title: "Judul A-Z" }, { id: "002", type: 2, title: "Judul Z-A" },
                { id: "003", type: 3, title: "Penulis A-Z" }, { id: "004", type: 4, title: "Penulis Z-A" }
            ],
            data_sub_kategori: [],
            selected_sub_kategori: "",
            selected_kode_sub_kategori: "",
            keyword: "",
            searchMode: false,
            searchFocus: false
        }
        this.goBack = this.goBack.bind(this);
        this.renderSkeleton = this.renderSkeleton.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.fetchContent = this.fetchContent.bind(this);
        this.onImageError = this.onImageError.bind(this);
        this.fetchMoreData = this.fetchMoreData.bind(this);
        this.handleSortClick = this.handleSortClick.bind(this);
        this.handleSortMenuClick = this.handleSortMenuClick.bind(this);
        this.renderSortMenu = this.renderSortMenu.bind(this);
        this.contentDetail = this.contentDetail.bind(this);
        this.renderSubKategori = this.renderSubKategori.bind(this);
        this.handleSubKategoriClick = this.handleSubKategoriClick.bind(this);
        this.handleSearchIconClick = this.handleSearchIconClick.bind(this);
        this.onSearchInputFocus = this.onSearchInputFocus.bind(this);
        this.onSearchInputBlur = this.onSearchInputBlur.bind(this);
        this.handleSearchPress = this.handleSearchPress.bind(this);
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);

    }

    componentDidMount = () => {
        console.log('param bro = ' + JSON.stringify(this.props.route.params))
        //console.log("WIDTH : " +book_width); 
        //console.log("HEIGHT : " + book_height);
        _is_mounted = true;

        window.addEventListener("message", (event) => {
            if (event.data.id == "go_back") {
                if (event.data.page == "PlayerMobile") {
                    this.goBack();
                }
            }
        });

        if (this.props.route.params.type == 'Video') {
            this.setState({
                videoSrc: {
                    type: "video",
                    sources: [
                        {
                            src: " https://bintangpusnas.perpusnas.go.id/api/konten_pnri/audiobook/" + this.props.route.params.nama_file
                        }
                    ]
                }
            })
        } else {
            this.setState({
                videoSrc: {
                    type: "audio",
                    sources: [
                        {
                            src: " https://bintangpusnas.perpusnas.go.id/api/konten_pnri/audiobook/" + this.props.route.params.nama_file
                        }
                    ]
                }
            })
        }
    }

    componentWillUnmount = () => {
        _is_mounted = false;
        window.removeEventListener("message", null);
    }

    goBack = () => {
        if (!this.state.searchMode) {
            // this.props.navigation.navigate(Identifier.MAIN);
            this.props.navigation.goBack()
        } else {
            this.setState({
                searchMode: false
            })
        }
    }

    //ANCHOR - Fetch Content
    fetchContent = async () => {
        if (_is_mounted) {
            if (this.state.page == 1) {
                this.setState({
                    showLoading: true
                })
            } else {
                this.setState({
                    moreLoading: true
                })
            }

            let formData = new FormData();
            formData.append("page", this.state.page);
            formData.append("limit", 50);
            formData.append("code", this.state.code);
            if (this.state.sort_type != -1) {
                formData.append("sort_by", this.state.sort_type);
            }

            let url;
            if (this.props.route.params.origins == "CONTENT_SECTION") {
                url = sectionContent;
                if (this.state.selected_kode_sub_kategori != "") {
                    formData.append("code_sub_category", this.state.selected_kode_sub_kategori);
                }
                formData.append("keyword", this.state.keyword);

            } else {
                url = sectionContentRecommended;
            }

            const response = await executePost(url, formData);
            if (response.code == code_ok) {
                console.log(JSON.stringify(response));
                if (parseInt(response.show) > 0) {
                    let show = parseInt(response.show) + parseInt(this.state.show);
                    let total = parseInt(response.total);
                    let data = [...this.state.data, ...response.data];

                    let data_sub_kategori;
                    if (response.hasOwnProperty("sub_category")) {
                        data_sub_kategori = [...response.sub_category];
                    } else {
                        data_sub_kategori = [...this.state.data_sub_kategori];
                    }

                    this.setState({
                        total: total,
                        show: show,
                        data: data,
                        moreLoading: false,
                        showLoading: false,
                        data_sub_kategori: data_sub_kategori
                    })
                }

                if (parseInt(response.total) == 0) {
                    this.setState({
                        showLoading: false,
                        moreLoading: false,
                        alertTitle: "Tidak ditemukan",
                        alertMessage: "Mohon maaf, data dihalaman ini tidak ditemukan"
                    })
                }
            } else {
                if (response.code == code_auth_error) {
                    window.postMessage({ id: "sign_out" });
                } else {
                    this.setState({
                        showLoading: false,
                        showAlert: true,
                        alertMessage: response.msg
                    })
                }
            }

        }
    }

    renderSkeleton = ({ item, index, separators }) => {

        return (
            <View
                style={{
                    width: book_width,
                    borderRadius: 8,
                    marginLeft: (isEven(index) ? 12 : 4),
                    marginRight: (!isEven(index)) ? 16 : 4,
                    marginBottom: 8,
                }}
            >
                <Skeleton
                    style={{
                        borderRadius: 8
                    }}
                    width={book_width}
                    height={book_height}
                    variant="rectangular"
                    animation="wave"
                />

                <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "1.5rem" }}
                    style={{
                        marginLeft: 12, marginTop: 8, marginRight: 12
                    }}
                />
                <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "1.5rem" }}
                    style={{
                        marginLeft: 12,
                        marginBottom: 12,
                        marginRight: 12
                    }}
                />
            </View>
        )
    }
    renderItem = ({ item, index, separators }) => {
        return (
            <Card
                style={{
                    width: book_width,
                    borderRadius: 8,
                    marginLeft: (isEven(index) ? 16 : 4),
                    marginRight: (!isEven(index)) ? 16 : 4,
                    marginBottom: 8,

                }}
            >
                <CardActionArea
                    sx={{ height: "100%" }}
                    onClick={() => { this.contentDetail(item, index) }}
                >
                    <Image
                        style={{
                            width: book_width,
                            height: book_height,
                        }}
                        resizeMode={"cover"}
                        source={{ uri: item.cover }}
                        onError={(error) => { this.onImageError(item, index) }}
                    />


                    <Typography
                        fontFamily="Open Sans"
                        fontSize={14}
                        color={color_text}
                        fontWeight={"600"}
                        style={{
                            marginLeft: 12, marginTop: 8, marginBottom: 8, marginRight: 12
                        }}
                    >
                        {truncateText(item.title, 32)}
                    </Typography>
                    <Typography
                        fontFamily="Open Sans"
                        color={sub_text}
                        fontSize={12}
                        style={{
                            marginLeft: 12,
                            marginBottom: 12,
                            marginRight: 12
                        }}
                    >
                        {truncateText(item.author, 20)}
                    </Typography>
                </CardActionArea>
            </Card>
        )
    }

    contentDetail = (item, index) => {
        let params = {
            previous: Identifier.SECTION_DETAIL,
            parameters: this.props.route.params,
            content_code: item.code
        }
        navigateContentDetail(this.props.navigation, params);
    }
    onImageError = (item, index) => {
        if (_is_mounted) {
            let data = [...this.state.data];
            let obj = { ...data[index] };
            obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
            data[index] = obj;
            this.setState({
                data: data
            })
        }
    }

    fetchMoreData = () => {
        if (_is_mounted && !this.state.showLoading && !this.state.moreLoading) {
            if (this.state.show < this.state.total) {
                let page = parseInt(this.state.page) + 1;
                this.setState({
                    page: page
                }, () => {
                    this.fetchContent();
                })

            }
        }
    }
    handleSortMenuClick = () => {
        if (_is_mounted) {
            if (this.state.showSortDialog) {
                this.setState({
                    showSortDialog: false
                })
            } else {
                this.setState({
                    showSortDialog: true
                })
            }
        }

    }
    handleSortClick = (variant) => {
        if (_is_mounted) {
            this.setState({
                sort_type: variant,
                page: 1,
                total: 0,
                show: 0,
                data: [],
            }, () => {
                this.fetchContent();
            });

        }
    }

    //ANCHOR - Render Sort Menu
    renderSortMenu = ({ item, index, separators }) => {
        return (
            <Chip
                style={{
                    marginRight: (index == 3 ? 16 : 8),
                    marginLeft: (index == 0 ? 16 : 0)
                }}
                variant={this.state.sort_type == item.type ? "contained" : "outlined"}
                label={item.title}
                onClick={() => { this.handleSortClick(item.type) }}
            />
        )
    }
    //ANCHOR - Render Sub Kategori
    renderSubKategori = ({ item, index, separators }) => {
        return (
            <Chip
                style={{
                    marginRight: (index == (this.state.data_sub_kategori.length - 1) ? 16 : 8),
                    marginLeft: (index == 0 ? 16 : 0)
                }}
                variant={this.state.selected_kode_sub_kategori == item.code ? "contained" : "outlined"}
                label={item.title}
                onClick={() => { this.handleSubKategoriClick(item) }}
            />
        )
    }

    //ANCHOR - Handle Sub Kategori Click
    handleSubKategoriClick = (item) => {
        var kode_sub_kategori = "";
        var sub_kategori = "";

        if (item.code != this.state.selected_kode_sub_kategori) {
            kode_sub_kategori = item.code;
            sub_kategori = item.title;
        }

        this.setState({
            selected_kode_sub_kategori: kode_sub_kategori,
            selected_sub_kategori: sub_kategori,
            page: 1,
            total: 0,
            show: 0,
            data: []
        }, () => {
            this.fetchContent()
        })
    }

    //ANCHOR - Handle Search Icon Click 
    handleSearchIconClick = () => {

        if (_is_mounted) {
            if (this.state.searchMode) {
                this.setState({
                    searchMode: false
                })
            } else {
                this.setState({
                    searchMode: true
                })
            }
        }
    }
    onSearchInputFocus = (e) => {
        this.setState({
            searchFocus: true
        })
    }
    onSearchInputBlur = () => {
        this.setState({
            searchFocus: false
        })
    }
    //ANCHOR - Handle Search Text Changed
    handleSearchTextChange = (text) => {
        if (_is_mounted) {
            this.setState({
                keyword: text
            })
        }
    }
    //ANCHOR - Handle Search
    handleSearchPress = (e) => {
        let key = e.nativeEvent.key;
        if (key == "Enter") {
            this.setState({
                page: 1,
                data: [],
                show: 0,
                total: 0,
            }, () => {
                this.fetchContent();
            });
        }
    }

    render() {
        window_width = Dimensions.get("window").width;
        book_width = (window_width / 2) - 16;
        book_height = book_width + 40;

        return (
            <View style={{ flex: 1 }}>
                <View style={{
                    marginLeft: 0,
                    //marginTop : (global.os == "ANDROID" ? 16 : 48 ) ,  
                    marginTop: 0,
                    marginBottom: 16,
                    flex: 0.07,
                    backgroundColor: primary,
                    flexDirection: "row",
                    alignItems: "center"

                }}>

                    <IconButton
                        style={{ marginRight: 16, marginLeft: 8 }}
                        onClick={() => { this.goBack() }}
                    >
                        <ChevronLeftIcon
                            sx={{ color: "white" }}
                        />
                    </IconButton>
                    {
                        !this.state.searchMode &&
                        <>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={16}
                                fontWeight="600"
                                style={{ flex: 1 }}
                                color={"white"}
                            >
                                {this.props.route.params.type + "book"}
                            </Typography>
                        </>
                    }

                </View>
                <View style={{ flex: 0.95, justifyContent: "center", alignItems: "center" }}>
                    {/* konten */}
                    <Image
                        source={{ uri: this.props.route.params.cover }}
                        style={{
                            marginTop: 16,
                            borderRadius: 8,
                            width: 160,
                            height: 200
                        }}
                        onError={(error) => { this.onImageError() }}
                    />
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={18}
                        fontWeight="600"
                        style={{ marginTop: 10, marginBottom: 16 }}
                        color={"primary"}
                    >
                        {this.props.route.params.title}
                    </Typography>
                    <Plyr source={this.state.videoSrc} />
                </View>
                {
                    this.state.showAlert &&
                    <Alert
                        onClose={() => { this.goBack() }}
                        severity="error"
                    >
                        <AlertTitle>Error</AlertTitle>
                        {this.state.alertMessage}
                    </Alert>
                }


            </View>
        )
    }
}
//ANCHOR -   Styles
const styles = StyleSheet.create({
    searchBar: {
        backgroundColor: "#F9FAFC",
        borderRadius: 8,
        borderWidth: 0.2,
        padding: 8,
        flexDirection: "row",
        flex: 1
    },
    searchInput: {
        fontFamily: "Open Sans",
        fontWeight: "400",
        color: color_text,
        fontSize: 14,
        flex: 0.95,
        backgroundColor: "#F9FAFC",
        outlineColor: "#F9FAFC",
        marginLeft: 18
    },
})
export default DesktopPlayer;