import React, { Component } from 'react'; 
import { StyleSheet, View, FlatList, Image, Dimensions } from 'react-native'; 
import { code_ok, code_error, code_auth_error, code_empty , homepageAuthor } from '../constants/api';
import { Skeleton, Avatar, Typography, Button } from '@mui/material'; 
import { executeGet, executePost, truncateText } from '../utilities/Utilities';
import { color_text, sub_text , primary } from '../constants/colors';

class HomepageAuthors extends Component { 
    constructor(props){
        super(props)
        this.state = {
            showLoading : true, 
            showAlert : false, 
            alertMessage : "",
            code : this.props.code, 
            dummy_data : [
                {i:0}, {i:1}, {i:2}, {i:3}
            ],
            data : []
        }

        this.handleAlertClose = this.handleAlertClose.bind(this);
        this.renderSkeleton = this.renderSkeleton.bind(this); 
        this.renderItem = this.renderItem.bind(this); 
        this.fetchAuthors = this.fetchAuthors.bind(this); 
    }

    componentDidMount = ()=>{
        this.fetchAuthors();
    }

    handleAlertClose = ()=>{

    }
    renderSkeleton = ({item, index, separators})=>{
        return(
            <View style={{
                flexDirection : "row", 
                alignItems: "center",
                marginLeft : (index == 0 ? 16 : 0 ), 
                marginRight : (index == (this.state.dummy_data.length - 1) ? 16 : 8 )
            }}
            >
                <Skeleton 
                    animation="wave"
                    variant="circular"
                    width={ 64 }
                    height={ 64 }
                />
                <Skeleton
                    variant="text"
                    sx={{fontSize : "2rem"}}
                    width={ 90 }
                    style={{marginLeft : 8 }}
                />
            </View>
        )
    }
    renderItem = ({item, index, separators})=>{
        return(
            <View style={{
                flexDirection : "row", 
                alignItems : "center", 
                maxWidth : 220, 
                
                marginBottom : 16, 
                marginLeft : (index ==  0 ? 16 : 0 ), 
                marginRight : (index == (this.state.data.length - 1) ? 16 : 24 )
            }}>
                <Avatar 
                    src={ item.photo }
                    style={{width : 64, height : 64 }}
                />
                <Typography
                    fontFamily="Open Sans"
                    fontSize={ 14 } 
                    variant="h3"
                    style={{marginLeft : 8 }}
                >
                    { truncateText(item.name, 20 )}
                </Typography>
            </View>
        )
    }
    fetchAuthors = async()=>{
        let formData = new FormData(); 
        formData.append("homepage_code", this.state.code );
        formData.append("page", 1);
        formData.append("limit", 12); 

        const response = await executePost(homepageAuthor, formData); 
        if(response.code == code_ok) {
            this.setState({
                showLoading : false, 
                data : response.data
            })
        } else {
            if(response.code != code_auth_error){
                this.setState({
                    showAlert : true, 
                    alertMessage : response.msg
                })
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, response.msg);
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : response.msg})
                // }
            } else {
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out"});
            }
        }
    }

    render(){ 
        return(
            <View style={{width : "100%"}}>
                {
                    this.state.showLoading && 
                    <View style={{marginBottom : 16 }}>
                        <Skeleton 
                            variant="text"
                            animation="wave"
                            width={ 120 }
                            sx={{fontSize : '2rem' }}
                            style={{marginLeft : 16 , marginBottom : 8 , marginRight : 16,  marginTop : 16 }} 
                        />
                        <FlatList
                            keyExtractor={(i, x)=>"author_skeleton_"+ x }
                            data={ this.state.dummy_data}
                            renderItem={ this.renderSkeleton }
                            horizontal={ true }
                            showsHorizontalScrollIndicator={false}
                        />
                    </View>
                }
                {
                    !this.state.showLoading && 
                    <View style={{marginTop : 16 }}>
                        <View style={{flexDirection : "row", alignItems : "center", marginBottom : 8 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 16 }
                                variant="h3"
                                fontWeight={"600"}
                                style={{flex : 1, marginLeft : 16, marginBottom : 8  }}
                                color={ color_text }
                            >
                                { truncateText(this.props.title, 20) }
                            </Typography>    
                            <Button
                                style={{textTransform : "none", marginRight : 16  }}
                                onClick={()=>{ this.handleMoreClick(this.state.code )}}
                            >
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 12 }
                                    fontWeight="500"
                                    color={ primary }
                                >
                                selebihnya
                                </Typography>
                            </Button>
                        </View>
                        <FlatList
                            keyExtractor={(item ,index)=>item.homepage_code + "_"+item.author_code }
                            data={ this.state.data }
                            horizontal={ true }
                            renderItem={ this.renderItem }
                            showsHorizontalScrollIndicator={false}
                        />
                        
                    </View>
                }

            </View>
        )
    }

}

export default HomepageAuthors;