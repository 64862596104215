import React, { Component } from 'react'; 
import { View, StyleSheet} from 'react-native'; 
import { Alert, AlertTitle, Button,Typography,  Avatar,IconButton, Paper } from '@mui/material';
import { code_ok, code_auth_error, profile , userSignOut, userDelete } from '../constants/api';
import { color_text, sub_text } from '../constants/colors';
import * as Identifier from '../constants/PageIdentifiers';
import { executeGet} from '../utilities/Utilities';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LoadingDialog from '../components/LoadingDialog';
import TextBox from '../components/TextBox';

var _is_mounted = false;
class InputKTPDesktop extends Component {
    constructor(props){
        super(props) 
        this.state = {
            showLoading : true, 
            showLoadingDialog: false,
            loadingDialogMessage: "",
            showAlert : false, 
            moreLoading :false, 
            loadingMessage : "", 
            alertMessage : "",
            data : [], 
            nomorKTP: '',
            code : this.props.route.params.code,
            title : this.props.route.params.title,
        }


        this.goBack = this.goBack.bind(this);
        this.fetchProfile = this.fetchProfile.bind(this); 
    }

    componentDidMount = ()=>{
        console.log("PROFILE MOUNTED");
        console.log("PARAMS--------");
        console.log(this.props.route.params);

        _is_mounted = true;
        window.addEventListener("message", (event)=>{
            if(event.data.id == "go_back"){
                if(event.data.page == Identifier.INPUTKTP){
                    this.goBack(); 
                }
            }
        });
        this.fetchProfile();
    
    }
    componentWillUnmount = ()=>{
        _is_mounted = false;
        window.removeEventListener("message", null);
    }
    //ANCHOR Go Back
    goBack = ()=>{
        // this.props.navigation.navigate(this.props.route.params.identifier);
        this.props.navigation.goBack();
    }

    fetchProfile = async()=>{
        const response = await executeGet(profile); 
        console.log("profile = " + JSON.stringify(response))
        if(response.code == code_ok){
            this.setState({
                showLoading : false, 
                data : response.data 
            })
        } else {
            if(response.code == code_auth_error){
                window.postMessage({ id: "sign_out"});
            } else {
                this.setState({
                    showLoading : false, 
                    showAlert : true, 
                    alertMessage : response.msg
                })
            }
        }
    }
    

    handleChangeKTP = (text) => {
        this.setState( {
            nomorKTP: text
        })
    }
    validasiKTP = async() => {
        console.log(this.state.nomorKTP)
        console.log("JUDUL " + this.state.title + " - " + this.state.code)
        let nomorKTP = this.state.nomorKTP;
        if (!isNaN(+nomorKTP)) {
            if (nomorKTP.length!=16) {
                this.setState({
                    showAlert: true,
                    alertMessage: "Nomor KTP tidak valid! Mohon dicek kemabali."
                })
            } else {
                //3576014403910003
                var umurValid = true;
                const batasUmur = 18;
                const currYear = Number(new Date().getFullYear().toString().substr(-2));
                const batasLahir = currYear - batasUmur;
                console.log("Tahun Ini: " + currYear)
                
                let tahun = nomorKTP.substr(10,2);
                if (Number(tahun)<currYear) {
                    //ini kelahiran 2023 s/d 2000
                    if (Number(tahun)>batasLahir) {
                        umurValid = false;  
                        this.setState({
                            showAlert: true,
                            alertMessage: "Maaf anda belum diperbolehkan untuk mengakses koleksi ini."
                        })
                    }
                }
                if (umurValid==true) {
                    window.localStorage.setItem('ktp', nomorKTP);
                    let params = { code : this.props.route.params.code, title : this.props.route.params.title, origins : "CONTENT_SECTION"}; 
                    this.props.navigation.navigate("DesktopSectionDetail", params);
                }

            }
        } else {
            this.setState({
                showAlert: true,
                alertMessage: "Nomor KTP tidak valid! Mohon dicek kemabali."
            })
        }
        
    }
    
    render(){
        return(
            <View style={{ flex: 1, paddingLeft: 147, paddingRight: 147 }}>
                <View style={{
                    flexDirection: "row",
                    backgroundColor: "rgb(249, 250, 252)",
                    margin: 16
                }}>
                    
                    <IconButton
                        style={{ marginRight : 16 }}
                        onClick={()=>{ this.goBack() }}
                    >
                        <ChevronLeftIcon 
                            sx={{color : color_text }}
                        />
                    </IconButton>                    
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 18 }
                        fontWeight={"600"}
                        style={{ marginLeft : 15 }}
                    >
                        Input Nomor KTP
                    </Typography>
                </View>
                
                <View style={{
                    flexDirection: "row",
                    backgroundColor: "rgb(249, 250, 252)",
                    margin: 16

                }}>
                
                {
                    !this.state.showLoading &&
                    <>
                    <Paper
                                style={{ borderRadius: 8, flex: 0.3 }}
                            >
                        <View style={{flexDirection : "row", alignItems : "center", backgroundColor: 'white',
                                paddingLeft : 16, 
                                paddingRight : 16, 
                                paddingTop : 5,
                                paddingBottom: 16,
                                marginBottom: 10
                            }}>
                            <Avatar 
                                src={ this.state.data.photo }
                                style={{ width : 64, height : 64  }}
                            />
                            <View style={{marginLeft : 16}}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 16 }
                                    fontWeight={"600"}
                                    color={ color_text }
                                    style={{ marginBottom : 4}}
                                >
                                    { this.state.data.user.name }
                                </Typography>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 14 }
                                    fontWeight={"500"}
                                    color={ sub_text }
                                >
                                    { this.state.data.user.email }
                                </Typography>
                            </View>
                        </View>
                        <View 
                            style={{ marginTop : 8, marginBottom : 8 , height : 0.5, backgroundColor : "silver"}}
                        />

                        
                        
                        
                    </Paper>
                    <Paper
                                style={{ borderRadius: 8, marginLeft: 16, flex: 0.7, padding: 16 }}
                            >
                        {
                            //ANCHOR == Edit Data ==// 
                        }
                        <View
                            style={{ backgroundColor: 'white' }}
                        >
                            <Typography
                                variant="body1"
                                fontSize={14}
                                fontFamily="Open Sans"
                                fontWeight="500"
                                color={ sub_text }
                                style={{marginLeft: 12, marginRight:12,  marginTop : 8 , marginBottom: 4 }}
                            >
                                Untuk mengakses ini anda diwajibkan telah memiliki KTP dan telah berusia minimal 18 tahun.<br></br>
                                Dengan mengisi nomor KTP ini, saya menyatakan bahwa benar nomor KTP yang saya isikan adalah nomor KTP yang saya miliki secarah sah.
                            </Typography>
                            
                            <View
                                style={ styles.form_container }
                            >
                                <TextBox
                                    caption={"Masukan Nomor KTP Anda"}
                                    placeholder={"Input KTP"} 
                                    containerStyle={{marginTop : 16, marginLeft : 16 , marginRight : 16, marginBottom : 16 }}
                                    onChangeText={ this.handleChangeKTP }
                                />
                                <Button
                                    variant="contained"
                                    style={{marginLeft : 16, marginRight : 16, textTransform : "none", borderRadius : 8, padding : 10  }}
                                    onClick={ this.validasiKTP }
                                >
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={ 16 }
                                        fontWeight="500"
                                    >
                                    Kirim
                                    </Typography>
                                </Button>
                            </View>
                        </View>
                    </Paper>
                    </>
                }
                </View>
                
                <LoadingDialog
                    open={this.state.showLoadingDialog}
                    message={this.state.loadingDialogMessage}
                />
                {
                    this.state.showAlert && 
                    <Alert 
                        onClose={()=>{ this.goBack() }}
                        severity="error"
                    > 
                        <AlertTitle>Error</AlertTitle>
                        { this.state.alertMessage }
                    </Alert>
                }


            </View>
        )
    }
}
const styles = StyleSheet.create({
    container : {
        flex : 1 ,
        backgroundColor : "white"
    },
    scrollview : {
        position : "absolute", 
        top : 0,
        bottom : 0, 
        left : 0, 
        right : 0,  
        flex : 1, 
    },
    form_container : {
        backgroundColor : "white", 
        borderTopLeftRadius : 20, 
        borderTopRightRadius : 20
    }
})

export default InputKTPDesktop;