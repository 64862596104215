import React, { Component } from 'react'; 
import { StyleSheet, Image, View, Linking, Dimensions, Pressable } from 'react-native'; 
import { Alert, AlertTitle, CircularProgress, LinearProgress, Typography, Button } from '@mui/material';
import { code_ok, code_empty, code_auth_error, code_error, userSignIn  } from '../constants/api';
import { Layer1Background, Layer2Background, WordLogo, CrowdBackground } from '../assets/Backgrounds';
import { LogoPusnas } from '../assets/Icons';
import { primary, color_text, sub_text } from '../constants/colors';
import TextBox from '../components/TextBox';
import PasswordBox from '../components/PasswordBox';
import LoadingDialog  from '../components/LoadingDialog';
import WarningDialog from '../components/WarningDialog';
import { executePost, executeGet, createAuthHeader } from '../utilities/Utilities';
import HeaderLogo from '../assets/HeaderLogo';
import HeaderLogoWhite from '../assets/HeaderLogoWhite';


import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/700.css';


var _is_mounted = false;
class LoginDesktop extends Component {
    constructor(props){
        super(props)
        this.state = {
            email : "", 
            password : "", 
            errorEmail : false, 
            errorEmailMessage : "", 
            errorPassword : false, 
            errorPasswordMessage : "",
            isSubmitting : false, 
            progressText : "",
            showAlert : false, 
            alertMessage : ""
            
        }
        
        this.handleForgotPasswordClick = this.handleForgotPasswordClick.bind(this); 
        this.handleSignUpClick = this.handleSignUpClick.bind(this); 
        this.handleLoginClick = this.handleLoginClick.bind(this); 

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this); 

        this.executeLogin = this.executeLogin.bind(this); 
        this.handleAlertClose = this.handleAlertClose.bind(this); 

        this.handleWarningDialogClick = this.handleWarningDialogClick.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);

    }

    componentDidMount = ()=>{
        _is_mounted = true; 
    }
    componentWillUnmount = ()=>{
        _is_mounted = false;
    }
    handleForgotPasswordClick = ()=>{
        this.props.navigation.navigate("ForgotPasswordDesktop"); 
    }

    handleSignUpClick = ()=>{
        this.props.navigation.navigate("SignUpDesktop", this.props.route.params );
    }
    handleLoginClick = ()=>{
        if(_is_mounted){
            if(this.state.email.length == 0 ) {
                this.setState({
                    errorEmail : true, 
                    errorEmailMessage : "E-mail harap diisi"
                })
                return;
            }

            if(this.state.password.length == 0 ) {
                this.setState({
                    errorPassword : true, 
                    errorPasswordMessage : "Password wajib diisi"
                })
                return;
            }
            this.executeLogin(); 
        }
    }

    handleEmailChange = (text)=>{
        if(_is_mounted){
            this.setState({
                email : text,
                errorEmail : false
            })
        }
    }

    handlePasswordChange = (text)=>{
        if(_is_mounted){
            this.setState({
                password : text, 
                errorPassword : false 
            })
        }
    }
    handleAlertClose = ()=>{
        this.setState({
            showAlert : false
        })
    }
    executeLogin = async()=>{
        let formData = new FormData(); 
        formData.append("username", this.state.email); 
        formData.append("password", this.state.password); 
        this.setState({
            isSubmitting : true,
            progressText : "Autentikasi"
        })

        const response = await executePost(userSignIn, formData); 
        console.log('login_desktop = ' + JSON.stringify(response))
        if(response.code == code_ok) {
            this.setState({
                isSubmitting : false, 
            },()=>{
                global.email = response.data.user.email;
                localStorage.setItem('email', response.data.user.email)
                localStorage.setItem('level', response.data.level)
                global.level = response.data.level;
                let data = response.data ;
                createAuthHeader(data);
                
                this.props.navigation.reset({
                    index : 0, 
                    routes:[{ name : "MainDesktop" , params : response.data.user }]
                })
            })
        } else {
            this.setState({
                isSubmitting : false, 
                showAlert : true, 
                alertMessage : response.msg
            })
        }
    }

    handleWarningDialogClick = ()=>{
        this.setState({
            showAlert : false
        })
    }

    handleKeyPress = (e)=>{
        let key = e.nativeEvent.key; 
        if(key =="Enter"){
            this.handleLoginClick();
        }
    }

    render(){
        const winHeight = Dimensions.get("window").height;
        const backgroundWidth = winHeight - 144; 
        const backgroundWidth2 = winHeight - 112; 
        return(
            <View style={{ flex : 1, flexDirection : "row"}}> 
                <View style={{ flex : 0.6, alignItems : "center", justifyContent : "center" }} >
                    <View style={{ position: "absolute", left : -16, top : 0 }}>
                        <Layer1Background 
                            //width={ backgroundWidth2 } 
                            height={ winHeight }
                            //viewBox={"0 0 " + backgroundWidth2 + " " + winHeight }
                        />
                    </View>
                    <View style={{ position: "absolute", left : -16, top : 0 }}>
                        <Layer2Background 
                            //width={ backgroundWidth }
                            height={ winHeight }
                            //viewBox={"0 0 " + backgroundWidth + " " + winHeight }
                        />
                    </View>
                    {/* <View style={{ position : "absolute", left : 16 , top : 16 }}>
                        <LogoPusnas 
                            width={ 55 }
                            height={ 51 }
                        />
                    </View> */}
                    <View style={{ position: "absolute", left : 16, top : 28 }}>
                        <HeaderLogoWhite
                            width={ 300 }
                            height={ 45 }
                        />
                    </View>
                    <View style={{marginRight : 128 }}>
                        <CrowdBackground 
                            width={ 500 }
                            height={ 421 }
                        />
                    </View>
                </View>
                <View style={{flex : 0.4, alignItems : 'center', justifyContent : "center" }}>
                    <View style={{ width : "70%"}}>
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={24}
                            fontWeight="700"
                            sx={{color : primary }}
                        >
                            Masuk
                        </Typography>
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={16}
                            fontWeight="600"
                            sx={{ color : sub_text }}
                        >
                            Silahkan masuk ke akun anda
                        </Typography>

                        <TextBox 
                            caption={"Email"}
                            editable={ true }
                            containerStyle={{ marginTop : 24 }}
                            placeholder={"Masukkan email"}
                            keyboardType={"email-address"}
                            error={ this.state.errorEmail }
                            errorMessage={ this.state.errorEmailMessage }
                            onChangeText={ this.handleEmailChange }
                        />

                        <PasswordBox
                            caption={"Password"}
                            containerStyle={{ marginTop : 16 }}
                            placeholder={"Masukkan password"}
                            error={ this.state.errorPassword }
                            errorMessage={ this.state.errorPasswordMessage }
                            onChangeText={ this.handlePasswordChange }
                            onKeyPress={ this.handleKeyPress }
                        />
                        <View style={{ flexDirection : "row-reverse", flex : 1, alignItems : "center", marginTop : 16, marginBottom : 16, marginRight : 0 }}>
                            <Pressable
                                onPress={ this.handleForgotPasswordClick }
                            >
                                <Typography
                                    variant="h3"
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    color={ primary }
                                    fontWeight="600"
                                >
                                    Lupa Password?
                                </Typography>
                            </Pressable>
                        </View>
                        <Button
                            variant="contained"
                            style={{marginLeft : 0, marginRight : 0, marginTop : 32, textTransform : "none", borderRadius : 8, padding : 10  }}
                            onClick={ this.handleLoginClick }
                        >
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 16 }
                                fontWeight="500"
                            >
                            Masuk
                            </Typography>
                        </Button>
                        <View style={{ flex : 1, alignItems : "center", justifyContent : "center", flexDirection : "row", marginTop : 24, marginBottom : 16 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 14 }
                                variant="body1"
                            >
                                Belum punya akun?
                            </Typography>
                            <Pressable
                                onPress={ this.handleSignUpClick }
                            >
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={ 14 }
                                    variant="body1"
                                    fontWeight="600"
                                    marginLeft={ 0.5 }
                                    color={ primary }   
                                >
                                    Daftar disini
                                </Typography>
                            </Pressable>
                        </View>
                    </View>
                </View>
                <WarningDialog
                    open={this.state.showAlert}
                    message={ this.state.alertMessage }
                    caption={"OK"}
                    onClick={ this.handleWarningDialogClick }
                />
                <LoadingDialog 
                    open={this.state.isSubmitting}
                    message={ "Autentikasi" }
                />
            </View>
        )
    }
}


export default LoginDesktop; 
