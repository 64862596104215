import React, { Component } from 'react';
import { StyleSheet, View, Image, FlatList, Dimensions } from 'react-native';
import {
    Alert, AlertTitle, Typography, Card, CardActionArea, CardContent,
    CardMedia, CircularProgress, IconButton,
    Chip
} from '@mui/material';
import { primary, color_text, sub_text } from '../constants/colors';
import { code_ok, code_empty, code_error, code_auth_error, searching } from '../constants/api';
import AlertDialog from '../components/AlertDialog';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { executeGet, executePost, isEven, truncateText, navigateContentDetail, isEvenThree } from '../utilities/Utilities';
import * as Identifier from '../constants/PageIdentifiers';
import * as Command from '../constants/Commands';
import OptionBox from '../components/OptionBox';
import SearchIcon from '@mui/icons-material/Search';
import LoadingPage from '../components/LoadingPage';
var _is_mounted = false;
var window_width;
var book_width;
var book_height;

class SearchPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            showAlert: false,
            alertMessage: "",
            moreLoading: false,
            errorSearch: false,
            errorSearchMessage: "",
            loadingMessage: "melakukan pencarian",
            keyword: "",
            page: 1,
            total: 0,
            show: 0,
            sort_type: "",
            sort_menu: [
                { id: "", title: "All" }, { id: "HMP-SD", title: "SD" },
                { id: "HMP-SMP", title: "SMP" }, { id: "HMP-SMA", title: "SMA" }, { id: "HMP-PERTI", title: "Perguruan Tinggi" },
                { id: "HMP-Audiobook", title: "Audiobook" }, { id: "HMP-Videobook", title: "Videobook" },
                { id: "HMP-Lokal", title: "Lokal" }, { id: "HMP-JURNAL", title: "Jurnal" },
                { id: "HMP-PRA", title: "Pra Nikah" }, { id: "HMP-NIKAH", title: "Nikah" },
                { id: "HMP-BSE", title: "BSE" }, { id: "HMP-DEPAG", title: "Departemen Agama" },
                { id: "HMP-GOLD", title: "GOLD" }
            ],
            dummy_data: [

            ],
            data: [],
            dataSearchAll: []
        }

        this.goBack = this.goBack.bind(this);
        this.handleSearching = this.handleSearching.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        this.onSubmitEditing = this.onSubmitEditing.bind(this);
        this.executeSearch = this.executeSearch.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.onImageError = this.onImageError.bind(this);
        this.fetchMoreData = this.fetchMoreData.bind(this);
        this.contentDetail = this.contentDetail.bind(this);
        this.renderSortMenu = this.renderSortMenu.bind(this);

    }

    componentDidMount = () => {
        _is_mounted = true;

        window_width = Dimensions.get("window").width;
        book_width = (window_width / 2) - 16;
        book_height = book_width + 40;

        if (global.os == "ANDROID") {
            JSBridge.launchFunction(Command.SET_PAGE, Identifier.SEARCH);
        }
        window.addEventListener("message", (event) => {
            if (event.data.id == "go_back") {
                this.goBack();
            }
        })
    }

    componentWillUnmount = () => {
        _is_mounted = false;
        window.removeEventListener("message", null);
    }

    goBack = () => {
        //JSBridge.launchFunction(Command.SET_PAGE, this.props.route.params.identifier );
        //this.props.navigation.goBack(); 
        this.props.navigation.navigate(this.props.route.params.identifier);
    }

    handleSearching = () => {

    }

    onChangeText = (text) => {
        if (_is_mounted) {
            this.setState({
                keyword: text,
                errorSearch: false
            })
        }
    }

    onSubmitEditing = () => {
        if (_is_mounted) {
            if (this.state.keyword.length > 0) {
                this.setState({
                    total: 0,
                    show: 0,
                    page: 1,
                }, () => {
                    this.executeSearch();
                })
            } else {
                this.setState({
                    errorSearch: true,
                    errorSearchMessage: "Harap masukkan kata kunci pencarian"
                })
            }

        }
    }
    //ANCHOR - Execute Search
    executeSearch = async () => {
        let formData = new FormData();
        formData.append("keyword", this.state.keyword);
        formData.append("page", this.state.page);
        this.setState({
            showLoading: true,
            data: [],
            page: 1,
            total: 0,
            show: 0
        })

        const response = await executePost(searching, formData);
        if (response.code == code_ok) {
            if (response.show > 0) {
                let show = this.state.show + response.show;
                let data = [...this.state.data, ...response.data];
                this.setState({
                    showLoading: false,
                    total: response.total,
                    show: show,
                    data: data,
                    dataSearchAll: data
                })
            }
        } else {
            if (response.code == code_auth_error) {
                if (global.os == "ANDROID") {
                    JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                } else {
                    window.webkit.messageHandlers.JSBridge.postMessage({ id: Command.SHOW_TOAST, params: "Autentikasi gagal! Harap login ulang!" })
                }
                window.postMessage({ id: "sign_out" });
            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }
        }

    }

    renderItem = ({ item, index, separators }) => {

        return (
            <Card
                style={{
                    width: book_width,
                    borderRadius: 8,
                    marginLeft: Dimensions.get("window").width < 430 ? (isEven(index) ? 16 : 4) : (isEvenThree(index) ? 16 : 4),
                    marginRight: Dimensions.get("window").width < 430 ? (!isEven(index) ? 16 : 4) : (isEvenThreeLast(index) ? 16 : 4),
                    marginBottom: 8,

                }}
            >
                <CardActionArea
                    sx={{ height: "100%" }}
                    onClick={() => { this.contentDetail(item, index) }}
                >
                    <Image
                        style={{
                            width: book_width,
                            height: book_height,
                        }}
                        resizeMode={"cover"}
                        source={{ uri: item.cover }}
                        onError={(error) => { this.onImageError(item, index) }}
                    />


                    <Typography
                        fontFamily="Open Sans"
                        fontSize={14}
                        color={color_text}
                        fontWeight={"600"}
                        style={{
                            marginLeft: 12, marginTop: 8, marginRight: 12, height: '15%'
                        }}
                    >
                        {truncateText(item.title, 32)}
                    </Typography>
                    <Typography
                        fontFamily="Open Sans"
                        color={sub_text}
                        fontSize={12}
                        style={{
                            marginLeft: 12,
                            marginRight: 12,
                        }}
                    >
                        {truncateText(item.author, 20)}
                    </Typography>
                    {/* <View
                        style={{
                            padding: 3,
                            borderColor: sub_text,
                            borderWidth: 1,
                            borderRadius: 20,
                            height: '15px',
                            marginLeft: 12
                        }}
                    >
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={12}
                            variant="h4"
                            color={sub_text}
                        >
                            {item.homepage_code == "HMP-SD" && truncateText("Koleksi SD", 20)}
                            {item.homepage_code == "HMP-SMP" && truncateText("Koleksi SMP", 20)}
                            {item.homepage_code == "HMP-SMA" && truncateText("Koleksi SMA", 20)}
                            {item.homepage_code == "HMP-PERTI" && truncateText("Koleksi Perguruan Tinggi", 25)}
                            {item.homepage_code == "HMP-Audiobook" && truncateText("Koleksi Audiobook", 20)}
                            {item.homepage_code == "HMP-Videobook" && truncateText("Koleksi Videobook", 20)}
                        </Typography>
                    </View> */}
                    <Typography
                        fontFamily="Open Sans"
                        color={sub_text}
                        fontSize={12}
                        fontWeight={"600"}
                        style={{
                            marginLeft: 12,
                            marginBottom: 12,
                            marginRight: 12,
                            height: '15%'
                        }}
                    >
                        {truncateText(item.homepage_name, 25)}
                    </Typography>
                </CardActionArea>
            </Card>
        )
    }

    contentDetail = (item, index) => {
        console.log('contendetail_search = ' + JSON.stringify(item));
        let params = {
            previous: Identifier.SEARCH,
            parameters: this.props.route.params,
            content_code: item.code
        }

        navigateContentDetail(this.props.navigation, params);
    }
    onImageError = (item, index) => {
        let data = [...this.state.data];
        let obj = { ...data[index] };
        obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/no-cover.png";
        data[index] = obj;
        this.setState({
            data: data
        })
    }

    fetchMoreData = async () => {
        if (_is_mounted && !this.state.showLoading && !this.state.moreLoading) {
            let show = this.state.show;
            let total = this.state.total;
            if (show < total) {

                let formData = new FormData();
                formData.append("keyword", this.state.keyword);
                formData.append("page", this.state.page);
                this.setState({
                    moreLoading: true,
                });

                const response = await executePost(searching, formData);
                if (response.code == code_ok) {

                    let show = parseInt(this.state.show) + parseInt(response.show);
                    let data = [...this.state.data, ...response.data];
                    this.setState({
                        moreLoading: false,
                        showLoading: false,
                        total: response.total,
                        show: show,
                        data: data
                    });

                } else {
                    if (response.code == code_auth_error) {
                        if (global.os == "ANDROID") {
                            JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                            window.postMessage({ id: "sign_out" });
                        } else {
                            //TODO: IOS Bridge 

                        }
                    } else {
                        this.setState({
                            showLoading: false,
                            showAlert: true,
                            alertMessage: response.msg
                        })
                    }
                }
            }
        }
    }

    handleSortClick = (variant) => {
        if (_is_mounted) {
            this.setState({
                sort_type: variant,
                data: variant == "" ? this.state.dataSearchAll : this.state.dataSearchAll.filter(x =>
                    x.homepage_code == variant
                )
            }, () => {
                // this.fetchContent();
                // const filteredHomes = this.state.data.filter( x => 
                //     x.homepage_code == variant
                // );

            });

        }
    }

    //ANCHOR - Render Sort Menu
    renderSortMenu = ({ item, index, separators }) => {
        return (
            <Chip
                style={{
                    marginRight: (index == 3 ? 16 : 8),
                    marginLeft: (index == 0 ? 16 : 0)
                }}
                variant={this.state.sort_type == item.id ? "contained" : "outlined"}
                label={item.title}
                onClick={() => { this.handleSortClick(item.id) }}
            />
        )
    }

    render() {
        return (
            <View style={{ flex: 1 }}>
                <View style={{
                    paddingLeft: 16,
                    //marginTop : (global.os == "ANDROID" ? 16 : 48 ) ,  
                    paddingTop: 16,
                    paddingBottom: 8,
                    flex: 0.05,
                    backgroundColor: "white",
                    flexDirection: "row",
                    alignItems: "center",


                }}>

                    <IconButton
                        style={{ marginRight: 16 }}
                        onClick={() => { this.goBack() }}
                    >
                        <ChevronLeftIcon
                            sx={{ color: color_text }}
                        />
                    </IconButton>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={18}
                        fontWeight={"600"}
                    >
                        Pencarian Buku
                    </Typography>
                </View>

                <View style={{ paddingBottom: 16, marginBottom: 16, backgroundColor: 'white', boxShadow: "0 4px 6px -1px rgba(0,0,0, 0.07)" }}>
                    <View style={{ marginBottom: 8, backgroundColor: "white" }}>
                        <OptionBox
                            editable={true}
                            caption={"Kata Kunci"}
                            usingIcon={true}
                            value={this.state.keyword}
                            placeholder={"Masukkan kata kunci"}
                            containerStyle={{ backgroundColor: "white", marginLeft: 16, marginRight: 16, marginBottom: 8 }}
                            icon={<SearchIcon fontSize="small" />}
                            onIconClick={this.handleSearching}
                            error={this.state.errorSearch}
                            errorMessage={this.state.errorSearchMessage}
                            onChangeText={this.onChangeText}
                            onSubmitEditing={this.onSubmitEditing}
                        />
                    </View>

                    <FlatList
                        horizontal={true}
                        keyExtractor={(k, l) => "index_" + k.id}
                        data={this.state.sort_menu}
                        renderItem={this.renderSortMenu}
                        showsHorizontalScrollIndicator={false}
                        style={{ marginBottom: 16 }}
                    />
                </View>

                <View style={{ flex: 0.95, backgroundColor: "#F2F2F2" }}>
                    {
                        this.state.showLoading &&
                        <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                            <CircularProgress
                                size={64}
                            />
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={16}
                                color={color_text}
                            >
                                Melakukan Pencarian
                            </Typography>

                        </View>
                    }
                    {
                        !this.state.showLoading &&
                        <FlatList
                            keyExtractor={(i, x) => "item_" + i.code + "_" + x}
                            data={this.state.data}
                            renderItem={this.renderItem}
                            numColumns={Dimensions.get("window").width > 430 ? 3 : 2}
                            showsVerticalScrollIndicator={false}
                            onEndReachedThreshold={0.5}
                            onEndReached={this.fetchMoreData}
                            ListFooterComponent={
                                this.state.moreLoading &&
                                <View style={{ alignItems: "center", padding: 8 }}>
                                    <CircularProgress
                                        size={32}
                                    />
                                </View>
                            }
                        />
                        // <FlatList
                        //         keyExtractor={(i, x) => "item_" + i.code + "_" + x}
                        //         data={this.state.data}
                        //         renderItem={this.renderItem}
                        //         numColumns={2}
                        //         showsVerticalScrollIndicator={false}
                        //         stickyHeaderIndices={[0]}
                        //         onEndReachedThreshold={0.2}
                        //         onEndReached={this.fetchMoreData}
                        //         ListFooterComponent={
                        //             this.state.moreLoading &&
                        //             <View style={{ alignItems: "center", padding: 8 }}>
                        //                 <CircularProgress
                        //                     size={32}
                        //                 />
                        //             </View>
                        //         }
                        //     />

                    }
                </View>

                {
                    this.state.showAlert &&
                    <Alert
                        onClose={() => { this.goBack() }}
                        severity="error"
                    >
                        <AlertTitle>Error</AlertTitle>
                        {this.state.alertMessage}
                    </Alert>
                }
            </View>
        )
    }
}

export default SearchPage;


