import React, { Component } from 'react'; 
import { View , StyleSheet, TextInput } from 'react-native'; 
import { primary , color_text, textbox, danger } from '../constants/colors';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { Typography } from '@mui/material'; 
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/700.css';

class PasswordBox extends Component {
    constructor(props){
        super(props)
        this.state = {
            show_password : false
        }
        this.handleVisibilityClick = this.handleVisibilityClick.bind(this); 
    }

    handleVisibilityClick = ()=>{
        if(!this.state.show_password ) {
            this.setState({
                show_password : true
            })
        } else {
            this.setState({
                show_password : false
            })
        }
    }

    render(){
        return(
            <View style={ this.props.containerStyle }>
                <Typography
                    variant="h3"
                    fontWeight="400"
                    fontSize={ 14 } 
                    fontFamily="Open Sans"
                    marginLeft={0.5}
                >
                    { this.props.caption }
                </Typography>
                <View style={ styles.input_container}>
                    <TextInput
                        ref={ this.props.reference }
                        placeholder={ this.props.placeholder } 
                        style={ styles.input }
                        secureTextEntry={ !this.state.show_password }
                        outlineColor={ primary }
                        onChangeText={ this.props.onChangeText }
                        onKeyPress={ this.props.onKeyPress }
                    />
                    <IconButton
                        style={{marginRight : 8, position : "absolute", right : 0 }}
                        onClick={ this.handleVisibilityClick }
                    >   
                    {
                        !this.state.show_password && 
                        <VisibilityIcon />
                    }
                    {
                        this.state.show_password && 
                        <VisibilityOffIcon />
                    }
                        
                    </IconButton>
                </View>
                {
                    this.props.error && 
                    <View style={{flexDirection : "row-reverse"}}>
                        <Typography
                            variant="body1"
                            fontFamily="Open Sans"
                            fontSize={ 14 }
                            color={ danger }  
                        >
                            { this.props.errorMessage }
                        </Typography>
                    </View>

                }
            </View>

        )
    }
}

const styles = StyleSheet.create({
    input_container : {
        backgroundColor : textbox, 
        borderRadius : 8, 
        width : "100%",
        justifyContent : "center",
        marginTop : 8 
    },
    input : {
        fontFamily : "Open Sans", 
        fontSize : 14, 
        padding : 12, 
        flex : 1,
        borderRadius : 8, 
        outlineColor : primary
    }
})


export default PasswordBox; 