import React, { Component } from 'react';
import { View, StyleSheet, Image, FlatList, Dimensions, ScrollView, Pressable } from 'react-native';
import {
    Alert, AlertTitle, Typography, Skeleton, Card, CardActionArea, CircularProgress,
    IconButton, Chip, List, ListItemButton, ListItemIcon,
    ListItemText, Collapse, Paper, Button, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, Rating
} from '@mui/material';
import LoadingPage from '../components/LoadingPage';
import AlertDialog from '../components/AlertDialog';
import * as Identifier from '../constants/PageIdentifiers';
import * as Command from '../constants/Commands';
import {
    code_ok, code_empty, code_error, code_auth_error, categories, categoryContent,
    contentDetail, stockCheck, contentRate, allowInputRate, borrow, sendRate,
    recommendedContent, readContent, readLocalContent, checkLembaga, queue
} from '../constants/api';
import { executeGet, executePost, isEven, truncateText } from '../utilities/Utilities';
import EmptySearchPage from '../components/EmptySearchPage';
import { color_text, sub_text, primary } from '../constants/colors';
import { IconCommentDesktop, IconPlay, IconRate, IconShareDesktop, IconStar, IconStock, IconStorage, IconBookmark, IconFolderAdd } from '../assets/Icons';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LoadingDialog from '../components/LoadingDialog';
import WarningDialog from '../components/WarningDialog';
import SuccessDialog from '../components/SuccessDialog';
import { LogoPusnas, LogoPusnasBlue } from "../assets/Icons";
import LogoPusnasNew from '../assets/images/LogoPusnasNew';
import { RWebShare } from "react-web-share";

var _is_mounted = false;
var first_render = true;
var first_recom_loaded = true;
class DesktopContentDetail extends Component {
    //ANCHOR - Constructor
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            showAlert: false,
            data: {
                total_pages: 0
            },
            alertMessage: "",
            content_code: this.props.route.params.content_code,
            containerWidth: 0,
            bookWidth: 0,
            bookHeight: 0,
            recomBookWidth: 0,
            recomBookHeight: 0,
            stockLoading: true,
            rateLoading: true,
            rateValue: 0,
            rateTotal: 0,
            rateData: [],
            stock: "~",
            total: "~",
            recommendedLoading: true,
            recomData: [],
            showBorrowDialog: false,
            showLoadingDialog: false,
            loadingDialogMessage: "",
            showBorrowSuccess: false,
            successMessage: "",
            allowRate: false,
            showRatingDialog: false,
            rateInputValue: 0,
            localContent: false,
            status_pinjam: false,
            kontenLokal: true,
            playlist: [],
            email: localStorage.getItem('email')
            // title: ""

        }

        this.goBack = this.goBack.bind(this);
        this.onLayout = this.onLayout.bind(this);
        this.fetchDetails = this.fetchDetails.bind(this);
        this.fetchRate = this.fetchRate.bind(this);
        this.fetchStock = this.fetchStock.bind(this);

        this.renderStars = this.renderStars.bind(this);
        this.onImageError = this.onImageError.bind(this);
        this.onRecomImageError = this.onRecomImageError.bind(this);

        this.onBookInfoLayout = this.onBookInfoLayout.bind(this);
        this.fetchRecommended = this.fetchRecommended.bind(this);
        this.renderRecom = this.renderRecom.bind(this);

        this.onRecomLayout = this.onRecomLayout.bind(this);
        this.navigateContentDetail = this.navigateContentDetail.bind(this);

        this.handleBorrowClick = this.handleBorrowClick.bind(this);
        this.handleBorrowConfirmationClose = this.handleBorrowConfirmationClose.bind(this);
        this.handleConfirmationClick = this.handleConfirmationClick.bind(this);

        this.executeLoan = this.executeLoan.bind(this);

        this.checkAllowRate = this.checkAllowRate.bind(this);
        this.showRatingBox = this.showRatingBox.bind(this);
        this.onRateValueChange = this.onRateValueChange.bind(this);
        this.handleSendRate = this.handleSendRate.bind(this);

        this.onReadClick = this.onReadClick.bind(this);
        this.checkBeforeRead = this.checkBeforeRead.bind(this);
        this.checkBeforeBorrow = this.checkBeforeBorrow.bind(this);
        this.checkBeforeAntrian = this.checkBeforeAntrian.bind(this);
        this.executeReadContent = this.executeReadContent.bind(this);

        this.onShareClick = this.onShareClick.bind(this);
        this.onCommentButtonClick = this.onCommentButtonClick.bind(this);
        this.handleAntrianClick - this.handleAntrianClick.bind(this);
    }

    //ANCHOR - Did Mount
    componentDidMount = () => {
        _is_mounted = true;
        this.fetchDetails();
    }

    componentWillUnmount = () => {
        _is_mounted = false;
        first_render = true;
        first_recom_loaded = true;
    }
    //ANCHOR - Go Back
    goBack = () => {
        this.props.navigation.goBack();
    }
    //ANCHOR - Fetch Details
    fetchDetails = async () => {
        if (_is_mounted) {
            let formData = new FormData();
            console.log("item_code fetch = " + this.state.content_code)

            formData.append("code", this.state.content_code)
            const response = await executePost(contentDetail, formData);
            console.log("fetchDetails = " + JSON.stringify(response))
            if (response.code == code_ok) {
                if (_is_mounted) {
                    if (response.data.file_konten_lokal == "-") {
                        this.setState({
                            showLoading: false,
                            data: response.data,
                            playlist: response.playlist,
                            // title: response.data.title,
                            kontenLokal: false,
                            stockLoading: true,
                            status_pinjam: response.status_pinjam
                        }, () => {
                            this.fetchStock();
                            this.fetchRate();

                        })
                    } else {

                        this.setState({
                            showLoading: false,
                            data: response.data,
                            playlist: response.playlist,
                            kontenLokal: true,
                            rateLoading: false,
                            stockLoading: false,
                            localContent: true
                        });
                    }
                    this.fetchRecommended();
                    this.checkAllowRate();
                }
            } else {
                if (response.code == code_auth_error) {
                    window.postMessage({ id: "sign_out" });
                } else {
                    this.setState({
                        showLoading: false,
                        showAlert: true,
                        alertMessage: response.msg
                    })
                }
            }
        }
    }

    //ANCHOR - Fetch Rate 
    fetchRate = async () => {
        if (_is_mounted) {
            let url = contentRate + this.props.route.params.content_code;
            const response = await executeGet(url);

            if (response.code == code_ok) {
                if (_is_mounted) {
                    let data = [];
                    if (response.rate > 0) {
                        for (let i = 0; i < response.rate; i++) {
                            data.push({ stars: i, color: "#FFD600" })
                        }
                    } else {
                        data = [{ stars: 0, color: "#999999" }]
                    }
                    this.setState({
                        rateLoading: false,
                        rateValue: response.rate,
                        rateTotal: response.total,
                        rateData: data
                    })
                }
            } else {
                if (response.code == code_auth_error) {
                    window.postMessage({ id: "sign_out" })
                } else {
                    this.setState({
                        rateLoading: false,
                        showAlert: true,
                        alertMessage: response.msg
                    })
                }
            }
        }

    }
    //ANCHOR - Fetch Stock 
    fetchStock = async () => {
        if (_is_mounted) {
            let formData = new FormData();
            formData.append("content_code", this.props.route.params.content_code);
            const response = await executePost(stockCheck, formData);
            console.log('fetchStock = ' + JSON.stringify(response))
            if (response.code == code_ok) {
                this.setState({
                    stockLoading: false,
                    total: response.total,
                    stock: response.stok
                })
            } else {
                if (response.code == code_auth_error) {
                    window.postMessage({ id: "sign_out" })
                } else {
                    this.setState({
                        showLoading: false,
                        showAlert: true,
                        alertMessage: response.msg
                    })
                }
            }
        }
    }

    handleAntrianClick = async () => {
        let formData = new FormData();
        formData.append("content_code", this.props.route.params.content_code);
        const response = await executePost(queue, formData);
        console.log(JSON.stringify(response));
        if (response.code == code_ok) {
            this.setState({
                showLoading: false,
                alertMessage: response.msg,
                showAlert: true
            })
        } else {
            this.setState({
                showLoading: false,
                showAlert: true,
                alertMessage: response.msg
            })
        }
    }


    //ANCHOR - Fetch Recommended 
    fetchRecommended = async () => {
        if (_is_mounted) {
            let formData = new FormData();
            formData.append("category_code", this.state.data.category_code);
            const response = await executePost(recommendedContent, formData);
            if (response.code == code_ok) {
                this.setState({
                    recommendedLoading: false,
                    recomData: response.data
                })
            } else {
                if (response.code == code_auth_error) {
                    window.postMessage({ id: "sign_out" })
                } else {
                    this.setState({
                        showLoading: false,
                        showAlert: true,
                        alertMessage: response.msg
                    })
                }
            }
        }
    }

    //ANCHOR - On Layout 
    onLayout = (e) => {
        if (first_render) {
            let containerWidth = e.nativeEvent.layout.width;
            let bookWidth = (containerWidth * 0.2);
            let bookHeight = bookWidth + (bookWidth * 0.3);
            first_render = false;

            this.setState({
                containerWidth: containerWidth,
                bookWidth: bookWidth,
                bookHeight: bookHeight
            })
        }
    }
    //ANCHOR - On Book Info Layout 
    onBookInfoLayout = (e) => {

        let layoutHeight = e.nativeEvent.layout.height;
        if (layoutHeight > this.state.bookHeight) {

        }

    }
    //ANCHOR - Render Stars 
    renderStars = ({ item, index, separators }) => {

        return (
            <View style={{ marginRight: 8 }}>
                <IconStar color={item.color} />
            </View>
        )
    }

    //ANCHOR - Render Recom 
    renderRecom = ({ item, index, separators }) => {
        return (
            <View
                key={"content_" + item.code}
                style={{
                    width: this.state.recomBookWidth,
                    height: this.state.recomBookHeight + 100,
                    borderRadius: 8,
                    marginBottom: 8,
                    marginRight: 8
                }}
            >
                <CardActionArea
                    style={{ height: (this.state.recomBookHeight + 100), borderRadius: 8 }}
                    onClick={() => { this.navigateContentDetail(item, index) }}
                >
                    <Image
                        style={{
                            borderRadius: 8,
                            width: this.state.recomBookWidth,
                            height: this.state.recomBookHeight,
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0
                        }}
                        resizeMode={"cover"}
                        source={{ uri: item.cover }}
                        onError={(error) => { this.onRecomImageError(item, index) }}
                    />
                    <View style={{
                        position: 'absolute',
                        top: (this.state.recomBookHeight + 4),
                        left: 0,
                        right: 0
                    }}>
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={12}
                            color={color_text}
                            fontWeight={"600"}
                            style={{
                                marginLeft: 12, marginTop: 8, marginBottom: 8, marginRight: 12
                            }}
                        >
                            {truncateText(item.title, 15)}
                        </Typography>
                        <Typography
                            fontFamily="Open Sans"
                            color={sub_text}
                            fontSize={10}
                            style={{
                                marginLeft: 12,
                                marginBottom: 12,
                                marginRight: 12
                            }}
                        >
                            {truncateText(item.author, 12)}
                        </Typography>
                    </View>
                </CardActionArea>
            </View>
        )
    }

    //ANCHOR - On Image Error
    onImageError = () => {
        let data = { ...this.state.data };
        data.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
        this.setState({
            data: data
        })
    }
    //ANCHOR - On Recom image Error
    onRecomImageError = (item, index) => {
        if (_is_mounted) {
            let data = [...this.state.recomData];
            let obj = { ...data[index] };
            obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
            data[index] = obj;
            this.setState({
                recomData: data
            })
        }
    }

    //ANCHOR - On Recom Layout
    onRecomLayout = (e) => {
        if (first_recom_loaded) {
            let width = e.nativeEvent.layout.width;
            let recomBookWidth = (width / 6) - 16;

            let recomBookHeight = recomBookWidth + (recomBookWidth * 0.3);
            if (_is_mounted) {
                this.setState({
                    recomBookWidth: recomBookWidth,
                    recomBookHeight: recomBookHeight
                });
            }
            first_recom_loaded = false;
        }
    }
    //ANCHOR - Navigate Content Detail 
    navigateContentDetail = (item, index) => {
        console.log("item_code recomended = " + item.code)
        this.setState({
            showLoading: true,
            allowRate: false,
            content_code: item.code
        }, () => {
            this.fetchDetails();
        });


    }
    //ANCHOR - Borrow Click
    handleBorrowClick = () => {
        if (_is_mounted) {
            this.setState({
                showBorrowDialog: true
            })
        }
    }
    //ANCHOR - Borrow Confirmation Close
    handleBorrowConfirmationClose = () => {
        if (_is_mounted) {
            this.setState({
                showBorrowDialog: false
            })
        }
    }
    //ANCHOR - Handle Confirmation Click 
    handleConfirmationClick = () => {
        if (_is_mounted) {
            this.setState({
                showBorrowDialog: false,
                showLoadingDialog: true,
                loadingDialogMessage: "memproses permintaan"
            }, () => {
                this.executeLoan();
            })
        }
    }
    //ANCHOR - Execute Loan
    executeLoan = async () => {
        let formData = new FormData();
        formData.append("content_code", this.props.route.params.content_code);
        const stockResponse = await executePost(stockCheck, formData);
        if (stockResponse.code == code_ok) {
            if (stockResponse.stok > 0) {
                const response = await executePost(borrow, formData);
                if (response.code == code_ok) {
                    this.setState({
                        showLoadingDialog: false,
                        successMessage: response.msg,
                        showBorrowSuccess: true
                    })
                } else {
                    if (response.code != code_auth_error) {
                        this.setState({
                            showLoadingDialog: false,
                            showAlert: true,
                            alertMessage: response.msg
                        })
                    } else {
                        window.postMessage({ id: "sign_out" })
                    }
                }
            } else {
                this.setState({
                    showLoadingDialog: false,
                    showAlert: true,
                    alertMessage: "Stok kosong, silahkan coba beberapa waktu kemudian"
                })
            }
        } else {
            this.setState({
                showLoadingDialog: false,
                showAlert: true,
                alertMessage: "Stok buku ini kosong"
            })
        }
    }

    //ANCHOR - Check Allow Rate 
    checkAllowRate = async () => {
        let url = allowInputRate + this.props.route.params.content_code;
        const response = await executeGet(url);
        console.log('checkAllowRate = ' + JSON.stringify(response))
        if (response.code == code_ok) {
            if (response.status) {
                this.setState({
                    //showRatingBox : true,
                    allowRate: true
                })
            } else {
                this.setState({
                    allowRate: false,
                    //showAlertRate : true, 
                    //alertRateMessage : response.msg
                })
            }
        } else {
            if (response.code == code_auth_error) {
                window.postMessage({ id: "sign_out" })
            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }
        }
    }
    //ANCHOR - Show Rating Box 
    showRatingBox = () => {
        if (_is_mounted) {
            this.setState({
                showRatingDialog: true
            })
        }
    }
    //ANCHOR - On Rate Value Change 
    onRateValueChange = (event, value) => {
        this.setState({
            rateInputValue: value
        })
    }
    //ANCHOR - Handle Send Rate 
    handleSendRate = () => {
        if (this.state.rateInputValue > 0) {
            this.postRate();
        }
    }
    postRate = async () => {
        let formData = new FormData();
        formData.append("content_code", this.props.route.params.content_code)
        formData.append("value", this.state.rateInputValue)

        this.setState({
            showRatingDialog: false,
            showLoadingDialog: true,
            showLoading: true,
            loadingDialogMessage: "mengirimkan rating"
        })

        const response = await executePost(sendRate, formData);

        if (response.code == code_ok) {
            this.setState({
                showLoadingDialog: false,
                showLoading: false,
            }, () => {
                this.fetchRate();
            })

        } else {
            if (response.code != code_auth_error) {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            } else {
                window.postMessage({ id: "sign_out" });
            }
        }
    }

    //ANCHOR - Check to read book
    checkBeforeRead = async () => {
        // let formData = new FormData(); 
        // formData.append("code", this.props.route.params.content_code); 
        // this.executeReadContent(formData); 

        if (_is_mounted) {
            // this.setState({
            //     showLoadingDialog : true,
            //     loadingDialogMessage : "Autentikasi"
            // });
            let url = checkLembaga;
            let response = await executeGet(url);
            console.log("checkLembaga = " + JSON.stringify(response))
            // code == 500 -> page verifikasi
            if (response.code == code_ok) {
                this.onReadClick()
            } else {
                // this.setState({
                //     showLoadingDialog : false,
                //     showAlert : true, 
                //     alertMessage : response.msg
                // })
                this.setState({
                    showLoadingDialog: false,
                }, () => {
                    let data = {
                        action: 'baca',
                        content_code: this.state.content_code,
                        localContent: this.state.localContent
                    };
                    this.props.navigation.navigate("VerificationUserDesktop", data);
                });
            }
        }
    }

    checkBeforePlay = async (item, index, play_type) => {
        // let formData = new FormData(); 
        // formData.append("code", this.props.route.params.content_code); 
        // this.executeReadContent(formData); 
        console.log('item checkBeforePlay = ' + JSON.stringify(item))
        if (_is_mounted) {
            // this.setState({
            //     showLoadingDialog : true,
            //     loadingDialogMessage : "Autentikasi"
            // });
            let url = checkLembaga;
            let response = await executeGet(url);
            console.log("checkLembaga = " + JSON.stringify(response))
            // code == 500 -> page verifikasi
            if (response.code == code_ok) {
                this.executeReadContentPlayer(item, index, play_type)
            } else {
                // this.setState({
                //     showLoadingDialog : false,
                //     showAlert : true, 
                //     alertMessage : response.msg
                // })
                this.setState({
                    showLoadingDialog: false,
                }, () => {
                    let data = {
                        action: 'play',
                        content_code: this.props.route.params.content_code,
                        localContent: this.state.localContent
                    };
                    this.props.navigation.navigate("VerificationUser", data);
                });
            }
        }
    }


    //ANCHOR - Check to read book
    checkBeforeBorrow = async () => {
        // let formData = new FormData(); 
        // formData.append("code", this.props.route.params.content_code); 
        // this.executeReadContent(formData); 

        if (_is_mounted) {
            // this.setState({
            //     showLoadingDialog : true,
            //     loadingDialogMessage : "Autentikasi"
            // });
            let url = checkLembaga;
            let response = await executeGet(url);
            console.log("checkLembaga = " + JSON.stringify(response))
            // code == 500 -> page verifikasi
            if (response.code == code_ok) {
                this.handleBorrowClick()
            } else {
                // this.setState({
                //     showLoadingDialog : false,
                //     showAlert : true, 
                //     alertMessage : response.msg
                // })
                this.setState({
                    showLoadingDialog: false,
                }, () => {
                    let data = {
                        action: 'pinjam'
                    };
                    this.props.navigation.navigate("VerificationUserDesktop", data);
                });
            }
        }
    }

    checkBeforeAntrian = async () => {
        // let formData = new FormData(); 
        // formData.append("code", this.props.route.params.content_code); 
        // this.executeReadContent(formData); 

        if (_is_mounted) {
            // this.setState({
            //     showLoadingDialog : true,
            //     loadingDialogMessage : "Autentikasi"
            // });
            let url = checkLembaga;
            let response = await executeGet(url);
            console.log("checkLembaga = " + JSON.stringify(response))
            // code == 500 -> page verifikasi
            if (response.code == code_ok) {
                this.handleAntrianClick()
            } else {
                // this.setState({
                //     showLoadingDialog : false,
                //     showAlert : true, 
                //     alertMessage : response.msg
                // })
                this.setState({
                    showLoadingDialog: false,
                }, () => {
                    let data = {
                        action: 'pinjam'
                    };
                    this.props.navigation.navigate("VerificationUserDesktop", data);
                });
            }
        }
    }

    //ANCHOR - On Read Click 
    onReadClick = () => {
        if (this.state.data.type_code == "TYP-0001") {
            if (this.state.stock > 0 && this.state.data.file_konten_lokal == "-") {
                let formData = new FormData();
                formData.append("code", this.state.content_code);
                this.executeReadContent(formData);
            } else if (this.state.data.file_konten_lokal != "-") {
                let formData = new FormData();
                formData.append("code", this.state.content_code);
                this.executeReadContent(formData);
            }
        } else {
            console.log('isi play = ' + JSON.stringify(this.state.data))
            console.log('isi play 2 = ' + this.state.data.kode_ebook)

            if (this.state.data.kode_ebook == "") {
                this.props.navigation.navigate("DesktopPlayer", this.state.data);
            } else {
                let formData = new FormData();
                formData.append("code", this.state.data.kode_ebook);
                this.executeReadContentPlayer(formData, 0);
                // this.executeReadContent(formData);
            }

            // this.props.navigation.navigate("DesktopPlayer", this.state.data ); 
        }
    }

    //ANCHOR - Execute Read Content
    executeReadContentPlayer = async (item, index, play_type) => {
        console.log('item player = ' + JSON.stringify(item))
        console.log('item player = ' + JSON.stringify(this.state.playlist))
        if (_is_mounted) {
            this.setState({
                showLoadingDialog: true,
                loadingDialogMessage: "Autentikasi"
            });

            // this.setState({
            //     showLoadingDialog: true,
            //     loadingDialogMessage: "Autentikasi"
            // });
            let formData = new FormData();
            formData.append("code", this.state.data.kode_ebook);

            let url = (this.state.localContent ? readLocalContent : readContent);
            let response = await executePost(url, formData);

            if (response.code == code_ok) {
                this.setState({
                    showLoadingDialog: false,
                }, () => {
                    // let data = response.data; 
                    // data.mode = 'read';
                    // data.local = this.state.localContent; 

                    let data = response.data;
                    data.mode = 'read';
                    data.local = this.state.localContent;
                    data.nama_file = item.multimedia_file;
                    data.type = this.state.data.type;
                    data.title = this.state.data.title;
                    data.cover = this.state.data.cover;
                    data.playlist = this.state.playlist;
                    data.first_page = item.halaman_dari;
                    data.last_page = item.halaman_sampai;
                    data.play_index = index;
                    data.play_type = play_type;
                    data.track

                    this.props.navigation.navigate("ReaderPlayerDesktop", data);
                })
            } else {
                if (response.code != code_auth_error) {
                    this.setState({
                        showLoadingDialog: false,
                        showAlert: true,
                        alertMessage: response.msg
                    })
                } else {
                    window.postMessage({ id: "sign_out" })
                }
            }
        }
    }

    //ANCHOR - Execute Read Content
    executeReadContent = async (formData) => {
        if (_is_mounted) {
            this.setState({
                showLoadingDialog: true,
                loadingDialogMessage: "Autentikasi"
            });

            this.setState({
                showLoadingDialog: true,
                loadingDialogMessage: "Autentikasi"
            });
            let url = (this.state.localContent ? readLocalContent : readContent);
            let response = await executePost(url, formData);
            console.log("RESPONSE");
            console.log(response);
            if (response.code == code_ok) {
                this.setState({
                    showLoadingDialog: false,
                }, () => {
                    console.log("GO TO READER DESKTOP");
                    let data = response.data;
                    data.mode = 'read';
                    data.local = this.state.localContent;
                    this.props.navigation.navigate("ReaderDesktop", data);
                })
            } else {
                if (response.code != code_auth_error) {
                    this.setState({
                        showLoadingDialog: false,
                        showAlert: true,
                        alertMessage: response.msg
                    })
                } else {
                    window.postMessage({ id: "sign_out" })
                }
            }
        }
    }

    onCommentButtonClick = () => {
        this.props.navigation.navigate("CommentPageDesktop", this.props.route.params);
    }

    onShareClick = async () => {
        try {
            let data = { ...this.state.data };
            data.message = "Baca buku ini di BintangPusnas\n" + data.title + "\nhttps://bintangpusnas.perpusnas.go.id/web/?code=" + data.code;
            //TODO - Share for PWA 
            console.log('share PWA')
            const title = document.title;
            const text = "Silahkan buka link di atas.";
            const url = "https://bintangpusnas.perpusnas.go.id";
            try {
                await navigator
                    .share({
                        title,
                        url,
                        text
                    })

                /*
                  Show a message if the user shares something
                */
                //alert(`Thanks for Sharing!`);
            } catch (err) {
                /*
                   This error will appear if the user cancels the action of sharing.
                 */
                alert(`Couldn't share ${err}`);
            }
        } catch (error) {

        }

    }

    renderItem = ({ item, index, separators }) => {
        return (
            <>
                {
                    item.judul != '' &&
                    <View
                        style={{
                            width: '100%',
                            borderColor: '#d9d9d9',
                            borderWidth: 1,
                            borderRadius: 10,
                            marginTop: '4px',
                            marginBottom: '4px',
                            paddingTop: '12px',
                            paddingBottom: '12px',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                            flexDirection: "row"
                        }}
                        onClick={() => { this.checkBeforePlay(item, index, 'single') }}
                    >
                        <View style={{ flex: 0.9 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={14}
                                color={color_text}
                                fontWeight={"600"}
                            >
                                {truncateText(item.judul, 32)}
                            </Typography>
                        </View>
                        <View style={{ flex: 0.1, alignItems: 'center' }}>
                            <IconPlay />
                        </View>
                    </View>
                }

                {
                    item.sub_judul != '' &&
                    <View
                        style={{
                            borderColor: '#d9d9d9',
                            borderWidth: 1,
                            borderRadius: 10,
                            marginTop: '4px',
                            marginBottom: '4px',
                            paddingTop: '12px',
                            paddingBottom: '12px',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                            marginLeft: '24px',
                            flexDirection: "row"
                        }}
                        onClick={() => { this.checkBeforePlay(item, index, 'single') }}
                    >
                        <View style={{ flex: 0.9 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={14}
                                color={color_text}
                                fontWeight={"600"}
                            >
                                {truncateText(item.sub_judul, 28)}
                            </Typography>
                        </View>
                        <View style={{ flex: 0.1, alignItems: 'center' }}>
                            <IconPlay />
                        </View>
                    </View>
                }
                {
                    item.sub_judul_2 != '' &&
                    <View
                        style={{
                            borderColor: '#d9d9d9',
                            borderWidth: 1,
                            borderRadius: 10,
                            marginTop: '4px',
                            marginBottom: '4px',
                            paddingTop: '12px',
                            paddingBottom: '12px',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                            marginLeft: '48px',
                            flexDirection: "row"
                        }}
                        onClick={() => { this.checkBeforePlay(item, index, 'single') }}
                    >
                        <View style={{ flex: 0.9 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={14}
                                color={color_text}
                                fontWeight={"600"}
                            >
                                {truncateText(item.sub_judul_2, 28)}
                            </Typography>
                        </View>
                        <View style={{ flex: 0.1, alignItems: 'center' }}>
                            <IconPlay />
                        </View>
                    </View>
                }
            </>

            // <Card
            //     style={{
            //         width: '100%',
            //         borderRadius: 8,
            //         marginTop: '10px',
            //         marginBottom: '10px'
            //     }}
            // >   
            //     <CardActionArea
            //         sx={{ height: "100%" }}
            //         onClick={() => { this.checkBeforePlay(item, index) }}
            //     >
            //         <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}>

            //             <View style={{ flex: 1 }}>
            //                 {
            //                     item.judul != '' &&
            //                     <Typography
            //                         fontFamily="Open Sans"
            //                         fontSize={14}
            //                         color={color_text}
            //                         fontWeight={"600"}
            //                     >
            //                         {truncateText(item.judul, 32)}
            //                     </Typography>
            //                 }
            //                 {
            //                     item.sub_judul != '' &&
            //                     <Typography
            //                         fontFamily="Open Sans"
            //                         fontSize={14}
            //                         color={color_text}
            //                         fontWeight={"600"}
            //                     >
            //                         {truncateText(item.sub_judul, 32)}
            //                     </Typography>
            //                 }
            //                 {
            //                     item.sub_judul_2 != '' &&
            //                     <Typography
            //                         fontFamily="Open Sans"
            //                         fontSize={14}
            //                         color={color_text}
            //                         fontWeight={"600"}
            //                     >
            //                         {truncateText(item.sub_judul_2, 32)}
            //                     </Typography>
            //                 }


            //             </View>
            //         </View>
            //     </CardActionArea>
            // </Card>

        )
    }

    //ANCHOR - Render Method 
    render() {
        return (
            <View style={{ flex: 1 }}>
                <View style={styles.header}>
                    <IconButton
                        style={{ marginRight: 16, marginLeft: 16 }}
                        onClick={() => { this.goBack() }}
                    >
                        <ChevronLeftIcon
                            sx={{ color: primary }}
                        />
                    </IconButton>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={18}
                        fontWeight="600"
                        style={{ flex: 1 }}
                        color={primary}
                    >
                        Detail Konten
                    </Typography>
                    <View style={{ flex: 1, flexDirection: "row-reverse", marginRight: 32 }}>
                        <LogoPusnasNew
                            width={220}
                            height={120}
                        />
                    </View>


                </View>
                <View style={{ flex: 1 }} >
                    <View style={styles.mainContainer} onLayout={this.onLayout}>
                        {
                            this.state.showLoading &&
                            <Skeleton
                                variant="rectangular"
                                animation="wave"
                                style={{
                                    width: this.state.bookWidth,
                                    height: this.state.bookHeight,
                                    borderRadius: 8
                                }}
                            />
                        }
                        {
                            !this.state.showLoading &&
                            <img
                                src={this.state.data.cover}
                                style={{ height: this.state.bookHeight, borderRadius: 8 }}
                                onError={() => { this.onImageError() }}
                            />

                        }
                        <View style={[styles.bookInfo, { maxHeight: this.state.bookHeight }]}
                            onLayout={this.onBookInfoLayout}
                        >
                            {
                                //ANCHOR - Info Skeleton 
                                this.state.showLoading &&
                                <View style={{ flex: 1 }}>
                                    <Skeleton
                                        variant="rectangle"
                                        animation="wave"
                                        width={350}
                                        height={48}
                                        style={{ borderRadius: 8 }}
                                    />
                                    <Skeleton
                                        variant="rectangle"
                                        animation="wave"
                                        width={250}
                                        height={32}
                                        style={{ borderRadius: 8, marginTop: 8 }}
                                    />
                                    <Skeleton
                                        variant="rectangle"
                                        animation="wave"
                                        width={120}
                                        height={32}
                                        style={{ borderRadius: 8, marginTop: 8 }}
                                    />
                                    <Skeleton
                                        variant="rectangle"
                                        animation="wave"
                                        width={300}
                                        height={64}
                                        style={{ borderRadius: 8, marginTop: 8 }}
                                    />


                                </View>
                            }
                            {
                                //ANCHOR - Info Container 
                                !this.state.showLoading &&
                                <View style={{ flex: 1 }}>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={24}
                                        fontWeight="600"
                                        color={color_text}
                                        style={{ marginBottom: 8 }}
                                    >
                                        {this.state.data.title}
                                    </Typography>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={18}
                                        fontWeight="600"
                                        color={sub_text}
                                        style={{ marginBottom: 8 }}
                                    >
                                        {this.state.data.author}
                                    </Typography>
                                    {
                                        this.state.rateLoading &&
                                        <Skeleton
                                            variant="rectangle"
                                            animation="wave"
                                            width={120}
                                            height={32}
                                            style={{ borderRadius: 8 }}
                                        />
                                    }
                                    {
                                        //ANCHOR - FlatList Rate Stars
                                        !this.state.rateLoading &&
                                        <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center' }}>
                                            <IconButton
                                                style={{ marginRight: 14 }}
                                                onClick={this.onCommentButtonClick}
                                            >
                                                <IconCommentDesktop />
                                            </IconButton>
                                            <RWebShare
                                                data={{
                                                    text: "Baca buku ini di Aplikasi BintangPusnas\n",
                                                    url: "https://bintangpusnas.perpusnas.go.id",
                                                    title: "Bintang Pusnas",
                                                }}
                                                onClick={() => console.log("shared successfully!")}
                                            >
                                                <IconButton
                                                    style={{ marginRight: 14 }}
                                                // onClick={this.onShareClick}
                                                >
                                                    <IconShareDesktop />
                                                </IconButton>
                                            </RWebShare>
                                            <FlatList
                                                horizontal={true}
                                                data={this.state.rateData}
                                                keyExtractor={(i, x) => "rate_idx_" + x}
                                                renderItem={this.renderStars}
                                                ListFooterComponent={
                                                    <View style={{ flexDirection: "row", alignItems: "center", marginTop: 3 }}>
                                                        <Typography
                                                            fontFamily="Open Sans"
                                                            fontSize={14}
                                                            fontWeight="600"
                                                            style={{ marginTop: 0 }}
                                                        >
                                                            {this.state.rateValue} ({this.state.rateTotal} )
                                                    </Typography>
                                                        {
                                                            this.state.allowRate &&
                                                            <Pressable
                                                                onPress={this.showRatingBox}
                                                            >
                                                                <Typography
                                                                    fontFamily="Open Sans"
                                                                    fontSize={14}
                                                                    fontWeight="500"
                                                                    color={primary}
                                                                    sx={{ marginLeft: 2 }}
                                                                >
                                                                    Berikan rating
                                                        </Typography>
                                                            </Pressable>
                                                        }
                                                    </View>
                                                }
                                            />
                                        </View>

                                    }

                                    <View
                                        style={{
                                            flexDirection: "row"
                                        }}
                                    >
                                        <View style={{ flex: 0.7, paddingRight: '24px' }}>
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={14}
                                                style={{ overflow: "scroll", flex: 8 }}
                                            >
                                                <div
                                                    style={{ overflow: "hidden" }}
                                                    dangerouslySetInnerHTML={{ __html: this.state.data.synopsis }}
                                                />
                                            </Typography>
                                        </View>
                                        {
                                            (this.state.data.type == "Audio" || this.state.data.type == "Video") &&
                                            <View style={{ flex: 0.3, backgroundColor: '#ffffff', height: '250px', paddingBottom: '16px' }}>

                                                <ScrollView style={{
                                                    backgroundColor: "white",
                                                    flex: 1
                                                }}
                                                >
                                                    <View style={{ marginTop: 16, marginLeft: 16, marginRight: 16 }}>
                                                        {/* <Typography
                                                            fontFamily="Open Sans"
                                                            fontSize={16}
                                                            fontWeight="700"
                                                            sx={{ color: color_text }}
                                                        >
                                                            Playlist
                                                        </Typography> */}
                                                        <View
                                                            style={{
                                                                flexDirection: "row",
                                                                marginBottom: 5
                                                            }}
                                                        >
                                                            <View style={{ flex: 0.5 }}>
                                                                <Typography
                                                                    fontFamily="Open Sans"
                                                                    fontSize={16}
                                                                    fontWeight="700"
                                                                    sx={{ color: color_text }}
                                                                >
                                                                    Playlist
                                                                </Typography>
                                                            </View>
                                                            <View style={{ flex: 0.5, alignItems: 'end' }}>
                                                                <Typography
                                                                    fontFamily="Open Sans"
                                                                    fontSize={16}
                                                                    fontWeight="700"
                                                                    sx={{ color: color_text }}
                                                                    onClick={() => { this.checkBeforePlay(this.state.playlist, 0, 'all') }}
                                                                >
                                                                    Putar Semua
                                                                </Typography>
                                                            </View>
                                                        </View>
                                                        <FlatList
                                                            keyExtractor={(item, index) => "item_index_" + index}
                                                            data={this.state.playlist}
                                                            renderItem={this.renderItem}
                                                            numColumns={1}
                                                            showsVerticalScrollIndicator={false}
                                                            onEndReachedThreshold={0.5}
                                                            onEndReached={this.fetchMoreData}
                                                            ListFooterComponent={
                                                                this.state.moreLoading &&
                                                                <View style={{ alignItems: "center", padding: 8 }}>
                                                                    <CircularProgress
                                                                        size={32}
                                                                    />
                                                                </View>
                                                            }
                                                        />
                                                    </View>
                                                </ScrollView>

                                            </View>
                                        }

                                    </View>
                                </View>
                            }
                        </View>
                    </View>
                    {
                        //ANCHOR - Book Details
                    }
                    <View style={[styles.mainContainer, { flex: 1 }]}>
                        <Paper
                            style={{ height: 280, flex: 0.5, borderRadius: 8, padding: 16, marginRight: 8 }}
                        >
                            <View style={{ height: 280 }}>
                                <View style={{ flexDirection: "row" }}>
                                    <View style={{ flex: 0.5 }}>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={16}
                                            fontWeight="600"
                                            color={primary}
                                            style={{ marginBottom: 16, border: 1 }}
                                        >
                                            Info Buku
                                </Typography>
                                    </View>
                                    <View style={{ flex: 0.5 }}>
                                        {
                                            (!this.state.stockLoading) &&
                                            <View style={{
                                                flexDirection: "row-reverse",
                                                bottom: 0,
                                                left: 0,
                                                right: 0
                                            }}> 
                                                <>
                                                    {
                                                    this.state.kontenLokal &&
                                                        //baca
                                                        <Button
                                                                    variant="contained"
                                                                    style={{ textTransform: "none", borderRadius: 25, marginRight: 0, minWidth: 120 }}
                                                                    onClick={this.checkBeforeRead}
                                                        >
                                                            <Typography
                                                                fontFamily="Open Sans"
                                                                fontSize={14}
                                                                fontWeight="500"
                                                            >
                                                                Baca
                                                            </Typography>
                                                        </Button>
                                                    }
                                                </>
                                                {
                                                    this.state.stock > 0 || this.state.email == 'eresources.akuisisi@gmail.com' ?
                                                        <>
                                                            {
                                                                this.state.data.type_code == "TYP-0001" &&
                                                                <Button
                                                                    variant="contained"
                                                                    style={{ textTransform: "none", borderRadius: 25, marginRight: 0, minWidth: 120 }}
                                                                    onClick={this.checkBeforeRead}
                                                                >
                                                                    <Typography
                                                                        fontFamily="Open Sans"
                                                                        fontSize={14}
                                                                        fontWeight="500"
                                                                    >
                                                                        {
                                                                            this.state.data.type_code == "TYP-0001" &&
                                                                            "Baca"
                                                                        }
                                                                        {/* {
                                                                        this.state.data.type_code != "TYP-0001" &&
                                                                        "Mainkan"
                                                                    } */}
                                                                    </Typography>
                                                                </Button>
                                                            }

                                                            {
                                                                (this.state.data.type_code == "TYP-0001" && this.state.data.file_konten_lokal == "-") &&

                                                                <Button
                                                                    variant="outlined"
                                                                    style={{ textTransform: "none", borderRadius: 25, marginRight: 16, minWidth: 120 }}
                                                                    onClick={this.checkBeforeBorrow}
                                                                >
                                                                    <Typography
                                                                        fontFamily="Open Sans"
                                                                        fontSize={14}
                                                                        fontWeight="500"
                                                                    >
                                                                        Pinjam
                                                                        </Typography>
                                                                </Button>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                this.state.status_pinjam ?
                                                                    <>
                                                                        {
                                                                            this.state.data.type_code == "TYP-0001" &&
                                                                            <Button
                                                                                variant="contained"
                                                                                style={{ textTransform: "none", borderRadius: 25, marginRight: 0, minWidth: 120 }}
                                                                                onClick={this.checkBeforeRead}
                                                                            >
                                                                                <Typography
                                                                                    fontFamily="Open Sans"
                                                                                    fontSize={14}
                                                                                    fontWeight="500"
                                                                                >
                                                                                    {
                                                                                        this.state.data.type_code == "TYP-0001" &&
                                                                                        "Baca"
                                                                                    }
                                                                                    {/* {
                                                                                this.state.data.type_code != "TYP-0001" &&
                                                                                "Mainkan"
                                                                            } */}
                                                                                </Typography>
                                                                            </Button>
                                                                        }
                                                                    </>

                                                                    :
                                                                    <>
                                                                        {
                                                                            !this.state.kontenLokal &&
                                                                            <Button
                                                                                variant="outlined"
                                                                                style={{ textTransform: "none", borderRadius: 25, marginRight: 16, minWidth: 120 }}
                                                                                onClick={this.checkBeforeAntrian}
                                                                            >
                                                                                <Typography
                                                                                    fontFamily="Open Sans"
                                                                                    fontSize={14}
                                                                                    fontWeight="500"
                                                                                >
                                                                                    Antrian
                                                                            </Typography>
                                                                            </Button>
                                                                        }
                                                                        
                                                                    </>
                                                            }
                                                        </>

                                                }



                                            </View>
                                        }
                                    </View>
                                </View>
                                <View
                                    style={{ marginBottom: 16, height: 0.5, backgroundColor: "#999999" }}
                                />
                                <View style={{ flexDirection: "row", marginBottom: 16 }}>
                                    <View style={{ flex: 0.5 }}>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={14}
                                            color={sub_text}
                                        >
                                            Jumlah Hal.
                                        </Typography>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={18}
                                            color={color_text}
                                        >
                                            {this.state.data.total_pages} Halaman
                                        </Typography>

                                    </View>
                                    <View style={{ flex: 0.5 }}>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={14}
                                            color={sub_text}
                                            style={{
                                                overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"
                                            }}
                                        >
                                            Penerbit
                                        </Typography>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={18}
                                            color={color_text}
                                        >
                                            {this.state.data.publisher}
                                        </Typography>
                                    </View>
                                </View>
                                <View style={{ flexDirection: "row", marginBottom: 16 }}>
                                    <View style={{ flex: 0.5 }}>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={14}
                                            color={sub_text}
                                        >
                                            ISBN
                                        </Typography>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={18}
                                            color={color_text}
                                        >
                                            {this.state.data.isbn}
                                        </Typography>

                                    </View>
                                    <View style={{ flex: 0.5 }}>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={14}
                                            color={sub_text}
                                        >
                                            Tahun Terbit
                                        </Typography>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={18}
                                            color={color_text}
                                        >
                                            {this.state.data.year}
                                        </Typography>
                                    </View>
                                </View>
                                <View style={{ marginBottom: 16, flexDirection: "row" }}>
                                    <View style={{ flex: 0.5 }}>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={14}
                                            color={sub_text}
                                        >
                                            Kategori
                                        </Typography>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={18}
                                            color={color_text}
                                        >
                                            {this.state.data.category}
                                        </Typography>
                                    </View>
                                    <View style={{ flex: 0.5 }}>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={14}
                                            color={sub_text}
                                        >
                                            Jumlah Stok
                                        </Typography>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={18}
                                            color={color_text}
                                        >
                                            {
                                                this.state.stockLoading &&
                                                <Skeleton
                                                    variant="rectangular"
                                                    animation="wave"
                                                    style={{ width: '100%', borderRadius: 8, marginTop: 8, marginBottom: 8 }}
                                                />
                                            }
                                            {
                                                !this.state.stockLoading &&
                                                this.state.stock + "/" + this.state.total
                                            }
                                        </Typography>
                                    </View>
                                </View>

                                {
                                    this.state.stockLoading &&
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        style={{
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0,
                                            right: 0,
                                            height: 48,
                                            borderRadius: 8
                                        }}
                                    />
                                }

                            </View>
                        </Paper>

                        <Paper
                            style={{ height: 280, flex: 0.5, borderRadius: 8, padding: 16, marginLeft: 8, overflow: "hidden" }}
                        >
                            <View style={{ height: 280 }}
                                onLayout={this.onRecomLayout}
                            >
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={16}
                                    fontWeight="600"
                                    color={primary}
                                    style={{ marginBottom: 16 }}
                                >
                                    Rekomendasi
                                </Typography>
                                <View
                                    style={{ marginBottom: 16, height: 0.5, backgroundColor: "#999999" }}
                                />
                                {
                                    this.state.recommendedLoading &&
                                    <Skeleton
                                        variant="rectangular"
                                        animation="wave"
                                        height={this.state.recomBookHeight}
                                        style={{ borderRadius: 8 }}
                                    />
                                }
                                {
                                    //ANCHOR - Flatlist Recommendation
                                    !this.state.recommendedLoading &&

                                    <FlatList
                                        keyExtractor={(i, x) => "recom_idx_" + x}
                                        data={this.state.recomData}
                                        renderItem={this.renderRecom}
                                        horizontal={true}
                                        showsHorizontalScrollIndicator={false}
                                    />

                                }
                            </View>
                        </Paper>
                    </View>
                </View>
                {
                    //ANCHOR - Borrow Confirmation Dialog
                }
                <Dialog
                    open={this.state.showBorrowDialog}
                    onClose={this.handleBorrowConfirmationClose}
                >
                    <DialogTitle>
                        Konfirmasi Peminjaman
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Proses peminjaman akan dilakukan , hal ini akan mengurangi stok yang dimiliki oleh perpustakaan ini. Lanjutkan?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleBorrowConfirmationClose}>Batalkan</Button>
                        <Button onClick={this.handleConfirmationClick}>Lanjutkan</Button>
                    </DialogActions>
                </Dialog>
                <LoadingDialog
                    open={this.state.showLoadingDialog}
                    message={this.state.loadingDialogMessage}
                />
                {
                    //ANCHOR - Warning Dialog
                }
                <WarningDialog
                    open={this.state.showAlert}
                    message={this.state.alertMessage}
                    caption={"OK"}
                    onClick={() => {
                        if (_is_mounted) {
                            this.setState({
                                showAlert: false
                            })
                        }
                    }}
                />
                {
                    //ANCHOR - Success Dialog
                }
                <SuccessDialog
                    open={this.state.showBorrowSuccess}
                    message={this.state.successMessage}
                    caption={"OK"}
                    onClick={() => {
                        this.props.navigation.goBack();
                    }}
                />
                {
                    //ANCHOR - Rating Box 
                }
                <Dialog
                    open={this.state.showRatingDialog}
                    onClose={() => {
                        this.setState({
                            showRatingDialog: false
                        })
                    }}
                    PaperProps={{
                        style: {
                            minWidth: 420
                        }
                    }}
                >
                    <DialogTitle>
                        Berikan Rating Anda
                    </DialogTitle>
                    <View style={{ justifyContent: "center", alignItems: "center", padding: 12 }}>
                        <Rating
                            size="large"
                            value={this.state.rateInputValue}
                            onChange={this.onRateValueChange}
                        />
                    </View>

                    <DialogActions>
                        <Button onClick={() => {
                            this.setState({
                                showRatingDialog: false
                            })
                        }}>Batal</Button>
                        <Button onClick={this.handleSendRate}>OK</Button>
                    </DialogActions>
                </Dialog>

            </View>
        )
    }
}

const styles = StyleSheet.create({
    header: {
        paddingTop: 16,
        paddingBottom: 16,
        marginBottom: 16,
        flex: 0.05,
        backgroundColor: "white",
        flexDirection: "row",
        alignItems: "center",
        boxShadow: "0 4px 6px -1px rgba(0,0,0, 0.07)"
    },
    mainContainer: {
        flexDirection: "row",
        padding: 16
    },
    bookInfo: {
        flex: 1,
        marginLeft: 16
    },
    starContainer: {
        flexDirection: "row",
        alignItems: "center"
    }


})

export default DesktopContentDetail;