import React, { Component} from 'react'; 
import { StyleSheet, View, Image } from 'react-native'; 
import { Typography, LinearProgress, CircularProgress } from '@mui/material'; 



class LoadingPage extends Component {
    constructor(props){
        super(props)
    }

    render(){
        return(
            <View style={ styles.container }>
               
                <CircularProgress 
                    size={ 64 }
                />
                <Typography
                    fontFamily="Open Sans"
                    fontWeight={"400"}
                    fontSize={ 16 }
                    style={{marginTop : 16 }}
                >
                    { this.props.message }
                </Typography>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container : {
        flex : 1, 
        backgroundColor : "white", 
        alignItems : "center",
        justifyContent : "center"
    }
});

export default LoadingPage; 
