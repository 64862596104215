import React, { Component } from 'react'; 
import { StyleSheet, View, Image, FlatList, Dimensions , ScrollView } from 'react-native'; 
import { Typography, Button , IconButton} from '@mui/material'; 
import * as Identifier from '../constants/PageIdentifiers'; 
import * as Command from '../constants/Commands';
import { primary , color_text, sub_text } from '../constants/colors';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { CalendarIcon, TimeIcon } from '../assets/Icons';
import { getQueryStrings } from '../utilities/Utilities';
import Plyr from "plyr-react";
import "plyr-react/plyr.css"
import zIndex from '@mui/material/styles/zIndex';

var _is_mounted = false;

class NewsDetail extends Component {
    constructor(props){
        super(props)
        this.state = {
            showLoading : false, 
            showAlert : false,
            alertMessage : "",  
            title : "", 
            code : "", 
            description : "", 
            image : "", 
            date : "", 
            time : ""
        }

        this.goBack = this.goBack.bind(this); 
    }

    
    componentDidMount = ()=>{

        if (global.os == "ANDROID") {
            JSBridge.launchFunction(Command.SET_PAGE, Identifier.NEWS_DETAIL);    
        }
        _is_mounted = true;
        window.addEventListener("message", (event)=>{
            
            if(event.data.id == "go_back"){
                if(global.currentRouteName == Identifier.NEWS_DETAIL){
                    this.goBack(); 
                }
            }
        });
        console.log("PARAMMMM");
        console.log(this.props.route.params);
        let dates = this.props.route.params.input_time.split(" "); 
        let date = dates[0] + " "+dates[1]; 
        let time = dates[2]; 
        if(_is_mounted){
            // this.setState({
            //     code : this.props.route.params.code, 
            //     title : this.props.route.params.title, 
            //     description : this.props.route.params.description, 
            //     image : this.props.route.params.content_image, 
            //     date : date, 
            //     time : time
            // })
            if(this.props.route.params.tipe == "berita") {
                this.setState({
                    code : this.props.route.params.code, 
                    title : this.props.route.params.title, 
                    description : this.props.route.params.description, 
                    image : this.props.route.params.content_image, 
                    date : date, 
                    time : time
                })
            } else if(this.props.route.params.tipe == "youtubeUtama") {
                let youtube_url = this.props.route.params.url_video; 
                let videoID = getQueryStrings(youtube_url)["v"]; 
                this.setState({
                    code : this.props.route.params.id, 
                    title : this.props.route.params.title, 
                    description : "", 
                    image : this.props.route.params.content_image, 
                    date : date, 
                    time : time,
                    videoSrc : {
                        type : "video", 
                        sources : [
                            {
                                src : videoID, 
                                provider : "youtube"
                            }
                        ]
                    }
                })
            } else {
                
                let youtube_url = this.props.route.params.url_youtube; 
                console.log("YT: " + youtube_url);
                let videoID = getQueryStrings(youtube_url)["v"]; 
                this.setState({
                    code : this.props.route.params.code, 
                    title : this.props.route.params.title, 
                    description : this.props.route.params.description, 
                    image : this.props.route.params.content_image, 
                    date : date, 
                    time : time,
                    videoSrc : {
                        type : "video", 
                        sources : [
                            {
                                src : videoID, 
                                provider : "youtube"
                            }
                        ]
                    }
                })
            }
        }
    }
    componentWillUnmount = ()=>{
        _is_mounted = false;
        window.removeEventListener("message", null); 
    }

    goBack = ()=>{
        /*
        if(this.props.route.params.identifier == Identifier.MAIN) {
            this.props.navigation.navigate(Identifier.MAIN); 
        } else {
            this.props.navigation.navigate(Identifier.NEWS, { identifier : Identifier.MAIN });
        }*/
        this.props.navigation.goBack(); 
    }

    render(){
        const image_width = Dimensions.get("window").width ; 
        const image_height = image_width - (image_width * 0.50) ;  
        
        return(
            <View style={{flex : 1, backgroundColor : primary }}>
                {/* <View style={{
                    height : 56 , 
                    flexDirection : "row", 
                    alignItems : "center", 
                    backgroundColor : "white" ,
                    padding : 12,
                    marginTop: (global.os == "IOS" ? 48 : 0),
                    boxShadow: "0 4px 6px -1px rgba(0,0,0, 0.07)"
                }}>
                    <IconButton
                        onClick={()=>{ this.goBack() }}
                    >
                        <ChevronLeftIcon 
                            sx={{color : "black"}}
                        />
                    </IconButton>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={ 18 }
                        color="black"
                        fontWeight={"500"}
                        style={{ marginLeft : 15 }}
                    >
                        Detail Berita
                    </Typography>
                </View> */}
                
                <View style={{
                    flex : 1,
                    backgroundColor : "white", 
                    borderTopLeftRadius : 0, 
                    borderTopRightRadius : 0,
                }}>
                    <View 
                        onClick={() => this.goBack() } 
                        style={{borderRadius:"10px", position: 'absolute', top: "14px", left: "10px", height:"42px", width: "50px", color: "white", backgroundColor: "rgb(24, 73, 153)", zIndex: 1 }}>
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={ 24 } 
                            fontWeight="600"
                            style={{position:"absolute",top:"2px", left:"16px"}}
                        >
                            X
                        </Typography>
                    </View>
                    <ScrollView>
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={ 16 }
                            fontWeight={"600"}
                            color={ color_text }
                            style={{ 
                                marginTop : 56, 
                                marginLeft : 16, 
                                marginRight : 16, 
                                marginBottom : 8
                            }}
                        >
                            { this.state.title }
                        </Typography>

                        <View style={{flexDirection : "row", alignItems : "center", marginLeft : 16, marginRight : 16 , marginBottom : 16 }}>
                            <CalendarIcon />
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 14 }
                                fontWeight={"400"}
                                color={ sub_text }
                                style={{ 
                                    marginLeft : 4, 
                                    marginRight : 24, 
                                }}
                            >
                                { this.state.date }
                            </Typography>

                            <TimeIcon />
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={ 14 }
                                fontWeight={"400"}
                                color={ sub_text }
                                style={{ 
                                    marginLeft : 4, 
                                }}
                            >
                                { this.state.time }
                            </Typography>
                        </View>

                        {
                            this.props.route.params.tipe == "berita" &&
                            <Image 
                                style={{
                                    width : (image_width - 32 ) , 
                                    height : image_height,
                                    borderRadius : 8 ,
                                    marginLeft : 16, 
                                    marginRight : 16, 
                                    marginBottom : 16
                                }}
                                resizeMode={"cover"}
                                source={{uri : this.state.image }}
                            />
                        }
                        {
                            this.props.route.params.tipe == "youtube" && 
                            <Plyr 
                                source={this.state.videoSrc} 
                                style={{ zIndex: 99 }}
                                
                            />
                        }
                        {
                            this.props.route.params.tipe == "youtubeUtama" && 
                            <Plyr 
                                source={this.state.videoSrc} 
                                style={{ zIndex: 99 }}
                            />
                        }
                        <View style={{marginLeft : 16, marginRight : 16 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={14}
                            >
                            <div 
                                dangerouslySetInnerHTML={{ __html: this.state.description }} 
                            />
                            </Typography>
                        </View>
                        
                    </ScrollView>
                </View>
                
            </View>
        )
    }
}

export default NewsDetail; 