import React, { Component } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import { executeGet, executePost } from '../utilities/Utilities';
import { color_text, sub_text, primary } from '../constants/colors';
import * as Command from '../constants/Commands';
import * as Identifier from '../constants/PageIdentifiers';
import LoadingPage from '../components/LoadingPage';
import TextBox from '../components/TextBox';
import {
    Alert, AlertTitle, Typography, Button, IconButton,
    List, ListItemButton, ListItemIcon, Collapse, TextField,
    ListItemText
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import OptionBox from '../components/OptionBox';
import RadioBox from '../components/RadioBox';
import {
    code_ok, code_empty, code_error, code_auth_error, appConfig, updateProfile,
    saveLembaga, profile, regencies, getLembaga
} from '../constants/api';
import EducationDialog from '../components/EducationDialog';
import OccupationDialog from '../components/OccupationDialog';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// import TextField from '@mui/material';
import moment from 'moment';

var _is_mounted = false;
class EditData extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showLoading: false,
            loadingMessage: "",
            showAlert: false,
            showDatePicker: false,
            alertMessage: "",
            fullname: this.props.route.params.fullname,
            birthdate: this.props.route.params.birthdate,
            sql_date: this.props.route.params.sql_date,
            gender: this.props.route.params.gender,
            occupation: this.props.route.params.occupation,
            education: this.props.route.params.education,
            educations: [],
            occupations: [],
            identities: [],
            errorName: false,
            errorNameMessage: "Nama lengkap harap diisi",
            errorDate: false,
            errorDateMessage: "Tgl lahir harap diisi",
            errorEducation: false,
            errorEducationMessage: "Jenjang edukasi harap dipilih",
            errorOccupation: false,
            errorOccupationMessage: "Jenis pekerjaan harap dipilih",
            showEducations: false,
            selectedEducationCode: this.props.route.params.education_code,
            showOccupations: false,
            selectedOccupationCode: this.props.route.params.occupation_code,
            regencies: [],
            showRegencies: false,
            selectedProvinceId: "-",
            city: "",
            cityId: "",
            errorCity: false,
            errorCityMessage: "Masukkan kota lahir anda",
            flagLembaga: false,
            showLembaga: false,
            lembagas: [],
            lembaga: "",
            selectedLembagaCode: "",
            errorLembaga: false,
            errorLembagaMessage: ""
        }

        this.goBack = this.goBack.bind(this);
        this.fetchConfig = this.fetchConfig.bind(this);
        //== Refs ==//
        this.nameRef = React.createRef();
        this.birthRef = React.createRef();

        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.handleOccupationClick = this.handleOccupationClick.bind(this);
        this.handleEducationClick = this.handleEducationClick.bind(this);
        this.handleSelectedBirthdate = this.handleSelectedBirthdate.bind(this);
        this.handleSelectedEducation = this.handleSelectedEducation.bind(this);
        this.handleSelectedOccupation = this.handleSelectedOccupation.bind(this);

        //== onChangeText ==// 
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleCalendarClick = this.handleCalendarClick.bind(this);

        this.handleCityClick = this.handleCityClick.bind(this);
        this.handleProvinceClick = this.handleProvinceClick.bind(this)

        this.handleUpdateClick = this.handleUpdateClick.bind(this);

        this.executeUpdate = this.executeUpdate.bind(this);

        this.fetchRegencies = this.fetchRegencies.bind(this);
        this.fetchLembaga = this.fetchLembaga.bind(this);
        this.setRegency = this.setRegency.bind(this);
    }

    componentDidMount = () => {
        _is_mounted = true;
        this.getProfile();
    }

    //ANCHOR - Get Profile
    getProfile = async () => {
        const response = await executeGet(profile);
        console.log("profile = " + JSON.stringify(response))

        // formData.append("pendidikan", this.state.selectedEducationCode); 
        // formData.append("pekerjaan", this.state.selectedOccupationCode); 
        // formData.append("lembaga_id", this.state.selectedLembagaCode);

        if (response.code == code_ok) {
            this.setState({
                data: response.data,
                photo: response.data.photo,
                fullname: response.data.user.fullname,
                education: response.data.education,
                occupation: response.data.occupation,
                formatted_birthdate: response.data.user.birthdate,
                birthdate: response.data.user.sql_date,
                city: response.data.kota,
                cityId: response.data.kota_id,
                selectedProvinceId: response.data.provinsi_id,
                lembaga: response.data.lembaga,
                selectedEducationCode: response.data.education,
                selectedOccupationCode: response.data.occupation_code,
                selectedLembagaCode: response.data.perpus_id
                // formatted_birthdate: moment(value).format("DD/MMM/YYYY"),
            }, () => {
                // this.getReadingHistory();
                this.fetchConfig();
                this.fetchRegencies();
                this.fetchLembaga();
                // if(response.data.provinsi_id) {
                //     formData.append("provinsi_id", this.state.selectedProvinceId ); 
                //     formData.append("kota_id", this.state.cityId ); 
                //     formData.append("jenjang", this.state.selectedEducationCode ); 
                // }
            })
        } else {
            if (response.code == code_auth_error) {
                window.postMessage({ id: "sign_out" })
            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }
        }
    }

    handleCityClick = () => {
        if (_is_mounted) {
            if (this.state.regencies.length > 0) {
                this.setState({
                    showRegencies: true,
                    // showLoading: true,
                    // loadingMessage: "Memuat data"
                })
            }
        }
    }

    handleProvinceClick = (item) => {
        if (this.state.selectedProvinceId == item.id) {
            this.setState({
                selectedProvinceId: "-"
            })
        } else {
            this.setState({
                selectedProvinceId: item.id
            })
        }
    }

    fetchRegencies = async () => {
        const response = await executeGet(regencies);
        console.log('fetchRegencies = ' + JSON.stringify(response))
        if (response.code == code_ok) {
            if (_is_mounted) {
                this.setState({
                    regencies: response.data
                });
            }
        } else {
            //TODO: handle errors 
        }
    }

    componentWillUnmount = () => {
        _is_mounted = false;
        window.removeEventListener("message", null);

    }
    goBack = () => {
        this.props.navigation.navigate(Identifier.PROFILE, { identifier: Identifier.MAIN });
    }

    handleNameChange = (text) => {
        if (_is_mounted) {
            this.setState({
                fullname: text,
                errorName: false
            })
        }
    }
    handleCalendarClick = () => {
        if (global.os == "ANDROID") {
            // JSBridge.launchFunction(Command.SET_DATE, "-");
            if (!this.state.showDatePicker) {
                this.setState({
                    showDatePicker: true
                })
            } else {
                this.setState({
                    showDatePicker: false
                })
            }
        } else {
            //TODO: IOS Bridge 
            // let obj = {
            //     id: Command.SET_DATE,
            //     params: "-"
            // }
            // window.webkit.messageHandlers.JSBridge.postMessage(obj);
            if (!this.state.showDatePicker) {
                this.setState({
                    showDatePicker: true
                })
            } else {
                this.setState({
                    showDatePicker: false
                })
            }
        }
        // if (!this.state.showDatePicker) {
        //     this.setState({
        //         showDatePicker: true
        //     })
        // } else {
        //     this.setState({
        //         showDatePicker: false
        //     })
        // } 
    }
    handleSelectedBirthdate = (value) => {
        console.log("select_tanggal_lahir lama" + moment(this.state.formatted_birthdate).format("DD/MMM"));
        console.log("select_tanggal_lahir baru" + moment(value).format("DD/MMM"));

        let dateLama = moment(this.state.formatted_birthdate).format("DD/MMM");
        let dateBaru = moment(value).format("DD/MMM");

        if(dateLama != dateBaru) {
            this.setState({
                formatted_birthdate: moment(value).format("DD/MMM/YYYY"),
                birthdate: moment(value).format("YYYY-MM-DD"),
                errorDate: false,
                showDatePicker: false
            })
        }

        console.log("select_tanggal_lahir lama" + moment(this.state.formatted_birthdate).format("DD/MMM/YYYY"));
        console.log("select_tanggal_lahir baru" + moment(value).format("DD/MMM/YYYY"));
        

        // this.setState({
        //     showDatePicker : false
        // })

    }
    fetchConfig = async () => {
        this.setState({
            showLoading: true,
            loadingMessage: "Memuat data"
        })
        const response = await executeGet(appConfig);
        if (response.code == code_ok) {
            let data = response.data;
            this.setState({
                showLoading: false,
                educations: data.jenjang,
                occupations: data.occupations,
                identities: data.identities
            })

        } else {
            this.setState({
                showLoading: false,
                alertShowing: true,
                alertTitle: "Error",
                alertMessage: response.msg
            });
        }
    }
    fetchLembaga = async () => {
        console.log("Fetch Lembaga = " + this.state.selectedEducationCode);
        let formData = new FormData();
        formData.append("provinsi_id", this.state.selectedProvinceId);
        formData.append("kota_id", this.state.cityId);
        formData.append("jenjang", this.state.selectedEducationCode);

        const url = getLembaga + "?provinsi_id=" + this.state.selectedProvinceId + "&kota_id=" + this.state.cityId + "&jenjang=" + this.state.selectedEducationCode;
        // const response = await executePost(getLembaga, formData); 
        console.log('fetchLembaga = ' + url)
        const response = await executeGet(url);
        console.log("fetchLembaga = " + JSON.stringify(response));
        if (response.code == code_ok) {
            if (_is_mounted) {
                this.setState({
                    flagLembaga: true,
                    lembagas: response.data
                });
            }


        } else {
            //TODO: handle errors 
        }
    }
    setRegency = (item) => {
        // this.setState({
        //     city : item.name ,
        //     cityId: item.id,
        //     showRegencies : false,
        //     errorCity : false
        // })

        this.setState({
            city: item.name,
            cityId: item.id,
            showRegencies: false,
            errorCity: false
        }, () => {
            // only now the state was updated
            this.fetchLembaga();
        });
    }
    handleSelectedEducation = (item) => {
        this.setState({
            showEducations: false,
            education: item.name,
            selectedEducationCode: item.code,
            errorEducation: false
        }, () => {
            // only now the state was updated
            console.log("Data is here", this.state.selectedEducationCode);
            this.fetchLembaga();
        });
    }

    handleSelectedOccupation = (item) => {
        this.setState({
            showOccupations: false,
            occupation: item.name,
            selectedOccupationCode: item.code,
            errorOccupation: false
        })
    }
    handleRadioChange = (event) => {
        if (_is_mounted) {
            this.setState({
                gender: event.target.value
            })
        }
    }
    handleOccupationClick = () => {
        if (_is_mounted) {
            this.setState({
                showOccupations: true
            })
        }
    }
    handleEducationClick = () => {
        if (_is_mounted) {
            this.setState({
                showEducations: true
            })
        }
    }
    handleUpdateClick = () => {
        if (this.state.fullname.length == 0) {
            this.setState({
                errorName: true,
                errorNameMessage: "Nama lengkap harap diisi"
            }, () => {
                this.nameRef.current.focus();
            });
            return;
        }

        this.executeUpdate();
    }
    handleLembagaClick = () => {
        if (_is_mounted) {
            this.setState({
                showLembaga: true
            })
        }
    }
    handleSelectedLembaga = (item) => {
        this.setState({
            showLembaga: false,
            lembaga: item.nama_lembaga,
            selectedLembagaCode: item.id,
            errorLembaga: false
        })
    }
    executeUpdate = async () => {

        let formData = new FormData();
        // formData.append("fullname", this.state.fullname);
        // formData.append("education_code", this.state.selectedEducationCode);
        // formData.append("occupation_code", this.state.selectedOccupationCode);
        // formData.append("birthdate", this.state.sql_date);
        // formData.append("gender", this.state.gender);

        formData.append("fullname", this.state.fullname);
        formData.append("provinsi_id", this.state.selectedProvinceId);
        formData.append("kota_id", this.state.cityId);
        formData.append("tanggal_lahir", this.state.birthdate);
        formData.append("pendidikan", this.state.selectedEducationCode);
        formData.append("pekerjaan", this.state.selectedOccupationCode);
        formData.append("lembaga_id", this.state.selectedLembagaCode);

        this.setState({
            showLoading: true,
            loadingMessage: "Memperbarui data anda"
        })

        const response = await executePost(saveLembaga, formData);
        if (response.code == code_ok) {
            this.setState({
                showLoading: false,
            }, () => {
                let obj = {
                    id: "update_fullname",
                    fullname: this.state.fullname
                }
                window.postMessage(obj);
                this.goBack();
            })
        } else {

            if (response.code == code_auth_error) {
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }

        }

    }
    render() {
        return (
            <View style={{ flex: 1, backgroundColor: primary }}>
                <View style={{
                    height: 56,
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: primary,
                    padding: 12,
                    // marginTop: (global.os == "ANDROID" ? 0 : 32),
                }}>
                    <IconButton
                        onClick={() => { this.goBack() }}
                    >
                        <ChevronLeftIcon
                            sx={{ color: "white" }}
                        />
                    </IconButton>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={16}
                        color="white"
                        fontWeight={"500"}
                        style={{ marginLeft: 15 }}
                    >
                        Edit Profil
                    </Typography>
                </View>
                {
                    this.state.showLoading &&
                    <LoadingPage
                        message={this.state.loadingMessage}
                    />
                }
                {
                    !this.state.showLoading &&
                    <ScrollView style={{ paddingLeft: 16, paddingRight: 16, backgroundColor: "white", flex: 1 }}>
                        {
                            !this.state.showRegencies && !this.state.showLembaga &&
                            <>
                                <TextBox
                                    reference={this.nameRef}
                                    caption={"Nama Lengkap"}
                                    placeholder={"Masukkan nama lengkap"}
                                    containerStyle={{ marginTop: 16 }}
                                    error={this.state.errorName}
                                    value={this.state.fullname}
                                    errorMessage={this.state.errorNameMessage}
                                    onChangeText={this.handleNameChange}
                                />
                                {/* <RadioBox 
                            caption={"Jenis Kelamin"}
                            containerStyle={{marginTop : 16 }}
                            captionA={"Laki-Laki"}
                            captionB={"Perempuan"}
                            valueA={"L"}
                            valueB={"P"}
                            value={ this.state.gender }
                            onChange={ this.handleRadioChange }
                        /> */}

                                {/* <Typography
                                    variant="h3"
                                    fontWeight="400"
                                    fontSize={ 14 } 
                                    fontFamily="Open Sans"
                                    marginLeft={0.5}
                                    marginTop={'16px'}
                                    marginBottom={'10px'}
                                >
                                    Tanggal Lahir
                                </Typography>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <MobileDatePicker 
                                            inputFormat="DD/MMM/YYYY"
                                            value={this.state.formatted_birthdate}
                                            onChange={this.handleSelectedBirthdate}
                                            renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider> */}
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <OptionBox
                                        reference={this.birthRef}
                                        editable={false}
                                        caption={"Tanggal Lahir"}
                                        usingIcon={true}
                                        placeholder={"Pilih tanggal lahir"}
                                        containerStyle={{ marginTop: 16 }}
                                        icon={<CalendarTodayIcon fontSize="small" />}
                                        onIconClick={this.handleCalendarClick}
                                        error={this.state.errorDate}
                                        errorMessage={this.state.errorDateMessage}
                                        value={this.state.formatted_birthdate}
                                    />
                                    {
                                        this.state.showDatePicker &&
                                        <StaticDatePicker
                                            displayStaticWrapperAs="desktop"
                                            inputFormat="DD/MMM/YYYY"
                                            value={this.state.formatted_birthdate}
                                            onChange={this.handleSelectedBirthdate}
                                            renderInput={(params) => <TextField {...params} />}
                                        />

                                    }
                                </LocalizationProvider>

                                <OptionBox
                                    reference={this.cityRef}
                                    caption={"Kota"}
                                    usingIcon={true}
                                    editable={false}
                                    placeholder={"Pilih kota "}
                                    containerStyle={{ marginTop: 16 }}
                                    value={this.state.city}
                                    icon={<ExpandMoreIcon fontSize="small" />}
                                    error={this.state.errorCity}
                                    errorMessage={this.state.errorCityMessage}
                                    onIconClick={this.handleCityClick}
                                />
                                <OptionBox
                                    reference={this.educationRef}
                                    caption={"Pendidikan"}
                                    usingIcon={true}
                                    editable={false}
                                    placeholder={"Pilih pendidikan terakhir anda"}
                                    containerStyle={{ marginTop: 16 }}
                                    value={this.state.education}
                                    icon={<ExpandMoreIcon fontSize="small" />}
                                    error={this.state.errorEducation}
                                    errorMessage={this.state.errorEducationMessage}
                                    onIconClick={this.handleEducationClick}
                                />
                                {
                                    this.state.flagLembaga &&
                                    <OptionBox
                                        reference={this.lembagaRef}
                                        caption={"Lembaga"}
                                        usingIcon={true}
                                        editable={false}
                                        placeholder={"Pilih Lembaga"}
                                        containerStyle={{ marginTop: 16 }}
                                        value={this.state.lembaga}
                                        icon={<ExpandMoreIcon fontSize="small" />}
                                        error={this.state.errorLembaga}
                                        errorMessage={this.state.errorLembagaMessage}
                                        onIconClick={this.handleLembagaClick}
                                    />
                                }
                                <OptionBox
                                    reference={this.occupationRef}
                                    caption={"Pekerjaan"}
                                    usingIcon={true}
                                    editable={false}
                                    placeholder={"Pilih pekerjaan anda"}
                                    containerStyle={{ marginTop: 16 }}
                                    value={this.state.occupation}
                                    icon={<ExpandMoreIcon fontSize="small" />}
                                    error={this.state.errorOccupation}
                                    errorMessage={this.state.errorOccupationMessage}
                                    onIconClick={this.handleOccupationClick}
                                />
                                <Button
                                    variant="contained"
                                    style={{ marginTop: 24, marginBottom: 24, textTransform: "none", borderRadius: 8, padding: 10 }}
                                    onClick={this.handleUpdateClick}
                                >
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={16}
                                        fontWeight="500"
                                    >
                                        Update
                                    </Typography>
                                </Button>
                            </>
                        }

                        {
                            this.state.showRegencies && !this.state.showLembaga &&
                            <View style={{ flex: 1, marginTop: 16, marginLeft: 16, marginRight: 16, marginBottom: 16 }}>
                                <List>
                                    {
                                        this.state.regencies.map((item, index) => (
                                            <>
                                                <ListItemButton component="a" onClick={() => { this.handleProvinceClick(item) }}>
                                                    <ListItemIcon key={index}>
                                                        {
                                                            (this.state.selectedProvinceId == item.id) &&
                                                            <ExpandMoreIcon />
                                                        }
                                                        {
                                                            (this.state.selectedProvinceId != item.id) &&
                                                            <ChevronRightIcon />
                                                        }

                                                    </ListItemIcon>
                                                    <ListItemText primary={item.name} />

                                                </ListItemButton>
                                                <Collapse in={this.state.selectedProvinceId == item.id}>
                                                    <List component="div" disablePadding>
                                                        {
                                                            item.regencies.map((i, d) => (
                                                                <ListItemButton key={"child_" + d} style={{ marginLeft: 8 }} onClick={() => { this.setRegency(i) }}>
                                                                    <ListItemIcon key={index}>
                                                                        <ChevronRightIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={i.name} />
                                                                </ListItemButton>
                                                            ))
                                                        }
                                                    </List>
                                                </Collapse>
                                            </>

                                        ))
                                    }
                                </List>

                            </View>
                        }

                        {
                            this.state.showLembaga && !this.state.showRegencies &&
                            <View style={{ flex: 1, marginTop: 16, marginLeft: 16, marginRight: 16, marginBottom: 16 }}>
                                <List>
                                    {
                                        this.state.lembagas.map((item, index) => (
                                            <>
                                                <ListItemButton component="a" onClick={() => { this.handleSelectedLembaga(item) }}>
                                                    {/* <ListItemIcon key={ index }>
                                                            {
                                                                (this.state.selectedProvinceId == item.id) && 
                                                                <ExpandMoreIcon />
                                                            }
                                                            {
                                                                (this.state.selectedProvinceId != item.id) && 
                                                                <ChevronRightIcon />
                                                            }
                                                            
                                                        </ListItemIcon> */}
                                                    <ListItemText primary={item.nama_lembaga} />

                                                </ListItemButton>
                                            </>

                                        ))
                                    }
                                </List>

                            </View>
                        }

                    </ScrollView>
                }
                <EducationDialog
                    showing={this.state.showEducations}
                    data={this.state.educations}
                    title={"Pilih Jenjang"}
                    onItemClick={this.handleSelectedEducation}
                />
                {/* <EducationDialog 
                    showing={ this.state.showLembaga} 
                    data={ this.state.lembagas }
                    title={ "Pilih Lembaga" }
                    onItemClick={ this.handleSelectedLembaga }
                /> */}
                <OccupationDialog
                    showing={this.state.showOccupations}
                    data={this.state.occupations}
                    onItemClick={this.handleSelectedOccupation}
                />
                {
                    this.state.showAlert &&
                    <Alert
                        onClose={() => { this.goBack() }}
                        severity="error"
                    >
                        <AlertTitle>Error</AlertTitle>
                        {this.state.alertMessage}
                    </Alert>
                }

            </View>
        )
    }
}


export default EditData;