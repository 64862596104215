import React, { Component } from 'react';
import { StyleSheet, View, FlatList, Image, Dimensions } from 'react-native';
import { code_ok, code_error, code_auth_error, code_empty, homepageRecommended, homepageNews } from '../../constants/api';
import { Skeleton, Typography, Card, CardActionArea, CardMedia, CardContent, Button } from '@mui/material';
import { executeGet, executePost, truncateText, navigateContentDetail } from '../../utilities/Utilities';
import AlertDialog from '../../components/AlertDialog';
import { color_text, sub_text, primary } from '../../constants/colors';
import * as Identifier from '../../constants/PageIdentifiers';


class MenuNewsPilihanDesktop extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            showAlert: false,
            alertMessage: "",
            loadingMessage: "",
            code: 'HMP-0008',
            data: [],
            dummy_data: [
                { i: 0 }, { i: 1 }, { i: 2 }
            ]

        }

        this.handleAlertClose = this.handleAlertClose.bind(this);
        this.handleMoreClick = this.handleMoreClick.bind(this);
        this.fetchContents = this.fetchContents.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.handleImageError = this.handleImageError.bind(this);
        this.contentDetail = this.contentDetail.bind(this);
        this.fetchNews = this.fetchNews.bind(this);
        this.handleNewsClick = this.handleNewsClick.bind(this);
    }

    componentDidMount = () => {
        // this.fetchContents(); 
        this.fetchNews();
    }

    handleAlertClose = () => {

    }
    handleMoreClick = (code, title) => {
        console.log("CODE ");
        console.log(code);
        this.props.navigation.navigate("NewsDesktop", { identifier : Identifier.NEWS, code : code, title : title, origins : "NEWS"}); 
    }

    fetchContents = async () => {

        let formData = new FormData();
        formData.append("page", 1);
        formData.append("homepage_code", this.props.code)
        formData.append("limit", 3);

        const response = await executePost(homepageRecommended, formData);
        console.log("homepageNews = " + JSON.stringify(response));
        if (response.code == code_ok) {
            console.log(JSON.stringify(this.state.data));
            this.setState({
                showLoading: false,
                // data : response.data
            })
        } else {
            if (response.code != code_auth_error) {
                this.setState({
                    showLoading: false,
                    alertMessage: response.msg,
                    showAlert: true
                })
            } else {
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            }
        }
    }

    fetchNews = async () => {
        let formData = new FormData();
        formData.append("homepage_code", 'HMP-0008');
        formData.append("page", 1);
        formData.append("limit", 4);

        const response = await executePost(homepageNews, formData);
        console.log('fetchNews = ' + JSON.stringify(response))
        if (response.code == code_ok) {
            console.log(JSON.stringify(response));
            this.setState({
                showLoading: false,
                data: response.data
            })
            // data: response.data

        } else {
            if (response.code != code_auth_error) {
                this.setState({
                    showLoading: false,
                })
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, response.msg);
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : response.msg })
                // }
            } else {
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            }
        }

    }

    handleNewsClick = (item, index) => {
        item.identifier = Identifier.MAIN;
        this.props.navigation.navigate("DesktopNewsDetail", item);
    }

    renderItem = ({ item, index, separators }) => {
        return (
            <>
                {
                    index>0 && index <= 3 &&
                    <Card
                        style={{
                            borderRadius: 8,
                            marginLeft: 16,
                            marginRight: 16,
                            marginTop: (index == 0 ? 0 : 8),
                            marginBottom: (index == (this.state.data.length - 1) ? 8 : 0),
                            boxShadow: "0px 33px 74px rgba(0, 0, 0, 0.0196802), 0px 13.7866px 30.9154px rgba(0, 0, 0, 0.0282725), 0px 7.37098px 16.5289px rgba(0, 0, 0, 0.035), 0px 4.13211px 9.26595px rgba(0, 0, 0, 0.0417275), 0px 2.19453px 4.92108px rgba(0, 0, 0, 0.0503198), 0px 0.913195px 2.04777px rgba(0, 0, 0, 0.07)"
                        }}
                    >
                        <CardActionArea
                            onClick={() => { this.handleNewsClick(item, index) }}
                        >
                            <View style={{ flexDirection: "row", padding: 8 }}>
                                <Image
                                    style={{
                                        width: 90,
                                        height: 90,
                                        borderRadius: 8
                                    }}
                                    source={{ uri: item.content_image }}
                                    onError={(error) => { this.handleImageError(item, index) }}
                                />
                                <View style={{ flex: 1, marginLeft: 10 }}>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={14}
                                        color={color_text}
                                        fontWeight={"600"}
                                    >
                                        {/* { truncateText(item.title, 50) } */}
                                        {item.title}
                                    </Typography>
                                    
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={10}
                                        color={sub_text}
                                        style={{ marginTop: 10 }}
                                    >
                                        {item.input_time}
                                    </Typography>
                                    

                                </View>
                            </View>
                        </CardActionArea>
                    </Card>

                }

            </>
        )
    }

    contentDetail = (item, index) => {
        let params = {
            previous: Identifier.MAIN,
            parameters: {},
            content_code: item.content_code
        }
        navigateContentDetail(this.props.navigation, params);
    }
    handleImageError = (item, index) => {
        let data = [...this.state.data];
        let obj = { ...data[index] };
        obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
        data[index] = obj;
        this.setState({
            data: data
        })
    }

    render() {
        return (
            <View style={{ width: "100%", paddingLeft: 147, paddingRight: 147 }}>
                {
                    this.state.showLoading &&
                    <View>
                        <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: '2rem', maxWidth: 120 }}
                            style={{ marginLeft: 16, marginBottom: 8, marginRight: 16, marginTop: 16 }}
                        />
                        <Skeleton
                            variant="rectangular"
                            style={{ height: 90, marginTop: 8, borderRadius: 8, marginLeft: 16, marginRight: 16 }}
                            animation="wave"
                        />
                        <Skeleton
                            variant="rectangular"
                            style={{ height: 90, marginTop: 8, borderRadius: 8, marginLeft: 16, marginRight: 16 }}
                            animation="wave"
                        />
                        <Skeleton
                            variant="rectangular"
                            style={{ height: 90, marginTop: 8, borderRadius: 8, marginLeft: 16, marginRight: 16 }}
                            animation="wave"
                        />
                    </View>
                }
                {
                    !this.state.showLoading &&
                    <View style={{ marginTop: 30 }}>
                        <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 20 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={28}
                                variant="h3"
                                style={{ flex: 1, marginLeft: 10 }}
                                color={color_text}
                                fontWeight={"600"}
                            >
                                {truncateText(this.props.title, 30)}
                            </Typography>
                            <Button
                                style={{ textTransform: "none", marginRight: 16 }}
                                onClick={() => { this.handleMoreClick(this.state.code, this.props.title) }}
                            >
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={16}
                                    fontWeight="500"
                                    color={primary}
                                >
                                    Lihat Semua
                                </Typography>
                            </Button>
                        </View>

                        <View style={{
                            flex: 1,
                            flexDirection: "row"
                        }}>
                            <View style={{ flex: 1 }} >
                                <Card style={styles.card}
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => { this.handleNewsClick(this.state.data[0], 0) }}
                                >
                                    <CardMedia image={this.state.data[0].content_image} style={styles.media} />
                                    <div style={styles.overlay}>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={20}
                                            color={"#ffffff"}
                                            fontWeight={"600"}
                                            styles={{marginRight: 10}}
                                        >
                                            {/* { truncateText(item.title, 50) } */}
                                            {this.state.data[0].title}
                                        </Typography>
                                    </div>
                                    <div style={styles.overlaySub}>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={14}
                                            color={"#ffffff"}
                                            fontWeight={"600"}
                                            styles={{marginRight: 10}}
                                        >
                                            {this.state.data[0].input_time}
                                        </Typography>
                                    </div>
                                </Card>
                            </View>

                            <View style={{ flex: 1 }} >
                                <FlatList
                                    keyExtractor={(item, index) => "rec_" + item.content_code}
                                    data={this.state.data}
                                    renderItem={this.renderItem}
                                    showsVerticalScrollIndicator={false}
                                />
                            </View>
                        </View>



                    </View>

                }


            </View>
        )
    }

}

const styles = {
    media: {
        height: 0,
        paddingTop: '56.25%' // 16:9
    },
    card: {
        position: 'relative',
        margin: '16px',
        borderRadius: '15px'
    },
    overlay: {
        position: 'absolute',
        bottom: '39px',
        left: '20px',
        color: 'white',
        fontSize: '18px'
    },
    overlaySub: {
        position: 'absolute',
        bottom: '12px',
        left: '20px',
        color: 'white',
        fontSize: '12px'
    }
}

export default MenuNewsPilihanDesktop;