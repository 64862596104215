import React, { Component } from 'react';
import { StyleSheet, Image, View, Linking, Dimensions, Pressable, ScrollView, TextInput } from 'react-native';
import {
    Alert, AlertTitle, CircularProgress, LinearProgress, Typography, Button, List, ListItemButton,
    ListItemText, ListItemIcon, Collapse, AutoComplete, TextField
} from '@mui/material';
import { code_ok, code_empty, code_error, code_auth_error, regencies, userRegistration, appConfig, getLembaga, saveLembaga, readLocalContent, readContent  } from '../constants/api';
import { Layer1Background, Layer2Background, WordLogo, CrowdBackground } from '../assets/Backgrounds';
import { LogoPusnas } from '../assets/Icons';
import { primary, color_text, textbox, danger, sub_text } from '../constants/colors';
import { executeGet, executePost } from '../utilities/Utilities';
import TextBox from '../components/TextBox';
import PasswordBox from '../components/PasswordBox';
import RadioBox from '../components/RadioBox';
import OptionBox from '../components/OptionBox';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Identifier from "../constants/PageIdentifiers";
import * as Command from "../constants/Commands";
import RegenciesDialog from '../components/RegenciesDialog';
import EducationDialog from '../components/EducationDialog';
import OccupationDialog from '../components/OccupationDialog';
import IdentityDialog from '../components/IdentityDialog';
import ComboBox from '../components/ComboBox';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import moment from 'moment';
import LoadingDialog from '../components/LoadingDialog';
import WarningDialog from '../components/WarningDialog';
import SuccessDialog from '../components/SuccessDialog';
import HeaderLogo from '../assets/HeaderLogo';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/700.css';
import HeaderLogoWhite from '../assets/HeaderLogoWhite';

var _is_mounted = false;

class VerificationUserDesktop extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            city: "",
            cityId: "",
            birthdate: "",
            formatted_birthdate: "",
            password: "",
            retype: "",
            gender: "L",
            occupation: "",
            education: "",
            educations: [],
            occupations: [],
            identities: [],
            identityNumber: "",
            identityType: "",
            errorIdentityType: false,
            errorIdentityTypeMessage: "",
            selectedIdentityCode: "",
            showIdentity: false,
            errorName: false,
            errorNameMessage: "Masukkan nama lengkap anda",
            errorDate: false,
            errorDateMessage: "Pilihlah tanggal lahir anda",
            errorEmail: false,
            errorEmailMessage: "Masukkan e-mail anda dengan format yang benar",
            errorCity: false,
            errorCityMessage: "Masukkan kota lahir anda",
            errorEducation: false,
            errorEducationMessage: "",
            errorOccupation: false,
            errorOccupationMessage: "",
            errorPassword: false,
            errorPasswordMessage: "Minimal 8-karakter alfanumerik",
            errorRetype: false,
            errorRetypeMessage: "Ketik ulang password anda",
            errorIdentity: false,
            errorIdentityMessage: "",
            regencies: [],
            showRegencies: false,
            selectedProvinceId: "-",
            showEducations: false,
            selectedEducationCode: "",
            showOccupations: false,
            selectedOccupationCode: "",
            showIdentityDialog: false,
            isSubmitting: false,
            showAlert: false,
            alertMessage: "",
            showSuccess: false,
            successMessage: "",
            showDatePicker: false,
            flagLembaga: false,
            showLembaga: false,
            lembagas: [],
            lembaga: "",
            selectedLembagaCode: "",
            errorLembaga : false, 
            errorLembagaMessage : ""

        }

        //== Refs ==//
        this.nameRef = React.createRef();
        this.birthRef = React.createRef();
        this.cityRef = React.createRef();
        this.identityRef = React.createRef();
        this.identTypeRef = React.createRef();
        this.educationRef = React.createRef();
        this.lembagaRef = React.createRef();
        this.occupationRef = React.createRef();
        this.emailRef = React.createRef();
        this.passwordRef = React.createRef();
        this.retypeRef = React.createRef();

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.handleCalendarClick = this.handleCalendarClick.bind(this);
        this.handleSelectedBirthdate = this.handleSelectedBirthdate.bind(this);
        this.handleCityClick = this.handleCityClick.bind(this);
        this.handleIdentityChange = this.handleIdentityChange.bind(this);
        this.handleIdentityTypeClick = this.handleIdentityTypeClick.bind(this);
        this.handleEducationClick = this.handleEducationClick.bind(this);
        this.handleOccupationClick = this.handleOccupationClick.bind(this);
        this.handleSelectedEducation = this.handleSelectedEducation.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleRetypeChange = this.handleRetypeChange.bind(this);
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.handleProvinceClick = this.handleProvinceClick.bind(this);
        this.setRegency = this.setRegency.bind(this);
        this.executeSignUp = this.executeSignUp.bind(this);
        this.backToLogin = this.backToLogin.bind(this);
        this.fetchRegencies = this.fetchRegencies.bind(this);
        this.fetchLembaga = this.fetchLembaga.bind(this);

        this.handleWarningDialogClick = this.handleWarningDialogClick.bind(this);
        this.handleSuccessDialogClick = this.handleSuccessDialogClick.bind(this);

    }

    componentDidMount = () => {
        const flagVerification = window.localStorage.getItem("cacheVerification");    
        if(flagVerification == "1") {
            console.log("go back = " + flagVerification)
            this.props.navigation.goBack();
        }
        
        _is_mounted = true;
        this.fetchRegencies();
        this.configuration();
        let default_birthdate = moment().subtract(7, 'year').format("DD/MMM/YYYY");

        this.setState({
            formatted_birthdate: "Pilih Tanggal Lahir Anda",
            // educations: [{ code: "", name: "-- Pilih pendidikan terakhir anda --" }, ...this.props.route.params.educations],
            // occupations: [{ code: "", name: "-- Pilih pekerjaan anda --" }, ...this.props.route.params.occupations],
            // identities: [{ code: "", name: "-- Pilih jenis identitas anda" }, ...this.props.route.params.identities]
        })
    }

    componentWillUnmount = () => {
        _is_mounted = false;

    }

    configuration = async () => {
        const response = await executeGet(appConfig);
        if (response.code == code_ok) {
            let data = response.data;
            this.setState({
                educations: [
                    {
                        "code": "SD",
                        "name": "SD/MI"
                    },
                    {
                        "code": "SMP",
                        "name": "SMP"
                    },
                    {
                        "code": "SMA",
                        "name": "SMA"
                    },
                    {
                        "code": "PERTI",
                        "name": "PERGURUAN TINGGI"
                    }
                ],
                occupations: data.occupations,
                identities: data.identities
            })

        } else {
            this.setState({
                alertShowing: true,
                alertTitle: "Error",
                alertMessage: response.msg
            });
        }
    }

    fetchRegencies = async () => {
        const response = await executeGet(regencies);
        if (response.code == code_ok) {
            if (_is_mounted) {
                this.setState({
                    regencies: response.data
                });
            }
        } else {
            //TODO: handle errors 
        }
    }

    backToLogin = () => {
        this.props.navigation.goBack();
    }

    handleNameChange = (text) => {
        if (_is_mounted) {
            this.setState({
                name: text,
                errorName: false
            })
        }
    }
    handleRadioChange = (event) => {
        if (_is_mounted) {
            this.setState({
                gender: event.target.value
            })
        }
    }
    handleCalendarClick = () => {
        if (_is_mounted) {
            if (!this.state.showDatePicker) {
                this.setState({
                    showDatePicker: true
                })
            } else {
                this.setState({
                    showDatePicker: false
                })
            }
        }
    }

    handleSelectedBirthdate = (value) => {
        //console.log(moment(value).format("DD/MMM/YYYY"));
        this.setState({
            formatted_birthdate: moment(value).format("DD/MMM/YYYY"),
            birthdate: moment(value).format("YYYY-MM-DD"),
            errorDate: false,
            showDatePicker: false
        })
        // this.setState({
        //     showDatePicker : false
        // })

    }

    handleCityClick = () => {
        console.log('handleCityClick')
        if (_is_mounted) {
            if (this.state.regencies.length > 0) {
                this.setState({
                    showRegencies: true
                })
            }
        }
    }
    handleIdentityChange = (text) => {
        if (_is_mounted) {
            this.setState({
                identityNumber: text,
                errorIdentity: false
            })
        }
    }
    handleIdentityTypeClick = () => {
        this.setState({
            showIdentityDialog: true
        })
    }
    handleEducationClick = () => {
        if (_is_mounted) {
            this.setState({
                showEducations: true
            })
        }
    }

    handleLembagaClick = ()=>{
        if(_is_mounted){
            this.setState({
                showLembaga : true
            })
        }
    }

    handleOccupationClick = () => {
        if (_is_mounted) {
            this.setState({
                showOccupations: true
            })
        }
    }


    handleSelectedEducation = (item) => {
        this.setState({
            showEducations: false,
            education: item.name,
            selectedEducationCode: item.code,
            errorEducation: false
        }, () => {
            // only now the state was updated
            console.log("Data is here", this.state.selectedEducationCode); 
            this.fetchLembaga();
         });

    }

    handleSelectedLembaga = (item)=>{
        console.log("Lembaga yang dipilih: ");
        console.log(item);
        this.setState({
            showLembaga : false,
            lembaga : item.nama_lembaga, 
            selectedLembagaCode : item.id,
            errorLembaga : false
        })
    }

    fetchLembaga = async()=>{
        console.log("Fetch Lembaga = " + this.state.selectedEducationCode);
        let formData = new FormData();
        formData.append("provinsi_id", this.state.selectedProvinceId ); 
        formData.append("kota_id", this.state.cityId ); 
        formData.append("jenjang", this.state.selectedEducationCode ); 

        const url = getLembaga + "?provinsi_id=" + this.state.selectedProvinceId + "&kota_id=" + this.state.cityId + "&jenjang=" + this.state.selectedEducationCode;
        // const response = await executePost(getLembaga, formData); 
        const response = await executeGet(url); 
        console.log("fetchLembaga = " + JSON.stringify(response));
        if(response.code == code_ok){
            if(_is_mounted){
                this.setState({
                    flagLembaga: true,
                    lembagas : response.data
                });
            }


        } else {
            //TODO: handle errors 
        }
    }

    handleSelectedOccupation = (item) => {
        this.setState({
            showOccupations: false,
            occupation: item.name,
            selectedOccupationCode: item.code,
            errorOccupation: false
        })
    }
    handleSelectedIdentity = (item) => {
        this.setState({
            errorIdentityType: false,
            selectedIdentityCode: item.short_name,
            identityType: item.name,
            showIdentityDialog: false
        })
    }
    handleEmailChange = (text) => {
        if (_is_mounted) {
            this.setState({
                email: text,
                errorEmail: false
            })
        }
    }
    handlePasswordChange = (text) => {
        if (_is_mounted) {
            this.setState({
                password: text,
                errorPassword: false
            })
        }
    }
    handleRetypeChange = (text) => {
        if (_is_mounted) {
            this.setState({
                retype: text,
                errorRetype: false
            })
        }
    }
    handleProvinceClick = (item) => {
        if (this.state.selectedProvinceId == item.id) {
            this.setState({
                selectedProvinceId: "-"
            })
        } else {
            this.setState({
                selectedProvinceId: item.id
            })
        }
    }
    setRegency = (item) => {
        this.setState({
            city: item.name,
            cityId: item.id,
            showRegencies: false,
            errorCity: false
        })
    }
    handleSubmitClick = () => {
        //== Verify ==//

        if(this.state.birthdate.length == 0) {
            this.setState({
                errorDate : true ,
                errorDateMessage : "Harap pilih tanggal lahir anda"
            },()=>{
                 this.birthRef.current.focus();
            })
            return; 
        }
        if(this.state.city.length == 0){
            this.setState({
                errorCity : true, 
                errorCityMessage : "Harap pilih kota lahir anda"
            },()=>{
                this.cityRef.current.focus();
            })
            return; 
        }
        if(this.state.selectedEducationCode.length == 0){
            this.setState({
                errorEducation : true, 
                errorEducationMessage : "Harap pilih pendidikan terakhir anda"
            },()=>{
                this.educationRef.current.focus(); 
            })
            return; 
        }
        if(this.state.lembaga.length == 0){
            this.setState({
                errorLembaga : true, 
                errorLembagaMessage : "Harap pilih Lembaga"
            },()=>{
                this.lembagaRef.current.focus();
            })
            return; 
        }
        if(this.state.selectedOccupationCode.length == 0){
            this.setState({
                errorOccupation : true, 
                errorOccupationMessage : "Harap pilih pekerjaan anda"
            },()=>{
                this.occupationRef.current.focus(); 
            })
            return;
        }
        
        //== Build Parameters ==// 
        let formData = new FormData();
        // formData.append("id_number", this.state.identityNumber ); 
        // formData.append("id_type_code", this.state.selectedIdentityCode ); 
        // formData.append("city_of_birth", this.state.city);
        // formData.append("occupation_code", this.state.selectedOccupationCode); 
        // formData.append("education_code", this.state.selectedEducationCode); 
        // formData.append("gender", this.state.gender);
        // formData.append("birthdate", this.state.birthdate);
        formData.append("tanggal_lahir", this.state.birthdate);
        formData.append("pendidikan", this.state.selectedEducationCode); 
        formData.append("pekerjaan", this.state.selectedOccupationCode); 
        formData.append("lembaga_id", this.state.selectedLembagaCode); 
        //console.log("SEND " + this.state.birthdate + ' / ' + this.state.selectedEducationCode + ' / ' + this.state.selectedOccupationCode + ' / ' + this.state.selectedLembagaCode);
        this.executeSignUp(formData);

    }

    executeSignUp = async (formData) => {
        this.setState({
            isSubmitting: true
        })
        const response = await executePost(saveLembaga, formData);
        console.log('respone lengkap = ' + JSON.stringify(response))
        if (response.code == code_ok) {
            console.log("-OK-");
            localStorage.setItem("cacheVerification", "1");     
            this.setState({
                isSubmitting: false,
                // showSuccess: true,
                // successMessage: response.msg
            })
            console.log(this.props.route.params.action);
            console.log(this.props.route.params.content_code);
            if(this.props.route.params.action == "baca") {
                //to reader
                this.handleRead();
            } else if(this.props.route.params.action == "pinjam") {
                this.props.navigation.goBack();
            } else if(this.props.route.params.action == "edukasi") {
                window.location.href = "https://edukasi.bintangpusnas.com/login?token="+ global.token
            }
        } else {
            this.setState({
                isSubmitting: false,
                alertMessage: response.msg,
                showAlert: true,
            })
        }
    }

    handleRead = async ()=>{
        let content_code = this.props.route.params.content_code;
        console.log("BACA KODE KONTEN");
        console.log(this.props.route.params);
        await this.executeReadContent(content_code); 
        // if(this.props.route.params.type_code == "TYP-0001") {
        //     await this.executeReadContent(content_code); 
        // } else {
        //     this.props.navigation.navigate("DesktopPlayer", this.props.route.params ); 
        // }
    }

    //ANCHOR - Execute Read Content
    executeReadContent = async(code)=>{
        if(_is_mounted){
            
            this.setState({
                showLoadingDialog : true,
                loadingDialogMessage : "Loading"
            });
            let url = ( this.props.route.params.localContent ? readLocalContent : readContent ); 
            console.log("Read url " + url);
            console.log("Code " + code)
            let formData = new FormData();
            formData.append("code", code);
            formData.append("kode", this.props.route.params.content_code);
            console.log(formData);
            let response = await executePost(url, formData);
            console.log("Response");
            console.log(response);
            if(response.code == code_ok){
                this.setState({
                    showLoadingDialog : false, 
                },()=>{
                    let data = response.data; 
                    data.mode = 'read';
                    data.local = this.state.localContent;
                    this.props.navigation.navigate("ReaderDesktop", data); 
                });
            } else {
                this.setState({
                    showLoadingDialog : false,
                    showAlert : true, 
                    alertMessage : response.msg
                })
            }
        }
    }

    handleWarningDialogClick = () => {
        this.setState({
            showAlert: false
        })
    }
    handleSuccessDialogClick = () => {
        this.setState({
            showSuccess: false,
        }, () => {
            this.backToLogin();
        })
    }
    render() {
        const winHeight = Dimensions.get("window").height;
        const backgroundWidth = winHeight - 144;
        const backgroundWidth2 = winHeight - 112;

        return (
            <View style={{ flex: 1, flexDirection: "row" }}>
                <View style={{ flex: 0.6, alignItems: "center", justifyContent: "center" }} >
                    <View style={{ position: "absolute", left: -16, top: 0 }}>
                        <Layer1Background
                            height={winHeight}
                        //viewBox={"0 0 " + backgroundWidth2 + " " + winHeight }
                        />
                    </View>
                    <View style={{ position: "absolute", left: -16, top: 0 }}>
                        <Layer2Background
                            height={winHeight}
                        //viewBox={"0 0 " + backgroundWidth + " " + winHeight }
                        />
                    </View>
                    {/* <View style={{ position : "absolute", left : 16 , top : 16 }}>
                        <LogoPusnas 
                            width={ 55 }
                            height={ 51 }
                        />
                    </View> */}
                    <View style={{ position: "absolute", left: 16, top: 28 }}>
                        <HeaderLogoWhite
                            width={300}
                            height={45}
                        />
                    </View>
                    <View style={{ marginRight: 128 }}>
                        <CrowdBackground
                            width={500}
                            height={421}
                        />
                    </View>
                </View>
                {
                    !this.state.isSubmitting &&
                    <View style={{ flex: 0.4, alignItems: 'center', justifyContent: "center" }}>
                        <View style={{ width: "70%" }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={24}
                                fontWeight="700"
                                sx={{ color: primary }}
                                style={{ position: 'absolute', top: 16, left: 0 }}
                            >
                                {
                                    !this.state.showRegencies &&
                                    "Kelengkapan Data"
                                }
                                {
                                    this.state.showRegencies &&
                                    "Pilih Kota"
                                }
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={16}
                                fontWeight="600"
                                sx={{ color: sub_text }}
                                style={{ position: 'absolute', top: 46, left: 0 }}
                            >
                                {
                                    !this.state.showRegencies &&
                                    "Lengkapi data dibawah ini sesuai dengan data anda"
                                }
                                {
                                    this.state.showRegencies &&
                                    "Pilih provinsi kemudian pilih kota"
                                }

                            </Typography>
                            {
                                !this.state.showRegencies &&
                                <>
                                    {/* <RadioBox
                                        caption={"Jenis Kelamin"}
                                        containerStyle={{ marginTop: 86 }}
                                        captionA={"Laki-Laki"}
                                        captionB={"Perempuan"}
                                        valueA={"L"}
                                        valueB={"P"}
                                        value={this.state.gender}
                                        onChange={this.handleRadioChange}
                                    /> */}
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <OptionBox
                                            reference={this.birthRef}
                                            editable={false}
                                            caption={"Tanggal Lahir"}
                                            usingIcon={true}
                                            placeholder={"Pilih tanggal lahir"}
                                            containerStyle={{ marginTop: 116 }}
                                            icon={<CalendarTodayIcon fontSize="small" />}
                                            onIconClick={this.handleCalendarClick}
                                            error={this.state.errorDate}
                                            errorMessage={this.state.errorDateMessage}
                                            value={this.state.formatted_birthdate}
                                        />
                                        {
                                            this.state.showDatePicker &&
                                            <StaticDatePicker
                                                displayStaticWrapperAs="desktop"
                                                inputFormat="DD/MMM/YYYY"
                                                value={this.state.formatted_birthdate}
                                                onChange={this.handleSelectedBirthdate}
                                                renderInput={(params) => <TextField {...params} />}
                                            />

                                        }
                                    </LocalizationProvider>
                                    <OptionBox
                                        reference={this.cityRef}
                                        caption={"Kota"}
                                        usingIcon={true}
                                        editable={false}
                                        placeholder={"Pilih kota"}
                                        containerStyle={{ marginTop: 16 }}
                                        value={this.state.city}
                                        icon={<ExpandMoreIcon fontSize="small" />}
                                        error={this.state.errorCity}
                                        errorMessage={this.state.errorCityMessage}
                                        onIconClick={this.handleCityClick}
                                    />
                                    <ComboBox
                                        reference={this.educationRef}
                                        caption={"Pendidikan"}
                                        usingIcon={true}
                                        editable={false}
                                        data={this.state.educations}
                                        placeholder={"Pilih pendidikan terakhir anda"}
                                        containerStyle={{ marginTop: 16 }}
                                        value={this.state.education}
                                        icon={<ExpandMoreIcon fontSize="small" />}
                                        error={this.state.errorEducation}
                                        errorMessage={this.state.errorEducationMessage}
                                        onIconClick={this.handleEducationClick}
                                        onChange={this.handleSelectedEducation}
                                    />
                                    {
                                        this.state.flagLembaga &&
                                        <ComboBox
                                            reference={ this.lembagaRef }
                                            caption={"Lembaga"} 
                                            usingIcon={ true }
                                            editable={ false }
                                            data={this.state.lembagas}
                                            placeholder={"Pilih Lembaga"} 
                                            containerStyle={{marginTop : 16 }} 
                                            value={ this.state.lembaga } 
                                            icon={<ExpandMoreIcon fontSize="small" />} 
                                            error={ this.state.errorLembaga } 
                                            errorMessage={ this.state.errorLembagaMessage } 
                                            onIconClick={ this.handleLembagaClick }
                                            onChange={this.handleSelectedLembaga}
                                        />    
                                    }
                                    <ComboBox
                                        reference={this.occupationRef}
                                        caption={"Pekerjaan"}
                                        usingIcon={true}
                                        editable={false}
                                        placeholder={"Pilih pekerjaan anda"}
                                        containerStyle={{ marginTop: 16 }}
                                        value={this.state.occupation}
                                        icon={<ExpandMoreIcon fontSize="small" />}
                                        error={this.state.errorOccupation}
                                        data={this.state.occupations}
                                        errorMessage={this.state.errorOccupationMessage}
                                        onIconClick={this.handleOccupationClick}
                                        onChange={this.handleSelectedOccupation}

                                    />
                                    <Button
                                        variant="contained"
                                        style={{ textTransform: "none", borderRadius: 8, padding: 10, marginBottom: 0, marginTop: 16 }}
                                        onClick={this.handleSubmitClick}
                                    >
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={16}
                                            fontWeight="500"
                                        >
                                            Lanjutkan
                                        </Typography>
                                    </Button>
                                    <Button
                                        variant="contained"
                                        style={{ textTransform : "none", borderRadius : 8, padding : 10, marginTop: 16  }}
                                        onClick={ this.backToLogin }
                                    >
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={ 16 }
                                            fontWeight="500"
                                        >
                                        Batal
                                        </Typography>
                                    </Button>
                                </>
                            }
                        </View>
                        {
                            this.state.showRegencies &&
                            <View style={{ flex: 1, marginTop: 86, marginLeft: 16, marginRight: 16, marginBottom: 16 }}>
                                <ScrollView>
                                <List>
                                    {
                                        this.state.regencies.map((item, index) => (
                                            <>
                                                <ListItemButton component="a" onClick={() => { this.handleProvinceClick(item) }}>
                                                    <ListItemIcon key={index}>
                                                        {
                                                            (this.state.selectedProvinceId == item.id) &&
                                                            <ExpandMoreIcon />
                                                        }
                                                        {
                                                            (this.state.selectedProvinceId != item.id) &&
                                                            <ChevronRightIcon />
                                                        }

                                                    </ListItemIcon>
                                                    <ListItemText primary={item.name} />

                                                </ListItemButton>
                                                <Collapse in={this.state.selectedProvinceId == item.id}>
                                                    <List component="div" disablePadding>
                                                        {
                                                            item.regencies.map((i, d) => (
                                                                <ListItemButton key={"child_" + d} style={{ marginLeft: 8 }} onClick={() => { this.setRegency(i) }}>
                                                                    <ListItemIcon key={index}>
                                                                        <ChevronRightIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={i.name} />
                                                                </ListItemButton>
                                                            ))
                                                        }
                                                    </List>
                                                </Collapse>
                                            </>
                                        ))
                                    }
                                </List>
                                </ScrollView>
                            </View>
                        }
                        {/* {
                            !this.state.showRegencies &&
                            <View style={{ flex: 1 }}>
                                <TextBox
                                    reference={this.nameRef}
                                    caption={"Nama Lengkap"}
                                    placeholder={"Masukkan nama lengkap"}
                                    containerStyle={{ marginTop: 16 }}
                                    error={this.state.errorName}
                                    errorMessage={this.state.errorNameMessage}
                                    onChangeText={this.handleNameChange}
                                />
                                <RadioBox 
                                        caption={"Jenis Kelamin"}
                                        containerStyle={{marginTop : 16 }}
                                        captionA={"Laki-Laki"}
                                        captionB={"Perempuan"}
                                        valueA={"L"}
                                        valueB={"P"}
                                        value={ this.state.gender }
                                        onChange={ this.handleRadioChange }
                                    />
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <OptionBox 
                                            reference={ this.birthRef }
                                            editable={ false }
                                            caption={"Tanggal Lahir"} 
                                            usingIcon={ true }
                                            placeholder={"Pilih tanggal lahir"} 
                                            containerStyle={{marginTop : 16 }}
                                            icon={ <CalendarTodayIcon fontSize="small" /> }
                                            onIconClick={ this.handleCalendarClick }
                                            error={ this.state.errorDate } 
                                            errorMessage={ this.state.errorDateMessage }
                                            value={ this.state.formatted_birthdate }
                                        />
                                        {
                                            this.state.showDatePicker && 
                                            <StaticDatePicker 
                                                displayStaticWrapperAs="desktop"
                                                inputFormat="DD/MMM/YYYY"
                                                value={ this.state.formatted_birthdate }
                                                onChange={this.handleSelectedBirthdate }
                                                renderInput={(params) => <TextField {...params} />}
                                            />

                                        }
                                    </LocalizationProvider>
                                    <OptionBox 
                                        reference={ this.cityRef }
                                        caption={"Kota Lahir"} 
                                        usingIcon={ true }
                                        editable={ false }
                                        placeholder={"Pilih kota lahir"} 
                                        containerStyle={{marginTop : 16 }}
                                        value={ this.state.city }
                                        icon={ <ExpandMoreIcon fontSize="small"/> }
                                        error={ this.state.errorCity }
                                        errorMessage={ this.state.errorCityMessage }
                                        onIconClick={ this.handleCityClick }
                                    />
                                    <TextBox
                                        reference={ this.identityRef }
                                        caption={"No. Identitas"}
                                        placeholder={"Masukkan nomor identitas anda"} 
                                        containerStyle={{marginTop : 16 }}
                                        error={ this.state.errorIdentity }
                                        errorMessage={ this.state.errorIdentityMessage }
                                        onChangeText={ this.handleIdentityChange }
                                    />
                                    <ComboBox
                                        reference={ this.identTypeRef}
                                        caption={"Jenis Identitas"} 
                                        usingIcon={ true }
                                        data={ this.state.identities }
                                        placeholder={"Pilih jenis identitas anda"} 
                                        containerStyle={{marginTop : 16 }} 
                                        editable={ false }
                                        value={ this.state.identityType } 
                                        icon={<ExpandMoreIcon fontSize="small" />} 
                                        error={ this.state.errorIdentityType } 
                                        errorMessage={ this.state.errorIdentityTypeMessage } 
                                        onIconClick={ this.handleIdentityTypeClick }
                                        onChange={this.handleSelectedIdentity }
                                        
                                    />
                                    <ComboBox
                                        reference={ this.educationRef }
                                        caption={"Pendidikan"} 
                                        usingIcon={ true }
                                        editable={ false }
                                        data={ this.state.educations }
                                        placeholder={"Pilih pendidikan terakhir anda"} 
                                        containerStyle={{marginTop : 16 }} 
                                        value={ this.state.education } 
                                        icon={<ExpandMoreIcon fontSize="small" />} 
                                        error={ this.state.errorEducation } 
                                        errorMessage={ this.state.errorEducationMessage } 
                                        onIconClick={ this.handleEducationClick }
                                        onChange={this.handleSelectedEducation }
                                    />
                                    <ComboBox
                                        reference={ this.occupationRef }
                                        caption={"Pekerjaan"} 
                                        usingIcon={ true }
                                        editable={ false }
                                        placeholder={"Pilih pekerjaan anda"} 
                                        containerStyle={{marginTop : 16 }} 
                                        value={ this.state.occupation } 
                                        icon={<ExpandMoreIcon fontSize="small" />} 
                                        error={ this.state.errorOccupation } 
                                        data={ this.state.occupations }
                                        errorMessage={ this.state.errorOccupationMessage } 
                                        onIconClick={ this.handleOccupationClick }
                                        onChange={this.handleSelectedOccupation }

                                    />
                                <TextBox
                                    reference={this.emailRef}
                                    caption={"Email"}
                                    placeholder={"Masukkan email anda"}
                                    containerStyle={{ marginTop: 16 }}
                                    error={this.state.errorEmail}
                                    errorMessage={this.state.errorEmailMessage}
                                    onChangeText={this.handleEmailChange}
                                />
                                <PasswordBox
                                    reference={this.passwordRef}
                                    caption={"Buat Password"}
                                    containerStyle={{ marginTop: 16 }}
                                    placeholder={"Min. 8-karakter alfanumerik"}
                                    error={this.state.errorPassword}
                                    errorMessage={this.state.errorPasswordMessage}
                                    onChangeText={this.handlePasswordChange}
                                />
                                <PasswordBox
                                    reference={this.retypeRef}
                                    caption={"Ketik ulang Password"}
                                    containerStyle={{ marginTop: 16, marginBottom: 16 }}
                                    placeholder={"Ketik ulang password diatas"}
                                    error={this.state.errorRetype}
                                    errorMessage={this.state.errorRetypeMessage}
                                    onChangeText={this.handleRetypeChange}
                                />
                                <Button
                                    variant="contained"
                                    style={{ textTransform: "none", borderRadius: 8, padding: 10, marginBottom: 0 }}
                                    onClick={this.handleSubmitClick}
                                >
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={16}
                                        fontWeight="500"
                                    >
                                        Daftar
                                        </Typography>
                                </Button>
                                <View style={{ flexDirection: "row", marginTop: 24, marginBottom: 16, alignItems: "center", justifyContent: "center" }}>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={14}
                                    >
                                        Sudah memiliki akun?
                                        </Typography>
                                    <Pressable
                                        onPress={() => { this.backToLogin() }}
                                    >
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={14}
                                            fontWeight="600"
                                            style={{ marginLeft: 8 }}
                                            color={primary}
                                        >
                                            Klik disini
                                            </Typography>
                                    </Pressable>
                                </View>
                            </View>
                        }
                        {
                            this.state.showRegencies &&
                            <View style={{ flex: 1, marginTop: 16, marginLeft: 16, marginRight: 16, marginBottom: 16 }}>
                                <List>
                                    {
                                        this.state.regencies.map((item, index) => (
                                            <>
                                                <ListItemButton component="a" onClick={() => { this.handleProvinceClick(item) }}>
                                                    <ListItemIcon key={index}>
                                                        {
                                                            (this.state.selectedProvinceId == item.id) &&
                                                            <ExpandMoreIcon />
                                                        }
                                                        {
                                                            (this.state.selectedProvinceId != item.id) &&
                                                            <ChevronRightIcon />
                                                        }

                                                    </ListItemIcon>
                                                    <ListItemText primary={item.name} />

                                                </ListItemButton>
                                                <Collapse in={this.state.selectedProvinceId == item.id}>
                                                    <List component="div" disablePadding>
                                                        {
                                                            item.regencies.map((i, d) => (
                                                                <ListItemButton key={"child_" + d} style={{ marginLeft: 8 }} onClick={() => { this.setRegency(i) }}>
                                                                    <ListItemIcon key={index}>
                                                                        <ChevronRightIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={i.name} />
                                                                </ListItemButton>
                                                            ))
                                                        }
                                                    </List>
                                                </Collapse>
                                            </>
                                        ))
                                    }
                                </List>
                            </View>
                        } */}
                    </View>
                }
                <LoadingDialog
                    open={this.state.isSubmitting}
                    message={"Mendaftarkan akun anda"}
                />
                <WarningDialog
                    open={this.state.showAlert}
                    message={this.state.alertMessage}
                    caption={"OK"}
                    onClick={this.handleWarningDialogClick}
                />
                <SuccessDialog
                    open={this.state.showSuccess}
                    message={this.state.successMessage}
                    caption={"OK"}
                    onClick={this.handleSuccessDialogClick}
                />

            </View>
        )
    }
}


export default VerificationUserDesktop;