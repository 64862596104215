import React, { Component} from 'react'; 
import { StyleSheet, View, Image } from 'react-native'; 
import { Typography, LinearProgress } from '@mui/material'; 
import AlertLogo from '../assets/images/AlertLogo';
import { AlertPlaceholder } from '../assets/Placeholders';


class AlertPage extends Component {
    constructor(props){
        super(props)
    }

    render(){
        return(
            <View style={ styles.container }>
                <AlertPlaceholder />
                <Typography
                    fontFamily="Open Sans" 
                    fontSize={16} 
                    fontWeight="600"
                >
                    { this.props.title }
                </Typography>

                <Typography
                    fontFamily="Open Sans"
                    fontSize={14}
                    fontWeight="500"
                    style={{ marginLeft : 16, marginRight : 16 }}
                >
                    { this.props.message }
                </Typography>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container : {
        flex : 1, 
        backgroundColor : "white", 
        alignItems : "center",
        justifyContent : "center"
    }
});

export default AlertPage; 
