import React, { Component } from 'react';
import { StyleSheet, View, Text, FlatList, Image, Dimensions, Pressable, ScrollView, PixelRatio } from 'react-native';
import { code_ok, code_error, code_auth_error, code_empty, homepageBanner } from '../constants/api';
import { Skeleton, Typography, Button } from '@mui/material';
import { executeGet, executePost } from '../utilities/Utilities';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import * as Command from '../constants/Commands';
import { color_text, sub_text, primary } from '../constants/colors';

var window_width = Dimensions.get("window").width - 32;

class Intro extends Component {
    constructor(props) {
        // const [sliderState, setSliderState] = useState({ currentPage: 0 });
        super(props)
        this.state = {
            showLoading: false,
            data: [],
            code: this.props.code,
            // randomNumber: 3
            randomNumber: Math.floor(Math.random() * (3 - 1 + 1)) + 1,
            sliderState: { currentPage: 0 }
        }
        this.fetchBanners = this.fetchBanners.bind(this);
        this.handleLoginClick = this.handleLoginClick.bind(this);
        this.openURL = this.openURL.bind(this);
    }

    componentDidMount = () => {
        this.fetchBanners();
        console.log('PARAMM SEBELUMNYA = ' + JSON.stringify(this.props.route.params))
        console.log('PARAMM PAGE = ' + JSON.stringify(this.props.route.pageTo))
        console.log('RANDOM NUMBER = ' + this.state.randomNumber)
        // setTimeout(() => {
        //     if (this.props.route.pageTo == 'Main') {
        //         this.props.navigation.reset({
        //             index: 0,
        //             routes: [{ name: 'Main', params: this.props.route.params }],
        //         });
        //     } else {
        //         this.props.navigation.reset({
        //             index: 0,
        //             routes: [{ name: 'Login', params: this.props.route.params }],
        //         });
        //     }

        // }, 10000);
    }

    handleLoginClick = () => {
        if (this.props.route.pageTo == 'Main') {
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'Main', params: this.props.route.params }],
            });
        } else {
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'Login', params: this.props.route.params }],
            });
        }

    }

    fetchBanners = async () => {
        let formData = new FormData();
        formData.append("homepage_code", this.state.code);

        const response = await executePost(homepageBanner, formData);
        console.log('fetchBanners = ' + JSON.stringify(response))
        if (response.code == code_ok) {
            this.setState({
                showLoading: false,
                data: response.data
            })
        } else {
            if (response.code != code_auth_error) {
                this.setState({
                    showLoading: false,
                    showAlert: true
                })
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, response.msg );
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : response.msg })
                // }
            } else {
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            }
        }
    }

    openURL = (item) => {
        //console.log(JSON.stringify(item)); 
        if (item.url.includes("http://") || item.url.includes("https://")) {
            //window.electron.sendMessage(Command.OPEN_URL, item.url);
            window.open(item.url);
        }

    }
    render() {
        const { width, height } = Dimensions.get('window');

        const setSliderPage = (event) => {
            const { currentPage } = this.state.sliderState;
            const { x } = event.nativeEvent.contentOffset;
            const indexOfNextScreen = Math.floor(x / width);
            if (indexOfNextScreen !== currentPage) {
                this.setState({
                    sliderState: {
                        currentPage: indexOfNextScreen,
                    }
                })
            }
            console.log("silde ke = " + this.state.sliderState.currentPage)

        };

        const { currentPage: pageIndex } = this.state.sliderState;

        return (
            <View style={{ width: "100%", height: '100%' }}>
                {
                    this.state.showLoading &&
                    <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height={120}
                        style={{ marginLeft: 16, marginRight: 16, borderRadius: 8 }}
                    />
                }
                {
                    !this.state.showLoading &&
                    <>
                        <View style={{ flex: 1, padding: 17, backgroundColor: 'white' }}>
                            <ScrollView
                                style={{ flex: 1 }}
                                horizontal={true}
                                scrollEventThrottle={16}
                                pagingEnabled={true}
                                showsHorizontalScrollIndicator={false}
                                onScroll={(event) => {
                                    setSliderPage(event);
                                }}
                            >
                                <View style={{ width, height, textAlign: 'center', alignItems: 'center', marginTop: 10 }}>
                                    {
                                        width > '430' ?
                                            <Image
                                                source={{ uri: 'https://bintangpusnas.perpusnas.go.id/images/icon/intro3.png' }}
                                                style={{
                                                    top: '50px',
                                                    paddingTop: '200px',
                                                    height: '100%',
                                                    width: '70%',
                                                    maxHeight: 'calc(100vh - 260px)',
                                                    marginRight: 5
                                                }}
                                            /> :
                                            <Image
                                                source={{ uri: 'https://bintangpusnas.perpusnas.go.id/images/icon/intro3.png' }}
                                                style={styles.imageStyle3}
                                            />
                                    }

                                </View>
                                <View style={{ width, height, textAlign: 'center', alignItems: 'center', marginTop: 50 }}>
                                    <Image
                                        source={{ uri: 'https://bintangpusnas.perpusnas.go.id/images/icon/intro1.png' }}
                                        style={styles.imageStyle1} />
                                    <View style={{ textAlign: 'left' }}>
                                        {/* <Text style={styles.header}>Nature Imitates Art</Text>
                                        <Text style={styles.paragraph}>....something like that</Text> */}
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={20}
                                            color={"#3067B6"}
                                            fontWeight={"700"}
                                            style={{
                                                marginTop: '46px',
                                                textAlign: 'center'
                                            }}
                                        >
                                            Literasi Adalah {width}
                                        </Typography>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={16}
                                            color={color_text}
                                            fontWeight={"400"}
                                            style={{
                                                marginTop: '22px',
                                                paddingLeft: 10,
                                                paddingRight: 35,
                                                textAlign: 'center'
                                            }}
                                        >
                                            Kedalaman pengetahuan seseorang terhadap suatu subjek ilmu pengetahuan tertentu yang dapat diimplementasikan dengan inovasi dan kreativitas untuk memproduksi barang dan jasa yang berkualitas tinggi dan dapat dipakai untuk memenangkan persaingan global.
                                        </Typography>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={16}
                                            color={color_text}
                                            fontWeight={"400"}
                                            style={{
                                                marginTop: '10px',
                                                paddingLeft: 10,
                                                paddingRight: 35,
                                                textAlign: 'center'
                                            }}
                                        >
                                            Jadi, literasi bukan hanya sekadar pandai baca tulis.
                                        </Typography>
                                    </View>
                                </View>
                                <View style={{ width, height, textAlign: 'center', alignItems: 'center', marginTop: 50 }}>
                                    <Image
                                        source={{ uri: 'https://bintangpusnas.perpusnas.go.id/images/icon/intro2.png' }}
                                        style={styles.imageStyle2}
                                    />
                                    <View style={styles.wrapper}>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={16}
                                            color={color_text}
                                            fontWeight={"400"}
                                            style={{
                                                marginTop: '25px',
                                                paddingLeft: 5,
                                                paddingRight: 35
                                            }}
                                        >
                                            Menyongsong dengan gegap gempita satu Januari sebagai tahun baru setiap tahun tidak akan membawa hasil signifikan jika tidak diawali dengan pengetahuan baru, skil baru, keterampilan baru, mindset yang baru.
                                        </Typography>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={16}
                                            color={color_text}
                                            fontWeight={"400"}
                                            style={{
                                                marginTop: '15px',
                                                paddingLeft: 5,
                                                paddingRight: 35
                                            }}
                                        >
                                            Sebab kegagalan manusia terbesar adalah selalu ingin mendapatkan hasil yang lebih baik di masa mendatang dengan pola kerja yang lama dan secara nyata telah membuat dia gagal bertahun tahun lamanya.
                                        </Typography>
                                    </View>
                                </View>
                                <View style={{ width, height, textAlign: 'center', alignItems: 'center', marginTop: 50 }}>
                                    <Image
                                        source={{ uri: 'https://bintangpusnas.perpusnas.go.id/images/icon/intro4.png' }}
                                        style={styles.imageStyle}
                                    />
                                    <View style={{ textAlign: 'left' }}>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={18}
                                            color={"#232D42"}
                                            fontWeight={"700"}
                                            style={{ paddingLeft: 20, paddingTop: 10, paddingBottom: 10 }}
                                        >PERINTAH PRESIDEN RI UNTUK TRANSFORMASI DIGITAL:
                                        </Typography>
                                        <Text style={styles.paragraph}>
                                            1. Menguasai ilmu pengetahuan dan teknologi terkini {"\n"}
                                            2. Peningkatan inovasi dan kreativitas {"\n"}
                                            3. Kemampuan menciptakan lapangan pekerjaan {"\n"}
                                            4. Kemampuan menyerap tenaga kerja dan mengurangi pengangguran {"\n"}
                                            5. Meningkatkan income per kapita {"\n"}
                                            6. Menambah devisa negara {"\n"}
                                            7. Terwujudnya masyarakat adil dan makmur {"\n"}
                                            8. Terwujudnya masyarakat Indonesia yang tangguh
                                        </Text>
                                    </View>
                                </View>
                                <View style={{ width, height, textAlign: 'center', alignItems: 'center', marginTop: 10 }}>
                                    {
                                        width > '430' ?
                                            <Image
                                                source={{ uri: 'https://bintangpusnas.perpusnas.go.id/images/icon/intro5.jpeg' }}
                                                style={{
                                                    paddingTop: '10px',
                                                    width: '70%',
                                                    height: '80%',
                                                    marginRight: 5
                                                }}
                                            /> :
                                            <Image
                                                source={{ uri: 'https://bintangpusnas.perpusnas.go.id/images/icon/intro5.jpeg' }}
                                                style={styles.imageStyle3}
                                            />
                                    }
                                </View>

                            </ScrollView>
                            <View style={styles.paginationWrapper}>
                                {Array.from(Array(5).keys()).map((key, index) => (
                                    <View style={[styles.paginationDots, { opacity: pageIndex === index ? 1 : 0.2 }]} key={index} onClick={(event) => {
                                        setSliderPage(event);
                                    }} />
                                ))}
                            </View>
                            {
                                this.state.sliderState.currentPage == '4' &&
                                <View style={styles.paginationWrapper2}>
                                    <Button
                                        variant="contained"
                                        style={{ textTransform: "none", borderRadius: 8, paddingTop: 10, paddingBottom: 10, paddingLeft: 30, paddingRight: 30, width: 270 }}
                                        onClick={this.handleLoginClick}
                                    >
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={16}
                                            fontWeight="500"
                                        >
                                            Masuk
                                        </Typography>
                                    </Button>
                                </View>
                            }

                        </View>
                    </>
                }
            </View>
        )
    }


}

const styles = StyleSheet.create({
    introContainer: {
        padding: 17,
        flex: 1,
        textAlign: 'center',
        alignItems: 'center',
        backgroundColor: 'white'
    },
    introContainerSecond: {
        flex: 1,
        backgroundColor: 'white'
    },
    backgroundImage: {
        flex: 1,
        resizeMode: 'contain', // or 'stretch',
        justifyContent: 'center',
    },
    bannerItem: {
        borderRadius: 12,
        borderWidth: 0,
        marginLeft: 4,
        marginRight: 4,
        overflow: "hidden"
    },

    imageStyle1: {
        width: '300px',
        height: '210px',
        paddingTop: 20,
        marginRight: 30
    },
    imageStyle2: {
        // height: PixelRatio.getPixelSizeForLayoutSize(135),
        // width: '100%',
        width: '250px',
        height: '260px',
    },
    imageStyle: {
        // height: PixelRatio.getPixelSizeForLayoutSize(135),
        // width: '100%',
        width: '250px',
        height: '260px',
    },
    imageStyle3: {

        top: '50px',
        paddingTop: '200px',
        height: '100%',
        width: '110%',
        maxHeight: 'calc(100vh - 260px)',
        marginRight: 5
    },
    wrapper: {
        justifyContent: 'center',
        alignItems: 'center',
        // marginVertical: 30,
    },
    header: {
        fontSize: 30,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    paragraph: {
        fontSize: 17,
        paddingLeft: 20,
        paddingRight: 20
    },
    paginationWrapper: {
        position: 'absolute',
        bottom: 100,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
    paginationWrapper2: {
        position: 'absolute',
        bottom: 25,
        left: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
    paginationDots: {
        height: 10,
        width: 10,
        borderRadius: 10 / 2,
        backgroundColor: '#0898A0',
        marginLeft: 10,
    },
})

export default Intro;