const code_ok = 200; 
const code_empty = 400; 
const code_error = 500; 
const code_auth_error = 505; 
const base_url = "https://bintangpusnas.perpusnas.go.id/api/";
const appConfig = base_url + "appConfig"; 
const regencies = base_url + "regencies";
const userRegistration = base_url + "userRegistration"; 
const userSignIn = base_url + "userSignIn"; 
const relogin = base_url +"relogin";
const forgotPasswordRequest = base_url + "forgotPasswordRequest"; 
const homepage = base_url + "homepage"; 
const homepageBanner = base_url + "homepageBanner"; 
const homepageContentType = base_url + "homepageContentType"; 
const homepageCategories = base_url + "homepageCategories"; 
const homepageContent = base_url + "homepageContent"; 
const homepageAuthor = base_url + "homepageAuthor";  
const homepageRecommended = base_url + "homepageRecommended";
const homepageFavorite = base_url + "homepageFavorite";
const homepageNews = base_url + "homepageNews";
const searching = base_url + "bookSearch";
const notificationList = base_url + "notificationList"; 
const updateNotificationStatus = base_url + "updateNotificationStatus"; 
const notificationCheck = base_url + "notificationCheck";
const newsList = base_url + "newsList";
const borrowingList = base_url + "borrowingList"; 
const bookReturn = base_url + "bookReturn"; 
const profile = base_url + "profile";
const updatePassword = base_url + "updatePassword";
const updateProfile = base_url + "updateProfile";
const readingHistory = base_url + "readingHistory";
const contentTypeList = base_url + "contentTypeList"; 
const categoryContent = base_url + "categoryContent";
const sectionContent = base_url + "sectionContent"; 
const sectionContentRecommended = base_url + "sectionContentRecommended"; 
const eduOffline = base_url + "eduOffline"; 
const userSignOut = base_url + "userSignOut"; 
const userDelete = base_url + "userDelete"; 
const categories = base_url + "categories"; 
const contentDetail = base_url + "contentDetail";
const stockCheck = base_url + "stockCheck";
const contentRate = base_url + "contentRate/";
const allowInputRate = base_url + "allowInputRate/";
const commentList = base_url + "commentList";
const updateFBToken = base_url + "updateFBToken";
const borrow = base_url + "borrow"; 
const queue = base_url + "queue"; 
const sendComment = base_url + "sendComment";
const sendRate = base_url + "sendRate"; 
const updateAvatar = base_url + "updateAvatar"; 
const recommendedContent = base_url + "recommendedContent";
const readContent = base_url + "readContent";
const getContent = base_url + "getContent/";
const closeBook = base_url + "closeBook";
const readBorrowedContent = base_url + "readBorrowedContent"; 
const autoSignIn = base_url + "autoSignIn";
const readLocalContent = base_url + "readLocalContent";
const checkLembaga = base_url + "cekLembaga";
const getLembaga = base_url + "getLembaga";
const saveLembaga = base_url + "saveLembaga";
const checkJWT = base_url + "checkJWT";
const statistikKoleksi = base_url + "statistik-koleksi";
const refreshToken = base_url + "refresh-token";
const widgetaudio = base_url + "widgetaudio";
const widgetTutorialEdukasi = base_url + "getVideoTutorial";
const urlMusik = base_url + "getMusik";
const urlDetailMusik = base_url + "getDetailMusik";

export { code_ok, code_empty, code_auth_error, appConfig, regencies, userRegistration, userSignIn, relogin, forgotPasswordRequest, homepage, homepageBanner, 
homepageContentType, homepageCategories, homepageContent, homepageAuthor, homepageRecommended, homepageFavorite,
homepageNews, searching, notificationList, updateNotificationStatus, notificationCheck, newsList, 
borrowingList , bookReturn, profile, updatePassword, updateProfile, readingHistory, contentTypeList, 
categoryContent, sectionContent, sectionContentRecommended, eduOffline, userSignOut, userDelete, categories, contentDetail, stockCheck, 
contentRate, allowInputRate, commentList, updateFBToken, borrow, sendComment, sendRate, updateAvatar, 
recommendedContent, readContent, getContent, closeBook, readBorrowedContent, autoSignIn, readLocalContent, 
checkLembaga, getLembaga,urlMusik, urlDetailMusik, saveLembaga, checkJWT, statistikKoleksi, refreshToken, queue, widgetaudio, widgetTutorialEdukasi }



