import React, { Component } from 'react'; 
import { StyleSheet, View, FlatList, Image, Dimensions, Pressable } from 'react-native'; 
import { code_ok, code_error, code_auth_error, code_empty , homepageBanner } from '../constants/api';
import { Skeleton, Typography } from '@mui/material'; 
import { executeGet, executePost, truncateText } from '../utilities/Utilities';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import * as Command from '../constants/Commands';
import { color_text, sub_text, primary } from '../constants/colors';

var window_width = Dimensions.get("window").width - 32;

class DesktopHomepageBanners extends Component {
    constructor(props){
        super(props)
        this.state = {
            showLoading : true, 
            data : [],
            code : this.props.code 
        }
        this.fetchBanners = this.fetchBanners.bind(this); 
        
        this.openURL = this.openURL.bind(this); 
    }

    componentDidMount = ()=>{
        this.fetchBanners();
    }   

    fetchBanners = async()=>{
        let formData = new FormData(); 
        formData.append("homepage_code", this.state.code); 

        const response = await executePost(homepageBanner, formData); 
        if(response.code == code_ok){
            this.setState({
                showLoading : false, 
                data : response.data 
            })
        } else {
            if(response.code != code_auth_error){
                this.setState({
                    showLoading : false, 
                    showAlert : true
                })
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, response.msg );
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : response.msg })
                // }
            } else {
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out"});
            }
        }
    }
    
    openURL = (item)=>{
        //console.log(JSON.stringify(item)); 
        if(item.url.includes("http://") || item.url.includes("https://")){
           //window.electron.sendMessage(Command.OPEN_URL, item.url);
           window.open(item.url);
        }
        
    }
    render(){
        return(
            <View style={{width : "100%", paddingLeft: 147, paddingRight: 147}}>
                {
                    this.state.showLoading && 
                    <Skeleton 
                        animation="wave"
                        variant="rectangular"
                        height={ 120 }
                        style={{marginLeft : 16, marginRight : 16, borderRadius : 8 }}
                    />
                }
                {
                    !this.state.showLoading && 
                    <View style={{ marginTop: 30 }}>
                        <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 20 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={28}
                                variant="h3"
                                style={{ flex: 1, marginLeft: 10 }}
                                color={color_text}
                                fontWeight={"600"}
                            >
                                {truncateText('Promosi & Informasi', 30)}
                            </Typography>
                        </View>
                        <View style={ styles.bannerContainer }>
                            <Carousel
                                showThumbs={false}
                                showStatus={false}
                            >
                                {
                                    this.state.data.map((item, index)=>(
                                        <Pressable key={"banner_"+index} style={ styles.bannerItem } 
                                            onPress={()=>{ this.openURL(item)}}
                                        >
                                            <img src={ item.image } />
                                        </Pressable>
                                    ))
                                }
                            </Carousel> 
                        </View>
                    </View>
                    
                }
            </View>
        )
    }


}

const styles = StyleSheet.create({
    bannerContainer : {
        marginLeft : 20, 
        marginRight : 20
    },
    bannerItem : {
        borderRadius : 12, 
        borderWidth : 0,
        marginLeft : 4, 
        marginRight : 4, 
        overflow : "hidden"
    }
})

export default DesktopHomepageBanners;