import React, { Component } from 'react';
import { StyleSheet, Image, View, Linking, Dimensions, Pressable, ScrollView, TextInput } from 'react-native';
import {
    Alert, AlertTitle, CircularProgress, LinearProgress, Typography, Button, List, ListItemButton,
    ListItemText, ListItemIcon, Collapse, AutoComplete, TextField
} from '@mui/material';
import { code_ok, code_empty, code_error, code_auth_error, regencies, userRegistration } from '../constants/api';
// import { Layer1Background, Layer2Background, WordLogo, CrowdBackground } from '../assets/Backgrounds';
import { Layer1Background, Layer2Background, WordLogo, CrowdBackground } from '../assets/Backgrounds';
import { LogoPusnas } from '../assets/Icons';
import { primary, color_text, textbox, danger, sub_text } from '../constants/colors';
import { executeGet, executePost } from '../utilities/Utilities';
import TextBox from '../components/TextBox';
import PasswordBox from '../components/PasswordBox';
import RadioBox from '../components/RadioBox';
import OptionBox from '../components/OptionBox';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as Identifier from "../constants/PageIdentifiers";
import * as Command from "../constants/Commands";
import RegenciesDialog from '../components/RegenciesDialog';
import EducationDialog from '../components/EducationDialog';
import OccupationDialog from '../components/OccupationDialog';
import IdentityDialog from '../components/IdentityDialog';
import ComboBox from '../components/ComboBox';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import moment from 'moment';
import LoadingDialog from '../components/LoadingDialog';
import WarningDialog from '../components/WarningDialog';
import SuccessDialog from '../components/SuccessDialog';
import HeaderLogo from '../assets/HeaderLogo';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/700.css';
import HeaderLogoWhite from '../assets/HeaderLogoWhite';

var _is_mounted = false;

class SignUpDesktop extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            email: "",
            city: "",
            birthdate: "",
            formatted_birthdate: "",
            password: "",
            retype: "",
            gender: "L",
            occupation: "",
            education: "",
            educations: [],
            occupations: [],
            identities: [],
            identityNumber: "",
            identityType: "",
            errorIdentityType: false,
            errorIdentityTypeMessage: "",
            selectedIdentityCode: "",
            showIdentity: false,
            errorName: false,
            errorNameMessage: "Masukkan nama lengkap anda",
            errorDate: false,
            errorDateMessage: "Pilihlah tanggal lahir anda",
            errorEmail: false,
            errorEmailMessage: "Masukkan e-mail anda dengan format yang benar",
            errorCity: false,
            errorCityMessage: "Masukkan kota lahir anda",
            errorEducation: false,
            errorEducationMessage: "",
            errorOccupation: false,
            errorOccupationMessage: "",
            errorPassword: false,
            errorPasswordMessage: "Minimal 8-karakter alfanumerik",
            errorRetype: false,
            errorRetypeMessage: "Ketik ulang password anda",
            errorIdentity: false,
            errorIdentityMessage: "",
            regencies: [],
            showRegencies: false,
            selectedProvinceId: "-",
            showEducations: false,
            selectedEducationCode: "",
            showOccupations: false,
            selectedOccupationCode: "",
            showIdentityDialog: false,
            isSubmitting: false,
            showAlert: false,
            alertMessage: "",
            showSuccess: false,
            successMessage: "",
            showDatePicker: false

        }

        //== Refs ==//
        this.nameRef = React.createRef();
        this.birthRef = React.createRef();
        this.cityRef = React.createRef();
        this.identityRef = React.createRef();
        this.identTypeRef = React.createRef();
        this.educationRef = React.createRef();
        this.occupationRef = React.createRef();
        this.emailRef = React.createRef();
        this.passwordRef = React.createRef();
        this.retypeRef = React.createRef();

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.handleCalendarClick = this.handleCalendarClick.bind(this);
        this.handleSelectedBirthdate = this.handleSelectedBirthdate.bind(this);
        this.handleCityClick = this.handleCityClick.bind(this);
        this.handleIdentityChange = this.handleIdentityChange.bind(this);
        this.handleIdentityTypeClick = this.handleIdentityTypeClick.bind(this);
        this.handleEducationClick = this.handleEducationClick.bind(this);
        this.handleOccupationClick = this.handleOccupationClick.bind(this);
        this.handleSelectedEducation = this.handleSelectedEducation.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleRetypeChange = this.handleRetypeChange.bind(this);
        this.handleSubmitClick = this.handleSubmitClick.bind(this);
        this.handleProvinceClick = this.handleProvinceClick.bind(this);
        this.setRegency = this.setRegency.bind(this);

        this.executeSignUp = this.executeSignUp.bind(this);
        this.backToLogin = this.backToLogin.bind(this);
        this.fetchRegencies = this.fetchRegencies.bind(this);

        this.handleWarningDialogClick = this.handleWarningDialogClick.bind(this);
        this.handleSuccessDialogClick = this.handleSuccessDialogClick.bind(this);

    }

    componentDidMount = () => {
        _is_mounted = true;
        this.fetchRegencies();
        let default_birthdate = moment().subtract(7, 'year').format("DD/MMM/YYYY");

        this.setState({
            formatted_birthdate: "Pilih Tanggal Lahir Anda",
            // educations: [{ code: "", name: "-- Pilih pendidikan terakhir anda --" }, ...this.props.route.params.educations],
            // occupations: [{ code: "", name: "-- Pilih pekerjaan anda --" }, ...this.props.route.params.occupations],
            // identities: [{ code: "", name: "-- Pilih jenis identitas anda" }, ...this.props.route.params.identities]
        })
    }
    componentWillUnmount = () => {
        _is_mounted = false;

    }
    fetchRegencies = async () => {
        const response = await executeGet(regencies);
        if (response.code == code_ok) {
            if (_is_mounted) {
                this.setState({
                    regencies: response.data
                });
            }
        } else {
            //TODO: handle errors 
        }
    }

    backToLogin = () => {
        this.props.navigation.goBack();
    }

    handleNameChange = (text) => {
        if (_is_mounted) {
            this.setState({
                name: text,
                errorName: false
            })
        }
    }
    handleRadioChange = (event) => {
        if (_is_mounted) {
            this.setState({
                gender: event.target.value
            })
        }
    }
    handleCalendarClick = () => {
        if (_is_mounted) {
            if (!this.state.showDatePicker) {
                this.setState({
                    showDatePicker: true
                })
            } else {
                this.setState({
                    showDatePicker: false
                })
            }
        }
    }

    handleSelectedBirthdate = (value) => {
        //console.log(moment(value).format("DD/MMM/YYYY"));
        this.setState({
            formatted_birthdate: moment(value).format("DD/MMM/YYYY"),
            birthdate: moment(value).format("YYYY-MM-DD"),
            errorDate: false
        })
        // this.setState({
        //     showDatePicker : false
        // })

    }

    handleCityClick = () => {
        if (_is_mounted) {
            if (this.state.regencies.length > 0) {
                this.setState({
                    showRegencies: true
                })
            }
        }
    }
    handleIdentityChange = (text) => {
        if (_is_mounted) {
            this.setState({
                identityNumber: text,
                errorIdentity: false
            })
        }
    }
    handleIdentityTypeClick = () => {
        this.setState({
            showIdentityDialog: true
        })
    }
    handleEducationClick = () => {
        if (_is_mounted) {
            this.setState({
                showEducations: true
            })
        }
    }
    handleOccupationClick = () => {
        if (_is_mounted) {
            this.setState({
                showOccupations: true
            })
        }
    }


    handleSelectedEducation = (item) => {
        this.setState({
            showEducations: false,
            education: item.name,
            selectedEducationCode: item.code,
            errorEducation: false
        })
    }

    handleSelectedOccupation = (item) => {
        this.setState({
            showOccupations: false,
            occupation: item.name,
            selectedOccupationCode: item.code,
            errorOccupation: false
        })
    }
    handleSelectedIdentity = (item) => {
        this.setState({
            errorIdentityType: false,
            selectedIdentityCode: item.short_name,
            identityType: item.name,
            showIdentityDialog: false
        })
    }
    handleEmailChange = (text) => {
        if (_is_mounted) {
            this.setState({
                email: text,
                errorEmail: false
            })
        }
    }
    handlePasswordChange = (text) => {
        if (_is_mounted) {
            this.setState({
                password: text,
                errorPassword: false
            })
        }
    }
    handleRetypeChange = (text) => {
        if (_is_mounted) {
            this.setState({
                retype: text,
                errorRetype: false
            })
        }
    }
    handleProvinceClick = (item) => {
        if (this.state.selectedProvinceId == item.id) {
            this.setState({
                selectedProvinceId: "-"
            })
        } else {
            this.setState({
                selectedProvinceId: item.id
            })
        }
    }
    setRegency = (item) => {
        this.setState({
            city: item.name,
            showRegencies: false,
            errorCity: false
        })
    }
    handleSubmitClick = () => {
        //== Verify ==//
        if (this.state.name.length < 3) {
            this.setState({
                errorName: true,
                errorNameMessage: "Harap masukkan nama lengkap anda"
            }, () => {
                this.nameRef.current.focus();
            })
            return;
        }

        // if(this.state.birthdate.length == 0) {
        //     this.setState({
        //         errorDate : true ,
        //         errorDateMessage : "Harap pilih tanggal lahir anda"
        //     },()=>{
        //          this.birthRef.current.focus();
        //     })
        //     return; 
        // }
        // if(this.state.city.length == 0){
        //     this.setState({
        //         errorCity : true, 
        //         errorCityMessage : "Harap pilih kota lahir anda"
        //     },()=>{
        //         this.cityRef.current.focus();
        //     })
        //     return; 
        // }
        // if(this.state.identityNumber.length == 0){
        //     this.setState({
        //         errorIdentity : true, 
        //         errorIdentityMessage : "Harap masukkan nomor identitas anda"
        //     },()=>{
        //         this.identityRef.current.focus();
        //     })
        //     return; 
        // }
        // if(this.state.identityType.length == 0){
        //     this.setState({
        //         errorIdentityType : true, 
        //         errorIdentityTypeMessage : "Harap pilih jenis identitas anda"
        //     },()=>{
        //         this.identTypeRef.current.focus(); 
        //     })
        //     return; 
        // }
        // if(this.state.selectedEducationCode.length == 0){
        //     this.setState({
        //         errorEducation : true, 
        //         errorEducationMessage : "Harap pilih pendidikan terakhir anda"
        //     },()=>{
        //         this.educationRef.current.focus(); 
        //     })
        //     return; 
        // }
        // if(this.state.selectedOccupationCode.length == 0){
        //     this.setState({
        //         errorOccupation : true, 
        //         errorOccupationMessage : "Harap pilih pekerjaan anda"
        //     },()=>{
        //         this.occupationRef.current.focus(); 
        //     })
        //     return;
        // }
        if (this.state.email.length == 0) {
            this.setState({
                errorEmail: true,
                errorEmailMessage: "Harap masukkan email anda"
            }, () => {
                this.emailRef.current.focus();
            })
            return;
        }
        let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (!regEmail.test(this.state.email)) {
            this.setState({
                errorEmail: true,
                errorEmailMessage: "E-mail wajib diisi dengan format yang benar"
            }, () => {
                this.emailRef.current.focus();
            });
            return;
        }
        let regPass = /^(?=.*\d).{8,}$/;
        if (!regPass.test(this.state.password)) {
            this.setState({
                errorPassword: true,
                errorPasswordMessage: 'Kata sandi minimal 8-karakter kombinasi huruf angka'
            }, () => {
                this.passwordRef.current.focus();
            });
            return;
        }
        if (this.state.retype != this.state.password) {
            this.setState({
                errorRetype: true,
                errorRetypeMessage: "Harap ketik ulang password anda"
            }, () => {
                this.retypeRef.current.focus();
            })
            return;
        }

        //== Build Parameters ==// 
        let formData = new FormData();
        // formData.append("id_number", this.state.identityNumber ); 
        // formData.append("id_type_code", this.state.selectedIdentityCode ); 
        // formData.append("city_of_birth", this.state.city);
        // formData.append("occupation_code", this.state.selectedOccupationCode); 
        // formData.append("education_code", this.state.selectedEducationCode); 
        // formData.append("gender", this.state.gender);
        // formData.append("birthdate", this.state.birthdate);
        formData.append("fullname", this.state.name);
        formData.append("email", this.state.email);
        formData.append("password", this.state.password);

        // console.log("Identity No : " + this.state.identityNumber ); 
        // console.log("Id Type Code : " + this.state.selectedIdentityCode);
        // console.log("City Of Birth : " + this.state.city ); 
        // console.log("Occupation Code : " + this.state.selectedOccupationCode); 
        // console.log("Education Code : " + this.state.selectedEducationCode);
        // console.log("Fullname : " + this.state.name);  
        // console.log("Gender : " + this.state.gender);  
        // console.log("Birthdate : " + this.state.birthdate);
        // console.log("Email : " + this.state.email);    
        // console.log("Password : " + this.state.password);  


        this.executeSignUp(formData);

    }

    executeSignUp = async (formData) => {
        this.setState({
            isSubmitting: true
        })
        const response = await executePost(userRegistration, formData);
        if (response.code == code_ok) {
            this.setState({
                isSubmitting: false,
                showSuccess: true,
                successMessage: response.msg
            })
        } else {
            this.setState({
                isSubmitting: false,
                alertMessage: response.msg,
                showAlert: true,
            })
        }
    }
    handleWarningDialogClick = () => {
        this.setState({
            showAlert: false
        })
    }
    handleSuccessDialogClick = () => {
        this.setState({
            showSuccess: false,
        }, () => {
            this.backToLogin();
        })
    }
    render() {
        const winHeight = Dimensions.get("window").height;
        const backgroundWidth = winHeight - 144;
        const backgroundWidth2 = winHeight - 112;

        return (
            <View style={{ flex: 1, flexDirection: "row" }}>
                <View style={{ flex : 0.6, alignItems : "center", justifyContent : "center" }} >
                    <View style={{ position: "absolute", left : -16, top : 0 }}>
                        <Layer1Background 
                            //width={ backgroundWidth2 } 
                            height={ winHeight }
                            //viewBox={"0 0 " + backgroundWidth2 + " " + winHeight }
                        />
                    </View>
                    <View style={{ position: "absolute", left : -16, top : 0 }}>
                        <Layer2Background 
                            //width={ backgroundWidth }
                            height={ winHeight }
                            //viewBox={"0 0 " + backgroundWidth + " " + winHeight }
                        />
                    </View>
                    {/* <View style={{ position : "absolute", left : 16 , top : 16 }}>
                        <LogoPusnas 
                            width={ 55 }
                            height={ 51 }
                        />
                    </View> */}
                    <View style={{ position: "absolute", left : 16, top : 28 }}>
                        <HeaderLogoWhite
                            width={ 300 }
                            height={ 45 }
                        />
                    </View>
                    <View style={{marginRight : 128 }}>
                        <CrowdBackground 
                            width={ 500 }
                            height={ 421 }
                        />
                    </View>
                </View>
                {
                    !this.state.isSubmitting &&
                    <View style={{ flex: 0.4, alignItems: 'center', justifyContent: "center" }}>
                        <View style={{ width: "70%" }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={24}
                                fontWeight="700"
                                sx={{ color: primary }}
                                style={{ position: 'absolute', top: 16, left: 0 }}
                            >
                                {
                                    !this.state.showRegencies &&
                                    "Daftar"
                                }
                                {
                                    this.state.showRegencies &&
                                    "Pilih Kota Lahir Anda"
                                }
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={16}
                                fontWeight="600"
                                sx={{ color: sub_text }}
                                style={{ position: 'absolute', top: 46, left: 0 }}
                            >
                                {
                                    !this.state.showRegencies &&
                                    "Isi formulir dibawah ini sesuai dengan data anda"
                                }
                                {
                                    this.state.showRegencies &&
                                    "Pilih provinsi kemudian pilih kota kelahiran anda"
                                }

                            </Typography>
                            <TextBox
                                reference={this.nameRef}
                                caption={"Nama Lengkap"}
                                placeholder={"Masukkan nama lengkap"}
                                containerStyle={{ marginTop: 86 }}
                                error={this.state.errorName}
                                errorMessage={this.state.errorNameMessage}
                                onChangeText={this.handleNameChange}
                            />
                            <TextBox
                                reference={this.emailRef}
                                caption={"Email"}
                                placeholder={"Masukkan email anda"}
                                containerStyle={{ marginTop: 16 }}
                                error={this.state.errorEmail}
                                errorMessage={this.state.errorEmailMessage}
                                onChangeText={this.handleEmailChange}
                            />
                            <PasswordBox
                                reference={this.passwordRef}
                                caption={"Buat Password"}
                                containerStyle={{ marginTop: 16 }}
                                placeholder={"Min. 8-karakter alfanumerik"}
                                error={this.state.errorPassword}
                                errorMessage={this.state.errorPasswordMessage}
                                onChangeText={this.handlePasswordChange}
                            />
                            <PasswordBox
                                reference={this.retypeRef}
                                caption={"Ketik ulang Password"}
                                containerStyle={{ marginTop: 16, marginBottom: 16 }}
                                placeholder={"Ketik ulang password diatas"}
                                error={this.state.errorRetype}
                                errorMessage={this.state.errorRetypeMessage}
                                onChangeText={this.handleRetypeChange}
                            />
                            <Button
                                variant="contained"
                                style={{ textTransform: "none", borderRadius: 8, padding: 10, marginBottom: 0 }}
                                onClick={this.handleSubmitClick}
                            >
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={16}
                                    fontWeight="500"
                                >
                                    Daftar
                                        </Typography>
                            </Button>
                            <View style={{ flexDirection: "row", marginTop: 24, marginBottom: 16, alignItems: "center", justifyContent: "center" }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                >
                                    Sudah memiliki akun?
                                        </Typography>
                                <Pressable
                                    onPress={() => { this.backToLogin() }}
                                >
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={14}
                                        fontWeight="600"
                                        style={{ marginLeft: 8 }}
                                        color={primary}
                                    >
                                        Klik disini
                                            </Typography>
                                </Pressable>
                            </View>
                        </View>
                        {/* {
                            !this.state.showRegencies &&
                            <View style={{ flex: 1 }}>
                                <TextBox
                                    reference={this.nameRef}
                                    caption={"Nama Lengkap"}
                                    placeholder={"Masukkan nama lengkap"}
                                    containerStyle={{ marginTop: 16 }}
                                    error={this.state.errorName}
                                    errorMessage={this.state.errorNameMessage}
                                    onChangeText={this.handleNameChange}
                                />
                                <RadioBox 
                                        caption={"Jenis Kelamin"}
                                        containerStyle={{marginTop : 16 }}
                                        captionA={"Laki-Laki"}
                                        captionB={"Perempuan"}
                                        valueA={"L"}
                                        valueB={"P"}
                                        value={ this.state.gender }
                                        onChange={ this.handleRadioChange }
                                    />
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <OptionBox 
                                            reference={ this.birthRef }
                                            editable={ false }
                                            caption={"Tanggal Lahir"} 
                                            usingIcon={ true }
                                            placeholder={"Pilih tanggal lahir"} 
                                            containerStyle={{marginTop : 16 }}
                                            icon={ <CalendarTodayIcon fontSize="small" /> }
                                            onIconClick={ this.handleCalendarClick }
                                            error={ this.state.errorDate } 
                                            errorMessage={ this.state.errorDateMessage }
                                            value={ this.state.formatted_birthdate }
                                        />
                                        {
                                            this.state.showDatePicker && 
                                            <StaticDatePicker 
                                                displayStaticWrapperAs="desktop"
                                                inputFormat="DD/MMM/YYYY"
                                                value={ this.state.formatted_birthdate }
                                                onChange={this.handleSelectedBirthdate }
                                                renderInput={(params) => <TextField {...params} />}
                                            />

                                        }
                                    </LocalizationProvider>
                                    <OptionBox 
                                        reference={ this.cityRef }
                                        caption={"Kota Lahir"} 
                                        usingIcon={ true }
                                        editable={ false }
                                        placeholder={"Pilih kota lahir"} 
                                        containerStyle={{marginTop : 16 }}
                                        value={ this.state.city }
                                        icon={ <ExpandMoreIcon fontSize="small"/> }
                                        error={ this.state.errorCity }
                                        errorMessage={ this.state.errorCityMessage }
                                        onIconClick={ this.handleCityClick }
                                    />
                                    <TextBox
                                        reference={ this.identityRef }
                                        caption={"No. Identitas"}
                                        placeholder={"Masukkan nomor identitas anda"} 
                                        containerStyle={{marginTop : 16 }}
                                        error={ this.state.errorIdentity }
                                        errorMessage={ this.state.errorIdentityMessage }
                                        onChangeText={ this.handleIdentityChange }
                                    />
                                    <ComboBox
                                        reference={ this.identTypeRef}
                                        caption={"Jenis Identitas"} 
                                        usingIcon={ true }
                                        data={ this.state.identities }
                                        placeholder={"Pilih jenis identitas anda"} 
                                        containerStyle={{marginTop : 16 }} 
                                        editable={ false }
                                        value={ this.state.identityType } 
                                        icon={<ExpandMoreIcon fontSize="small" />} 
                                        error={ this.state.errorIdentityType } 
                                        errorMessage={ this.state.errorIdentityTypeMessage } 
                                        onIconClick={ this.handleIdentityTypeClick }
                                        onChange={this.handleSelectedIdentity }
                                        
                                    />
                                    <ComboBox
                                        reference={ this.educationRef }
                                        caption={"Pendidikan"} 
                                        usingIcon={ true }
                                        editable={ false }
                                        data={ this.state.educations }
                                        placeholder={"Pilih pendidikan terakhir anda"} 
                                        containerStyle={{marginTop : 16 }} 
                                        value={ this.state.education } 
                                        icon={<ExpandMoreIcon fontSize="small" />} 
                                        error={ this.state.errorEducation } 
                                        errorMessage={ this.state.errorEducationMessage } 
                                        onIconClick={ this.handleEducationClick }
                                        onChange={this.handleSelectedEducation }
                                    />
                                    <ComboBox
                                        reference={ this.occupationRef }
                                        caption={"Pekerjaan"} 
                                        usingIcon={ true }
                                        editable={ false }
                                        placeholder={"Pilih pekerjaan anda"} 
                                        containerStyle={{marginTop : 16 }} 
                                        value={ this.state.occupation } 
                                        icon={<ExpandMoreIcon fontSize="small" />} 
                                        error={ this.state.errorOccupation } 
                                        data={ this.state.occupations }
                                        errorMessage={ this.state.errorOccupationMessage } 
                                        onIconClick={ this.handleOccupationClick }
                                        onChange={this.handleSelectedOccupation }

                                    />
                                <TextBox
                                    reference={this.emailRef}
                                    caption={"Email"}
                                    placeholder={"Masukkan email anda"}
                                    containerStyle={{ marginTop: 16 }}
                                    error={this.state.errorEmail}
                                    errorMessage={this.state.errorEmailMessage}
                                    onChangeText={this.handleEmailChange}
                                />
                                <PasswordBox
                                    reference={this.passwordRef}
                                    caption={"Buat Password"}
                                    containerStyle={{ marginTop: 16 }}
                                    placeholder={"Min. 8-karakter alfanumerik"}
                                    error={this.state.errorPassword}
                                    errorMessage={this.state.errorPasswordMessage}
                                    onChangeText={this.handlePasswordChange}
                                />
                                <PasswordBox
                                    reference={this.retypeRef}
                                    caption={"Ketik ulang Password"}
                                    containerStyle={{ marginTop: 16, marginBottom: 16 }}
                                    placeholder={"Ketik ulang password diatas"}
                                    error={this.state.errorRetype}
                                    errorMessage={this.state.errorRetypeMessage}
                                    onChangeText={this.handleRetypeChange}
                                />
                                <Button
                                    variant="contained"
                                    style={{ textTransform: "none", borderRadius: 8, padding: 10, marginBottom: 0 }}
                                    onClick={this.handleSubmitClick}
                                >
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={16}
                                        fontWeight="500"
                                    >
                                        Daftar
                                        </Typography>
                                </Button>
                                <View style={{ flexDirection: "row", marginTop: 24, marginBottom: 16, alignItems: "center", justifyContent: "center" }}>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={14}
                                    >
                                        Sudah memiliki akun?
                                        </Typography>
                                    <Pressable
                                        onPress={() => { this.backToLogin() }}
                                    >
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={14}
                                            fontWeight="600"
                                            style={{ marginLeft: 8 }}
                                            color={primary}
                                        >
                                            Klik disini
                                            </Typography>
                                    </Pressable>
                                </View>
                            </View>
                        }
                        {
                            this.state.showRegencies &&
                            <View style={{ flex: 1, marginTop: 16, marginLeft: 16, marginRight: 16, marginBottom: 16 }}>
                                <List>
                                    {
                                        this.state.regencies.map((item, index) => (
                                            <>
                                                <ListItemButton component="a" onClick={() => { this.handleProvinceClick(item) }}>
                                                    <ListItemIcon key={index}>
                                                        {
                                                            (this.state.selectedProvinceId == item.id) &&
                                                            <ExpandMoreIcon />
                                                        }
                                                        {
                                                            (this.state.selectedProvinceId != item.id) &&
                                                            <ChevronRightIcon />
                                                        }

                                                    </ListItemIcon>
                                                    <ListItemText primary={item.name} />

                                                </ListItemButton>
                                                <Collapse in={this.state.selectedProvinceId == item.id}>
                                                    <List component="div" disablePadding>
                                                        {
                                                            item.regencies.map((i, d) => (
                                                                <ListItemButton key={"child_" + d} style={{ marginLeft: 8 }} onClick={() => { this.setRegency(i) }}>
                                                                    <ListItemIcon key={index}>
                                                                        <ChevronRightIcon />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary={i.name} />
                                                                </ListItemButton>
                                                            ))
                                                        }
                                                    </List>
                                                </Collapse>
                                            </>
                                        ))
                                    }
                                </List>
                            </View>
                        } */}
                    </View>
                }
                <LoadingDialog
                    open={this.state.isSubmitting}
                    message={"Mendaftarkan akun anda"}
                />
                <WarningDialog
                    open={this.state.showAlert}
                    message={this.state.alertMessage}
                    caption={"OK"}
                    onClick={this.handleWarningDialogClick}
                />
                <SuccessDialog
                    open={this.state.showSuccess}
                    message={this.state.successMessage}
                    caption={"OK"}
                    onClick={this.handleSuccessDialogClick}
                />

            </View>
        )
    }
}


export default SignUpDesktop;