import React, { Component } from 'react';
import { StyleSheet, Image, View, Linking } from 'react-native';
import { Alert, AlertTitle, CircularProgress, LinearProgress } from '@mui/material';
import {
    checkLocalData, executePost, executeGet, createAuthHeader, clearStorage,
    saveLocalParameters, checkLocalParameters, clearLocalParams, createAuthHeaderReader
} from '../utilities/Utilities';
import { code_ok, code_empty, code_auth_error, code_error, appConfig, relogin, autoSignIn, refreshToken } from '../constants/api';
import * as Identifier from "../constants/PageIdentifiers";
import * as Command from "../constants/Commands";
import AlertDialog from '../components/AlertDialog';
import LogoSplash from '../assets/images/LogoSplash';
import LogoSplashNew from '../assets/images/LogoSplashNew';
import LogoPusnas from '../assets/images/LogoPusnas';
import { Typography } from '@mui/material';
import { primary } from '../constants/colors';

var _is_mounted = false;
class Splash extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertShowing: false,
            alertTitle: "Perhatian!",
            alertMessage: "",
            options: {},
            appToken: ""
        }
        this.initialization = this.initialization.bind(this);
        this.configuration = this.configuration.bind(this);
        this.handleAlertClose = this.handleAlertClose.bind(this);
        this.relogin = this.relogin.bind(this);
        this.checkSSOToken = this.checkSSOToken.bind(this);

        this.getLocalParams = this.getLocalParams.bind(this);

    }

    componentDidMount = () => {
        _is_mounted = true;

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        let os = params.os;
        let appAccess = params.appaccess;
        let contentId = params.content;

        global.os = os;
        global.contentId = contentId;
        global.appAccess = appAccess;

        if (params.v != undefined) {
            global.versi = params.v;
        }

        if (params.versiIos != undefined) {
            let versiIos = params.versiIos;
            global.versiIos = versiIos;
        }


        let token = params.token;
        if (token == 'null' || token == null || token == 'undefined' || token == '') {
            token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJrdWJ1a3UuY28uaWQiLCJpYXQiOjE2NzQ2MTQ2MTAsImV4cCI6MjQ1MjIxNDYxMCwiYXVkIjoia3VidWt1LmNvLmlkIiwiQVBQSUQiOjEsIkFDQ0VTU0lEIjozLCJDTElFTlRJRCI6NDgzOCwiT1MiOiJkYXJ3aW4iLCJVVUlEIjoiZDhmOGI3YmMtNmEzZi00NTQ1LWIzMDgtOTJmOTg5M2RiZTg4In0.aNM1U9tw3wxHC2d8JuPuF1E_rVd5ALXxHP7NNjVy1-0';
        }

        // console.log("reader splash = " + global.reader)
        // if(global.reader == "yes") {
        //     // this.props.navigation.reset({
        //     //     index: 0,
        //     //     routes:[{name:'ContentDetail', params, pageTo:'ContentDetail'}],
        //     // });
        //     let data = localStorage.getItem('readerData');
        //     console.log("reader local = " + data)
        //     this.props.navigation.navigate("ReaderMobile", JSON.parse(data));
        // } else {
        //     this.setState({
        //         appToken: token
        //     }, () => {
        //         if (params.fr != undefined) {
        //             this.getLocalParams(token, os);
        //         } else {
        //             //Clear Local Params 
        //             clearLocalParams();
        //             this.initialization(token, os);
        //         }
        //     })
        // }

        // if(contentId != undefined || contentId != null) {
        //     let params = {
        //         content_code : contentId
        //     }

        //     this.props.navigation.reset({
        //         index: 0,
        //         routes:[{name:'ContentDetail', params, pageTo:'ContentDetail'}],
        //     });
        // } else {
        //     this.setState({
        //         appToken : token
        //     },()=>{
        //         if(params.fr != undefined){
        //             this.getLocalParams(token, os);
        //         } else {
        //             //Clear Local Params 
        //             clearLocalParams();
        //             this.initialization(token, os); 
        //         }
        //     })
        // }

        this.setState({
            appToken: token
        }, () => {
            console.log('FROM_APP = ' + params.fr);
            if (params.fr != undefined) {
                this.getLocalParams(token, os);
            } else {
                //Clear Local Params 
                clearLocalParams();
                this.initialization(token, os);
            }
        })

        
        // this.props.navigation.reset({
        //     index: 0,
        //     routes: [{ name: "Main", params: {} }]
        // })


        /*
        if(os == "ANDROID") {
            JSBridge.launchFunction(Command.REQUEST_APP_TOKEN, "-");
           
        } else if(os == "IOS") {
            let obj = {
                id : Command.REQUEST_APP_TOKEN , 
                params : "TESTING"
            }
            window.webkit.messageHandlers.JSBridge.postMessage(obj);
        } 

        window.addEventListener("message", (event)=>{
            //console.log("RECEIVING MESSAGE");
            //console.log(event.data)
            let obj = event.data; 
            if(obj.id == "app_token") {
                this.initialization(obj.token, obj.os);
            }
        })
        */
    }
    componentWillUnmount = () => {
        _is_mounted = false;
        //window.removeEventListener("message", null);
    }


    initialization = async (token, os) => {
        //console.log("INITIALIZATION"); 
        if (_is_mounted) {
            // const params = new Proxy(new URLSearchParams(window.location.search), {
            //     get: (searchParams, prop) => searchParams.get(prop),
            // });
            // console.log('reader_check = ' + params.reader)

            // if (params.reader != "yes") {
            //     await checkLocalData(token, os);
            // }
            
            await checkLocalData(token, os);
            this.configuration();
            
        }
    }

    getLocalParams = async (token, os) => {
        let obj = await checkLocalParameters();
        // let obj = localStorage.getItem("main_data");
        console.log('checkLocalParameters' + obj);
        if (obj != null) {
            // global.defaultHeaders = obj.credential;
            this.props.navigation.reset({
                index: 0,
                routes: [{ name: "Main", params: obj }]
            })

        } else {
            this.initialization(token, os);
        }
    }

    configuration = async () => {
        //console.log("CONFIGURATION()");

        const response = await executeGet(appConfig);
        if (response.code == code_ok) {
            console.log(JSON.stringify(response));
            let data = response.data;
            let educations = data.educations;
            let occupations = data.occupations;
            let identities = data.identities;
            let options = {
                educations: educations,
                occupations: occupations,
                identities: identities
            }
            this.setState({
                options: options
            })

            if(global.os == "ANDROID" || global.os == "android") {
                if(data.version == global.versi || global.versi == undefined) {
                    //ANCHOR - Check SSO TOKEN 
                    this.checkSSOToken();
                } else {
                    //ANCHOR - Go to Update
                    this.props.navigation.reset({
                        index: 0,
                        routes:[{name:'UpdatePage', params : {} }],
                    });
                    
                }
            } else {
                //ANCHOR - Check SSO TOKEN 
                this.checkSSOToken();
            }
            
            

            // if(global.defaultHeaders.hasOwnProperty("CLIENTTOKEN")) {
            //     this.relogin(); 
            // } else { 
            //     //GOTO: Login 
            //     setTimeout(() => {
            //         this.props.navigation.reset({
            //             index: 0,
            //             routes:[{name:'Login', params : options}],
            //         });
            //     }, 3000);
            // }

        } else {
            this.setState({
                alertShowing: true,
                alertTitle: "Error",
                alertMessage: response.msg
            });
        }
    }

    handleAlertClose = () => {
        if (global.os == "ANDROID") {
            JSBridge.launchFunction(Command.CLOSE_APP, "-");
        } else {
            window.webkit.messageHandlers.JSBridge.postMessage({ id: Command.CLOSE_APP, params: "-" })
        }
    }

    relogin = async () => {
        //console.log(JSON.stringify(global.defaultHeaders));
        const response = await executeGet(relogin);
        console.log("RESPONE_RELOGIN = " + JSON.stringify(response));
        if (response.code == code_ok) {
            createAuthHeader(response.data);
            // setTimeout(()=>{
            //     let parameters = response.data.user; 
            //     parameters.options = this.state.options;

            //     this.props.navigation.reset({
            //         index : 0, 
            //         routes:[{ name : "Main", params : parameters }]
            //     })
            // },3000);

            setTimeout(() => {
                let parameters = response.data.user;
                parameters.options = this.state.options;

                this.props.navigation.reset({
                    index: 0,
                    routes: [{ name: 'Intro', params: parameters, pageTo: 'Main' }],
                });
            }, 3000);
        } else {
            let os = global.defaultHeaders.OS;
            let app_token = global.defaultHeaders.APPTOKEN;
            clearStorage(app_token, os);
            // setTimeout(() => {
            //     this.props.navigation.reset({
            //         index: 0,
            //         routes:[{name:'Login', params : this.state.options}],
            //     });
            // }, 3000);  
            setTimeout(() => {
                this.props.navigation.reset({
                    index: 0,
                    routes: [{ name: 'Intro', params: this.state.options, pageTo: 'Login' }],
                });
            }, 3000);
        }
    }

    checkSSOToken = async () => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let contentId = params.content;
        let homepageId = params.hmp;

        console.log('contentId = ' + contentId)
        console.log('reader_check = ' + params.reader)

        if (params.reader == "yes") {
            // this.props.navigation.reset({
            //     index: 0,
            //     routes:[{name:'ContentDetail', params, pageTo:'ContentDetail'}],
            // });

            if (global.os == "ANDROID") {
                console.log('Rack Online')
                window.addEventListener("message", (event) => {

                    if (event.data.id == "reader_online") {
                        
                        console.log('[KUBUKU PWA 1] = ' + event.data.id)
                        console.log('[KUBUKU PWA 2] = ' + event.data.data)
                        console.log('[KUBUKU PWA 3] = ' + event.data.main_content)
                        console.log('[KUBUKU PWA 4] = ' + event.data.local)
                        
                        let data = JSON.parse(event.data.data);
                        console.log('[KUBUKU PWA DATA] = ' + data)
                        data.mode = 'read';
                        data.local = event.data.local;
                        data.main_content = event.data.main_content;
                        localStorage.setItem('readerData', JSON.stringify(data));

                        console.log('[KUBUKU PWA DATA] = ' + JSON.stringify(data))
                        createAuthHeaderReader(params.token, params.uid, params.uuid);
                        this.props.navigation.navigate("ReaderMobile", data);
                    }
                });
            } else {
                let data = localStorage.getItem('readerData');
                console.log("reader local in spl = " + data)
                console.log('token atas = ' + params.token)
                console.log('uid atas = ' + params.uid)
                createAuthHeaderReader(params.token, params.uid, params.uuid);
                // console.log('global token atas = ' + params.token)
                this.props.navigation.navigate("ReaderMobile", JSON.parse(data));
            }

            // let data = localStorage.getItem('readerData');
            // console.log("reader local in spl = " + data)
            // console.log('token atas = ' + params.token)
            // console.log('uid atas = ' + params.uid)
            // createAuthHeaderReader(params.token, params.uid, params.uuid);
            // // console.log('global token atas = ' + params.token)
            // this.props.navigation.navigate("ReaderMobile", JSON.parse(data));

        } else {
            // console.log('token atas = ' + params.token)
            // console.log('uid atas = ' + params.uid)
            // createAuthHeaderReader(params.token, params.uid);

            console.log("CHECK SSO TOKEN = " + JSON.stringify(global.defaultHeaders));
            // console.log(JSON.stringify(global.defaultHeaders));
            const response = await executeGet(autoSignIn);
            console.log("RESPON SSO TERBARU = " + JSON.stringify(response));
            // console.log(response);
            if (response.code == code_ok) {
                console.log("RESPONSE SSO TOKEN");
                console.log(JSON.stringify(response));
                console.log("EMAIL ");
                console.log(response.data.user.email);
                console.log("dddddddddd");
                createAuthHeader(response.data);
                saveLocalParameters(response.data.user);

                if (global.os == "ANDROID") {
                    JSBridge.launchFunction(Command.UPDATE_TOKEN, response.data.token);
                }
                // setTimeout(()=>{
                //     let parameters = response.data.user; 
                //     parameters.options = this.state.options;

                //     this.props.navigation.reset({
                //         index : 0, 
                //         routes:[{ name : "Main", params : parameters }]
                //     })
                //     // this.props.navigation.navigate("SignUp", this.props.route.params );
                // },3000);

                // setTimeout(() => {
                //     this.props.navigation.reset({
                //         index: 0,
                //         routes:[{name:'Main', params : response.data.user}],
                //     });
                // }, 3000);

                //check go to content
                console.log('contentId = ' + contentId)
                console.log('homepageId = ' + homepageId)
                console.log('reader_check = ' + params.reader)
                if (contentId != undefined || contentId != null) {
                    let params = {
                        content_code: contentId
                    }
                    // console.log("contentDetail 1 = " + JSON.stringify(this.props.route.params));
                    // console.log("contentDetail 2 = " + JSON.stringify(this.props.navigation));
                    // console.log("contentDetail 3 = " + JSON.stringify(params));
                    // navigateContentDetail(this.props.navigation, params); 

                    this.props.navigation.reset({
                        index: 0,
                        routes: [{ name: 'ContentDetail', params, pageTo: 'Main' }],
                    });

                } else if (params.reader == "yes") {
                    // this.props.navigation.reset({
                    //     index: 0,
                    //     routes:[{name:'ContentDetail', params, pageTo:'ContentDetail'}],
                    // });

                    if (global.os == "ANDROID") {
                        console.log('Rack Online 2')
                        window.addEventListener("message", (event) => {
                            if (event.data.id == "reader_online") {
                        
                                console.log('[KUBUKU PWA 1] = ' + event.data.id)
                                console.log('[KUBUKU PWA 2] = ' + event.data.data)
                                console.log('[KUBUKU PWA 3] = ' + event.data.main_content)
                                console.log('[KUBUKU PWA 5] = ' + event.data.local)
                                
                                let data = JSON.parse(event.data.data);
                                console.log('[KUBUKU PWA DATA] = ' + data)
                                data.mode = 'read';
                                data.local = event.data.local;
                                data.main_content = event.data.main_content;
                                localStorage.setItem('readerData', JSON.stringify(data));
        
                                console.log('[KUBUKU PWA DATA] = ' + JSON.stringify(data))
                                createAuthHeaderReader(params.token, params.uid, params.uuid);
                                this.props.navigation.navigate("ReaderMobile", data);
                            }
                        });
                    } else {
                        let data = localStorage.getItem('readerData');
                        console.log("reader local in spl = " + data)
                        this.props.navigation.navigate("ReaderMobile", data);
                    }

                    
                    // let response = await executeGet(refreshToken);
                    // console.log('refreshToken splash= ' + JSON.stringify(response))
                    // if (response.code == code_ok) {
                    //     console.log('token atas = ' + params.token)
                    //     createAuthHeaderReader(params.token);
                    //     // console.log('global token atas = ' + params.token)
                    //     this.props.navigation.navigate("ReaderMobile", JSON.parse(data));

                    // }
                } else if(params.fr == "app1") {
                    this.props.navigation.reset({
                        index: 0,
                        routes: [{ name: 'Main', params: response.data.user }],
                    });
                } else {
                    setTimeout(() => {
                        this.props.navigation.reset({
                            index: 0,
                            routes: [{ name: 'Intro', params: response.data.user, pageTo: 'Main' }],
                        });
                    }, 3000);
                }
            } else {
                console.log('GAGAL DISINI 1')
                if (global.defaultHeaders.hasOwnProperty("CLIENTTOKEN")) {
                    console.log('relogin_nih')
                    this.relogin();
                } else {
                    console.log("GOTO INTRRO");
                    //GOTO: Login 
                    localStorage.clear();
                    document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

                    setTimeout(() => {
                        this.props.navigation.reset({
                            index: 0,
                            routes: [{ name: 'Intro', params: this.state.options, pageTo: 'Login' }],
                        });
                    }, 3000);
                }
            }
        }
    }
    render() {
        return (
            <View style={styles.container}>
                {/* <Image 
                    source={require("../assets/images/logo.png")}
                    style={{
                        width : 200,
                        height : 142
                    }}
                /> */}
                {
                    global.contentId != null && global.reader == 'yes' &&
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={20}
                        variant="body1"
                        fontWeight="600"
                        marginLeft={0.5}
                        color={primary}
                    >
                        Loading
                    </Typography>
                }
                {
                    global.reader == 'yes' &&
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={20}
                        variant="body1"
                        fontWeight="600"
                        marginLeft={0.5}
                        color={primary}
                    >
                        Loading
                    </Typography>
                }
                {/* global.versiIos != 1 || */}
                {
                    global.contentId == null && global.reader == null &&
                    <LogoSplashNew
                        width={215}
                        height={195}
                    />
                }

                {/* <LogoPusnas
                    width={ 300 }
                    height={ 48 }
                /> */}

                <LinearProgress
                    style={{ marginTop: 16, width: "50%" }}
                />

                {/* <AlertDialog
                    showing={ this.state.alertShowing } 
                    title={ this.state.alertTitle }
                    message={ this.state.alertMessage }
                    onClose={ this.handleAlertClose }
                    onClick={ this.handleAlertClose }
                    buttonCaption={"OK"}
                /> */}
                {
                    this.state.alertShowing &&
                    <Alert
                        onClose={() => { this.handleAlertClose() }}
                        severity="error"
                        sx={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
                    >
                        <AlertTitle>{this.state.alertTitle}</AlertTitle>
                        {this.state.alertMessage}
                    </Alert>

                }
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center"
    }
})
export default Splash;