import React, { Component } from 'react';
import { StyleSheet, View, FlatList, Image, Dimensions } from 'react-native';
import { code_ok, code_error, code_auth_error, code_empty, homepageNews } from '../../constants/api';
import { Skeleton, Typography, Card, CardActionArea, CardMedia, CardContent, Button } from '@mui/material';
import { executeGet, executePost, truncateText, getQueryStrings } from '../../utilities/Utilities';
import AlertDialog from '../../components/AlertDialog';
import { color_text, sub_text, primary } from '../../constants/colors';
import * as Identifier from '../../constants/PageIdentifiers';

const HomeYoutube = () => {
    return (
        <Card
            style={{
                borderRadius: 8,
                marginRight: 16,
                marginBottom: 12,
                marginTop: 4,
                boxShadow: "0px 33px 74px rgba(0, 0, 0, 0.0196802), 0px 13.7866px 30.9154px rgba(0, 0, 0, 0.0282725), 0px 7.37098px 16.5289px rgba(0, 0, 0, 0.035), 0px 4.13211px 9.26595px rgba(0, 0, 0, 0.0417275), 0px 2.19453px 4.92108px rgba(0, 0, 0, 0.0503198), 0px 0.913195px 2.04777px rgba(0, 0, 0, 0.07)"
            }}>
            <CardActionArea
                sx={{ height: "100%" }}
                onClick={() => { this.handleNewsClick(item, index) }}
            >
                <CardMedia
                    image={'https://mui.com/static/images/cards/contemplative-reptile.jpg'}
                    height={167}
                    component="img"
                />
                <CardContent
                    style={{ flex: 1 }}
                >
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={14}
                        fontWeight={"600"}
                        color={color_text}
                        style={{ marginBottom: 8, flex: 1 }}
                    >
                        Tranformasi Perpustakaan berbasis inklusi socsial di Daerah NTT FLORES...
                    </Typography>

                    <Typography
                        fontFamily="Open Sans"
                        fontSize={12}
                        fontWeight={"400"}
                        color={sub_text}
                        style={{ marginBottom: 8 }}
                    >
                        Perpustakaan Nasional RI
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

class MenuYoutubeDesktop extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            showAlert: false,
            alertMessage: "",
            loadingMessage: "",
            code: this.props.code,
            data: [],
            dummy_data: [
                { i: 0 }, { i: 1 }, { i: 2 }
            ]
        }

        this.renderSkeleton = this.renderSkeleton.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.fetchNews = this.fetchNews.bind(this);
        this.handleNewsClick = this.handleNewsClick.bind(this);
        this.handleMoreClick = this.handleMoreClick.bind(this);
    }

    componentDidMount = () => {
        //console.log("MENU YOUTUBE DESKTOP");
        this.fetchNews();
    }

    handleMoreClick = (code, title) => {
        console.log("CODE ");
        console.log(code);
        this.props.navigation.navigate("NewsDesktop", { identifier : Identifier.NEWS, code : code, title : title, origins : "NEWS"}); 
    }

    renderSkeleton = ({ item, index, separators }) => {
        return (
            <View style={{
                width: 220,
                marginLeft: (index == 0 ? 16 : 0),
                marginRight: (index == (this.state.dummy_data.length - 1) ? 16 : 8)
            }}>
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    style={{ borderRadius: 8, width: 220, height: 110, marginBottom: 8 }}
                />
                <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "1.5rem", width: 180 }}
                    style={{ marginBottom: 4 }}
                />
                <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "1.2rem", width: 120 }}
                    style={{ marginBottom: 4 }}
                />
            </View>
        )
    }

    renderItem = ({ item, index, separators }) => {
        //== ATTENTION FOR YOUTUBE TYPE ==//
        let image = "";
        if(item.tipe == "youtube"){
            let url_youtube = item.url_youtube;
            let video_id = getQueryStrings(url_youtube)["v"]; 
            image = "https://img.youtube.com/vi/"+video_id+"/hqdefault.jpg";
        }
        return (
            <Card
                sx={{ maxWidth: 220 }}
                style={{
                    borderRadius: 8,
                    marginRight: (index == (this.state.data.length - 1) ? 26 : 26),
                    marginBottom: 8,
                    marginTop: 4,
                    boxShadow: "0px 33px 74px rgba(0, 0, 0, 0.0196802), 0px 13.7866px 30.9154px rgba(0, 0, 0, 0.0282725), 0px 7.37098px 16.5289px rgba(0, 0, 0, 0.035), 0px 4.13211px 9.26595px rgba(0, 0, 0, 0.0417275), 0px 2.19453px 4.92108px rgba(0, 0, 0, 0.0503198), 0px 0.913195px 2.04777px rgba(0, 0, 0, 0.07)"
                }}>
                <CardActionArea
                    style={{flexDirection : "row"}}
                    onClick={() => { this.handleNewsClick(item, index) }}
                >
                    <View style={{flex:0.3}}>
                    <CardMedia
                        image={image}
                        height={110}
                        component="img"
                        style={{top:0}}
                    />
                    </View>
                    <CardContent
                        style={{ flex: 0.7 }}
                    >
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={14}
                            fontWeight={"600"}
                            color={color_text}
                            style={{ marginBottom: 8, flex: 1 }}
                        >
                            {item.title}
                        </Typography>

                        <Typography
                            fontFamily="Open Sans"
                            fontSize={12}
                            fontWeight={"400"}
                            color={sub_text}
                            style={{ marginBottom: 8 }}
                        >
                            {item.input_time}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    }
    fetchNews = async () => {
        let formData = new FormData();
        formData.append("homepage_code", this.props.code);
        formData.append("page", 1);
        formData.append("limit", 6);

        const response = await executePost(homepageNews, formData);
        if (response.code == code_ok) {
            console.log(JSON.stringify(response));
            this.setState({
                showLoading: false,
                data: response.data
            })

        } else {
            if (response.code != code_auth_error) {
                this.setState({
                    showLoading: false,
                })
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, response.msg);
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : response.msg })
                // }
            } else {
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            }
        }

    }

    handleNewsClick = (item, index) => {
        item.identifier = Identifier.MAIN;
        this.props.navigation.navigate("DesktopNewsDetail", item);
    }
    render() {
        return (
            <View style={{ width: "100%", paddingLeft: 147, paddingRight: 147, marginBottom: 30 }}>
                {
                    this.state.showLoading &&
                    <View>
                        <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{ fontSize: "2rem", width: 150 }}
                            style={{ marginBottom: 8, marginLeft: 16 }}
                        />
                        <FlatList
                            keyExtractor={(i, x) => "news_skeleton_" + x}
                            data={this.state.dummy_data}
                            renderItem={this.renderSkeleton}
                            horizontal={true}
                        />
                    </View>
                }
                {
                    !this.state.showLoading &&
                    <View style={{ marginTop: 30 }}>
                        <View style={{ flexDirection: "row", alignItems: "center", marginBottom: 20 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={28}
                                variant="h3"
                                style={{ flex: 1 }}
                                color={color_text}
                                fontWeight={"600"}
                            >
                                {truncateText(this.props.title, 30)}
                            </Typography>
                            <Button
                                style={{ textTransform: "none", marginRight: 16 }}
                                onClick={() => { this.handleMoreClick(this.state.code, "Youtube") }}
                            >
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={16}
                                    fontWeight="500"
                                    color={primary}
                                >
                                    Lihat Semua
                                </Typography>
                            </Button>
                        </View>
                        <FlatList
                            keyExtractor={(item, index) => item.homepage_code + "_" + item.news_code}
                            data={this.state.data}
                            horizontal={true}
                            renderItem={this.renderItem}
                        />
                    </View>
                }

            </View>
        )
    }
}

export default MenuYoutubeDesktop;