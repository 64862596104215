import React, { Component } from 'react'; 
import { StyleSheet, Image, View, Linking } from 'react-native'; 
import { Alert, AlertTitle, CircularProgress, LinearProgress, Typography } from '@mui/material';
import { checkLocalData, executePost, executeGet, createAuthHeader, clearStorage ,
    saveLocalParameters, checkLocalParameters, clearLocalParams} from '../utilities/Utilities';
import { code_ok, code_empty, code_auth_error, code_error, appConfig, relogin , autoSignIn } from '../constants/api';
import * as Identifier from "../constants/PageIdentifiers";
import * as Command from "../constants/Commands";
import AlertDialog from '../components/AlertDialog';
import { LogoPusnas, LogoPusnasBlue } from "../assets/Icons";
import LogoPusnasNew from '../assets/images/LogoPusnasNew';
import LogoSplash from "../assets/images/LogoSplash";
import MainLogo from '../assets/MainLogo';

var _is_mounted = false;
class SplashDesktop extends Component {
    constructor(props){
        super(props)
        this.state = {
            alertShowing : false, 
            alertTitle : "Perhatian!", 
            alertMessage : "",
            options : {},
            appToken : ""
        }

        this.initialization = this.initialization.bind(this); 
        this.configuration = this.configuration.bind(this); 
        this.handleAlertClose = this.handleAlertClose.bind(this); 
        this.relogin = this.relogin.bind(this); 
        this.checkSSOToken = this.checkSSOToken.bind(this);
        this.getLocalParams = this.getLocalParams.bind(this); 

    }
    //ANCHOR - Component Did Mount
    componentDidMount = ()=>{
        _is_mounted = true;
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        let os = params.os; 
        global.os = os; 
        let token = params.token; //APPTOKEN / SSO TOKEN
        console.log('FROM_APP = ' + params.fr);
        this.setState({
            appToken : token
        },()=>{
            if(params.fr == "app1" ) {
                checkLocalData(token,os);
                this.getLocalParams(token, os);
                // this.props.navigation.reset({
                //     index : 0, 
                //     routes:[{ name : "MainDesktop", params : global.starting_parameter }]
                // })
            } else {
                this.initialization(token, os); 
            }
        })
        

    }
    
    getLocalParams = async(token, os)=>{
        // let obj = localStorage.getItem("main_data");
        // console.log('checkLocalParameters' + obj);
        // this.props.navigation.reset({
        //     index: 0,
        //     routes: [{ name: "MainDesktop", params: {} }]
        // })
        let obj = await checkLocalParameters(); 
        if(obj != null) {
            global.defaultHeaders = obj.credential; 
            this.props.navigation.reset({
                index : 0, 
                routes:[{ name : "MainDesktop", params : obj }]
            })
        } else {
            this.initialization(token, os); 
        }
    }
    //ANCHOR - Initialization
    initialization = async(token, os)=>{
        if(_is_mounted){
            await checkLocalData(token,os);
            this.configuration(); 
        }
    }
    //ANCHOR - Configuration
    configuration = async()=>{
        //console.log("Execute Configuration"); 
        const response = await executeGet(appConfig);
        //console.log("Got Response"); 
        //console.log(response);
        if(response.code == code_ok){
            let data = response.data; 
            let educations = data.educations; 
            let occupations = data.occupations; 
            let identities = data.identities; 
            let options = {
                educations : educations, 
                occupations : occupations,
                identities : identities
            }
            this.setState({
                options : options
            })
            //ANCHOR - Check SSO TOKEN 
            this.checkSSOToken();
        } else {
            this.setState({
                alertShowing : true,
                alertTitle : "Error", 
                alertMessage : response.msg
            });
        }
    }

    handleAlertClose = ()=>{
        
        
    }

    checkSSOToken = async()=>{
        const response = await executeGet(autoSignIn); 
        if(response.code == code_ok){
            createAuthHeader(response.data); 
            // setTimeout(()=>{
            //     let parameters = response.data.user; 
            //     parameters.options = this.state.options;
                
            //     this.props.navigation.reset({
            //         index : 0, 
            //         routes:[{ name : "MainDesktop", params : parameters }]
            //     })
            // },3000);
            setTimeout(() => {
                this.props.navigation.reset({
                    index: 0,
                    routes:[{name:'MainDesktop', params : this.state.options}],
                });
            }, 3000);
        } else {
            if(global.defaultHeaders.hasOwnProperty("CLIENTTOKEN")) {
                this.relogin(); 
            } else { 
                //console.log("GOTO LOGIN");
                //GOTO: Login 
                setTimeout(() => {
                    this.props.navigation.reset({
                        index: 0,
                        routes:[{name:'LoginDesktop', params : this.state.options}],
                    });
                }, 3000);
            }
        }
    }
    relogin = async()=>{
        
        const response = await executeGet(relogin);
        if(response.code == code_ok){
            createAuthHeader(response.data); 
            let parameters = response.data.user; 
            parameters.options = this.state.options;
            setTimeout(()=>{
                global.starting_parameter = parameters; 
                this.props.navigation.reset({
                    index : 0, 
                    routes:[{ name : "MainDesktop", params : parameters }]
                })
            },3000);
        } else {
            let os = global.defaultHeaders.OS;
            let app_token = global.defaultHeaders.APPTOKEN; 
            clearStorage(app_token, os);
            setTimeout(() => {
                this.props.navigation.reset({
                    index: 0,
                    routes:[{name:'LoginDesktop', params : this.state.options}],
                });
            }, 3000);     
        }
    }

    componentWillUnmount = ()=>{
        _is_mounted = false;
    }



    render(){
        return(
            <View style={ styles.container }>
                {/* <Image 
                    source={require("../assets/images/logo.png")}
                    style={{
                        width : 200,
                        height : 142
                    }}
                /> */}
                {/* <LogoSplash 
                    width={ 200 }
                    height={ 195 }
                /> */}
                <LogoPusnasNew 
                    width={ 512 }
                    height={ 120 }
                />
                <LinearProgress 
                    style={{marginTop : 16, width : 200, height : 8 }}
                />
                {
                    this.state.alertShowing && 
                    <Alert 
                        onClose={()=>{ this.handleAlertClose() } }
                        severity="error"
                        sx={{ position : "absolute", bottom : 0, left : 0 , right : 0 }}
                    > 
                        <AlertTitle>{ this.state.alertTitle }</AlertTitle>
                        { this.state.alertMessage }
                    </Alert>
                }
            </View>
        )
    }

}


const styles = StyleSheet.create({
    container : {
        flex : 1,
        alignItems : "center", 
        justifyContent : "center"
    }
})

export default SplashDesktop;