import * as React from "react"
import Svg, { G, Path } from "react-native-svg"



class HeaderLogo extends React.Component {
    constructor(props){
        super(props) 
    }

    render(){
        return(
            <Svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 2030 434"
        xmlSpace="preserve"
        {...this.props}
      >
        <G>
          <G>
            <Path className="st0" d="M53.2,352.3c4,3.5,6,8.3,6,14.5c0,6.5-2,11.6-6,15.2c-4,3.6-9.6,5.4-16.9,5.4H21.7v18.2h-8v-58.5h22.6 C43.6,347.1,49.2,348.9,53.2,352.3z M47.5,376.9c2.7-2.2,4-5.4,4-9.7c0-4.2-1.3-7.3-4-9.5c-2.7-2.1-6.5-3.2-11.5-3.2l-14.2,0.1 v25.7h14.2C40.9,380.2,44.8,379.1,47.5,376.9z" />
            <Path className="st0" d="M102.3,367.2c3.5,4,5.3,9.7,5.3,16.8c0,1.1,0,2-0.1,2.5H72.5c0.6,4,2.3,7.1,5,9.4c2.7,2.3,6,3.5,10,3.5 c2.6,0,5.1-0.5,7.3-1.4c2.3-0.9,4.2-2.3,5.9-4l4.3,4.4c-2.2,2.3-4.8,4.2-7.9,5.4c-3.1,1.3-6.5,1.9-10.2,1.9 c-4.3,0-8.2-0.9-11.6-2.8c-3.4-1.9-6-4.5-7.9-7.9c-1.9-3.4-2.8-7.3-2.8-11.6c0-4.3,0.9-8.2,2.8-11.6c1.9-3.4,4.5-6,7.8-7.9 c3.3-1.9,7.2-2.9,11.5-2.9C93.6,361.2,98.8,363.2,102.3,367.2z M100.5,380.6c-0.2-4-1.6-7.2-4-9.5c-2.5-2.3-5.7-3.5-9.7-3.5 c-3.9,0-7.1,1.2-9.7,3.5c-2.6,2.3-4.1,5.5-4.6,9.4H100.5z" />
            <Path className="st0" d="M132.1,363.7c2.7-1.6,5.9-2.5,9.5-2.6v7.5c-4.5-0.2-8.2,1-10.9,3.5c-2.8,2.5-4.4,5.9-4.8,10.1v23.3h-7.8 v-44.2h7.8v9.5C127.4,367.8,129.5,365.4,132.1,363.7z" />
            <Path className="st0" d="M185.7,364c3.2,1.9,5.7,4.5,7.5,7.9c1.8,3.4,2.7,7.3,2.7,11.7c0,4.3-0.9,8.2-2.6,11.6c-1.8,3.4-4.2,6-7.4,7.9 c-3.2,1.9-6.9,2.8-11,2.8c-3.7,0-7-0.8-9.9-2.3c-2.8-1.6-5.1-3.8-6.7-6.7v25h-7.8v-60.4h7.8v8.8c1.6-2.9,3.8-5.1,6.6-6.7 c2.8-1.6,6.1-2.3,9.7-2.3C178.8,361.2,182.5,362.1,185.7,364z M180.8,397.1c2.3-1.3,4-3.2,5.3-5.6c1.3-2.4,1.9-5.1,1.9-8.1 c0-3-0.6-5.6-1.9-8c-1.3-2.4-3-4.3-5.3-5.6c-2.2-1.3-4.8-2-7.6-2c-2.8,0-5.4,0.7-7.6,2c-2.3,1.3-4,3.2-5.3,5.6 c-1.3,2.4-1.9,5.1-1.9,8c0,3.1,0.6,5.8,1.9,8.1c1.3,2.4,3.1,4.2,5.3,5.6c2.3,1.3,4.8,2,7.6,2C176,399.1,178.6,398.5,180.8,397.1z" />
            <Path className="st0" d="M245.9,361.4v44.2H238v-9.4c-2.9,6.3-8.4,9.5-16.4,9.6c-5.1,0-9.1-1.5-12-4.6c-2.9-3-4.3-7.2-4.3-12.6v-27.3 h7.9v25.2c0,3.7,1,6.6,3.1,8.7c2,2.1,4.8,3.1,8.4,3.1c4.1-0.1,7.4-1.5,9.8-4.4c2.4-2.9,3.6-6.5,3.6-11v-21.7H245.9z" />
            <Path className="st0" d="M279.1,368.5c-2.2-0.7-4.3-1-6.3-1c-2.3,0-4.2,0.4-5.6,1.3c-1.4,0.9-2.1,2.2-2.1,4s0.8,3.1,2.4,4.1 c1.6,0.9,4,1.9,7.2,2.8c3.1,0.9,5.6,1.8,7.5,2.7c1.9,0.9,3.6,2.2,5,3.9c1.4,1.7,2,4,2,6.9c0,4.2-1.6,7.4-4.7,9.5 c-3.1,2.2-7.1,3.3-11.7,3.3c-3.3,0-6.5-0.5-9.6-1.6s-5.7-2.6-7.8-4.5l2.9-5.7c1.8,1.7,4.1,3,6.8,4c2.7,1,5.3,1.5,7.9,1.5 c2.6,0,4.6-0.5,6.2-1.4c1.6-0.9,2.3-2.4,2.3-4.3c0-2-0.8-3.5-2.5-4.5c-1.6-1-4.1-2-7.5-3c-3-0.8-5.4-1.7-7.2-2.5 c-1.9-0.9-3.5-2.1-4.8-3.8c-1.3-1.7-2-3.8-2-6.5c0-4.1,1.5-7.2,4.5-9.3c3-2.1,6.8-3.1,11.3-3.1c2.7,0,5.3,0.4,7.9,1.1 c2.6,0.8,4.8,1.8,6.7,3.1l-2.8,5.9C283.3,370.1,281.3,369.2,279.1,368.5z" />
            <Path className="st0" d="M323.2,402.7c-3.4,2.2-6.9,3.3-10.4,3.3c-3.5,0-6.4-1-8.5-3.1c-2.2-2.1-3.3-5.3-3.3-9.5v-24.5h-6.3v-5.9h6.3 v-11.9h7.9v11.9h13.5v5.9h-13.5v23.1c0,2.5,0.4,4.2,1.3,5.3c0.9,1.1,2.2,1.6,4,1.6c2.1,0,4.4-0.7,6.9-2.1L323.2,402.7z" />
            <Path className="st0" d="M359.6,405.6v-6.2c-3.3,4.3-8.4,6.5-15.5,6.5c-3.1,0-5.8-0.6-8.1-1.8c-2.3-1.2-4.1-2.8-5.3-4.8 c-1.3-2.1-1.9-4.4-1.9-6.9c0-4,1.5-7.1,4.4-9.4c2.9-2.3,7-3.5,12.2-3.6h14v-2.1c0-3.1-1-5.5-2.9-7.2c-1.9-1.7-4.7-2.6-8.4-2.6 c-4.5,0-9,1.6-13.6,4.8l-3.3-5.3c3.2-2.1,6.1-3.6,8.8-4.5c2.7-0.9,5.9-1.4,9.7-1.4c5.5,0,9.8,1.4,12.8,4.1c3,2.7,4.5,6.5,4.6,11.3 l0.1,29.1H359.6z M355,396.9c2.5-1.8,4.1-4.1,4.6-7v-4.4h-13c-3.5,0-6.1,0.5-7.8,1.6c-1.7,1.1-2.6,2.7-2.6,5s0.9,4.1,2.6,5.4 c1.7,1.3,4,2,6.9,2C349.4,399.5,352.5,398.7,355,396.9z" />
            <Path className="st0" d="M412.1,405.6L398,384.2l-8.6,8.9v12.5h-7.8v-62h7.8v39.5l21.4-21.7h9.1l-16.5,17.1l18.1,27.1H412.1z" />
            <Path className="st0" d="M454.6,405.6v-6.2c-3.3,4.3-8.4,6.5-15.5,6.5c-3.1,0-5.8-0.6-8.1-1.8c-2.3-1.2-4.1-2.8-5.3-4.8 s-1.9-4.4-1.9-6.9c0-4,1.5-7.1,4.4-9.4c2.9-2.3,7-3.5,12.2-3.6h14v-2.1c0-3.1-1-5.5-2.9-7.2c-1.9-1.7-4.7-2.6-8.4-2.6 c-4.5,0-9,1.6-13.6,4.8l-3.3-5.3c3.2-2.1,6.1-3.6,8.8-4.5c2.7-0.9,5.9-1.4,9.7-1.4c5.5,0,9.8,1.4,12.8,4.1c3,2.7,4.5,6.5,4.6,11.3 l0.1,29.1H454.6z M449.9,396.9c2.5-1.8,4.1-4.1,4.6-7v-4.4h-13c-3.5,0-6.1,0.5-7.8,1.6c-1.7,1.1-2.6,2.7-2.6,5s0.9,4.1,2.6,5.4 c1.7,1.3,4,2,6.9,2C444.3,399.5,447.4,398.7,449.9,396.9z" />
            <Path className="st0" d="M503.1,405.6v-6.2c-3.3,4.3-8.4,6.5-15.5,6.5c-3.1,0-5.8-0.6-8.1-1.8c-2.3-1.2-4.1-2.8-5.3-4.8 s-1.9-4.4-1.9-6.9c0-4,1.5-7.1,4.4-9.4c2.9-2.3,7-3.5,12.2-3.6h14v-2.1c0-3.1-1-5.5-2.9-7.2c-1.9-1.7-4.7-2.6-8.4-2.6 c-4.5,0-9,1.6-13.6,4.8l-3.3-5.3c3.2-2.1,6.1-3.6,8.8-4.5c2.7-0.9,5.9-1.4,9.7-1.4c5.5,0,9.8,1.4,12.8,4.1c3,2.7,4.5,6.5,4.6,11.3 l0.1,29.1H503.1z M498.5,396.9c2.5-1.8,4.1-4.1,4.6-7v-4.4h-13c-3.5,0-6.1,0.5-7.8,1.6c-1.7,1.1-2.6,2.7-2.6,5s0.9,4.1,2.6,5.4 c1.7,1.3,4,2,6.9,2C492.9,399.5,496,398.7,498.5,396.9z" />
            <Path className="st0" d="M561.5,365.7c3,3,4.4,7.2,4.4,12.6v27.3h-7.8v-25.2c0-3.7-1-6.6-3.1-8.7c-2.1-2.1-5-3.1-8.7-3.1 c-4,0.1-7.2,1.3-9.7,3.8c-2.5,2.5-3.9,5.7-4.2,9.7v23.7h-7.8v-44.2h7.8v9.4c3-6.3,8.6-9.5,16.8-9.6 C554.5,361.2,558.5,362.7,561.5,365.7z" />
            <Path className="st0" d="M602.4,347.1h9.5l21.4,41.1l21.2-41.1h9.6v58.5h-7.5l-0.1-46.7l-20.8,40.5h-4.9l-21-40.5v46.7h-7.4V347.1z" />
            <Path className="st0" d="M713.4,367.2c3.5,4,5.3,9.7,5.3,16.8c0,1.1,0,2-0.1,2.5h-34.9c0.6,4,2.3,7.1,5,9.4c2.7,2.3,6,3.5,10,3.5 c2.6,0,5.1-0.5,7.3-1.4c2.3-0.9,4.2-2.3,5.9-4l4.3,4.4c-2.2,2.3-4.8,4.2-7.9,5.4c-3.1,1.3-6.5,1.9-10.2,1.9 c-4.3,0-8.2-0.9-11.6-2.8s-6-4.5-7.9-7.9c-1.9-3.4-2.8-7.3-2.8-11.6c0-4.3,0.9-8.2,2.8-11.6c1.9-3.4,4.5-6,7.8-7.9 c3.3-1.9,7.2-2.9,11.5-2.9C704.7,361.2,709.9,363.2,713.4,367.2z M711.7,380.6c-0.2-4-1.6-7.2-4-9.5c-2.5-2.3-5.7-3.5-9.7-3.5 c-3.9,0-7.1,1.2-9.7,3.5c-2.6,2.3-4.1,5.5-4.6,9.4H711.7z" />
            <Path className="st0" d="M766.1,365.7c3,3,4.4,7.2,4.4,12.6v27.3h-7.8v-25.2c0-3.7-1-6.6-3.1-8.7c-2.1-2.1-5-3.1-8.7-3.1 c-4,0.1-7.2,1.3-9.7,3.8c-2.5,2.5-3.9,5.7-4.2,9.7v23.7h-7.8v-44.2h7.8v9.4c3-6.3,8.6-9.5,16.8-9.6 C759.1,361.2,763.1,362.7,766.1,365.7z" />
            <Path className="st0" d="M777.7,415.5c2.1,0.1,3.7-0.7,4.8-2.2c1.1-1.5,1.7-3.7,1.7-6.6v-45.3h7.9v46.4c0,4.6-1.1,8.1-3.3,10.6 s-5.3,3.7-9.3,3.7c-2.5,0-5-0.5-7.6-1.5l1.8-5.9C774.9,415.1,776.3,415.4,777.7,415.5z M791.5,345.2c0.9,0.9,1.4,2.1,1.4,3.6 c0,1.5-0.5,2.6-1.4,3.6c-0.9,0.9-2.1,1.4-3.5,1.4c-1.4,0-2.5-0.5-3.5-1.4c-0.9-0.9-1.4-2.1-1.4-3.6c0-1.4,0.5-2.6,1.4-3.6 c0.9-0.9,2.1-1.4,3.5-1.4C789.5,343.8,790.6,344.3,791.5,345.2z" />
            <Path className="st0" d="M833.4,405.6v-6.2c-3.3,4.3-8.4,6.5-15.5,6.5c-3.1,0-5.8-0.6-8.1-1.8c-2.3-1.2-4.1-2.8-5.3-4.8 c-1.3-2.1-1.9-4.4-1.9-6.9c0-4,1.5-7.1,4.4-9.4c2.9-2.3,7-3.5,12.2-3.6h14v-2.1c0-3.1-1-5.5-2.9-7.2c-1.9-1.7-4.7-2.6-8.4-2.6 c-4.5,0-9,1.6-13.6,4.8l-3.3-5.3c3.2-2.1,6.1-3.6,8.8-4.5c2.7-0.9,5.9-1.4,9.7-1.4c5.5,0,9.8,1.4,12.8,4.1c3,2.7,4.5,6.5,4.6,11.3 l0.1,29.1H833.4z M828.7,396.9c2.5-1.8,4.1-4.1,4.6-7v-4.4h-13c-3.5,0-6.1,0.5-7.8,1.6c-1.7,1.1-2.6,2.7-2.6,5s0.9,4.1,2.6,5.4 c1.7,1.3,4,2,6.9,2C823.1,399.5,826.2,398.7,828.7,396.9z" />
            <Path className="st0" d="M891.7,365.7c3,3,4.4,7.2,4.4,12.6v27.3h-7.8v-25.2c0-3.7-1-6.6-3.1-8.7c-2.1-2.1-5-3.1-8.7-3.1 c-4,0.1-7.2,1.3-9.7,3.8c-2.5,2.5-3.9,5.7-4.2,9.7v23.7h-7.8v-44.2h7.8v9.4c3-6.3,8.6-9.5,16.8-9.6 C884.7,361.2,888.7,362.7,891.7,365.7z" />
            <Path className="st0" d="M949.1,361.4v39.7c0,4.2-0.9,7.9-2.8,11.1c-1.9,3.2-4.5,5.6-7.9,7.3c-3.4,1.7-7.4,2.5-11.9,2.5 c-6.5,0-12.6-2.1-18.2-6.2l3.4-5.7c2.2,1.8,4.4,3.1,6.8,4c2.3,0.9,4.9,1.3,7.7,1.3c4.6,0,8.2-1.3,10.9-3.8c2.7-2.6,4.1-6,4.1-10.3 v-6.2c-1.6,2.7-3.7,4.7-6.4,6.2c-2.7,1.5-5.7,2.2-9.2,2.2c-4,0-7.5-0.9-10.5-2.7c-3-1.8-5.4-4.3-7.1-7.6 c-1.7-3.2-2.5-6.9-2.5-11.1c0-4.1,0.8-7.8,2.5-10.9c1.7-3.2,4-5.7,7.1-7.4c3-1.8,6.5-2.7,10.5-2.7c3.5,0,6.6,0.7,9.2,2.2 c2.6,1.4,4.8,3.5,6.3,6.2v-8.1H949.1z M934.5,395.4c2.1-1.3,3.8-3.1,5-5.3c1.2-2.3,1.8-4.8,1.8-7.6c0-2.9-0.6-5.5-1.8-7.7 c-1.2-2.3-2.9-4-5-5.3c-2.1-1.3-4.5-1.9-7.3-1.9s-5.2,0.6-7.3,1.9c-2.1,1.3-3.8,3.1-5.1,5.3c-1.2,2.3-1.8,4.8-1.8,7.7 c0.1,4.3,1.4,7.9,4,10.7c2.6,2.8,6,4.2,10.2,4.2C930,397.4,932.4,396.7,934.5,395.4z" />
            <Path className="st0" d="M994.3,405.6l-14.1-21.5l-8.6,8.9v12.5h-7.8v-62h7.8v39.5l21.4-21.7h9.1l-16.5,17.1l18.1,27.1H994.3z" />
            <Path className="st0" d="M1036.7,405.6v-6.2c-3.3,4.3-8.4,6.5-15.5,6.5c-3.1,0-5.8-0.6-8.1-1.8c-2.3-1.2-4.1-2.8-5.3-4.8 c-1.3-2.1-1.9-4.4-1.9-6.9c0-4,1.5-7.1,4.4-9.4c2.9-2.3,7-3.5,12.2-3.6h14v-2.1c0-3.1-1-5.5-2.9-7.2c-1.9-1.7-4.7-2.6-8.4-2.6 c-4.5,0-9,1.6-13.6,4.8l-3.3-5.3c3.2-2.1,6.1-3.6,8.8-4.5c2.7-0.9,5.9-1.4,9.7-1.4c5.5,0,9.8,1.4,12.8,4.1c3,2.7,4.5,6.5,4.6,11.3 l0.1,29.1H1036.7z M1032.1,396.9c2.5-1.8,4.1-4.1,4.6-7v-4.4h-13c-3.5,0-6.1,0.5-7.8,1.6c-1.7,1.1-2.6,2.7-2.6,5s0.9,4.1,2.6,5.4 c1.7,1.3,4,2,6.9,2C1026.5,399.5,1029.5,398.7,1032.1,396.9z" />
            <Path className="st0" d="M1097.4,361.4v44.2h-7.9v-9.4c-2.9,6.3-8.4,9.5-16.4,9.6c-5.1,0-9.1-1.5-12-4.6c-2.9-3-4.3-7.2-4.3-12.6 v-27.3h7.9v25.2c0,3.7,1,6.6,3.1,8.7c2,2.1,4.8,3.1,8.4,3.1c4.1-0.1,7.4-1.5,9.8-4.4c2.4-2.9,3.6-6.5,3.6-11v-21.7H1097.4z" />
            <Path className="st0" d="M1135.2,347.1h9.5l21.4,41.1l21.2-41.1h9.6v58.5h-7.5l-0.1-46.7l-20.8,40.5h-4.9l-21-40.5v46.7h-7.4V347.1z" />
            <Path className="st0" d="M1239.9,405.6v-6.2c-3.3,4.3-8.4,6.5-15.5,6.5c-3.1,0-5.8-0.6-8.1-1.8c-2.3-1.2-4.1-2.8-5.3-4.8 c-1.3-2.1-1.9-4.4-1.9-6.9c0-4,1.5-7.1,4.4-9.4c2.9-2.3,7-3.5,12.2-3.6h14v-2.1c0-3.1-1-5.5-2.9-7.2c-1.9-1.7-4.7-2.6-8.4-2.6 c-4.5,0-9,1.6-13.6,4.8l-3.3-5.3c3.2-2.1,6.1-3.6,8.8-4.5c2.7-0.9,5.9-1.4,9.7-1.4c5.5,0,9.8,1.4,12.8,4.1c3,2.7,4.5,6.5,4.6,11.3 l0.1,29.1H1239.9z M1235.3,396.9c2.5-1.8,4.1-4.1,4.6-7v-4.4h-13c-3.5,0-6.1,0.5-7.8,1.6c-1.7,1.1-2.6,2.7-2.6,5s0.9,4.1,2.6,5.4 c1.7,1.3,4,2,6.9,2C1229.6,399.5,1232.7,398.7,1235.3,396.9z" />
            <Path className="st0" d="M1279.6,368.5c-2.2-0.7-4.3-1-6.3-1c-2.3,0-4.2,0.4-5.6,1.3c-1.4,0.9-2.1,2.2-2.1,4s0.8,3.1,2.4,4.1 c1.6,0.9,4,1.9,7.2,2.8c3.1,0.9,5.6,1.8,7.5,2.7c1.9,0.9,3.6,2.2,5,3.9c1.4,1.7,2,4,2,6.9c0,4.2-1.6,7.4-4.7,9.5 c-3.1,2.2-7.1,3.3-11.7,3.3c-3.3,0-6.5-0.5-9.6-1.6c-3.1-1.1-5.7-2.6-7.8-4.5l2.9-5.7c1.8,1.7,4.1,3,6.8,4c2.7,1,5.3,1.5,7.9,1.5 c2.6,0,4.6-0.5,6.2-1.4c1.6-0.9,2.3-2.4,2.3-4.3c0-2-0.8-3.5-2.5-4.5c-1.6-1-4.1-2-7.5-3c-3-0.8-5.4-1.7-7.2-2.5 c-1.9-0.9-3.5-2.1-4.8-3.8c-1.3-1.7-2-3.8-2-6.5c0-4.1,1.5-7.2,4.5-9.3c3-2.1,6.8-3.1,11.3-3.1c2.7,0,5.3,0.4,7.9,1.1 c2.6,0.8,4.8,1.8,6.7,3.1l-2.8,5.9C1283.8,370.1,1281.8,369.2,1279.6,368.5z" />
            <Path className="st0" d="M1309.5,419.6c-2.3,1.7-5,2.6-8.1,2.6c-1.9,0-3.6-0.3-5.2-0.8c-1.6-0.6-3.1-1.4-4.5-2.6l3.3-6.1 c1.9,1.4,3.9,2.1,5.9,2.1c3.3,0,5.9-1.9,7.5-5.7l1.7-3.9l-18.3-43.7h8.2l14,35.4l13.3-35.4h7.9l-20.2,50.5 C1313.6,415.3,1311.8,417.9,1309.5,419.6z" />
            <Path className="st0" d="M1368.4,405.6v-6.2c-3.3,4.3-8.4,6.5-15.5,6.5c-3.1,0-5.8-0.6-8.1-1.8c-2.3-1.2-4.1-2.8-5.3-4.8 c-1.3-2.1-1.9-4.4-1.9-6.9c0-4,1.5-7.1,4.4-9.4c2.9-2.3,7-3.5,12.2-3.6h14v-2.1c0-3.1-1-5.5-2.9-7.2c-1.9-1.7-4.7-2.6-8.4-2.6 c-4.5,0-9,1.6-13.6,4.8l-3.3-5.3c3.2-2.1,6.1-3.6,8.8-4.5c2.7-0.9,5.9-1.4,9.7-1.4c5.5,0,9.8,1.4,12.8,4.1c3,2.7,4.5,6.5,4.6,11.3 l0.1,29.1H1368.4z M1363.7,396.9c2.5-1.8,4.1-4.1,4.6-7v-4.4h-13c-3.5,0-6.1,0.5-7.8,1.6c-1.7,1.1-2.6,2.7-2.6,5s0.9,4.1,2.6,5.4 c1.7,1.3,4,2,6.9,2C1358.1,399.5,1361.2,398.7,1363.7,396.9z" />
            <Path className="st0" d="M1403.9,363.7c2.7-1.6,5.9-2.5,9.5-2.6v7.5c-4.5-0.2-8.2,1-10.9,3.5c-2.8,2.5-4.4,5.9-4.8,10.1v23.3h-7.8 v-44.2h7.8v9.5C1399.1,367.8,1401.2,365.4,1403.9,363.7z" />
            <Path className="st0" d="M1446.9,405.6v-6.2c-3.3,4.3-8.4,6.5-15.5,6.5c-3.1,0-5.8-0.6-8.1-1.8c-2.3-1.2-4.1-2.8-5.3-4.8 c-1.3-2.1-1.9-4.4-1.9-6.9c0-4,1.5-7.1,4.4-9.4c2.9-2.3,7-3.5,12.2-3.6h14v-2.1c0-3.1-1-5.5-2.9-7.2c-1.9-1.7-4.7-2.6-8.4-2.6 c-4.5,0-9,1.6-13.6,4.8l-3.3-5.3c3.2-2.1,6.1-3.6,8.8-4.5c2.7-0.9,5.9-1.4,9.7-1.4c5.5,0,9.8,1.4,12.8,4.1c3,2.7,4.5,6.5,4.6,11.3 l0.1,29.1H1446.9z M1442.3,396.9c2.5-1.8,4.1-4.1,4.6-7v-4.4h-13c-3.5,0-6.1,0.5-7.8,1.6c-1.7,1.1-2.6,2.7-2.6,5s0.9,4.1,2.6,5.4 c1.7,1.3,4,2,6.9,2C1436.7,399.5,1439.7,398.7,1442.3,396.9z" />
            <Path className="st0" d="M1499.4,405.6l-14.1-21.5l-8.6,8.9v12.5h-7.8v-62h7.8v39.5l21.4-21.7h9.1l-16.5,17.1l18.1,27.1H1499.4z" />
            <Path className="st0" d="M1541.9,405.6v-6.2c-3.3,4.3-8.4,6.5-15.5,6.5c-3.1,0-5.8-0.6-8.1-1.8c-2.3-1.2-4.1-2.8-5.3-4.8 c-1.3-2.1-1.9-4.4-1.9-6.9c0-4,1.5-7.1,4.4-9.4c2.9-2.3,7-3.5,12.2-3.6h14v-2.1c0-3.1-1-5.5-2.9-7.2c-1.9-1.7-4.7-2.6-8.4-2.6 c-4.5,0-9,1.6-13.6,4.8l-3.3-5.3c3.2-2.1,6.1-3.6,8.8-4.5c2.7-0.9,5.9-1.4,9.7-1.4c5.5,0,9.8,1.4,12.8,4.1c3,2.7,4.5,6.5,4.6,11.3 l0.1,29.1H1541.9z M1537.2,396.9c2.5-1.8,4.1-4.1,4.6-7v-4.4h-13c-3.5,0-6.1,0.5-7.8,1.6c-1.7,1.1-2.6,2.7-2.6,5s0.9,4.1,2.6,5.4 c1.7,1.3,4,2,6.9,2C1531.6,399.5,1534.7,398.7,1537.2,396.9z" />
            <Path className="st0" d="M1587.2,402.7c-3.4,2.2-6.9,3.3-10.4,3.3c-3.5,0-6.4-1-8.5-3.1c-2.2-2.1-3.3-5.3-3.3-9.5v-24.5h-6.3v-5.9h6.3 v-11.9h7.9v11.9h13.5v5.9h-13.5v23.1c0,2.5,0.4,4.2,1.3,5.3c0.9,1.1,2.2,1.6,4,1.6c2.1,0,4.4-0.7,6.9-2.1L1587.2,402.7z" />
          </G>
          <G>
            <G>
              <Path className="st0" d="M148.2,99.2c11.9,8.4,17.9,19.7,17.9,34.1c0,9.9-2.9,18.6-8.7,26c-5.8,7.4-13.6,12.4-23.6,15.1 c12.1,2.3,21.6,7.7,28.5,16.1c6.9,8.4,10.4,18.8,10.4,31c0,16-6.3,28.6-18.9,37.8c-12.6,9.2-29.8,13.9-51.4,13.9H13.7V86.7h85.8 C120.1,86.7,136.3,90.9,148.2,99.2z M116.1,156.3c4.5-3.6,6.8-8.7,6.8-15.3c0-6.4-2.3-11.4-6.8-14.9c-4.5-3.6-10.8-5.2-18.8-5.1 H55.8v40.8h41.6C105.3,161.8,111.6,160,116.1,156.3z M120.8,232.8c5.7-4,8.5-9.5,8.5-16.7c0-6.7-2.8-12.1-8.5-16 c-5.7-3.9-13.5-5.8-23.4-5.6H55.8v44.2h41.6C107.3,238.8,115.1,236.8,120.8,232.8z" />
              <Path className="st0" d="M202.6,129.8h41v143.3h-41V129.8z" />
              <Path className="st0" d="M411.8,143.2c9.5,9.9,14.3,23.3,14.3,40v90h-41v-78.1c0-9.1-2.5-16.2-7.6-21.4c-5.1-5.2-11.9-7.9-20.6-7.9 c-10.1,0.2-18.1,3.7-24,10.5c-5.9,6.8-8.8,15.6-8.8,26.2v70.6h-41V129.8h41v25c9.9-17.4,26.5-26.3,49.6-26.6 C389.6,128.2,402.3,133.2,411.8,143.2z" />
              <Path className="st0" d="M550.2,265.2c-5.3,3.2-11.2,5.6-17.7,7.3c-6.5,1.7-13,2.5-19.6,2.5c-13.3,0-24.2-3.8-32.5-11.5 c-8.4-7.6-12.5-18.6-12.5-32.8v-66.3h-20v-28.2h20V96.8h40.8v39.4h38.9v28.5h-38.9v59.1c0,11,4.3,16.5,13.1,16.5 c6,0,12.7-1.8,20-5.3L550.2,265.2z" />
              <Path className="st0" d="M658.1,273.2v-16.8c-4.8,6-11,10.7-18.7,13.9c-7.6,3.2-16.4,4.8-26.4,4.8c-15.1,0-27.1-4.1-36-12.4 c-8.9-8.3-13.3-19-13.3-32.4c0-13.5,4.8-24,14.3-31.6c9.5-7.5,23-11.4,40.4-11.6h39.4v-2.7c0-7.6-2.5-13.5-7.6-17.7 c-5.1-4.2-12.5-6.3-22.2-6.3c-13.3,0-27.5,4.2-42.6,12.5l-12.8-28.2c11.4-5.7,22-9.9,32-12.5c9.9-2.7,20.9-4,32.8-4 c19.2,0,34.1,4.5,44.6,13.6c10.6,9.1,15.9,21.7,16.1,37.8l0.3,93.5H658.1z M646.7,239.6c6.2-4.1,9.9-9.2,11.2-15.5v-12.8h-32.2 c-8,0-13.9,1.3-17.7,3.9c-3.8,2.6-5.7,6.7-5.7,12.4c0,5.5,2,9.9,6,13.2c4,3.3,9.5,4.9,16.4,4.9 C633.1,245.7,640.5,243.7,646.7,239.6z" />
              <Path className="st0" d="M862.6,143.2c9.5,9.9,14.3,23.3,14.3,40v90h-41v-78.1c0-9.1-2.5-16.2-7.6-21.4c-5.1-5.2-11.9-7.9-20.6-7.9 c-10.1,0.2-18.1,3.7-24,10.5c-5.9,6.8-8.8,15.6-8.8,26.2v70.6h-41V129.8h41v25c9.9-17.4,26.5-26.3,49.6-26.6 C840.4,128.2,853.1,133.2,862.6,143.2z" />
              <Path className="st0" d="M1046.6,129.8v130c0,13.5-3.2,25.3-9.6,35.3c-6.4,10-15.4,17.8-26.9,23.2c-11.5,5.4-24.9,8.1-40,8.1 c-11.7,0-22.9-1.7-33.4-5.1c-10.6-3.4-20.2-8-28.9-13.9l15.2-28.8c13.5,9.6,28.3,14.4,44.5,14.4c11.7,0,21-2.9,28-8.7 c6.9-5.8,10.4-13.5,10.4-23.3v-16.3c-4.8,6.8-10.8,11.9-18.1,15.5c-7.3,3.6-15.6,5.3-25,5.3c-12.3,0-23.1-2.9-32.6-8.7 c-9.5-5.8-16.9-13.9-22.1-24.4c-5.2-10.5-7.9-22.6-7.9-36.2c0-13.5,2.6-25.4,7.9-35.6c5.2-10.2,12.6-18.2,22.1-23.8 c9.5-5.7,20.4-8.5,32.6-8.5c9.2,0,17.5,1.8,24.8,5.5c7.3,3.6,13.4,8.7,18.4,15.3v-19.4H1046.6z M996.5,224.3 c6.2-6.8,9.3-15.8,9.3-26.8c0-11-3.1-20-9.3-26.9c-6.2-6.9-14.3-10.4-24.2-10.4c-9.9,0-18,3.5-24.2,10.4 c-6.2,6.9-9.4,15.9-9.6,26.9c0.2,11,3.3,19.9,9.5,26.8c6.1,6.8,14.3,10.3,24.4,10.3C982.2,234.5,990.2,231.1,996.5,224.3z" />
              <Path className="st0" d="M1219.2,103.6c13,11.3,19.4,27.2,19.4,47.8c0,21.7-6.5,38.5-19.6,50.5c-13.1,12-31.4,18-55,18h-37v53.3 h-42.1V86.7h79.1C1187.8,86.7,1206.2,92.3,1219.2,103.6z M1189.2,176.6c6.3-5.4,9.5-13.4,9.5-23.8c0-10.3-3.2-18.1-9.5-23.3 c-6.3-5.2-15.4-7.9-27.3-7.9H1127v63.1h34.9C1173.8,184.7,1182.9,182,1189.2,176.6z" />
              <Path className="st0" d="M1400.9,129.8v143.3h-40.8v-25.3c-9.8,17.9-25.8,26.9-48,26.9c-15.8,0-28.4-4.9-37.7-14.8 c-9.3-9.9-14-23.2-14-40.1v-90h41v78.3c0,9.1,2.4,16.2,7.2,21.3c4.8,5.2,11.4,7.7,19.7,7.7c9.8-0.2,17.5-3.6,23.2-10.4 c5.7-6.7,8.5-15.5,8.5-26.1v-70.9H1400.9z" />
              <Path className="st0" d="M1507.7,161.7c-7.6-2.4-14.5-3.6-20.5-3.6c-5,0-9,0.9-12,2.7c-3,1.8-4.5,4.4-4.5,8c0,3.9,2,6.9,6.1,9.1 c4.1,2.1,10.7,4.5,20,7.2c10.1,3,18.5,6,25,8.9c6.6,2.9,12.3,7.3,17,13.1c4.8,5.8,7.2,13.4,7.2,22.8c0,14.2-5.5,25.3-16.4,33.2 c-10.9,7.9-24.9,11.9-42,11.9c-11.7,0-23-1.7-33.8-5.2c-10.8-3.5-20.2-8.4-28.2-14.8l13.3-27.7c7.3,5.7,15.4,10.1,24.4,13.2 c9,3.1,17.4,4.7,25.4,4.7c5.5,0,9.9-0.9,13.2-2.8c3.3-1.9,4.9-4.7,4.9-8.4c0-4.1-2.1-7.3-6.3-9.6c-4.2-2.3-10.9-4.8-20.1-7.5 c-9.8-2.8-17.9-5.7-24.4-8.5c-6.5-2.8-12-7.1-16.7-12.9c-4.6-5.8-6.9-13.3-6.9-22.5c0-14.2,5.3-25.2,16-33 c10.7-7.8,24.2-11.7,40.5-11.7c9.8,0,19.5,1.4,29.3,4.1c9.8,2.8,18.6,6.7,26.6,11.9l-14.1,28 C1523.1,167.5,1515.3,164.1,1507.7,161.7z" />
              <Path className="st0" d="M1702.3,143.2c9.5,9.9,14.3,23.3,14.3,40v90h-41v-78.1c0-9.1-2.5-16.2-7.6-21.4c-5.1-5.2-11.9-7.9-20.6-7.9 c-10.1,0.2-18.1,3.7-24,10.5c-5.9,6.8-8.8,15.6-8.8,26.2v70.6h-41V129.8h41v25c9.9-17.4,26.5-26.3,49.6-26.6 C1680.1,128.2,1692.8,133.2,1702.3,143.2z" />
              <Path className="st0" d="M1835.1,273.2v-16.8c-4.8,6-11,10.7-18.6,13.9c-7.6,3.2-16.4,4.8-26.4,4.8c-15.1,0-27.1-4.1-36-12.4 c-8.9-8.3-13.3-19-13.3-32.4c0-13.5,4.8-24,14.3-31.6c9.5-7.5,23-11.4,40.4-11.6h39.4v-2.7c0-7.6-2.5-13.5-7.6-17.7 c-5.1-4.2-12.5-6.3-22.2-6.3c-13.3,0-27.5,4.2-42.6,12.5l-12.8-28.2c11.4-5.7,22-9.9,32-12.5c9.9-2.7,20.9-4,32.8-4 c19.2,0,34.1,4.5,44.6,13.6c10.6,9.1,15.9,21.7,16.1,37.8l0.3,93.5H1835.1z M1823.6,239.6c6.2-4.1,9.9-9.2,11.2-15.5v-12.8h-32.2 c-8,0-13.9,1.3-17.7,3.9c-3.8,2.6-5.7,6.7-5.7,12.4c0,5.5,2,9.9,6,13.2c4,3.3,9.5,4.9,16.4,4.9 C1810.1,245.7,1817.4,243.7,1823.6,239.6z" />
              <Path className="st0" d="M1977.9,161.7c-7.6-2.4-14.5-3.6-20.5-3.6c-5,0-9,0.9-12,2.7c-3,1.8-4.5,4.4-4.5,8c0,3.9,2,6.9,6.1,9.1 c4.1,2.1,10.7,4.5,20,7.2c10.1,3,18.5,6,25,8.9c6.6,2.9,12.3,7.3,17,13.1c4.8,5.8,7.2,13.4,7.2,22.8c0,14.2-5.5,25.3-16.4,33.2 c-10.9,7.9-24.9,11.9-42,11.9c-11.7,0-23-1.7-33.8-5.2c-10.8-3.5-20.2-8.4-28.2-14.8l13.3-27.7c7.3,5.7,15.4,10.1,24.4,13.2 c9,3.1,17.4,4.7,25.4,4.7c5.5,0,9.9-0.9,13.2-2.8c3.3-1.9,4.9-4.7,4.9-8.4c0-4.1-2.1-7.3-6.3-9.6c-4.2-2.3-10.9-4.8-20.1-7.5 c-9.8-2.8-17.9-5.7-24.4-8.5c-6.5-2.8-12-7.1-16.7-12.9c-4.6-5.8-6.9-13.3-6.9-22.5c0-14.2,5.3-25.2,16-33 c10.7-7.8,24.2-11.7,40.5-11.7c9.8,0,19.5,1.4,29.3,4.1c9.8,2.8,18.6,6.7,26.6,11.9l-14.1,28 C1993.3,167.5,1985.5,164.1,1977.9,161.7z" />
            </G>
            <G>
              <G id="_x23_169b48ff_16_">
                <Path className="st0" d="M223,7.3c1.8,2.8,3.5,5.6,5.3,8.3c3.2,5.1,6.6,10.2,9.3,15.6c1.7,3.6,3.1,7.3,3.5,11.3 c0.3,2.7,0.2,5.4-0.2,8l0.3-0.1c-0.7,0.5-1.1,1.2-1.6,1.9c-5.1,7-10.3,14-15.4,21c-0.4-2-0.9-3.9-1.8-5.7 c-1.5-3.1-3.6-5.9-5.7-8.5c-2.5-3-5-6-7.4-9.1C213.8,35.7,218.4,21.5,223,7.3z" />
              </G>
              <G id="_x23_11743bff_16_">
                <Path className="st0" d="M241.2,50.4c4.3-1.9,9.1-2.7,13.8-2.6c5.9,0.1,11.6,1.4,17.3,2.6c5.1,1.2,10.3,2.2,15.5,3.2 c-12.4,9-24.7,17.9-37.1,26.8c-3.5-1.2-7-2.7-10.5-4c-5.1-2-10.6-3.7-16.1-3.1l0.1-0.1c5.2-7,10.3-14,15.4-21 C240.1,51.7,240.5,50.9,241.2,50.4z" />
              </G>
              <G id="_x23_215facff_16_">
                <Path className="st0" d="M158.2,52.4c11.1,0,22.1,0,33.2,0c4.6,0,9.2,0.1,13.8,0c1.7,2.1,3.4,4.2,5.1,6.3c2,2.4,4.1,4.9,5.8,7.5 c1.6,2.4,2.9,5,3.4,7.8c0.3,1.6,0.2,3.3,0.3,5c0,0.4-0.2,0.6-0.4,0.9c-6.9,9.4-13.8,18.8-20.6,28.2c-4.9,6.7-9.8,13.4-14.6,20.1 c-0.2,0.3-0.4,0.6-0.7,0.9c0.1-0.6,0.3-1.1,0.5-1.6c5-15.2,10-30.4,14.9-45.6c-10.6-7.6-21.2-15.3-31.7-22.9 C164.2,56.7,161.2,54.6,158.2,52.4z" />
              </G>
              <G id="_x23_184999ff_16_">
                <Path className="st0" d="M219.9,79c2-0.5,3.9-1,5.9-1.1c3.5-0.2,7,0.8,10.4,1.9c4.6,1.6,9.1,3.7,13.8,5.2 c4.8,14.8,9.7,29.6,14.5,44.4c-2.5-1.8-5-3.6-7.5-5.4c-11-8.1-22.1-16.1-33.1-24.1c-13.5,9.9-27.1,19.7-40.6,29.6 c0-0.2,0.1-0.4,0.1-0.6l0.1,0.1c0.3-0.2,0.5-0.6,0.7-0.9c4.9-6.7,9.8-13.4,14.6-20.1c6.9-9.4,13.7-18.8,20.6-28.2 C219.7,79.6,219.9,79.4,219.9,79z" />
              </G>
            </G>
          </G>
        </G>
      </Svg>
        )
    }
}

export default HeaderLogo
