import React, { Component } from 'react';
import { View, StyleSheet, FlatList, Image, Pressable } from 'react-native';
import {
    Alert, AlertTitle, Card, CardActionArea, CardContent, Typography, Skeleton,
    CardActions, Button, IconButton,
    Box, Tabs, Tab, TabPanel,
    Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import { code_ok, code_empty, code_error, code_auth_error, borrowingList, bookReturn, readBorrowedContent, stockCheck, borrow } from '../constants/api';
import { color_text, sub_text } from '../constants/colors';
import * as Identifier from '../constants/PageIdentifiers';
import * as Command from '../constants/Commands';
import { executeGet, executePost } from '../utilities/Utilities';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LoadingDialog from '../components/LoadingDialog';
var _is_mounted = false;

class DesktopBookRack extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            showLoadingDialog: false,
            loadingDialogMessage: "",
            showAlert: false,
            moreLoading: false,
            loadingMessage: "",
            alertMessage: "",
            data: [],
            dataHistory: [],
            dummy_data: [
                { i: 0 }, { i: 1 }, { i: 2 }, { i: 3 }, { i: 4 }, { i: 5 }, { i: 6 }, { i: 7 }
            ],
            valueTab: 0,
            contentCodeTemp: '',
            showBorrowDialog: false,
            showBorrowSuccess: false,
            successMessage: "",
            showConfirmReturn: false,
            itemReturn: "",
            indexReturn: ""
        }

        this.goBack = this.goBack.bind(this);
        this.renderSkeleton = this.renderSkeleton.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.fetchContents = this.fetchContents.bind(this);
        this.handleImageError = this.handleImageError.bind(this);
        this.executeReturn = this.executeReturn.bind(this);
        this.handleReadFromRack = this.handleReadFromRack.bind(this);
        this.readFromRack = this.readFromRack.bind(this);

        this.handleBorrowClick = this.handleBorrowClick.bind(this);
        this.handleBorrowConfirmationClose = this.handleBorrowConfirmationClose.bind(this);
        this.handleConfirmationClick = this.handleConfirmationClick.bind(this);

        this.executeLoan = this.executeLoan.bind(this);
        this.confirmReturn = this.confirmReturn.bind(this);
    }

    componentDidMount = () => {
        _is_mounted = true;
        //JSBridge.launchFunction(Command.SET_PAGE, Identifier.BOOK_RACK);
        window.addEventListener("message", (event) => {
            if (event.data.id == "go_back") {
                if (event.data.page == Identifier.BOOK_RACK) {
                    this.goBack();
                }
            }
        });
        this.fetchContents();

    }
    componentWillUnmount = () => {
        _is_mounted = false;
        window.removeEventListener("message", null);
    }

    //ANCHOR - Borrow Click
    handleBorrowClick = (item) => {
        if (_is_mounted) {
            this.setState({
                contentCodeTemp: item.content_code,
                showBorrowDialog: true
            })
        }
    }
    //ANCHOR - Borrow Confirmation Close
    handleBorrowConfirmationClose = () => {
        if (_is_mounted) {
            this.setState({
                showBorrowDialog: false
            })
        }
    }
    //ANCHOR - Handle Confirmation Click 
    handleConfirmationClick = () => {
        if (_is_mounted) {
            this.setState({
                showBorrowDialog: false,
                showLoadingDialog: true,
                loadingDialogMessage: "memproses permintaan"
            }, () => {
                this.executeLoan();
            })
        }
    }
    //ANCHOR - Execute Loan
    executeLoan = async () => {
        let formData = new FormData();
        formData.append("content_code", this.state.contentCodeTemp);
        const stockResponse = await executePost(stockCheck, formData);
        if (stockResponse.code == code_ok) {
            if (stockResponse.stok > 0) {
                const response = await executePost(borrow, formData);
                if (response.code == code_ok) {
                    this.setState({
                        showLoadingDialog: false,
                        successMessage: response.msg,
                        showBorrowSuccess: true
                    })
                } else {
                    if (response.code != code_auth_error) {
                        this.setState({
                            showLoadingDialog: false,
                            showAlert: true,
                            alertMessage: response.msg
                        })
                    } else {
                        window.postMessage({ id: "sign_out" })
                    }
                }
            } else {
                this.setState({
                    showLoadingDialog: false,
                    showAlert: true,
                    alertMessage: "Stok kosong, silahkan coba beberapa waktu kemudian"
                })
            }
        } else {
            this.setState({
                showLoadingDialog: false,
                showAlert: true,
                alertMessage: "Stok buku ini kosong"
            })
        }
    }

    renderSkeleton = ({ item, index, separators }) => {
        return (
            <View style={{
                flexDirection: "row",
                alignItems: "center"
            }}>

                <Skeleton
                    variant="rectangular"
                    style={{
                        width: 90,
                        height: 130,
                        borderRadius: 8,
                        marginLeft: 16
                    }}
                />
                <View style={{ flex: 1, marginLeft: 16, marginRight: 16 }}>
                    <Skeleton
                        variant="text"
                        sx={{ fontSize: "2rem" }}
                        animation="wave"
                        style={{ marginBottom: 8, borderRadius: 8 }}
                    />
                    <Skeleton
                        variant="rectangular"
                        sx={{ height: 90 }}
                        style={{ marginBottom: 8, borderRadius: 8 }}
                    />

                </View>
            </View>
        )
    }

    goBack = () => {
        //JSBridge.launchFunction(Command.SET_PAGE, this.props.route.params.identifier );
        this.props.navigation.goBack();
        //this.props.navigation.navigate(this.props.route.params.identifier);
    }
    renderItem = ({ item, index, separators }) => {
        return (
            <Card
                style={{
                    borderRadius: 8,
                    marginLeft: 0,
                    marginRight: (index % 2 == 0 ? 16 : 0),
                    flex: 1,
                    //marginTop : (index == 0 ? 0 : 8 ), 
                    marginBottom: 16,
                    boxShadow: "0 4px 6px -1px rgba(0,0,0, 0.07)"
                }}
            >
                <CardContent>
                    <View style={{ flexDirection: "row", padding: 0 }}>
                        <Image
                            style={{
                                width: 90,
                                height: 130,
                                borderRadius: 8
                            }}
                            source={{ uri: item.cover }}
                            onError={(error) => { this.handleImageError(item, index) }}
                        />
                        <View style={{ flex: 1, marginLeft: 8, marginRight: 8 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={14}
                                color={color_text}
                                fontWeight={"600"}
                            >
                                {item.title}
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={14}
                                color={sub_text}
                                style={{ marginTop: 8 }}
                            >
                                {item.author}
                            </Typography>

                        </View>
                    </View>
                </CardContent>
                <CardActions>
                    <Button size="small" onClick={() => { this.handleReadFromRack(item) }}>Baca</Button>
                    <Button size="small" onClick={() => { this.confirmReturn(item, index) }}>Kembalikan</Button>
                </CardActions>

            </Card>
        )
    }

    renderItemHistory = ({ item, index, separators }) => {
        return (
            <Card
                style={{
                    borderRadius: 8,
                    marginLeft: 0,
                    marginRight: (index % 2 == 0 ? 16 : 0),
                    flex: 1,
                    //marginTop : (index == 0 ? 0 : 8 ), 
                    marginBottom: 16,
                    boxShadow: "0 4px 6px -1px rgba(0,0,0, 0.07)"
                }}
            >
                <CardContent>
                    <View style={{ flexDirection: "row", padding: 0 }}>
                        <Image
                            style={{
                                width: 90,
                                height: 130,
                                borderRadius: 8
                            }}
                            source={{ uri: item.cover }}
                            onError={(error) => { this.handleImageError(item, index) }}
                        />
                        <View style={{ flex: 1, marginLeft: 8, marginRight: 8 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={14}
                                color={color_text}
                                fontWeight={"600"}
                            >
                                {item.title}
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={14}
                                color={sub_text}
                                style={{ marginTop: 8 }}
                            >
                                {item.author}
                            </Typography>

                        </View>
                    </View>
                </CardContent>
                <CardActions>
                    <Button size="small" onClick={() => { this.handleBorrowClick(item) }}>Pinjam Lagi</Button>
                </CardActions>

            </Card>
        )
    }

    fetchContents = async () => {
        const response = await executeGet(borrowingList);
        console.log("rak buku - " + JSON.stringify(response));
        if (response.code == code_ok) {
            this.setState({
                showLoading: false,
                data: response.data,
                dataHistory: response.data_history
            })


        } else {
            if (response.code == code_auth_error) {
                if (global.os == "ANDROID") {
                    JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                } else {
                    window.webkit.messageHandlers.JSBridge.postMessage({ id: Command.SHOW_TOAST, params: "Autentikasi gagal! Harap login ulang!" })
                }
                window.postMessage({ id: "sign_out" });
            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }
        }
    }

    handleImageError = (item, index) => {
        let data = [...this.state.data];
        let obj = { ...data[index] };
        obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/no-cover.png";
        data[index] = obj;
        this.setState({
            data: data
        })
    }

    confirmReturn = (item, index) => {
        if (_is_mounted) {
            this.setState({
                itemReturn: item,
                indexReturn: index,
                showConfirmReturn: true
            })
        }
    }

    handleConfirmCloseReturn = () => {
        if (_is_mounted) {
            this.setState({
                showConfirmReturn: false
            })
        }
    }

    executeReturn = async () => {
        this.setState({
            showConfirmReturn: false,
            showLoadingDialog: true,
            loadingDialogMessage: "Loading..."
        }, async () => {

            let formData = new FormData();
            formData.append("content_code", this.state.itemReturn.content_code);

            const response = await executePost(bookReturn, formData);
            if (response.code == code_ok) {
                let data = [...this.state.data];
                data.splice(this.state.indexReturn, 1);
                this.setState({
                    data: data,
                    showLoadingDialog: false
                })
                this.fetchContents()

            } else {
                if (response.code == code_auth_error) {
                    if (global.os == "ANDROID") {
                        JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                    } else {
                        window.webkit.messageHandlers.JSBridge.postMessage({ id: Command.SHOW_TOAST, params: "Autentikasi gagal! Harap login ulang!" })
                    }
                    window.postMessage({ id: "sign_out" });
                } else {
                    this.setState({
                        showLoading: false,
                        showAlert: true,
                        alertMessage: response.msg
                    })
                }
            }
        })

    }

    handleReadFromRack = (item) => {
        let content_code = item.content_code;
        this.readFromRack(content_code);
    }

    readFromRack = async (code) => {
        let formData = new FormData();
        formData.append("code", code);

        this.setState({
            showLoadingDialog: true,
            loadingDialogMessage: "Memeriksa Peminjaman"
        })

        const response = await executePost(readBorrowedContent, formData);
        //console.log(JSON.stringify(response));
        if (response.code == code_ok) {
            this.setState({
                showLoadingDialog: false
            })
            let data = response.data;
            data.mode = "borrow";
            //data.mode = "read";
            this.props.navigation.navigate("ReaderDesktop", data);

        } else {
            if (response.code == code_empty) {
                this.setState({
                    showAlert: true,
                    showLoadingDialog: false,
                    alertMessage: response.msg
                })
            } else {
                //TODO - Force Logout 
                window.postMessage({ id: "sign_out" })
            }
        }
    }

    a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    handleChange = (newValue) => {
        this.setState({
            valueTab: newValue
        })
    };

    render() {
        return (
            <View style={{ flex: 1 }}>
                <View style={{
                    paddingTop: 16,
                    paddingBottom: 16,
                    marginBottom: 16,
                    flex: 0.05,
                    backgroundColor: "white",
                    flexDirection: "row",
                    alignItems: "center",
                    boxShadow: "0 4px 6px -1px rgba(0,0,0, 0.07)"

                }}>
                    <IconButton
                        style={{ marginRight: 16, marginLeft: 16 }}
                        onClick={() => { this.goBack() }}
                    >
                        <ChevronLeftIcon
                            sx={{ color: color_text }}
                        />
                    </IconButton>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={18}
                        fontWeight={"600"}
                    >
                        Rak Pinjam
                    </Typography>
                </View>
                <View style={{ flex: 0.95 }}>
                    {
                        this.state.showLoading &&
                        <FlatList
                            keyExtractor={(i, x) => "index_" + x}
                            data={this.state.dummy_data}
                            renderItem={this.renderSkeleton}
                            showsVerticalScrollIndicator={false}
                        />
                    }
                    {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={this.state.valueTab} onChange={() => this.handleChange()} aria-label="basic tabs example">
                            <Tab label="Item One" {...this.a11yProps(0)} />
                            <Tab label="Item Two" {...this.a11yProps(1)} />
                            <Tab label="Item Three" {...this.a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={this.state.valueTab} index={0}>
                        Item One
                    </TabPanel>
                    <TabPanel value={this.state.valueTab} index={1}>
                        Item Two
                    </TabPanel>
                    <TabPanel value={this.state.valueTab} index={2}>
                        Item Three
                    </TabPanel> */}
                    {
                        !this.state.showLoading &&
                        <View>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={18}
                                fontWeight={"600"}
                                style={{ marginTop: 16, marginLeft: 16, marginBottom: 16 }}
                            >
                                Konten Digital Yang Dipinjam Saat Ini :
                            </Typography>
                            {
                                this.state.data.length > 0 ?
                                    <FlatList
                                        keyExtractor={(item, index) => "rec_" + item.content_code}
                                        data={this.state.data}
                                        renderItem={this.renderItem}
                                        numColumns={2}
                                        showsVerticalScrollIndicator={false}
                                        style={{ marginLeft: 16, marginRight: 16 }}
                                    />
                                    :
                                    <View style={{ justifyContent: "center", alignItems: "center" }}>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={18}
                                            margin={5}
                                        >
                                            Ini adalah rak konten digital anda, tetapi anda sampai saat ini belum meminjam konten digital.
                                        </Typography>
                                    </View>
                            }

                            <Typography
                                fontFamily="Open Sans"
                                fontSize={18}
                                fontWeight={"600"}
                                style={{ marginTop: 16, marginLeft: 16, marginBottom: 16 }}
                            >
                                Konten Digital Yang Pernah Di Pinjam :
                            </Typography>
                            {
                                this.state.dataHistory.length > 0 ?
                                    <FlatList
                                        keyExtractor={(item, index) => "rec_" + item.content_code}
                                        data={this.state.dataHistory}
                                        renderItem={this.renderItemHistory}
                                        numColumns={2}
                                        showsVerticalScrollIndicator={false}
                                        style={{ marginLeft: 16, marginRight: 16 }}
                                    />
                                    :
                                    <View style={{ justifyContent: "center", alignItems: "center" }}>
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={18}
                                            margin={5}
                                        >
                                            Belum ada history konten digital yang dipinjam
                                        </Typography>
                                    </View>
                            }
                        </View>


                    }
                </View>
                {
                    this.state.showAlert &&
                    <Alert
                        onClose={() => { this.goBack() }}
                        severity="error"
                    >
                        <AlertTitle>Error</AlertTitle>
                        {this.state.alertMessage}
                    </Alert>
                }
                {
                    //ANCHOR - Borrow Confirmation Dialog
                }
                <Dialog
                    open={this.state.showBorrowDialog}
                    onClose={this.handleBorrowConfirmationClose}
                >
                    <DialogTitle>
                        Konfirmasi Peminjaman
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Proses peminjaman akan dilakukan , hal ini akan mengurangi stok yang dimiliki oleh perpustakaan ini. Lanjutkan?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleBorrowConfirmationClose}>Batalkan</Button>
                        <Button onClick={this.handleConfirmationClick}>Lanjutkan</Button>
                    </DialogActions>
                </Dialog>

                <LoadingDialog
                    open={this.state.showLoadingDialog}
                    message={this.state.loadingDialogMessage}
                />

                <Dialog
                    open={this.state.showConfirmReturn}
                    onClose={this.handleConfirmCloseReturn}
                >
                    <DialogTitle>
                        Konfirmasi
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Yakin ingin mengembalikan buku.{"\r\n"}Lanjutkan?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleConfirmCloseReturn}>Batal</Button>
                        <Button onClick={this.executeReturn}>Lanjutkan</Button>
                    </DialogActions>

                </Dialog>

            </View>
        )
    }
}

export default DesktopBookRack;