import React, { Component } from 'react';
import { View, StyleSheet, Image, FlatList, Dimensions, ScrollView, Pressable, Share, SafeAreaView } from 'react-native';
import {
    Alert, AlertTitle, Typography, Skeleton, Card, CardActionArea, CircularProgress,
    IconButton, Chip, List, ListItemButton, ListItemIcon,
    ListItemText, Collapse, Paper, Button, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, Rating
} from '@mui/material';
import LoadingPage from '../components/LoadingPage';
import AlertDialog from '../components/AlertDialog';
import * as Identifier from '../constants/PageIdentifiers';
import * as Command from '../constants/Commands';
import {
    code_ok, code_empty, code_error, code_auth_error, categories, categoryContent, refreshToken,
    contentDetail, stockCheck, contentRate, allowInputRate, borrow, queue, sendRate, readContent, readLocalContent, checkLembaga
} from '../constants/api';
import { executeGet, executePost, isEven, truncateText } from '../utilities/Utilities';
import EmptySearchPage from '../components/EmptySearchPage';
import { color_text, sub_text, primary } from '../constants/colors';
import { IconComment, IconPlay, IconRate, IconShare, IconStar, IconStock, IconStorage, IconBookmark, IconFolderAdd } from '../assets/Icons';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LoadingDialog from '../components/LoadingDialog';
import WarningDialog from '../components/WarningDialog';
import moment from 'moment';

var _is_mounted = false;
var first_render = true;
class ContentDetail extends Component {
    //ANCHOR - Constructor
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            loadingMessage: "",
            showAlert: false,
            alertMessage: "",
            data: {},
            rateLoading: true,
            stockLoading: true,
            stock: 0,
            sizeLoading: false,
            synopsisDimensions: {
                width: 0,
                height: 0
            },
            collapseSynopsis: true,
            total: "~",
            rate_value: 0,
            total_rate: 0,
            showAlertRate: false,
            alertRateMessage: "",
            showBorrowDialog: false,
            showBorrowSuccess: false,
            showRatingBox: false,
            rateValue: 0,
            showLoadingDialog: false,
            loadingDialogMessage: "",
            localContent: false,
            fileSize: "--",
            status_pinjam: false,
            kontenLokal: true,
            playlist: [],
            email: localStorage.getItem('email')
        }
        this.goBack = this.goBack.bind(this);
        this.fetchDetails = this.fetchDetails.bind(this);
        this.fetchStock = this.fetchStock.bind(this);
        this.fetchRate = this.fetchRate.bind(this);
        this.checkAllowRate = this.checkAllowRate.bind(this);

        this.scrollRef = React.createRef();
        this.onLayoutSynopsis = this.onLayoutSynopsis.bind(this);
        this.expandMore = this.expandMore.bind(this);
        this.onContentSizeChange = this.onContentSizeChange.bind(this);

        this.onRateButtonClick = this.onRateButtonClick.bind(this);
        this.onCommentButtonClick = this.onCommentButtonClick.bind(this);

        this.checkBeforeRead = this.checkBeforeRead.bind(this);
        this.checkBeforePlay = this.checkBeforePlay.bind(this);
        this.checkBeforeBorrow = this.checkBeforeBorrow.bind(this);
        this.checkBeforeAntrian = this.checkBeforeAntrian.bind(this);

        this.handleReadClick = this.handleReadClick.bind(this);
        this.executeReadContent = this.executeReadContent.bind(this);
        this.handleBorrowClick = this.handleBorrowClick.bind(this);
        this.handleBorrowConfirmationClose = this.handleBorrowConfirmationClose.bind(this);
        this.handleConfirmationClick = this.handleConfirmationClick.bind(this);
        this.handleRatingBoxClose = this.handleRatingBoxClose.bind(this);

        this.executeLoan = this.executeLoan.bind(this);
        this.onRateValueChange = this.onRateValueChange.bind(this);
        this.handleSendRate = this.handleSendRate.bind(this);
        this.postRate = this.postRate.bind(this);

        this.handleRateInfoClick = this.handleRateInfoClick.bind(this);
        this.handleStockInfoClick = this.handleStockInfoClick.bind(this);
        this.handleSizeInfoClick = this.handleSizeInfoClick.bind(this);
        this.handleAntrianClick - this.handleAntrianClick.bind(this);
        this.onShareClick = this.onShareClick.bind(this);
    }

    componentDidMount = () => {

        console.log("PARAMS--------");
        console.log(this.props.route.params);
        _is_mounted = true;
        if (global.os == "ANDROID") {
            JSBridge.launchFunction(Command.SET_PAGE, Identifier.CONTENT_DETAIL);
        }
        window.addEventListener("message", (event) => {
            if (event.data.id == "go_back") {
                this.goBack();
            }
        })
        this.fetchDetails();

        // if(global.os == "IOS"){
        //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Kode Konten: " + this.props.route.params.content_code})
        // }
    }

    componentWillUnmount = () => {
        _is_mounted = false;
        window.removeEventListener("message", null);
    }
    //ANCHOR - Go Back 
    goBack = () => {
        //let previous = this.props.route.params.previous;
        //let params = this.props.route.params.parameters;
        //this.props.navigation.navigate(previous,  params );
        // if (global.contentId != null) {
        //     history.back();
        // } else {
        //     this.props.navigation.goBack();
        // }
        this.props.navigation.goBack();

    }
    //ANCHOR - Fetch Details 
    fetchDetails = async () => {
        // console.log("fetch content detail = " + this.props.route.params.content_code);
        // console.log("homepage_code = " + this.props.route.params.homepage_code);
        first_render = true;
        let formData = new FormData();
        formData.append("code", this.props.route.params.content_code)
        formData.append("homepage_code", this.props.route.params.homepage_code)
        const response = await executePost(contentDetail, formData);
        //console.log(response);
        // console.log("detail = " + JSON.stringify(response));

        if (response.code == code_ok) {
            // console.log("detail playlist= " + JSON.stringify(response.playlist));
            if (_is_mounted) {

                if (response.data.file_konten_lokal == "-") {
                    this.setState({
                        showLoading: false,
                        data: response.data,
                        playlist: response.playlist,
                        stockLoading: true,
                        kontenLokal: false,
                        status_pinjam: response.status_pinjam
                    }, () => {

                        this.fetchStock();
                        this.fetchRate();

                    })
                } else {
                    this.setState({
                        showLoading: false,
                        kontenLokal: true,
                        data: response.data,
                        playlist: response.playlist,
                        rateLoading: false,
                        stockLoading: false,
                        localContent: true
                    });
                }
            }


        } else {
            console.log('error disini')
            if (response.code == code_auth_error) {
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }
        }
    }
    //ANCHOR - Fetch Stock
    fetchStock = async () => {
        let formData = new FormData();
        formData.append("content_code", this.props.route.params.content_code);
        formData.append("homepage_code", this.props.route.params.homepage_code)

        const response = await executePost(stockCheck, formData);
        console.log(JSON.stringify("respone stock = " + JSON.stringify(response)));
        if (response.code == code_ok) {
            if (_is_mounted) {
                this.setState({
                    stockLoading: false,
                    total: response.total,
                    stock: response.stok
                })
            }
        } else {
            console.log('error fetch disini')
            // this.setState({
            //     showLoading: false,
            //     showAlert: true,
            //     alertMessage: response.msg
            // })
            if (response.code == code_auth_error) {
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }
        }

    }
    //ANCHOR - Fetch Rate
    fetchRate = async () => {
        let url = contentRate + this.props.route.params.content_code;
        const response = await executeGet(url);
        //console.log("fetch rate response"); 
        //console.log(JSON.stringify(response));
        if (response.code == code_ok) {
            this.setState({
                rateLoading: false,
                rate_value: response.rate,
                total_rate: response.total
            })
        }
    }
    //ANCHOR - On Image Error
    onImageError = () => {
        let data = { ...this.state.data };
        data.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
        this.setState({
            data: data
        })
    }

    onLayoutSynopsis = (event) => {
        if (_is_mounted && first_render) {
            first_render = false;
            let { width, height } = event.nativeEvent.layout;
            if (height > 90) {
                //console.log("On Layout Synopsis"); 
                this.scrollRef.current.scrollTo({ y: 0, animated: true });
                this.setState({
                    collapseSynopsis: false,
                    synopsisDimensions: {
                        width: width,
                        height: height
                    }
                })
            } else {
                this.setState({
                    collapseSynopsis: true,
                    synopsisDimensions: {
                        width: width,
                        height: height
                    }

                })
            }
        }
    }

    expandMore = () => {
        if (_is_mounted) {
            if (this.state.collapseSynopsis) {
                //console.log("TRUE");
                this.setState({
                    collapseSynopsis: false
                }, () => {
                    setTimeout(() => {
                        this.scrollRef.current.scrollTo({ y: 0, animated: true });
                    }, 500);

                })
            } else {
                //console.log("FALSE");
                this.setState({
                    collapseSynopsis: true
                }, () => {
                    setTimeout(() => {
                        this.scrollRef.current.scrollToEnd({ animated: true })
                    }, 500);

                })
            }
        }
    }
    onContentSizeChange = () => {
        if (!first_render) {
            //console.log("Content Size Change");
            this.scrollRef.current.scrollToEnd({ animated: false });
        }
    }

    onRateButtonClick = () => {
        this.checkAllowRate();
    }
    onCommentButtonClick = () => {
        this.props.navigation.navigate("CommentPage", this.props.route.params);
    }

    checkAllowRate = async () => {
        let url = allowInputRate + this.props.route.params.content_code;
        const response = await executeGet(url);
        if (response.code == code_ok) {

            if (response.status) {
                if (_is_mounted) {
                    this.setState({
                        showRatingBox: true
                    })
                }

            } else {
                if (_is_mounted) {
                    this.setState({
                        showAlertRate: true,
                        alertRateMessage: response.msg
                    })
                }
            }
        } else {
            if (_is_mounted) {
                this.setState({
                    showAlert: true,
                    alertMessage: response.msg
                })
            }
        }
    }

    //ANCHOR - Check to borrow book
    checkBeforeBorrow = async () => {
        // let formData = new FormData(); 
        // formData.append("code", this.props.route.params.content_code); 
        // this.executeReadContent(formData); 

        if (_is_mounted) {
            // this.setState({
            //     showLoadingDialog : true,
            //     loadingDialogMessage : "Autentikasi"
            // });
            let url = checkLembaga;
            let response = await executeGet(url);
            console.log("checkLembaga = " + JSON.stringify(response))
            // code == 500 -> page verifikasi
            if (response.code == code_ok) {
                this.handleBorrowClick()
            } else {
                // this.setState({
                //     showLoadingDialog : false,
                //     showAlert : true, 
                //     alertMessage : response.msg
                // })
                this.setState({
                    showLoadingDialog: false,
                }, () => {
                    let data = {
                        action: 'pinjam'
                    };
                    this.props.navigation.navigate("VerificationUser", data);
                });
            }
        }
    }

    handleAntrianClick = async () => {
        let formData = new FormData();
        formData.append("content_code", this.props.route.params.content_code);
        const response = await executePost(queue, formData);
        console.log(JSON.stringify(response));
        if (response.code == code_ok) {
            this.setState({
                showLoading: false,
                alertMessage: response.msg,
                showBorrowSuccess: true
            })
        } else {
            this.setState({
                showLoading: false,
                showAlert: true,
                alertMessage: response.msg
            })
        }
    }

    handleBorrowClick = () => {
        if (_is_mounted) {
            this.setState({
                showBorrowDialog: true
            })
        }
    }

    handleBorrowConfirmationClose = () => {
        if (_is_mounted) {
            this.setState({
                showBorrowDialog: false
            })
        }
    }
    handleConfirmationClick = () => {
        if (_is_mounted) {
            this.setState({
                showBorrowDialog: false,
                showLoading: true
            }, () => {
                this.executeLoan();
            })
        }
    }

    executeLoan = async () => {
        //console.log("Execute Loan"); 
        let formData = new FormData();
        formData.append("content_code", this.props.route.params.content_code);
        const stockResponse = await executePost(stockCheck, formData);
        //console.log(JSON.stringify(stockResponse)); 
        if (stockResponse.code == code_ok) {
            if (stockResponse.stok > 0) {
                // JSBridge.launchFunction(Command.SAVE_DETAIL_BOOK, JSON.stringify(this.state.data));
                const response = await executePost(borrow, formData);
                console.log("executeLoan = " + JSON.stringify(response));
                if (response.code == code_ok) {
                    //sent to JSBridge data detail
                    let d = new Date(new Date().setDate(new Date().getDate() + 7));
                    let tgl_balik = moment(d).format("YYYY-MM-DD HH:mm:ss");
                    this.state.data["tgl_balik"] = tgl_balik;
                    console.log('tgl_balik = ' + JSON.stringify(this.state.data));

                    if (global.os == "ANDROID") {
                        let d = new Date(new Date().setDate(new Date().getDate() + 7));
                        let tgl_balik = moment(d).format("YYYY-MM-DD HH:mm:ss");
                        this.state.data["tgl_balik"] = tgl_balik;
                        JSBridge.launchFunction(Command.SAVE_DETAIL_BOOK, JSON.stringify(this.state.data));
                    }
                    this.setState({
                        showLoading: false,
                        alertMessage: response.msg,
                        showBorrowSuccess: true
                    })
                } else {
                    this.setState({
                        showLoading: false,
                        showAlert: true,
                        alertMessage: response.msg
                    })
                }
            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: "Stok kosong, silahkan coba beberapa waktu kemudian"
                })

            }
        } else {
            this.setState({
                showLoading: false,
                showAlert: true,
                alertMessage: "Stok buku ini kosong"
            })
        }
    }
    handleRatingBoxClose = () => {
        this.setState({
            showRatingBox: false
        })
    }
    onRateValueChange = (event, value) => {
        this.setState({
            rateValue: value
        })
    }
    handleSendRate = () => {
        if (this.state.rateValue > 0) {
            this.postRate();
        }
    }

    postRate = async () => {
        let formData = new FormData();
        formData.append("content_code", this.props.route.params.content_code)
        formData.append("value", this.state.rateValue)

        this.setState({
            showRatingBox: false,
            rateLoading: true,
        })

        const response = await executePost(sendRate, formData);
        //console.log(JSON.stringify(response)); 
        if (response.code == code_ok) {
            this.fetchRate();
        } else {
            if (response.code != code_auth_error) {
                this.setState({
                    rateLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            } else {
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out" });
            }
        }
    }

    handleRateInfoClick = () => {
        // if(global.os == "ANDROID"){
        //     JSBridge.launchFunction(Command.SHOW_TOAST, "Nilai rata-rata dari semua pengguna yang membuka konten ini");
        // } else {
        //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_INFO, params : "Nilai rata-rata dari semua pengguna yang membuka konten ini"})
        // }
    }
    handleStockInfoClick = () => {
        // if(global.os == "ANDROID"){
        //     JSBridge.launchFunction(Command.SHOW_TOAST, "Stok konten yang tersedia");
        // } else {
        //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_INFO, params : "Stok konten yang tersedia"})
        // }
    }
    handleSizeInfoClick = () => {
        // if(global.os == "ANDROID"){
        //     JSBridge.launchFunction(Command.SHOW_TOAST, "Ukuran kuota dalam MB (MegaByte) yang anda butuhkan untuk membuka konten ini");
        // } else {
        //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_INFO, params : "Ukuran kuota dalam MB (MegaByte) yang anda butuhkan untuk membuka konten ini"})
        // }
    }

    handlePlayClick = async (item, index, type_play) => {
        console.log('item handlePlayClick = ' + JSON.stringify(item))
        console.log('isi play = ' + JSON.stringify(this.state.data))
        console.log('isi play 2 = ' + this.state.data.kode_ebook)

        if (this.state.data.kode_ebook == "") {
            this.props.navigation.navigate("PlayerMobile", this.state.data);
        } else {
            if (_is_mounted) {
                let formData = new FormData();
                formData.append("code", this.state.data.kode_ebook);

                this.setState({
                    showLoadingDialog: true,
                    loadingDialogMessage: "Autentikasi"
                });
                let url = (this.state.localContent ? readLocalContent : readContent);
                let response = await executePost(url, formData);
                console.log('respone play = ' + JSON.stringify(response))
                if (response.code == code_ok) {
                    this.setState({
                        showLoadingDialog: false,
                    }, () => {
                        console.log('respone playlist = ' + JSON.stringify(this.state.playlist))
                        let data = response.data;
                        data.mode = 'read';
                        data.local = this.state.localContent;
                        data.nama_file = item.multimedia_file;
                        data.type = this.state.data.type;
                        data.playlist = this.state.playlist;
                        data.first_page = item.halaman_dari;
                        data.last_page = item.halaman_sampai;
                        data.play_index = index;
                        data.play_type = type_play;
                        data.track
                        this.props.navigation.navigate("ReaderPlayerMobile", data);
                    });
                } else {
                    this.setState({
                        showLoadingDialog: false,
                        showAlert: true,
                        alertMessage: response.msg
                    })
                }
            }
        }

        // this.props.navigation.navigate("PlayerMobile", this.state.data); 
    }

    checkBeforePlay = async (item, index, type_play) => {
        // let formData = new FormData(); 
        // formData.append("code", this.props.route.params.content_code); 
        // this.executeReadContent(formData); 
        console.log('item checkBeforePlay = ' + JSON.stringify(item))
        console.log('item checkBeforePlay = ' + index)
        if (_is_mounted) {
            // this.setState({
            //     showLoadingDialog : true,
            //     loadingDialogMessage : "Autentikasi"
            // });
            let url = checkLembaga;
            let response = await executeGet(url);
            console.log("checkLembaga = " + JSON.stringify(response))
            // code == 500 -> page verifikasi
            if (response.code == code_ok) {
                this.handlePlayClick(item, index, type_play)
            } else {
                // this.setState({
                //     showLoadingDialog : false,
                //     showAlert : true, 
                //     alertMessage : response.msg
                // })
                this.setState({
                    showLoadingDialog: false,
                }, () => {
                    let data = {
                        action: 'play',
                        content_code: this.props.route.params.content_code,
                        localContent: this.state.localContent
                    };
                    this.props.navigation.navigate("VerificationUser", data);
                });
            }
        }
    }

    checkBeforeAntrian = async () => {
        // let formData = new FormData(); 
        // formData.append("code", this.props.route.params.content_code); 
        // this.executeReadContent(formData); 

        if (_is_mounted) {
            // this.setState({
            //     showLoadingDialog : true,
            //     loadingDialogMessage : "Autentikasi"
            // });
            let url = checkLembaga;
            let response = await executeGet(url);
            console.log("checkLembaga = " + JSON.stringify(response))
            // code == 500 -> page verifikasi
            if (response.code == code_ok) {
                this.handleAntrianClick()
            } else {
                // this.setState({
                //     showLoadingDialog : false,
                //     showAlert : true, 
                //     alertMessage : response.msg
                // })
                this.setState({
                    showLoadingDialog: false,
                }, () => {
                    let data = {
                        action: 'pinjam'
                    };
                    this.props.navigation.navigate("VerificationUserDesktop", data);
                });
            }
        }
    }

    //ANCHOR - Check to read book
    checkBeforeRead = async () => {
        // let formData = new FormData(); 
        // formData.append("code", this.props.route.params.content_code); 
        // this.executeReadContent(formData); 

        if (_is_mounted) {
            // this.setState({
            //     showLoadingDialog : true,
            //     loadingDialogMessage : "Autentikasi"
            // });
            let url = checkLembaga;
            let response = await executeGet(url);
            console.log("checkLembaga = " + JSON.stringify(response))
            // code == 500 -> page verifikasi
            if (response.code == code_ok) {
                this.handleReadClick()
            } else if (response.code == code_auth_error) {
                window.postMessage({ id: "sign_out" });
            } else {
                // this.setState({
                //     showLoadingDialog : false,
                //     showAlert : true, 
                //     alertMessage : response.msg
                // })
                this.setState({
                    showLoadingDialog: false,
                }, () => {
                    let data = {
                        action: 'baca',
                        content_code: this.props.route.params.content_code,
                        localContent: this.state.localContent
                    };
                    this.props.navigation.navigate("VerificationUser", data);
                });
            }
        }
    }

    //ANCHOR - Handle Read Click 
    handleReadClick = () => {
        let formData = new FormData();
        formData.append("code", this.props.route.params.content_code);
        this.executeReadContent(formData);
    }

    //ANCHOR - Execute Read Content
    executeReadContent = async (formData) => {
        console.log('localContent 1 = ' + this.state.localContent);
        console.log('localContent 2 = ' + this.props.route.params.content_code);
        if (_is_mounted) {
            this.setState({
                showLoadingDialog: true,
                loadingDialogMessage: "Autentikasi"
            });
            let url = (this.state.localContent ? readLocalContent : readContent);
            let response = await executePost(url, formData);
            if (response.code == code_ok) {
                this.setState({
                    showLoadingDialog: false,
                }, async () => {
                    // JSBridge.launchFunction(Command.DISABLE_SCREEN, "Disable Screen");
                    // JSBridge.launchFunction(Command.SHOW_TOAST, "Disable Screen");

                    let data = response.data;
                    data.mode = 'read';
                    data.local = this.state.localContent;
                    data.main_content = this.state.data.nama_file;
                    localStorage.setItem('readerData', JSON.stringify(data));

                    console.log('reader data = ' + JSON.stringify(data))
                    console.log('reader token = ' + global.token)
                    // console.log('url reader = ' + "https://bintangpusnas.perpusnas.go.id/web/?os=ANDROID&platform=mobile&reader=yes&token=" + global.token)
                    // window.location.href = "https://dev.bintangpusnas.com/?os=android&platform=mobile&reader=yes"
                    if (global.os == "ANDROID" || global.os == "android") {
                        let data = {
                            url: "https://bintangpusnas.perpusnas.go.id/web/?os=ANDROID&platform=mobile&reader=yes&token=" + global.token + "&uid=" + global.defaultHeaders.UID,
                            localContent: this.state.localContent,
                            code: this.props.route.params.content_code,
                            main_content: this.state.data.nama_file
                        }
                        JSBridge.launchFunction(Command.DISABLE_SCREEN, JSON.stringify(data));
                        // JSBridge.launchFunction(Command.DISABLE_SCREEN, "https://bintangpusnas.perpusnas.go.id/web/?os=ANDROID&platform=mobile&reader=yes&token=" + global.token + "&uid=" + global.defaultHeaders.UID);

                        // let response = await executeGet(refreshToken);
                        // console.log('refreshToken = ' + JSON.stringify(response))
                        // if (response.code == code_ok) {
                        // updateAuthHeader(response)
                        // this.checkBeforeEdukasi(response.client_token);
                        // let newToken = response.client_token;
                        // let hostname = location.hostname;
                        // console.log('hostname terbaru = ' + hostname)
                        // JSBridge.launchFunction(Command.DISABLE_SCREEN, "https://bintangpusnas.perpusnas.go.id/web/?os=iosedu&platform=mobile&reader=yes&token=" + global.token + "&uid=" + global.defaultHeaders.UID);
                        // if (hostname == "dev.bintangpusnas.com") {
                        //     console.log('hostname dev ' + hostname + ' - ' + newToken)
                        //     JSBridge.launchFunction(Command.DISABLE_SCREEN, "https://dev.bintangpusnas.com/?os=ANDROID&platform=mobile&reader=yes&token=" + newToken);
                        // } else if (hostname == "pwa.bintangpusnas.com") {
                        //     console.log('hostname pwa ' + hostname + ' - ' + newToken)
                        //     JSBridge.launchFunction(Command.DISABLE_SCREEN, "https://pwa.bintangpusnas.com/?os=ANDROID&platform=mobile&reader=yes&token=" + newToken);
                        // } else {
                        //     console.log('hostname superapp ' + hostname)
                        //     JSBridge.launchFunction(Command.DISABLE_SCREEN, "https://bintangpusnas.perpusnas.go.id/web/?os=ANDROID&platform=mobile&reader=yes&token=" + newToken);
                        // }
                        // }

                        // let hostname = location.hostname;
                        // console.log('hostname terbaru = ' + hostname)
                        // if (hostname == "dev.bintangpusnas.com") {
                        //     console.log('hostname dev ' + hostname + ' - ' + newToken)
                        //     JSBridge.launchFunction(Command.DISABLE_SCREEN, "https://dev.bintangpusnas.com/?os=ANDROID&platform=mobile&reader=yes");
                        // } else if (hostname == "pwa.bintangpusnas.com") {
                        //     console.log('hostname pwa ' + hostname + ' - ' + newToken)
                        //     JSBridge.launchFunction(Command.DISABLE_SCREEN, "https://pwa.bintangpusnas.com/?os=ANDROID&platform=mobile&reader=yes&token=" + global.token);
                        // } else {
                        //     console.log('hostname superapp ' + hostname)
                        //     JSBridge.launchFunction(Command.DISABLE_SCREEN, "https://bintangpusnas.perpusnas.go.id/web/?os=ANDROID&platform=mobile&reader=yes&token=" + global.token);
                        // }
                    } else if (global.os == "darwin") {
                        // window.location.href = "https://bintangpusnas.perpusnas.go.id/web/?os=iosedu&platform=mobile&reader=yes&token=" + global.token + "&uid=" + global.defaultHeaders.UID;
                        window.location.href = "http://localhost:19006/?os=darwin&platform=mobile&reader=yes&token=" + global.token + "&uid=" + global.defaultHeaders.UID + "&uuid=" + global.defaultHeaders.UUID;
                    } else {
                        this.props.navigation.navigate("ReaderMobile", data);
                        // if(global.os == "darwin") {
                        //     this.props.navigation.navigate("ReaderMobile", data);
                        // } else {
                        //     console.log('Headers Get= ' + JSON.stringify(global.defaultHeaders))
                        //     window.location.href = "http://localhost:19006/?os=iosedu&platform=mobile&reader=yes&token=" + global.token + "&uid=" + global.defaultHeaders.UID;
                        // }

                        // let response = await executeGet(refreshToken);
                        // console.log('refreshToken = ' + JSON.stringify(response))
                        // if (response.code == code_ok) {
                        //     window.location.href = "http://localhost:19006/?os=iosedu&platform=mobile&reader=yes&token=" + response.client_token + "&uid=" + global.defaultHeaders.UID;
                        // // window.location.href = "https://bintangpusnas.perpusnas.go.id/web/?os=ANDROID&platform=mobile&reader=yes&token=" + global.token;
                        // }
                    }
                    // JSBridge.launchFunction(Command.DISABLE_SCREEN, "https://dev.bintangpusnas.com/?os=android&platform=mobile&reader=yes");
                    // this.props.navigation.navigate("ReaderMobile", data);
                });
            } else {
                this.setState({
                    showLoadingDialog: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }
        }
    }

    onShareClick = async () => {
        try {
            let data = { ...this.state.data };
            console.log('onShareClick = ' + JSON.stringify(data))
            data.message = "Baca buku ini di Aplikasi BintangPusnas\n" + "Judul Buku : " + data.title + "\nhttps://bintangpusnas.com/";
            if (global.appAccess == undefined) {
                //TODO - Share for PWA 
                console.log('share PWA')
                const title = document.title;
                const text = "Silahkan buka link di atas.";
                const url = "https://bintangpusnas.com/";
                try {
                    await navigator
                        .share({
                            title,
                            url,
                            text
                        })

                    /*
                      Show a message if the user shares something
                    */
                    //alert(`Thanks for Sharing!`);
                } catch (err) {
                    /*
                       This error will appear if the user cancels the action of sharing.
                     */
                    alert(`Couldn't share ${err}`);
                }
            } else {

                JSBridge.launchFunction(Command.SHARE_BOOK, JSON.stringify(data));
            }

        } catch (error) {

        }

    }

    renderItem = ({ item, index, separators }) => {
        return (
            <>
                {
                    item.judul != '' &&
                    <View
                        style={{
                            width: '100%',
                            borderColor: '#d9d9d9',
                            borderWidth: 1,
                            borderRadius: 10,
                            marginTop: '4px',
                            marginBottom: '4px',
                            paddingTop: '12px',
                            paddingBottom: '12px',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                            flexDirection: "row"
                        }}
                        onClick={() => { this.checkBeforePlay(item, index, 'single') }}
                    >
                        <View style={{ flex: 0.9 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={14}
                                color={color_text}
                                fontWeight={"600"}
                            >
                                {truncateText(item.judul, 32)}
                            </Typography>
                        </View>
                        <View style={{ flex: 0.1, alignItems: 'center' }}>
                            <IconPlay />
                        </View>
                    </View>
                }

                {
                    item.sub_judul != '' &&
                    <View
                        style={{
                            borderColor: '#d9d9d9',
                            borderWidth: 1,
                            borderRadius: 10,
                            marginTop: '4px',
                            marginBottom: '4px',
                            paddingTop: '12px',
                            paddingBottom: '12px',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                            marginLeft: '24px',
                            flexDirection: "row"
                        }}
                        onClick={() => { this.checkBeforePlay(item, index, 'single') }}
                    >
                        <View style={{ flex: 0.9 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={14}
                                color={color_text}
                                fontWeight={"600"}
                            >
                                {truncateText(item.sub_judul, 28)}
                            </Typography>
                        </View>
                        <View style={{ flex: 0.1, alignItems: 'center' }}>
                            <IconPlay />
                        </View>
                    </View>
                }
                {
                    item.sub_judul_2 != '' &&
                    <View
                        style={{
                            borderColor: '#d9d9d9',
                            borderWidth: 1,
                            borderRadius: 10,
                            marginTop: '4px',
                            marginBottom: '4px',
                            paddingTop: '12px',
                            paddingBottom: '12px',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                            marginLeft: '48px',
                            flexDirection: "row"
                        }}
                        onClick={() => { this.checkBeforePlay(item, index, 'single') }}
                    >
                        <View style={{ flex: 0.9 }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={14}
                                color={color_text}
                                fontWeight={"600"}
                            >
                                {truncateText(item.sub_judul_2, 28)}
                            </Typography>
                        </View>
                        <View style={{ flex: 0.1, alignItems: 'center' }}>
                            <IconPlay />
                        </View>
                    </View>
                }
            </>

            // <Card
            //     style={{
            //         width: '100%',
            //         borderRadius: 8,
            //         marginTop: '10px',
            //         marginBottom: '10px'
            //     }}
            // >   
            //     <CardActionArea
            //         sx={{ height: "100%" }}
            //         onClick={() => { this.checkBeforePlay(item, index) }}
            //     >
            //         <View style={{ flexDirection: "row", padding: 12, alignItems: 'center' }}>

            //             <View style={{ flex: 1 }}>
            //                 {
            //                     item.judul != '' &&
            //                     <Typography
            //                         fontFamily="Open Sans"
            //                         fontSize={14}
            //                         color={color_text}
            //                         fontWeight={"600"}
            //                     >
            //                         {truncateText(item.judul, 32)}
            //                     </Typography>
            //                 }
            //                 {
            //                     item.sub_judul != '' &&
            //                     <Typography
            //                         fontFamily="Open Sans"
            //                         fontSize={14}
            //                         color={color_text}
            //                         fontWeight={"600"}
            //                     >
            //                         {truncateText(item.sub_judul, 32)}
            //                     </Typography>
            //                 }
            //                 {
            //                     item.sub_judul_2 != '' &&
            //                     <Typography
            //                         fontFamily="Open Sans"
            //                         fontSize={14}
            //                         color={color_text}
            //                         fontWeight={"600"}
            //                     >
            //                         {truncateText(item.sub_judul_2, 32)}
            //                     </Typography>
            //                 }


            //             </View>
            //         </View>
            //     </CardActionArea>
            // </Card>

        )
    }

    render() {
        return (
            <SafeAreaView style={{ flex: 1, backgroundColor: primary }}>
                {/* primary */}
                <View style={{
                    height: 56,
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: "white",
                    padding: 12,
                    // marginTop: (global.os == "IOS" ? 48 : 0)
                }}>
                    <IconButton
                        onClick={() => { this.goBack() }}
                    >
                        <ChevronLeftIcon
                            sx={{ color: "black" }}
                        />
                    </IconButton>
                    <Typography
                        fontFamily="Open Sans"
                        fontSize={16}
                        color="black"
                        fontWeight={"500"}
                        style={{ marginLeft: 15 }}
                    >
                        Detail Konten
                    </Typography>
                </View>
                {   //Skeleton 
                    this.state.showLoading &&
                    <ScrollView style={{
                        backgroundColor: "white",
                        flex: 1
                    }}
                        showsVerticalScrollIndicator={false}
                    >
                        <View style={{
                            backgroundColor: primary,
                            justifyContent: "center",
                            alignItems: "center",
                            paddingBottom: 48

                        }}>
                            <Skeleton
                                variant="rectangular"
                                width={160}
                                height={200}
                                animation="wave"
                                style={{ marginTop: 16, borderRadius: 8 }}
                            />
                            <Skeleton
                                variant="text"
                                animation="wave"
                                sx={{ fontSize: "1.5rem" }}
                                width={"80%"}
                                style={{ marginTop: 8 }}
                            />
                            <Skeleton
                                variant="text"
                                animation="wave"
                                sx={{ fontSize: "1.5rem" }}
                                width={"60%"}
                            />
                            <View style={{ width: "80%", justifyContent: "center", flexDirection: "row", marginTop: 8 }}>
                                <Skeleton
                                    variant="circular"
                                    animation="wave"
                                    style={{ marginRight: 14 }}
                                    width={48}
                                    height={48}
                                />
                                <Skeleton
                                    variant="circular"
                                    animation="wave"
                                    style={{ marginRight: 14, marginLeft: 14 }}
                                    width={48}
                                    height={48}
                                />
                                <Skeleton
                                    variant="circular"
                                    animation="wave"
                                    style={{ marginLeft: 14 }}
                                    width={48}
                                    height={48}
                                />
                            </View>
                        </View>
                        <View style={{ flex: 1, backgroundColor: "white" }}>
                            <Paper
                                elevation={6}
                                style={{ padding: 0, alignSelf: "center", width: "90%", justifyContent: "center", alignItems: "center", marginTop: -27, height: 54, borderRadius: 15 }}
                            >
                                <View style={{ flexDirection: "row", marginTop: -2, justifyContent: "center", alignItems: "center" }}>
                                    <Skeleton
                                        variant="text"
                                        sx={{ fontSize: "3rem" }}
                                        style={{ flex: 0.3, marginRight: 8, borderRadius: 15 }}
                                    />
                                    <Skeleton
                                        variant="text"
                                        sx={{ fontSize: "3rem" }}
                                        style={{ flex: 0.3, marginLeft: 0, marginRight: 8, borderRadius: 15 }}
                                    />
                                    <Skeleton
                                        variant="text"
                                        sx={{ fontSize: "3rem" }}
                                        style={{ flex: 0.3, borderRadius: 15 }}
                                    />
                                </View>
                            </Paper>
                            {
                                // Detail Buku 
                            }
                            <Skeleton
                                variant="text"
                                animation="wave"
                                width={100}
                                style={{ marginLeft: 16, marginTop: 16 }}
                                sx={{ fontSize: "2rem" }}
                            />
                            <View style={{ marginTop: 16, marginLeft: 16, marginRight: 16 }}>
                                <View style={{ flexDirection: "row", marginBottom: -8 }}>
                                    <Skeleton
                                        variant="text"
                                        animation="wave"
                                        style={{ flex: 0.3 }}
                                        sx={{ fontSize: "2rem" }}
                                    />
                                    <Skeleton
                                        variant="text"
                                        animation="wave"
                                        style={{ marginLeft: 8, flex: 0.2 }}
                                        sx={{ fontSize: "2rem" }}
                                    />
                                </View>
                                <View style={{ flexDirection: "row", marginBottom: -8 }}>
                                    <Skeleton
                                        variant="text"
                                        animation="wave"
                                        style={{ flex: 0.3 }}
                                        sx={{ fontSize: "2rem" }}
                                    />
                                    <Skeleton
                                        variant="text"
                                        animation="wave"
                                        style={{ marginLeft: 8, flex: 0.4 }}
                                        sx={{ fontSize: "2rem" }}
                                    />
                                </View>
                                <View style={{ flexDirection: "row", marginBottom: -8 }}>
                                    <Skeleton
                                        variant="text"
                                        animation="wave"
                                        style={{ flex: 0.3 }}
                                        sx={{ fontSize: "2rem" }}
                                    />
                                    <Skeleton
                                        variant="text"
                                        animation="wave"
                                        style={{ marginLeft: 8, flex: 0.5 }}
                                        sx={{ fontSize: "2rem" }}
                                    />
                                </View>
                                <View style={{ flexDirection: "row", marginBottom: -8 }}>
                                    <Skeleton
                                        variant="text"
                                        animation="wave"
                                        style={{ flex: 0.3 }}
                                        sx={{ fontSize: "2rem" }}
                                    />
                                    <Skeleton
                                        variant="text"
                                        animation="wave"
                                        style={{ marginLeft: 8, flex: 0.7 }}
                                        sx={{ fontSize: "2rem" }}
                                    />
                                </View>
                            </View>
                            {
                                // Sinopsis 
                            }
                            <View style={{ marginTop: 16, marginLeft: 16, marginRight: 16 }}>
                                <Skeleton
                                    variant="text"
                                    animation="wave"
                                    width={140}
                                    sx={{ fontSize: "2rem" }}
                                />
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    height={120}
                                    style={{ borderRadius: 8 }}
                                />

                            </View>
                        </View>
                    </ScrollView>
                }
                {
                    !this.state.showLoading &&
                    <View style={{ flex: 1, backgroundColor: "white" }}>
                        <ScrollView
                            ref={this.scrollRef}
                            style={{
                                backgroundColor: "white",
                                flex: 1
                            }}
                            showsVerticalScrollIndicator={false}
                        //onContentSizeChange={this.onContentSizeChange}
                        >

                            <View style={{
                                backgroundColor: primary,
                                justifyContent: "center",
                                alignItems: "center",
                                paddingBottom: 32
                            }}>

                                <Image
                                    source={{ uri: this.state.data.cover }}
                                    style={{
                                        marginTop: 16,
                                        borderRadius: 8,
                                        width: 160,
                                        height: 200
                                    }}
                                    onError={(error) => { this.onImageError() }}
                                />
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    sx={{ color: "white" }}
                                    width={"80%"}
                                    align="center"
                                    fontWeight="700"
                                    style={{ marginTop: 8 }}
                                >
                                    {this.state.data.title}
                                </Typography>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    sx={{ color: "white" }}
                                    width={"60%"}
                                    align="center"
                                    fontWeight="500"
                                    style={{ marginTop: 8 }}
                                >
                                    {this.state.data.author}
                                </Typography>

                                { // TOOLS ICON 
                                }
                                <View style={{ width: "80%", justifyContent: "center", flexDirection: "row", marginTop: 8 }}>
                                    <IconButton
                                        style={{ marginRight: 14 }}
                                        onClick={this.onRateButtonClick}
                                    >
                                        <IconRate />
                                    </IconButton>
                                    <IconButton
                                        style={{ marginRight: 14 }}
                                        onClick={this.onCommentButtonClick}
                                    >
                                        <IconComment />
                                    </IconButton>
                                    <IconButton
                                        style={{ marginRight: 14 }}
                                        onClick={this.onShareClick}
                                    >
                                        <IconShare />
                                    </IconButton>

                                </View>
                            </View>
                            {
                                // Extra Information 
                            }
                            <View style={{ flex: 1, backgroundColor: "white" }}>
                                <Paper
                                    elevation={6}
                                    style={{ padding: 0, alignSelf: "center", width: "90%", justifyContent: "center", alignItems: "center", marginTop: -27, height: 54, borderRadius: 15 }}
                                >
                                    <View style={{ flexDirection: "row", marginTop: 12, justifyContent: "center", alignItems: "center" }}>
                                        {
                                            this.state.rateLoading &&
                                            <Skeleton
                                                variant="rectangle"
                                                sx={{ height: 32 }}
                                                style={{ flex: 0.3, marginLeft: 0, marginRight: 0, borderRadius: 8 }}
                                            />

                                        }
                                        {
                                            !this.state.rateLoading &&
                                            <Pressable style={{ flex: 0.3, flexDirection: "row", alignItems: "center", justifyContent: "center" }}
                                                onPress={this.handleRateInfoClick}
                                            >
                                                <IconStar color="#FFD600" />
                                                <Typography
                                                    fontFamily="Open Sans"
                                                    fontSize={14}
                                                    fontWeight="700"
                                                    style={{ marginTop: 3, marginLeft: 4 }}
                                                    sx={{ color: color_text }}
                                                >
                                                    {this.state.rate_value}
                                                </Typography>
                                                <Typography
                                                    fontFamily="Open Sans"
                                                    fontSize={14}
                                                    fontWeight="600"
                                                    style={{ marginTop: 3, marginLeft: 4 }}
                                                    sx={{ color: sub_text }}
                                                >
                                                    ({this.state.total_rate})
                                                </Typography>
                                            </Pressable>
                                        }
                                        {
                                            this.state.stockLoading &&
                                            <Skeleton
                                                variant="rectangle"
                                                sx={{ height: 32 }}
                                                style={{ flex: 0.3, marginLeft: 0, marginRight: 0, borderRadius: 8 }}
                                            />
                                        }
                                        {
                                            !this.state.stockLoading &&
                                            <Pressable style={{ flex: 0.3, borderStyle: "dashed", borderColor: sub_text, borderLeftWidth: 1, borderRightWidth: 1, flexDirection: "row", alignItems: "center", justifyContent: "center" }}
                                                onPress={this.handleStockInfoClick}
                                            >
                                                <IconStock />
                                                <Typography
                                                    fontFamily="Open Sans"
                                                    fontSize={14}
                                                    fontWeight="700"
                                                    style={{ marginTop: 3, marginLeft: 4 }}
                                                    sx={{ color: color_text }}
                                                >
                                                    {this.state.stock + "/" + this.state.total}
                                                </Typography>
                                            </Pressable>
                                        }
                                        {
                                            this.state.sizeLoading &&
                                            <Skeleton
                                                variant="text"
                                                sx={{ fontSize: "3rem" }}
                                                style={{ flex: 0.3, borderRadius: 15 }}
                                            />
                                        }
                                        {
                                            !this.state.sizeLoading &&
                                            <Pressable style={{ flex: 0.3, flexDirection: "row", alignItems: "center", justifyContent: "center" }}
                                                onPress={this.handleSizeInfoClick}
                                            >
                                                <IconStorage />
                                                <Typography
                                                    fontFamily="Open Sans"
                                                    fontSize={14}
                                                    fontWeight="700"
                                                    style={{ marginTop: 3, marginLeft: 4 }}
                                                    sx={{ color: color_text }}
                                                >
                                                    {this.state.data.filesize}
                                                </Typography>
                                                <Typography
                                                    fontFamily="Open Sans"
                                                    fontSize={14}
                                                    fontWeight="600"
                                                    style={{ marginTop: 3, marginLeft: 0 }}
                                                    sx={{ color: sub_text }}
                                                >
                                                    mb
                                                </Typography>
                                            </Pressable>
                                        }
                                    </View>
                                </Paper>

                            </View>
                            {
                                // Detail Info 
                            }

                            <Typography
                                fontFamily="Open Sans"
                                fontSize={16}
                                fontWeight="700"
                                style={{ marginLeft: 16, marginTop: 16 }}
                                sx={{ color: color_text }}
                            >
                                Detail
                            </Typography>
                            <View style={{ marginTop: 16, marginLeft: 16, marginRight: 16 }}>
                                <View style={{ flexDirection: "row", marginBottom: 2 }}>
                                    <Typography
                                        style={{ flex: 0.4 }}
                                        fontFamily="Open Sans"
                                        fontWeight="500"
                                        fontSize={14}
                                        sx={{ color: color_text }}
                                    >
                                        Jumlah Halaman:
                                    </Typography>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontWeight="500"
                                        fontSize={14}
                                        style={{ marginLeft: 8, flex: 0.6 }}
                                        sx={{ color: sub_text }}
                                    >
                                        {this.state.data.total_pages}
                                    </Typography>
                                </View>
                                <View style={{ flexDirection: "row", marginBottom: 2 }}>
                                    <Typography
                                        style={{ flex: 0.4 }}
                                        fontFamily="Open Sans"
                                        fontWeight="500"
                                        fontSize={14}
                                        sx={{ color: color_text }}
                                    >
                                        Tahun Terbit
                                    </Typography>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontWeight="500"
                                        fontSize={14}
                                        style={{ marginLeft: 8, flex: 0.6 }}
                                        sx={{ color: sub_text }}
                                    >
                                        {this.state.data.year}
                                    </Typography>
                                </View>
                                <View style={{ flexDirection: "row", marginBottom: 2 }}>
                                    <Typography
                                        style={{ flex: 0.4 }}
                                        fontFamily="Open Sans"
                                        fontWeight="500"
                                        fontSize={14}
                                        sx={{ color: color_text }}
                                    >
                                        ISBN:
                                    </Typography>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontWeight="500"
                                        fontSize={14}
                                        style={{ marginLeft: 8, flex: 0.6 }}
                                        sx={{ color: sub_text }}
                                    >
                                        {this.state.data.isbn}
                                    </Typography>
                                </View>
                                <View style={{ flexDirection: "row", marginBottom: 2 }}>
                                    <Typography
                                        style={{ flex: 0.4 }}
                                        fontFamily="Open Sans"
                                        fontWeight="500"
                                        fontSize={14}
                                        sx={{ color: color_text }}
                                    >
                                        Penerbit:
                                    </Typography>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontWeight="500"
                                        fontSize={14}
                                        style={{ marginLeft: 8, flex: 0.6 }}
                                        sx={{ color: sub_text }}
                                    >
                                        {this.state.data.publisher}
                                    </Typography>
                                </View>
                            </View>
                            {
                                // Sinopsis 
                            }
                            <View style={{ marginTop: 16, marginLeft: 16, marginRight: 16 }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={16}
                                    fontWeight="700"
                                    sx={{ color: color_text }}
                                >
                                    Sinopsis
                                </Typography>
                                {
                                    (this.state.data.type == "Audio" || this.state.data.type == "Video") ?
                                        <View>
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={14}
                                                component={'span'}
                                                variant={'body2'}
                                            >
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: this.state.data.synopsis }}
                                                />
                                            </Typography>

                                        </View> :
                                        <View
                                            onLayout={this.onLayoutSynopsis}
                                        >
                                            <Collapse in={this.state.collapseSynopsis}
                                                collapsedSize={90}
                                            >
                                                <Typography
                                                    fontFamily="Open Sans"
                                                    fontSize={14}
                                                    component={'span'}
                                                    variant={'body2'}
                                                >
                                                    <div
                                                        dangerouslySetInnerHTML={{ __html: this.state.data.synopsis }}
                                                    />
                                                </Typography>
                                            </Collapse>

                                        </View>
                                }

                            </View>
                            {
                                (this.state.data.type == "Audio" || this.state.data.type == "Video") &&
                                <View style={{ marginTop: 16, marginLeft: 16, marginRight: 16 }}>

                                    {/* <Typography
                                        fontFamily="Open Sans"
                                        fontSize={16}
                                        fontWeight="700"
                                        sx={{ color: color_text }}
                                    >
                                        Playlist
                                    </Typography> */}
                                    <View
                                        style={{
                                            flexDirection: "row"
                                        }}
                                    >
                                        <View style={{ flex: 0.5 }}>
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={16}
                                                fontWeight="700"
                                                sx={{ color: color_text }}
                                            >
                                                Playlist
                                            </Typography>
                                        </View>
                                        <View style={{ flex: 0.5, alignItems: 'end' }}>
                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={16}
                                                fontWeight="700"
                                                sx={{ color: color_text }}
                                                onClick={() => { this.checkBeforePlay(this.state.playlist, 0, 'all') }}
                                            >
                                                Putar Semua
                                            </Typography>
                                        </View>
                                    </View>
                                    <FlatList
                                        keyExtractor={(item, index) => "item_index_" + index}
                                        data={this.state.playlist}
                                        renderItem={this.renderItem}
                                        numColumns={1}
                                        showsVerticalScrollIndicator={false}
                                        onEndReachedThreshold={0.5}
                                        onEndReached={this.fetchMoreData}
                                        ListFooterComponent={
                                            this.state.moreLoading &&
                                            <View style={{ alignItems: "center", padding: 8 }}>
                                                <CircularProgress
                                                    size={32}
                                                />
                                            </View>
                                        }
                                    />
                                </View>
                            }
                        </ScrollView>
                        <View style={{ backgroundColor: "rgba(255, 255, 255,0.8)", marginBottom: 15 }}>
                            {
                                this.state.synopsisDimensions.height > 90 &&
                                <Pressable
                                    style={{
                                        padding: 8,
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginBottom: 0,
                                        backgroundColor: "rgba(255, 255, 255,0.8)"
                                    }}
                                    onPress={() => { this.expandMore() }}
                                >
                                    {
                                        !this.state.collapseSynopsis &&
                                        <ExpandMoreIcon />
                                    }
                                    {
                                        this.state.collapseSynopsis &&
                                        <ExpandLessIcon />
                                    }
                                </Pressable>
                            }
                            {
                                !this.state.stockLoading &&
                                <View style={{ flexDirection: "row", justifyContent: "center", paddingBottom: 10, paddingTop: 10 }}>
                                    {
                                        this.state.kontenLokal ?
                                            //baca
                                            <Button
                                                variant="contained"
                                                style={{ textTransform: "none", marginLeft: 16, borderRadius: 25, marginRight: 16, padding: 0, flex: 1, height: 54, maxHeight: 54, marginTop: 6 }}
                                                onClick={this.checkBeforeRead}
                                            >
                                                <Typography
                                                    fontFamily="Open Sans"
                                                    fontSize={16}
                                                    fontWeight="500"
                                                >
                                                    Baca
                                                </Typography>
                                            </Button>
                                            :
                                            <>
                                                {
                                                    (this.state.data.type == "Audio" || this.state.data.type == "Video") ?
                                                        // <Button
                                                        //     variant="contained"
                                                        //     style={{ textTransform: "none", borderRadius: 25, marginRight: 16, marginLeft: 16, padding: 16, flex: 1, maxHeight: 54, marginTop: 6 }}
                                                        //     onClick={() => { this.checkBeforePlay(this.state.playlist, 0) }}
                                                        // >
                                                        //     <Typography
                                                        //         fontFamily="Open Sans"
                                                        //         fontSize={16}
                                                        //         fontWeight="500"
                                                        //     >
                                                        //         Putar Semua
                                                        //     </Typography>
                                                        // </Button>
                                                        <></>
                                                        :
                                                        <>
                                                            {
                                                                this.state.stock > 0 || this.state.email == 'eresources.akuisisi@gmail.com' ?
                                                                    <>
                                                                        <Button
                                                                            variant="contained"
                                                                            style={{ textTransform: "none", marginLeft: 16, borderRadius: 25, marginRight: 16, padding: 0, flex: 1, height: 54, maxHeight: 54, marginTop: 6 }}
                                                                            onClick={this.checkBeforeBorrow}
                                                                        >
                                                                            <Typography
                                                                                fontFamily="Open Sans"
                                                                                fontSize={16}
                                                                                fontWeight="500"
                                                                            >
                                                                                Pinjam
                                                                            </Typography>
                                                                        </Button>
                                                                        {
                                                                            (this.state.data.type == "Audio" || this.state.data.type == "Video") ?
                                                                                <Button
                                                                                    variant="contained"
                                                                                    style={{ textTransform: "none", borderRadius: 25, marginRight: 16, padding: 0, flex: 1, maxHeight: 54, marginTop: 6 }}
                                                                                    onClick={this.checkBeforePlay}
                                                                                >
                                                                                    <Typography
                                                                                        fontFamily="Open Sans"
                                                                                        fontSize={16}
                                                                                        fontWeight="500"
                                                                                    >
                                                                                        Play
                                                                                    </Typography>
                                                                                </Button> :
                                                                                <Button
                                                                                    variant="contained"
                                                                                    style={{ textTransform: "none", borderRadius: 25, marginRight: 16, padding: 0, flex: 1, maxHeight: 54, marginTop: 6 }}
                                                                                    onClick={this.checkBeforeRead}
                                                                                >
                                                                                    <Typography
                                                                                        fontFamily="Open Sans"
                                                                                        fontSize={16}
                                                                                        fontWeight="500"
                                                                                    >
                                                                                        Baca
                                                                                    </Typography>
                                                                                </Button>

                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {
                                                                            this.state.status_pinjam ?
                                                                                <Button
                                                                                    variant="contained"
                                                                                    style={{ textTransform: "none", marginLeft: 16, borderRadius: 25, marginRight: 16, padding: 0, flex: 1, height: 54, maxHeight: 54, marginTop: 6 }}
                                                                                    onClick={this.checkBeforeRead}
                                                                                >
                                                                                    <Typography
                                                                                        fontFamily="Open Sans"
                                                                                        fontSize={16}
                                                                                        fontWeight="500"
                                                                                    >
                                                                                        Baca
                                                                                    </Typography>
                                                                                </Button>
                                                                                :
                                                                                <Button
                                                                                    variant="contained"
                                                                                    style={{ textTransform: "none", marginLeft: 16, borderRadius: 25, marginRight: 16, padding: 0, flex: 1, height: 54, maxHeight: 54, marginTop: 6 }}
                                                                                    onClick={this.checkBeforeAntrian}
                                                                                >
                                                                                    <Typography
                                                                                        fontFamily="Open Sans"
                                                                                        fontSize={16}
                                                                                        fontWeight="500"
                                                                                    >
                                                                                        Antri Pinjam
                                                                                    </Typography>
                                                                                </Button>
                                                                        }

                                                                    </>
                                                            }
                                                        </>
                                                }
                                            </>
                                    }


                                </View>
                            }
                            {
                                this.state.stockLoading &&
                                <Skeleton
                                    variant="rectangular"
                                    animation="wave"
                                    style={{ borderRadius: 8, marginLeft: 16, marginRight: 16 }}
                                    height={56}
                                />
                            }
                        </View>
                    </View>
                }
                {
                    this.state.showAlert &&
                    <Alert
                        onClose={() => { this.goBack() }}
                        severity="error"
                        sx={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
                    >
                        <AlertTitle>Pesan</AlertTitle>
                        {this.state.alertMessage}
                    </Alert>
                }
                {
                    this.state.showAlertRate &&
                    <Alert
                        onClose={() => { this.setState({ showAlertRate: false }) }}
                        severity="warning"
                        sx={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
                    >
                        <AlertTitle>Perhatian</AlertTitle>
                        {this.state.alertRateMessage}
                    </Alert>
                }
                {
                    this.state.showBorrowSuccess &&
                    <Alert
                        onClose={() => { this.setState({ showBorrowSuccess: false }) }}
                        severity="success"
                        sx={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
                    >
                        <AlertTitle>Berhasil</AlertTitle>
                        {this.state.alertMessage}
                    </Alert>
                }
                <Dialog
                    open={this.state.showBorrowDialog}
                    onClose={this.handleBorrowConfirmationClose}
                >
                    <DialogTitle>
                        Konfirmasi Peminjaman
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Apakah anda akan meminjam koleksi digital ini?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleBorrowConfirmationClose}>Batalkan</Button>
                        <Button onClick={this.handleConfirmationClick}>Lanjutkan</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showRatingBox}
                    onClose={this.handleRatingBoxClose}
                >
                    <DialogTitle>
                        Berikan Rating Anda
                    </DialogTitle>
                    <DialogContent>
                        <View style={{ alignItems: "center" }}>
                            <Rating
                                size="large"
                                value={this.state.rateValue}
                                onChange={this.onRateValueChange}
                            />
                        </View>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleRatingBoxClose}>Batalkan</Button>
                        <Button onClick={this.handleSendRate}>Kirim</Button>
                    </DialogActions>
                </Dialog>
                <LoadingDialog
                    open={this.state.showLoadingDialog}
                    message={this.state.loadingDialogMessage}
                />
            </SafeAreaView>
        )
    }
}


export default ContentDetail;