const REQUEST_APP_TOKEN = "REQUEST_APP_TOKEN";
const RECEIVE_APP_TOKEN = "RECEIVE_APP_TOKEN"; 
const SET_PAGE = "SET_PAGE"; 
const SET_DATE = "SET_DATE"; 
const OPEN_URL = "OPEN_URL";
const SHARE_APP = "SHARE_APP";
const SHOW_TOAST = "SHOW_TOAST"; 
const SHOW_INFO = "SHOW_INFO";
const SIGN_OUT = "SIGN_OUT";
const DISABLE_SCREEN = "DISABLE_SCREEN"; 
const CLOSE_BOOK = "CLOSE_BOOK"; 
const CLOSE_APP = "CLOSE_APP"; 
const INIT_FCM = "INIT_FCM"; 
const CONSOLE_LOG = "CONSOLE_LOG"; 
const NAVIGATE_MENU = "NAVIGATE_MENU"; 
const DISPATCH_MENU = "DISPATCH_MENU"; 
const SPEAK_UP = "SPEAK_UP";
const STOP_SPEAK = "STOP_SPEAK"; 
const SHARE_BOOK = "SHARE_BOOK"; 
const HIDE_KEYBOARD = "HIDE_KEYBOARD"; 
const CREDENTIAL_LOGIN = "CREDENTIAL_LOGIN"; 
const SAVE_DETAIL_BOOK = "SAVE_DETAIL_BOOK"; 
const UPDATE_TOKEN = "UPDATE_TOKEN";
const RETURN_BOOK = "RETURN_BOOK"; 

export { 
    RETURN_BOOK,
    REQUEST_APP_TOKEN,
    RECEIVE_APP_TOKEN, 
    SET_PAGE, 
    SET_DATE, 
    OPEN_URL, 
    SHARE_APP, 
    SHOW_TOAST, 
    SHOW_INFO, 
    SIGN_OUT,
    CLOSE_APP, 
    INIT_FCM ,
    CONSOLE_LOG,
    NAVIGATE_MENU ,
    DISPATCH_MENU,
    SPEAK_UP,
    STOP_SPEAK,
    SHARE_BOOK,
    HIDE_KEYBOARD,
    DISABLE_SCREEN,
    CLOSE_BOOK,
    CREDENTIAL_LOGIN,
    SAVE_DETAIL_BOOK,
    UPDATE_TOKEN
}
