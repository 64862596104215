import React, { Component } from 'react';
import { View, StyleSheet, Dimensions, TextInput, Image } from 'react-native';
import LoadingDialog from '../components/LoadingDialog';
import { executeGet, executePost, truncateText, isEven } from '../utilities/Utilities';
import { code_ok, code_error, code_empty, code_auth_error, getContent, closeBook } from '../constants/api';
import { Button, Dialog, Typography, DialogTitle, DialogActions, DialogContent, DialogContentText, Card, CardActionArea } from '@mui/material';
import TextBox from '../components/TextBox';
import { color_text, sub_text, primary, textbox } from '../constants/colors';
import * as Command from '../constants/Commands';
import Plyr, { APITypes, PlyrInstance, PlyrProps, usePlyr } from "plyr-react";
import "plyr-react/plyr.css"

import ReactAudioPlayer from 'react-audio-player';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

var _is_mounted = false;
class ReaderPlayerMobile extends Component {
    constructor(props) {
        // console.log('ini_nama = ' + this.props.route.params)
        // console.log('playlist_length = ' + this.props.route.params.playlist.length)
        // console.log('playlist_length = ' + this.props.route.params.play_type)

        super(props)
        this.state = {
            showLoadingDialog: true,
            showAudio: false,
            loadingDialogMessage: "Harap Tunggu",
            id_baca: "",
            id_baca_mitra: "",
            token_baca: "",
            blobUrl: "",
            id_konten: "",
            kode_konten: "",
            lastPage: "1",
            jumlahLagu: 1,
            nomorLagu: 1,
            autoPlay: false,
            mode: "-",
            sumberAudio: "",
            showHighlightDialog: false,
            highlightComment: "",
            citationData: {},
            //Voices Modules 
            voices: [],
            currentVoice: {},
            last_page_index: 0,
            videoSrc: {
                type: "audio",
                sources: [
                    {
                        src: " https://bintangpusnas.perpusnas.go.id/api/konten_pnri/audiobook/" + this.props.route.params.nama_file
                    }
                ]
            },
            track: this.props.route.params.first_page,
            playlist: this.props.route.params.play_index == '0' ? this.props.route.params.playlist : this.props.route.params.playlist[this.props.route.params.play_index],
            currentTrack: this.props.route.params.play_index,
        }

        //this.fetchContent = this.fetchContent.bind(this); 
        this.onLoadIframe = this.onLoadIframe.bind(this);
        this.iframeRef = React.createRef();
        this.executeCloseBook = this.executeCloseBook.bind(this);
        this.handleCloseHighlightDialog = this.handleCloseHighlightDialog.bind(this);
        this.handleConfirmAddHighlight = this.handleConfirmAddHighlight.bind(this);
        this.updateVoices = this.updateVoices.bind(this);
        this.speakUp = this.speakUp.bind(this);
        this.getFileMedia = this.getFileMedia.bind(this);

        this.synth = React.createRef();

        this.handleBookCloseCancel = this.handleBookCloseCancel.bind(this);
        this.handleBookCloseAccept = this.handleBookCloseAccept.bind(this);

        console.log('ini_nama = ' + JSON.stringify(this.props.route.params))
        console.log('playlist  = ' + JSON.stringify(this.state.playlist))
        console.log('playlist 0 = ' + JSON.stringify(this.state.playlist.multimedia_file))
        console.log('playlist 1 = ' + JSON.stringify(this.props.route.params.playlist[this.props.route.params.play_index]))


        console.log('playlist_length = ' + this.props.route.params.play_index)
        console.log('playlist_length = ' + this.state.currentTrack)


    }

    getFileMedia = () => {
        let listLength = this.props.route.params.playlist.length;
        console.log('getFileMedia 1 = ' + listLength)
        for (let i = this.props.route.params.play_index; i < listLength; i++) {
            console.log('getFileMedia 2 = ' + i)
            let fileName = JSON.stringify(this.props.route.params.playlist[i].multimedia_file)
            console.log('getFileMedia 3 = ' + fileName)
            if (fileName != "null" && fileName != '""') {
                this.setState({
                    playlist: this.props.route.params.playlist[i],
                    showAudio: true
                }, () => {
                    // console.log('playlist new = ' + this.state.playlist.multimedia_file)
                    // console.log('showAudio new = ' + this.state.showAudio)
                });

                return fileName;
            }
        }

        // this.setState({
        //     voices: this.synth.current.getVoices()
        // }, () => {

        // });
    }

    updateVoices = () => {
        this.setState({
            voices: this.synth.current.getVoices()
        }, () => {

        });
    }

    handleBookCloseCancel = () => {
        if (_is_mounted) {
            this.setState({
                showCloseDialog: false
            })
        }
    }
    handleBookCloseAccept = () => {
        if (_is_mounted) {
            this.setState({
                showCloseDialog: false,
            }, () => {
                this.executeCloseBook();
                //this.props.navigation.goBack();

            })

        }
    }

    filterPlaylist = () => {
        
    }

    componentDidMount = () => {
        _is_mounted = true;

        let fileMedia = JSON.stringify(this.state.playlist.multimedia_file);
        // console.log('fileMedia = ' + fileMedia)
        // console.log('fileMedia = ' + fileMedia.length)

        

        if (fileMedia == "null" || fileMedia == '""') {
            console.log('next file')
            this.getFileMedia();
        } else {
            console.log('exist file')
            if(this.props.route.params.play_index == '0') {
                if (this.props.route.params.local != "musik_keluarga") {
                    //menghilangkan file kosong
                    var newArray = this.state.playlist.filter(function (el) {
                        return el.multimedia_file != "" ;
                    });
                    console.log('newArray = ' + JSON.stringify(newArray))
                    this.setState({
                        playlist: newArray,
                        showAudio: true
                    }, () => {
                        // console.log('showAudio = ' + this.state.showAudio)
                    });
                }
            } else {
                if (_is_mounted) {
                    this.setState({
                        showAudio: true
                    }, () => {
                        // console.log('showAudio = ' + this.state.showAudio)
                    });
                }
            }
            
        }
        // console.log('param from detail = ' + JSON.stringify(this.props.route.params))
        // console.log('play 1 = ' + JSON.stringify(this.state.playlist[this.state.currentTrack]))
        if (this.props.route.params.type == 'Video') {
            this.setState({
                videoSrc: {
                    type: "video",
                    sources: [
                        {
                            src: " https://bintangpusnas.perpusnas.go.id/api/konten_pnri/audiobook/" + this.props.route.params.playlist[0].multimedia_file // + ".mp4"
                        }
                    ]
                }
            })
        } else {
            /*
            this.setState({
                videoSrc: {
                    type: "audio",
                    sources: " https://bintangpusnas.perpusnas.go.id/api/konten_pnri/audiobook/" + this.props.route.params.nama_file
                },
                sumberAudio: " https://bintangpusnas.perpusnas.go.id/api/konten_pnri/audiobook/" + this.props.route.params.nama_file
            })*/
            // console.log('playlist_length = ' + this.props.route.params.playlist.length)
            // console.log('playlist_length = ' + this.props.route.params.play_type)
            // console.log('playlist_length = ' + " https://bintangpusnas.perpusnas.go.id/api/konten_pnri/audiobook/" + this.state.playlist[this.state.currentTrack].multimedia_file)
            // console.log('playlist_length = ' + " https://bintangpusnas.perpusnas.go.id/api/konten_pnri/audiobook/" + this.state.playlist.multimedia_file)

            if (this.props.route.params.local == "musik_keluarga") {
                console.log('playlist_isi = ' + this.props.route.params.nama_file)
                this.setState({
                    sumberAudio: "https://kubuku.id/multimedia/" + this.props.route.params.nama_file,
                    jumlahLagu: 1,
                    nomorLagu: 1
                });
            } else {
                if (this.props.route.params.playlist.length == undefined) {
                    console.log('playlist_isi = ' + this.props.route.params.nama_file)
                    this.setState({
                        sumberAudio: " https://bintangpusnas.perpusnas.go.id/api/konten_pnri/audiobook/" + this.props.route.params.nama_file,
                        jumlahLagu: 1,
                        nomorLagu: 1
                    });
                } else {
                    // console.log('playlist_isi1 = ' + this.props.route.params.playlist[0].multimedia_file)
                    this.setState({
                        sumberAudio: " https://bintangpusnas.perpusnas.go.id/api/konten_pnri/audiobook/" + this.props.route.params.playlist[0].multimedia_file,
                        jumlahLagu: this.props.route.params.playlist.length,
                        nomorLagu: 1
                    });
                }
            }


        }

        try {
            if (global.appAccess == undefined) {
                this.synth.current = window.speechSynthesis;
                this.synth.current.onvoiceschanged = this.updateVoices;
                //this.updateVoices();
                this.setState({
                    id_baca: this.props.route.params.id_baca,
                    id_baca_mitra: this.props.route.params.id_baca_mitra,
                    token_baca: this.props.route.params.token,
                    id_konten: this.props.route.params.id_konten,
                    kode_konten: this.props.route.params.kode_konten,
                    main_konten: this.props.route.params.nama_file,
                    mode: this.props.route.params.mode,
                    voices: this.synth.current.getVoices(),
                    currentVoice: this.synth.current.getVoices()[6]
                }, () => {
                    //this.fetchContent();
                    //console.log(window.speechSynthesis);
                })
            } else {
                this.setState({
                    id_baca: this.props.route.params.id_baca,
                    id_baca_mitra: this.props.route.params.id_baca_mitra,
                    token_baca: this.props.route.params.token,
                    id_konten: this.props.route.params.id_konten,
                    main_konten: this.props.route.params.nama_file,
                    kode_konten: this.props.route.params.kode_konten,
                    mode: this.props.route.params.mode,
                }, () => {
                    //this.fetchContent();
                    //console.log(window.speechSynthesis);
                })
            }

            window.addEventListener("message", (event) => {
                if (event.data.hasOwnProperty("command")) {
                    if (event.data.command == "close") {
                        // let page = event.data.page;
                        // this.setState({
                        //     lastPage: page
                        // }, () => {
                        //     if (this.state.mode == "read") {
                        //         this.executeCloseBook();
                        //     } else {
                        //         this.props.navigation.goBack();
                        //     }
                        // })
                        console.log("COMMAND CLOSE");
                        let page = event.data.page;
                        this.setState({
                            lastPage: page
                        }, () => {
                            this.executeCloseBook();
                        })
                    } else if (event.data.command == "log") {
                        console.log(event.data.message);
                    } else if (event.data.command == "cite") {
                        let data = event.data.data;
                        this.setState({
                            citationData: data,
                            showCitationDialog: true
                        })
                    } else if (event.data.command == "add_highlight") {
                        this.setState({
                            showHighlightDialog: true
                        })
                    } else if (event.data.command == "speak") {
                        if (global.appAccess == undefined) {
                            this.speakUp(event.data.message);
                        } else {
                            JSBridge.launchFunction(Command.SPEAK_UP, event.data.message);
                        }
                    } else if (event.data.command == "close_dialog") {
                        //alert("RECEIVE CLOSE DIALOG");
                        this.setState({
                            showLoadingDialog: false
                        })
                    }
                } else if (event.data.hasOwnProperty("id")) {
                    if (event.data.id == "go_back") {
                        try {
                            // setTimeout(() => {
                            //     document.getElementById('readerFrame').contentWindow.postMessage({ id: "go_back" }, "*");
                            // }, 500);
                            this.executeCloseBook();
                        } catch (error) {

                        }

                    }
                }
            })
        } catch (error) {

        }
    }

    componentWillUnmount = () => {
        _is_mounted = false;
        window.removeEventListener("message", null);
        if (global.appAccess == undefined) {
            this.synth.current.onvoiceschanged = null;
        }
    }

    handleClickPrevious = () => {
        console.log('click previous = ' + this.state.currentTrack)
        console.log('click previous = ' + this.state.playlist.length)
        this.setState({ currentTrack: this.state.currentTrack > 0 ? this.state.currentTrack - 1 : 0 })
        //   setTrackIndex((currentTrack) =>
        //       currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
        //   );
    };

    handleClickNext = () => {
        console.log('click next')
        this.setState({ currentTrack: this.state.currentTrack < this.state.playlist.length - 1 ? this.state.currentTrack + 1 : 0 })
        //   setTrackIndex((currentTrack) =>
        //       currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
        //   );
    };

    handleEnd = () => {
        console.log('end')
        this.setState({ currentTrack: this.state.currentTrack < this.state.playlist.length - 1 ? this.state.currentTrack + 1 : 0 })
        //   setTrackIndex((currentTrack) =>
        //           currentTrack < playlist.length - 1 ? currentTrack + 1 : 0
        //       );
    }

    nextAudio = () => {
        console.log("NEXT");
        console.log(this.state.jumlahLagu);
        if (this.state.jumlahLagu != "1") {
            console.log("FIND NEXT AUDIO");
            var nomorLagu = parseInt(this.state.nomorLagu) + 1;
            if (nomorLagu < parseInt(this.state.jumlahLagu)) {
                console.log("GOT IT");
                console.log(this.props.route.params.playlist[nomorLagu].multimedia_file);
                this.setState({
                    sumberAudio: " https://bintangpusnas.perpusnas.go.id/api/konten_pnri/audiobook/" + this.props.route.params.playlist[nomorLagu].multimedia_file,
                    nomorLagu: nomorLagu,
                    autoPlay: true
                });
            }
        }
    }
    speakUp = (text) => {
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.voice = this.state.voices[6];
        utterance.pitch = 1;
        utterance.rate = 1;
        this.synth.current.speak(utterance);
    }
    onLoadIframe = () => {
        this.setState({
            showLoadingDialog: false,
        }, () => {
            setTimeout(() => {
                //console.log("POST MESSAGE");
                document.getElementById('readerFrame').contentWindow.postMessage({
                    headers: global.defaultHeaders,
                    token: this.props.route.params.token,
                    id_baca: this.props.route.params.id_baca,
                    id_baca_mitra: this.props.route.params.id_baca_mitra,
                    id_konten: this.props.route.params.id_konten,
                    kode_konten: this.props.route.params.kode_konten,
                    mode: this.props.route.params.mode,
                    last_page_index: this.props.route.params.last_page_index,
                    first_page: this.props.route.params.first_page,
                    last_page: this.props.route.params.last_page,
                    local_content: this.props.route.params.local,
                    keyword: (this.props.route.params.hasOwnProperty("keyword") ? this.props.route.params.keyword : ""),
                    directIndex: (this.props.route.params.hasOwnProperty("directIndex") ? this.props.route.params.directIndex : 0)
                }, "*");
            }, 2000);
        })
    }

    executeCloseBook = async () => {
        //console.log("ID BACA: " + this.state.id_baca); 
        //console.log("ID BACA MITRA: " + this.state.id_baca_mitra); 
        //console.log("LAST PAGE: " + this.state.lastPage); 

        let formData = new FormData();
        formData.append("id_baca", this.state.id_baca);
        formData.append("id_baca_mitra", this.state.id_baca_mitra);
        formData.append("last_page", this.state.lastPage);
        this.setState({
            showLoadingDialog: true,
            loadingDialogMessage: "Mengembalikan Buku"
        })
        const response = await executePost(closeBook, formData);
        if (response.code == code_ok) {
            this.setState({
                showLoadingDialog: false
            }, () => {
                if (global.appAccess != undefined) {
                    try {
                        JSBridge.launchFunction(Command.STOP_SPEAK, "-");
                    } catch (error) {

                    }
                }
                this.props.navigation.goBack();
                // history.back();
            })
        } else {
            this.setState({
                showLoadingDialog: false
            }, () => {
                this.props.navigation.goBack();
                // history.back();
            })
        }

    }
    tutupBuku = () => {
        console.log("TUTUP BUKU");
        //this.executeCloseBook();
        this.setState({
            showCloseDialog: true
        });
    }
    handleCloseHighlightDialog = () => {
        this.setState({
            showHighlightDialog: false
        }, () => {
            document.getElementById('readerFrame').contentWindow.postMessage({
                id: "cancel_highlight"
            }, "*");
        })
    }

    handleConfirmAddHighlight = () => {
        this.setState({
            showHighlightDialog: false
        }, () => {
            //console.log(this.state.highlightComment);
            let comment = this.state.highlightComment;
            document.getElementById("readerFrame").contentWindow.postMessage({
                id: "confirm_higlight",
                message: comment
            }, "*");
        })
    }

    render() {

        let viewWidth = Dimensions.get("window").width;
        let viewHeight = Dimensions.get("window").height;

        let window_width = Dimensions.get("window").width;
        let book_width = (window_width / 2) - 16;
        let book_height = book_width + 40;

        return (
            <View style={styles.container}>
                {
                    this.props.route.params.type == 'Video' ?
                        <>
                            <View style={{ flex: 0.25 }}>
                                <Plyr source={this.state.videoSrc} />
                            </View>
                            <View style={{ flex: 0.75 }}>
                                <LoadingDialog
                                    open={this.state.showLoadingDialog}
                                    message={this.state.loadingDialogMessage}
                                />

                                <iframe
                                    id="readerFrame"
                                    //src={"https://reader.bintangpusnas.com:3000/?platform=mobile"}
                                    src={"https://bintangpusnas.perpusnas.go.id/web/reader/?platform=mobile"}
                                    width={viewWidth}
                                    height={viewHeight}
                                    frameBorder={0} onLoad={this.onLoadIframe}></iframe>
                                <View onClick={() => this.tutupBuku()} style={{ borderRadius: "10px", position: "absolute", top: "14px", left: "4px", height: "42px", width: "50px", color: "white", backgroundColor: "rgb(24, 73, 153)" }}>
                                    <Typography
                                        fontFamily="Open Sans"
                                        fontSize={24}
                                        fontWeight="600"
                                        style={{ position: "absolute", top: "2px", left: "16px" }}
                                    >
                                        X
                                    </Typography>
                                </View>
                                <Dialog
                                    open={this.state.showHighlightDialog}
                                    onClose={this.handleCloseHighlightDialog}
                                    fullWidth={true}
                                >
                                    <DialogTitle>
                                        Tambah Catatan
                                    </DialogTitle>
                                    <DialogContent>
                                        <TextInput
                                            style={{
                                                height: 120,
                                                width: "100%",
                                                fontFamily: "Open Sans",
                                                fontWeight: "400",
                                                color: color_text,
                                                padding: 12,
                                                borderRadius: 8,
                                                backgroundColor: textbox,
                                                outlineColor: primary
                                            }}
                                            multiline={true}
                                            textAlignVertical={"top"}
                                            placeholder={"Ketik catatan anda disini"}
                                            onChangeText={(text) => { this.setState({ highlightComment: text }) }}
                                        />

                                        {/* <TextBox
                            labeled={ false }
                            placeholder={"Ketik catatan anda"}
                            onChangeText={(text)=>{ this.setState({ highlightComment : text })}}
                        /> */}

                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.handleCloseHighlightDialog}>Batal</Button>
                                        <Button onClick={this.handleConfirmAddHighlight}>Simpan</Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    open={this.state.showCloseDialog}
                                    onClose={this.handleBookCloseCancel}
                                >
                                    <DialogTitle>
                                        Konfirmasi!
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            Apakah anda selesai membaca dan tutup buku ini?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.handleBookCloseCancel}>Batal</Button>
                                        <Button onClick={this.handleBookCloseAccept}>OK</Button>
                                    </DialogActions>
                                </Dialog>
                            </View>
                        </> :
                        <>
                            {
                                this.props.route.params.local == "musik_keluarga" ?
                                    <>
                                        <View style={{ flex: 0.9 }}>
                                            <View onClick={() => this.props.navigation.goBack()} style={{ borderRadius: "10px", position: "absolute", top: "14px", left: "4px", height: "42px", width: "50px", color: "white", backgroundColor: "rgb(24, 73, 153)" }}>
                                                <Typography
                                                    fontFamily="Open Sans"
                                                    fontSize={24}
                                                    fontWeight="600"
                                                    style={{ position: "absolute", top: "2px", left: "16px" }}
                                                >
                                                    X
                                                </Typography>
                                            </View>

                                            {/* <Card
                                                style={{
                                                    width: book_width,
                                                    borderRadius: 8,
                                                    marginLeft: (isEven(1) ? 16 : 4),
                                                    marginRight: (!isEven(1)) ? 16 : 4,
                                                    marginBottom: 8,

                                                }}
                                            >
                                                
                                            </Card> */}
                                            <Image
                                                style={{
                                                    width: book_width,
                                                    height: book_height,
                                                    margin: 'auto',
                                                    borderRadius: 10
                                                }}
                                                resizeMode={"cover"}
                                                source={{ uri: this.props.route.params.cover }}
                                                onError={(error) => { this.onImageError(item, index) }}
                                            />


                                            <Typography
                                                fontFamily="Open Sans"
                                                fontSize={14}
                                                color={color_text}
                                                fontWeight={"600"}
                                                style={{
                                                    marginLeft: 12, marginTop: 8, marginBottom: 8, marginRight: 12, textAlign: 'center'
                                                }}
                                            >
                                                {truncateText(this.props.route.params.nama_file, 32)}
                                            </Typography>
                                            <Typography
                                                fontFamily="Open Sans"
                                                color={color_text}
                                                fontSize={12}
                                                style={{
                                                    marginLeft: 12,
                                                    marginBottom: 12,
                                                    marginRight: 12,
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {truncateText(this.props.route.params.pencipta, 20)}
                                            </Typography>
                                        </View>
                                        <View style={{ flex: 0.1 }}>
                                            <AudioPlayer
                                                autoPlay
                                                src={"https://kubuku.id/multimedia/" + this.props.route.params.nama_file}
                                                showSkipControls
                                                onClickPrevious={() => { this.handleClickPrevious() }}
                                                onClickNext={() => { this.handleClickNext() }}
                                                onEnded={() => { this.handleEnd() }}
                                            // Try other props!
                                            />
                                        </View>
                                    </>
                                    :
                                    <>
                                        <View style={{ flex: 0.9 }}>
                                            <LoadingDialog
                                                open={this.state.showLoadingDialog}
                                                message={this.state.loadingDialogMessage}
                                            />

                                            <iframe
                                                id="readerFrame"
                                                src={"https://bintangpusnas.perpusnas.go.id/web/reader/?platform=mobile"}
                                                //src={ "https://reader.bintangpusnas.com:3000/?platform=mobile" } 
                                                //src={"http://localhost:3000/?platform=mobile"}
                                                width={viewWidth}
                                                height="100%"
                                                frameBorder={0} onLoad={this.onLoadIframe}></iframe>
                                            <View onClick={() => this.tutupBuku()} style={{ borderRadius: "10px", position: "absolute", top: "14px", left: "4px", height: "42px", width: "50px", color: "white", backgroundColor: "rgb(24, 73, 153)" }}>
                                                <Typography
                                                    fontFamily="Open Sans"
                                                    fontSize={24}
                                                    fontWeight="600"
                                                    style={{ position: "absolute", top: "2px", left: "16px" }}
                                                >
                                                    X
                                                </Typography>
                                            </View>
                                            <Dialog
                                                open={this.state.showCitationDialog}
                                                onClose={() => { this.setState({ showCitationDialog: false }) }}
                                                style={{ width: "100%" }}
                                            >
                                                <DialogTitle>
                                                    Pilih Style Sitasi
                                                </DialogTitle>
                                                <DialogContent>
                                                    <View style={{ flex: 1, flexDirection: "column", alignItems: "center" }}>
                                                        <Typography
                                                            fontFamily="Open Sans"
                                                            fontSize={14}
                                                            fontWeight="600"
                                                        >
                                                            MLA :
                                                            <div
                                                                style={{ overflow: "hidden", color: primary }}
                                                                dangerouslySetInnerHTML={{ __html: this.state.citationData.mla }}
                                                            />
                                                        </Typography>
                                                        <Typography
                                                            fontFamily="Open Sans"
                                                            fontSize={14}
                                                            fontWeight="600"
                                                        >
                                                            Chicago :
                                                            <div
                                                                style={{ overflow: "hidden", color: primary }}
                                                                dangerouslySetInnerHTML={{ __html: this.state.citationData.chg }}
                                                            />
                                                        </Typography>
                                                        <Typography
                                                            fontFamily="Open Sans"
                                                            fontSize={14}
                                                            fontWeight="600"
                                                        >
                                                            Turabian :
                                                            <div
                                                                style={{ overflow: "hidden", color: primary }}
                                                                dangerouslySetInnerHTML={{ __html: this.state.citationData.trb }}
                                                            />
                                                        </Typography>
                                                        <Typography
                                                            fontFamily="Open Sans"
                                                            fontSize={14}
                                                            fontWeight="600"
                                                        >
                                                            APA :
                                                            <div
                                                                style={{ overflow: "hidden", color: primary }}
                                                                dangerouslySetInnerHTML={{ __html: this.state.citationData.apa }}
                                                            />
                                                        </Typography>
                                                        <Typography
                                                            fontFamily="Open Sans"
                                                            fontSize={14}
                                                            fontWeight="600"
                                                        >
                                                            Harvard :
                                                            <div
                                                                style={{ overflow: "hidden", color: primary }}
                                                                dangerouslySetInnerHTML={{ __html: this.state.citationData.hrv }}
                                                            />
                                                        </Typography>
                                                        <Typography
                                                            fontFamily="Open Sans"
                                                            fontSize={14}
                                                            fontWeight="600"
                                                        >
                                                            IEEE :
                                                            <div
                                                                style={{ overflow: "hidden", color: primary }}
                                                                dangerouslySetInnerHTML={{ __html: this.state.citationData.ieee }}
                                                            />
                                                        </Typography>
                                                    </View>

                                                </DialogContent>


                                            </Dialog>
                                            <Dialog
                                                open={this.state.showHighlightDialog}
                                                onClose={this.handleCloseHighlightDialog}
                                                fullWidth={true}
                                            >
                                                <DialogTitle>
                                                    Tambah Catatan
                                                </DialogTitle>
                                                <DialogContent>
                                                    <TextInput
                                                        style={{
                                                            height: 120,
                                                            width: "100%",
                                                            fontFamily: "Open Sans",
                                                            fontWeight: "400",
                                                            color: color_text,
                                                            padding: 12,
                                                            borderRadius: 8,
                                                            backgroundColor: textbox,
                                                            outlineColor: primary
                                                        }}
                                                        multiline={true}
                                                        textAlignVertical={"top"}
                                                        placeholder={"Ketik catatan anda disini"}
                                                        onChangeText={(text) => { this.setState({ highlightComment: text }) }}
                                                    />

                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={this.handleCloseHighlightDialog}>Batal</Button>
                                                    <Button onClick={this.handleConfirmAddHighlight}>Simpan</Button>
                                                </DialogActions>
                                            </Dialog>

                                            <Dialog
                                                open={this.state.showCloseDialog}
                                                onClose={this.handleBookCloseCancel}
                                            >
                                                <DialogTitle>
                                                    Konfirmasi!
                                                </DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText>
                                                        Apakah anda selesai membaca dan tutup buku ini?
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={this.handleBookCloseCancel}>Batal</Button>
                                                    <Button onClick={this.handleBookCloseAccept}>OK</Button>
                                                </DialogActions>
                                            </Dialog>

                                        </View>
                                        <View style={{ flex: 0.1 }}>
                                            {/*
                                            <Plyr 
                                                
                                                source={this.state.videoSrc} 
                                            />
                                            */}
                                            {/* <ReactAudioPlayer
                                            src={this.state.sumberAudio} 
                                            autoPlay = {this.state.autoPlay}
                                            controls = {true}
                                            controlsList={"nodownload"}
                                            onEnded = {()=>{this.nextAudio()}}
                                            style={{width: "100vw"}}
                                            /> */}
                                            {/* <AudioPlayer
                                                autoPlay
                                                src={this.state.sumberAudio}
                                                onPlay={e => console.log("onPlay")}
                                                // other props here
                                            /> */}

                                            {/* Error karna type single atau all */}
                                            {
                                                this.state.showAudio &&
                                                <AudioPlayer
                                                    autoPlay
                                                    src={this.props.route.params.play_type == 'single' ? (this.props.route.params.play_index == '0' ? " https://bintangpusnas.perpusnas.go.id/api/konten_pnri/audiobook/" + this.state.playlist[this.state.currentTrack].multimedia_file : " https://bintangpusnas.perpusnas.go.id/api/konten_pnri/audiobook/" + this.state.playlist.multimedia_file) : " https://bintangpusnas.perpusnas.go.id/api/konten_pnri/audiobook/" + this.state.playlist[this.state.currentTrack].multimedia_file}
                                                    showSkipControls
                                                    onClickPrevious={() => { this.handleClickPrevious() }}
                                                    onClickNext={() => { this.handleClickNext() }}
                                                    onEnded={() => { this.handleEnd() }}
                                                // Try other props!
                                                />
                                            }



                                        </View>
                                    </>

                            }


                        </>
                }
            </View>

        )
    }
}


const styles = StyleSheet.create({
    container: {
        //marginTop: (global.os === "ANDROID" ? 0 : 48),
        flex: 1
    }
})

export default ReaderPlayerMobile;