import React, { Component } from 'react';
import { StyleSheet, View, FlatList, Image, Dimensions } from 'react-native'; 
import { code_ok, code_error, code_auth_error, code_empty , homepageNews } from '../constants/api';
import { Skeleton, Typography, Card, CardActionArea, CardMedia, CardContent, Button } from '@mui/material'; 
import { executeGet, executePost , truncateText } from '../utilities/Utilities';
import AlertDialog from '../components/AlertDialog';
import { color_text, sub_text, primary } from '../constants/colors';
import * as Identifier from '../constants/PageIdentifiers';

class HomepageNews extends Component {
    constructor(props){
        super(props)
        this.state = {
            showLoading : true, 
            showAlert : false, 
            alertMessage : "", 
            loadingMessage : "",
            code : this.props.code ,
            data : [], 
            dummy_data : [
                { i : 0 }, { i : 1}, {i : 2 }
            ]
        }

        this.renderSkeleton = this.renderSkeleton.bind(this); 
        this.renderItem = this.renderItem.bind(this); 
        this.fetchNews = this.fetchNews.bind(this); 
        this.handleNewsClick = this.handleNewsClick.bind(this); 

    }

    componentDidMount = ()=>{
        this.fetchNews(); 
    }

    renderSkeleton = ({item, index, separators})=>{
        return(
            <View style={{
                width : 220,
                marginLeft : (index == 0 ? 16 : 0 ), 
                marginRight : (index == (this.state.dummy_data.length -1 ) ? 16 : 8 ) 
            }}>
                <Skeleton 
                    variant="rectangular"
                    animation="wave"
                    style={{borderRadius : 8, width : 220, height : 110, marginBottom : 8 }}
                />
                <Skeleton 
                    variant="text"
                    animation="wave"
                    sx={{ fontSize : "1.5rem", width : 180 }} 
                    style={{marginBottom : 4 }} 
                />
                <Skeleton 
                    variant="text"
                    animation="wave"
                    sx={{ fontSize : "1.2rem", width : 120 }} 
                    style={{marginBottom : 4 }} 
                />
            </View>
        )
    }
    renderItem = ({item ,index, separators})=>{
        return(
            <Card 
            sx={{maxWidth : 220 }}
            style={{
                borderRadius : 8, 
                marginLeft : (index == 0 ? 16 : 0 ),
                marginRight : (index == (this.state.data.length - 1) ? 16 : 8 ) ,
                marginBottom : 8, 
                marginTop : 4
            }}>
                <CardActionArea
                    sx={{ height : "100%"}}
                    onClick={()=>{ this.handleNewsClick(item, index)}}
                >
                    <CardMedia 
                        image={ item.header_image}
                        height={ 110 }
                        component="img"
                    />
                    <CardContent
                        style={{flex : 1 }}
                    >
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={ 14 }
                            fontWeight={"600"}
                            color={ color_text }
                            style={{marginBottom : 8, flex : 1 }}
                        >
                            { item.title }
                        </Typography>

                        <Typography
                            fontFamily="Open Sans"
                            fontSize={ 12 }
                            fontWeight={"400"}
                            color={ sub_text }
                            style={{marginBottom : 8 }}
                        >
                            { item.input_time }
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    }
    fetchNews = async()=>{
        let formData = new FormData(); 
        formData.append("homepage_code", this.props.code ); 
        formData.append("page", 1); 
        formData.append("limit", 3 ); 

        const response = await executePost(homepageNews, formData); 
        if(response.code == code_ok){
            this.setState({
                showLoading : false, 
                data : response.data
            })

        } else {
            if(response.code != code_auth_error){
                this.setState({
                    showLoading : false,
                })
                // if(global.os == "ANDROID") {
                //     JSBridge.launchFunction(Command.SHOW_TOAST, response.msg);
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : response.msg })
                // }
            } else {
                // if(global.os == "ANDROID"){
                //     JSBridge.launchFunction(Command.SHOW_TOAST, "Autentikasi gagal! Harap login ulang!");
                // } else {
                //     window.webkit.messageHandlers.JSBridge.postMessage({ id : Command.SHOW_TOAST, params : "Autentikasi gagal! Harap login ulang!"})
                // }
                window.postMessage({ id: "sign_out"});
            }
        }

    }

    handleNewsClick = (item, index)=>{
        item.identifier = Identifier.MAIN; 
        this.props.navigation.navigate("NewsDetail", item); 
    }
    render(){
        return(
            <View style={{width : "100%"}}>
                {
                    this.state.showLoading && 
                    <View>
                        <Skeleton
                            variant="text"
                            animation="wave"
                            sx={{fontSize : "2rem", width : 150 }}
                            style={{marginBottom : 8, marginLeft : 16 }} 
                        />  
                        <FlatList 
                            keyExtractor={(i, x)=>"news_skeleton_"+x}
                            data={ this.state.dummy_data}
                            renderItem={ this.renderSkeleton} 
                            horizontal={ true }
                        />
                    </View>
                }
                {
                    !this.state.showLoading && 
                    <View>
                        <Typography
                            fontFamily="Open Sans"
                            fontSize={ 16 }
                            variant="h3"
                            color={ color_text } 
                            fontWeight={"600"}
                            style={{flex : 1, marginLeft : 16, marginTop : 16 , marginBottom : 8  }}
                        >
                            { truncateText(this.props.title, 20) }
                        </Typography>    
                        <FlatList
                            keyExtractor={(item ,index)=>item.homepage_code + "_"+item.news_code }
                            data={ this.state.data }
                            horizontal={ true }
                            renderItem={ this.renderItem }
                        />
                    </View>
                }

            </View>
        )
    }
}

export default HomepageNews;